"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var Redux = require("redux");
var _ = require("lodash");
var moment = require("moment");
var pages_1 = require("../../../routes/pages");
var cfg = require("../../../cfg");
var component_1 = require("../../component");
var lib_1 = require("../../lib");
var __1 = require("..");
var DealDetail;
(function (DealDetail) {
    var store = null;
    var app = function (state, action) {
        switch (action.type) {
            case 0: {
                state = _.assign({}, { deal: action.data, is_loading: false });
                return state;
            }
            case 1: {
                state.deal = _.assign({}, state.deal, action.data);
                __1.DealSearch.store && __1.DealSearch.store.dispatch({ type: 3, data: state.deal });
                return state;
            }
            default: {
                return state;
            }
        }
    };
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            var _this = _super.call(this, props) || this;
            _this.trackViewTimeout = null;
            _this._id = _this.props.match.params._id;
            return _this;
        }
        Page.prototype.componentWillMount = function () {
            var _this = this;
            lib_1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    if (_this.props.staticContext) {
                        var d = _.assign({}, _this.props.staticContext);
                        store = Redux.createStore(app, { deal: d.result, is_loading: false });
                    }
                    else {
                        var $initData = document.querySelector('#_init_' + pages_1.pages.deal_detail.id);
                        if ($initData && $initData.getAttribute('data-init')) {
                            var d = JSON.parse(decodeURIComponent($initData.getAttribute('data-init')));
                            $initData.remove();
                            store = Redux.createStore(app, { deal: d, is_loading: false });
                            _this.trackView();
                        }
                        else {
                            store = Redux.createStore(app, { deal: null, is_loading: true });
                            _this.init();
                        }
                        _this.unsubscribe = store.subscribe(function () { _this.forceUpdate(); });
                    }
                }
                else {
                    lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                }
            });
        };
        Page.prototype.init = function () {
            var _this = this;
            lib_1.Request.deal.detail(this._id, function (err, apiRes) {
                if (err) {
                    lib_1.util.handleError(lib_1.Request.getApiErrorCode(err));
                }
                else {
                    store.dispatch({ type: 0, data: apiRes.body.data });
                    _this.trackView();
                }
            });
        };
        Page.prototype.componentDidMount = function () {
            document.title = lib_1.util.title(pages_1.pages.deal_detail.title);
        };
        Page.prototype.componentWillUpdate = function () {
            if (lib_1.util.isClient() && this.props.location.hash) {
                lib_1.util.scrollTo(this.props.location.hash, 40);
            }
            document.title = lib_1.util.title(pages_1.pages.deal_detail.title, store.getState().deal.title);
        };
        Page.prototype.componentWillUnmount = function () {
            this.unsubscribe && this.unsubscribe();
            store = null;
            this.trackViewTimeout && clearTimeout(this.trackViewTimeout);
        };
        Page.prototype.trackView = function () {
            var _this = this;
            this.trackViewTimeout = setTimeout(function () {
                lib_1.Tracking.trackView({ name: 'deal', _id: _this._id }, function (err, result) {
                    if (!_.isEmpty(result)) {
                        store.dispatch({ type: 1, data: result });
                    }
                });
            }, 500);
        };
        Page.prototype.commentDone = function (count, _id) {
            if (__1.DealSearch.store) {
                __1.DealSearch.store.dispatch({ type: 3, data: { _id: _id, comment_count: count } });
            }
        };
        Page.prototype.renderViewCount = function () {
            var vc = store.getState().deal.view_count;
            return (React.createElement("div", { className: 'c-stat mr-2' },
                React.createElement("span", { className: 'c-count-prepend' }, (vc != null && vc > 0) ? vc.toLocaleString() : 0),
                React.createElement("span", { className: 'name' },
                    "View",
                    (vc > 1) ? 's' : '')));
        };
        Page.prototype.renderVote = function () {
            var deal = store.getState().deal;
            return (React.createElement(component_1.Vote.LikeButton, { name: 'deal', _id: deal._id, user_id: null, vote: deal.vote, onVoteDone: this.voteDone.bind(this), className: 'btn c-btn-3 c-btn-xs' }));
        };
        Page.prototype.voteDone = function (d) {
            store.dispatch({ type: 1, data: d });
        };
        Page.prototype.renderAddress = function (loc) {
            var showMapLink = (loc.formatted_address && loc.formatted_address != '') || (loc.address1 && loc.address1 != '');
            return (React.createElement("div", null, showMapLink
                ?
                    React.createElement("a", { href: 'https://www.google.com/maps/search/' + ((loc.address1 && loc.address1 != '') ? (loc.address1 + ', ') : '') + loc.city + ', ' + (loc.state || loc.state_abbr) },
                        React.createElement("span", { className: 'd-block d-sm-inline' }, loc.address1),
                        React.createElement("span", { className: 'd-none d-sm-inline' }, ", "),
                        React.createElement("span", null, loc.city + ', ' + (loc.state || loc.state_abbr) + ' ' + loc.zip_code))
                :
                    loc.city + ', ' + (loc.state || loc.state_abbr) + ' ' + loc.zip_code));
        };
        Page.prototype.renderVendorInfo = function (deal) {
            return (React.createElement("div", { className: 'mt-4' },
                React.createElement("div", { className: 'c-font-size-xl c-font-weight-medium' }, deal.vendor_display.name),
                (deal.vendor_display.web_site && deal.vendor_display.web_site != '')
                    &&
                        React.createElement("div", { className: 'mt-1' },
                            React.createElement("a", { href: deal.vendor_display.web_site, target: '_blank', rel: 'noopener noreferrer', className: 'c-btn-link' },
                                React.createElement("span", { className: 'ellipsis' }, deal.vendor_display.web_site))),
                (deal.vendor_display.toll_free && deal.vendor_display.toll_free != '')
                    &&
                        React.createElement("div", { className: 'mt-1' },
                            React.createElement("a", { href: 'tel:' + deal.vendor_display.toll_free, className: 'c-btn-link' },
                                React.createElement("i", { className: 'fal fa-phone' }),
                                React.createElement("span", null, deal.vendor_display.toll_free))),
                (deal.vendor_display.phone && deal.vendor_display.phone != '')
                    &&
                        React.createElement("div", { className: 'mt-1' },
                            React.createElement("a", { href: 'tel:' + deal.vendor_display.phone, className: 'c-btn-link' },
                                React.createElement("i", { className: 'fal fa-phone' }),
                                React.createElement("span", null, deal.vendor_display.phone)))));
        };
        Page.prototype.renderLocationList = function (deal) {
            var _this = this;
            return (React.createElement("section", null,
                React.createElement("h3", { className: 'pt-3' },
                    React.createElement("span", null,
                        "Location",
                        deal.location_list.length > 1 ? 's' : ''),
                    React.createElement("a", { href: 'https://www.google.com/maps/search/' + encodeURI(deal.vendor_display.name), target: '_blank', rel: 'noopener noreferrer', className: 'c-btn-link ml-2' },
                        React.createElement("i", { className: 'far fa-map' }))),
                deal.location_list && deal.location_list.length > 0
                    ?
                        React.createElement("ul", { className: 'mt-1 list-unstyled' }, _.map(_.sortBy(deal.location_list, function (loc) { return loc.zip_code; }), function (loc, idx) {
                            return (React.createElement("li", { key: idx, className: 'mt-3' },
                                React.createElement("div", { className: 'd-flex' },
                                    React.createElement("i", { className: 'fas fa-map-marker-alt mr-2 mt-1' }),
                                    React.createElement("div", null, _this.renderAddress(loc)))));
                        }))
                    :
                        (_.has(deal.vendor_display, 'is_online') == false || deal.vendor_display.is_online == false)
                            &&
                                React.createElement("div", { className: 'mt-1' },
                                    React.createElement("i", { className: 'fas fa-fw fa-map-marker-alt mr-2' }),
                                    React.createElement("a", { href: 'https://www.google.com/maps/search/' + encodeURI(deal.vendor_display.name), target: '_blank', rel: 'noopener noreferrer', className: 'c-btn-link' }, "All Locations"))));
        };
        Page.prototype.renderReedemHistory = function (deal) {
            if (deal.my_redeem_meta_list && deal.my_redeem_meta_list.length > 0) {
                return (React.createElement("div", { className: 'mt-3 c-alert c-alert-expired' },
                    "You have redeemed this deal",
                    deal.my_redeem_meta_list.length == 1
                        ?
                            React.createElement("span", null,
                                " at ",
                                moment(deal.my_redeem_meta_list[0].date_create).format(cfg.format.datetime),
                                ".")
                        :
                            React.createElement("ul", { className: 'list-group mt-2' }, _.map(deal.my_redeem_meta_list, function (d, idx) {
                                var md = moment(d.date_create);
                                return (React.createElement("li", { key: idx, className: 'list-group-item' },
                                    React.createElement("span", null, md.format(cfg.format.datetimeLong) + ' (' + md.fromNow() + ')'),
                                    React.createElement("span", { className: 'separator d-none d-sm-inline' }),
                                    React.createElement("span", { className: 'd-block d-sm-inline' }, d.vehicle_title)));
                            }))));
            }
            else {
                return null;
            }
        };
        Page.prototype.renderDeal = function () {
            var d = store.getState().deal;
            var hasLimit = (!_.isEmpty(d.limit) && d.limit.is_active && d.limit.count_total > 0);
            var my_user_id = !_.isEmpty(lib_1.Global.store.getState().user) ? lib_1.Global.store.getState().user._id : null;
            var comment = this.props.staticContext ? _.assign({}, this.props.staticContext['comment']) : null;
            return (React.createElement("div", { className: 'flex-ch-100' },
                (hasLimit && d.limit.count_total_left == 0) || moment().isAfter(moment(d.date_end))
                    &&
                        React.createElement("div", { className: 'c-alert c-alert-expired text-center' }, !_.isEmpty(d.limit) && d.limit.is_active && d.limit.count_total == 0
                            ?
                                'Sorry, this deal has sold out.'
                            :
                                'Sorry, this deal has expired.'),
                React.createElement("div", { className: 'c-detail-heading d-lg-flex align-items-end' },
                    React.createElement("div", { className: 'pr-lg-5' },
                        React.createElement("h1", { className: 'title' }, d.title),
                        (hasLimit && d.limit.count_total_left > 0)
                            &&
                                React.createElement("div", null,
                                    React.createElement("span", { className: 'c-font-weight-medium c-font-color-2 c-font-size-l' }, d.limit.count_total_left.toLocaleString()),
                                    " Remain",
                                    React.createElement("span", { className: 'separator' }),
                                    React.createElement("span", { className: 'c-font-weight-medium c-font-size-l' }, (d.limit.count_total - d.limit.count_total_left).toLocaleString()),
                                    " Redeemed"),
                        (!hasLimit && d.date_end && d.date_end != '')
                            &&
                                React.createElement("div", { className: 'c-font-size-xs' },
                                    React.createElement("i", { className: 'far fa-clock mr-2' }),
                                    moment().isAfter(moment(d.date_end))
                                        ?
                                            React.createElement("span", { className: 'c-font-color-2' }, "Deal Expired")
                                        :
                                            React.createElement("span", null,
                                                "Expires: ",
                                                moment(d.date_end).format(cfg.format.date),
                                                " (",
                                                moment(d.date_end).fromNow(),
                                                ")")),
                        this.renderReedemHistory(d)),
                    React.createElement("div", { className: 'ml-auto mt-3 mt-lg-0 d-flex flex-nowrap' },
                        this.renderViewCount(),
                        this.renderVote())),
                React.createElement("div", { className: 'row no-gutters flex-rh-100' },
                    React.createElement("div", { className: 'col-12 col-lg pb-4' },
                        React.createElement("div", { className: 'pb-1' },
                            component_1.PhotoView.riGallery(d.title, cfg.photoBaseUrl[process.env.NODE_ENV].cdn + '/deal/' + d.group + '/' + d._id + '/', d.photo_list, true),
                            React.createElement("div", { className: 'c-detail-content' },
                                this.renderVendorInfo(d),
                                this.renderLocationList(d),
                                (d.description && d.description != '')
                                    &&
                                        React.createElement("section", { className: 'pt-3' },
                                            React.createElement("h3", null, "About this Deal"),
                                            lib_1.util.text2html(d.description)),
                                (d.redeem && !_.isEmpty(d.redeem.description))
                                    &&
                                        React.createElement("section", { className: 'pt-3' },
                                            React.createElement("h3", null, "How to Redeem"),
                                            lib_1.util.text2html(d.redeem.description)),
                                (!hasLimit && d.date_end && d.date_end != '')
                                    &&
                                        React.createElement("section", { className: 'pt-3' },
                                            React.createElement("h3", null, "Expires"),
                                            React.createElement("div", null,
                                                React.createElement("span", null, moment().isAfter(moment(d.date_end))
                                                    ?
                                                        React.createElement("span", { className: 'c-font-color-2' }, "Deal Expired")
                                                    :
                                                        moment(d.date_end).format(cfg.format.date)),
                                                React.createElement("span", { className: 'ml-2' }, '(' + moment(d.date_end).fromNow() + ')'))),
                                (d.disclaimer && d.disclaimer != '')
                                    &&
                                        React.createElement("section", { className: 'pt-3' },
                                            React.createElement("h3", null, "Disclaimer"),
                                            React.createElement("div", null, lib_1.util.text2html(d.disclaimer))),
                                d.vendor_display.is_online
                                    &&
                                        React.createElement("section", { className: 'pt-4' },
                                            React.createElement("a", { href: d.vendor_display.web_site, target: '_blank', rel: 'noopener noreferrer', className: 'btn c-btn-s c-btn-1' }, "See Deal")))),
                        React.createElement("hr", { className: 'c-hr' }),
                        React.createElement("div", { className: 'mt-1 mb-3 c-detail-content' },
                            React.createElement("div", { className: 'c-toolbar' },
                                component_1.Stat.views(d.view_count),
                                this.renderVote()))),
                    React.createElement("div", { className: 'col-12 c-col-right' },
                        React.createElement(component_1.Comment.Component, { parent_url: this.props.location.pathname, api_base_url: lib_1.Global.cruzApiBaseUrl + '/deal', discussion_id: d._id, query: { page_size: cfg.commentPageSize.deal }, result: comment, name: 'deal_comment', onCommentDone: this.commentDone.bind(this) })))));
        };
        Page.prototype.render = function () {
            return (component_1.Layout.main(null, null, React.createElement("div", { className: 'flex-ch-100' },
                React.createElement(component_1.Hero, { title: pages_1.pages.deal_detail.title }),
                React.createElement("div", { className: 'flex-rh-100 container c-detail-container c-deal-detail' }, (store.getState().is_loading || _.isEmpty(store.getState().deal))
                    ?
                        React.createElement(component_1.Loading, null)
                    :
                        this.renderDeal()))));
        };
        return Page;
    }(React.Component));
    DealDetail.Page = Page;
})(DealDetail = exports.DealDetail || (exports.DealDetail = {}));
