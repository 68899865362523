"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var request = require("superagent");
var _ = require("lodash");
var moment = require("moment");
var Clipboard = require("clipboard");
var classNames = require("classnames");
var reactStringReplace = require('react-string-replace');
var Cruz = require("cruz");
var cfg = require("../../../cfg");
var pages_1 = require("../../../routes/pages");
var lib_1 = require("../../lib");
var component_1 = require("../../component");
var __1 = require("..");
var User;
(function (User) {
    function shouldShowUser(status_id) {
        return (status_id != Cruz.Lookup.CruzUser.StatusEnum.Disabled && status_id != Cruz.Lookup.CruzUser.StatusEnum.Closed);
    }
    var Hero = (function (_super) {
        __extends(Hero, _super);
        function Hero(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                user: _.assign({}, _this.props.user),
                is_working: false
            };
            return _this;
        }
        Hero.prototype.componentDidMount = function () {
            var _this = this;
            this.unsubscribe = lib_1.Global.store.subscribe(function () { _this.forceUpdate(); });
        };
        Hero.prototype.componentWillUnmount = function () {
            this.unsubscribe && this.unsubscribe();
        };
        Hero.prototype.componentWillReceiveProps = function (nextProps) {
            this.setState({ user: _.assign({}, this.state.user, nextProps.user) });
        };
        Hero.prototype.follow = function (user_id) {
            var _this = this;
            this.setState({ is_working: true }, function () {
                lib_1.Request.follow.follow(user_id, function (err, apiRes) {
                    _this.setState({ is_working: false });
                    if (err) {
                        lib_1.util.setToastMessage('error', apiRes.body.error.detail);
                    }
                    else {
                        _this.setState({ user: apiRes.body.data['follow_user'] });
                        lib_1.Global.store.dispatch({ type: lib_1.Global.actions.setUser, data: apiRes.body.data['user'] });
                    }
                });
            });
        };
        Hero.prototype.unFollow = function (user_id) {
            var _this = this;
            this.setState({ is_working: true }, function () {
                lib_1.Request.follow.unFollow(user_id, function (err, apiRes) {
                    _this.setState({ is_working: false });
                    if (err) {
                        lib_1.util.handleError(lib_1.Request.getApiErrorCode(err));
                    }
                    else {
                        _this.setState({ user: apiRes.body.data['follow_user'] });
                        lib_1.Global.store.dispatch({ type: lib_1.Global.actions.setUser, data: apiRes.body.data['user'] });
                    }
                });
            });
        };
        Hero.prototype.renderFollow = function (user_id) {
            var _this = this;
            var me = lib_1.Global.store.getState().user || null;
            var is_following = (me && !_.isEmpty(me.follow) && !_.isEmpty(me.follow.following_list) && _.indexOf(me.follow.following_list, user_id) > -1);
            return (is_following
                ?
                    React.createElement("div", { className: 'dropdown' },
                        React.createElement("a", { className: 'btn c-btn-1 c-btn-xs c-btn-round dropdown-toggle', href: '#', role: 'button', "data-toggle": 'dropdown' },
                            React.createElement("i", { className: 'fal fa-check' }),
                            React.createElement("span", { className: 'mr-1' }, this.state.is_working ? React.createElement(__1.BtnWorking, { value: 'Updating ...' }) : 'Following')),
                        React.createElement("div", { className: 'dropdown-menu' },
                            React.createElement("button", { className: 'dropdown-item', onClick: function (e) { return _this.unFollow(user_id); } },
                                React.createElement("i", { className: 'fal fa-unlink' }),
                                React.createElement("span", null, "Unfollow"))))
                :
                    React.createElement("button", { className: 'btn c-btn-1 c-btn-xs c-btn-round', onClick: function (e) { return _this.follow(user_id); } },
                        React.createElement("i", { className: 'fal fa-plus' }),
                        React.createElement("span", null, this.state.is_working ? React.createElement(__1.BtnWorking, { value: 'Following ...' }) : 'Follow')));
        };
        Hero.prototype.render = function () {
            var user = this.state.user;
            var my_user_id = !_.isEmpty(lib_1.Global.store.getState().user) ? lib_1.Global.store.getState().user._id : null;
            var isMe = (my_user_id != null && my_user_id == user._id);
            var following_count = (!_.isEmpty(user.follow) && user.follow.following_count != null) ? user.follow.following_count : 0;
            var follower_count = (!_.isEmpty(user.follow) && user.follow.follower_count != null) ? user.follow.follower_count : 0;
            var user_name = _.startCase(user.first_name + ' ' + user.last_name);
            var user_url = isMe ? pages_1.pages.my_profile.path : lib_1.util.userCruzBaseUrl(user);
            var hasAvatar = (user.photo_url && user.photo_url != '');
            return (React.createElement("div", { className: 'shadow' },
                React.createElement("div", { className: 'c-user-hero d-flex align-items-center' },
                    React.createElement("div", { className: 'container c-hero-container my-3 d-md-flex justify-content-between align-items-center' },
                        React.createElement("div", { className: 'c-user-hero-display d-flex align-items-center flex-row justify-content-center' },
                            (user.status_id > 0)
                                &&
                                    React.createElement("div", { className: classNames('mr-4', { 'd-none d-md-block': !hasAvatar }) },
                                        React.createElement(react_router_dom_1.Link, { to: user_url, title: user_name }, renderUserIcon(user))),
                            React.createElement("div", { className: classNames({ 'text-center text-md-left': !hasAvatar }) },
                                React.createElement("div", { className: 'c-name ellipsis' }, shouldShowUser(user.status_id)
                                    ?
                                        React.createElement(react_router_dom_1.Link, { to: user_url, className: 'c-btn-link' }, user_name)
                                    :
                                        '-'),
                                React.createElement("div", { className: 'c-font-size-s mt-2' },
                                    !isMe &&
                                        React.createElement("span", null, user.location.city + ', ' + user.location.state),
                                    (!user.is_cruzteam && user.date_active) &&
                                        React.createElement("span", { className: classNames({ 'd-block d-sm-inline-block': hasAvatar }) },
                                            !isMe &&
                                                React.createElement("span", { className: classNames('separator', { 'd-none d-sm-inline-block': hasAvatar }) }),
                                            React.createElement("span", null, 'Joined ' + moment(user.date_active).fromNow())),
                                    (isMe && !_.isEmpty(user.register_number) && user.register_number.global > 0) &&
                                        React.createElement("span", { className: 'd-block d-sm-inline-block' },
                                            React.createElement("span", { className: 'separator d-none d-sm-inline-block' }),
                                            React.createElement("span", null,
                                                "Cruz Member #",
                                                user.register_number.global.toLocaleString()))),
                                React.createElement("div", { className: 'd-flex align-items-center justify-content-md-start' },
                                    (isMe && follower_count != null && follower_count > 0)
                                        ?
                                            React.createElement(react_router_dom_1.Link, { to: pages_1.pages.my_follow.path + '?tab=follower', className: 'c-btn-link c-font-size-xs' },
                                                "Follower",
                                                (follower_count > 1) && 's')
                                        :
                                            React.createElement("span", { className: 'c-font-size-xs' },
                                                "Follower",
                                                (follower_count > 1) && 's'),
                                    React.createElement("span", { className: 'ml-2 c-font-size-l' }, follower_count.toLocaleString()),
                                    React.createElement("span", { className: 'separator' }),
                                    following_count > 0
                                        ?
                                            React.createElement(react_router_dom_1.Link, { to: isMe ? pages_1.pages.my_follow.path + '?tab=following' : lib_1.util.userCruzBaseUrl(user) + '/' + _.last(pages_1.pages.user_following.path.split('/')), className: 'c-btn-link c-font-size-xs' }, "Following")
                                        :
                                            React.createElement("span", { className: 'c-font-size-xs' }, "Following"),
                                    React.createElement("span", { className: 'ml-2 c-font-size-l' }, following_count.toLocaleString())))),
                        (shouldShowUser(user.status_id) == true && my_user_id && !isMe) &&
                            React.createElement("div", { className: 'd-flex justify-content-center mt-2 mt-md-0' },
                                React.createElement("div", { className: 'd-inline-flex align-items-center' },
                                    this.renderFollow(user._id),
                                    React.createElement("button", { type: 'button', className: 'btn c-btn-1 c-btn-xs c-btn-round ml-2', onClick: function () { return lib_1.Chat.chatWithUser(user, lib_1.util.getRelativeUrl()); } },
                                        React.createElement("i", { className: 'fal fa-comments' }),
                                        React.createElement("span", null, "Messaging")))),
                        isMe &&
                            React.createElement("div", { className: 'd-none d-sm-flex mt-3 mt-lg-0 justify-content-center' },
                                React.createElement("div", { className: 'c-code d-flex align-items-center' },
                                    lib_1.util.myQRCode(),
                                    React.createElement("div", { className: 'px-3' },
                                        React.createElement("div", { className: 'c-font-size-xs mb-1' }, "CRUZ\u00AE Code:"),
                                        React.createElement("div", { className: 'c-font-size-l c-code-value' }, lib_1.Global.store.getState().user.code)))))),
                isMe
                    ?
                        React.createElement(component_1.Nav.NavbarMyCruz, { pathname: lib_1.Global.history.location.pathname })
                    :
                        shouldShowUser(user.status_id)
                            ?
                                React.createElement(component_1.Nav.NavbarUser, { baseUrl: lib_1.util.userCruzBaseUrl(this.props.user), pathname: lib_1.Global.history.location.pathname })
                            :
                                React.createElement("div", null)));
        };
        return Hero;
    }(React.Component));
    User.Hero = Hero;
    var ReferralUrl = (function (_super) {
        __extends(ReferralUrl, _super);
        function ReferralUrl(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                myUrl: _this.props.myUrl
            };
            return _this;
        }
        ReferralUrl.prototype.componentDidMount = function () {
            var clipboard = new Clipboard('.c-btn-clipboard');
            var $btn = $('.c-btn-clipboard');
            clipboard.on('success', function (e) {
                $btn.tooltip({ title: 'Copied' }).tooltip('show').on('shown.bs.tooltip', function () {
                    setTimeout(function () { $btn.tooltip('hide'); }, 2000);
                });
            });
            clipboard.on('error', function (e) {
                $btn.tooltip({ title: 'Failed' }).tooltip('show').on('shown.bs.tooltip', function () {
                    setTimeout(function () { $btn.tooltip('hide'); }, 2000);
                });
            });
        };
        ReferralUrl.prototype.selectUrl = function (tgt) {
            tgt.select();
        };
        ReferralUrl.prototype.render = function () {
            var _this = this;
            return (React.createElement("div", { className: 'form-group' },
                React.createElement("div", { className: 'input-group' },
                    React.createElement("input", { type: 'text', className: 'form-control text-center no-label', defaultValue: this.state.myUrl, readOnly: true, onFocus: function (e) { return _this.selectUrl(e.target); } }),
                    React.createElement("div", { className: 'input-group-append' },
                        React.createElement("button", { type: 'button', className: 'btn c-btn-1 c-btn-clipboard', "data-clipboard-text": this.state.myUrl, "data-toggle": 'tooltip', "data-placement": 'bottom', "data-trigger": 'manual' },
                            React.createElement("i", { className: 'far fa-copy mr-1' }),
                            React.createElement("span", null, "Copy"))))));
        };
        return ReferralUrl;
    }(React.Component));
    User.ReferralUrl = ReferralUrl;
    var SummaryList = (function (_super) {
        __extends(SummaryList, _super);
        function SummaryList(props) {
            var _this = _super.call(this, props) || this;
            var gUser = lib_1.Global.store.getState().user;
            _this.state = {
                is_working: false,
                my_following_list: (gUser && gUser.follow) ? gUser.follow.following_list : []
            };
            return _this;
        }
        SummaryList.prototype.gotoUserProfile = function (e, ud) {
            e.preventDefault();
            lib_1.Global.history.push(lib_1.util.userCruzBaseUrl(ud));
            $('#_' + this.props._id).modal('hide');
        };
        SummaryList.prototype.follow = function (user_id) {
            var _this = this;
            if (!this.state.is_working) {
                this.setState({ is_working: true }, function () {
                    lib_1.Request.follow.follow(user_id, function (err, apiRes) {
                        _this.setState({ is_working: false });
                        if (err) {
                            lib_1.util.handleError(lib_1.Request.getApiErrorCode(err));
                        }
                        else {
                            _this.setUser(apiRes.body.data['user']);
                        }
                    });
                });
            }
        };
        SummaryList.prototype.unFollow = function (user_id) {
            var _this = this;
            if (!this.state.is_working) {
                this.setState({ is_working: true }, function () {
                    lib_1.Request.follow.unFollow(user_id, function (err, apiRes) {
                        _this.setState({ is_working: false });
                        if (err) {
                            lib_1.util.handleError(lib_1.Request.getApiErrorCode(err));
                        }
                        else {
                            _this.setUser(apiRes.body.data['user']);
                        }
                    });
                });
            }
        };
        SummaryList.prototype.setUser = function (user) {
            this.setState({ my_following_list: user.follow.following_list });
            lib_1.Global.store.dispatch({ type: lib_1.Global.actions.setUser, data: user });
        };
        SummaryList.prototype.render = function () {
            var _this = this;
            try {
                var ud_1 = this.props.user;
                var my_user_id = lib_1.Global.store.getState().user ? lib_1.Global.store.getState().user._id : null;
                return (React.createElement("div", { className: 'd-flex align-items-center' },
                    React.createElement("div", { className: 'c-ud ellipsis' },
                        React.createElement("a", { href: lib_1.util.userCruzBaseUrl(ud_1), className: 'c-btn-link c-font-weight-medium', onClick: function (e) { return _this.gotoUserProfile(e, ud_1); } }, User.renderUserIcon(ud_1)),
                        React.createElement("div", { className: 'ml-3 ellipsis' },
                            React.createElement("a", { href: lib_1.util.userCruzBaseUrl(ud_1), className: 'c-btn-link c-font-weight-medium', onClick: function (e) { return _this.gotoUserProfile(e, ud_1); } }, _.startCase(ud_1.first_name + ' ' + ud_1.last_name)),
                            React.createElement("div", { className: 'c-font-size-xs' }, ud_1.location.city + ', ' + ud_1.location.state))),
                    (my_user_id != ud_1._id)
                        &&
                            React.createElement("div", { className: 'ml-auto' }, (_.indexOf(this.state.my_following_list, ud_1._id) > -1)
                                ?
                                    React.createElement("div", { className: 'dropdown' },
                                        React.createElement("a", { className: 'btn c-btn-1 c-btn-xs c-btn-round dropdown-toggle', href: '#', role: 'button', "data-toggle": 'dropdown' },
                                            React.createElement("i", { className: 'fal fa-check' }),
                                            React.createElement("span", { className: 'mr-1' }, this.state.is_working ? React.createElement(__1.BtnWorking, { value: 'Updating ...' }) : 'Following')),
                                        React.createElement("div", { className: 'dropdown-menu' },
                                            React.createElement("button", { className: 'dropdown-item', onClick: function (e) { return _this.unFollow(ud_1._id); } },
                                                React.createElement("i", { className: 'fal fa-unlink' }),
                                                React.createElement("span", null, "Unfollow"))))
                                :
                                    React.createElement("button", { className: 'btn c-btn-1 c-btn-xs c-btn-round', onClick: function (e) { return _this.follow(ud_1._id); } },
                                        React.createElement("i", { className: 'fal fa-plus' }),
                                        React.createElement("span", null, this.state.is_working ? React.createElement(__1.BtnWorking, { value: 'Following ...' }) : 'Follow')))));
            }
            catch (err) {
                lib_1.util.logError(err, null);
                return null;
            }
        };
        return SummaryList;
    }(React.Component));
    User.SummaryList = SummaryList;
    var SummaryCard = (function (_super) {
        __extends(SummaryCard, _super);
        function SummaryCard(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                is_working: false
            };
            return _this;
        }
        SummaryCard.prototype.componentDidMount = function () {
            var _this = this;
            this.unsubscribe = lib_1.Global.store.subscribe(function () { _this.forceUpdate(); });
        };
        SummaryCard.prototype.componentWillUnmount = function () {
            this.unsubscribe && this.unsubscribe();
        };
        SummaryCard.prototype.follow = function () {
            var _this = this;
            this.setState({ is_working: true }, function () {
                lib_1.Request.follow.follow(_this.props.user._id, function (err, apiRes) {
                    _this.setState({ is_working: false });
                    if (err) {
                        lib_1.util.handleError(lib_1.Request.getApiErrorCode(err));
                    }
                    else {
                        lib_1.Global.store.dispatch({ type: lib_1.Global.actions.setUser, data: apiRes.body.data['user'] });
                        _this.props.onFollowChanged && _this.props.onFollowChanged(apiRes.body.data['follow_user']);
                    }
                });
            });
        };
        SummaryCard.prototype.unFollow = function () {
            var _this = this;
            this.setState({ is_working: true }, function () {
                lib_1.Request.follow.unFollow(_this.props.user._id, function (err, apiRes) {
                    _this.setState({ is_working: false });
                    if (err) {
                        lib_1.util.handleError(lib_1.Request.getApiErrorCode(err));
                    }
                    else {
                        lib_1.Global.store.dispatch({ type: lib_1.Global.actions.setUser, data: apiRes.body.data['user'] });
                        _this.props.onFollowChanged && _this.props.onFollowChanged(apiRes.body.data['follow_user']);
                    }
                });
            });
        };
        SummaryCard.prototype.renderText = function (text) {
            try {
                if (this.props.keyword && this.props.keyword != '') {
                    var rText_1 = text;
                    _.forEach(_.words(this.props.keyword), function (word) {
                        rText_1 = reactStringReplace(rText_1, word, function (match, i) {
                            return React.createElement("mark", { key: match + i }, match);
                        });
                    });
                    return rText_1;
                }
                else {
                    return text;
                }
            }
            catch (err) {
                lib_1.util.logError(err, null);
                return text;
            }
        };
        SummaryCard.prototype.render = function () {
            var _this = this;
            try {
                var user_1 = this.props.user;
                var user_name = _.startCase(user_1.first_name + ' ' + user_1.last_name);
                var isFollowing = false;
                var me = (lib_1.Global.store.getState().user || null);
                if (!_.isEmpty(me) && _.indexOf(_.get(me, 'follow.following_list'), user_1._id) > -1) {
                    isFollowing = true;
                }
                var vehicle_count = (!_.isEmpty(user_1.statistic) && !_.isEmpty(user_1.statistic.vehicle))
                    ?
                        (user_1.statistic.vehicle.active != null ? user_1.statistic.vehicle.active : 0) + (user_1.statistic.vehicle.sold != null ? user_1.statistic.vehicle.sold : 0)
                    :
                        0;
                var following_count = (!_.isEmpty(user_1.follow) && user_1.follow.following_count != null) ? user_1.follow.following_count : 0;
                var userBaseUrl = lib_1.util.userCruzBaseUrl(user_1);
                return (React.createElement("div", { className: 'card' },
                    React.createElement("div", { className: 'card-img' },
                        user_1.status_id == Cruz.Lookup.CruzUser.StatusEnum.Disabled
                            &&
                                React.createElement("div", { className: 'c-icon-status', title: 'User account disabled' },
                                    React.createElement("i", { className: 'fal fa-ban' })),
                        user_1.status_id == Cruz.Lookup.CruzUser.StatusEnum.Closed
                            &&
                                React.createElement("div", { className: 'c-icon-status', title: 'User account closed' },
                                    React.createElement("i", { className: 'fal fa-circle' })),
                        (user_1.status_id == Cruz.Lookup.CruzUser.StatusEnum.Pending)
                            &&
                                React.createElement("div", { className: 'c-icon-status', title: 'Not verified' },
                                    React.createElement("i", { className: 'fal fa-exclamation-triangle' })),
                        React.createElement(react_router_dom_1.Link, { to: userBaseUrl, title: user_name },
                            React.createElement("img", { className: 'card-img-top img-fluid img-user-profile rounded-circle', src: _.isEmpty(user_1.photo_url) ? '/img/default_profile.png' : cfg.photoBaseUrl[cfg.env].s3 + '/' + user_1.photo_url }))),
                    React.createElement("div", { className: 'card-body' },
                        React.createElement("div", { className: 'd-flex' },
                            React.createElement("h2", { className: 'card-title mb-1 ellipsis' }, shouldShowUser(user_1.status_id)
                                ?
                                    React.createElement(react_router_dom_1.Link, { to: userBaseUrl, className: 'c-btn-link', title: (this.props.show_following_icon && isFollowing) ? 'You are following' : '' },
                                        user_name,
                                        (this.props.show_following_icon && isFollowing)
                                            &&
                                                React.createElement("i", { className: 'fal fa-check ml-2', title: 'You are following' }))
                                :
                                    '-'),
                            !shouldShowUser(user_1.status_id) || _.isEmpty(me) || me._id == user_1._id
                                ?
                                    React.createElement("div", null)
                                :
                                    this.state.is_working
                                        ?
                                            React.createElement("div", { className: 'ml-auto pl-4' },
                                                React.createElement("i", { className: 'far fa-spinner fa-pulse' }))
                                        :
                                            React.createElement("div", { className: 'ml-auto dropup c-ellipsis-drop pl-4' },
                                                React.createElement("button", { className: 'dropdown-toggle', "data-toggle": 'dropdown' },
                                                    React.createElement("i", { className: 'far fa-ellipsis-v' })),
                                                React.createElement("div", { className: 'dropdown-menu dropdown-menu-right' },
                                                    isFollowing
                                                        ?
                                                            React.createElement("button", { className: 'dropdown-item', type: 'button', onClick: function () { return _this.unFollow(); } },
                                                                React.createElement("i", { className: 'fal fa-unlink fa-fw' }),
                                                                React.createElement("span", null, "Unfollow"))
                                                        :
                                                            React.createElement("button", { className: 'dropdown-item', type: 'button', onClick: function () { return _this.follow(); } },
                                                                React.createElement("i", { className: 'fal fa-plus fa-fw' }),
                                                                React.createElement("span", null, "Follow")),
                                                    React.createElement("button", { type: 'button', className: 'dropdown-item', onClick: function (e) { return lib_1.Chat.chatWithUser(user_1, lib_1.util.getRelativeUrl()); } },
                                                        React.createElement("i", { className: 'fal fa-comments fa-fw' }),
                                                        React.createElement("span", null, "Messaging"))))),
                        React.createElement("div", { className: 'c-font-size-xs' },
                            React.createElement("div", { className: 'ellipsis' },
                                React.createElement("i", { className: 'fal fa-map-marker fa-fw mr-2' }),
                                this.renderText(user_1.location.city + ', ' + user_1.location.state + ' ' + user_1.location.zip_code),
                                (user_1.location.distance != null)
                                    &&
                                        React.createElement("span", { className: 'ml-1 c-font-size-xs' },
                                            "(",
                                            (user_1.location.distance > 10 ? '\u2248' + ' ' + user_1.location.distance.toLocaleString() : '< 10') + ' miles',
                                            ")")),
                            React.createElement("div", { className: 'ellipsis' },
                                React.createElement("i", { className: 'fal fa-clock fa-fw mr-2' }),
                                React.createElement("span", null, (user_1.status_id > 0) ? 'Joined ' + moment(user_1.date_active).fromNow() : 'Registered ' + moment(user_1.date_create).fromNow())),
                            (user_1.email && user_1.email != '')
                                &&
                                    React.createElement("div", { className: 'ellipsis' },
                                        React.createElement("i", { className: 'fal fa-at fa-fw mr-2' }),
                                        this.renderText(user_1.email)),
                            (!_.isEmpty(user_1.phone))
                                &&
                                    React.createElement("div", { className: 'ellipsis' },
                                        React.createElement("i", { className: 'fal fa-phone fa-fw mr-2' }),
                                        React.createElement("span", null, user_1.phone)))),
                    React.createElement("div", { className: 'card-footer c-card-footer' },
                        React.createElement("div", { className: 'c-card-footer-row row no-gutters flex-nowrap' },
                            React.createElement("div", { className: 'cc-item col-5' },
                                React.createElement("span", { className: 'count' }, vehicle_count),
                                React.createElement("span", { className: 'name' }, vehicle_count > 0
                                    ?
                                        React.createElement(react_router_dom_1.Link, { to: userBaseUrl + '/' + _.last(pages_1.pages.user_vehicle.path.split('/')), className: 'c-btn-link' },
                                            "Vehicle",
                                            vehicle_count > 1 ? 's' : '')
                                    :
                                        'vehicle'),
                                (vehicle_count > 0 && user_1.statistic.vehicle.sold != null && user_1.statistic.vehicle.sold > 0)
                                    &&
                                        React.createElement("span", { className: 'name ml-2' },
                                            "(",
                                            user_1.statistic.vehicle.sold,
                                            " sold)")),
                            React.createElement("div", { className: 'cc-item col-7' },
                                React.createElement("span", { className: 'count' }, following_count > 0 ? user_1.follow.following_count.toLocaleString() : 0),
                                React.createElement("span", { className: 'name' }, following_count > 0
                                    ?
                                        React.createElement(react_router_dom_1.Link, { to: userBaseUrl + '/' + _.last(pages_1.pages.user_following.path.split('/')), className: 'c-btn-link' }, "Following")
                                    :
                                        'Following'),
                                React.createElement("span", { className: 'separator' }),
                                React.createElement("span", { className: 'count' }, (!_.isEmpty(user_1.follow) && user_1.follow.follower_count) ? user_1.follow.follower_count.toLocaleString() : 0),
                                React.createElement("span", { className: 'name' },
                                    "Follower",
                                    (!_.isEmpty(user_1.follow) && user_1.follow.follower_count != null && user_1.follow.follower_count > 1) && 's'))))));
            }
            catch (err) {
                lib_1.util.logError(err, null);
                return null;
            }
        };
        return SummaryCard;
    }(React.Component));
    User.SummaryCard = SummaryCard;
    function renderUserIcon(ud) {
        try {
            if (!_.isEmpty(ud.photo_url)) {
                return (React.createElement("img", { className: 'rounded-circle c-avatar', src: cfg.photoBaseUrl[cfg.env].s3 + '/' + ud.photo_url }));
            }
            else if (!_.isEmpty(ud.initials)) {
                return (React.createElement("div", { className: 'c-avatar c-avatar-alt' }, ud.initials));
            }
            else {
                return null;
            }
        }
        catch (err) {
            lib_1.util.logError(err, null);
            return null;
        }
    }
    User.renderUserIcon = renderUserIcon;
    function renderUserDisplay(ud, linkToUserProfile) {
        try {
            return (React.createElement("div", { className: 'c-ud' },
                linkToUserProfile
                    ?
                        React.createElement(react_router_dom_1.Link, { to: lib_1.util.userCruzBaseUrl(ud) }, renderUserIcon(ud))
                    :
                        renderUserIcon(ud),
                React.createElement("div", { className: 'ml-3' },
                    React.createElement("div", { className: 'c-name' }, _.startCase(ud.first_name + ' ' + ud.last_name)),
                    React.createElement("div", { className: 'c-description ellipsis' },
                        React.createElement("span", null, ud.location.city + ', ' + ud.location.state),
                        (ud.date_active && !ud.is_cruzteam)
                            &&
                                React.createElement("span", { className: 'd-block d-sm-inline-block' },
                                    React.createElement("span", { className: 'separator d-none d-sm-inline-block' }),
                                    React.createElement("span", null, 'Joined ' + moment(ud.date_active).fromNow()))))));
        }
        catch (err) {
            lib_1.util.logError(err, null);
            return null;
        }
    }
    User.renderUserDisplay = renderUserDisplay;
    function renderUserDisplaySummary(ud, my_following_list) {
        try {
            var distance = ud.location.distance;
            return (React.createElement("div", { className: 'c-ud' },
                React.createElement("div", null,
                    React.createElement("div", { className: 'c-name ellipsis' }, shouldShowUser(ud.status_id)
                        ?
                            React.createElement(react_router_dom_1.Link, { to: lib_1.util.userCruzBaseUrl(ud), className: 'c-btn-link' },
                                _.startCase(ud.first_name + ' ' + ud.last_name),
                                (my_following_list && my_following_list.length > 0 && _.indexOf(my_following_list, ud._id) > -1)
                                    &&
                                        React.createElement("i", { className: 'fal fa-check ml-2', title: 'You are following' }))
                        :
                            '-'),
                    React.createElement("div", { className: 'c-description ellipsis' },
                        ud.location.city + ', ' + ud.location.state,
                        (distance != null) &&
                            React.createElement("span", { className: 'ml-1' },
                                "(",
                                (distance > 5 ? '\u2248' + ' ' + distance.toLocaleString() : '< 5') + ' miles',
                                ")")))));
        }
        catch (err) {
            lib_1.util.logError(err, null);
            return null;
        }
    }
    User.renderUserDisplaySummary = renderUserDisplaySummary;
    function accountDisabled() {
        return (React.createElement("div", { className: 'alert text-center' },
            React.createElement("p", null, "We're sorry, your account has been disabled."),
            React.createElement("p", null,
                "For questions, please ",
                React.createElement(react_router_dom_1.Link, { to: pages_1.pages.contact.path, className: 'c-btn-link' }, "Contact Us"),
                ".")));
    }
    User.accountDisabled = accountDisabled;
    function accountClosed() {
        return (React.createElement("div", { className: 'alert text-center' },
            React.createElement("p", null, "We're sorry, your account has been closed."),
            React.createElement("p", null,
                "For questions, please ",
                React.createElement(react_router_dom_1.Link, { to: pages_1.pages.contact.path, className: 'c-btn-link' }, "Contact Us"),
                ".")));
    }
    User.accountClosed = accountClosed;
    var accountPending = (function (_super) {
        __extends(accountPending, _super);
        function accountPending(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                email: _this.props.email,
                is_working: false
            };
            return _this;
        }
        accountPending.prototype.requestVerificationToken = function () {
            var _this = this;
            this.setState({ is_working: true });
            request.get(lib_1.Global.cruzApiBaseUrl + '/user/request-verification-token/' + encodeURI(this.state.email))
                .timeout(cfg.superagent.timeout.normal)
                .retry(2)
                .end(function (err, apiRes) {
                if (err) {
                    _this.setState({ is_working: false });
                    lib_1.util.setToastMessage('error', apiRes.body.error.detail);
                }
                else {
                    _this.setState({ is_email_sent: true, is_working: false });
                }
            });
        };
        accountPending.prototype.renderRequestEmail = function () {
            var _this = this;
            return (React.createElement("div", { className: 'alert text-center' },
                React.createElement("p", null, "This email has been registered but not verified."),
                React.createElement("hr", { className: 'c-hr-1 w-50 mt-4 mb-4 mx-auto' }),
                React.createElement("p", null, "Click the button below to request an email to verify your account."),
                React.createElement("button", { type: 'button', className: 'btn c-btn-1 c-btn-round mt-3', onClick: function (e) { return _this.requestVerificationToken(); } }, this.state.is_working ? React.createElement(__1.BtnWorking, { value: 'Sending ...' }) : 'Send')));
        };
        accountPending.prototype.renderEmailSent = function () {
            var _this = this;
            return (React.createElement("div", { className: 'alert text-center' },
                lib_1.util.text2html(cfg.message.requestVerificationToken),
                React.createElement("button", { type: 'button', className: 'btn c-btn-1 c-btn-round mt-4', onClick: function (e) { return _this.requestVerificationToken(); } }, this.state.is_working ? React.createElement(__1.BtnWorking, { value: 'Sending ...' }) : 'Send Again')));
        };
        accountPending.prototype.render = function () {
            return (this.state.is_email_sent
                ?
                    this.renderEmailSent()
                :
                    this.renderRequestEmail());
        };
        return accountPending;
    }(React.Component));
    User.accountPending = accountPending;
})(User = exports.User || (exports.User = {}));
