"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_dom_1 = require("react-dom");
var react_router_dom_1 = require("react-router-dom");
var routes_1 = require("./routes/routes");
var lib_1 = require("./modules/lib");
require("./scss/global.scss");
require("bootstrap");
lib_1.Global.clientCreateStore();
react_dom_1.render((React.createElement(react_router_dom_1.BrowserRouter, null,
    React.createElement(routes_1.CruzRoutes, null))), document.querySelector('#app'));
