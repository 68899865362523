"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var _ = require("lodash");
var lib_1 = require("../lib");
var pages_1 = require("../../routes/pages");
var component_1 = require("../component");
var ServiceQuote;
(function (ServiceQuote) {
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            return _super.call(this, props) || this;
        }
        Page.prototype.componentDidMount = function () {
            document.title = lib_1.util.title(pages_1.pages.service_quote.title);
        };
        Page.prototype.render = function () {
            return (component_1.Layout.main(null, null, React.createElement(React.Fragment, null,
                React.createElement(component_1.Hero, { title: pages_1.pages.service_quote.title }),
                React.createElement("div", { className: 'container c-container' },
                    React.createElement("section", { className: 'mb-4' },
                        React.createElement("div", { className: 'd-flex justify-content-between' },
                            React.createElement("h1", { className: 'c-title' }, pages_1.pages.service_quote.title),
                            !_.isEmpty(lib_1.Global.store.getState().user)
                                &&
                                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.my_service_quote.path, className: 'btn c-btn-1-rev c-btn-xs ml-2' }, pages_1.pages.my_service_quote.title)),
                        React.createElement("hr", null)),
                    React.createElement("div", { className: 'row' },
                        React.createElement("div", { className: 'col-lg-7' },
                            React.createElement("img", { src: '/img/service/quote.png', className: 'd-none d-md-block img-fluid mb-sm-5' }),
                            React.createElement("img", { src: '/img/service/quote_sm.png', className: 'd-md-none img-fluid mb-5' })),
                        React.createElement("div", { className: 'col-lg-5' },
                            React.createElement("p", null, "From windshields to tires and everything in between, let CRUZ\u00AE do the shopping for you! Save yourself time and money by receiving multiple quotes from local shops on the service(s) you're in need of."),
                            React.createElement("div", { className: 'border bg-light p-4 rounded my-4' },
                                React.createElement("h2", { className: 'c-font-size-xxl mb-3' }, "How it Works:"),
                                React.createElement("ol", { className: 'mb-0' },
                                    React.createElement("li", { className: 'mb-1' }, "Choose Quote Type"),
                                    React.createElement("li", { className: 'mb-1' }, "Request Quote"),
                                    React.createElement("li", { className: 'mb-1' }, "Let CRUZ\u00AE Shop"),
                                    React.createElement("li", { className: 'mb-1' }, "Review Quotes"),
                                    React.createElement("li", { className: 'mb-0' }, "Select and Book"))),
                            React.createElement("p", null, "Simply click \"Request Quote\", answer a question or two, and wait for your quotes to roll in!"),
                            React.createElement("div", { className: 'mt-3 text-center text-md-left' },
                                React.createElement(react_router_dom_1.Link, { className: 'btn c-btn-1 mt-4', to: pages_1.pages.service_quote_request.path + this.props.location.search },
                                    React.createElement("i", { className: 'fal fa-plus' }),
                                    React.createElement("span", null, pages_1.pages.service_quote_request.title)))))))));
        };
        return Page;
    }(React.Component));
    ServiceQuote.Page = Page;
})(ServiceQuote = exports.ServiceQuote || (exports.ServiceQuote = {}));
