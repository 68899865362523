"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var lib_1 = require("./lib");
var pages_1 = require("../routes/pages");
var component_1 = require("./component");
var PrivacyPolicy;
(function (PrivacyPolicy) {
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            return _super.call(this, props) || this;
        }
        Page.prototype.componentDidMount = function () {
            document.title = lib_1.util.title(pages_1.pages.privacy_policy.title);
        };
        Page.prototype.componentWillUnmount = function () {
        };
        Page.prototype.renderContent = function () {
            return (React.createElement(React.Fragment, null,
                React.createElement("h4", null, "General:"),
                React.createElement("p", null,
                    "Cruz, Inc. (\u201CCruz\u00AE\u201D) believes it is important to be transparent about the privacy of your personal information.  The Cruz.com website and associated applications (the \"Website\") is comprised of various web pages, applications, and features operated by Cruz\u00AE. The terms \u201Cwe\u201D \u201Cour\u201D and \u201Cus\u201D refer to Cruz\u00AE, its subsidiaries and affiliates, and the terms \u201Cyou\u201D or \u201Cyour\u201D refer to any individuals who access this Website.  This privacy policy is intended to inform you about what kind of information we gather through our Website and what we do with that information.  By using this Website, or continuing to use this Website, you consent to this privacy policy in full and also to our ",
                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.terms_conditions.path, className: 'c-link-2' }, "Terms and Conditions"),
                    " of Use."),
                React.createElement("h4", null, "Overview of Information Collection Policy"),
                React.createElement("p", null, "In general, you can visit our Website without revealing any personally identifiable information about yourself to us.  We do not collect, or require individuals to transmit, any personally identifiable financial information (as defined under Federal Regulation P (12 C.F.R. \u00A7 1016.3(q)), social security numbers (SSNs), or driver\u2019s license numbers through our Website.  We also do not automatically collect any other personally identifiable information about you through our Website."),
                React.createElement("p", null, "Our Website automatically collects non-personally identifiable information about our Website users through our server logs, cookies, web beacons, and analytics tools, including:"),
                React.createElement("ul", null,
                    React.createElement("li", null, "the amount of time you spend on our Website, "),
                    React.createElement("li", null, "your browser type, "),
                    React.createElement("li", null, "the domain you visited our Website from, "),
                    React.createElement("li", null, "your IP address, and"),
                    React.createElement("li", null, "other similar non-personally identifiable information.")),
                React.createElement("p", null, "The technical information we automatically collect does not enable us to personally identify you and is used to better understand our Website traffic, analyze the interests of our Website users, improve our Website and diagnose technical errors, and for business or marketing purposes. The only personally identifiable information we collect is information you choose to provide us through voluntary forms and features on our Website."),
                React.createElement("h4", null, "Cookies, Web Beacons, and Server Logs"),
                React.createElement("p", null, "Our Website uses cookies and other similar technologies which are automatically generated, sent to your browser, and stored on your hard drive to record information about how and when you use our Website.  These cookies and other similar technologies identify a web browser on a specific computer or device and reveal certain non-personally identifiable information such as browser type, language, time zone, and operating system.  The cookies and other similar technologies used by our Website do not identify the user personally, but allow the user to remain anonymous.  Our Website may still be used with cookies disabled, but some Website functionality may be impaired."),
                React.createElement("p", null, "Our Website may also use web beacons or pixel tags and other similar technologies, which are not placed on your computer but instead are embedded on certain pages of our Website or in emails.  When you visit a page or read an email with a pixel tag, web beacon, or other similar technology, a generic notice of your interaction is generated."),
                React.createElement("p", null, "Non-personally identifiable information collected through our Website may be used in aggregate for statistical purposes, and some of our analytics and digital marketing tools may combine the non-personally identifiable information collected from our Website with non-personally identifiable information gathered from you through other websites for target advertising, remarketing, retargeting, or tracking referral purposes.  Our third-party vendors, like Google and Facebook, use cookies, web beacons, and similar technologies from our Website and generated from their systems, to: (i) serve ads to you based on past visits to our Website; (ii) to collect or receive information from our Website and elsewhere on the internet, and use that information to provide measurement services and target ads; and (iii) show you our ads on websites and apps across the Internet.  Your use of other websites and apps across the Internet is governed by the privacy policies of those websites and apps."),
                React.createElement("p", null, "Our servers also log non-personally identifiable information about our Website users and their interactions with our Website.  All non-personally identifiable information we gather using cookies, pixel tags, web beacons, server logs, or other similar technologies are used as described in this privacy policy.  For information on how to opt-out of having cookies, web beacons, and other similar technologies used on your web browser, please see the \u201COpting Out of Cookies, Web Beacons, and Similar Technologies\u201D section of this policy."),
                React.createElement("h4", null, "How We Use Non-Personally Identifiable Information"),
                React.createElement("p", null, "The non-personally identifiable information that is collected about your visit to our Website is used for analytics purposes to better understand our Website traffic, analyze the interests of our Website users, improve our Website and diagnose technical errors, and for business or marketing purposes as determined by us in our sole discretion.   This information may be aggregated and shared with or sold to third parties, but will not identify you personally.  However, the non-personally identifiable information we collect may become linked to personally identifiable information if you provide personally identifiable information to us through our Website.  Any non-personally identifiable information linked to personally identifiable information is subject to our personally identifiable information sharing practices detailed in the \u201CPersonally Identifiable Information Sharing\u201D section of this privacy policy."),
                React.createElement("h4", null, "Opting Out of Cookies, Web Beacons, and Similar Technologies"),
                React.createElement("p", null, "You can manage cookie preferences and opt out of having cookies and other similar collection technologies used by adjusting the settings on your web browser.  If you do not want cookies stored on your computer, you can change the settings on your browser to notify you when a cookie is about to be saved or block cookies entirely.  How to access these settings, which settings are available, and how to turn on these settings varies by browser type and we encourage you to further investigate your browser\u2019s privacy settings if you wish to opt out or adjust these settings in any way.  If you choose to decline or block cookies, you may not be able to fully utilize all of the interactive features of the Website."),
                React.createElement("p", null,
                    "You can opt out of a third-party vendor's use of cookies, web beacons, and similar technologies by visiting the ",
                    React.createElement("a", { href: 'http://www.networkadvertising.org/managing/opt_out.asp', target: '_blank', rel: 'noopener noreferrer', className: 'c-link-2' }, "Network Advertising Initiative opt-out page"),
                    ", or learn more about your choices for limiting or disabling these tools by visiting ",
                    React.createElement("a", { href: 'http://www.aboutads.info/choices', target: "_blank", rel: 'noopener noreferrer', className: 'c-link-2' }, "http://www.aboutads.info/choices"),
                    " and ",
                    React.createElement("a", { href: 'http://www.youronlinechoices.eu/', target: '_blank', rel: 'noopener noreferrer', className: 'c-link-2' }, "http://www.youronlinechoices.eu/"),
                    "."),
                React.createElement("p", null, "You cannot decline web beacons; however, setting your browser to decline cookies or to prompt you for a response, or installing an ad-blocker, may keep web beacons from tracking your activity."),
                React.createElement("h4", null, "Do Not Track Signals"),
                React.createElement("p", null, "Some browsers may send Do Not Track (DNT) signals to our Website.  Our Website does not currently respond to these DNT signals, and Website users are subject to the same information tracking and use as detailed in this privacy policy."),
                React.createElement("h4", null, "Collection of Personally Identifiable Financial Information and Other Personally Identifiable Information"),
                React.createElement("p", null, "We do not collect, or require individuals to transmit, any personally identifiable financial information (as defined under Federal Regulation P), social security numbers (SSNs), or driver\u2019s license numbers in connection with your use of this Website.  We do not collect other personally identifiable information through our Website unless you affirmatively and voluntarily provide this information to us through our Website features. For example, you may provide us personally identifiable information by completing contact forms, registering for or updating an account, adding your vehicle to the Website, or taking advantage of other voluntary services and features available through the Website."),
                React.createElement("p", null, "If you use certain Website features, we may ask for personally identifiable information about you, including, but not limited to:"),
                React.createElement("ul", null,
                    React.createElement("li", null, "demographic and biographic information, (age range, marital status, household composition, gender, etc.)"),
                    React.createElement("li", null, "contact information (name, address, city, state and zip code, email address, telephone number, etc.)"),
                    React.createElement("li", null, "business affiliations, "),
                    React.createElement("li", null, "vehicle information (make, model, VIN, buyer and seller, purchase price, dealer, etc.), "),
                    React.createElement("li", null, "survey responses,"),
                    React.createElement("li", null, "geolocation information,"),
                    React.createElement("li", null, "relationships you have with our affiliates or subsidiaries, and"),
                    React.createElement("li", null, "marketing related information (like when you plan to purchase a vehicle, products you may be interested in, etc.)")),
                React.createElement("p", null, "You are not required to use the Website features that require disclosure of personally identifiable information, and you do so entirely at your own discretion.  Our Website and any analytics tools utilized by our Website may link non-personally identifiable information with personally identifiable information you provide to us through our Website.  Any non-personally identifiable information linked to personally identifiable information is shared as personally identifiable information and subject to our personally identifiable information sharing practices.  Please see the \u201CPersonally Identifiable Information Sharing\u201D section of this privacy policy for more information on how we share your personally identifiable information."),
                React.createElement("h4", null, "Personally Identifiable Information Sharing"),
                React.createElement("p", null, "We will use the personally identifiable information that you provide to us in any way that is legally permissible, including, without limitation, to provide services requested by you, to contact you regarding products and services, to conduct research and surveys, and to otherwise enhance your user experience.  We may disclose your personally identifiable information if we believe it is necessary or useful to provide services to you or to inform you of other products or services available from us and our affiliates."),
                React.createElement("p", null, "Your personally identifiable information may be shared with our affiliates, subsidiaries, and third party service providers. We may share, sell, convey, rent, transfer, or lease our customer lists and any of your personally identifiable information to third parties.  Your personally identifiable information may be used for our own direct marketing purposes or the direct marketing purposes of third parties with whom we share your information. We reserve the right to share your personal information in any way that is legally permissible.  We also reserve the right to share your personally identifiable information in order to preserve our legal rights or comply with applicable law, as detailed below in the \u201CLegal Compliance\u201D section of this privacy policy."),
                React.createElement("p", null, "We may serve as a conduit for messages, chat, emails, and other communications between third parties and you.  We may store any of the information that you provide us indefinitely, including your personally identifiable information. This specifically includes, but is in no way limited to, the information you provide about yourself or a vehicle.  Please see the \u201CPersonally Identifiable Information Security\u201D section for more information about the security of your information and the \u201CChanging Personally Identifiable Information\u201D section for how you can change your personally identifiable information in our records."),
                React.createElement("p", null, "Notwithstanding the forgoing, we do not share or disclose certain information to other parties unless required under the Legal Compliance section of this privacy policy; this information includes credit card numbers, social security numbers, driver\u2019s license numbers, and personally identifiable financial information as defined under Federal Regulation P."),
                React.createElement("h4", null, "Personally Identifiable Information Security"),
                React.createElement("p", null, "We use information technology security measures to protect your personally identifiable information that is transmitted through our Website. We also use information technology security features to protect the personally identifiable information stored by us.  However, in spite of our security efforts and protocols, we cannot guarantee that information sent to us through our Website will be secure. Therefore, we make no warranties or representations regarding the security of your information."),
                React.createElement("h4", null, "Email Addresses and Phone Numbers"),
                React.createElement("p", null, "We and our third party service providers use email addresses collected through the Website to uniquely identify and communicate with users of our Website and services. If you provide your email address to us, we may share that information with third parties to contact you at your provided email address. We may also use your email address to contact you regarding: new features, ad status, confirmation of registration, and other service-related notices."),
                React.createElement("p", null, "Email addresses and phone numbers may be shared or sold as provided for in this privacy policy, as further detailed in the \u201CPersonally Identifiable Information Sharing\u201D section above."),
                React.createElement("h4", null, "User Profiles"),
                React.createElement("p", null, "Information that you provide in your user profile may be visible to the public.  If you do not want your profile information to be visible to the public, do not use this Website.  We may provide options for you to be able to control how much of the information in your profile is visible to the public, but we cannot guarantee these features will be available at all times or will be error-free.  Any limitations you place on your profile information will only be for purposes of display to the public when using certain Website features and will not limit or alter how we treat your information as described in this privacy policy."),
                React.createElement("h4", null, "Your California Privacy Rights"),
                React.createElement("p", null,
                    "California Civil Code section 1798.83 requires certain businesses to respond to requests from California customers asking about the businesses' practices related to disclosing personally identifiable information to third parties for the third parties' direct marketing purposes. California residents who provide personally identifiable information in obtaining products or services for personal, family, or household use are entitled to request and obtain from us once a calendar year information about the customer information we shared, if any, with other businesses for their own direct marketing purposes. In compliance with this law, California residents with whom we have an established business relationship are entitled to request and receive, free of charge, once per calendar year, a copy of Cruz\u2019s Information Sharing Disclosure Notice about the customer information we shared, if any, with other businesses for their own direct marketing purposes in the previous calendar year (e.g., requests made in 2018 will receive information regarding 2017 sharing activities).  To obtain this information on behalf of Cruz\u00AE, please send an email message to ",
                    React.createElement("a", { href: "mailto: privacy@cruz.com", className: 'c-link-2' }, "privacy@cruz.com"),
                    " with \"Request for California Privacy Information\" on the subject line and in the body of your message. We will provide the requested information to you at your email address.  Please be aware that not all information sharing is covered by the \u201CShine the Light\u201D law requirements and only information on covered sharing will be included in our response to your request."),
                React.createElement("h4", null, "Changing Personally Identifiable Information"),
                React.createElement("p", null, "It is important that we keep the most accurate, up-to-date information about you in our records.  You may contact us to change the personally identifiable information we have collected about you through your account user profile or \u201CMy Cruz\u201D section on our Website.  We may require you to verify your identity before we change our records."),
                React.createElement("h4", null, "Legal Compliance"),
                React.createElement("p", null, "We reserve the right in our sole discretion to disclose information about you without notice, including personally identifiable information, if it is necessary or appropriate to comply with applicable law, respond to legal claims and the legal process (including subpoenas), protect our rights, interests, or property, or if we believe in good faith that it is necessary to protect the personal safety of any person, including but not limited to: your safety, the safety of our other users or our employees, the safety of the public, or the safety of any third party."),
                React.createElement("h4", null, "Personally Identifiable Information of Children"),
                React.createElement("p", null,
                    "Our Website is not directed to anyone under the age of 18, and by using this Website, you are confirming that you are 18 years of age or older, or are over the age of 13 and using this Website with the consent and supervision of a parent or guardian.  If a parent or guardian is aware or becomes aware that his or her child has provided us with personally identifiable information without consent, please contact us at ",
                    React.createElement("a", { href: "mailto: privacy@cruz.com", className: 'c-link-2' }, "privacy@cruz.com"),
                    ", so we can remove the unauthorized information from our records."),
                React.createElement("p", null,
                    "This Website does not knowingly contact or collect personally identifiable information from children under the age of 13.  This Website is not intended to solicit information of any kind from children under the age of 13.  We do not knowingly collect or store information of children under 13, even in aggregate form.  It is possible that through fraud or deceit we may receive information pertaining to children under the age of 13. Parents or guardians may report unauthorized use of our Website by a child under age 13 by contacting us at ",
                    React.createElement("a", { href: "mailto: privacy@cruz.com", className: 'c-link-2' }, "privacy@cruz.com"),
                    ". If we are notified of unauthorized use by a child under 13, upon verifying the information, we will immediately delete the unauthorized information from our servers and records."),
                React.createElement("h4", null, "Links, Widgets, Embeds, Social Media, and Other Third Party Features"),
                React.createElement("p", null, "Our Website may provide links to other websites for the convenience of our Website users.  Our Website may also make use of widgets, embeds, social media, or other third party features that are hosted by a third party or hosted directly on our Website.  Your interactions with any link or third party widget, embed, social media, or other feature are governed by the privacy policy of the third party providing the widget, embed, social media, or other third party feature, or the privacy policy of the linked website.  The privacy policies of third parties may be substantially different than this privacy policy, and any personally identifiable information collected by or provided to third parties will be guided by the privacy policies of such third parties. We neither own nor control third party websites, widgets, embeds, social media, or other features that are accessible through this Website. Therefore, before visiting the website of a third party, or making use of a third party feature on our Website, whether by means of widget, embed, social media feature, or otherwise, you should first consult that third party\u2019s privacy policy (if any) and inform yourself of the privacy practices (if any) used by that third party."),
                React.createElement("h4", null, "Business Transactions"),
                React.createElement("p", null, "If we undergo any type of business transaction, including but not limited to an asset sale, merger, acquisition, bankruptcy, or restructuring, your personally identifiable information and/or technical information we obtain from you through this Website may be disclosed to any potential or actual purchasers and may be transferred to the new owners."),
                React.createElement("h4", null, "Policy Changes"),
                React.createElement("p", null, "We reserve the right to change this privacy policy at any time without advance notice to you.  Each time any content in our privacy policy changes, we will update the effective date on the privacy policy available through our Website.  Your use of the Website after the effective date constitutes your consent to be subject to our then-current and active privacy policy. We encourage you to review our privacy policy for every new or continued use of our Website."),
                React.createElement("h4", null, "Opt-Out & Unsubscribe from Communications"),
                React.createElement("p", null, "We respect your privacy and give you an opportunity to opt-out of receiving certain communications from us. Users may opt-out of receiving certain email communications from Cruz\u00AE by clicking on the unsubscribe link in any marketing or promotional email that you receive from us.  Your receipt of certain communications and alerts from Cruz\u00AE may also be altered through your user profile."),
                React.createElement("h4", null, "How to Contact Us"),
                React.createElement("p", null,
                    "If you have questions about our privacy policy or seek to contact us in any way about our privacy policy, please email us at ",
                    React.createElement("a", { href: "mailto: privacy@cruz.com", className: 'c-link-2' }, "privacy@cruz.com"),
                    "."),
                React.createElement("p", null, "Effective Date: 11/22/2017")));
        };
        Page.prototype.render = function () {
            return (component_1.Layout.main(null, null, React.createElement(React.Fragment, null,
                React.createElement(component_1.Hero, { title: pages_1.pages.privacy_policy.title }),
                React.createElement("div", { className: 'container c-container' },
                    React.createElement("section", { className: 'mb-4' },
                        React.createElement("h1", { className: 'c-title' }, pages_1.pages.privacy_policy.title)),
                    this.renderContent()))));
        };
        return Page;
    }(React.Component));
    PrivacyPolicy.Page = Page;
})(PrivacyPolicy = exports.PrivacyPolicy || (exports.PrivacyPolicy = {}));
