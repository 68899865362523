"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var request = require("superagent");
var _ = require("lodash");
var moment = require("moment");
var pages_1 = require("../../routes/pages");
var cfg = require("../../cfg");
var discussion_search_1 = require("./discussion_search");
var component_1 = require("../component");
var lib_1 = require("../lib");
var DiscussionDetail;
(function (DiscussionDetail) {
    function getDiscussionDetail(_id, callback) {
        lib_1.Auth.isAuthed(function () {
            request.get(lib_1.Global.cruzApiBaseUrl + '/community/' + _id + '?page_size=' + cfg.commentPageSize.discussion.detail)
                .timeout(cfg.superagent.timeout.normal)
                .set(lib_1.Auth.authHeader())
                .retry(2)
                .end(function (err, apiRes) {
                return callback(err, apiRes);
            });
        });
    }
    DiscussionDetail.getDiscussionDetail = getDiscussionDetail;
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            var _this = _super.call(this, props) || this;
            _this.trackViewTimeout = null;
            _this.state = {
                _id: _this.props.match.params._id,
                is_loading: false,
                is_working: false
            };
            return _this;
        }
        Page.prototype.componentWillMount = function () {
            var _this = this;
            lib_1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    if (_this.props.staticContext) {
                        var d = _.assign({}, _this.props.staticContext);
                        _this.setState({ feed: d.result, is_loading: false });
                    }
                    else {
                        var $initData = document.querySelector('#_init_discussion_detail');
                        if ($initData && $initData.getAttribute('data-init')) {
                            var d = JSON.parse(decodeURIComponent($initData.getAttribute('data-init')));
                            $initData.remove();
                            _this.setState({ feed: d, is_loading: false });
                            _this.trackView();
                        }
                        else {
                            _this.init();
                        }
                    }
                }
                else {
                    lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                }
            });
        };
        Page.prototype.componentDidMount = function () {
            this.setPageTitle();
        };
        Page.prototype.componentWillUpdate = function () {
            this.setPageTitle();
        };
        Page.prototype.componentWillUnmount = function () {
            this.trackViewTimeout && clearTimeout(this.trackViewTimeout);
        };
        Page.prototype.setPageTitle = function () {
            document.title = lib_1.util.title(pages_1.pages.discussion_detail.title, _.isEmpty(this.state.feed) ? null : this.state.feed.title);
        };
        Page.prototype.trackView = function () {
            var _this = this;
            this.trackViewTimeout = setTimeout(function () {
                lib_1.Tracking.trackView({ name: 'community_feed', _id: _this.state._id }, function (err, result) {
                    if (!err && !_.isEmpty(result)) {
                        _this.updateDone(result);
                    }
                });
            }, 500);
        };
        Page.prototype.updateDone = function (d) {
            var _this = this;
            this.setState({ feed: _.assign({}, this.state.feed, d) }, function () {
                lib_1.Statistic.refreshStatistic();
                _this.updateExternalStore(d);
            });
        };
        Page.prototype.updateExternalStore = function (d) {
            if (discussion_search_1.DiscussionSearch.store) {
            }
        };
        Page.prototype.init = function () {
            var _this = this;
            this.setState({ is_loading: true }, function () {
                getDiscussionDetail(_this.state._id, function (err, apiRes) {
                    if (err) {
                        lib_1.util.handleError(lib_1.Request.getApiErrorCode(err));
                    }
                    else {
                        _this.setState({ is_loading: false, feed: apiRes.body.data }, function () {
                            _this.trackView();
                        });
                    }
                });
            });
        };
        Page.prototype.onCommentDone = function (d) {
        };
        Page.prototype.voteDone = function (d) {
            var _this = this;
            this.setState({ feed: _.assign({}, this.state.feed, d) }, function () {
                _this.updateExternalStore(d);
            });
        };
        Page.prototype.renderVote = function () {
            var d = this.state.feed;
            return (React.createElement(component_1.Vote.LikeButton, { name: 'community_feed', _id: d._id, user_id: d.user_id, vote: d.vote, onVoteDone: this.voteDone.bind(this), className: 'btn c-btn-3 c-btn-xs' }));
        };
        Page.prototype.renderHeading = function (d, isMine) {
            return (React.createElement("div", { className: 'c-detail-heading' },
                React.createElement("div", { className: 'd-lg-flex align-items-end' },
                    React.createElement("div", { className: 'pr-lg-5' },
                        React.createElement(react_router_dom_1.Link, { to: pages_1.pages.discussion_search.path, className: 'c-category c-btn-link' },
                            pages_1.pages.discussion_search.title,
                            React.createElement("i", { className: 'fal fa-angle-double-right ml-2' })),
                        React.createElement("h1", { className: 'title mt-1' }, d.title),
                        React.createElement("div", { className: 'c-font-size-xs mt-2' },
                            React.createElement("span", null, 'Posted ' + moment(d.date_create).fromNow()),
                            d.date_update
                                &&
                                    React.createElement("span", null,
                                        React.createElement("span", { className: 'separator' }),
                                        React.createElement("span", null, 'Updated ' + moment(d.date_update).fromNow())))),
                    React.createElement("div", { className: 'ml-lg-auto mt-2 mt-lg-0 pl-0 pl-lg-5 c-toolbar flex-nowrap' },
                        component_1.Stat.views(d.view_count),
                        this.renderVote(),
                        isMine
                            &&
                                React.createElement("div", { className: 'd-flex flex-nowrap' },
                                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.discussion_add_edit.path + '?_id=' + d._id, className: 'btn c-btn-1 c-btn-xs' }, "Edit"))))));
        };
        Page.prototype.renderFeedDetail = function () {
            var d = this.state.feed;
            var my_user_id = !_.isEmpty(lib_1.Global.store.getState().user) ? lib_1.Global.store.getState().user._id : null;
            var isMine = (my_user_id != null && my_user_id == d.user_id);
            return (React.createElement("div", { className: 'flex-ch-100' },
                this.renderHeading(d, isMine),
                React.createElement("div", { className: 'row no-gutters flex-rh-100' },
                    React.createElement("div", { className: 'col-12 col-xl pb-3' },
                        (d.tag_list && d.tag_id_list.length > 0)
                            &&
                                React.createElement("div", { className: 'c-detail-content' }, component_1.Discussion.renderTagList(d)),
                        (d.content && d.content != '')
                            &&
                                React.createElement("div", { className: 'c-detail-content pb-4 pt-2' }, lib_1.util.text2html(d.content)),
                        component_1.PhotoView.riGallery(d.title, cfg.photoBaseUrl[process.env.NODE_ENV].cdn + '/community/' + d.group + '/' + d._id + '/', d.photo_list, true),
                        React.createElement("hr", { className: 'c-hr my-0' }),
                        React.createElement("div", { className: 'my-3 c-detail-content' },
                            React.createElement("div", { className: 'c-toolbar' },
                                component_1.Stat.views(d.view_count),
                                (my_user_id == null || my_user_id == d.user_id)
                                    &&
                                        React.createElement("div", { className: 'c-stat' },
                                            React.createElement("i", { className: 'fal fa-heart mr-2' }),
                                            React.createElement("span", { className: 'c-count-prepend' }, d.vote.up ? d.vote.up.count.toLocaleString() : '0'),
                                            React.createElement("span", { className: 'name' },
                                                "Like",
                                                (d.vote.up.count > 1) ? 's' : '')),
                                (my_user_id != null && my_user_id != d.user_id)
                                    &&
                                        this.renderVote())),
                        React.createElement("hr", { className: 'c-hr' }),
                        React.createElement("div", { className: 'c-detail-content pb-4' },
                            React.createElement(component_1.Discussion.FeedComment, { discussion_id: d._id, result: d.comments, query: { page_size: cfg.commentPageSize.discussion.detail }, onCommentDone: this.onCommentDone.bind(this) }))),
                    React.createElement("div", { className: 'col-12 c-col-right' }))));
        };
        Page.prototype.render = function () {
            return (component_1.Layout.main(((this.state && !_.isEmpty(this.state.feed)) ? this.state.feed.user_display : null), null, React.createElement("div", { className: 'flex-rh-100 container c-detail-container' }, (this.state.is_loading || _.isEmpty(this.state.feed))
                ?
                    React.createElement(component_1.Loading, null)
                :
                    this.renderFeedDetail())));
        };
        return Page;
    }(React.Component));
    DiscussionDetail.Page = Page;
})(DiscussionDetail = exports.DiscussionDetail || (exports.DiscussionDetail = {}));
