"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var _ = require("lodash");
var lib_1 = require("../../lib");
var PasswordInput = (function (_super) {
    __extends(PasswordInput, _super);
    function PasswordInput(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            showPassword: false
        };
        return _this;
    }
    PasswordInput.prototype.toggleView = function () {
        this.setState({
            showPassword: !this.state.showPassword
        });
    };
    PasswordInput.prototype.render = function () {
        var _this = this;
        try {
            return (React.createElement("div", { className: this.props['data-grid'] },
                React.createElement("div", { className: lib_1.util.formGroupClass(this.props) },
                    React.createElement("div", { className: 'input-group' },
                        this.state.showPassword
                            ?
                                React.createElement('input', lib_1.util.inputProps(_.assign({}, this.props, { type: 'text' })))
                            :
                                React.createElement('input', lib_1.util.inputProps(_.assign({}, this.props, { type: 'password' }))),
                        (this.props.label != null && this.props.label != '')
                            &&
                                React.createElement("label", { htmlFor: this.props.id, className: lib_1.util.formLabelClass(this.props) }, this.props.label),
                        React.createElement("div", { className: 'input-group-append' },
                            React.createElement("button", { type: 'button', className: 'btn c-btn-1', onClick: function (e) { return _this.toggleView(); }, tabIndex: -1 },
                                React.createElement("i", { className: this.state.showPassword ? 'far fa-lg fa-fw fa-eye-slash' : 'far fa-lg fa-fw fa-eye' })))),
                    React.createElement("span", { className: 'form-control-feedback', "aria-hidden": true }, this.props['data-err']))));
        }
        catch (err) {
            lib_1.util.logError(err, null);
            return null;
        }
    };
    return PasswordInput;
}(React.Component));
exports.PasswordInput = PasswordInput;
