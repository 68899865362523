"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var jwt_decode = require("jwt-decode");
var request = require("superagent");
var _ = require("lodash");
var uri = require("urijs");
var cfg = require("../../../cfg");
var lib_1 = require("../../lib");
var pages_1 = require("../../../routes/pages");
var component_1 = require("../../component");
var RequestVerificationToken;
(function (RequestVerificationToken) {
    var validate = require('validate.js');
    var rules = {
        'email': {
            presence: {
                message: '^Email is required.', allowEmpty: false
            },
            email: {
                message: '^Invalid email format.', allowEmpty: false
            }
        }
    };
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {};
            try {
                var locSearch = new uri(_this.props.location.search).search(true);
                if (locSearch) {
                    if (locSearch.email) {
                        _this.state = { email: locSearch.email };
                    }
                    else if (locSearch.token) {
                        var tk = jwt_decode(locSearch.token.toString());
                        if (tk && tk['email']) {
                            _this.state = { email: tk['email'] };
                        }
                    }
                }
            }
            catch (err) {
            }
            return _this;
        }
        Page.prototype.componentDidMount = function () {
            if (lib_1.util.isClient()) {
                document.title = lib_1.util.title(pages_1.pages.request_verification_token.title);
                lib_1.util.setFocus('#email');
            }
        };
        Page.prototype.componentWillUnmount = function () {
        };
        Page.prototype.getError = function (key) {
            if (this.state && this.state.validation != null && this.state.validation[key]) {
                return this.state.validation[key][0];
            }
            else {
                return null;
            }
        };
        Page.prototype.valid = function () {
            this.setState({ validation: validate(this.state, rules) });
        };
        Page.prototype.emailChanged = function (tgt) {
            var _this = this;
            this.setState({ email: tgt.value }, function () {
                _this.state.validation && _this.valid();
            });
        };
        Page.prototype.submit = function (e) {
            var _this = this;
            e && e.preventDefault();
            var vd = validate(this.state, rules);
            this.setState({ validation: vd });
            if (!this.state.is_working && (vd == null || _.isEmpty(vd) == true)) {
                this.setState({ is_working: true });
                request.get(lib_1.Global.cruzApiBaseUrl + '/user/request-verification-token/' + encodeURI(this.state.email))
                    .timeout(cfg.superagent.timeout.normal)
                    .retry(2)
                    .end(function (err, apiRes) {
                    _this.setState({ is_working: false });
                    if (err) {
                        lib_1.util.setToastMessage('error', apiRes.body.error.detail);
                    }
                    else {
                        lib_1.Global.onSubmitSuccess('requestVerificationEmail');
                        lib_1.util.setToastMessage('success', cfg.message.requestVerificationToken);
                    }
                });
            }
        };
        Page.prototype.render = function () {
            var _this = this;
            return (component_1.Layout.entry(1, React.createElement("div", { className: 'mt-3' },
                React.createElement("h1", { className: 'c-section-title text-center' }, "Account Verification"),
                React.createElement("p", { className: 'text-center mt-3' },
                    "Please enter your email below.",
                    React.createElement("br", null),
                    "We'll send you an email to verify your account."),
                React.createElement("form", { className: 'mt-4 mx-auto', id: 'formRequestVerificationEmail', onSubmit: function (e) { return _this.submit(e); }, noValidate: true },
                    React.createElement(component_1.Text, { id: 'email', required: true, type: 'email', label: 'Email', "data-err": this.getError('email'), value: this.state.email || '', onChange: function (e) { return _this.emailChanged(e.target); } }),
                    React.createElement("div", { id: "_msg" }),
                    React.createElement("button", { type: 'submit', className: 'btn c-btn-1 btn-block mt-4' }, this.state.is_working ? React.createElement(component_1.BtnWorking, { value: 'Sending ...' }) : 'Send')))));
        };
        return Page;
    }(React.Component));
    RequestVerificationToken.Page = Page;
})(RequestVerificationToken = exports.RequestVerificationToken || (exports.RequestVerificationToken = {}));
