"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var Redux = require("redux");
var react_router_dom_1 = require("react-router-dom");
var request = require("superagent");
var react_dropzone_1 = require("react-dropzone");
var _ = require("lodash");
var uri = require("urijs");
var filesize = require("filesize");
var async = require("async");
var cfg = require("../../../cfg");
var pages_1 = require("../../../routes/pages");
var lib_1 = require("../../lib");
var component_1 = require("../../component");
var MyProfile;
(function (MyProfile) {
    var initState = {
        userInput: {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            location: {
                address: '',
                zip_code: ''
            },
        },
        validationServer: {
            email: null,
            zip_code: null
        },
        is_loading: true,
        is_working: false,
        is_dirty: false,
        upload: {
            file_list: [],
            total_size: 0,
            is_uploading: false,
            progress: 0,
        }
    };
    var dirtyKeys = [
        'first_name', 'last_name', 'email', 'phone', 'location.address', 'location.zip_code'
    ];
    var validate = require('validate.js');
    var rules = {
        first_name: {
            presence: { message: '^First name is required.', allowEmpty: false },
        },
        last_name: {
            presence: { message: '^Last name is required.', allowEmpty: false }
        },
        'location.zip_code': {
            presence: { message: '^Zip Code is required.', allowEmpty: false }
        },
    };
    var uploader = null;
    function getMyProfile(callback) {
        lib_1.Request.user.myProfile(function (err, apiRes) {
            return callback(err, apiRes);
        });
    }
    MyProfile.getMyProfile = getMyProfile;
    var app = function (state, action) {
        var _a;
        var tgt = action.target;
        function valid() {
            state.validation = validate(state.userInput, rules);
            return;
        }
        function setGlobalUser(d) {
            lib_1.Global.store.dispatch({ type: lib_1.Global.actions.setUser, data: d });
        }
        function isDirty() {
            state.is_dirty = !_.isEqual(state.prevUser, _.pick(state.userInput, dirtyKeys));
            return;
        }
        switch (action.type) {
            case 0: {
                lib_1.util.setToastMessage('error', action.error.detail);
                state.is_working = false;
                return state;
            }
            case 1: {
                state.is_loading = true;
                getMyProfile(function (err, apiRes) {
                    err ? store.dispatch({ type: 0, error: apiRes.body ? apiRes.body.error : err }) : store.dispatch({ type: 2, data: apiRes.body.data });
                });
                return state;
            }
            case 2: {
                try {
                    var d = _.pickBy(action.data, _.identity);
                    state.is_loading = false;
                    state.userInput = _.assign({}, d);
                    state.prevUser = _.memoize(_.pick)(d, dirtyKeys);
                    state.userInput.location = {
                        address: d.location.address ? d.location.address : '',
                        zip_code: d.location.zip_code
                    };
                    if (state.userInput.photo_url && state.userInput.photo_url != '') {
                        var pUri = new uri(state.userInput.photo_url);
                        pUri.setSearch('ts', new Date().getTime().toString());
                        state.userInput.photo_url = pUri.toString();
                    }
                    return state;
                }
                catch (err) {
                    return state;
                }
            }
            case 3: {
                try {
                    if (action.data && action.data.length > 0) {
                        lib_1.Auth.isAuthed(function (isAuthed) {
                            if (isAuthed) {
                                state.upload.is_uploading = true;
                                uploader = request.post(lib_1.Global.cruzApiBaseUrl + '/user/photo')
                                    .set(lib_1.Auth.authHeader())
                                    .timeout(cfg.superagent.timeout.upload);
                                async.waterfall([
                                    function (next) {
                                        state.upload.file_list = action.data;
                                        lib_1.util.resize(action.data[0], 600, 600, function (err, blob) {
                                            if (err) {
                                                state.upload.total_size = state.upload.file_list[0].size;
                                                uploader.attach('photo', state.upload.file_list[0], function () {
                                                });
                                            }
                                            else {
                                                state.upload.total_size = blob.size;
                                                uploader.attach('photo', blob);
                                            }
                                            next();
                                        });
                                    },
                                    function (next) {
                                        uploader.on('progress', function (pe) {
                                            store.dispatch({ type: 4, data: Math.round(pe['percent']) });
                                        }).end(function (err, apiRes) {
                                            if (err) {
                                                store.dispatch({ type: 6, error: err });
                                            }
                                            else {
                                                store.dispatch({ type: 5, data: apiRes.body.data });
                                            }
                                        });
                                    }
                                ]);
                            }
                            else {
                                lib_1.Auth.redirectSignin(pages_1.pages.my_profile.path);
                            }
                        });
                    }
                    return state;
                }
                catch (err) {
                    return state;
                }
            }
            case 4: {
                state.upload.progress = action.data;
                return state;
            }
            case 5: {
                if (action.data) {
                    state.userInput.photo_url = uri(action.data.photo_url).path() + '?ts=' + new Date().getTime();
                    setGlobalUser({ photo_url: state.userInput.photo_url });
                    lib_1.CruzSocket.reconnect();
                }
                state.upload = _.assign({}, initState.upload);
                return state;
            }
            case 6: {
                state.upload = _.assign({}, initState.upload);
                return state;
            }
            case 7: {
                lib_1.Auth.isAuthed(function (isAuthed) {
                    if (isAuthed) {
                        request.delete(lib_1.Global.cruzApiBaseUrl + '/user/photo')
                            .timeout(cfg.superagent.timeout.normal)
                            .set(lib_1.Auth.authHeader())
                            .end(function (err, apiRes) {
                            err ? store.dispatch({ type: 0, error: apiRes.body.error }) : store.dispatch({ type: 8 });
                        });
                    }
                    else {
                        lib_1.Auth.redirectSignin(pages_1.pages.my_profile.path);
                    }
                });
                return state;
            }
            case 8: {
                state.userInput.photo_url = '';
                setGlobalUser({ photo_url: null });
                lib_1.CruzSocket.reconnect();
                return state;
            }
            case 9: {
                state.userInput = _.assign({}, state.userInput, (_a = {}, _a[tgt.id] = tgt.value, _a));
                isDirty();
                state.validation && valid();
                return state;
            }
            case 10: {
                state.userInput.location.address = tgt.value;
                isDirty();
                return state;
            }
            case 11: {
                if (tgt.value == null || tgt.value == '') {
                    state.userInput.location.zip_code = '';
                }
                else {
                    var zip_code = lib_1.Mask.UnmaskedValue(tgt.value, lib_1.Mask.TypeEnum.ZipCode);
                    state.userInput.location.zip_code = zip_code;
                    if (zip_code.length == 5) {
                        lib_1.Request.geo.getZipCode(zip_code, function (err, apiRes) {
                            if (!err && apiRes && apiRes.body) {
                                !_.isEmpty(apiRes.body.data)
                                    ?
                                        store.dispatch({ type: 12, data: null })
                                    :
                                        store.dispatch({ type: 12, data: 'Invalid Zip Code' });
                            }
                        });
                    }
                }
                state.validation && valid();
                isDirty();
                return state;
            }
            case 12: {
                state.validationServer.zip_code = action.data;
                return state;
            }
            case 13: {
                action.event.preventDefault();
                valid();
                if (state.is_dirty && !state.is_working && (state.validation == null || _.isEmpty(state.validation) == true) && state.validationServer.email == null && state.validationServer.zip_code == null) {
                    lib_1.Auth.isAuthed(function (isAuthed) {
                        if (isAuthed) {
                            request.put(lib_1.Global.cruzApiBaseUrl + '/user/my-profile')
                                .set(lib_1.Auth.authHeader())
                                .timeout(cfg.superagent.timeout.normal)
                                .send(state.userInput)
                                .end(function (err, apiRes) {
                                if (err) {
                                    store.dispatch({ type: 0, error: apiRes.body.error });
                                }
                                else {
                                    lib_1.Global.onSubmitSuccess('myProfile');
                                    store.dispatch({ type: 14, data: apiRes.body.data });
                                }
                            });
                        }
                        else {
                            lib_1.Auth.redirectSignin(pages_1.pages.my_profile.path);
                        }
                    });
                    state.is_working = true;
                    return state;
                }
                else {
                    if (!_.isEmpty(state.validation)) {
                        lib_1.util.setFocus('#' + _.last(_.keys(state.validation)[0].split('.')));
                    }
                    else if (state.validationServer.email != null) {
                        lib_1.util.setFocus('#email');
                    }
                    else if (state.validationServer.zip_code != null) {
                        lib_1.util.setFocus('#zip_code');
                    }
                    return state;
                }
            }
            case 14: {
                setGlobalUser(action.data);
                var user = _.pickBy(action.data, _.identity);
                state.userInput = _.assign({}, user);
                state.prevUser = _.memoize(_.pick)(user, dirtyKeys);
                state.is_dirty = false;
                if (!_.isEmpty(user.access_token)) {
                    lib_1.Auth.setAccessToken(user.access_token);
                }
                lib_1.CruzSocket.reconnect();
                lib_1.util.setToastMessage('success', 'Successfully Updated');
                state.is_working = false;
                return state;
            }
            case 15: {
                return JSON.parse(JSON.stringify(initState));
            }
            default:
                return state;
        }
    };
    var store = Redux.createStore(app, JSON.parse(JSON.stringify(initState)));
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            return _super.call(this, props) || this;
        }
        Page.prototype.componentWillMount = function () {
            var _this = this;
            lib_1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    store.dispatch({ type: 1 });
                }
                else {
                    lib_1.Auth.redirectSignin(_this.props.location.pathname);
                }
            });
        };
        Page.prototype.componentDidMount = function () {
            var _this = this;
            document.title = lib_1.util.title(pages_1.pages.my_profile.title);
            this.unsubscribe = store.subscribe(function () { _this.forceUpdate(); });
        };
        Page.prototype.componentWillUnmount = function () {
            store.dispatch({ type: 15 });
            this.unsubscribe && this.unsubscribe();
        };
        Page.prototype.openSelectPhoto = function () {
            var s = this.refs['dropzone'];
            s.open();
        };
        Page.prototype.cancelUpload = function () {
            if (uploader) {
                uploader.abort();
                store.dispatch({ type: 5 });
            }
        };
        Page.prototype.getError = function (key, isServer) {
            if (isServer && store.getState().validationServer != null && store.getState().validationServer[key]) {
                return store.getState().validationServer[key];
            }
            else if (store.getState().validation && store.getState().validation[key]) {
                return store.getState().validation[key][0];
            }
            return null;
        };
        Page.prototype.renderForm = function () {
            var userInput = store.getState().userInput;
            var upload = store.getState().upload;
            return (React.createElement("form", { id: 'formMyProfile', onSubmit: function (e) { return store.dispatch({ type: 13, event: e }); }, noValidate: true },
                React.createElement("div", { className: 'card card-form mt-3' },
                    React.createElement("div", { className: 'card-body' },
                        React.createElement("div", { className: 'd-lg-none d-flex justify-content-center mb-4' },
                            React.createElement("div", { className: 'c-code d-inline-flex align-items-center' },
                                lib_1.util.myQRCode(),
                                React.createElement("div", { className: 'px-3' },
                                    React.createElement("div", { className: 'c-font-size-xs mb-1' }, "CRUZ\u00AE Code:"),
                                    React.createElement("div", { className: 'c-font-size-l c-code-value' }, lib_1.Global.store.getState().user.code)))),
                        React.createElement("div", { className: 'row' },
                            React.createElement("div", { className: 'col-lg-5' },
                                React.createElement("div", { className: 'c-photo-edit' },
                                    React.createElement(react_dropzone_1.default, { ref: "dropzone", multiple: false, disableClick: true, accept: 'image/jpeg, image/png, image/gif, image/tiff, image/bmp', disablePreview: false, className: 'dropzone mb-4 mb-lg-0', activeClassName: 'active', onDrop: function (e) { return store.dispatch({ type: 3, data: e }); } },
                                        React.createElement("div", { className: 'toolbar text-center mb-4' },
                                            React.createElement("span", null, (userInput.photo_url && userInput.photo_url) != '' ? 'To replace avatar, ' : 'To add avatar, '),
                                            React.createElement("button", { type: 'button', className: 'btn c-btn-1 c-btn-xs c-btn-round', onClick: this.openSelectPhoto.bind(this) }, "Select"),
                                            React.createElement("span", null, " or Drag and drop your photo here.")),
                                        React.createElement("div", { className: 'text-center mb-4' },
                                            React.createElement("img", { src: (userInput.photo_url && userInput.photo_url != '')
                                                    ?
                                                        cfg.photoBaseUrl[cfg.env].s3 + '/' + store.getState().userInput.photo_url
                                                    :
                                                        '/img/default_profile.png', className: 'img-profile rounded-circle' })),
                                        upload.is_uploading
                                            &&
                                                React.createElement("div", { className: 'upload-single' },
                                                    React.createElement("div", { className: 'mt-4 mb-4' },
                                                        React.createElement("img", { src: upload.file_list.length > 0 ? upload.file_list[0].preview : '', className: 'img-profile' })),
                                                    React.createElement("div", { className: 'upload-progress' },
                                                        React.createElement(component_1.Progress, { value: upload.progress.toString() }),
                                                        upload.progress == 100
                                                            &&
                                                                React.createElement("div", { className: 'c-blink mb-1' },
                                                                    "Processing photo ",
                                                                    React.createElement("span", null, "."),
                                                                    React.createElement("span", null, "."),
                                                                    React.createElement("span", null, ".")),
                                                        upload.total_size > 0
                                                            &&
                                                                React.createElement("span", null,
                                                                    upload.progress && !_.isNaN(upload.progress)
                                                                        &&
                                                                            React.createElement("span", null, upload.progress.toString() + '% of '),
                                                                    React.createElement("span", null, filesize(upload.total_size, { round: 0 }))),
                                                        upload.progress < 100
                                                            &&
                                                                React.createElement("button", { type: 'button', className: 'btn c-btn-2 c-btn-xs c-btn-round ml-2', onClick: this.cancelUpload.bind(this) }, "Cancel"))),
                                        (userInput.photo_url && userInput.photo_url != '' && !upload.is_uploading) &&
                                            React.createElement(component_1.Delete.InPageConfirm, { question: 'Are you sure you want delete your avatar?', className: 'text-center', onYes: function (e) { return store.dispatch({ type: 7 }); } })))),
                            React.createElement("div", { className: 'col-lg-7' },
                                React.createElement("div", { className: 'd-none d-lg-flex justify-content-center mb-4' },
                                    React.createElement("div", { className: 'c-code d-inline-flex align-items-center' },
                                        lib_1.util.myQRCode(),
                                        React.createElement("div", { className: 'px-3' },
                                            React.createElement("div", { className: 'c-font-size-xs mb-1' }, "CRUZ\u00AE Code:"),
                                            React.createElement("div", { className: 'c-font-size-l c-code-value' }, lib_1.Global.store.getState().user.code)))),
                                React.createElement("hr", { className: 'c-hr d-none d-lg-block mb-4' }),
                                React.createElement("div", { className: 'row' },
                                    React.createElement("div", { className: 'col-sm-6' },
                                        React.createElement("div", { className: 'form-group has-value' },
                                            React.createElement("div", { className: 'input-group' },
                                                React.createElement("input", { type: 'text', className: 'form-control', id: 'email', value: userInput.email, disabled: true }),
                                                React.createElement("label", { htmlFor: 'email', className: 'control-label' }, "Email"),
                                                React.createElement("div", { className: 'input-group-append' },
                                                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.change_email.path, className: 'btn c-btn-1' },
                                                        React.createElement("i", { className: 'far fa-pen' })))))),
                                    React.createElement(component_1.MaskedText, { type: 'tel', id: 'phone', label: 'Phone', mask: lib_1.Mask.TypeEnum.Phone, "data-grid": 'col-sm-6', value: userInput.phone || '', onChange: function (e) { return store.dispatch({ type: 9, target: e.target }); } })),
                                React.createElement("div", { className: 'row' },
                                    React.createElement(component_1.Text, { id: 'first_name', label: 'First Name', required: true, "data-err": this.getError('first_name'), "data-grid": 'col-sm-6', value: userInput.first_name || '', onChange: function (e) { return store.dispatch({ type: 9, target: e.target }); } }),
                                    React.createElement(component_1.Text, { id: 'last_name', label: 'Last Name', required: true, "data-err": this.getError('last_name'), "data-grid": 'col-sm-6', value: userInput.last_name || '', onChange: function (e) { return store.dispatch({ type: 9, target: e.target }); } })),
                                React.createElement("div", { className: 'row' },
                                    React.createElement(component_1.Text, { id: 'address', label: 'Street Address', "data-grid": 'col-sm-6', value: userInput.location.address || '', onChange: function (e) { return store.dispatch({ type: 10, target: e.target }); } }),
                                    React.createElement(component_1.MaskedText, { type: 'tel', id: 'zip_code', label: 'Zip Code', mask: lib_1.Mask.TypeEnum.ZipCode, required: true, value: userInput.location.zip_code || '', "data-err": this.getError('zip_code', true) || this.getError('location.zip_code'), "data-grid": 'col-sm-6', onChange: function (e) { return store.dispatch({ type: 11, target: e.target }); } }))))),
                    React.createElement("div", { className: 'card-footer text-right' },
                        React.createElement("button", { type: 'submit', className: 'btn c-btn-1' }, store.getState().is_working ? React.createElement(component_1.BtnWorking, { value: 'Saving ...' }) : 'Save')))));
        };
        Page.prototype.render = function () {
            return (component_1.Layout.main(lib_1.Global.store.getState().user, null, React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'container c-container' },
                    React.createElement("h1", { className: 'c-title' }, pages_1.pages.my_profile.title),
                    !store || store.getState().is_loading
                        ?
                            React.createElement(component_1.Loading, null)
                        :
                            this.renderForm()),
                React.createElement(component_1.Dirty, { when: store && store.getState().is_dirty }))));
        };
        return Page;
    }(React.Component));
    MyProfile.Page = Page;
})(MyProfile = exports.MyProfile || (exports.MyProfile = {}));
