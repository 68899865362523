"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var Dropzone = require("react-dropzone/dist");
var async = require("async");
var _ = require("lodash");
var classNames = require("classnames");
var request = require("superagent");
var __1 = require("..");
var filesize = require("filesize");
var sortablejs_1 = require("sortablejs");
var __2 = require("..");
var cfg = require("../../../cfg");
var lib_1 = require("../../lib");
var Photo;
(function (Photo) {
    var PhotoEdit = (function (_super) {
        __extends(PhotoEdit, _super);
        function PhotoEdit(props) {
            var _this = _super.call(this, props) || this;
            _this.sortPhotoList = null;
            _this.uploader = null;
            _this.api_url = null;
            _this.api_url = _this.props.api_url;
            _this.state = {
                file_list: [],
                blob_list: [],
                is_resizing: false,
                is_uploading: false,
                is_deleting: false,
                total_size: 0,
                progress: 0,
                blur_lp: _this.props.blur_lp_checked
            };
            return _this;
        }
        PhotoEdit.prototype.componentDidMount = function () {
            this.initPhotoSortable();
        };
        PhotoEdit.prototype.componentWillReceiveProps = function (nextProps) {
            if (nextProps.api_url) {
                this.api_url = nextProps.api_url;
                nextProps.executeUpload && this.uploadFile();
            }
        };
        PhotoEdit.prototype.componentDidUpdate = function () {
            this.initPhotoSortable();
        };
        PhotoEdit.prototype.onDropAccepted = function (file_list) {
            var _this = this;
            if (file_list && file_list.length > 0) {
                this.setState({ is_resizing: true });
                var blob_list_1 = [];
                async.each(file_list, function (file, nf) {
                    try {
                        if (file.name) {
                            lib_1.util.resize(file, 2048, 2048, function (err, blob) {
                                blob['name'] = file.name;
                                if (err) {
                                    blob_list_1.push(file);
                                }
                                else {
                                    file.preview = window.URL.createObjectURL(blob);
                                    blob_list_1.push(blob);
                                }
                                nf();
                            });
                        }
                        else {
                            nf();
                        }
                    }
                    catch (err) {
                        nf();
                    }
                }, function () {
                    var all_file_list = _.union(_this.state.file_list, file_list);
                    var all_blob_list = _.union(_this.state.blob_list, blob_list_1);
                    _this.setState({ file_list: all_file_list, blob_list: all_blob_list, is_resizing: false }, function () {
                        _this.props.onUploadFileChanged && _this.props.onUploadFileChanged(all_blob_list);
                    });
                });
            }
        };
        PhotoEdit.prototype.selectFile = function (preview) {
            var file = _.find(this.state.file_list, { preview: preview });
            file.selected ? file.selected = false : file.selected = true;
            this.forceUpdate();
        };
        PhotoEdit.prototype.deleteFile = function () {
            var _this = this;
            var file_list = _.filter(this.state.file_list, function (file) { return !file.selected; });
            var filenames = _.map(file_list, 'name');
            var blob_list = this.state.blob_list.filter(function (blob) {
                return filenames.indexOf(blob['name']) > -1;
            });
            this.setState({ file_list: file_list, blob_list: blob_list }, function () {
                _this.props.onUploadFileChanged && _this.props.onUploadFileChanged(blob_list);
            });
        };
        PhotoEdit.prototype.uploadFile = function () {
            var _this = this;
            if (this.api_url && this.api_url != '' && !this.state.is_uploading) {
                lib_1.Auth.isAuthed(function (isAuthed) {
                    if (isAuthed) {
                        _this.props.onWorking && _this.props.onWorking(true);
                        var total_size_1 = 0;
                        _this.uploader = request.post(_this.api_url + '?blur_lp=' + _this.state.blur_lp)
                            .set(lib_1.Auth.authHeader())
                            .timeout(cfg.superagent.timeout.upload);
                        async.eachSeries(_this.state.blob_list, function (blob, nf) {
                            total_size_1 += blob.size;
                            _this.uploader.attach('photo', blob);
                            nf();
                        }, function () {
                            _this.setState({ is_uploading: true, total_size: total_size_1 }, function () {
                                _this.uploader.on('progress', function (pevt) {
                                    _this.setState({ progress: Math.round(pevt['percent']) });
                                }).end(function (err, apiRes) {
                                    if (err) {
                                        lib_1.util.logError(err, null);
                                        lib_1.util.setToastMessage('error', err.detail);
                                        _this.setState({ is_uploading: false, progress: 0 });
                                    }
                                    else {
                                        _this.uploadFileDone(_.get(apiRes, 'body.data'));
                                    }
                                    _this.uploader = null;
                                });
                            });
                        });
                    }
                    else {
                        lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                    }
                });
            }
        };
        PhotoEdit.prototype.uploadFileDone = function (d) {
            var _this = this;
            this.setState({
                file_list: [],
                blob_list: [],
                is_uploading: false,
                total_size: 0,
                progress: 0,
            }, function () {
                _this.props.onUploadFileDone(d);
            });
        };
        PhotoEdit.prototype.cancelUpload = function () {
            this.uploader.abort();
            this.props.onWorking && this.props.onWorking(false);
            this.setState({ is_uploading: false });
        };
        PhotoEdit.prototype.sortPhoto = function () {
            if (this.props.onSortPhoto) {
                this.props.onSortPhoto(_.map($('#photo_list').find('.c-img-wrap'), 'id'));
            }
        };
        PhotoEdit.prototype.selectPhoto = function (id) {
            if (_.size(this.props.photo_list) > 1 || (_.size(this.props.photo_list) == 1 && !this.props.required)) {
                var x = _.find(this.props.photo_list, { id: id });
                x.selected ? x.selected = false : x.selected = true;
                this.forceUpdate();
            }
        };
        PhotoEdit.prototype.unSelectAllPhoto = function () {
            _.map(this.props.photo_list, function (x) {
                return _.assign(x, { selected: false });
            });
            this.forceUpdate();
        };
        PhotoEdit.prototype.deletePhoto = function () {
            var _this = this;
            var pe = this;
            lib_1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    _this.props.onWorking && _this.props.onWorking(true);
                    pe.setState({ is_deleting: true }, function () {
                        var delete_id_list = _.map(_.filter(pe.props.photo_list, function (photo) { return photo.selected; }), 'id');
                        request.delete(pe.props.api_url)
                            .set(lib_1.Auth.authHeader())
                            .timeout(cfg.superagent.timeout.normal)
                            .send(delete_id_list)
                            .end(function (err, apiRes) {
                            pe.setState({ is_deleting: false }, function () {
                                pe.props.onDeletePhotoDone(apiRes.body.data);
                            });
                        });
                    });
                }
                else {
                    lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                }
            });
        };
        PhotoEdit.prototype.openSelectFile = function () {
            var s = this.refs['dropzone'];
            s.open();
        };
        PhotoEdit.prototype.getPhotoListClassNames = function () {
            return classNames(this.props.photo_list_classNames ? this.props.photo_list_classNames : 'col-md-3 col-sm-4 col-xs-6');
        };
        PhotoEdit.prototype.initPhotoSortable = function () {
            var _this = this;
            if (!this.sortPhotoList && typeof this.props.onSortPhoto == 'function' && _.size(this.props.photo_list) > 1) {
                try {
                    this.sortPhotoList = sortablejs_1.default.create(document.querySelector('#photo_list'), {
                        animation: 150,
                        onEnd: function (e) {
                            _this.sortPhoto();
                        }
                    });
                }
                catch (err) {
                }
            }
        };
        PhotoEdit.prototype.blurlpChanged = function (tgt) {
            this.setState({
                blur_lp: tgt.checked
            });
        };
        PhotoEdit.prototype.render = function () {
            var _this = this;
            try {
                var selected_file_count = _.filter(this.state.file_list, function (x) { return x.selected; }).length;
                var selected_photo_count = _.filter(this.props.photo_list, function (x) { return x.selected; }).length;
                var pe_1 = this;
                return (React.createElement("div", { className: classNames('c-photo-edit', lib_1.util.formGroupClass(this.props)) },
                    React.createElement(Dropzone, { ref: 'dropzone', multiple: true, disableClick: true, accept: 'image/jpeg, image/jpg, image/png, image/tiff, image/webp', disablePreview: true, className: 'dropzone', activeClassName: 'active', onDrop: function (file_list) { return _this.onDropAccepted(file_list); } },
                        (!this.state.is_uploading && !this.state.is_resizing)
                            &&
                                React.createElement("div", { className: 'toolbar text-center mt-1 mb-1' },
                                    React.createElement("span", null, "To upload photo(s), please "),
                                    React.createElement("button", { type: 'button', className: 'btn c-btn-1 c-btn-xs c-btn-round', onClick: function () { _this.openSelectFile(); } },
                                        React.createElement("i", { className: 'fal fa-plus' }),
                                        React.createElement("span", null, "Select")),
                                    React.createElement("span", null, " or Drag and drop your photo(s) here."),
                                    React.createElement("div", { id: 'photo_msg', className: 'mt-3' }),
                                    this.props.min_size &&
                                        React.createElement("div", { className: 'mt-3 c-help' },
                                            "* Minimum dimension is ",
                                            this.props.min_size.width,
                                            "px (width) and ",
                                            this.props.min_size.height,
                                            "px (height)."),
                                    (this.props.blur_lp && this.state.file_list.length == 0)
                                        &&
                                            React.createElement("div", { className: 'd-inline-block ml-auto mr-auto mt-1' },
                                                React.createElement(__2.Checkbox, { id: 'blur_lp', label: 'Blur License Plate', className: 'mb-0', checked: this.state.blur_lp || false, onChange: function (e) { return _this.blurlpChanged(e.target); } }))),
                        (this.state.is_resizing)
                            &&
                                React.createElement("div", { className: 'text-center' },
                                    React.createElement("div", { className: 'c-loading' },
                                        React.createElement("i", { className: 'far fa-spinner fa-pulse fa-2x' }),
                                        React.createElement("div", { className: 'c-blink mt-2' },
                                            "Resizing photo ",
                                            React.createElement("span", null, "."),
                                            React.createElement("span", null, "."),
                                            React.createElement("span", null, ".")))),
                        (_.size(this.state.file_list) > 0)
                            &&
                                React.createElement("div", { className: 'upload-multiple mt-3' },
                                    React.createElement("div", { className: 'row img-list' }, _.map(this.state.file_list, function (file) {
                                        var id = _.uniqueId(file.name + '_');
                                        return (React.createElement("div", { key: id, className: pe_1.getPhotoListClassNames() },
                                            React.createElement("div", { className: classNames('c-img-wrap', file.selected ? 'selected' : ''), onClick: function (e) { return pe_1.selectFile(file.preview); } },
                                                React.createElement("img", { src: file.preview }))));
                                    })),
                                    React.createElement("div", { className: 'clearfix' }),
                                    this.state.is_uploading
                                        ?
                                            React.createElement("div", { className: 'upload-progress' },
                                                React.createElement(__1.Progress, { value: this.state.progress.toString() }),
                                                React.createElement("div", null,
                                                    (this.state.total_size > 0)
                                                        &&
                                                            React.createElement("span", null,
                                                                (this.state.progress && !_.isNaN(this.state.progress) && this.state.progress.toString() != 'NaN' && this.state.progress > 0)
                                                                    &&
                                                                        React.createElement("span", { className: 'mr-2' }, this.state.progress.toString() + '% of'),
                                                                React.createElement("span", null, filesize(this.state.total_size, { round: 0 }))),
                                                    (pe_1.props._id && !_.isNaN(this.state.progress) && this.state.progress < 100)
                                                        &&
                                                            React.createElement("button", { type: 'button', className: 'btn c-btn-2 c-btn-xs c-btn-round ml-2', onClick: pe_1.cancelUpload.bind(pe_1) },
                                                                React.createElement("i", { className: 'fal fa-times' }),
                                                                React.createElement("span", null, "Cancel")),
                                                    (pe_1.props._id && this.state.progress == 100)
                                                        &&
                                                            React.createElement("span", { className: 'c-blink c-font-color-2 ml-3' },
                                                                "Processing photo ",
                                                                React.createElement("span", null, "."),
                                                                React.createElement("span", null, "."),
                                                                React.createElement("span", null, "."))))
                                        :
                                            React.createElement("div", { className: 'toolbar text-center' },
                                                (this.state.file_list.length > 0) &&
                                                    React.createElement("div", { className: 'mt-3 mb-3 c-help' }, "* To delete photo(s), please select the photo(s) then click the \"Delete\" button."),
                                                (this.props.blur_lp && selected_file_count == 0)
                                                    &&
                                                        React.createElement("div", null,
                                                            React.createElement("div", { className: 'd-inline-block ml-auto mr-auto mt-1 mb-2' },
                                                                React.createElement(__2.Checkbox, { id: 'blur_lp', label: 'Blur License Plate', checked: this.state.blur_lp || false, onChange: function (e) { return _this.blurlpChanged(e.target); } }))),
                                                (selected_file_count > 0)
                                                    ?
                                                        React.createElement("button", { type: 'button', className: 'btn c-btn-2 c-btn-xs', onClick: function () { return pe_1.deleteFile(); } }, 'Delete ' + selected_file_count + ' Photo' + (selected_file_count > 1 ? 's' : ''))
                                                    :
                                                        (pe_1.props._id) &&
                                                            React.createElement("button", { type: 'button', className: 'btn c-btn-1 c-btn-xs c-btn-round', onClick: function (e) { return pe_1.uploadFile(); } },
                                                                React.createElement("i", { className: 'fal fa-upload' }),
                                                                React.createElement("span", null, 'Upload ' + this.state.file_list.length + ' Photo' + (this.state.file_list.length > 1 ? 's' : '')))))),
                    (pe_1.props.photo_list && pe_1.props.photo_list.length > 0)
                        &&
                            React.createElement("div", { className: 'mt-3' },
                                React.createElement("div", { className: 'row img-list', id: 'photo_list' }, _.map(pe_1.props.photo_list, function (photo) {
                                    return (React.createElement("div", { key: photo.id, className: pe_1.getPhotoListClassNames() },
                                        React.createElement("div", { id: photo.id, className: classNames('c-img-wrap', photo.selected ? 'selected' : ''), onClick: function () { return pe_1.selectPhoto(photo.id); } },
                                            React.createElement("img", { src: pe_1.props.photo_base_url + '/' + photo.id + '-md.jpg' }))));
                                })),
                                React.createElement("div", { className: 'clearfix' }),
                                React.createElement("div", { className: 'toolbar text-center' },
                                    (pe_1.props.photo_list.length == 1 && !pe_1.props.required) &&
                                        React.createElement("div", { className: 'mt-2 mb-3 c-help' }, "* To delete photo(s), please select the photo(s) then click the \"Delete\" button."),
                                    (pe_1.props.photo_list.length > 1) &&
                                        React.createElement("div", { className: 'mt-2 mb-3 c-help' },
                                            "* Drag and drop to arrange the photos.",
                                            React.createElement("br", null),
                                            "** To delete photo(s), please select the photo(s) then click the \"Delete\" button."),
                                    (pe_1.props.required && selected_photo_count == pe_1.props.photo_list.length) ?
                                        React.createElement("div", { className: 'c-alert c-alert-error' }, "Can't delete! At least one photo is required.")
                                        :
                                            (selected_photo_count > 0 && !pe_1.state.is_deleting)
                                                &&
                                                    React.createElement(__1.Delete.InPageConfirm, { onYes: function () { return pe_1.deletePhoto(); }, onCancel: this.unSelectAllPhoto.bind(this), count: selected_photo_count }),
                                    pe_1.state.is_deleting &&
                                        React.createElement("div", { className: 'upload-progress mt-2 c-blink' },
                                            "Deleting ",
                                            React.createElement("span", null, "."),
                                            React.createElement("span", null, "."),
                                            React.createElement("span", null, "."))),
                                React.createElement("hr", { className: 'd-lg-none' })),
                    React.createElement("span", { className: 'form-control-icon' }),
                    React.createElement("span", { className: 'form-control-feedback' }, pe_1.props['data-err'])));
            }
            catch (err) {
                lib_1.util.logError(err, null);
                return null;
            }
        };
        return PhotoEdit;
    }(React.Component));
    Photo.PhotoEdit = PhotoEdit;
})(Photo = exports.Photo || (exports.Photo = {}));
