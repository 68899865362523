"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var request = require("superagent");
var Redux = require("redux");
var _ = require("lodash");
var $ = require("jquery");
var pages_1 = require("../../../routes/pages");
var cfg = require("../../../cfg");
var request_1 = require("./request");
var __1 = require("..");
var Global;
(function (Global) {
    if (_.isEmpty(process.env)) {
        console.log(cfg.cruzAscii);
    }
    if (typeof window != 'undefined' && window.document) {
        (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
                'gtm.start': new Date().getTime(), event: 'gtm.js'
            });
            var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
            j['async'] = true;
            j['src'] =
                'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', cfg.gtmKey[cfg.env]);
    }
    function onSubmitSuccess(elementId) {
        try {
            window['dataLayer'] = window['dataLayer'] || [];
            window['dataLayer'].push({
                event: 'formSubmitSuccess',
                'gtm.elementId': elementId
            });
        }
        catch (err) {
        }
    }
    Global.onSubmitSuccess = onSubmitSuccess;
    Global.cruzApiBaseUrl = cfg.apiBaseUrl[cfg.env].cruz;
    Global.history = null;
    Global.temp_access_token = null;
    Global.referral_code = null;
    Global.store = null;
    var actions;
    (function (actions) {
        actions[actions["setUser"] = 0] = "setUser";
        actions[actions["setUserSettings"] = 1] = "setUserSettings";
        actions[actions["setNewItem"] = 2] = "setNewItem";
        actions[actions["setHasNewMessage"] = 3] = "setHasNewMessage";
        actions[actions["setNewNotification"] = 4] = "setNewNotification";
        actions[actions["setToastMessage"] = 5] = "setToastMessage";
        actions[actions["setSocketStatus"] = 6] = "setSocketStatus";
        actions[actions["setSocketDisconnectTime"] = 7] = "setSocketDisconnectTime";
    })(actions = Global.actions || (Global.actions = {}));
    var app = function (state, action) {
        switch (action.type) {
            case actions.setUser: {
                state.user = action.data ? _.assign({}, state.user, action.data) : {};
                return state;
            }
            case actions.setUserSettings: {
                state.user.settings = _.assign({}, state.user.settings, action.data);
                return state;
            }
            case actions.setHasNewMessage: {
                state.has_new_message = action.data;
                return state;
            }
            case actions.setNewNotification: {
                state.user.notification = _.assign({}, state.user.notification, action.data);
                if (state.user.notification.new_notification_count > 0) {
                    var _pno = {
                        body: 'You have a new notification.',
                        link: pages_1.pages.notification.path,
                        icon: '/favicon-32x32.png',
                        timeout: 120000
                    };
                    __1.util.pushNotification('Notification', _pno, _pno.link);
                }
                return state;
            }
            case actions.setToastMessage: {
                state.toast_message = action.data;
                return state;
            }
            case actions.setSocketStatus: {
                state.socket_status = action.data;
                return state;
            }
            case actions.setSocketDisconnectTime: {
                state.socket_disconnect_time = action.data;
                return state;
            }
            default:
                return state;
        }
    };
    function serverCreateStore(d, s) {
        Global.store = Redux.createStore(app, d);
        __1.Statistic.store = Redux.createStore(__1.Statistic.app, { statistic: s });
    }
    Global.serverCreateStore = serverCreateStore;
    function clientCreateStore() {
        if (typeof init_global != 'undefined' && !_.isEmpty(init_global)) {
            Global.store = Redux.createStore(app, init_global);
            $('#PRELOAD_STATE').empty();
            if (typeof init_statistic != 'undefined' && !_.isEmpty(init_statistic)) {
                __1.Statistic.store.dispatch({ type: __1.Statistic.actions.setStatistic, data: init_statistic });
                $('#PRELOAD_STATE_STATISTIC').empty();
            }
        }
        else {
            var d = {
                user: {},
                toast_message: {}
            };
            Global.store = Redux.createStore(app, d);
            __1.Statistic.refreshStatistic();
        }
    }
    Global.clientCreateStore = clientCreateStore;
    function refreshMyProfile() {
        request_1.Request.user.myProfile(function (err, apiRes) {
            if (!err && apiRes) {
                Global.store.dispatch({ type: actions.setUser, data: apiRes.body.data });
            }
        });
    }
    Global.refreshMyProfile = refreshMyProfile;
    function getUserSettings(callback) {
        __1.Auth.isAuthed(function (isAuthed) {
            if (isAuthed) {
                request.get(Global.cruzApiBaseUrl + '/user/settings')
                    .timeout(cfg.superagent.timeout.normal)
                    .set(__1.Auth.authHeader())
                    .end(function (err, apiRes) {
                    return callback(err ? null : apiRes.body.data);
                });
            }
            else {
                return callback(null);
            }
        });
    }
    Global.getUserSettings = getUserSettings;
    function saveUserSettings(d) {
        if (!_.isEmpty(d)) {
            __1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    request.put(Global.cruzApiBaseUrl + '/user/settings')
                        .timeout(cfg.superagent.timeout.normal)
                        .set(__1.Auth.authHeader())
                        .send(d)
                        .end(function (err, apiRes) {
                        if (!err) {
                            Global.store.dispatch({ type: actions.setUserSettings, data: d });
                        }
                    });
                }
            });
        }
    }
    Global.saveUserSettings = saveUserSettings;
    function getMyCruzPath() {
        var defaultPath = pages_1.pages.my_vehicle.path;
        try {
            return Global.store.getState().user.settings.my_cruz_path || defaultPath;
        }
        catch (err) {
            return defaultPath;
        }
    }
    Global.getMyCruzPath = getMyCruzPath;
    function refreshSavedFilter() {
        request_1.Request.saved.vehicle_filter.get(function (err, apiRes) {
            if (!err && apiRes.body) {
                Global.store.dispatch({ type: actions.setUser, data: { saved_vehicle_filter_list: apiRes.body.data } });
            }
        });
    }
    Global.refreshSavedFilter = refreshSavedFilter;
})(Global = exports.Global || (exports.Global = {}));
