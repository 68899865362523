"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var classNames = require("classnames");
var _ = require("lodash");
var MaskedTextInput = require('react-text-mask').default;
var lib_1 = require("../../lib");
var MaskedText = (function (_super) {
    __extends(MaskedText, _super);
    function MaskedText(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { showHelp: false };
        return _this;
    }
    MaskedText.prototype.onFocus = function (e) {
        if (e) {
            e.target.select();
        }
    };
    MaskedText.prototype.render = function () {
        try {
            var inputProps = _.assign({ role: 'textbox', autoComplete: 'off' }, lib_1.util.inputProps(this.props), { mask: lib_1.Mask.Type[this.props.mask], guide: true });
            return (React.createElement("div", { className: this.props['data-grid'] },
                React.createElement("div", { className: lib_1.util.formGroupClass(this.props) },
                    (this.props['data-input-pre-addon'] || this.props['data-input-addon'])
                        ?
                            React.createElement("div", { className: 'input-group' },
                                this.props['data-input-pre-addon']
                                    &&
                                        React.createElement("div", { className: 'input-group-prepend' },
                                            React.createElement("span", { className: 'input-group-text' }, this.props['data-input-pre-addon'])),
                                React.createElement("div", { className: classNames('input-group-main', { 'has-pre-addon': this.props['data-input-pre-addon'] }, { 'has-addon': this.props['data-input-addon'] }) },
                                    React.createElement(MaskedTextInput, __assign({}, inputProps)),
                                    this.props.label
                                        &&
                                            React.createElement("label", { htmlFor: this.props.id, className: lib_1.util.formLabelClass(this.props) }, this.props.label)),
                                this.props['data-input-addon']
                                    &&
                                        React.createElement("div", { className: 'input-group-append' },
                                            React.createElement("span", { className: 'input-group-text' }, this.props['data-input-addon'])))
                        :
                            React.createElement(React.Fragment, null,
                                React.createElement(MaskedTextInput, __assign({}, inputProps)),
                                this.props.label
                                    &&
                                        React.createElement("label", { htmlFor: this.props.id, className: lib_1.util.formLabelClass(this.props) }, this.props.label)),
                    React.createElement("span", { className: 'form-control-feedback', "aria-hidden": true }, this.props['data-err']))));
        }
        catch (err) {
            lib_1.util.logError(err, null);
            return null;
        }
    };
    return MaskedText;
}(React.Component));
exports.MaskedText = MaskedText;
