"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var request = require("superagent");
var _ = require("lodash");
var moment = require("moment");
var cfg = require("../../../cfg");
var lib_1 = require("../../lib");
var __1 = require("..");
var CommentReply;
(function (CommentReply) {
    function isContentValid(content) {
        return (content && content.trim() != '');
    }
    var Reply = (function (_super) {
        __extends(Reply, _super);
        function Reply(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                comment_id: _this.props.comment_id,
                reply_list: [],
                status: {},
                create: {},
                update: {},
                reply_to_id: null,
                my_user_id: !_.isEmpty(lib_1.Global.store.getState().user) ? lib_1.Global.store.getState().user._id : null
            };
            return _this;
        }
        Reply.prototype.componentDidMount = function () {
            var _this = this;
            this.setState({ status: { is_loading: true } }, function () {
                _this.loadReply();
            });
        };
        Reply.prototype.handleError = function (err, apiResBody) {
            this.setState({ status: {} });
            if (apiResBody) {
                var errMsg = _.get(apiResBody, 'error.detail');
                if (errMsg) {
                    lib_1.util.setToastMessage('error', errMsg);
                }
            }
        };
        Reply.prototype.loadReply = function () {
            var _this = this;
            lib_1.Auth.isAuthed(function () {
                request.get(_this.props.api_base_url)
                    .set(lib_1.Auth.authHeader())
                    .timeout(cfg.superagent.timeout.normal)
                    .end(function (err, apiRes) {
                    if (err) {
                        _this.handleError(err, apiRes.body);
                    }
                    else {
                        var d_1 = _.assign({}, apiRes.body.data);
                        _this.setState({
                            status: {},
                            reply_list: d_1.reply_list
                        }, function () {
                            (_this.props.reply_count != d_1.reply_count && _this.props.onReplyCountUpdate)
                                &&
                                    _this.props.onReplyCountUpdate(d_1._id, d_1.reply_count);
                        });
                    }
                });
            });
        };
        Reply.prototype.renderCreateForm = function (parent_id) {
            var _this = this;
            if (_.isEmpty(lib_1.Global.store.getState().user)) {
                return null;
            }
            else {
                return (React.createElement(React.Fragment, null,
                    React.createElement(__1.Textarea, { placeholder: 'Reply', name: 'newReply', minRows: 1, maxRows: 10, value: this.state.create.content || '', onChange: function (e) { return _this.createReplyContentChanged(e.target); } }),
                    React.createElement("div", { className: 'text-right' },
                        parent_id != null
                            &&
                                React.createElement("button", { type: 'button', className: 'btn c-btn-2 c-btn-xs mr-2', onClick: function () { return _this.toggleReply(parent_id); } }, "Cancel"),
                        React.createElement("button", { type: 'button', className: 'btn c-btn-1 c-btn-xs', onClick: function () { return _this.createReply(parent_id); } }, this.state.status.is_creating ? React.createElement(__1.BtnWorking, { value: 'Replying ...' }) : 'Reply'))));
            }
        };
        Reply.prototype.createReplyContentChanged = function (tgt) {
            this.setState({ create: { content: tgt.value } });
        };
        Reply.prototype.createReply = function (parent_id) {
            var _this = this;
            if (!this.state.status.is_creating && isContentValid(this.state.create.content)) {
                this.setState({ status: { is_creating: true } }, function () {
                    lib_1.Auth.isAuthed(function (isAuthed) {
                        if (isAuthed) {
                            request.post(_this.props.api_base_url + (parent_id ? '/' + parent_id : ''))
                                .set(lib_1.Auth.authHeader())
                                .send({ content: _this.state.create.content })
                                .timeout(cfg.superagent.timeout.normal)
                                .end(function (err, apiRes) {
                                if (err) {
                                    _this.handleError(err, apiRes.body);
                                }
                                else {
                                    var d = apiRes.body.data;
                                    _this.setState({ status: {}, reply_list: d.reply_list, create: {}, reply_to_id: null });
                                    _this.props.onReplyCountUpdate && _this.props.onReplyCountUpdate(d._id, d.reply_count);
                                }
                            });
                        }
                        else {
                            lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                        }
                    });
                });
            }
        };
        Reply.prototype.toggleReply = function (id) {
            var _this = this;
            lib_1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    _this.setState({ reply_to_id: (_this.state.reply_to_id == id ? null : id) });
                }
                else {
                    lib_1.util.setModal(null, false, false, React.createElement(__1.Modal.RequestAuth, null));
                }
            });
        };
        Reply.prototype.updateReply = function (id) {
            this.setState({
                update: _.pick(_.find(this.state.reply_list, { id: id }), ['id', 'content']),
                reply_to_id: null
            });
        };
        Reply.prototype.updateReplyContentChanged = function (tgt) {
            this.setState({
                update: _.assign({}, this.state.update, { content: tgt.value })
            });
        };
        Reply.prototype.renderUpdateForm = function () {
            var _this = this;
            try {
                return (React.createElement(React.Fragment, null,
                    React.createElement(__1.Textarea, { placeholder: 'Reply', name: 'updateReply', minRows: 1, maxRows: 10, value: this.state.update.content || '', onChange: function (e) { return _this.updateReplyContentChanged(e.target); }, "data-err": !_.isEmpty(this.state.update.err) ? this.state.update.err.detail : null }),
                    React.createElement("div", { className: 'text-right' },
                        React.createElement("button", { type: 'button', className: 'btn c-btn-2 c-btn-xs mr-2', onClick: function (e) { return _this.setState({ update: {} }); } }, "Cancel"),
                        React.createElement("button", { type: 'button', className: 'btn c-btn-1 c-btn-xs', onClick: function (e) { return _this.saveUpdate(); } }, this.state.status.is_updating ? React.createElement(__1.BtnWorking, { value: 'Saving ...' }) : 'Save'))));
            }
            catch (err) {
                lib_1.util.logError(err, null);
                return null;
            }
        };
        Reply.prototype.saveUpdate = function () {
            var _this = this;
            if (!this.state.status.is_updating && this.state.update.content && this.state.update.content.trim() != '') {
                lib_1.Auth.isAuthed(function (isAuthed) {
                    if (isAuthed) {
                        _this.setState({ status: { is_updating: true } }, function () {
                            request.put(_this.props.api_base_url + '/' + _this.state.update.id)
                                .set(lib_1.Auth.authHeader())
                                .send({ content: _this.state.update.content })
                                .timeout(cfg.superagent.timeout.normal)
                                .end(function (err, apiRes) {
                                if (err) {
                                    _this.setState({ status: {} });
                                    _this.handleError(err, apiRes.body);
                                }
                                else {
                                    var d = apiRes.body.data;
                                    if (d) {
                                        var x = _.find(_this.state.reply_list, { id: d.id });
                                        x && _.assign(x, d);
                                    }
                                    _this.setState({ status: {}, update: {} });
                                }
                            });
                        });
                    }
                    else {
                        lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                    }
                });
            }
        };
        Reply.prototype.openDeleteDialog = function (id) {
            var _this = this;
            lib_1.util.setModal(null, false, true, React.createElement(__1.Delete.Dialog, { id: id, onYes: function () { return _this.deleteYes(id); } }));
        };
        Reply.prototype.deleteYes = function (id) {
            var _this = this;
            lib_1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    request.delete(_this.props.api_base_url + '/' + id)
                        .set(lib_1.Auth.authHeader())
                        .timeout(cfg.superagent.timeout.normal)
                        .end(function (err, apiRes) {
                        if (err) {
                            _this.handleError(err, apiRes.body);
                        }
                        else {
                            try {
                                var d = apiRes.body.data;
                                _this.setState({ status: {}, reply_list: d.reply_list });
                                _this.props.onReplyCountUpdate && _this.props.onReplyCountUpdate(d._id, d.reply_count);
                            }
                            catch (err) {
                                _this.handleError(err, null);
                            }
                        }
                    });
                }
                else {
                    lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                }
            });
        };
        Reply.prototype.voteDone = function (d) {
            var x = _.find(this.state.reply_list, { id: d.id });
            if (x) {
                _.assign(x, d);
                this.forceUpdate();
            }
        };
        Reply.prototype.renderReply = function (d) {
            var _this = this;
            try {
                var isMine = (this.state.my_user_id && this.state.my_user_id == d.user_id);
                var ud = d.user_display;
                var udParent = (d.parent && d.parent.user_display) ? d.parent.user_display : null;
                return (React.createElement("div", { key: d.id, className: 'd-flex c-reply', id: 'reply_' + d.id },
                    React.createElement("div", { className: 'mr-1' }, __1.User.renderUserIcon(ud)),
                    React.createElement("div", { className: 'flex-grow ml-2' },
                        React.createElement("div", { className: 'c-ud-summary ellipsis' },
                            React.createElement("div", { className: 'ellipsis' },
                                React.createElement("span", { className: 'c-name' }, ud.first_name + ' ' + ud.last_name),
                                React.createElement("span", { className: 'c-font-size-xs ml-3 c-location' }, ud.location.city + ', ' + ud.location.state)),
                            React.createElement("div", { className: 'c-font-size-xs' }, moment(d.date_create).fromNow())),
                        React.createElement("div", { className: 'word-wrap mt-1 pb-1' }, (this.state.update.id == d.id && isMine)
                            ?
                                this.renderUpdateForm()
                            :
                                React.createElement(React.Fragment, null,
                                    (udParent && d.user_id != d.parent.user_id)
                                        &&
                                            React.createElement("span", { className: 'mr-2' },
                                                React.createElement("i", { className: 'fal fa-arrow-alt-right mr-1 c-font-size-xs' }),
                                                udParent.first_name + ' ' + udParent.last_name,
                                                ":"),
                                    lib_1.util.text2html(d.content, 'd-inline-block'))),
                        React.createElement("div", { className: 'mt-1 c-toolbar d-flex' },
                            React.createElement("div", { className: 'd-flex flex-nowrap align-items-center' },
                                React.createElement("button", { className: 'btn c-btn-comment c-font-size-xs', onClick: function () { return _this.toggleReply(d.id); } },
                                    React.createElement("i", { className: 'fal fa-reply' }),
                                    React.createElement("span", null, "Reply")),
                                React.createElement("span", { className: 'separator' }),
                                React.createElement(__1.Vote.LikeButton, { name: this.props.name, _id: this.props.comment_id, user_id: d.user_id, vote: d.vote, list_id: d.id, list_name: 'reply_list', className: 'btn c-btn-comment', onVoteDone: this.voteDone.bind(this) })),
                            (isMine && this.state.update.id != d.id)
                                &&
                                    React.createElement("div", { className: 'ml-auto c-ellipsis-drop pl-4 dropup' },
                                        React.createElement("button", { className: 'dropdown-toggle', "data-toggle": 'dropdown' },
                                            React.createElement("i", { className: 'far fa-ellipsis-v' })),
                                        React.createElement("div", { className: 'dropdown-menu dropdown-menu-right' },
                                            React.createElement("button", { className: 'dropdown-item', onClick: function (e) { return _this.updateReply(d.id); } },
                                                React.createElement("i", { className: 'far fa-pen fa-fw' }),
                                                React.createElement("span", null, "Edit")),
                                            React.createElement("div", { className: 'dropdown-divider' }),
                                            React.createElement("button", { className: 'dropdown-item', onClick: function (e) { return _this.openDeleteDialog(d.id); } },
                                                React.createElement("i", { className: 'fal fa-trash-alt fa-fw' }),
                                                React.createElement("span", null, "Delete"))))),
                        (this.state.reply_to_id != null && this.state.reply_to_id == d.id)
                            &&
                                React.createElement("div", { className: 'mt-2' }, this.renderCreateForm(d.id)))));
            }
            catch (err) {
                lib_1.util.logError(err, null);
                return null;
            }
        };
        Reply.prototype.render = function () {
            var _this = this;
            try {
                return (React.createElement("div", { className: 'mt-3' },
                    !this.state.reply_to_id && _.isEmpty(this.state.update)
                        &&
                            this.renderCreateForm(null),
                    this.state.status.is_loading
                        &&
                            React.createElement("div", { className: 'c-loading' },
                                React.createElement("i", { className: 'far fa-spinner fa-pulse fa-3x' }),
                                React.createElement("div", { className: 'mt-2' }, "loading ...")),
                    (!this.state.status.is_loading && this.state.reply_list && this.state.reply_list.length > 0)
                        &&
                            React.createElement("div", { className: 'mt-3' }, _.map(this.state.reply_list, function (d) {
                                return _this.renderReply(d);
                            }))));
            }
            catch (err) {
                lib_1.util.logError(err, null);
                return null;
            }
        };
        return Reply;
    }(React.Component));
    CommentReply.Reply = Reply;
})(CommentReply = exports.CommentReply || (exports.CommentReply = {}));
