"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var Redux = require("redux");
var _ = require("lodash");
var async = require("async");
var uri = require("urijs");
var classNames = require("classnames");
var cfg = require("../../../cfg");
var lib_1 = require("../../lib");
var pages_1 = require("../../../routes/pages");
var component_1 = require("../../component");
var MyFollow;
(function (MyFollow) {
    function init(keyword, callback) {
        async.parallel({
            following: function (next) {
                lib_1.Request.follow.myFollowing(cfg.apiQueryDefault.follow, function (err, apiRes) {
                    next(err, apiRes.body);
                });
            },
            follower: function (next) {
                lib_1.Request.follow.myFollower(cfg.apiQueryDefault.follow, function (err, apiRes) {
                    next(err, apiRes.body);
                });
            },
            search: function (next) {
                if (keyword) {
                    lib_1.Request.follow.searchUser(_.assign({}, cfg.apiQueryDefault.follow, { keyword: keyword }), function (err, apiRes) {
                        next(err, apiRes.body);
                    });
                }
                else {
                    next(null, { links: {}, meta: {}, data: [] });
                }
            }
        }, function (err, result) {
            return callback(err, result);
        });
    }
    MyFollow.init = init;
    var app = function (state, action) {
        switch (action.type) {
            case 0: {
                state.is_loading = false;
                state.is_loading_more = false;
                lib_1.util.setToastMessage('error', action.error.detail);
                return state;
            }
            case 1: {
                state.is_loading = true;
                init(state.search_query.keyword, function (err, d) {
                    MyFollow.store.dispatch({ type: 2, data: d });
                });
                return state;
            }
            case 2: {
                state.result = action.data;
                state.is_loading = false;
                return state;
            }
            case 3: {
                if (action.data) {
                    state = _.assign({}, state, action.data);
                }
                if (_.has(action.data, 'tab')) {
                    lib_1.Global.history.replace(lib_1.util.buildUrl(location.pathname, _.pick(state, 'tab')));
                }
                lib_1.Request.follow.myFollowing(cfg.apiQueryDefault.follow, function (err, apiRes) {
                    if (err) {
                        MyFollow.store.dispatch({ type: 0, error: err });
                    }
                    else {
                        MyFollow.store.dispatch({ type: 5, data: apiRes.body });
                    }
                });
                return state;
            }
            case 4: {
                state.is_loading_more = true;
                lib_1.Request.follow.myFollowing(uri(state.result.following.links.next).search(true), function (err, apiRes) {
                    if (err) {
                        MyFollow.store.dispatch({ type: 0, error: err });
                    }
                    else {
                        MyFollow.store.dispatch({ type: 5, data: apiRes.body, isLoadMore: true });
                    }
                });
                return state;
            }
            case 5: {
                state.is_loading_more = false;
                state.is_loading = false;
                if (action.data) {
                    var d = action.data;
                    state.result.following.links = _.assign({}, d.links);
                    state.result.following.meta = _.assign({}, d.meta);
                    state.result.following.data = action.isLoadMore ? _.union(state.result.following.data, d.data) : d.data;
                }
                return state;
            }
            case 6: {
                if (action.data) {
                    state = _.assign({}, state, action.data);
                }
                lib_1.Global.history.replace(lib_1.util.buildUrl(location.pathname, _.pick(state, 'tab')));
                lib_1.Request.follow.myFollower(cfg.apiQueryDefault.follow, function (err, apiRes) {
                    if (err) {
                        MyFollow.store.dispatch({ type: 0, error: err });
                    }
                    else {
                        MyFollow.store.dispatch({ type: 8, data: apiRes.body });
                    }
                });
                return state;
            }
            case 7: {
                state.is_loading_more = true;
                lib_1.Request.follow.myFollower(uri(state.result.follower.links.next).search(true), function (err, apiRes) {
                    if (err) {
                        MyFollow.store.dispatch({ type: 0, error: err });
                    }
                    else {
                        MyFollow.store.dispatch({ type: 8, data: apiRes.body, isLoadMore: true });
                    }
                });
                return state;
            }
            case 8: {
                state.is_loading_more = false;
                state.is_loading = false;
                if (action.data) {
                    var d = action.data;
                    state.result.follower.links = _.assign({}, d.links);
                    state.result.follower.meta = _.assign({}, d.meta);
                    state.result.follower.data = action.isLoadMore ? _.union(state.result.follower.data, d.data) : d.data;
                }
                return state;
            }
            case 9: {
                state.search_query.keyword = action.target.value;
                lib_1.Global.history.replace(lib_1.util.buildUrl(location.pathname, { tab: state.tab, keyword: state.search_query.keyword }));
                return state;
            }
            case 10: {
                action.event.preventDefault();
                if (state.search_query.keyword && state.search_query.keyword.trim() != '') {
                    state.tab = action.data.tab;
                    lib_1.Global.history.replace(lib_1.util.buildUrl(location.pathname, { tab: action.data.tab, keyword: state.search_query.keyword }));
                    state.is_searching = true;
                    state.keyword = state.search_query.keyword;
                    lib_1.Request.follow.searchUser(state.search_query, function (err, apiRes) {
                        if (err) {
                            MyFollow.store.dispatch({ type: 0, error: err });
                        }
                        else {
                            MyFollow.store.dispatch({ type: 12, data: apiRes.body });
                        }
                    });
                }
                return state;
            }
            case 11: {
                return state;
            }
            case 12: {
                state.is_searching = false;
                if (action.data) {
                    var d = action.data;
                    state.result.search.links = _.assign({}, d.links);
                    state.result.search.meta = _.assign({}, d.meta);
                    state.result.search.data = action.isLoadMore ? _.union(state.result.search.data, d.data) : d.data;
                }
                return state;
            }
            case 13: {
                var d = action.data;
                if (!_.isEmpty(d)) {
                    _.assign(_.find(state.result.search.data, { _id: d._id }), d);
                }
                return state;
            }
            default: {
                return state;
            }
        }
    };
    MyFollow.store = null;
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            return _super.call(this, props) || this;
        }
        Page.prototype.componentWillMount = function () {
            var _this = this;
            lib_1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    var locSearch = new uri(_this.props.location.search).search(true);
                    var tab = _.get(locSearch, 'tab') || 'following';
                    if (_.indexOf(['following', 'follower', 'search'], tab) < 0) {
                        tab = 'following';
                    }
                    var searchQuery = _.assign({}, cfg.apiQueryDefault.follow, _.pick(locSearch, ['keyword']));
                    if (_this.props.staticContext) {
                        MyFollow.store = Redux.createStore(app, _.assign({}, _this.props.staticContext, { tab: tab, search_query: searchQuery, keyword: _.get(searchQuery, 'keyword') }));
                    }
                    else if (MyFollow.store == null) {
                        var $initData = document.querySelector('#_init_' + pages_1.pages.my_follow.id);
                        if ($initData && $initData.getAttribute('data-init')) {
                            MyFollow.store = Redux.createStore(app, _.assign({}, {
                                result: JSON.parse(decodeURIComponent($initData.getAttribute('data-init'))),
                                is_initing: false,
                                is_loading: false,
                                is_searching: false,
                                search_query: searchQuery,
                                keyword: _.get(locSearch, 'keyword'),
                                tab: tab,
                                history: _this.props.history
                            }));
                            $initData.remove();
                        }
                        else {
                            var d = {
                                result: {
                                    following: { data: [], meta: {}, links: {} },
                                    follower: { data: [], meta: {}, links: {} },
                                    search: { data: [], meta: {}, links: {} }
                                },
                                is_loading: true,
                                is_loading_more: false,
                                is_searching: false,
                                search_query: searchQuery,
                                keyword: locSearch['keyword'],
                                tab: tab
                            };
                            MyFollow.store = Redux.createStore(app, d);
                            MyFollow.store.dispatch({ type: 1 });
                        }
                    }
                    else if (['PUSH', 'REPLACE'].includes(_this.props.history.action)) {
                        MyFollow.store.dispatch({ type: 1 });
                    }
                    _this.unsubscribe = MyFollow.store.subscribe(function () { _this.forceUpdate(); });
                }
                else {
                    lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                }
            });
        };
        Page.prototype.componentDidMount = function () {
            document.title = lib_1.util.title(pages_1.pages.my_follow.title);
            var locSearch = new uri(this.props.location.search).search(true);
            if (MyFollow.store && !_.has(locSearch, 'tab')) {
                var state = MyFollow.store.getState();
                var q = { tab: state.tab };
                if (state.tab == 'search' && state.search_query.keyword) {
                    q = _.assign(q, { keyword: state.search_query.keyword });
                }
                lib_1.Global.history.replace(lib_1.util.buildUrl(location.pathname, q));
            }
            lib_1.Global.saveUserSettings({ my_cruz_path: lib_1.util.getRelativeUrl() });
        };
        Page.prototype.componentWillReceiveProps = function (nextProps) {
            if (nextProps.history.action == 'PUSH') {
                MyFollow.store.dispatch({ type: 1 });
            }
        };
        Page.prototype.componentWillUnmount = function () {
            this.unsubscribe && this.unsubscribe();
        };
        Page.prototype.onFollowChanged = function (d) {
            if (MyFollow.store.getState().tab == 'search') {
                MyFollow.store.dispatch({ type: 13, data: d });
            }
            MyFollow.store.dispatch({ type: 3, data: MyFollow.store.getState().tab == 'following' ? { is_loading: true } : null });
        };
        Page.prototype.renderHeading = function () {
            try {
                var state = MyFollow.store.getState();
                return (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'd-sm-flex justify-content-between' },
                        React.createElement("h1", { className: 'c-title' }, pages_1.pages.my_follow.title),
                        React.createElement("form", { id: 'formSearchUser', onSubmit: function (e) { return MyFollow.store.dispatch({ type: 10, event: e, data: { tab: 'search' } }); } },
                            React.createElement("div", { className: 'form-group mt-3 mt-sm-0' },
                                React.createElement("div", { className: 'input-group' },
                                    React.createElement("input", { type: 'text', className: 'form-control no-label', id: 'keyword', autoComplete: 'off', value: state.search_query.keyword || '', placeholder: 'Name, Email ...', onChange: function (e) { return MyFollow.store.dispatch({ type: 9, target: e.target }); } }),
                                    React.createElement("div", { className: 'input-group-append' },
                                        React.createElement("button", { type: 'submit', className: 'btn c-btn-1' },
                                            React.createElement("i", { className: 'far fa-search' }))))))),
                    React.createElement("ul", { className: 'nav nav-tabs mt-3 mb-2', id: 'myFollowTab', role: 'tablist' },
                        React.createElement("li", { className: 'nav-item' },
                            React.createElement("a", { className: classNames('nav-link', { active: (state.tab == null || state.tab == 'following') }), id: 'following-tab', "data-toggle": 'tab', href: '#follower', onClick: function () { return MyFollow.store.dispatch({ type: 3, data: { tab: 'following', is_loading: true } }); } },
                                "Following",
                                !_.isEmpty(state.result.following.meta) ? ' (' + state.result.following.meta.total_records.toLocaleString() + ')' : '')),
                        React.createElement("li", { className: 'nav-item' },
                            React.createElement("a", { className: classNames('nav-link', { active: state.tab == 'follower' }), id: 'follower-tab', "data-toggle": 'tab', href: '#following', role: 'tab', onClick: function () { return MyFollow.store.dispatch({ type: 6, data: { tab: 'follower', is_loading: true } }); } },
                                "Follower",
                                !_.isEmpty(state.result.follower.meta) ? ' (' + state.result.follower.meta.total_records.toLocaleString() + ')' : '')))));
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.renderFollow = function () {
            var _this = this;
            try {
                var state_1 = MyFollow.store.getState();
                var d = state_1.result[state_1.tab];
                return (React.createElement(React.Fragment, null,
                    (d && d.meta && d.meta.total_records > 0) &&
                        React.createElement("div", { className: 'row c-sm-gutters' }, _.map(d.data, function (user, idx) {
                            return (React.createElement("div", { key: user._id, className: 'col-md-6 col-xl-4 mt-2 mb-2', id: '_mf_' + idx },
                                React.createElement(component_1.User.SummaryCard, { user: user, show_following_icon: state_1.tab != 'following', onFollowChanged: _this.onFollowChanged.bind(_this), keyword: (state_1.tab == 'search' && state_1.keyword && state_1.keyword != '') ? state_1.keyword : null })));
                        })),
                    (d && d.meta && d.meta.total_records == 0) &&
                        React.createElement("div", { className: 'alert c-alert mt-3 text-center' }, state_1.tab == 'search'
                            ?
                                React.createElement("span", null,
                                    "Your search - ",
                                    React.createElement("span", { className: 'c-font-weight-semi-bold' }, state_1.keyword),
                                    " - did not match any users")
                            :
                                React.createElement("span", null,
                                    "No ",
                                    state_1.tab)),
                    (d && d.links && d.links.next) &&
                        React.createElement("div", { className: 'text-center mt-4' },
                            React.createElement("button", { type: 'button', className: 'btn c-btn-2-rev c-btn-round', onClick: function () { return MyFollow.store.dispatch({ type: state_1.tab == 'following' ? 4 : 7 }); } },
                                React.createElement("i", { className: 'far fa-angle-double-down' }),
                                state_1.is_loading_more ? React.createElement(component_1.BtnWorking, { value: 'Loading More...' }) : React.createElement("span", null,
                                    "Load More (",
                                    d.meta.total_records_left.toLocaleString() + ' left',
                                    ")")))));
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.render = function () {
            return (component_1.Layout.main(lib_1.Global.store.getState().user, null, React.createElement("div", { className: 'container c-container' }, (!MyFollow.store)
                ?
                    React.createElement(component_1.Loading, null)
                :
                    React.createElement(React.Fragment, null,
                        this.renderHeading(),
                        MyFollow.store.getState().is_searching && React.createElement(component_1.Loading, { label: 'searching ...' }),
                        MyFollow.store.getState().is_loading && React.createElement(component_1.Loading, null),
                        (!MyFollow.store.getState().is_loading && !MyFollow.store.getState().is_searching)
                            &&
                                this.renderFollow()))));
        };
        return Page;
    }(React.Component));
    MyFollow.Page = Page;
})(MyFollow = exports.MyFollow || (exports.MyFollow = {}));
