"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var uri = require("urijs");
var React = require("react");
var Redux = require("redux");
var react_router_dom_1 = require("react-router-dom");
var _ = require("lodash");
var moment = require("moment");
var numeral = require("numeral");
var Cruz = require("cruz");
var cfg = require("../../../cfg");
var lib_1 = require("../../lib");
var pages_1 = require("../../../routes/pages");
var component_1 = require("../../component");
var register_control_1 = require("./register_control");
var Register;
(function (Register) {
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                mileageInput: null
            };
            return _this;
        }
        Page.prototype.componentWillMount = function () {
            var locSearch = new uri(this.props.location.search).search(true);
            try {
                if (locSearch && locSearch.ref) {
                    lib_1.Global.referral_code = locSearch.ref;
                }
                else if (lib_1.Global && lib_1.Global.referral_code) {
                    lib_1.Global.history.replace(lib_1.util.buildUrl(location.pathname, { ref: lib_1.Global.referral_code }));
                }
                register_control_1.RegisterControl.store = Redux.createStore(register_control_1.RegisterControl.app, _.assign({}, register_control_1.RegisterControl.initState, { referral: { code: (lib_1.Global && lib_1.Global.referral_code) ? lib_1.Global.referral_code : null } }));
            }
            catch (err) {
                register_control_1.RegisterControl.store = Redux.createStore(register_control_1.RegisterControl.app, _.assign({}, register_control_1.RegisterControl.initState, { referral: { code: locSearch.ref } }));
            }
        };
        Page.prototype.componentDidMount = function () {
            var _this = this;
            this.unsubscribe = register_control_1.RegisterControl.store.subscribe(function () { return _this.forceUpdate(); });
            document.title = lib_1.util.title(pages_1.pages.register.title);
            register_control_1.RegisterControl.store && register_control_1.RegisterControl.store.dispatch({ type: 1 });
        };
        Page.prototype.componentWillUnmount = function () {
            this.unsubscribe && this.unsubscribe();
            register_control_1.RegisterControl.store = null;
        };
        Page.prototype.getError = function (key, isServer) {
            var state = register_control_1.RegisterControl.store.getState();
            if (isServer && state.validationServer != null && state.validationServer[key]) {
                return state.validationServer[key];
            }
            else if (state.validation != null && state.validation[key]) {
                return state.validation[key][0];
            }
            return null;
        };
        Page.prototype.getReferralError = function () {
            var refObj = register_control_1.RegisterControl.store.getState().referral;
            if (refObj && refObj.code && refObj.code.length == 7 && refObj.is_valid == false) {
                return 'Invalid referral code';
            }
            else {
                return null;
            }
        };
        Page.prototype.renderSelectList = function () {
            var v = register_control_1.RegisterControl.store.getState().vehicle;
            return (_.map(register_control_1.RegisterControl.store.getState().options.select_list, function (item) {
                return (React.createElement(component_1.Select, { id: item.id, label: item.name, options: lib_1.util.mapSelectList(item), "data-grid": 'col-sm-6', key: item.id, value: (v.xdata && v.xdata[item.id]) ? v.xdata[item.id] : '', onChange: function (e) { return register_control_1.RegisterControl.store.dispatch({ type: 7, target: e.target }); } }));
            }));
        };
        Page.prototype.mapTransmissionTypeOptions = function (ndo) {
            return _.union([React.createElement("option", { value: '', key: '-1' })], _.map(ndo.transmission, function (item) {
                return React.createElement("option", { value: item.type, key: 'trans_' + item.type }, item.name);
            }));
        };
        Page.prototype.mapTransmissionGearsOptions = function (ndo, trans_type) {
            if (trans_type) {
                var x = _.find(ndo.transmission, { type: trans_type });
                if (x) {
                    return _.union([React.createElement("option", { value: '', key: '-1' })], _.map(x.gears, function (item) {
                        return React.createElement("option", { value: item, key: 'trans_gears_' + item }, item);
                    }));
                }
                else {
                    return null;
                }
            }
            else {
                return null;
            }
        };
        Page.prototype.renderTrim = function () {
            var state = register_control_1.RegisterControl.store.getState();
            var v = state.vehicle;
            var opt = state.options;
            if ((v.make == '~' || v.model == '') || (v.year != null && v.year > 0 && _.isEmpty(opt.trim)) || (!_.isEmpty(opt.trim) && opt.trim.length == 1 && _.isEmpty(_.first(opt.trim)))) {
                return (React.createElement(component_1.Text, { id: 'trim', label: 'Trim', "data-grid": 'col-12 col-sm-6', maxLength: 30, value: v.trim || '', onChange: function (e) { return register_control_1.RegisterControl.store.dispatch({ type: 17, target: e.target }); } }));
            }
            else {
                return (React.createElement(component_1.Select, { id: 'trim', label: 'Trim', "data-grid": 'col-12 col-sm-6', size: 1, disabled: (state.decode_method == 'vin' && opt.trim.length == 1) || _.isEmpty(opt.trim), value: v.trim || '', options: lib_1.util.mapOptions('Trim', opt.trim), onChange: function (e) { return register_control_1.RegisterControl.store.dispatch({ type: 17, target: e.target }); } }));
            }
        };
        Page.prototype.renderPhotoUpload = function () {
            var v = register_control_1.RegisterControl.store.getState().vehicle;
            return (React.createElement("div", { className: 'mt-3 form-group' },
                React.createElement("label", { className: 'control-label optional' }, "Photo(s)"),
                React.createElement(component_1.Photo.PhotoEdit, __assign({ _id: v._id || '', blur_lp: true, blur_lp_checked: true, api_url: v._id ? lib_1.Global.cruzApiBaseUrl + '/vehicle/' + v._id + '/photo' : '', photo_base_url: v._id ? cfg.photoBaseUrl[cfg.env].s3 + '/vehicle/' + v.group + '/' + v._id : '', onUploadFileChanged: function (file_list) { return register_control_1.RegisterControl.store.dispatch({ type: 33, data: file_list }); }, onUploadFileDone: function (photo_list) { return register_control_1.RegisterControl.store.dispatch({ type: 34, data: photo_list }); }, photo_list_classNames: 'col-xl-4 col-md-3 col-sm-4 col-xs-6' }, { photo_list: v.photo_list, executeUpload: register_control_1.RegisterControl.store.getState().executeUpload }))));
        };
        Page.prototype.renderUserForm = function () {
            var state = register_control_1.RegisterControl.store.getState();
            var user = state.user;
            return (React.createElement("section", null,
                React.createElement("h2", { className: 'c-section-subtitle' }, "About You"),
                React.createElement("div", { className: 'row' },
                    React.createElement(component_1.Text, { id: 'email', label: 'Email', type: 'email', required: true, "data-err": this.getError('user.email') || this.getError('email', true), "data-grid": 'col-sm-6', maxLength: 50, value: user.email || '', onChange: function (e) { return register_control_1.RegisterControl.store.dispatch({ type: 2, target: e.target }); }, onBlur: function (e) { return register_control_1.RegisterControl.verifyEmail(e.target['value']); } }),
                    React.createElement(component_1.PasswordInput, { id: 'password', label: 'Password', type: 'password', required: true, "data-err": this.getError('user.password'), "data-grid": 'col-sm-6', maxLength: 50, value: user.password || '', onChange: function (e) { return register_control_1.RegisterControl.store.dispatch({ type: 2, target: e.target }); } })),
                (register_control_1.RegisterControl.store.getState().is_account_exist_not_verified && user.email) &&
                    React.createElement(component_1.User.accountPending, { email: user.email }),
                React.createElement("div", { className: 'row' },
                    React.createElement(component_1.Text, { id: 'first_name', label: 'First Name', required: true, "data-err": this.getError('user.first_name'), "data-grid": 'col-sm-6', maxLength: 50, value: user.first_name || '', onChange: function (e) { return register_control_1.RegisterControl.store.dispatch({ type: 2, target: e.target }); } }),
                    React.createElement(component_1.Text, { id: 'last_name', label: 'Last Name', required: true, "data-err": this.getError('user.last_name'), "data-grid": 'col-sm-6', maxLength: 50, value: user.last_name || '', onChange: function (e) { return register_control_1.RegisterControl.store.dispatch({ type: 2, target: e.target }); } }),
                    React.createElement(component_1.MaskedText, { id: 'zip_code', label: 'Zip Code', mask: lib_1.Mask.TypeEnum.ZipCode, required: true, value: user.location.zip_code != null ? user.location.zip_code : '', "data-err": this.getError('zip_code', true) || this.getError('user.location.zip_code'), "data-grid": 'col-sm-6', onChange: function (e) { return register_control_1.RegisterControl.store.dispatch({ type: 4, target: e.target }); } }),
                    React.createElement(component_1.MaskedText, { id: 'phone', label: 'Phone', mask: lib_1.Mask.TypeEnum.Phone, "data-grid": 'col-sm-6', value: user.phone || '', onChange: function (e) { return register_control_1.RegisterControl.store.dispatch({ type: 2, target: e.target }); } }))));
        };
        Page.prototype.validMileage = function (mileage) {
            var _this = this;
            var state = register_control_1.RegisterControl.store.getState();
            var v = state.vehicle;
            if (mileage == this.state.mileageInput) {
                return true;
            }
            else if (v.year > 0 && mileage > 0 && mileage < 1000) {
                var age = moment().year() - v.year;
                var shouldConfirmMileage = ((age == 1 && mileage < 20) || (age > 1 && age <= 4 && mileage <= 100) || (age > 4));
                if (shouldConfirmMileage) {
                    lib_1.util.setModal(null, false, false, React.createElement(component_1.Modal.MileageConfirm, { year: v.year, mileage: mileage, onYes: function () {
                            _this.setState({ mileageInput: mileage * 1000 });
                            register_control_1.RegisterControl.store.dispatch({ type: 21, data: mileage * 1000 });
                        }, onNo: function () {
                            _this.setState({ mileageInput: mileage });
                        } }));
                }
                return !shouldConfirmMileage;
            }
            return true;
        };
        Page.prototype.renderVehicleForm = function () {
            var _this = this;
            var state = register_control_1.RegisterControl.store.getState();
            var v = state.vehicle;
            var opt = state.options;
            var sGrid = 'col-12 col-sm-6';
            return (React.createElement("section", null,
                React.createElement("h2", { className: 'c-section-subtitle' }, "About Your Vehicle"),
                React.createElement(component_1.Checkbox, { id: 'own_vehicle', label: "I don't own a vehicle.", defaultChecked: !state.own_vehicle, onChange: function (e) { return register_control_1.RegisterControl.store.dispatch({ type: 6, target: e.target }); } }),
                React.createElement("div", { id: '_vehicle', className: state.own_vehicle ? '' : 'collapse' },
                    React.createElement("div", { className: 'row' },
                        React.createElement(component_1.VinInput, { id: 'vin', label: 'VIN', maxLength: 17, "data-grid": sGrid, value: v.vin || '', onChange: function (e) { return register_control_1.RegisterControl.store.dispatch({ type: 8, target: e.target }); } }),
                        React.createElement(component_1.MaskedText, { id: 'mileage', type: 'tel', label: 'Mileage', mask: lib_1.Mask.TypeEnum.Number, "data-grid": sGrid, "data-input-addon": 'miles', maxLength: 9, required: true, "data-err": this.getError('vehicle.mileage'), value: v.mileage || '', onChange: function (e) { return register_control_1.RegisterControl.store.dispatch({ type: 21, data: _.get(e.target, 'value') }); }, onBlur: function (e) { return _this.validMileage(numeral(e.target.value).value()); } }),
                        state.decode_method != 'vin' &&
                            React.createElement(component_1.Select, { id: 'body_type_code', label: 'Type', "data-grid": sGrid, size: 1, required: true, value: v.body_type_code ? v.body_type_code.toString() : '', options: lib_1.util.mapBodyTypeOptions(Cruz.Lookup.Vehicle.body_type_search, false), onChange: function (e) { return register_control_1.RegisterControl.store.dispatch({ type: 10, target: e.target }); } }),
                        React.createElement(component_1.Select, { id: 'make', label: 'Make', "data-grid": sGrid, size: 1, required: true, "data-err": this.getError('vehicle.make'), disabled: state.decode_method == 'vin' || _.isEmpty(opt.make), value: v.make || '', options: lib_1.util.mapOptionGroup('Make', opt.make, null, true), onChange: function (e) { return register_control_1.RegisterControl.store.dispatch({ type: 12, target: e.target }); } }),
                        (v.make != '~') &&
                            React.createElement(component_1.Select, { id: 'model', label: 'Model', required: true, "data-err": this.getError('vehicle.model'), "data-grid": sGrid, size: 1, disabled: ((state.decode_method == 'vin' && opt.model.all.length == 1) || _.isEmpty(opt.model)), value: v.model || '', options: (v.make && v.make != '') && lib_1.util.mapOptionGroup('Models', opt.model, null, true), onChange: function (e) { return register_control_1.RegisterControl.store.dispatch({ type: 14, target: e.target }); } }),
                        v.make == '~' &&
                            React.createElement(component_1.Select, { id: 'brand', label: 'Brand', required: true, "data-err": this.getError('vehicle.custom.brand'), "data-grid": sGrid, size: 1, options: lib_1.util.mapCustomBrandOptions(state.noneDecodeOptions.custom_brand), value: v.custom.brand || '', onChange: function (e) { return register_control_1.RegisterControl.store.dispatch({ type: 35, target: e.target }); } }),
                        React.createElement(component_1.Select, { id: 'year', label: 'Year', required: true, "data-err": this.getError('vehicle.year'), "data-grid": sGrid, size: 1, disabled: state.decode_method == 'vin' || _.isEmpty(opt.year), value: v.year != null ? v.year.toString() : '', options: lib_1.util.mapOptions('Year', register_control_1.RegisterControl.store.getState().options.year || _.range(moment().year() + 1, 1900, -1)), onChange: function (e) { return register_control_1.RegisterControl.store.dispatch({ type: 15, target: e.target }); } }),
                        this.renderTrim(),
                        (v.make == '~' || v.model == '~') &&
                            React.createElement(component_1.Text, { id: 'title', label: 'Title', maxLength: 50, "data-grid": sGrid, value: v.custom.title || '', onChange: function (e) { return register_control_1.RegisterControl.store.dispatch({ type: 35, target: e.target }); } }),
                        React.createElement(component_1.Select, { id: 'exterior_color', label: 'Exterior Color', required: true, "data-err": this.getError('vehicle.exterior_color'), "data-grid": sGrid, size: 1, options: (opt.exterior_color && opt.exterior_color.length > 0)
                                ?
                                    (_.has(_.first(opt.exterior_color), 'ext_color_id')
                                        ?
                                            lib_1.util.mapExteriorColorOptions('Color', opt.exterior_color)
                                        :
                                            lib_1.util.mapColorOptions('Exterior Color', opt.exterior_color))
                                :
                                    [], disabled: _.isEmpty(opt.exterior_color), value: (!_.isEmpty(v.exterior_color))
                                ?
                                    (v.exterior_color.ext_color_id != null ? v.exterior_color.ext_color_id.toString() : v.exterior_color.color_group)
                                :
                                    '', onChange: function (e) { return register_control_1.RegisterControl.store.dispatch({ type: 20, target: e.target }); } }),
                        state.is_decoding &&
                            React.createElement(component_1.Decoding, null),
                        (register_control_1.RegisterControl.store.getState().options.select_list) &&
                            this.renderSelectList(),
                        (opt.transmission_list) &&
                            React.createElement(component_1.Select, { id: 'trans_type', label: 'Transmission Type', options: this.mapTransmissionTypeOptions({ transmission: opt.transmission_list }), "data-grid": sGrid, size: 1, value: v.ydata.trans_type || '', onChange: function (e) { return register_control_1.RegisterControl.store.dispatch({ type: 30, target: e.target }); } }),
                        (opt.transmission_list) &&
                            React.createElement(component_1.Select, { id: 'trans_gears', label: 'Transmission Gears', options: this.mapTransmissionGearsOptions({ transmission: opt.transmission_list }, v.ydata.trans_type), "data-grid": sGrid, size: 1, value: (v.ydata && v.ydata.trans_gears != null) ? v.ydata.trans_gears.toString() : '', onChange: function (e) { return register_control_1.RegisterControl.store.dispatch({ type: 31, target: e.target }); } })),
                    React.createElement("div", { className: 'row mt-3' },
                        React.createElement(component_1.Select, { id: 'sale_status_id', label: 'Is Your Vehicle ...', required: true, "data-err": this.getError('vehicle.sale_status_id'), "data-grid": sGrid, size: 1, value: v.sale_status_id != null ? v.sale_status_id.toString() : '', options: lib_1.util.mapSaleStatusOptions(Cruz.Lookup.Vehicle.sale_status_list), onChange: function (e) { return register_control_1.RegisterControl.store.dispatch({ type: 24, target: e.target }); } }),
                        (state.sale_status && state.sale_status.price_name) &&
                            React.createElement(component_1.MaskedText, { id: 'price', label: state.sale_status.price_name, required: state.sale_status.price_required, mask: lib_1.Mask.TypeEnum.Price, "data-err": this.getError('vehicle.price'), "data-grid": 'col-sm-6', value: v.price != null ? v.price.toString() : '', maxLength: 8, onChange: function (e) { return register_control_1.RegisterControl.store.dispatch({ type: 22, target: e.target }); } })),
                    this.renderPhotoUpload())));
        };
        Page.prototype.renderReferralForm = function () {
            var state = register_control_1.RegisterControl.store.getState();
            return (React.createElement("section", null,
                React.createElement("h2", { className: 'c-section-subtitle' }, "Referral"),
                React.createElement("div", { className: 'row' },
                    React.createElement(component_1.Text, { id: 'referral_code', label: 'Referral Code', "data-grid": 'col-sm-6', maxLength: 7, value: state.referral.code || '', "data-err": this.getReferralError(), onChange: function (e) { return register_control_1.RegisterControl.store.dispatch({ type: 28, target: e.target }); } })),
                !_.isEmpty(state.referral.user) &&
                    React.createElement("div", { className: 'mt-2' }, component_1.User.renderUserDisplay(state.referral.user))));
        };
        Page.prototype.onSubmit = function (e) {
            e.preventDefault();
            if (this.validMileage(_.get(register_control_1.RegisterControl.store.getState().vehicle, 'mileage'))) {
                register_control_1.RegisterControl.store.dispatch({ type: 25, event: e });
            }
        };
        Page.prototype.render = function () {
            var _this = this;
            var state = register_control_1.RegisterControl.store.getState();
            var is_dirty = true;
            if (state) {
                if (state.is_registered == true) {
                    is_dirty = false;
                }
                else if (_.isEqual(state.initUserData, _.pick(state, 'user', 'own_vehicle', 'vehicle'))) {
                    is_dirty = false;
                }
            }
            else {
                is_dirty = false;
            }
            return (component_1.Layout.entry(2, React.createElement("div", { className: 'mb30' },
                React.createElement("div", { className: 'text-center' },
                    React.createElement("h1", { className: 'c-section-title' }, pages_1.pages.register.title),
                    React.createElement("div", { className: 'mb-3' }, "Just need a bit of information and you'll be ready to go!"),
                    React.createElement("div", { className: 'c-alert-account' },
                        "Already have an account?",
                        React.createElement("div", { className: 'd-block d-sm-none' }),
                        React.createElement(react_router_dom_1.Link, { to: pages_1.pages.signin.path, className: 'c-btn-link ml-2 mr-2', role: 'link' }, "Sign in"))),
                React.createElement("form", { id: 'formRegister', role: 'form', onSubmit: function (e) { return _this.onSubmit(e); }, noValidate: true },
                    this.renderUserForm(),
                    this.renderVehicleForm(),
                    this.renderReferralForm(),
                    React.createElement("section", { className: 'text-center' },
                        React.createElement("button", { type: 'submit', className: 'btn c-btn-1 px-5 c-btn-round mt-2', role: 'button' }, state.is_working ? React.createElement(component_1.BtnWorking, { value: 'Register ...' }) : 'Register'),
                        React.createElement("p", { className: 'mt-4' },
                            "By clicking \"Register\", you agree to CRUZ\u00AE's ",
                            React.createElement("a", { href: pages_1.pages.terms_conditions.path, className: 'c-btn-link', target: '_blank' }, "Terms & Conditions"))))), React.createElement(component_1.Dirty, { when: is_dirty })));
        };
        return Page;
    }(React.Component));
    Register.Page = Page;
})(Register = exports.Register || (exports.Register = {}));
