"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var _ = require("lodash");
var lib_1 = require("../../lib");
var Delete;
(function (Delete) {
    var Dialog = (function (_super) {
        __extends(Dialog, _super);
        function Dialog(props) {
            return _super.call(this, props) || this;
        }
        Dialog.prototype.componentDidMount = function () {
            var _this = this;
            $('#_' + this.props.id).modal('show').on('hidden.bs.modal', function (e) {
                lib_1.util.removeModal(_this);
            });
        };
        Dialog.prototype.onYes = function () {
            this.props.onYes && this.props.onYes();
            $('#_' + this.props.id).modal('hide');
        };
        Dialog.prototype.render = function () {
            try {
                return (React.createElement("div", { className: 'modal', id: '_' + this.props.id, tabIndex: -1, role: 'dialog', "data-backdrop": 'static', "data-keyboard": false },
                    React.createElement("div", { className: 'modal-dialog', role: 'document' },
                        React.createElement("div", { className: 'modal-content' },
                            React.createElement("div", { className: 'modal-header' },
                                React.createElement("h2", { className: 'modal-title' }, "Confirm"),
                                React.createElement("button", { type: 'button', "data-dismiss": 'modal', className: 'close' })),
                            React.createElement("div", { className: 'modal-body' }, !_.isEmpty(this.props.question) ? this.props.question : 'Are you sure you want delete this?'),
                            React.createElement("div", { className: 'modal-footer' },
                                React.createElement("div", { className: 'c-btn-toolbar' },
                                    React.createElement("button", { type: 'button', className: 'btn c-btn-xs c-btn-2 mr-2', onClick: this.onYes.bind(this) }, "Yes"),
                                    React.createElement("button", { type: 'button', className: 'btn c-btn-xs c-btn-1', "data-dismiss": 'modal' }, "No")))))));
            }
            catch (err) {
                lib_1.util.logError(err, null);
                return null;
            }
        };
        return Dialog;
    }(React.Component));
    Delete.Dialog = Dialog;
    var InPageConfirm = (function (_super) {
        __extends(InPageConfirm, _super);
        function InPageConfirm(props) {
            var _this = _super.call(this, props) || this;
            _this.state = { showConfirm: false };
            return _this;
        }
        InPageConfirm.prototype.askConfirm = function () {
            this.setState({ showConfirm: true });
        };
        InPageConfirm.prototype.onNo = function () {
            this.setState({ showConfirm: false });
        };
        InPageConfirm.prototype.onYes = function () {
            var _this = this;
            this.setState({ showConfirm: false }, function () {
                _this.props.onYes && _this.props.onYes();
            });
        };
        InPageConfirm.prototype.onCancel = function () {
            var _this = this;
            this.setState({ showConfirm: false }, function () {
                _this.props.onCancel && _this.props.onCancel();
            });
        };
        InPageConfirm.prototype.render = function () {
            var _this = this;
            return (React.createElement("div", { className: this.props.className }, this.state.showConfirm
                ?
                    React.createElement("div", { className: 'c-alert c-alert-confirm' },
                        React.createElement("p", { className: 'mb10' }, !_.isEmpty(this.props.question)
                            ?
                                this.props.question
                            :
                                (this.props.count ?
                                    'Are you sure you want to delete ' + this.props.count + ' photo' + (this.props.count > 1 ? 's' : '')
                                    :
                                        'Are you sure') + '?'),
                        React.createElement("div", { className: 'text-center' },
                            React.createElement("button", { type: 'button', className: 'btn c-btn-xs c-btn-1 mr-2', onClick: function () { return _this.onYes(); } }, "Yes"),
                            React.createElement("button", { type: 'button', className: 'btn c-btn-xs c-btn-2', onClick: function () { return _this.onNo(); } }, "No")))
                :
                    React.createElement(React.Fragment, null,
                        this.props.onCancel
                            &&
                                React.createElement("button", { type: 'button', className: 'btn c-btn-xs c-btn-2-rev mr-2', onClick: function () { return _this.onCancel(); } }, "Cancel"),
                        React.createElement("button", { type: 'button', className: 'btn c-btn-xs c-btn-2', onClick: this.askConfirm.bind(this) }, (this.props.count != null && this.props.count > 0) ? 'Delete ' + this.props.count + ' Photo' + (this.props.count > 1 ? 's' : '') : 'Delete'))));
        };
        return InPageConfirm;
    }(React.Component));
    Delete.InPageConfirm = InPageConfirm;
})(Delete = exports.Delete || (exports.Delete = {}));
