"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var classNames = require("classnames");
var request = require("superagent");
var _ = require("lodash");
var react_router_dom_1 = require("react-router-dom");
var moment = require("moment");
var lib_1 = require("../../lib");
var cfg = require("../../../cfg");
var __1 = require("..");
var Discussion;
(function (Discussion) {
    function renderStatisticCards(d) {
        if (_.isEmpty(d)) {
            return null;
        }
        else {
            var sGrid = 'col-6 col-sm-6 col-lg-12';
            return (React.createElement("div", { className: 'row c-sm-gutters mb-4 mt-3' },
                React.createElement("div", { className: sGrid },
                    React.createElement("div", { className: 'stat-card' },
                        React.createElement("div", { className: 'value' },
                            React.createElement("span", { className: 'number' }, d.count.toLocaleString())),
                        React.createElement("div", { className: 'label ellipsis' },
                            React.createElement("span", null, "People with "),
                            React.createElement(react_router_dom_1.Link, { to: lib_1.util.buildUrl('/vehicles', _.pick(d, ['make', 'model', 'year'])), className: 'c-btn-link' }, _.values(_.pick(d, ['year', 'make', 'model'])).join(' ').trim())))),
                React.createElement("div", { className: classNames(sGrid, 'mt-lg-2') },
                    React.createElement("div", { className: 'stat-card' },
                        React.createElement("div", { className: 'value' },
                            React.createElement("span", { className: 'number' },
                                React.createElement("sup", null, "#"),
                                d.popularity[0].toLocaleString()),
                            React.createElement("span", { className: 'ml-2 mr-2' }, "of"),
                            React.createElement("span", { className: 'number' }, d.popularity[1].toLocaleString())),
                        React.createElement("div", { className: 'label ellipsis' }, "Popularity Rank")))));
        }
    }
    Discussion.renderStatisticCards = renderStatisticCards;
    function renderTagList(d) {
        if ((d.tag_list && d.tag_list.length > 0) || !_.isEmpty(d.vehicle)) {
            return (React.createElement("ul", { className: 'list-inline my-2' },
                (d.tag_list != null && d.tag_list.length > 0) &&
                    _.map(d.tag_list, function (tag) {
                        return (React.createElement("li", { key: 'tag_' + tag.id, className: 'list-inline-item mb-2' },
                            React.createElement("span", { className: 'badge c-badge-s' }, tag.name)));
                    }),
                (!_.isEmpty(d.vehicle) && d.vehicle.body_type_name && !d.vehicle.year) &&
                    React.createElement("li", { className: 'list-inline-item mb-2' },
                        React.createElement("span", { className: 'badge c-badge-s' }, d.vehicle.body_type_name)),
                (!_.isEmpty(d.vehicle) && d.vehicle.make && d.vehicle.make != '') &&
                    React.createElement("li", { className: 'list-inline-item mb-2' },
                        React.createElement("span", { className: 'badge c-badge-s' }, __1.Vehicle.detailTitle(d.vehicle, false)))));
        }
        else {
            return null;
        }
    }
    Discussion.renderTagList = renderTagList;
    function renderPhotoList(feed) {
        var photoColClassName = 'col-12';
        if (feed.photo_list && feed.photo_list.length > 0) {
            var c = feed.photo_list.length;
            if (c == 1 || c == 2 || c == 4) {
                photoColClassName = 'col-6';
            }
            if (c == 3) {
                photoColClassName = 'col-6 col-sm-4';
            }
            else if (c > 4) {
                photoColClassName = 'col-6 col-sm-4 col-md-3';
            }
        }
        var openPhotoView = function () {
            lib_1.util.setModalPhotoView({
                title: feed.title.trim(),
                baseUrl: cfg.photoBaseUrl[process.env.NODE_ENV].cdn + '/community/' + feed.group + '/' + feed._id,
                photo_list: feed.photo_list
            });
        };
        return (React.createElement("div", { className: 'row c-photo-gutters mt-4' }, _.map(feed.photo_list, function (photo) {
            return (React.createElement("div", { key: photo.id, className: photoColClassName },
                React.createElement("img", { className: 'img-fluid  c-photo-enlarge', src: cfg.photoBaseUrl[process.env.NODE_ENV].cdn + '/community/' + feed.group + '/' + feed._id + '/' + photo.id + '-md.jpg', title: feed.title, onClick: function (e) { return openPhotoView(); } })));
        })));
    }
    Discussion.renderPhotoList = renderPhotoList;
    var FeedComment = (function (_super) {
        __extends(FeedComment, _super);
        function FeedComment(props) {
            var _this = _super.call(this, props) || this;
            _this.state = _.assign({}, {
                api_base_url: lib_1.Global.cruzApiBaseUrl + '/community_feed/' + _this.props.discussion_id + '/comment',
                status: {},
                create: {},
                update: {}
            }, props);
            return _this;
        }
        FeedComment.prototype.handleError = function (err) {
        };
        FeedComment.prototype.newCommentChanged = function (tgt) {
            this.setState({ create: _.assign({}, this.state.create, { content: tgt.value }) });
        };
        FeedComment.prototype.create = function () {
            if (this.state.create.content && this.state.create.content.trim() != '' && !this.state.status.is_creating) {
                var tc_1 = this;
                lib_1.Auth.isAuthed(function (isAuthed) {
                    if (isAuthed) {
                        tc_1.setState({ status: { is_creating: true } });
                        request.post(tc_1.state.api_base_url)
                            .set(lib_1.Auth.authHeader())
                            .send({ content: tc_1.state.create.content })
                            .timeout(cfg.superagent.timeout.normal)
                            .end(function (err, apiRes) {
                            err ? tc_1.handleError(apiRes.body.error) : tc_1.createDone(apiRes.body.data);
                        });
                    }
                    else {
                        lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                    }
                });
            }
        };
        FeedComment.prototype.createDone = function (d) {
            this.setState(_.assign({}, this.state, {
                is_working: false,
                create: {}
            }));
            var temp_query = _.assign({}, this.state.query);
            if (this.state.result && this.state.result.data && this.state.result.data.length > 0) {
                temp_query = _.assign({}, temp_query, {
                    page_size: this.state.result.data.length,
                    tsn: new Date(_.first(this.state.result.data).date_create).getTime()
                });
            }
            this.search(temp_query);
            this.props.onCommentDone && this.props.onCommentDone();
        };
        FeedComment.prototype.update = function (_id) {
            this.setState({ update: _.pick(_.find(this.state.result.data, { _id: _id }), ['_id', 'content']) });
        };
        FeedComment.prototype.updateCommentChanged = function (e) {
            this.setState({ update: _.assign({}, this.state.update, { content: e.target['value'] }) });
        };
        FeedComment.prototype.cancelUpdate = function () {
            this.setState({ update: {} });
        };
        FeedComment.prototype.saveUpdate = function () {
            if (!this.state.status.is_updating && this.state.update.content && this.state.update.content.trim() != '') {
                var tc_2 = this;
                lib_1.Auth.isAuthed(function (isAuthed) {
                    if (isAuthed) {
                        tc_2.setState({ status: { is_updating: true } });
                        request.put(tc_2.state.api_base_url + '/' + tc_2.state.update._id)
                            .set(lib_1.Auth.authHeader())
                            .send({ content: tc_2.state.update.content })
                            .timeout(cfg.superagent.timeout.normal)
                            .end(function (err, apiRes) {
                            err ? tc_2.handleError(apiRes.body.error) : tc_2.updateDone(apiRes.body.data);
                        });
                    }
                    else {
                        lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                    }
                });
            }
        };
        FeedComment.prototype.updateDone = function (d) {
            var x = _.find(this.state.result.data, { _id: d._id });
            if (x) {
                _.assign(x, d);
            }
            this.setState({
                status: {},
                update: {
                    content: null
                }
            });
            this.props.onCommentDone && this.props.onCommentDone();
        };
        FeedComment.prototype.openDeleteDialog = function (_id) {
            var _this = this;
            lib_1.util.setModal(null, false, true, React.createElement(__1.Delete.Dialog, { id: _id, onYes: function (e) { return _this.deleteYes(_id); } }));
        };
        FeedComment.prototype.deleteYes = function (_id) {
            var _this = this;
            lib_1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    request.delete(_this.state.api_base_url + '/' + _id)
                        .set(lib_1.Auth.authHeader())
                        .timeout(cfg.superagent.timeout.normal)
                        .end(function (err, apiRes) {
                        if (err) {
                            _this.handleError(apiRes.body.error);
                        }
                        else {
                            _this.search(_this.state.query);
                            _this.props.onCommentDone && _this.props.onCommentDone();
                        }
                    });
                }
                else {
                    lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                }
            });
        };
        FeedComment.prototype.search = function (query) {
            var _this = this;
            lib_1.Auth.isAuthed(function () {
                var url = _this.state.api_base_url + lib_1.util.buildApiQueryString(query);
                request.get(url)
                    .timeout(cfg.superagent.timeout.normal)
                    .retry(2)
                    .set(lib_1.Auth.authHeader())
                    .end(function (err, apiRes) {
                    err ? _this.handleError(apiRes.body.error) : _this.setState(_.assign({}, _this.state, { result: apiRes.body }));
                });
            });
        };
        FeedComment.prototype.loadMore = function (e) {
            var _this = this;
            e && e.preventDefault();
            this.setState(_.assign({}, this.state, { is_loading: true }));
            lib_1.Auth.isAuthed(function (isAuthed) {
                request.get(_this.state.result.links.next)
                    .timeout(cfg.superagent.timeout.normal)
                    .retry(2)
                    .set(lib_1.Auth.authHeader())
                    .end(function (err, apiRes) {
                    _this.setState(_.assign({}, _this.state, { is_loading: false }));
                    if (err) {
                        _this.handleError(apiRes.body.error);
                    }
                    else {
                        var d = apiRes.body;
                        var result = {
                            meta: d.meta,
                            links: d.links,
                            data: _.union(_this.state.result.data, d.data)
                        };
                        _this.setState(_.assign({}, _this.state, { result: result }));
                    }
                });
            });
        };
        FeedComment.prototype.renderComment = function (comment) {
            var _this = this;
            return (React.createElement("li", { key: comment._id, className: 'li-comment' },
                React.createElement("div", { className: 'row no-gutters mb-3' },
                    React.createElement("div", { className: 'col-md-8' }, __1.User.renderUserDisplay(comment.user_display)),
                    (lib_1.Global.store.getState().user._id && lib_1.Global.store.getState().user._id == comment.user_id && (!this.state.update || this.state.update._id != comment._id)) &&
                        React.createElement("div", { className: 'col-md-4' },
                            React.createElement("div", { className: 'c-btn-link-toolbar float-md-right mt-3 mt-md-0' },
                                React.createElement("button", { type: 'button', className: 'c-btn-link', onClick: function (e) { return _this.update(comment._id); } },
                                    React.createElement("i", { className: 'far fa-pen' }),
                                    React.createElement("span", null, "Edit")),
                                React.createElement("span", { className: 'separator' }),
                                React.createElement("button", { type: 'button', className: 'c-btn-link', onClick: function (e) { return _this.openDeleteDialog(comment._id); } },
                                    React.createElement("i", { className: 'far fa-trash-alt' }),
                                    React.createElement("span", null, "Delete"))))),
                React.createElement("div", { className: 'mb-3' }, (this.state.update && this.state.update._id == comment._id)
                    ?
                        React.createElement("div", null,
                            React.createElement(__1.Textarea, { placeholder: 'Reply', name: 'updateComment', className: 'textarea-lg', value: this.state.update.content || '', minRows: 1, maxRows: 5, onChange: function (e) { return _this.updateCommentChanged(e); } }),
                            React.createElement("div", { className: 'text-right' },
                                React.createElement("button", { type: 'button', className: 'btn c-btn-2 mr-1', onClick: function (e) { return _this.cancelUpdate(); } },
                                    React.createElement("span", null, "Cancel")),
                                React.createElement("button", { type: 'button', className: 'btn c-btn-1', onClick: function (e) { return _this.saveUpdate(); } }, this.state.status.is_updating ? React.createElement(__1.BtnWorking, { value: 'Reply' }) : React.createElement("span", null, "Save"))))
                    :
                        React.createElement("div", { className: 'word-wrap' }, lib_1.util.text2html(comment.content))),
                React.createElement("div", { className: 'c-btn-link-toolbar mb-3' },
                    React.createElement("span", null,
                        React.createElement("i", { className: 'far fa-clock mr-2' }),
                        React.createElement("span", null, moment(comment.date_create).fromNow())),
                    React.createElement("span", { className: 'separator' }),
                    React.createElement(__1.Vote.LikeButton, __assign({}, {
                        name: 'community_feed_comment',
                        _id: comment._id,
                        user_id: comment.user_id,
                        vote: comment.vote
                    })))));
        };
        FeedComment.prototype.render = function () {
            var tc = this;
            return (React.createElement("div", { className: 'mt-4 community-comments' },
                React.createElement("h4", { className: 'title' },
                    "Reply",
                    React.createElement("span", { className: 'c-title-count' }, (!_.isEmpty(tc.state.result) && tc.state.result.meta) ? tc.state.result.meta.total_records.toLocaleString() : 0)),
                React.createElement("div", { className: 'feed-reply' },
                    React.createElement(__1.Textarea, { placeholder: 'Reply', minRows: 1, maxRows: 5, name: 'newComment', value: tc.state.create.content, onChange: function (e) { return tc.newCommentChanged(e.target); } }),
                    React.createElement("button", { type: 'button', className: 'btn c-btn-1', onClick: function (e) { return tc.create(); } }, tc.state.status.is_creating ? React.createElement(__1.BtnWorking, { value: 'Reply' }) : 'Reply')),
                (tc.state.result && tc.state.result.data) &&
                    React.createElement("ul", { className: 'list-unstyled' }, _.map(tc.state.result.data, function (comment) { return tc.renderComment(comment); })),
                (tc.state.result && tc.state.result.links && tc.state.result.links.next) &&
                    React.createElement("div", { className: 'mt-4 text-center' },
                        React.createElement("a", { className: 'btn c-btn-1-rev c-btn-s', href: '#', onClick: function (e) { return tc.loadMore(e); } },
                            React.createElement("i", { className: 'far fa-angle-double-down' }),
                            tc.state.status.is_loading ? React.createElement(__1.BtnWorking, { value: 'Loading ...' }) : React.createElement("span", null,
                                "View More Replies (",
                                tc.state.result.meta.total_records_left.toLocaleString(),
                                ")")))));
        };
        return FeedComment;
    }(React.Component));
    Discussion.FeedComment = FeedComment;
})(Discussion = exports.Discussion || (exports.Discussion = {}));
