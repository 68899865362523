"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var Redux = require("redux");
var _ = require("lodash");
var uri = require("urijs");
var cfg = require("../../../cfg");
var lib_1 = require("../../lib");
var pages_1 = require("../../../routes/pages");
var component_1 = require("../../component");
var MyReferrals;
(function (MyReferrals) {
    function searchReferrals(query, callback) {
        lib_1.Request.user.myReferral(_.assign({}, cfg.apiQueryDefault.my_referral, query), function (err, apiRes) {
            return callback(err, apiRes);
        });
    }
    MyReferrals.searchReferrals = searchReferrals;
    MyReferrals.app = function (state, action) {
        function search() {
            searchReferrals(state.query, function (err, apiRes) {
                if (err) {
                    lib_1.util.handleError(lib_1.Request.getApiErrorCode(err));
                }
                else {
                    MyReferrals.store.dispatch({ type: 3, data: apiRes.body, isLoadMore: false });
                }
            });
        }
        switch (action.type) {
            case 5: {
                state.is_loading = false;
                state.is_loading_more = false;
                state.is_filtering = false;
                lib_1.util.handleError(lib_1.Request.getApiErrorCode(action.error));
                return state;
            }
            case 0: {
                if (!state.is_filtering) {
                    state.is_filtering = true;
                    state.query = _.assign({}, cfg.apiQueryDefault.my_referral, action.query);
                    lib_1.Global.history.replace(lib_1.util.buildUrl(location.pathname, state.query));
                    search();
                }
                return state;
            }
            case 1: {
                if (!state.is_loading) {
                    state.is_loading = true;
                    search();
                }
                return state;
            }
            case 2: {
                action.event && action.event.preventDefault();
                if (!state.is_loading_more) {
                    state.is_loading_more = true;
                    searchReferrals(_.assign({}, state.query, uri.parseQuery(new uri(state.result.links.next).query())), function (err, apiRes) {
                        if (err) {
                            MyReferrals.store.dispatch({ type: 5, error: err });
                        }
                        else {
                            MyReferrals.store.dispatch({ type: 3, data: apiRes.body, isLoadMore: true });
                        }
                    });
                }
                return state;
            }
            case 3: {
                var d = action.data;
                if (d) {
                    state.result.links = _.assign({}, d.links);
                    state.result.meta = _.assign({}, d.meta);
                    state.result.data = (action.isLoadMore) ? _.union(state.result.data, d.data) : d.data;
                }
                state.is_loading = false;
                state.is_filtering = false;
                state.is_loading_more = false;
                var tgtIndex = d.meta.page_size * (d.meta.page_number - 1);
                if (action.isLoadMore && tgtIndex > 0) {
                    lib_1.util.scrollTo('#_mr_' + tgtIndex, 70);
                }
                return state;
            }
            case 4: {
                var d = action.data;
                var x = _.find(state.result.data, { _id: d._id });
                _.assign(x, d);
            }
            case 6: {
                state = { result: { data: [], meta: {}, links: {} } };
                return state;
            }
            default: {
                return state;
            }
        }
    };
    MyReferrals.store = null;
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            return _super.call(this, props) || this;
        }
        Page.prototype.componentWillMount = function () {
            var _this = this;
            lib_1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    var locSearch = new uri(_this.props.location.search).search(true);
                    var user = lib_1.Global.store.getState().user;
                    if (_this.props.staticContext) {
                        MyReferrals.store = Redux.createStore(MyReferrals.app, _.assign({}, _this.props.staticContext));
                    }
                    else if (MyReferrals.store == null) {
                        var $initData = document.querySelector('#_init_' + pages_1.pages.my_referral.id);
                        if ($initData && $initData.getAttribute('data-init')) {
                            MyReferrals.store = Redux.createStore(MyReferrals.app, _.assign({}, {
                                result: JSON.parse(decodeURIComponent($initData.getAttribute('data-init'))),
                                query: _.assign({}, (user && user.settings && user.settings.referral_filter) ? user.settings.referral_filter : {}, locSearch),
                                history: _this.props.history
                            }));
                            $initData.remove();
                        }
                        else {
                            var d = {
                                query: _.assign({}, (user && user.settings && user.settings.referral_filter) ? user.settings.referral_filter : {}, locSearch),
                                result: {
                                    data: [],
                                    meta: {},
                                    links: {},
                                },
                                is_loading: false
                            };
                            MyReferrals.store = Redux.createStore(MyReferrals.app, d);
                            MyReferrals.store.dispatch({ type: 1 });
                        }
                    }
                    else if (_this.props.history.action == 'PUSH') {
                        MyReferrals.store.dispatch({ type: 1 });
                    }
                    if (lib_1.util.isClient()) {
                        lib_1.Global.history.replace(lib_1.util.buildUrl(location.pathname, locSearch));
                    }
                    _this.unsubscribe = MyReferrals.store.subscribe(function () { _this.forceUpdate(); });
                }
                else {
                    lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                }
            });
        };
        Page.prototype.componentDidMount = function () {
            document.title = lib_1.util.title(pages_1.pages.my_referral.title);
            lib_1.Global.saveUserSettings({ my_cruz_path: pages_1.pages.my_referral.path });
        };
        Page.prototype.componentWillReceiveProps = function (nextProps) {
            if (nextProps.history.action == 'PUSH') {
                MyReferrals.store.dispatch({ type: 1 });
            }
        };
        Page.prototype.componentWillUnmount = function () {
            this.unsubscribe && this.unsubscribe();
        };
        Page.prototype.onFollowChanged = function (d) {
            if (!_.isEmpty(d)) {
                MyReferrals.store.dispatch({ type: 4, data: d });
            }
        };
        Page.prototype.renderUserList = function () {
            var _this = this;
            var state = MyReferrals.store.getState();
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'row c-sm-gutters' }, _.map(state.result.data, function (user, idx) {
                    return (React.createElement("div", { key: user._id, className: 'col-md-6 col-xl-4 mt-2 mb-2', id: '_mr_' + idx },
                        React.createElement(component_1.User.SummaryCard, { user: user, show_following_icon: true, onFollowChanged: _this.onFollowChanged.bind(_this) })));
                })),
                MyReferrals.store.getState().result.links && MyReferrals.store.getState().result.links.next &&
                    React.createElement("div", { className: 'text-center mt-4' },
                        React.createElement("a", { className: 'btn c-btn-2-rev c-btn-round', href: state.result.links.next, onClick: function (e) { return MyReferrals.store.dispatch({ type: 2, event: e }); } },
                            React.createElement("i", { className: 'far fa-angle-double-down' }),
                            state.is_loading_more ? React.createElement(component_1.BtnWorking, { value: 'Loading More...' }) : React.createElement("span", null,
                                "Load More (",
                                state.result.meta.total_records_left.toLocaleString() + ' left',
                                ")")))));
        };
        Page.prototype.renderSharePanel = function () {
            var myUrl = (cfg.env == 'production' ? 'https://' : 'http://') + lib_1.Global.store.getState().user.region + '.' + cfg.shareUrl[cfg.env] + '?ref=' + lib_1.Global.store.getState().user.code;
            return (React.createElement("div", { className: 'card mb-2 mt-2 text-center' },
                React.createElement("div", { className: 'card-body' },
                    React.createElement("div", { className: 'mt-1 mt-md-2' },
                        React.createElement("span", { className: 'mr-2 c-font-size-l' }, "Share Away!"),
                        React.createElement("br", null),
                        React.createElement("a", { href: 'https://www.facebook.com/share.php?u=' + uri.encode(myUrl), target: '_blank', rel: 'noopener noreferrer', title: 'Share on Facebook', className: 'btn c-btn-facebook-l mt-2' },
                            React.createElement("i", { className: 'fab fa-facebook-f' }))),
                    React.createElement("div", { className: 'row no-gutters mt-3' },
                        React.createElement("div", { className: 'col-12 col-md-8 col-lg-6 mx-auto' },
                            React.createElement("label", null, "Or copy and share your unique URL"),
                            React.createElement(component_1.User.ReferralUrl, { myUrl: myUrl }))))));
        };
        Page.prototype.render = function () {
            var state = MyReferrals.store ? MyReferrals.store.getState() : null;
            return (component_1.Layout.main(lib_1.Global.store.getState().user, null, React.createElement("div", { className: 'container c-container my_referrals' }, !state || state.is_loading
                ?
                    React.createElement(component_1.Loading, null)
                :
                    React.createElement(React.Fragment, null,
                        React.createElement("div", { className: 'mb-3' },
                            React.createElement("h1", { className: 'c-title' },
                                pages_1.pages.my_referral.title,
                                (state.result.meta.total_records > 0)
                                    &&
                                        React.createElement("span", { className: 'c-title-count' }, state.is_filtering
                                            ?
                                                '?/' + state.result.meta.total_records.toLocaleString()
                                            :
                                                (state.result.meta.total_records_left + state.result.data.length).toLocaleString() + '/' + state.result.meta.total_records.toLocaleString()))),
                        this.renderSharePanel(),
                        state.result.meta.total_records == 0
                            &&
                                React.createElement("div", { className: 'mt-4 mb-3 text-center c-font-size-xl' },
                                    "You currently have ",
                                    React.createElement("span", null, "0"),
                                    " referrals."),
                        state.is_filtering
                            ?
                                React.createElement(component_1.Loading, { label: 'seaching ...' })
                            :
                                state.result.meta.total_records_matched > 0
                                    ?
                                        this.renderUserList()
                                    :
                                        state.result.meta.total_records > 0
                                            &&
                                                React.createElement("div", { className: 'mt-3' }, "No referrals matched")))));
        };
        return Page;
    }(React.Component));
    MyReferrals.Page = Page;
})(MyReferrals = exports.MyReferrals || (exports.MyReferrals = {}));
