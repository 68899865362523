"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var request = require("superagent");
var _ = require("lodash");
var $ = require("jquery");
var uri = require("urijs");
var pages_1 = require("../../../routes/pages");
var lib_1 = require("../../lib");
var cfg = require("../../../cfg");
var user_1 = require("../../user");
var VehicleSearchControl;
(function (VehicleSearchControl) {
    VehicleSearchControl.filterKey = {
        main: ['condition', 'body_type', 'make', 'model', 'year_min', 'year_max', 'price_min', 'price_max', 'mileage', 'seller', 'sale_status', 'zip_code', 'distance'],
        more: [],
        powertrain: ['drive_type', 'trans_type', 'trans_gear', 'fuel_type', 'engine_name', 'engine_aspiration', 'engine_cylinder', 'engine_hp_min', 'engine_torque_min', 'mpg_min', 'towing_capacity_min', 'acceleration_max', 'range_min'],
        specification: ['seats_min', 'seating_row', 'door', 'tonnage', 'bed_length_min', 'wheelbase_min', 'class'],
        other: ['sport', 'luxury', 'exotic', 'epa_smartway', 'with_photo']
    };
    VehicleSearchControl.moreFilterKeys = _.union(VehicleSearchControl.filterKey.powertrain, VehicleSearchControl.filterKey.specification);
    VehicleSearchControl.allFilterKeys = _.flatten(_.values(VehicleSearchControl.filterKey));
    function searchVehicle(query, callback) {
        lib_1.Request.vehicle.search(_.assign({}, cfg.apiQueryDefault.vehicle, query), function (err, apiRes) {
            return callback(err, apiRes);
        });
    }
    VehicleSearchControl.searchVehicle = searchVehicle;
    function getInitSearchOptions(q) {
        request.get(lib_1.Global.cruzApiBaseUrl + '/vehicle/option/v2/init' + lib_1.util.buildApiQueryString(_.omit(q, VehicleSearchControl.pageKeys)))
            .timeout(cfg.superagent.timeout.normal)
            .end(function (err, apiRes) {
            if (err) {
                VehicleSearchControl.store.dispatch({ type: 0, error: err });
            }
            else {
                VehicleSearchControl.store.dispatch({ type: 1, data: apiRes.body.data });
            }
        });
    }
    VehicleSearchControl.getInitSearchOptions = getInitSearchOptions;
    VehicleSearchControl.pageKeys = ['page_number', 'page_size', 'sort', 'ts', 'tsn', 'sort_value'];
    var rKeys = ['page_number'];
    var keepKeys = ['price_min', 'price_max', 'zip_code', 'distance', 'from_following', 'from_follower'];
    var keepValueUpKeys = ['mileage_max', 'year_to', 'distance', 'acceleration_max'];
    var keepValueDownKeys = ['year_from', 'engine_hp_min', 'engine_torque_min', 'seats_min', 'mpg_min', 'towing_capacity_min', 'range_min', 'bed_length_min', 'wheelbase_min'];
    function trimQuery(query, key, value) {
        if (_.isEmpty(query)) {
            return {};
        }
        else if (!_.isEmpty(value)) {
            if ((_.includes(keepValueUpKeys, key) && Number(value) >= Number(query[key])) || (_.includes(keepValueDownKeys, key) && Number(value) <= Number(query[key]))) {
                return query;
            }
            else {
                var allKeys = _.keys(_.omit(query, VehicleSearchControl.pageKeys));
                var keyIndex = _.indexOf(allKeys, key);
                if (keyIndex == 0) {
                    return _.pick(query, keepKeys);
                }
                else {
                    var xKeys = _.take(allKeys, keyIndex + 1);
                    if (_.size(xKeys) == 0) {
                        return query;
                    }
                    else {
                        return _.pick(query, _.concat(xKeys, keepKeys));
                    }
                }
            }
        }
        else if (key == 'make') {
            return _.omit(query, 'make', 'model', 'trim');
        }
        else if (key == 'model') {
            return _.omit(query, 'model', 'trim');
        }
        else {
            return _.omit(query, key);
        }
    }
    function getPageTitle(query, location) {
        try {
            var tp = [];
            if (query.condition != null) {
                var x = _.find(lib_1.Lookup.condition, { id: Number(query.condition) });
                if (x) {
                    tp.push(x.name);
                }
            }
            if (query.luxury) {
                tp.push('Luxury');
            }
            else if (query.sport) {
                tp.push('Sport');
            }
            else if (query.exotic) {
                tp.push('Exotic');
            }
            if (query.make == null && query.body_type == null) {
                tp.push('Vehicles');
            }
            else {
                if (query.make != null) {
                    if (query.year_min != null) {
                        if (query.year_min == query.year_max) {
                            tp.push(query.year_min);
                        }
                        else if (query.year_min < query.year_max) {
                            tp.push(query.year_min + '-' + query.year_max);
                        }
                    }
                    tp.push(query.make);
                    if (query.model != null) {
                        tp.push(query.model);
                        if (query.trim != null) {
                            tp.push(query.trim);
                        }
                    }
                }
                else if (query.body_type != null) {
                    var bt = lib_1.util.getBodyTypeByValue(query.body_type);
                    if (!_.isEmpty(bt)) {
                        tp.push(bt.name);
                    }
                }
            }
            var title = _.join(tp, ' ');
            var loc = null;
            if (!_.isEmpty(location)) {
                loc = _.pick(location, 'city', 'state_abbr');
            }
            else {
                loc = _.pick(query, 'city', 'state_abbr');
            }
            if (!_.isEmpty(loc)) {
                title += ' in ' + loc.city + ', ' + loc.state_abbr;
            }
            return title;
        }
        catch (err) {
            return 'Vehicles';
        }
    }
    VehicleSearchControl.getPageTitle = getPageTitle;
    VehicleSearchControl.app = function (state, action) {
        var _a, _b, _c;
        function getSpecifiedOption() {
            var q = _.omit(state.query, VehicleSearchControl.pageKeys);
            if (_.isEmpty(q)) {
                state.searchOptions.specified = {};
            }
            else {
                request.get(lib_1.Global.cruzApiBaseUrl + '/vehicle/option/v2/specified' + lib_1.util.buildApiQueryString(q))
                    .timeout(cfg.superagent.timeout.normal)
                    .end(function (err, apiRes) {
                    if (err) {
                        VehicleSearchControl.store.dispatch({ type: 0, error: err });
                    }
                    else {
                        VehicleSearchControl.store.dispatch({ type: 2, data: apiRes.body.data });
                    }
                });
            }
        }
        function saveUserSettings() {
            if (!_.isEmpty(lib_1.Global.store.getState().user)) {
                var d = {
                    sort: state.query.sort,
                    sale_status: state.query.sale_status,
                };
                var zip_code = _.get(state.query, 'zip_code');
                if (zip_code != null && _.size(zip_code) == 5) {
                    _.assign(d, { zip_code: zip_code });
                }
                if (state.query.distance && state.query.distance > 0) {
                    _.assign(d, { distance: state.query.distance });
                }
                lib_1.Global.saveUserSettings({ vehicle_search: _.omitBy(d, null) });
            }
        }
        function search(is_init, saved_vehicle_filter_id) {
            getSpecifiedOption();
            if (!state.is_filtering) {
                if (!is_init) {
                    state.is_filtering = true;
                }
                var user = lib_1.Global.store.getState().user;
                if (saved_vehicle_filter_id == null && !_.isEmpty(user) && !_.isEmpty(user.saved_vehicle_filter_list)) {
                    var x = _.find(user.saved_vehicle_filter_list, function (d) {
                        return _.isEqual(_.omit(d.filter, 'sort'), _.omit(state.query, VehicleSearchControl.pageKeys));
                    });
                    if (x) {
                        state.saved_vehicle_filter_id = x.id;
                        lib_1.Request.saved.vehicle_filter.updateDateView(x.id, function (err, apiRes) {
                            if (!err && apiRes.body.data) {
                                lib_1.Global.store.dispatch({ type: lib_1.Global.actions.setUser, data: apiRes.body.data });
                            }
                        });
                    }
                    else {
                        state.saved_vehicle_filter_id = null;
                    }
                }
                searchVehicle(state.query, function (err, apiRes) {
                    lib_1.Global.history.replace(lib_1.util.buildUrl(location.pathname, state.query));
                    err ? VehicleSearchControl.store.dispatch({ type: 0, error: err }) : VehicleSearchControl.store.dispatch({ type: 19, data: apiRes.body });
                });
            }
        }
        function update(v) {
            if (v) {
                var x = _.find(state.result.data, function (d) { return d._id == v._id; });
                if (x) {
                    _.assign(x, v);
                }
            }
        }
        var tgt = action.target;
        switch (action.type) {
            case 0: {
                state.is_loading = false;
                state.is_loading_more = false;
                lib_1.util.logError(action.error, null);
                lib_1.util.handleError(lib_1.Request.getApiErrorCode(action.error));
                return state;
            }
            case 17: {
                state.query = _.omit(action.query || state.query, rKeys);
                if (!_.isEqual(state.prev_query, state.query)) {
                    search();
                }
                return state;
            }
            case 1: {
                _.assign(state.searchOptions, action.data);
                var user = lib_1.Global.store.getState().user;
                if (!_.isEmpty(user) && !_.isEmpty(user.follow)) {
                    if (user.follow.following_count > 0) {
                        state.searchOptions.default.other.push({ key: 'from_following', name: 'From Following' });
                    }
                    if (user.follow.follower_count > 0) {
                        state.searchOptions.default.other.push({ key: 'from_follower', name: 'From Follower' });
                    }
                }
                return state;
            }
            case 16: {
                if (!state.is_loading) {
                    state.is_loading = true;
                    if (action.query) {
                        state.query = _.assign({}, _.pick(state.query, ['sort']), action.query);
                    }
                    else {
                        state.query = _.assign({}, cfg.apiQueryDefault.vehicle);
                    }
                    state.prev_query = _.assign({}, state.query);
                    search(true);
                }
                return state;
            }
            case 2: {
                _.assign(state.searchOptions, { specified: action.data });
                return state;
            }
            case 4: {
                var val = tgt.value;
                var q = trimQuery(state.query, tgt.id, tgt.value);
                if (tgt.type == 'checkbox' && !tgt.checked) {
                    state.query = _.omit(q, rKeys, tgt.id);
                }
                else {
                    state.query = _.pickBy(_.assign({}, _.omit(q, rKeys), (_a = {}, _a[tgt.id] = tgt.checked ? 1 : tgt.id == 'sort' ? Number(val) : val, _a)));
                }
                if (_.includes(['condition', 'body_type', 'make', 'model'], tgt.id)) {
                    state.title = getPageTitle(state.query);
                }
                if (tgt.id == 'sort') {
                    saveUserSettings();
                }
                $(tgt).blur();
                search();
                return state;
            }
            case 5: {
                var q = trimQuery(state.query, tgt.id, tgt.value);
                state.query = _.pickBy(_.assign({}, _.omit(q, rKeys), { body_type: (!_.isEmpty(tgt.value)) ? action.target.value.split(',') : '' }));
                state.title = getPageTitle(state.query);
                search();
                return state;
            }
            case 6: {
                var val = Number(tgt.value);
                var shouldGetQuery = false;
                if (isNaN(val) || val == 0 || tgt.value == '') {
                    state.query = _.omit(state.query, rKeys, tgt.id);
                }
                else {
                    if (tgt.id == 'year_min' && val > state.query.year_min) {
                        shouldGetQuery = true;
                    }
                    else if (tgt.id == 'year_max' && val < state.query.year_max) {
                        shouldGetQuery = true;
                    }
                    if (shouldGetQuery) {
                        state.query = trimQuery(state.query, tgt.id, tgt.value);
                    }
                    state.query = _.assign({}, _.omit(state.query, rKeys), (_b = {}, _b[tgt.id] = tgt.value, _b));
                }
                if (state.query.year_min != null && state.query.year_min > state.query.year_max) {
                    state.query.year_max = state.query.year_min;
                }
                search();
                return state;
            }
            case 7: {
                var zip_code = lib_1.Mask.UnmaskedValue(tgt.value, lib_1.Mask.TypeEnum.ZipCode);
                if (_.isEmpty(zip_code)) {
                    state.query = _.omit(state.query, rKeys, tgt.id);
                }
                else {
                    if (zip_code.length == 5) {
                        state.query = _.assign({}, _.omit(state.query, rKeys), { zip_code: zip_code });
                        saveUserSettings();
                    }
                    else {
                        _.assign(state.query, { zip_code: zip_code });
                    }
                }
                if ((zip_code.length == 0 || zip_code.length == 5) && _.isEqual(state.prev_query, state.query) == false) {
                    if (state.query.distance != null && state.query.distance > 0) {
                        state.query = _.assign({}, trimQuery(state.query, tgt.id, tgt.value), { distance: state.query.distance });
                    }
                    if (zip_code.length == 0) {
                        state.title = getPageTitle(state.query);
                    }
                    search();
                }
                return state;
            }
            case 8: {
                state.query = trimQuery(state.query, tgt.id, tgt.value);
                if (_.isEmpty(tgt.value)) {
                    state.query = _.omit(state.query, rKeys, tgt.id);
                }
                else {
                    _.assign(state.query, (_c = {}, _c[tgt.id] = tgt.value, _c));
                }
                saveUserSettings();
                if (state.query.zip_code && (state.query.zip_code.length == 5)) {
                    search();
                }
                return state;
            }
            case 9: {
                if (_.isEmpty(state.query.tag)) {
                    state.query.tag = [tgt.value];
                }
                else if (state.query.tag.includes(tgt.value)) {
                    state.query.tag = _.pull(state.query.tag, tgt.value);
                    if (_.size(state.query.tag) == 0) {
                        state.query = _.omit(state.query, 'tag');
                    }
                }
                else {
                    state.query.tag = _.concat(state.query.tag, tgt.value);
                }
                search();
                return state;
            }
            case 10: {
                state.query = _.omit(trimQuery(state.query, action.data, null), action.data);
                if (_.includes(['condition', 'body_type', 'make', 'model'], action.data)) {
                    state.title = getPageTitle(state.query);
                }
                search();
                return state;
            }
            case 11: {
                var tag_list = _.chain(state.query).get('tag').without(action.data).value();
                if (_.size(tag_list) == 0) {
                    state.query = _.omit(state.query, 'tag');
                }
                else {
                    state.query = _.assign({}, state.query, { tag: tag_list });
                }
                search();
                return state;
            }
            case 12: {
                state.query = _.pick(state.query, ['sort']);
                state.title = getPageTitle(state.query);
                state.searchOptions.specified = {};
                state.saved_vehicle_filter_id = null;
                lib_1.Global.history.replace(lib_1.util.buildUrl(pages_1.pages.vehicle_search.path, state.query));
                saveUserSettings();
                search();
                return state;
            }
            case 19: {
                try {
                    state.prev_query = _.assign({}, state.query);
                    state.is_loading = false;
                    state.is_loading_more = false;
                    state.is_filtering = false;
                    var d = action.data;
                    if (d) {
                        state.result.links = _.assign({}, d.links);
                        state.result.meta = _.assign({}, d.meta);
                        state.result.data = (action.isLoadMore) ? _.union(state.result.data, d.data) : d.data;
                        state.title = getPageTitle(state.query, _.get(state.result.meta, 'location'));
                        document.title = state.title;
                    }
                    var tgtIndex = d.meta.page_size * (d.meta.page_number - 1);
                    if (lib_1.util.isClient() && tgtIndex > 0) {
                        lib_1.util.scrollTo('#_vs_' + tgtIndex, d.meta.page_number == 2 ? 100 : 160);
                    }
                    if (state.saved_vehicle_filter_id != null && !_.isEmpty(state.result.meta)) {
                        var x = _.find(lib_1.Global.store.getState().user.saved_vehicle_filter_list, { id: state.saved_vehicle_filter_id });
                        if (x && _.size(x.filter) != state.result.meta.total_records_matched) {
                            lib_1.Global.refreshSavedFilter();
                        }
                    }
                    return state;
                }
                catch (err) {
                }
            }
            case 18: {
                action.event && action.event.preventDefault();
                if (!state.is_loading_more) {
                    state.is_loading_more = true;
                    _.assign(state.query, uri(state.result.links.next).search(true));
                    searchVehicle(state.query, function (err, apiRes) {
                        if (err) {
                            VehicleSearchControl.store.dispatch({ type: 0, error: err });
                        }
                        else {
                            VehicleSearchControl.store.dispatch({ type: 19, data: apiRes.body, isLoadMore: true });
                        }
                    });
                }
                return state;
            }
            case 21: {
                update(action.data);
                user_1.MyVehicle.store && user_1.MyVehicle.store.dispatch({ type: 6, data: action.data });
                return state;
            }
            case 22: {
                update(action.data);
                return state;
            }
            case 13: {
                state.show_filter[action.data] = !state.show_filter[action.data];
                return state;
            }
            case 14: {
                var q = _.assign({}, cfg.apiQueryDefault.vehicle);
                if (_.isEmpty(tgt.value)) {
                    state.saved_vehicle_filter_id = null;
                }
                else {
                    var x = _.find(lib_1.Global.store.getState().user.saved_vehicle_filter_list, function (d) { return d.id == tgt.value; });
                    if (!_.isEmpty(x)) {
                        _.assign(q, x.filter);
                    }
                    state.saved_vehicle_filter_id = tgt.value;
                }
                state.query = _.assign({}, q);
                search(false, state.saved_vehicle_filter_id);
                return state;
            }
            case 15: {
                state.saved_vehicle_filter_id = action.data;
                if (!_.isEmpty(state.saved_vehicle_filter_id)) {
                    lib_1.Request.saved.vehicle_filter.updateDateView(state.saved_vehicle_filter_id, function (err, apiRes) {
                        if (!err && apiRes.body.data) {
                            lib_1.Global.store.dispatch({ type: lib_1.Global.actions.setUser, data: apiRes.body.data });
                        }
                    });
                }
                return state;
            }
            default: {
                return state;
            }
        }
    };
    VehicleSearchControl.store = null;
})(VehicleSearchControl = exports.VehicleSearchControl || (exports.VehicleSearchControl = {}));
