"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var request = require("superagent");
var _ = require("lodash");
var moment = require("moment");
var cfg = require("../../../cfg");
var global_1 = require("./global");
var util_1 = require("./util");
var Tracking;
(function (Tracking) {
    var viewedList = [];
    var trackingKey = 'viewedList';
    if (util_1.util.isClient()) {
        try {
            var x = localStorage.getItem(trackingKey);
            if (x) {
                var y = JSON.parse(x);
                if (Array.isArray(y)) {
                    viewedList = y;
                    if (viewedList.length > 0) {
                        viewedList = viewedList.filter(function (d) {
                            return moment(d.date).isAfter(moment().subtract(12, 'hours'));
                        });
                    }
                }
            }
        }
        catch (err) {
        }
    }
    function isViewed(name, _id) {
        if (viewedList && viewedList.length > 0) {
            var q = {
                name: name,
                _id: _id
            };
            return !_.isEmpty(_.find(viewedList, q));
        }
        else {
            return false;
        }
    }
    Tracking.isViewed = isViewed;
    function trackView(d, callback) {
        try {
            if (viewedList.length == 0 || _.find(viewedList, { name: d.name, _id: d._id }) == null) {
                var apiUrl = global_1.Global.cruzApiBaseUrl + '/tracking/view/' + d.name + '/' + d._id;
                if (global_1.Global && global_1.Global.store.getState().user._id) {
                    d.user_id = global_1.Global.store.getState().user._id;
                    apiUrl += '/' + d.user_id;
                }
                request.post(apiUrl)
                    .timeout(cfg.superagent.timeout.normal)
                    .end(function (err, apiRes) {
                    try {
                        if (err) {
                            return callback(err, null);
                        }
                        else {
                            d.date = moment().toISOString();
                            if (viewedList == null) {
                                viewedList = [];
                            }
                            viewedList.push(d);
                            localStorage.setItem(trackingKey, JSON.stringify(viewedList));
                            return callback(null, apiRes.body.data);
                        }
                    }
                    catch (err) {
                        return callback(err, null);
                    }
                });
            }
            else {
                return callback(null, null);
            }
        }
        catch (err) {
            return callback(err, null);
        }
    }
    Tracking.trackView = trackView;
})(Tracking = exports.Tracking || (exports.Tracking = {}));
