"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var classNames = require("classnames");
var lib_1 = require("../../lib");
var _ = require("lodash");
var Select = (function (_super) {
    __extends(Select, _super);
    function Select(props) {
        return _super.call(this, props) || this;
    }
    Select.prototype.render = function () {
        var _this = this;
        try {
            return (React.createElement("div", { className: this.props['data-grid'] },
                React.createElement("div", { className: lib_1.util.formGroupClass(this.props) },
                    React.createElement("div", { className: 'control-wrap' }, (this.props['data-input-prepend'] || this.props['data-input-append'])
                        ?
                            React.createElement("div", { className: 'input-group' },
                                this.props['data-input-prepend'] &&
                                    React.createElement("div", { className: 'input-group-prepend' },
                                        React.createElement("label", { className: 'input-group-text' }, this.props['data-input-prepend'])),
                                React.createElement("div", { className: classNames('input-group-main', { 'has-pre-addon': this.props['data-input-prepend'] }, { 'has-addon': this.props['data-input-append'] }) },
                                    React.createElement('select', _.extend({ ref: this.props.id }, lib_1.util.inputProps(this.props)), this.props.options),
                                    this.props.label &&
                                        React.createElement("label", { htmlFor: this.props.id, className: lib_1.util.formLabelClass(this.props), onClick: function () { $('#' + _this.props.id).trigger('mousedown'); } }, this.props.label)),
                                this.props['data-input-append'] &&
                                    React.createElement("div", { className: 'input-group-append' },
                                        React.createElement("label", { className: 'input-group-text' }, this.props['data-input-append'])))
                        :
                            React.createElement(React.Fragment, null,
                                React.createElement('select', lib_1.util.inputProps(this.props), this.props.options),
                                this.props.label &&
                                    React.createElement("label", { htmlFor: this.props.id, className: lib_1.util.formLabelClass(this.props), onClick: function () { $('#' + _this.props.id).trigger('mousedown'); } }, this.props.label))),
                    !_.isEmpty(this.props['data-err']) &&
                        React.createElement("span", { className: 'form-control-feedback', "aria-hidden": true }, this.props['data-err']))));
        }
        catch (err) {
            lib_1.util.logError(err, null);
            return null;
        }
    };
    return Select;
}(React.Component));
exports.Select = Select;
