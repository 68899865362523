"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Redux = require("redux");
var request = require("superagent");
var _ = require("lodash");
var cfg = require("../../../cfg");
var pages_1 = require("../../../routes/pages");
var lib_1 = require("../../lib");
var __1 = require("..");
var user_1 = require("../../user");
var SpottedAddEditControl;
(function (SpottedAddEditControl) {
    SpottedAddEditControl.initState = {
        spottedInput: {
            title: '',
            location_name: '',
            content: ''
        },
        prevSpottedInput: {},
        spotted: {},
        is_dirty: false,
        is_working: false,
        executeUpload: false,
        is_loading: true,
    };
    var validate = require('validate.js');
    var rules = {
        location_name: {
            presence: { message: '^Location is required.', allowEmpty: false }
        },
        title: {
            presence: { message: '^Title is required.', allowEmpty: false },
        },
        upload_file_list: {
            presence: { message: '^Need at least one photo.', allowEmpty: false }
        }
    };
    var formKeys = ['_id', 'title', 'location_name', 'content'];
    SpottedAddEditControl.store = Redux.createStore(function (state, action) {
        function valid() {
            state.validation = validate(state.spottedInput, (state.spottedInput._id ? _.omit(rules, 'upload_file_list') : rules));
            return state;
        }
        function updateSpottedStores() {
            __1.SpottedSearch.store && __1.SpottedSearch.store.dispatch({ type: 5, data: state.spotted });
            user_1.MySpotted.store && user_1.MySpotted.store.dispatch({ type: 5, data: state.spotted });
        }
        switch (action.type) {
            case 0: {
                state.is_working = false;
                lib_1.util.setToastMessage('error', _.get(action.error, cfg.apiErrorKey));
                return state;
            }
            case 1: {
                state.is_loading = false;
                return state;
            }
            case 2: {
                state.is_loading = true;
                lib_1.Request.spotted.forUpdate(action.data, function (err, apiRes) {
                    if (err) {
                        lib_1.util.handleError(lib_1.Request.getApiErrorCode(err));
                    }
                    else {
                        SpottedAddEditControl.store.dispatch({ type: 3, data: apiRes.body.data });
                    }
                });
                return state;
            }
            case 3: {
                state.spotted = _.pickBy(action.data, _.identity);
                state.is_loading = false;
                state.spottedInput = _.pick(state.spotted, formKeys);
                state.prevSpottedInput = _.memoize(_.pick)(state.spotted, formKeys);
                return state;
            }
            case 4: {
                state.spottedInput.upload_file_list = action.data;
                state.validation && valid();
                return state;
            }
            case 6: {
                lib_1.Auth.isAuthed(function (isAuthed) {
                    if (isAuthed) {
                        state.is_working = true;
                        var id_list = action.data;
                        request.delete(lib_1.Global.cruzApiBaseUrl + '/spotted/' + state.spotted._id + '/photo')
                            .set(lib_1.Auth.authHeader())
                            .timeout(cfg.superagent.timeout.normal)
                            .send(id_list)
                            .end(function (err, apiRes) {
                            err ? SpottedAddEditControl.store.dispatch({ type: 0, error: apiRes.body.error }) : SpottedAddEditControl.store.dispatch({ type: 7, data: apiRes.body.data });
                        });
                    }
                    else {
                        lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                    }
                });
                return state;
            }
            case 7: {
                if (action.data) {
                    state.spotted.photo_list = action.data;
                    lib_1.util.setToastMessage('success', 'Successfully Saved');
                    if (state.is_create) {
                        user_1.MySpotted.store && user_1.MySpotted.store.dispatch({ type: 1 });
                        state.is_create = false;
                    }
                    else {
                        updateSpottedStores();
                    }
                }
                state.spottedInput.upload_file_list = [];
                state.prevSpottedInput = _.memoize(_.pick)(state.spottedInput, formKeys);
                state.is_dirty = false;
                state.is_working = false;
                state.executeUpload = false;
                setTimeout(function () {
                    lib_1.Global.history.replace(pages_1.pages.spotted_add_edit.path + '?_id=' + state.spotted._id);
                }, 10);
                return state;
            }
            case 8: {
                lib_1.Auth.isAuthed(function (isAuthed) {
                    if (isAuthed) {
                        state.is_working = true;
                        request.put(lib_1.Global.cruzApiBaseUrl + '/spotted/' + state.spottedInput._id + '/photo')
                            .set(lib_1.Auth.authHeader())
                            .timeout(cfg.superagent.timeout.normal)
                            .send(action.data)
                            .end(function (err, apiRes) {
                            err ? SpottedAddEditControl.store.dispatch({ type: 0, error: apiRes.body.error }) : SpottedAddEditControl.store.dispatch({ type: 9, data: apiRes.body.data });
                        });
                    }
                    else {
                        lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                    }
                });
                return state;
            }
            case 9: {
                state.spotted.photo_list = action.data;
                state.is_working = false;
                lib_1.util.setToastMessage('success', 'Successfully Saved');
                updateSpottedStores();
                return state;
            }
            case 10: {
                if (action.data) {
                    state.is_working = true;
                }
                return state;
            }
            case 11: {
                state.spottedInput = _.assign({}, state.spottedInput, action.data);
                state.is_dirty = !_.isEqual(state.prevSpottedInput, _.pickBy(state.spottedInput, _.identity));
                state.validation && valid();
                return state;
            }
            case 12: {
                action.event.preventDefault();
                valid();
                if (!state.is_working && (state.validation == null || _.isEmpty(state.validation) == true)) {
                    lib_1.Auth.isAuthed(function (isAuthed) {
                        if (isAuthed) {
                            state.is_working = true;
                            var requestSpotted = null;
                            var isUpdate_1 = false;
                            if (state.spottedInput._id && state.spottedInput._id != '') {
                                isUpdate_1 = true;
                                requestSpotted = request.put(lib_1.Global.cruzApiBaseUrl + '/spotted/' + state.spottedInput._id);
                            }
                            else {
                                state.is_create = true;
                                requestSpotted = request.post(lib_1.Global.cruzApiBaseUrl + '/spotted');
                            }
                            requestSpotted
                                .set(lib_1.Auth.authHeader())
                                .timeout(cfg.superagent.timeout.normal)
                                .send(_.omit(state.spottedInput, 'upload_file_list'))
                                .end(function (err, apiRes) {
                                if (err) {
                                    SpottedAddEditControl.store.dispatch({ type: 0, error: err });
                                }
                                else {
                                    lib_1.Global.onSubmitSuccess('spotted');
                                    SpottedAddEditControl.store.dispatch({ type: (isUpdate_1 ? 13 : 14), data: apiRes.body.data });
                                }
                            });
                        }
                        else {
                            lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                        }
                    });
                }
                return state;
            }
            case 14: {
                state.spotted = _.pickBy(action.data, _.identity);
                state.executeUpload = true;
                state.spottedInput = _.assign({}, _.pick(state.spottedInput, 'upload_file_list'), action.data);
                return state;
            }
            case 13: {
                state.spotted = _.pickBy(action.data, _.identity);
                state.spottedInput = _.assign({}, _.pick(state.spottedInput, 'upload_file_list'), _.pick(state.spotted, formKeys));
                state.prevSpottedInput = _.memoize(_.pick)(state.spotted, formKeys);
                state.is_dirty = false;
                if (_.size(state.spottedInput.upload_file_list) > 0) {
                    state.executeUpload = true;
                }
                else {
                    lib_1.util.setToastMessage('success', 'Successfully Saved');
                    state.is_working = false;
                }
                updateSpottedStores();
                return state;
            }
            case 16: {
                state = JSON.parse(JSON.stringify(SpottedAddEditControl.initState));
                return state;
            }
            default: {
                return state;
            }
        }
    }, JSON.parse(JSON.stringify(SpottedAddEditControl.initState)));
})(SpottedAddEditControl = exports.SpottedAddEditControl || (exports.SpottedAddEditControl = {}));
