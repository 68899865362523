"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var _ = require("lodash");
var async = require("async");
var uri = require("urijs");
var Cruz = require("cruz");
var lib_1 = require("../../lib");
var component_1 = require("../../component");
var pages_1 = require("../../../routes/pages");
var cfg = require("../../../cfg");
var UserFollowing;
(function (UserFollowing) {
    function init(query, callback) {
        async.parallel({
            user: function (next) {
                lib_1.Request.user.profile(query.user_id, function (err, apiRes) {
                    next(err, apiRes.body.data);
                });
            },
            following: function (next) {
                lib_1.Request.follow.userFollowing(query, function (err, apiRes) {
                    next(err, apiRes.body);
                });
            }
        }, function (err, results) {
            return callback(err, results);
        });
    }
    UserFollowing.init = init;
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                is_loading: false,
                is_loading_more: false,
                user: null,
                following: {
                    meta: {},
                    data: null,
                    links: {}
                }
            };
            return _this;
        }
        Page.prototype.componentWillMount = function () {
            var _this = this;
            if (this.props.staticContext) {
                var d = _.assign({}, this.props.staticContext);
                this.setState(_.assign({}, this.state, d.result, { is_loading: false }));
            }
            else {
                var $initData_1 = document.querySelector('#_init_' + pages_1.pages.user_following.id);
                if ($initData_1 && $initData_1.getAttribute('data-init')) {
                    this.setState(_.assign({}, this.state, JSON.parse(decodeURIComponent($initData_1.getAttribute('data-init'))), { is_loading: false }), function () {
                        $initData_1.remove();
                        _this.setPageTitle();
                    });
                }
                else {
                    this.setState({ is_loading: true }, function () {
                        init(_.assign({}, cfg.apiQueryDefault.follow, _.pick(_this.props.match.params, ['user_id'])), function (err, result) {
                            if (err) {
                                _this.handleError(err);
                            }
                            else {
                                _this.setState(_.assign({}, _this.state, result, { is_loading: false }), function () {
                                    if (_this.state.user.status_id == Cruz.Lookup.CruzUser.StatusEnum.Closed || _this.state.user.status_id == Cruz.Lookup.CruzUser.StatusEnum.Disabled) {
                                        lib_1.Global.history.replace(pages_1.pages.error.path + '/410');
                                    }
                                    else {
                                        _this.setPageTitle();
                                    }
                                });
                            }
                        });
                    });
                }
            }
        };
        Page.prototype.componentDidMount = function () {
            lib_1.Global.saveUserSettings({ user_cruz_path: '/following' });
        };
        Page.prototype.componentWillUnmount = function () {
        };
        Page.prototype.handleError = function (err) {
            this.setState({ is_loading: false, is_loading_more: false });
            lib_1.util.handleError(lib_1.Request.getApiErrorCode(err));
        };
        Page.prototype.setPageTitle = function () {
            document.title = lib_1.util.title(this.state.user.first_name + ' ' + this.state.user.last_name);
        };
        Page.prototype.loadMore = function () {
            var _this = this;
            this.setState({ is_loading_more: true }, function () {
                lib_1.Request.follow.userFollowing(_.assign({}, uri.parseQuery(new uri(_this.state.following.links.next).query())), function (err, apiRes) {
                    if (err) {
                        _this.handleError(err);
                    }
                    else {
                        var d = apiRes.body;
                        _this.setState({
                            following: {
                                links: d.links,
                                meta: d.meta,
                                data: _.union(_this.state.following.data, d.data)
                            }
                        });
                        var tgtIndex = d.meta.page_size * (d.meta.page_number - 1);
                        if (tgtIndex > 0) {
                            lib_1.util.scrollTo('#_ss_' + tgtIndex, 70);
                        }
                    }
                });
            });
        };
        Page.prototype.renderContent = function () {
            var _this = this;
            var d = this.state.following;
            return (React.createElement(React.Fragment, null,
                React.createElement("h1", { className: 'c-title mb-3' },
                    pages_1.pages.user_following.title,
                    (d.meta.total_records > 0) &&
                        React.createElement("span", { className: 'c-title-count' }, d.meta.total_records.toLocaleString())),
                d.meta.total_records == 0 &&
                    React.createElement("div", { className: 'alert c-alert mt-3 text-center' }, "No following"),
                d.meta.total_records > 0
                    &&
                        React.createElement("div", { className: 'row c-sm-gutters' }, _.map(d.data, function (user, idx) {
                            return (React.createElement("div", { className: 'col-xl-4 col-lg-6 col-md-6 mt-2 mb-2', key: user._id, id: '_vs_' + idx },
                                React.createElement(component_1.User.SummaryCard, { user: user })));
                        })),
                (d.meta.total_records > 0 && d.links && d.links.next)
                    &&
                        React.createElement("div", { className: 'text-center mt-4' },
                            React.createElement("button", { className: 'btn c-btn-2-rev c-btn-round', onClick: function (e) { return _this.loadMore(); } },
                                React.createElement("i", { className: 'far fa-angle-double-down' }),
                                this.state.is_loading_more ? React.createElement(component_1.BtnWorking, { value: 'Loading More...' }) : React.createElement("span", null,
                                    "Load More (",
                                    d.meta.total_records_left.toLocaleString() + ' left',
                                    ")")))));
        };
        Page.prototype.render = function () {
            return (component_1.Layout.main(this.state.user, null, React.createElement("div", { className: 'container c-container' }, this.state.is_loading || _.isEmpty(this.state.user)
                ?
                    React.createElement(component_1.Loading, null)
                :
                    this.renderContent())));
        };
        return Page;
    }(React.Component));
    UserFollowing.Page = Page;
})(UserFollowing = exports.UserFollowing || (exports.UserFollowing = {}));
