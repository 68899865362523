"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var lib_1 = require("./lib");
var pages_1 = require("../routes/pages");
var component_1 = require("./component");
var About;
(function (About) {
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            return _super.call(this, props) || this;
        }
        Page.prototype.componentDidMount = function () {
            document.title = lib_1.util.title(pages_1.pages.about.title);
        };
        Page.prototype.render = function () {
            return (component_1.Layout.main(null, null, React.createElement(React.Fragment, null,
                React.createElement(component_1.Hero, { title: pages_1.pages.about.title }),
                React.createElement("div", { className: 'container c-container' },
                    React.createElement("section", { className: 'mb-4' },
                        React.createElement("h1", { className: 'c-title' }, pages_1.pages.about.title)),
                    React.createElement("p", null, "Cruz\u00AE is an online car community website that helps vehicle owners take control of vehicle ownership."),
                    React.createElement("p", null, "Founded in 2015 and privately owned, the Cruz\u00AE team continually provides tools to give vehicle owners the ultimate experience when managing their cars their way. Whether vehicle owners are buying and selling, want to connect with another exclusive member, view their vehicle history report or see the craziest cars out on the street, Cruz\u00AE is here to help take control.")))));
        };
        return Page;
    }(React.Component));
    About.Page = Page;
})(About = exports.About || (exports.About = {}));
