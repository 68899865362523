"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var request = require("superagent");
var _ = require("lodash");
var uri = require("urijs");
var Cruz = require("cruz");
var cfg = require("../../../cfg");
var lib_1 = require("../../lib");
var pages_1 = require("../../../routes/pages");
var component_1 = require("../../component");
var Signin;
(function (Signin) {
    var validate = require('validate.js');
    var rules = {
        'email': {
            presence: { message: '^Email is required.', allowEmpty: false },
            email: { message: '^Invalid email format.', allowEmpty: false }
        },
        'password': {
            presence: { message: '^Password is required.', allowEmpty: false }
        }
    };
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                auth: {
                    email: '',
                    password: '',
                    remember_me: false
                },
                is_working: false,
                is_email_bounced: false,
                user: {
                    status_id: Cruz.Lookup.CruzUser.StatusEnum.Active
                }
            };
            return _this;
        }
        Page.prototype.componentWillMount = function () {
            var _this = this;
            lib_1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    _this.redirectAuthed();
                }
            });
        };
        Page.prototype.componentDidMount = function () {
            document.title = lib_1.util.title(pages_1.pages.signin.title);
        };
        Page.prototype.redirectAuthed = function () {
            lib_1.Auth.redirectAuthed(uri.parseQuery(location.search)['returnUrl']);
        };
        Page.prototype.getError = function (key) {
            if (this.state.validation && this.state.validation[key]) {
                return _.first(this.state.validation[key]);
            }
            else {
                return null;
            }
        };
        Page.prototype.valid = function (callback) {
            this.setState({ validation: validate(this.state.auth, rules) }, function () {
                return callback();
            });
        };
        Page.prototype.valueChanged = function (tgt) {
            var _a;
            var _this = this;
            this.setState({ auth: _.assign({}, this.state.auth, (_a = {}, _a[tgt.id] = tgt.value, _a)) }, function () {
                _this.state.validation && _this.valid(function () { });
            });
        };
        Page.prototype.rememberMeChanged = function (tgt) {
            var _this = this;
            this.setState({ auth: _.assign({}, this.state.auth, { remember_me: tgt.checked }) }, function () {
                _this.state.validation && _this.valid(function () { });
            });
        };
        Page.prototype.submit = function (e) {
            var _this = this;
            e.preventDefault();
            this.valid(function () {
                lib_1.util.removeToastMessage();
                if (!_this.state.is_working && _.isEmpty(_this.state.validation)) {
                    _this.setState({ is_working: true }, function () {
                        request.post(lib_1.Global.cruzApiBaseUrl + '/user/authorize').timeout(cfg.superagent.timeout.normal)
                            .send(_this.state.auth)
                            .end(function (err, apiRes) {
                            if (err) {
                                var dErr = apiRes.body.error;
                                _this.setState({ is_working: false });
                                var d = apiRes.body.data;
                                if (_.isEmpty(d)) {
                                    lib_1.util.setToastMessage('error', dErr.detail);
                                }
                                else {
                                    _this.setState({ user: d });
                                }
                            }
                            else {
                                _this.setState({ is_working: false });
                                lib_1.Global.onSubmitSuccess('signin');
                                var d = apiRes.body.data;
                                lib_1.Global.store.dispatch({ type: lib_1.Global.actions.setUser, data: d });
                                lib_1.Auth.setRefreshToken(d.refresh_token, _this.state.auth.remember_me);
                                lib_1.Auth.setAccessToken(d.access_token);
                                lib_1.CruzSocket.reconnect();
                                if (!_.isEmpty(d.email_service) && !_.isEmpty(d.email_service.bounce) && d.email_service.bounce.email.toLowerCase() == d.email.toLowerCase()) {
                                    _this.setState({ is_email_bounced: true, user: d });
                                }
                                else {
                                    _this.redirectAuthed();
                                }
                            }
                        });
                    });
                }
            });
        };
        Page.prototype.renderSigninForm = function () {
            var _this = this;
            return (React.createElement("div", { className: 'text-center' },
                React.createElement("div", { className: 'c-alert-account mt-3' },
                    "Don't have an account?",
                    React.createElement("div", { className: 'd-block d-sm-none' }),
                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.register.path, className: 'c-btn-link ml-0 ml-sm-2' }, pages_1.pages.register.title)),
                React.createElement("form", { className: 'mt-4 text-left', id: 'formSignin', onSubmit: function (e) { return _this.submit(e); }, noValidate: true },
                    React.createElement(component_1.Text, { id: 'email', type: 'email', label: 'Email', required: true, "data-err": this.getError('email'), value: this.state.auth.email || '', onChange: function (e) { return _this.valueChanged(e.target); } }),
                    React.createElement(component_1.PasswordInput, { id: 'password', label: 'Password', required: true, "data-err": this.getError('password'), value: this.state.auth.password || '', onChange: function (e) { return _this.valueChanged(e.target); } }),
                    React.createElement(component_1.Checkbox, { id: 'remember_me', label: 'Keep me signed in', className: 'mt-3', checked: this.state.auth.remember_me ? true : false, onChange: function (e) { return _this.rememberMeChanged(e.target); } }),
                    React.createElement("div", { id: "_msg" }),
                    React.createElement("button", { type: 'submit', className: 'btn c-btn-1 btn-block mt-4' }, this.state.is_working ? React.createElement(component_1.BtnWorking, { value: 'Sign In ...' }) : 'Sign In')),
                React.createElement(react_router_dom_1.Link, { to: pages_1.pages.forgot_password.path, className: 'btn c-btn-2 c-btn-xs c-btn-round px-4 mt-4' }, "Forgot Password?")));
        };
        Page.prototype.renderEmailBounced = function () {
            var _this = this;
            return (React.createElement("div", { className: 'text-center' },
                React.createElement("div", { className: 'c-alert-error mt-3' },
                    "The email ",
                    this.state.user.email,
                    " was not reachable!"),
                React.createElement("div", { className: 'mt-4' },
                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.change_email.path, className: 'btn c-btn-1' }, "Change Email")),
                React.createElement("button", { type: 'button', className: 'btn c-btn-2 c-btn-xs c-btn-round px-4 mt-4', onClick: function (e) { return _this.redirectAuthed(); } }, "Skip")));
        };
        Page.prototype.render = function () {
            return (component_1.Layout.entry(1, React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'text-center' },
                    React.createElement("h1", { className: 'c-section-title' }, "Sign in")),
                this.state.user.status_id == Cruz.Lookup.CruzUser.StatusEnum.Disabled
                    &&
                        component_1.User.accountDisabled(),
                this.state.user.status_id == Cruz.Lookup.CruzUser.StatusEnum.Closed
                    &&
                        component_1.User.accountClosed(),
                this.state.user.status_id == Cruz.Lookup.CruzUser.StatusEnum.Pending
                    &&
                        React.createElement(component_1.User.accountPending, { email: this.state.auth.email }),
                this.state.is_email_bounced
                    &&
                        this.renderEmailBounced(),
                (!this.state.is_email_bounced && this.state.user.status_id > 0)
                    &&
                        this.renderSigninForm())));
        };
        return Page;
    }(React.Component));
    Signin.Page = Page;
})(Signin = exports.Signin || (exports.Signin = {}));
