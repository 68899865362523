"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var _ = require("lodash");
var uri = require("urijs");
var classNames = require("classnames");
var moment = require("moment");
var numeral = require("numeral");
var Cruz = require("cruz");
var cfg = require("../../../cfg");
var component_1 = require("../../component");
var lib_1 = require("../../lib");
var vehicle_add_edit_control_1 = require("./vehicle_add_edit_control");
var pages_1 = require("../../../routes/pages");
var VehicleAddEdit;
(function (VehicleAddEdit) {
    var emptyOption = [React.createElement("option", { value: '', key: '-1' })];
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            var _this = _super.call(this, props) || this;
            _this.sGrid = 'col-sm-6 col-md-4 col-xl-6';
            _this.state = {
                mileageInput: null
            };
            return _this;
        }
        Page.prototype.componentWillMount = function () {
            var _this = this;
            lib_1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    var locSearch = new uri(_this.props.location.search).search(true);
                    if (_.get(locSearch, '_id')) {
                        vehicle_add_edit_control_1.VehicleAddEditControl.getVehicleForUpdate(locSearch._id, function (err, result) {
                            if (lib_1.util.isClient()) {
                                _this.setState({ mileageInput: _.get(result, 'vehicle.mileage') });
                            }
                            vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 2, data: result });
                        });
                        _this.title = 'Edit Vehicle';
                    }
                    else {
                        vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 1 });
                        _this.title = 'Add Vehicle';
                    }
                }
                else {
                    lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                }
            });
        };
        Page.prototype.componentDidMount = function () {
            var _this = this;
            this.unsubscribe = vehicle_add_edit_control_1.VehicleAddEditControl.store.subscribe(function () { _this.forceUpdate(); });
            document.title = lib_1.util.title(this.title || 'Add Vehicle');
        };
        Page.prototype.componentWillUnmount = function () {
            vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 42 });
            this.unsubscribe && this.unsubscribe();
        };
        Page.prototype.renderPrice = function () {
            var ss = _.find(Cruz.Lookup.Vehicle.sale_status_list, { id: vehicle_add_edit_control_1.VehicleAddEditControl.store.getState().vehicleInput.sale_status_id });
            if (ss && ss.price_name) {
                return (React.createElement(component_1.MaskedText, { id: 'price', label: ss.price_name, required: ss.price_required, mask: lib_1.Mask.TypeEnum.Price, "data-err": this.getError('price'), "data-grid": this.sGrid, "data-input-pre-addon": '$', value: vehicle_add_edit_control_1.VehicleAddEditControl.store.getState().vehicleInput.price ? vehicle_add_edit_control_1.VehicleAddEditControl.store.getState().vehicleInput.price.toString() : '', maxLength: 8, onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 28, target: e.target }); } }));
            }
            else {
                return null;
            }
        };
        Page.prototype.mapTransmissionTypeOptions = function (ndo) {
            return _.union(emptyOption, _.map(ndo.transmission, function (item) {
                return React.createElement("option", { value: item.type, key: 'trans_' + item.type }, item.name);
            }));
        };
        Page.prototype.mapTransmissionGearsOptions = function (ndo, trans_type) {
            if (trans_type) {
                var x = _.find(ndo.transmission, { type: trans_type });
                if (x) {
                    return _.union(emptyOption, _.map(x.gears, function (item) {
                        return React.createElement("option", { value: item, key: 'trans_gears_' + item }, item);
                    }));
                }
                else {
                    return null;
                }
            }
            else {
                return null;
            }
        };
        Page.prototype.mapDriveTypeOptions = function (ndo) {
            return _.union(emptyOption, _.map(ndo.drive_type, function (item) {
                return React.createElement("option", { value: item.drive_type, key: 'drive_' + item.drive_type }, item.name);
            }));
        };
        Page.prototype.mapNumberArray = function (d) {
            return _.union(emptyOption, _.map(d, function (item) {
                return React.createElement("option", { value: item.toString(), key: item.toString() }, item);
            }));
        };
        Page.prototype.mapFuelTypeOptions = function (ndo) {
            return _.union(emptyOption, _.map(ndo.fuel_type, function (item) {
                return React.createElement("option", { value: item.fuel_type, key: 'drive_' + item.fuel_type }, item.name);
            }));
        };
        Page.prototype.mapEngineAspirationOptions = function (ndo) {
            return _.union(emptyOption, _.map(ndo.engine_aspiration, function (item) {
                return React.createElement("option", { value: item.type, key: 'aspiration' + item.type }, item.name);
            }));
        };
        Page.prototype.renderNoneDecodeOptions = function () {
            var ndo = vehicle_add_edit_control_1.VehicleAddEditControl.store.getState().noneDecodeOptions;
            var v = vehicle_add_edit_control_1.VehicleAddEditControl.store.getState().vehicleInput;
            var sGridsm = 'col-12 col-sm-6';
            if (v.ydata == null) {
                v.ydata = {};
            }
            if (_.isEmpty(_.omit(ndo, ['exterior_color', 'interior_color']))) {
                return null;
            }
            else {
                var disableDoors = false;
                if (v.ydata && v.ydata.body_type_code && v.ydata.body_type_code > 0) {
                    var x = vehicle_add_edit_control_1.VehicleAddEditControl.getBodyTypeByCode(v.ydata.body_type_code, vehicle_add_edit_control_1.VehicleAddEditControl.store.getState().noneDecodeOptions.body_type);
                    disableDoors = (x != null && x.doors != null);
                }
                var tags = _.map((v.year && v.year > 0) ? ndo.feature_tag.filter(function (tag) { return v.year >= tag.start_year; }) : ndo.feature_tag, 'name');
                return ((vehicle_add_edit_control_1.VehicleAddEditControl.store.getState().showNoneDecodeForm)
                    ?
                        React.createElement("div", { className: 'animated fadeIn' },
                            React.createElement("div", { className: 'text-center mt-2 mb-4' },
                                React.createElement("button", { type: 'button', className: 'c-btn-collapse', onClick: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 44 }); } },
                                    React.createElement("i", { className: 'fas fa-caret-up' }),
                                    React.createElement("span", null, "Less"))),
                            React.createElement("h2", { className: 'section-title' },
                                React.createElement("span", null, "Engine")),
                            React.createElement("div", { className: 'row' },
                                React.createElement(component_1.Select, { id: 'fuel_type', label: 'Fuel Type', options: this.mapFuelTypeOptions(ndo), "data-grid": sGridsm, size: 1, value: v.ydata.fuel_type || '', onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 47, target: e.target }); } }),
                                React.createElement(component_1.Select, { id: 'engine_cylinders', label: 'Cylinders', options: this.mapNumberArray(ndo.engine_cylinder), "data-grid": sGridsm, size: 1, value: (v.ydata.engine_cylinders != null) ? v.ydata.engine_cylinders.toString() : '', onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 46, target: e.target }); } }),
                                React.createElement(component_1.NumberInput, { id: 'engine_displacement', label: 'Displacement', "data-input-addon": 'L', "data-grid": sGridsm, min: 0, max: 12, step: 0.1, value: v.ydata.engine_displacement != null ? v.ydata.engine_displacement : '', onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 46, target: e.target }); }, "data-err": this.getError('ydata.engine_displacement') }),
                                React.createElement(component_1.Select, { id: 'engine_aspiration', label: 'Aspiration', options: this.mapEngineAspirationOptions(ndo), "data-grid": sGridsm, size: 1, value: (v.ydata && v.ydata.engine_aspiration) ? v.ydata.engine_aspiration.toString() : '', onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 47, target: e.target }); } }),
                                React.createElement(component_1.NumberInput, { id: 'max_hp', label: 'Max Horsepower', mask: lib_1.Mask.TypeEnum.Number, "data-grid": sGridsm, "data-input-addon": 'hp', value: v.ydata.max_hp != null ? v.ydata.max_hp : '', onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 46, target: e.target }); }, "data-err": this.getError('ydata.max_hp') }),
                                React.createElement(component_1.NumberInput, { id: 'max_torque', label: 'Max Torque', "data-input-addon": 'ft.lbf', "data-grid": sGridsm, value: v.ydata.max_torque != null ? v.ydata.max_torque : '', onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 46, target: e.target }); }, "data-err": this.getError('ydata.max_torque') })),
                            React.createElement("h2", { className: 'section-title' },
                                React.createElement("span", null, "Transmission")),
                            React.createElement("div", { className: 'row' },
                                React.createElement(component_1.Select, { id: 'trans_type', label: 'Transmission Type', options: this.mapTransmissionTypeOptions(ndo), "data-grid": this.sGrid, size: 1, value: v.ydata.trans_type || '', onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 47, target: e.target }); } }),
                                React.createElement(component_1.Select, { id: 'trans_gears', label: 'Transmission Gears', options: this.mapTransmissionGearsOptions(ndo, v.ydata.trans_type), "data-grid": this.sGrid, size: 1, value: (v.ydata && v.ydata.trans_gears != null) ? v.ydata.trans_gears.toString() : '', onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 46, target: e.target }); } })),
                            React.createElement("h2", { className: 'section-title' },
                                React.createElement("span", null, "Specifications")),
                            React.createElement("div", { className: 'row' },
                                React.createElement(component_1.Select, { id: 'doors', label: 'Doors', options: this.mapNumberArray(ndo.door), "data-grid": sGridsm, size: 1, disabled: disableDoors, value: v.ydata.doors != null ? v.ydata.doors.toString() : '', onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 46, target: e.target }); } }),
                                React.createElement(component_1.Select, { id: 'max_seating', label: 'Max Seating', size: 1, options: this.mapNumberArray(ndo.seating), "data-grid": sGridsm, value: v.ydata.max_seating != null ? v.ydata.max_seating.toString() : '', onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 46, target: e.target }); } }),
                                React.createElement(component_1.Select, { id: 'seating_rows', label: 'Seating Rows', options: this.mapNumberArray(ndo.seating_row), "data-grid": sGridsm, size: 1, value: v.ydata.seating_rows != null ? v.ydata.seating_rows.toString() : '', onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 46, target: e.target }); } }),
                                React.createElement(component_1.NumberInput, { id: 'acceleration', label: 'Acceleration', "data-note": '0-60 mph', "data-input-addon": 'sec', "data-grid": sGridsm, placeholder: 'Acceleration', value: v.ydata.acceleration != null ? v.ydata.acceleration : '', onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 46, target: e.target }); }, "data-err": this.getError('ydata.acceleration') }),
                                React.createElement(component_1.NumberInput, { id: 'wheel_dia', label: 'Wheel Diameter', "data-input-addon": 'inch', "data-grid": sGridsm, value: v.ydata.wheel_dia != null ? v.ydata.wheel_dia : '', onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 46, target: e.target }); }, "data-err": this.getError('ydata.wheel_dia') }),
                                React.createElement(component_1.Text, { id: 'tire_type', label: 'Tire Type', "data-grid": sGridsm, placeholder: 'ex. P205/55R16', maxLength: 20, value: v.ydata.tire_type || '', onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 47, target: e.target }); } })),
                            React.createElement("h2", { className: 'section-title' },
                                React.createElement("span", null,
                                    "Features (",
                                    _.size(vehicle_add_edit_control_1.VehicleAddEditControl.store.getState().vehicleInput.ydata.feature_tag_list) + '/' + _.size(tags),
                                    ")")),
                            React.createElement("ul", { className: 'list-inline' }, _.map(tags, function (tag, idx) {
                                return (React.createElement("li", { key: 'ft_' + idx, className: 'list-inline-item c-btn-checkbox' },
                                    React.createElement("input", { type: 'checkbox', autoComplete: 'off', name: 'feature_tag', id: 'ft_' + idx, value: tag, checked: _.indexOf(vehicle_add_edit_control_1.VehicleAddEditControl.store.getState().vehicleInput.ydata.feature_tag_list, tag) > -1, onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 48, target: e.target }); } }),
                                    React.createElement("label", { htmlFor: 'ft_' + idx }, tag)));
                            })),
                            React.createElement("div", { className: 'text-center' },
                                React.createElement("button", { type: 'button', className: 'btn c-btn-collapse', onClick: function () { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 44 }); } },
                                    React.createElement("i", { className: 'fas fa-caret-up' }),
                                    React.createElement("span", null, "Less"))))
                    :
                        React.createElement("div", { className: 'text-center mt-2' },
                            React.createElement("button", { type: 'button', className: 'btn c-btn-collapse', onClick: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 44 }); } },
                                React.createElement("i", { className: 'fas fa-caret-down' }),
                                React.createElement("span", null, "More"))));
            }
        };
        Page.prototype.renderSelectList = function () {
            var v = vehicle_add_edit_control_1.VehicleAddEditControl.store.getState().vehicleInput;
            return (_.map(vehicle_add_edit_control_1.VehicleAddEditControl.store.getState().dataone.options.select_list, function (item) {
                return (React.createElement(component_1.Select, { id: item.id, label: item.name, options: lib_1.util.mapSelectList(item), "data-grid": 'col-sm-6 col-lg-4 col-xl-6', key: item.id, size: 1, value: (!_.isEmpty(v.xdata) && v.xdata[item.id]) ? v.xdata[item.id] : '', onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 22, target: e.target }); } }));
            }));
        };
        Page.prototype.renderAllOptions = function (idPrefix) {
            var voc = vehicle_add_edit_control_1.VehicleAddEditControl.store.getState().dataone.options.vehicle_option;
            var o_list = vehicle_add_edit_control_1.VehicleAddEditControl.store.getState().vehicleInput.option_id_list;
            return (_.map(voc, function (cat) {
                return (React.createElement("div", { key: 'vo_all_cat_' + cat.category_id, className: 'mb-4' },
                    React.createElement("h3", { className: 'c-veh-option-category-title' },
                        cat.category_name,
                        React.createElement("span", { className: 'badge badge-pill c-badge-xs ml-2' }, vehicle_add_edit_control_1.VehicleAddEditControl.vehicleOptionCount(o_list, _.map(cat.option_list, 'option_id')))),
                    React.createElement("div", { className: 'row' }, _.map(cat.option_list, function (option) {
                        return (React.createElement(component_1.Checkbox, { key: 'vo_all_o_' + option.option_id, id: idPrefix + '_vo_all_o_' + option.option_id, checked: _.indexOf(o_list, option.option_id) > -1, onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 23, target: e.target }); }, "data-grid": 'col-xl-12', className: 'form-group-tight', "data-label-element": component_1.Vehicle.renderOptionLabel(option), title: option.option_description, value: option.option_id.toString() }));
                    }))));
            }));
        };
        Page.prototype.renderVehicleOptionEdit = function () {
            try {
                var voc_1 = vehicle_add_edit_control_1.VehicleAddEditControl.store.getState().dataone.options.vehicle_option;
                var t = _.sumBy(voc_1, 'option_list.length');
                var o_list_1 = vehicle_add_edit_control_1.VehicleAddEditControl.store.getState().vehicleInput.option_id_list;
                return (React.createElement("div", { className: 'mt-2' },
                    React.createElement("h2", { className: 'c-font-size-l c-font-weight-medium pb-2' },
                        "Select Installed Optional Features",
                        React.createElement("span", { className: 'c-font-size-m c-font-weight-medium ml-1' },
                            "(",
                            ((_.size(o_list_1) > 0) ? o_list_1.length : '-') + '/' + t.toLocaleString(),
                            ")")),
                    React.createElement("div", { className: 'c-veh-options-edit d-block d-sm-none pt-3 px-3' }, this.renderAllOptions('sm')),
                    React.createElement("div", { className: 'row no-gutters c-veh-options-edit d-none d-sm-flex' },
                        _.size(voc_1) > 1
                            &&
                                React.createElement("div", { className: 'col-sm-4 col-lg-3' },
                                    React.createElement("ul", { className: 'nav nav-tabs nav-tabs-left', role: 'tablist' },
                                        _.map(voc_1, function (cat, idx) {
                                            return (React.createElement("li", { key: 'vo_' + cat.category_id, className: 'nav-item' },
                                                React.createElement("a", { className: classNames('nav-link', idx == 0 ? 'active' : ''), "data-toggle": 'tab', href: '#vo_cat_' + cat.category_id, role: 'tab' },
                                                    cat.category_name,
                                                    React.createElement("span", { className: 'ml-1' },
                                                        "(",
                                                        vehicle_add_edit_control_1.VehicleAddEditControl.vehicleOptionCount(o_list_1, _.map(cat.option_list, 'option_id')),
                                                        ")"))));
                                        }),
                                        React.createElement("li", { className: 'nav-item' },
                                            React.createElement("a", { className: 'nav-link', href: '#vo_all', "data-toggle": 'tab', role: 'tab' },
                                                "All Optional Features",
                                                React.createElement("span", { className: 'ml-1' },
                                                    "(",
                                                    (o_list_1 && o_list_1.length > 0 ? o_list_1.length : '-') + '/' + t.toLocaleString(),
                                                    ")"))))),
                        React.createElement("div", { className: classNames({ 'col-sm-8 col-lg-9': _.size(voc_1) > 1 }) },
                            React.createElement("div", { className: 'tab-content' },
                                React.createElement("div", { className: 'tab-pane mx-4 my-3', id: 'vo_all' }, this.renderAllOptions('lg')),
                                _.map(voc_1, function (cat, idx) {
                                    return (React.createElement("div", { className: classNames('tab-pane mx-4 my-3', { active: idx == 0 }), id: 'vo_cat_' + cat.category_id, key: 'voc' + cat.category_id },
                                        _.size(voc_1) > 1
                                            &&
                                                React.createElement("h3", { className: 'c-veh-option-category-title' },
                                                    cat.category_name,
                                                    React.createElement("span", { className: 'ml-2' },
                                                        "(",
                                                        vehicle_add_edit_control_1.VehicleAddEditControl.vehicleOptionCount(o_list_1, _.map(cat.option_list, 'option_id')),
                                                        ")")),
                                        React.createElement("div", { className: 'row' }, _.map(cat.option_list, function (option) {
                                            return (React.createElement(component_1.Checkbox, { key: 'vo_o_' + option.option_id, id: 'vo_o_' + option.option_id, checked: _.indexOf(o_list_1, option.option_id) > -1, onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 23, target: e.target }); }, "data-grid": 'col-xl-12', "data-label-element": component_1.Vehicle.renderOptionLabel(option), className: 'form-group-tight', value: option.option_id.toString() }));
                                        }))));
                                }))))));
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.renderTrim = function () {
            var v = vehicle_add_edit_control_1.VehicleAddEditControl.store.getState().vehicleInput;
            var o = vehicle_add_edit_control_1.VehicleAddEditControl.store.getState().dataone.options;
            if ((v.make == '~' || v.model == '') || (v.year != null && v.year > 0 && _.isEmpty(o.trim)) || (!_.isEmpty(o.trim) && o.trim.length == 1 && _.isEmpty(_.first(o.trim)))) {
                return (React.createElement(component_1.Text, { id: 'trim', label: 'Trim', "data-grid": this.sGrid, maxLength: 30, value: v.trim || '', onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 18, target: e.target }); } }));
            }
            else {
                return (React.createElement(component_1.Select, { id: 'trim', label: 'Trim', "data-grid": this.sGrid, size: 1, disabled: (vehicle_add_edit_control_1.VehicleAddEditControl.store.getState().dataone.decode_method == 'vin' && o.trim.length == 1), value: v.trim || '', options: lib_1.util.mapOptions('Trim', o.trim), onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 18, target: e.target }); } }));
            }
        };
        Page.prototype.getError = function (key) {
            return ((vehicle_add_edit_control_1.VehicleAddEditControl.store.getState().validation && vehicle_add_edit_control_1.VehicleAddEditControl.store.getState().validation[key]) ? vehicle_add_edit_control_1.VehicleAddEditControl.store.getState().validation[key][0] : null);
        };
        Page.prototype.getServerError = function (key) {
            return vehicle_add_edit_control_1.VehicleAddEditControl.store.getState().validationServer ? vehicle_add_edit_control_1.VehicleAddEditControl.store.getState().validationServer[key] : null;
        };
        Page.prototype.validMileage = function (mileage) {
            var _this = this;
            var state = vehicle_add_edit_control_1.VehicleAddEditControl.store.getState();
            var v = state.vehicleInput;
            if (mileage == this.state.mileageInput) {
                return true;
            }
            else if (v.year > 0 && mileage > 0 && mileage < 1000) {
                var age = moment().year() - v.year;
                var shouldConfirmMileage = ((age == 1 && mileage < 20) || (age > 1 && age <= 4 && mileage <= 100) || (age > 4));
                if (shouldConfirmMileage) {
                    lib_1.util.setModal(null, false, false, React.createElement(component_1.Modal.MileageConfirm, { year: v.year, mileage: mileage, onYes: function () {
                            _this.setState({ mileageInput: mileage * 1000 });
                            vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 29, data: mileage * 1000 });
                        }, onNo: function () {
                            _this.setState({ mileageInput: mileage });
                        } }));
                }
                return !shouldConfirmMileage;
            }
            return true;
        };
        Page.prototype.onSubmit = function (e) {
            e.preventDefault();
            if (this.validMileage(_.get(vehicle_add_edit_control_1.VehicleAddEditControl.store.getState().vehicleInput, 'mileage'))) {
                vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 39, event: e });
            }
        };
        Page.prototype.renderForm = function () {
            var _this = this;
            var state = vehicle_add_edit_control_1.VehicleAddEditControl.store.getState();
            var opt = state.dataone.options;
            var v = state.vehicleInput;
            var dataone = state.dataone;
            return (React.createElement("form", { id: 'formVehicleAddEdit', onSubmit: function (e) { return _this.onSubmit(e); }, noValidate: true },
                React.createElement("div", { className: 'card card-form mt-3' },
                    React.createElement("div", { className: 'card-body' },
                        React.createElement("div", { className: 'row' },
                            React.createElement("div", { className: 'col-xl-6 order-xl-2 mb-3 mb-lg-0' },
                                React.createElement(component_1.Photo.PhotoEdit, __assign({ _id: v._id, api_url: v._id ? lib_1.Global.cruzApiBaseUrl + '/vehicle/' + v._id + '/photo' : '', blur_lp: true, blur_lp_checked: true, photo_base_url: v._id ? cfg.photoBaseUrl[cfg.env].s3 + '/vehicle/' + v.group + '/' + v._id : '', onUploadFileChanged: function (file_list) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 32, data: file_list }); }, onUploadFileDone: function (photo_list) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 34, data: photo_list }); }, onDeletePhotoDone: function (photo_list) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 34, data: photo_list }); }, onSortPhoto: function (ids) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 35, data: ids }); }, onWorking: function (status) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 37, data: status }); }, photo_list_classNames: 'col-xl-4 col-md-3 col-sm-4 col-xs-6' }, { photo_list: v.photo_list, executeUpload: vehicle_add_edit_control_1.VehicleAddEditControl.store.getState().executeUpload }))),
                            React.createElement("div", { className: 'col-xl-6 order-xl-1' },
                                React.createElement("div", { className: 'row c-sm-gutters ' },
                                    React.createElement(component_1.Select, { id: 'sale_status_id', label: 'Is Your Vehicle ...', "data-grid": this.sGrid, size: 1, required: true, "data-err": this.getError('sale_status_id'), value: v.sale_status_id != null ? v.sale_status_id.toString() : '', options: lib_1.util.mapSaleStatusOptions(Cruz.Lookup.Vehicle.sale_status_list), onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 27, target: e.target }); } }),
                                    this.renderPrice()),
                                React.createElement("div", { className: 'row c-sm-gutters mt-3' },
                                    React.createElement(component_1.VinInput, { id: 'vin', label: 'VIN', maxLength: 17, "data-grid": this.sGrid, value: v.vin || '', onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 13, target: e.target }); }, onBlur: function () { return vehicle_add_edit_control_1.VehicleAddEditControl.verifyVIN(); }, "data-err": this.getServerError('vin') }),
                                    React.createElement(component_1.MaskedText, { id: 'mileage', label: 'Mileage', mask: lib_1.Mask.TypeEnum.Number, "data-grid": this.sGrid, "data-input-addon": 'miles', size: 1, required: true, "data-err": this.getError('mileage'), value: v.mileage != null ? v.mileage.toString() : '', maxLength: 9, onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 29, data: _.get(e.target, 'value') }); }, onBlur: function (e) { return _this.validMileage(numeral(e.target.value).value()); } }),
                                    React.createElement(component_1.Select, { id: 'body_type_code', label: 'Type', required: true, disabled: dataone.decode_method == 'vin', "data-grid": this.sGrid, size: 1, value: v.body_type_code ? v.body_type_code.toString() : '', options: lib_1.util.mapBodyTypeOptions(Cruz.Lookup.Vehicle.body_type_search, false), onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 4, target: e.target }); } }),
                                    React.createElement(component_1.Select, { id: 'make', label: 'Make', required: true, "data-err": this.getError('make'), "data-grid": this.sGrid, size: 1, disabled: dataone.decode_method == 'vin' || _.isEmpty(opt.make), value: v.make || '', options: lib_1.util.mapOptionGroup('Make', opt.make, null, true), onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 15, target: e.target }); } }),
                                    v.make != '~' &&
                                        React.createElement(component_1.Select, { id: 'model', label: 'Model', required: true, "data-err": this.getError('model'), "data-grid": this.sGrid, size: 1, disabled: ((dataone.decode_method == 'vin' && opt.model && opt.model.all && opt.model.all.length == 1) || _.isEmpty(opt.model)), value: v.model || '', options: v.make != '' && lib_1.util.mapOptionGroup('Model', dataone.options.model, null, dataone.decode_method != 'vin'), onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 16, target: e.target }); } }),
                                    v.make == '~' &&
                                        React.createElement(component_1.Select, { id: 'brand', label: 'Brand', required: true, "data-err": this.getError('custom.brand'), "data-grid": this.sGrid, size: 1, options: lib_1.util.mapCustomBrandOptions(vehicle_add_edit_control_1.VehicleAddEditControl.store.getState().noneDecodeOptions.custom_brand), value: v.custom.brand || '', onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 49, target: e.target }); } }),
                                    React.createElement(component_1.Select, { id: 'year', label: 'Year', required: true, "data-err": this.getError('year'), "data-grid": this.sGrid, size: 1, disabled: dataone.decode_method == 'vin', value: v.year != null ? v.year.toString() : '', options: lib_1.util.mapOptions('Year', dataone.options.year || _.range(moment().year() + 1, 1900, -1)), onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 17, target: e.target }); } }),
                                    this.renderTrim(),
                                    (v.make == '~' || v.model == '~') &&
                                        React.createElement(component_1.Text, { id: 'title', label: 'Name', maxLength: 50, "data-grid": this.sGrid, value: v.custom.title || '', onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 49, target: e.target }); } }),
                                    (_.size(dataone.options.exterior_color) > 0) &&
                                        React.createElement(component_1.Select, { id: 'exterior_color', label: 'Exterior Color', required: true, "data-err": this.getError('exterior_color'), "data-grid": this.sGrid, size: 1, options: (_.has(_.first(dataone.options.exterior_color), 'ext_color_id')
                                                ?
                                                    lib_1.util.mapExteriorColorOptions('Exterior Color', dataone.options.exterior_color)
                                                :
                                                    lib_1.util.mapColorOptions('Exterior Color', dataone.options.exterior_color)), value: v.exterior_color ? (v.exterior_color.ext_color_id != null ? v.exterior_color.ext_color_id.toString() : v.exterior_color.color_group) : '', onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 19, target: e.target }); } }),
                                    (_.size(dataone.options.interior_color) > 0) &&
                                        React.createElement(component_1.Select, { id: 'interior_color', label: 'Interior Color', "data-grid": this.sGrid, size: 1, options: _.has(_.first(dataone.options.interior_color), 'int_color_id')
                                                ?
                                                    lib_1.util.mapInteriorColorOptions('Interior Color', dataone.options.interior_color)
                                                :
                                                    lib_1.util.mapColorOptions('Interior Color', dataone.options.interior_color), value: v.interior_color
                                                ?
                                                    (v.interior_color.int_color_id != null ? v.interior_color.int_color_id.toString() : v.interior_color.color_group)
                                                :
                                                    '', onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 20, target: e.target }); } }),
                                    (_.size(dataone.options.roof_color) > 0)
                                        &&
                                            React.createElement(component_1.Select, { id: 'roof_color', label: 'Roof Color', "data-grid": this.sGrid, size: 1, options: lib_1.util.mapRoofColorOptions('Roof Color', dataone.options.roof_color), value: (v.roof_color != null && v.roof_color.roof_color_id != null) ? v.roof_color.roof_color_id.toString() : '', onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 21, target: e.target }); } }),
                                    state.is_decoding &&
                                        React.createElement("div", { className: 'col-12' },
                                            React.createElement(component_1.Decoding, null)),
                                    (_.size(dataone.options.select_list) > 0) &&
                                        this.renderSelectList(),
                                    (dataone.options.transmission_list) &&
                                        React.createElement(component_1.Select, { id: 'trans_type', label: 'Transmission Type', options: this.mapTransmissionTypeOptions({ transmission: dataone.options.transmission_list }), "data-grid": this.sGrid, size: 1, value: v.ydata ? v.ydata.trans_type || '' : '', onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 47, target: e.target }); } }),
                                    (dataone.options.transmission_list) &&
                                        React.createElement(component_1.Select, { id: 'trans_gears', label: 'Transmission Gears', options: this.mapTransmissionGearsOptions({ transmission: dataone.options.transmission_list }, v.ydata ? v.ydata.trans_type : null), "data-grid": this.sGrid, size: 1, value: (v.ydata && v.ydata.trans_gears != null) ? v.ydata.trans_gears.toString() : '', onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 46, target: e.target }); } }),
                                    (dataone.decode_method == 'none' && !_.isEmpty(vehicle_add_edit_control_1.VehicleAddEditControl.store.getState().noneDecodeOptions) && !_.isEmpty(vehicle_add_edit_control_1.VehicleAddEditControl.store.getState().noneDecodeOptions.drive_type))
                                        &&
                                            React.createElement(component_1.Select, { id: 'drive_type', label: 'Drivetrain', size: 1, options: this.mapDriveTypeOptions(vehicle_add_edit_control_1.VehicleAddEditControl.store.getState().noneDecodeOptions), "data-grid": this.sGrid, value: v.ydata ? v.ydata.drive_type || '' : '', onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 47, target: e.target }); } })),
                                (dataone.decode_method == 'none' && !_.isEmpty(vehicle_add_edit_control_1.VehicleAddEditControl.store.getState().noneDecodeOptions)) &&
                                    this.renderNoneDecodeOptions(),
                                React.createElement("div", { className: 'mt-3' },
                                    React.createElement(component_1.Text, { id: 'highlight', label: 'Highlight', maxLength: 50, value: v.highlight || '', onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 24, target: e.target }); } }),
                                    React.createElement(component_1.Textarea, { id: 'description', label: 'Description', minRows: 2, maxRows: 10, maxLength: 5000, value: v.description || '', onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 25, target: e.target }); } }),
                                    React.createElement(component_1.Textarea, { id: 'warranty', label: 'Warranty', minRows: 2, maxRows: 10, maxLength: 5000, value: v.warranty || '', onChange: function (e) { return vehicle_add_edit_control_1.VehicleAddEditControl.store.dispatch({ type: 26, target: e.target }); } })))),
                        (dataone.options.vehicle_option && dataone.options.vehicle_option.length > 0) &&
                            this.renderVehicleOptionEdit()),
                    React.createElement("div", { className: 'card-footer' },
                        React.createElement("div", { className: 'd-flex justify-content-between align-items-center' },
                            React.createElement("div", null,
                                this.renderServiceQuoteLink(),
                                this.renderDetailLink()),
                            React.createElement("button", { type: 'submit', className: 'btn c-btn-1' }, vehicle_add_edit_control_1.VehicleAddEditControl.store.getState().is_working ? React.createElement(component_1.BtnWorking, { value: 'Saving ...' }) : 'Save'))))));
        };
        Page.prototype.renderDetailLink = function () {
            if (_.get(vehicle_add_edit_control_1.VehicleAddEditControl.store.getState().vehicle, '_id')) {
                var detailUrl = component_1.Vehicle.detailUrl(_.assign({}, _.get(vehicle_add_edit_control_1.VehicleAddEditControl.store.getState(), 'vehicle'), _.pick(lib_1.Global.store.getState().user, 'location')));
                if (detailUrl) {
                    return React.createElement(react_router_dom_1.Link, { to: detailUrl, className: 'btn c-btn-1-rev c-btn-xs' }, "View Detail");
                }
                else {
                    return null;
                }
            }
            else {
                return null;
            }
        };
        Page.prototype.renderServiceQuoteLink = function () {
            var locSearch = new uri(this.props.location.search).search(true);
            if (_.get(locSearch, 'service_quote') == '1') {
                return React.createElement(react_router_dom_1.Link, { to: pages_1.pages.service_quote_request.path + '?vehicle_id=' + _.get(locSearch, '_id') || '', className: 'mr-2 btn c-btn-1-rev c-btn-xs' }, "Request Service Quote");
            }
            else {
                return null;
            }
        };
        Page.prototype.render = function () {
            return (component_1.Layout.main(lib_1.Global.store.getState().user, null, React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'container c-container' }, vehicle_add_edit_control_1.VehicleAddEditControl.store == null
                    ?
                        React.createElement(component_1.Loading, null)
                    :
                        React.createElement(React.Fragment, null,
                            React.createElement("div", { className: 'd-flex justify-content-between' },
                                React.createElement("h1", { className: 'c-title' }, vehicle_add_edit_control_1.VehicleAddEditControl.store.getState().vehicle && vehicle_add_edit_control_1.VehicleAddEditControl.store.getState().vehicle._id ? 'Edit Vehicle' : this.title),
                                this.renderDetailLink()),
                            vehicle_add_edit_control_1.VehicleAddEditControl.store.getState().is_loading
                                ?
                                    React.createElement(component_1.Loading, null)
                                :
                                    this.renderForm())),
                React.createElement(component_1.Dirty, { when: vehicle_add_edit_control_1.VehicleAddEditControl.store != null && vehicle_add_edit_control_1.VehicleAddEditControl.store.getState().is_dirty }))));
        };
        return Page;
    }(React.Component));
    VehicleAddEdit.Page = Page;
})(VehicleAddEdit = exports.VehicleAddEdit || (exports.VehicleAddEdit = {}));
