"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var component_1 = require("../../component");
var lib_1 = require("../../lib");
var MyCruz;
(function (MyCruz) {
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            return _super.call(this, props) || this;
        }
        Page.prototype.componentDidMount = function () {
            lib_1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    lib_1.Global.history.replace(lib_1.Global.getMyCruzPath());
                }
                else {
                    lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                }
            });
        };
        Page.prototype.render = function () {
            return (component_1.Layout.main(lib_1.Global.store.getState().user, null, React.createElement(React.Fragment, null,
                React.createElement(component_1.Loading, null))));
        };
        return Page;
    }(React.Component));
    MyCruz.Page = Page;
})(MyCruz = exports.MyCruz || (exports.MyCruz = {}));
