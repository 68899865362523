"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var classNames = require("classnames");
var request = require("superagent");
var _ = require("lodash");
var lib_1 = require("../../lib");
var __1 = require("..");
var cfg = require("../../../cfg");
var Vote;
(function (Vote) {
    function openStatLikedModal(d) {
        lib_1.Auth.isAuthed(function (isAuthed) {
            if (isAuthed) {
                lib_1.util.setModal(null, false, true, React.createElement(LikedUserListModal, __assign({}, d)));
            }
            else {
                lib_1.util.setModal(null, false, false, React.createElement(__1.Modal.RequestAuth, null));
            }
        });
    }
    Vote.openStatLikedModal = openStatLikedModal;
    var LikedUserListModal = (function (_super) {
        __extends(LikedUserListModal, _super);
        function LikedUserListModal(props) {
            var _this = _super.call(this, props) || this;
            var gUser = lib_1.Global.store.getState().user;
            _this.state = {
                is_loading: true,
                user_list: null,
                my_following: gUser && gUser.follow ? gUser.follow.following_list : null
            };
            return _this;
        }
        LikedUserListModal.prototype.componentDidMount = function () {
            var _this = this;
            $('#_' + this.props._id).modal('show').on('hidden.bs.modal', function (e) {
                lib_1.util.removeModal(_this);
            });
            var reqUrl = lib_1.Global.cruzApiBaseUrl + '/like/' + this.props.name + '/' + this.props._id;
            if (this.props.list_name) {
                reqUrl += '/' + this.props.list_name + '/' + this.props.list_id;
            }
            reqUrl += '/users';
            request.get(reqUrl)
                .set(lib_1.Auth.authHeader())
                .timeout(cfg.superagent.timeout.normal)
                .end(function (err, apiRes) {
                if (err) {
                    _this.setState({ error: err, is_loading: false });
                }
                else {
                    _this.setState({ is_loading: false, user_list: apiRes.body.data });
                }
            });
        };
        LikedUserListModal.prototype.render = function () {
            var _this = this;
            return (React.createElement("div", { className: 'modal', id: '_' + this.props._id, tabIndex: -1, role: 'dialog' },
                React.createElement("div", { className: 'modal-dialog', role: 'document' },
                    React.createElement("div", { className: 'modal-content' },
                        React.createElement("div", { className: 'modal-header' },
                            (!this.state.is_loading && this.state.user_list)
                                &&
                                    React.createElement("h2", { className: 'modal-title' },
                                        this.state.user_list.length.toLocaleString(),
                                        React.createElement("span", { className: 'ml-2 c-font-size-s c-font-weight-normal' },
                                            "Like",
                                            this.state.user_list.length > 1 ? 's' : '')),
                            React.createElement("button", { type: 'button', "data-dismiss": 'modal', className: 'close' })),
                        React.createElement("div", { className: 'modal-body' }, this.state.is_loading
                            ?
                                React.createElement(__1.Loading, null)
                            :
                                React.createElement("ul", { className: 'list-group list-group-flush' }, _.map(this.state.user_list, function (ud) {
                                    return (React.createElement("li", { key: ud._id, className: 'list-group-item px-0' },
                                        React.createElement(__1.User.SummaryList, { user: ud, _id: _this.props._id })));
                                })))))));
        };
        return LikedUserListModal;
    }(React.Component));
    Vote.LikedUserListModal = LikedUserListModal;
    var LikeButton = (function (_super) {
        __extends(LikeButton, _super);
        function LikeButton(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                vote: _this.props.vote,
                is_working: false
            };
            return _this;
        }
        LikeButton.prototype.componentWillReceiveProps = function (nextProps) {
            this.setState({ vote: nextProps.vote });
        };
        LikeButton.prototype.toggleLike = function () {
            var _this = this;
            !this.state.is_working
                &&
                    lib_1.Auth.isAuthed(function (isAuthed) {
                        if (isAuthed) {
                            if (_this.props.user_id != lib_1.Global.store.getState().user._id) {
                                _this.setState({ is_working: true }, function () {
                                    var apiUrl = lib_1.Global.cruzApiBaseUrl + '/like/' + _this.props.name + '/' + _this.props._id;
                                    if (_this.props.list_name && _this.props.list_id) {
                                        apiUrl += '/' + _this.props.list_name + '/' + _this.props.list_id;
                                    }
                                    if (_this.isLiked()) {
                                        request.delete(apiUrl).set(lib_1.Auth.authHeader()).timeout(cfg.superagent.timeout.normal)
                                            .end(function (err, apiRes) {
                                            _this.setState({ is_working: false });
                                            if (!err) {
                                                _this.props.onVoteDone && _this.props.onVoteDone(apiRes.body.data);
                                            }
                                        });
                                    }
                                    else {
                                        request.post(apiUrl).set(lib_1.Auth.authHeader()).timeout(cfg.superagent.timeout.normal)
                                            .end(function (err, apiRes) {
                                            _this.setState({ is_working: false });
                                            if (!err) {
                                                _this.props.onVoteDone && _this.props.onVoteDone(apiRes.body.data);
                                            }
                                        });
                                    }
                                });
                            }
                        }
                        else {
                            lib_1.util.setModal(null, false, false, React.createElement(__1.Modal.RequestAuth, null));
                        }
                    });
        };
        LikeButton.prototype.isLiked = function () {
            var isLiked = false;
            var user = lib_1.Global.store.getState().user;
            if (!_.isEmpty(user) && !_.isEmpty(this.state.vote) && !_.isEmpty(this.state.vote.up) && !_.isEmpty(this.state.vote.up.user_list) && _.indexOf(this.state.vote.up.user_list, user._id) > -1) {
                isLiked = true;
            }
            return isLiked;
        };
        LikeButton.prototype.render = function () {
            var _this = this;
            var user = lib_1.Global.store.getState().user;
            var isMine = (!_.isEmpty(user) && user._id == this.props.user_id);
            var c = _.get(this.state, 'vote.up.count') || 0;
            var isLiked = this.isLiked();
            return (React.createElement("div", { className: 'btn-group' },
                React.createElement("button", { className: classNames(this.props.className || 'c-btn-link', { liked: isLiked }, { 'c-btn-disabled': isMine }), onClick: function () { return _this.toggleLike(); }, title: isLiked ? 'You liked this - Unlike' : isMine ? 'You cannot like your own post.' : 'Like' },
                    React.createElement("i", { className: classNames({ 'fas fa-thumbs-up c-fa-liked': isLiked }, { 'fal fa-thumbs-up': !isLiked }) }),
                    React.createElement("span", { className: 'c-count-prepend' }, c.toLocaleString()),
                    React.createElement("span", { className: 'c-font-size-xxs' },
                        "Like",
                        c > 1 ? 's' : ''),
                    this.state.is_working
                        &&
                            React.createElement("i", { className: 'far fa-spinner fa-pulse ml-2' })),
                (c > 0)
                    &&
                        React.createElement("button", { className: 'c-btn-open-stat c-btn-xs', onClick: function () { return openStatLikedModal(_this.props); } },
                            React.createElement("i", { className: 'fal fa-list-ul' }))));
        };
        return LikeButton;
    }(React.Component));
    Vote.LikeButton = LikeButton;
})(Vote = exports.Vote || (exports.Vote = {}));
