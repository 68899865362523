"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var io = require("socket.io-client");
var uri = require("urijs");
var moment = require("moment");
var cfg = require("../../../cfg");
var __1 = require("..");
var CruzSocket;
(function (CruzSocket) {
    CruzSocket.socket = null;
    function connect() {
        __1.Global.store.dispatch({ type: __1.Global.actions.setSocketStatus, data: 1 });
        __1.Auth.isAuthed(function () {
            var cruzSocketUrl = cfg.socketUrl[cfg.env] + '/cruz';
            var cruzSocketOption = {
                transports: ['websocket'],
                reconnectionDelay: 2000,
                reconnectionDelayMax: 10000,
                query: {
                    token: (__1.util.cookie.get('access_token') || '')
                }
            };
            CruzSocket.socket = io(cruzSocketUrl, cruzSocketOption);
            CruzSocket.socket.on('connect', function () {
                __1.Global.store.dispatch({ type: __1.Global.actions.setSocketStatus, data: 2 });
                __1.Auth.isAuthed(function (isAuthed) {
                    var room = __1.util.getRoom(new uri(__1.util.getRelativeUrl()).pathname());
                    if (room) {
                        CruzSocket.socket.emit('join_room', room);
                    }
                    if (isAuthed) {
                        __1.Chat.store.dispatch({ type: 2 });
                    }
                });
            });
            CruzSocket.socket.on('reconnecting', function () {
                __1.Global.store.dispatch({ type: __1.Global.actions.setSocketStatus, data: 1 });
            });
            CruzSocket.socket.on('reconnect', function () {
                __1.Global.store.dispatch({ type: __1.Global.actions.setSocketStatus, data: 2 });
            });
            CruzSocket.socket.on('connect_timeout', function () {
                __1.Global.store.dispatch({ type: __1.Global.actions.setSocketStatus, data: -1 });
            });
            CruzSocket.socket.on('error', function (err) {
                __1.Global.store.dispatch({ type: __1.Global.actions.setSocketStatus, data: -1 });
            });
            CruzSocket.socket.on('connect_error', function () {
                __1.Global.store.dispatch({ type: __1.Global.actions.setSocketStatus, data: -1 });
            });
            CruzSocket.socket.on('reconnect_error', function () {
                __1.Global.store.dispatch({ type: __1.Global.actions.setSocketStatus, data: -1 });
            });
            CruzSocket.socket.on('disconnect', function () {
                __1.Global.store.dispatch({ type: __1.Global.actions.setSocketDisconnectTime, data: moment().toISOString() });
            });
            CruzSocket.socket.on('online_user', function (d) {
                __1.Statistic.store.dispatch({ type: __1.Statistic.actions.setOnlineUser, data: d });
            });
            CruzSocket.socket.on('statistic', function (d) {
                __1.Statistic.store.dispatch({ type: __1.Statistic.actions.setStatistic, data: d });
            });
            CruzSocket.socket.on('newNotification', function (d) {
                __1.Global.store.dispatch({ type: __1.Global.actions.setNewNotification, data: d });
            });
        });
    }
    CruzSocket.connect = connect;
    function reconnect() {
        if (CruzSocket.socket) {
            CruzSocket.socket.disconnect();
        }
        connect();
    }
    CruzSocket.reconnect = reconnect;
    function changeRoom(leave, join) {
        if (CruzSocket.socket) {
            CruzSocket.socket.emit('change_room', { leave: leave, join: join });
        }
    }
    CruzSocket.changeRoom = changeRoom;
})(CruzSocket = exports.CruzSocket || (exports.CruzSocket = {}));
