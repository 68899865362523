"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_navigation_prompt_1 = require("react-router-navigation-prompt");
var lib_1 = require("../../lib");
exports.Dirty = function (props) {
    function show() {
        setTimeout(function () {
            $('#_dirty').modal('show');
        }, 10);
    }
    function hide() {
        $('#_dirty').modal('hide');
    }
    return (React.createElement(react_router_navigation_prompt_1.default, { renderIfNotActive: true, when: props.when }, function (_a) {
        var isActive = _a.isActive, onCancel = _a.onCancel, onConfirm = _a.onConfirm;
        if (lib_1.util.isClient() && isActive) {
            show();
        }
        return (React.createElement("div", { className: 'modal', id: '_dirty', tabIndex: -1, role: 'dialog', "data-backdrop": 'static', "data-keyboard": false },
            React.createElement("div", { className: 'modal-dialog', role: 'document' },
                React.createElement("div", { className: 'modal-content' },
                    React.createElement("div", { className: 'modal-header' },
                        React.createElement("h2", { className: 'modal-title' }, props.title || 'Leaving this page?')),
                    React.createElement("div", { className: 'modal-body' }, "Are you sure you want to leave?"),
                    React.createElement("div", { className: 'modal-footer' },
                        React.createElement("button", { type: 'button', className: 'btn c-btn-xs c-btn-2 mr-2', onClick: function () { hide(); onConfirm(); } }, "Leave"),
                        React.createElement("button", { type: 'button', className: 'btn c-btn-xs c-btn-1 mr-2', onClick: function () { hide(); onCancel(); } }, "Stay"))))));
    }));
};
