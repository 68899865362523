"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var _ = require("lodash");
var lib_1 = require("../../lib");
var Vendor;
(function (Vendor) {
    var Hero = (function (_super) {
        __extends(Hero, _super);
        function Hero(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                is_working: false
            };
            return _this;
        }
        Hero.prototype.componentDidMount = function () {
            var _this = this;
            this.unsubscribe = lib_1.Global.store.subscribe(function () { return _this.forceUpdate(); });
        };
        Hero.prototype.componentWillUnmount = function () {
            this.unsubscribe && this.unsubscribe();
        };
        Hero.prototype.render = function () {
            var d = this.props.vendor;
            var loc = _.first(d.location_list);
            try {
                return (React.createElement("div", { className: 'c-vendor-hero d-flex align-items-center' },
                    React.createElement("div", { className: 'container my-4' },
                        React.createElement("div", { className: 'c-name ellipsis' }, !_.isEmpty(loc.name) ? loc.name : d.name),
                        React.createElement("div", { className: 'mt-1 d-flex flex-row align-items-center' },
                            React.createElement("i", { className: 'fal fa-map-marker fa-fw mr-2' }),
                            React.createElement("a", { href: 'https://www.google.com/maps/search/' + encodeURI(loc.formatted_address), target: '_blank', className: 'c-link-3' },
                                !_.isEmpty(loc.address1) &&
                                    React.createElement("span", { className: 'd-block d-sm-inline' },
                                        _.startCase(loc.address1.toLowerCase()),
                                        React.createElement("span", { className: 'd-none d-sm-inline' }, ", ")),
                                loc.city + ', ' + loc.state + ' ' + loc.zip_code)),
                        !_.isEmpty(d.web_site) &&
                            React.createElement("div", { className: 'ellipsis mt-2 mt-sm-1 d-flex flex-row align-items-center' },
                                React.createElement("i", { className: 'fal fa-link fa-fw mr-2' }),
                                React.createElement("a", { href: 'http://' + d.web_site, target: '_blank', className: 'c-link-3' }, d.web_site)),
                        !_.isEmpty(loc.phone) &&
                            React.createElement("div", { className: 'ellipsis mt-2 mt-sm-1 d-flex flex-row align-items-center' },
                                React.createElement("i", { className: 'fal fa-phone fa-fw mr-2' }),
                                React.createElement("a", { href: 'tel:' + loc.phone, className: 'c-link-3' }, loc.phone)))));
            }
            catch (err) {
                return null;
            }
        };
        return Hero;
    }(React.Component));
    Vendor.Hero = Hero;
    function renderVendorDisplaySummary(vd, loc) {
        try {
            var distance = _.get(loc, 'distance');
            return (React.createElement("div", { className: 'c-vd' },
                React.createElement("div", { className: 'c-name ellipsis' }, _.get(loc, 'name') ? loc.name : vd.name),
                React.createElement("div", { className: 'c-description ellipsis' },
                    loc.city + ', ' + loc.state + ' ' + loc.zip_code,
                    (distance != null && distance > 0) &&
                        React.createElement("span", { className: 'ml-1' },
                            "(",
                            (distance > 5 ? '\u2248' + ' ' + distance.toLocaleString() : '< 5') + ' miles',
                            ")"))));
        }
        catch (err) {
            return null;
        }
    }
    Vendor.renderVendorDisplaySummary = renderVendorDisplaySummary;
})(Vendor = exports.Vendor || (exports.Vendor = {}));
