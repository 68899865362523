"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var request = require("superagent");
var moment = require("moment");
var numeral = require("numeral");
var Cruz = require("cruz");
var cfg = require("../../../cfg");
var lib_1 = require("../../lib");
var pages_1 = require("../../../routes/pages");
var RegisterControl;
(function (RegisterControl) {
    RegisterControl.initState = {
        user: {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            location: {},
            phone: ''
        },
        own_vehicle: true,
        vehicle: {
            vin: '',
            xdata: {},
            ydata: {},
            custom: {}
        },
        initUserData: {
            user: {
                first_name: '',
                last_name: '',
                email: '',
                password: '',
                location: {},
                phone: ''
            },
            own_vehicle: true,
            vehicle: {
                vin: '',
                xdata: {},
                ydata: {},
                custom: {}
            }
        },
        options: {
            year: _.range(moment().year() + 1, 1900, -1)
        },
        sale_status: {},
        decode_method: null,
        vinDecodeData: {},
        reverseDecodeData: {},
        noneDecodeOptions: {},
        validationServer: {
            email: null,
            zip_code: null
        },
        is_working: false,
        is_registered: false,
        referral: {
            code: '',
            is_valid: true,
            user: {}
        },
        is_account_exist_not_verified: false,
        executeUpload: false,
        upload_file_list: []
    };
    var validate = require('validate.js');
    var rules = {
        user: {
            'user.first_name': {
                presence: { message: '^First name is required.', allowEmpty: false },
            },
            'user.last_name': {
                presence: { message: '^Last name is required.', allowEmpty: false }
            },
            'user.email': {
                presence: { message: '^Email is required.', allowEmpty: false },
                email: { message: '^Invalid email format.' }
            },
            'user.password': {
                presence: { message: '^Password is required.', allowEmpty: false },
                length: {
                    minimum: 6,
                    tooShort: '^At least 6 characters.'
                }
            },
            'user.location.zip_code': {
                presence: { message: '^Zip Code is required.', allowEmpty: false }
            }
        },
        vehicle: {
            'vehicle.make': {
                presence: { message: '^Make is required.', allowEmpty: false }
            },
            'vehicle.model': {
                presence: { message: '^Model is required.', allowEmpty: false }
            },
            'vehicle.year': {
                presence: { message: '^Year is required.', allowEmpty: false },
            },
            'vehicle.mileage': {
                presence: { message: '^Mileage is required.', allowEmpty: false },
                numericality: { greaterThan: 0, message: '^Mileage must be greater than 0.' }
            },
            'vehicle.exterior_color': {
                presence: { message: '^Exterior Color is required.', allowEmpty: false }
            },
            'vehicle.sale_status_id': {
                presence: { message: '^This is required.', allowEmpty: false }
            }
        }
    };
    var rulesCustom = {
        'vehicle.custom.brand': {
            presence: { message: '^Brand is required.', allowEmpty: false }
        }
    };
    function verifyZipCode(zip_code) {
        lib_1.Request.geo.getZipCode(zip_code, function (err, apiRes) {
            if (apiRes.status == 200) {
                if (apiRes.body.data && !_.isEmpty(apiRes.body.data)) {
                    RegisterControl.store.dispatch({ type: 5, data: null });
                }
                else {
                    RegisterControl.store.dispatch({ type: 5, data: 'Invalid Zip Code' });
                }
            }
        });
    }
    RegisterControl.verifyZipCode = verifyZipCode;
    function verifyEmail(email) {
        if (validate.single(email, { presence: { allowEmpty: false }, email: true }) == null) {
            lib_1.Request.user.verifyEmail(email, null, function (err, apiRes) {
                if (err) {
                    RegisterControl.store.dispatch({ type: 3, data: apiRes.body.error });
                }
                else {
                    RegisterControl.store.dispatch({ type: 3, data: null });
                }
            });
        }
    }
    RegisterControl.verifyEmail = verifyEmail;
    function getReferral(code) {
        lib_1.Request.user.getReferralByCode(code, function (err, apiRes) {
            RegisterControl.store.dispatch({ type: 29, data: (err ? null : apiRes.body.data) });
        });
    }
    RegisterControl.getReferral = getReferral;
    var allVehicleKeys = ['vehicle_id', 'body_type_code', 'make', 'model', 'year', 'trim', 'exterior_color', 'xdata'];
    var noneDecodeKeys = [];
    RegisterControl.app = function (state, action) {
        var _a, _b;
        var tgt = action.target;
        function valid() {
            var vr = _.assign({}, rules.vehicle);
            var _rules = null;
            if (state.own_vehicle) {
                _rules = _.assign({}, rules.user, vr, (state.vehicle.make == '~' && rulesCustom));
            }
            else {
                _rules = rules.user;
            }
            state.validation = _.assign({}, validate(_.pick(state, ['user', 'vehicle']), _rules));
        }
        function getMakeOptions() {
            lib_1.Request.dataone.makesGroup({ body_type_code: state.vehicle.body_type_code }, function (err, apiRes) {
                err ? RegisterControl.store.dispatch({ type: 0, error: apiRes.body.error }) : RegisterControl.store.dispatch({ type: 11, data: apiRes.body.data });
            });
        }
        function getModelOptions(d) {
            if (d.make && d.make != '') {
                lib_1.Request.dataone.modelsGroup(d, function (err, apiRes) {
                    err ? RegisterControl.store.dispatch({ type: 0, error: apiRes.body.error }) : RegisterControl.store.dispatch({ type: 13, data: apiRes.body.data });
                });
            }
        }
        function getTrimOptions(d) {
            if (d.make && d.make != '' && d.model && d.model != '' && d.year != null && d.year > 1980) {
                lib_1.Request.dataone.trims(d, function (err, apiRes) {
                    err ? RegisterControl.store.dispatch({ type: 0, error: apiRes.body.error }) : RegisterControl.store.dispatch({ type: 16, data: apiRes.body.data });
                });
            }
        }
        function getColorOptions(d) {
            if (d.make && d.make != '' && d.model && d.model != '' && d.year != null && d.year > 1980) {
                lib_1.Request.dataone.exteriorColors(d, function (err, apiRes) {
                    if (!err && !_.isEqual(apiRes.body.data, state.options.exterior_color)) {
                        RegisterControl.store.dispatch({ type: 19, data: apiRes.body.data });
                    }
                });
            }
        }
        function getNoneDecodeOptions() {
            lib_1.Request.dataone.noneDecodeOptions(function (err, apiRes) {
                err ? RegisterControl.store.dispatch({ type: 0, error: apiRes.body.error }) : RegisterControl.store.dispatch({ type: 32, data: apiRes.body.data });
            });
        }
        function reverseDecode() {
            if (!state.is_decoding) {
                state.is_decoding = true;
                var q = _.pick(state.vehicle, ['make', 'model', 'year', 'trim']);
                if (isHD()) {
                    lib_1.Request.dataone.reverseDecode(_.assign(q, { is_hd: true }), function (err, apiRes) {
                        err ? RegisterControl.store.dispatch({ type: 0, error: apiRes.body.error }) : RegisterControl.store.dispatch({ type: 18, data: apiRes.body.data });
                    });
                }
                else {
                    lib_1.Request.dataone.reverseDecode(q, function (err, apiRes) {
                        err ? RegisterControl.store.dispatch({ type: 0, error: apiRes.body.error }) : RegisterControl.store.dispatch({ type: 18, data: apiRes.body.data });
                    });
                }
            }
        }
        function getDecodedTrimOptions() {
            var d = { vehicle_list: [], select_list: [] };
            var x = _.find(state.vinDecodeData.model_list, { 'model': state.vehicle.model });
            if (x) {
                var y = _.find(x.trim_list, { 'trim': state.vehicle.trim });
                if (y) {
                    return _.pick(y, ['vehicle_list', 'select_list']);
                }
                else {
                    return d;
                }
            }
            else {
                return d;
            }
        }
        function resolveVehicleId() {
            var vList = state.options.vehicle_list;
            var sList = state.options.select_list;
            var xdata = state.vehicle.xdata || {};
            var xdataKeys = [];
            if (!_.isEmpty(vList) && vList.length > 1 && !_.isEmpty(sList) && sList.length > 0 && !_.isEmpty(xdata)) {
                _.each(_.keys(xdata), function (id) {
                    var sl = _.find(sList, { id: id });
                    if (!_.isEmpty(sl)) {
                        if (id == 'trans_id' || id == 'engine_id') {
                            vList = _.reject(vList, function (v) {
                                var _a;
                                return (!_.find(v[sl.key], (_a = {}, _a[sl.id] = xdata[id], _a)));
                            });
                        }
                        else {
                            vList = _.reject(vList, function (v) {
                                return v[sl.key] != xdata[id];
                            });
                        }
                        xdataKeys.push(id);
                        if (vList.length == 1) {
                            return false;
                        }
                    }
                });
            }
            var vehicle_id = null;
            if (!_.isEmpty(vList) && vList.length == 1) {
                var v_1 = vList[0];
                vehicle_id = v_1.vehicle_id;
                state.vehicle.vehicle_id = vehicle_id;
                var x = _.filter(sList, function (item) { return !xdataKeys.includes(item.id); });
                var oxData_1 = {};
                _.each(x, function (item) {
                    var _a;
                    var d = v_1[item.key];
                    if (!_.isEmpty(d) && d.length == 1) {
                        _.assign(oxData_1, (_a = {}, _a[item.id] = d[0][item.id], _a));
                    }
                });
                _.extend(state.vehicle.xdata, oxData_1);
            }
        }
        function isHD() {
            if (!state.vehicle.body_type_code) {
                return false;
            }
            else {
                var x = _.find(Cruz.Lookup.Vehicle.body_type_search, { code: state.vehicle.body_type_code });
                return (x && x.is_hd == true);
            }
        }
        switch (action.type) {
            case 0: {
                state.is_working = false;
                state.is_decoding = false;
                lib_1.util.setToastMessage('error', action.error.detail);
                return state;
            }
            case 1: {
                if (state.referral.code) {
                    getReferral(state.referral.code);
                }
                return state;
            }
            case 28: {
                var code = tgt.value;
                code = code.trim().toUpperCase();
                state.referral.code = code;
                lib_1.Global.referral_code = code;
                if (code.length == 7) {
                    getReferral(code);
                }
                else if (state.referral.user) {
                    state.referral.user = {};
                    state.referral.is_valid = false;
                }
                return state;
            }
            case 29: {
                state.referral.user = action.data;
                if (state.referral.user) {
                    state.user.referral_id = state.referral.user._id;
                    state.referral.is_valid = true;
                }
                else {
                    state.user.referral_id = null;
                    state.referral.is_valid = false;
                }
                return state;
            }
            case 2: {
                state.user = _.assign({}, state.user, (_a = {}, _a[tgt.id] = tgt.value, _a));
                state.validation && valid();
                return state;
            }
            case 3: {
                var err = action.data;
                if (err) {
                    if (err.type == 'AccountPending') {
                        state.is_account_exist_not_verified = true;
                    }
                    state.validationServer.email = err.detail;
                }
                else {
                    state.validationServer.email = null;
                    state.is_account_exist_not_verified = false;
                }
                return state;
            }
            case 4: {
                if (_.isEmpty(tgt.value)) {
                    state.user.location = {};
                }
                else {
                    var zip_code = lib_1.Mask.UnmaskedValue(tgt.value, lib_1.Mask.TypeEnum.ZipCode);
                    state.user.location.zip_code = zip_code;
                    if (zip_code.length == 5) {
                        verifyZipCode(zip_code);
                    }
                }
                state.validation && valid();
                return state;
            }
            case 5: {
                state.validationServer.zip_code = action.data;
                return state;
            }
            case 6: {
                state.own_vehicle = !tgt.checked;
                return state;
            }
            case 7: {
                if (_.isEmpty(tgt.value)) {
                    state.vehicle.xdata = _.assign({}, _.omit(state.vehicle.xdata, tgt.id));
                }
                else {
                    state.vehicle.xdata = _.assign({}, state.vehicle.xdata, (_b = {}, _b[tgt.id] = lib_1.util.isNumeric(tgt.value) ? Number(tgt.value) : tgt.value, _b));
                }
                resolveVehicleId();
                return state;
            }
            case 8: {
                var vin = _.toUpper(_.trim(tgt.value));
                state.vehicle = _.assign({}, state.vehicle, { vin: vin });
                state.options.select_list = [];
                state.vehicle.xdata = {};
                if (vin.length >= 17) {
                    state.is_decoding = true;
                    lib_1.Request.dataone.vinDecode(vin, null, function (err, apiRes) {
                        err ? RegisterControl.store.dispatch({ type: 0, error: apiRes.body.error }) : RegisterControl.store.dispatch({ type: 9, data: apiRes.body.data });
                    });
                }
                else {
                    state.decode_method = null;
                    if (!_.isEmpty(state.vinDecodeData)) {
                        state.vinDecodeData = {};
                        state.options.model = {};
                        state.options.trim = [];
                        state.options.exterior_color = [];
                        state.vehicle = _.omit(state.vehicle, allVehicleKeys);
                    }
                }
                return state;
            }
            case 9: {
                state.is_decoding = false;
                var d = _.assign({}, action.data);
                state.vinDecodeData = d;
                state.vehicle = _.omit(state.vehicle, 'interior_color', 'option_id_list', 'roof_color');
                state.vehicle.xdata = {};
                if (_.isEmpty(state.vinDecodeData)) {
                    state.decode_method = null;
                    state.vehicle = _.omit(state.vehicle, allVehicleKeys);
                    state.options = _.pick(state.options, 'body_type', 'year', 'sale_status');
                }
                else {
                    state.decode_method = 'vin';
                    var v = {
                        body_type_code: d.body_type_code,
                        make: d.make,
                        model: _.size(d.model_list) == 1 ? d.model_list[0].model : '',
                        year: d.year,
                        trim: '',
                    };
                    state.options = {
                        is_hd: (d.is_hd == true),
                        make: { all: [v.make] },
                        model: { all: _.map(d.model_list, function (item) { return item.model; }) },
                        trim: []
                    };
                    _.assign(state.vehicle, v);
                    if (!_.isEmpty(v.model)) {
                        if (d.is_hd) {
                            _.assign(state.options, d.model_list[0].hd);
                        }
                        else {
                            state.options.trim = _.map(d.model_list[0].trim_list, function (item) { return item.trim; });
                            if (_.size(state.options.trim) == 1) {
                                state.vehicle.trim = _.first(state.options.trim);
                                _.assign(state.options, getDecodedTrimOptions());
                                resolveVehicleId();
                            }
                        }
                    }
                    var hasReference = !_.isEmpty(d.reference);
                    if (hasReference) {
                        state.vehicle = _.assign({}, state.vehicle, d.reference);
                    }
                    if (_.isEmpty(state.vehicle.trim)) {
                        state.vehicle = _.omit(state.vehicle, ['vehicle_id', 'exterior_color', 'interior_color', 'roof_color', 'option_id_list', 'is_hd']);
                        state.vehicle.xdata = {};
                    }
                    else {
                        if (!hasReference) {
                            resolveVehicleId();
                        }
                        getColorOptions(_.pick(state.vehicle, ['make', 'model', 'year', 'trim', 'vehicle_id']));
                    }
                }
                state.validation && valid();
                return state;
            }
            case 10: {
                state.vehicle = _.assign({}, _.omit(state.vehicle, _.without(allVehicleKeys, 'body_type_code')), { body_type_code: Number(tgt.value) });
                state.vehicle.xdata = {};
                state.options.make = {};
                state.options.model = {};
                state.options.trim = [];
                state.options.exterior_color = [];
                state.options.select_list = [];
                var x = _.find(Cruz.Lookup.Vehicle.body_type_search, { code: state.vehicle.body_type_code });
                state.options.is_hd = (x && x.is_hd && x.is_hd == true);
                getMakeOptions();
                return state;
            }
            case 11: {
                state.options.make = action.data;
                return state;
            }
            case 12: {
                state.vehicle = _.assign({}, _.omit(state.vehicle, _.without(allVehicleKeys, 'body_type_code', 'make', 'year')), { make: tgt.value });
                state.vehicle.xdata = {};
                state.options = _.assign({}, _.pick(state.options, ['body_type', 'make', 'year', 'sale_status']));
                if (tgt.value && tgt.value != '' && tgt.value != '~') {
                    getModelOptions(_.pick(state.vehicle, ['body_type_code', 'make']));
                }
                else if (tgt.value == '~') {
                    state.vehicle.model = '~';
                    state.decode_method = 'none';
                    state.options.trim = null;
                    if (_.isEmpty(state.noneDecodeOptions)) {
                        getNoneDecodeOptions();
                    }
                    else {
                        state.options.exterior_color = _.assign({}, state.noneDecodeOptions.exterior_color);
                    }
                }
                if (tgt.value != '~') {
                    state.vehicle.custom = {};
                }
                state.validation && valid();
                return state;
            }
            case 13: {
                state.options.model = action.data;
                if (state.options.model && state.options.model.all.length == 1) {
                    state.vehicle.model = _.first(state.options.model.all);
                }
                return state;
            }
            case 14: {
                state.vehicle = _.assign({}, _.omit(state.vehicle, _.without(allVehicleKeys, 'body_type_code', 'make', 'model', 'year')), { model: tgt.value });
                if (isHD()) {
                    if (state.decode_method == 'vin') {
                        var x = null;
                        x = _.find(state.vinDecodeData.model_list, { 'model': state.vehicle.model });
                        if (!_.isEmpty(x)) {
                            _.assign(state.options, x.hd);
                        }
                        resolveVehicleId();
                    }
                    else {
                        var year = state.vehicle.year;
                        if (year && year > 1900 && year <= moment().year() + 1) {
                            reverseDecode();
                        }
                    }
                    getColorOptions(_.pick(state.vehicle, ['make', 'model', 'year']));
                }
                else {
                    if (state.decode_method == 'vin') {
                        if (_.isEmpty(tgt.value)) {
                            state.options.trim = [];
                        }
                        else {
                            var trim_list = _.map(_.find(state.vinDecodeData.model_list, { model: state.vehicle.model })['trim_list'], function (item) { return item.trim; });
                            state.options.trim = trim_list;
                            if (trim_list && trim_list.length == 1) {
                                state.vehicle.trim = trim_list[0];
                                state.options = _.assign({}, state.options, getDecodedTrimOptions());
                                resolveVehicleId();
                            }
                            getColorOptions(_.pick(state.vehicle, ['make', 'model', 'year', 'trim']));
                        }
                    }
                    else {
                        state.options = _.pick(state.options, ['body_type', 'make', 'model', 'year', 'sale_status']);
                        if (!_.isEmpty(tgt.value) && tgt.value != '~' && state.vehicle.year) {
                            var q = _.pick(state.vehicle, ['make', 'model', 'year']);
                            getTrimOptions(q);
                            getColorOptions(q);
                        }
                        else if (tgt.value == '~') {
                            state.decode_method = 'none';
                            if (_.isEmpty(state.noneDecodeOptions)) {
                                getNoneDecodeOptions();
                            }
                            else {
                                state.options.exterior_color = state.noneDecodeOptions.exterior_color;
                            }
                        }
                    }
                }
                state.validation && valid();
                return state;
            }
            case 15: {
                var keys = ['body_type_code', 'make', 'model', 'year', 'trim', 'exterior_color'];
                var year = Number(tgt.value);
                state.vehicle = _.assign({}, _.omit(state.vehicle, _.without.apply(_, __spreadArrays([allVehicleKeys], keys))), { year: year });
                if (year != null && year > 1900 && year <= moment().year() + 1) {
                    state.options.select_list = [];
                    state.options.vehicle_list = [];
                    state.vehicle.xdata = {};
                    if (year > 1980 && state.vehicle.make != '~' && state.vehicle.model != '~') {
                        if (state.vehicle.make && state.vehicle.make != '' && state.vehicle.model && state.vehicle.model != '') {
                            var q = _.pick(state.vehicle, keys);
                            if (isHD()) {
                                reverseDecode();
                            }
                            else {
                                getTrimOptions(q);
                            }
                            getColorOptions(q);
                        }
                    }
                    else {
                        state.decode_method = 'none';
                        state.options.trim = null;
                        if (_.isEmpty(state.noneDecodeOptions)) {
                            getNoneDecodeOptions();
                        }
                        else {
                            state.options.exterior_color = state.noneDecodeOptions.exterior_color;
                        }
                    }
                }
                state.validation && valid();
                return state;
            }
            case 16: {
                state.options.trim = action.data;
                if (state.options.trim == null || state.options.trim.length == 0) {
                    state.decode_method = 'none';
                    var omitKeys = _.union(_.without(allVehicleKeys, 'body_type_code', 'make', 'model', 'year'));
                    state.vehicle = _.omit(state.vehicle, omitKeys);
                    state.options.select_list = [];
                    state.options.vehicle_list = [];
                    if (_.isEmpty(state.noneDecodeOptions)) {
                        getNoneDecodeOptions();
                    }
                }
                else {
                    state.noneDecodeOptions = {};
                    var omitKeys = _.union(_.without(allVehicleKeys, 'body_type_code', 'make', 'model', 'year'), noneDecodeKeys);
                    state.vehicle = _.omit(state.vehicle, omitKeys);
                    if (state.decode_method != 'vin') {
                        state.decode_method = 'reverse';
                    }
                    if (state.options.trim.length == 1) {
                        _.assign(state.vehicle, { trim: action.data[0] });
                        getColorOptions(_.pick(state.vehicle, ['make', 'model', 'year', 'trim']));
                        if (state.decode_method == 'vin') {
                            _.assign(state.options, getDecodedTrimOptions());
                        }
                        else {
                            state.options.select_list = [];
                            state.options.vehicle_list = [];
                            reverseDecode();
                        }
                    }
                }
                return state;
            }
            case 17: {
                if (tgt.type == 'text') {
                    _.assign(state.vehicle, { trim: tgt.value });
                }
                else {
                    var keys = ['body_type_code', 'make', 'model', 'year', 'trim', 'exterior_color'];
                    state.vehicle = _.assign({}, _.omit(state.vehicle, _.without.apply(_, __spreadArrays([allVehicleKeys], keys))), { trim: tgt.value });
                    state.options = _.pick(state.options, ['body_type', 'make', 'model', 'year', 'trim', 'sale_status']);
                    getColorOptions(_.pick(state.vehicle, ['make', 'model', 'year', 'trim']));
                    if (state.decode_method == 'vin') {
                        _.assign(state.options, getDecodedTrimOptions());
                    }
                    else {
                        state.options = _.omit(state.options, ['vehicle_list', 'select_list']);
                        state.vehicle.xdata = {};
                        if (!_.isEmpty(tgt.value)) {
                            reverseDecode();
                        }
                    }
                    resolveVehicleId();
                }
                state.validation && valid();
                return state;
            }
            case 18: {
                state.is_decoding = false;
                if (_.isEmpty(action.data)) {
                    state.decode_method = 'none';
                    state.options = _.omit(state.options, ['select_list', 'vehicle_list', 'transmission_list']);
                }
                else {
                    state.decode_method = 'reverse';
                    state.reverseDecodeData = _.assign({}, action.data);
                    _.assign(state.options, _.pick(action.data, ['select_list', 'vehicle_list', 'transmission_list']));
                    if (state.reverseDecodeData.vehicle_list && state.reverseDecodeData.vehicle_list.length == 1) {
                        state.vehicle.vehicle_id = state.reverseDecodeData.vehicle_list[0].vehicle_id;
                    }
                }
                return state;
            }
            case 19: {
                state.options.exterior_color = action.data;
                if (_.size(state.options.exterior_color) == 1) {
                    state.vehicle.exterior_color = state.options.exterior_color[0];
                }
                else {
                    state.vehicle.exterior_color = _.find(state.options.exterior_color, { ext_color_id: _.get(state.vehicle, 'exterior_color.ext_color_id') }) || null;
                }
                return state;
            }
            case 20: {
                if (tgt.value != null && tgt.value != '') {
                    if (lib_1.util.isNumeric(tgt.value)) {
                        state.vehicle.exterior_color = _.find(state.options.exterior_color, { 'ext_color_id': Number(tgt.value) });
                    }
                    else {
                        state.vehicle.exterior_color = { 'color_group': tgt.value };
                    }
                }
                else {
                    state.vehicle.exterior_color = null;
                }
                state.validation && valid();
                return state;
            }
            case 32: {
                state.noneDecodeOptions = action.data;
                state.options.exterior_color = state.noneDecodeOptions.exterior_color;
                return state;
            }
            case 21: {
                if (_.isNil(action.data)) {
                    state.vehicle.mileage = null;
                }
                else {
                    state.vehicle.mileage = numeral(action.data).value();
                }
                state.validation && valid();
                return state;
            }
            case 35: {
                state.vehicle.custom[tgt.id] = tgt.value;
                state.validation && valid();
                return state;
            }
            case 22: {
                var val = Number(tgt.value.replace(/[^0-9]/g, ''));
                if (isNaN(val) || val == 0) {
                    state.vehicle.price = null;
                }
                else {
                    state.vehicle.price = val;
                }
                state.validation && valid();
                return state;
            }
            case 24: {
                if (tgt.value != null && tgt.value != '') {
                    state.vehicle.sale_status_id = Number(tgt.value);
                    state.sale_status = _.find(Cruz.Lookup.Vehicle.sale_status_list, { id: state.vehicle.sale_status_id });
                }
                else {
                    state.sale_status = null;
                    state.vehicle = _.omit(state.vehicle, 'sale_status_id');
                }
                var ss = _.find(Cruz.Lookup.Vehicle.sale_status_list, { id: state.vehicle.sale_status_id });
                if (ss && ss.price_required) {
                    rules['vehicle']['vehicle.price'] = { presence: { message: '^Price is required.', allowEmpty: false } };
                }
                else {
                    delete rules['vehicle']['vehicle.price'];
                }
                state.validation && valid();
                return state;
            }
            case 23: {
                state.vehicle.description = tgt.value;
                return state;
            }
            case 33: {
                state.upload_file_list = action.data;
                return state;
            }
            case 31: {
                if (tgt.value && tgt.value != '') {
                    state.vehicle.ydata[tgt.id] = Number(tgt.value);
                }
                else {
                    state.vehicle.ydata[tgt.id] = null;
                }
                return state;
            }
            case 30: {
                state.vehicle.ydata[tgt.id] = tgt.value;
                return state;
            }
            case 25: {
                action.event.preventDefault();
                valid();
                if ((state.validation == null || _.isEmpty(state.validation) == true) && state.validationServer.email == null && state.validationServer.zip_code == null) {
                    state.is_working = true;
                    var vehicle_list_1 = [];
                    if (state.vinDecodeData && !_.isEmpty(state.vinDecodeData) && state.vehicle.trim && state.vehicle.trim != '') {
                        var trim = _.find(_.find(state.vinDecodeData.model_list, { model: state.vehicle.model }).trim_list, { trim: state.vehicle.trim });
                        if (trim.select_list != null && trim.select_list.length > 0) {
                            vehicle_list_1 = trim.vehicle_list;
                        }
                    }
                    else if (state.reverseDecodeData && !_.isEmpty(state.reverseDecodeData)) {
                        vehicle_list_1 = state.reverseDecodeData.vehicle_list;
                    }
                    if (state.vehicle.xdata && vehicle_list_1.length > 1) {
                        _.keys(state.vehicle.xdata).forEach(function (key) {
                            if (key == 'trans_id') {
                                _.forEach(vehicle_list_1, function (v) {
                                    if (!_.find(v.transmission_list, { trans_id: state.vehicle.xdata.trans_id })) {
                                        vehicle_list_1 = vehicle_list_1.filter(function (vx) { return vx.vehicle_id != v.vehicle_id; });
                                    }
                                });
                            }
                            else if (key == 'engine_id') {
                                _.forEach(vehicle_list_1, function (v) {
                                    if (!_.find(v.engine_list, { engine_id: state.vehicle.xdata.engine_id })) {
                                        vehicle_list_1 = vehicle_list_1.filter(function (vx) { return vx.vehicle_id != v.vehicle_id; });
                                    }
                                });
                            }
                            else {
                                vehicle_list_1 = _.filter(vehicle_list_1, function (v) {
                                    return v[key] == state.vehicle.xdata[key];
                                });
                            }
                        });
                    }
                    if (vehicle_list_1.length == 1) {
                        state.vehicle.vehicle_id = vehicle_list_1[0].vehicle_id;
                    }
                    request.post(lib_1.Global.cruzApiBaseUrl + '/user/register')
                        .set('region', lib_1.util.getRegion())
                        .timeout(cfg.superagent.timeout.normal)
                        .send({ user: state.user, vehicle: state.own_vehicle ? state.vehicle : null })
                        .end(function (err, apiRes) {
                        if (err) {
                            lib_1.util.setToastMessage('error', apiRes.body.error && (apiRes.body.error.detail || ''));
                            RegisterControl.store && RegisterControl.store.dispatch({ type: 27 });
                        }
                        else {
                            RegisterControl.store && RegisterControl.store.dispatch({ type: 26, data: apiRes.body.data });
                            lib_1.Global.onSubmitSuccess('register');
                        }
                    });
                }
                else {
                    if (!_.isEmpty(state.validation)) {
                        lib_1.util.setFocus('#' + _.last(_.keys(state.validation)[0].split('.')));
                    }
                    else if (state.validationServer.email != null) {
                        lib_1.util.setFocus('#email');
                    }
                    else if (state.validationServer.zip_code != null) {
                        lib_1.util.setFocus('#zip_code');
                    }
                }
                return state;
            }
            case 27: {
                state.is_working = false;
                return state;
            }
            case 26: {
                var user = action.data.user;
                state.user._id = user._id;
                state.vehicle._id = action.data.vehicle._id;
                if (_.size(state.upload_file_list) > 0) {
                    lib_1.Global.temp_access_token = user.temp_access_token;
                    state.executeUpload = true;
                }
                else {
                    state.is_working = false;
                    state.is_registered = true;
                    setTimeout(function () {
                        lib_1.Global.history.push(pages_1.pages.registered.path + '/' + state.user._id);
                    }, 10);
                }
                return state;
            }
            case 34: {
                state.executeUpload = false;
                state.is_working = false;
                state.is_registered = true;
                setTimeout(function () {
                    lib_1.Global.history.push(pages_1.pages.registered.path + '/' + state.user._id);
                }, 10);
                return state;
            }
            default:
                return state;
        }
    };
    RegisterControl.store = null;
})(RegisterControl = exports.RegisterControl || (exports.RegisterControl = {}));
