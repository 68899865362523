"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Redux = require("redux");
var _ = require("lodash");
var request = require("superagent");
var uri = require("urijs");
var Push = require('push.js');
var $ = require("jquery");
var moment = require("moment");
var pages_1 = require("../../../routes/pages");
var socket_1 = require("./socket");
var cfg = require("../../../cfg");
var __1 = require("..");
var user_1 = require("../../user");
var Chat;
(function (Chat) {
    function requestNotificationPermission() {
        Push.Permission.request(function () {
            Chat.store.dispatch({ type: 25, data: true });
        }, function () {
            Chat.store.dispatch({ type: 25, data: false });
        });
    }
    Chat.requestNotificationPermission = requestNotificationPermission;
    Chat.cs = null;
    var initState = {
        chat_list: null,
        active_chat_id: null,
        message: {
            text: ''
        },
        typing_list: [],
        sending_list: [],
        is_creating_chat: false,
        is_deleting_chat: false,
        is_loading_more_message: false,
        is_more_message_loaded: false,
        show_new_message_button: false,
        show_request_permission: false,
        show_chat_panel: false
    };
    function joinChatRoom(chat_id) {
        user_1.Messaging.scrollMessageToBottom();
        user_1.Messaging.registerMetaImageLoad();
        if (Chat.cs) {
            chat_id = (chat_id || Chat.store.getState().active_chat_id);
            if (chat_id != null) {
                var chat = getChatById(chat_id);
                if (chat.message_count > _.size(chat.message_list)) {
                    user_1.Messaging.onMessageScroll();
                }
                setTimeout(function () {
                    if ($('.cc-left').is(':visible') == false) {
                        window.scrollTo(0, $('#c-mobile-footer').outerHeight());
                    }
                    $('#cc-chat-text').focus();
                }, 10);
                Chat.cs.emit('joinChatRoom', chat_id);
            }
        }
    }
    Chat.joinChatRoom = joinChatRoom;
    function leaveChatRoom() {
        user_1.Messaging.offMessageScroll();
        if (Chat.cs) {
            Chat.cs.emit('leaveChatRoom', Chat.store.getState().active_chat_id);
        }
    }
    Chat.leaveChatRoom = leaveChatRoom;
    var typingCanPublish = true;
    function typing() {
        if (typingCanPublish) {
            var typing_1 = {
                chat_id: Chat.store.getState().active_chat_id,
                user_id: __1.Global.store.getState().user._id
            };
            typingCanPublish = false;
            Chat.cs.emit('typing', typing_1);
            setTimeout(function () {
                typingCanPublish = true;
            }, 1000);
        }
    }
    Chat.typing = typing;
    function chatViewed(chat_id) {
        if (Chat.cs) {
            Chat.cs.emit('chatViewed', chat_id);
        }
    }
    Chat.chatViewed = chatViewed;
    function chatWithUser(user, referralUrl) {
        var me = __1.Global.store.getState().user;
        if (!_.isEmpty(me)) {
            var existChat_1 = null;
            if (_.size(Chat.store.getState().chat_list) > 0) {
                var my_user_id_1 = __1.Global.store.getState().user._id;
                Chat.store.getState().chat_list.forEach(function (chat) {
                    if (chat.user_list.length == 2) {
                        var ids = _.map(chat.user_list, '_id');
                        if (ids.indexOf(user._id) > -1 && ids.indexOf(my_user_id_1) > -1) {
                            existChat_1 = _.assign({}, chat);
                            return;
                        }
                    }
                });
            }
            if (_.isEmpty(existChat_1)) {
                Chat.store.dispatch({ type: 3, data: user });
            }
            else {
                Chat.store.dispatch({ type: 8, data: existChat_1._id });
            }
            __1.Global.history.push(pages_1.pages.messaging.path);
        }
    }
    Chat.chatWithUser = chatWithUser;
    function isMe(user_id) {
        if (user_id == __1.Global.store.getState().user._id) {
            return true;
        }
        else {
            return false;
        }
    }
    function regListeners() {
        Chat.cs = socket_1.CruzSocket.socket;
        Chat.cs.on('cruz_error', function (err) {
            Chat.store.dispatch({ type: 0, error: err });
        });
        Chat.cs.on('createChat', function (d) {
            Chat.store.dispatch({ type: 4, data: d });
        });
        var typingTimeout = [];
        Chat.cs.on('typing', function (d) {
            clearTimeout(typingTimeout[d.chat_id + '_' + d.user_id]);
            typingTimeout[d.chat_id + '_' + d.user_id] = setTimeout(function () {
                Chat.store.dispatch({ type: 13, data: d });
            }, 2000);
            Chat.store.dispatch({ type: 12, data: d });
        });
        Chat.cs.on('getChat', function (d) {
            Chat.store.dispatch({ type: 6, data: d });
        });
        Chat.cs.on('newMessage', function (d) {
            Chat.store.dispatch({ type: 16, data: d });
        });
        Chat.cs.on('onlineStatus', function (d) {
            Chat.store.dispatch({ type: 22, data: d });
        });
        Chat.cs.on('newMessageMeta', function (d) {
            Chat.store.dispatch({ type: 17, data: d });
        });
        Chat.cs.on('chatViewed', function (d) {
            Chat.store.dispatch({ type: 11, data: d });
        });
    }
    function getChatById(chat_id) {
        return _.find(Chat.store.getState().chat_list, { _id: chat_id });
    }
    Chat.getChatById = getChatById;
    function getUserById(chat_id, user_id) {
        return _.find(getChatById(chat_id).user_list, { _id: user_id });
    }
    function getActiveChat() {
        if (Chat.store.getState().active_chat_id) {
            return _.find(Chat.store.getState().chat_list, { _id: Chat.store.getState().active_chat_id });
        }
        else {
            return null;
        }
    }
    Chat.getActiveChat = getActiveChat;
    function deleteChat(chat_id) {
        __1.Auth.isAuthed(function (isAuthed) {
            if (isAuthed) {
                request.delete(cfg.socketUrl[process.env.NODE_ENV] + '/chat/' + chat_id)
                    .set(__1.Auth.authHeader())
                    .timeout(cfg.superagent.timeout.normal)
                    .end(function (err, apiRes) {
                    err ? Chat.store.dispatch({ type: 0, error: apiRes.body.error }) : Chat.store.dispatch({ type: 24, data: apiRes.body.data });
                });
            }
            else {
                __1.Auth.redirectSignin(__1.util.getRelativeUrl());
            }
        });
    }
    function getNewChat(chat_id) {
        __1.Auth.isAuthed(function (isAuthed) {
            if (isAuthed) {
                request.get(cfg.socketUrl[process.env.NODE_ENV] + '/chat/' + chat_id)
                    .set(__1.Auth.authHeader())
                    .retry(2)
                    .timeout(cfg.superagent.timeout.normal)
                    .end(function (err, apiRes) {
                    err ? Chat.store.dispatch({ type: 0, error: apiRes.body.error }) : Chat.store.dispatch({ type: 5, data: apiRes.body.data });
                });
            }
            else {
                __1.Auth.redirectSignin(__1.util.getRelativeUrl());
            }
        });
    }
    function getChatList() {
        __1.Auth.isAuthed(function (isAuthed) {
            if (isAuthed) {
                request.get(cfg.socketUrl[process.env.NODE_ENV] + '/chat')
                    .set(__1.Auth.authHeader())
                    .timeout(cfg.superagent.timeout.normal)
                    .end(function (err, apiRes) {
                    err ? Chat.store.dispatch({ type: 0, error: apiRes.body.error }) : Chat.store.dispatch({ type: 7, data: apiRes.body.data });
                });
            }
            else {
                __1.Auth.redirectSignin(location ? location.pathname + location.search : '');
            }
        });
    }
    function loadMoreMessage(last_message_id) {
        __1.Auth.isAuthed(function (isAuthed) {
            if (isAuthed) {
                var chat = getActiveChat();
                if (chat.message_count > _.size(chat.message_list)) {
                    var query = {
                        page_size: cfg.apiQueryDefault.chat_message.page_size,
                        last_message_id: last_message_id
                    };
                    request.get(cfg.socketUrl[process.env.NODE_ENV] + '/chat/' + chat._id + '/message' + __1.util.buildApiQueryString(query))
                        .set(__1.Auth.authHeader())
                        .timeout(cfg.superagent.timeout.normal)
                        .end(function (err, apiRes) {
                        err ? Chat.store.dispatch({ type: 0, error: apiRes.body.error }) : Chat.store.dispatch({ type: 21, data: apiRes.body.data });
                    });
                }
            }
            else {
                __1.Auth.redirectSignin(location ? location.pathname + location.search : '');
            }
        });
    }
    Chat.store = Redux.createStore(function (state, action) {
        function setNewMessageAlert() {
            var userHasNewMessage = _.find(_.flatten(_.map(state.chat_list, 'user_list')), function (user) {
                return (user._id == __1.Global.store.getState().user._id && user.chat_log.new_message_count > 0);
            });
            __1.Global.store.dispatch({ type: __1.Global.actions.setHasNewMessage, data: userHasNewMessage != null || false });
        }
        switch (action.type) {
            case 14: {
                state.message.text = action.data;
                return state;
            }
            case 0: {
                __1.util.setToastMessage('error', 'Something is wrong.');
                state.is_loading_more_message = false;
                state.is_deleting_chat = false;
                state.is_creating_chat = false;
                return state;
            }
            case 2: {
                if (state.chat_list == null) {
                    getChatList();
                    regListeners();
                }
                else if (state.chat_list != null && __1.Global.store.getState().socket_disconnect_time != null && moment().diff(moment(__1.Global.store.getState().socket_disconnect_time), 'minutes') > 10) {
                    getChatList();
                }
                else if (state.active_chat_id) {
                    joinChatRoom(state.active_chat_id);
                }
                state.show_request_permission = __1.util.shouldShowRequestNotificationPermission();
                return state;
            }
            case 7: {
                state.chat_list = action.data;
                if (_.size(state.chat_list) > 0) {
                    setNewMessageAlert();
                    if ($('.c-chat').length > 0) {
                        var gUser_1 = __1.Global.store.getState().user;
                        var qs_1 = new uri().search(true);
                        if (qs_1._id && getChatById(qs_1._id)) {
                            state.active_chat_id = qs_1._id;
                            __1.Global.saveUserSettings({ chat_id: state.active_chat_id });
                        }
                        else if (gUser_1 && gUser_1.settings && gUser_1.settings.chat_id && getChatById(gUser_1.settings.chat_id) != null) {
                            state.active_chat_id = gUser_1.settings.chat_id;
                        }
                        else {
                            var _chat = _.find(state.chat_list, function (d) {
                                var x = _.find(d.user_list, { _id: gUser_1._id, is_organizer: true });
                                return (x != null || d.message_list.length > 0);
                            });
                            if (_chat != null) {
                                state.active_chat_id = _chat._id;
                                __1.Global.store.dispatch({ type: __1.Global.actions.setUserSettings, data: { chat_id: _chat._id } });
                                __1.Global.saveUserSettings({ chat_id: _chat._id });
                            }
                        }
                        if (state.active_chat_id) {
                            chatViewed(state.active_chat_id);
                        }
                        joinChatRoom();
                    }
                }
                return state;
            }
            case 1: {
                state = JSON.parse(JSON.stringify(initState));
                return state;
            }
            case 25: {
                if (action.data != null) {
                    localStorage.setItem('notification_permission', action.data);
                }
                state.show_request_permission = false;
                return state;
            }
            case 20: {
                if (state.is_loading_more_message == false) {
                    var x = getChatById(state.active_chat_id);
                    if (!_.isEmpty(x)) {
                        var loadedCount = _.size(x.message_list);
                        if (loadedCount < x.message_count) {
                            if (loadedCount < cfg.chatMessageViewLimit) {
                                state.is_loading_more_message = true;
                                loadMoreMessage(_.first(x.message_list).id);
                            }
                        }
                    }
                }
                return state;
            }
            case 21: {
                var d = Object.assign({}, action.data);
                var scrollToId = null;
                if (d && d.message_list) {
                    var x = getChatById(d._id);
                    scrollToId = _.first(x.message_list).id;
                    x.message_list = d.message_list.concat(x.message_list);
                }
                state.is_loading_more_message = false;
                state.is_more_message_loaded = true;
                user_1.Messaging.onMessageLoadMoreDone(scrollToId);
                if (_.size(d.message_list) >= cfg.chatMessageViewLimit) {
                    user_1.Messaging.offMessageScroll();
                }
                return state;
            }
            case 8: {
                if (action.data && state.active_chat_id != action.data) {
                    var _chat = getChatById(action.data);
                    if (_chat) {
                        state.active_chat_id = _chat._id;
                        __1.Global.store.dispatch({ type: __1.Global.actions.setUserSettings, data: { chat_id: _chat._id } });
                        __1.Global.saveUserSettings({ chat_id: _chat._id });
                    }
                }
                return state;
            }
            case 9: {
                var _chat_id = (action.data || __1.Global.store.getState().user.settings.chat_id);
                var qs_2 = new uri().search(true);
                if (qs_2._id == null) {
                    __1.Global.history.replace(__1.util.buildUrl(new uri().pathname(), { _id: _chat_id }));
                }
                else if (qs_2._id != _chat_id) {
                    __1.Global.history.push(__1.util.buildUrl(new uri().pathname(), { _id: _chat_id }));
                }
                if (state.chat_list && state.chat_list.length > 0) {
                    if (_chat_id == null) {
                        _chat_id = _.first(state.chat_list)._id;
                    }
                    else {
                        var x = getChatById(_chat_id);
                        if (x == null) {
                            _chat_id = _.first(state.chat_list)._id;
                        }
                    }
                }
                state.show_new_message_button = false;
                state.is_loading_more_message = false;
                state.show_chat_panel = true;
                if (_chat_id) {
                    if (state.active_chat_id != _chat_id) {
                        var _chat = getChatById(_chat_id);
                        if (_chat) {
                            state.active_chat_id = _chat_id;
                            __1.Global.saveUserSettings({ chat_id: _chat_id });
                        }
                    }
                    joinChatRoom(_chat_id);
                    chatViewed(_chat_id);
                }
                return state;
            }
            case 10: {
                state.show_chat_panel = false;
                return state;
            }
            case 22: {
                var u_1 = _.assign({}, action.data);
                _.each(state.chat_list, function (chat) {
                    _.each(chat.user_list, function (user) {
                        if (user._id === u_1._id) {
                            user.online_status = u_1.online_status;
                        }
                    });
                });
                return state;
            }
            case 3: {
                state.is_creating_chat = true;
                if (Chat.cs) {
                    Chat.cs.emit('createChat', action.data);
                }
                return state;
            }
            case 4: {
                state.is_creating_chat = false;
                var d = action.data;
                var x = getChatById(d._id);
                if (x == null) {
                    state.chat_list.unshift(d);
                    state.active_chat_id = d._id;
                    joinChatRoom();
                    state.show_chat_panel = true;
                }
                return state;
            }
            case 5: {
                try {
                    var d_1 = action.data;
                    var x = getChatById(d_1._id);
                    if (x == null) {
                        state.chat_list.unshift(d_1);
                        setNewMessageAlert();
                    }
                    var withNewLink = true;
                    if (state.active_chat_id == null) {
                        withNewLink = false;
                        state.active_chat_id = d_1._id;
                        setTimeout(function () {
                            var inChatRoom = ($('#chat_' + d_1._id).length > 0);
                            if (inChatRoom) {
                                chatViewed(d_1._id);
                            }
                        }, 5000);
                    }
                    var _msg = _.first(d_1.message_list);
                    var _user = getUserById(d_1._id, _msg.user_id);
                    var _pno = {
                        body: _user.first_name + ' ' + _user.last_name + ': ' + _msg.text,
                        link: pages_1.pages.messaging.path + '?_id=' + d_1._id,
                        icon: _user.photo_url ? cfg.photoBaseUrl[process.env.NODE_ENV].s3 + '/' + _user.photo_url : '/favicon-32x32.png',
                        timeout: 120000
                    };
                    __1.util.pushNotification('New Conversation', _pno, withNewLink && _pno.link);
                }
                catch (err) {
                }
                return state;
            }
            case 23: {
                if (action.data) {
                    var x = getChatById(action.data);
                    if (x != null) {
                        state.is_deleting_chat = true;
                        deleteChat(x._id);
                    }
                }
                return state;
            }
            case 24: {
                state.is_deleting_chat = false;
                if (action.data) {
                    leaveChatRoom();
                    var d_2 = action.data;
                    state.chat_list = _.reject(state.chat_list, function (chat) { return chat._id == d_2._id; });
                    if (state.chat_list.length > 0) {
                        state.active_chat_id = _.first(state.chat_list)._id;
                        __1.Global.saveUserSettings({ chat_id: state.active_chat_id });
                        joinChatRoom();
                    }
                    else {
                        __1.Global.saveUserSettings({ chat_id: null });
                    }
                }
                return state;
            }
            case 12: {
                var x = _.find(state.typing_list, action.data);
                if (x == null) {
                    var d = action.data;
                    state.typing_list.push(d);
                }
                return state;
            }
            case 13: {
                var d_3 = action.data;
                state.typing_list = state.typing_list.filter(function (tp) { return tp.chat_id != d_3.chat_id && tp.user_id != d_3.user_id; });
                return state;
            }
            case 15: {
                if (!_.isEmpty(action.data)) {
                    var d = {
                        chat_id: state.active_chat_id,
                        send_no: _.uniqueId(),
                        message: {
                            user_id: __1.Global.store.getState().user._id,
                            text: action.data
                        }
                    };
                    Chat.cs.emit('newMessage', d);
                }
                return state;
            }
            case 16: {
                var d_4 = _.assign({}, action.data);
                var _chat_1 = getChatById(d_4.chat_id);
                if (!_.isEmpty(_chat_1)) {
                    if (_.first(state.chat_list)._id != d_4.chat_id) {
                        state.chat_list = _.concat(_chat_1, _.remove(state.chat_list, function (x) { return x._id != _chat_1._id; }));
                    }
                    var _isMe = isMe(d_4.message.user_id);
                    if (!_isMe) {
                        new Audio('/media/message.mp3').play();
                    }
                    if (state.active_chat_id == null) {
                        state.active_chat_id = d_4.chat_id;
                    }
                    var _inChatRoom = ($('#chat_' + d_4.chat_id).length > 0);
                    var _isAtBottom = (_inChatRoom && user_1.Messaging.isMessageAtBottom());
                    var _shouldPushNotification = (document.hasFocus() == false);
                    _chat_1.message_list.push(d_4.message);
                    if (_isMe || _isAtBottom) {
                        user_1.Messaging.scrollMessageToBottom();
                    }
                    else {
                        _.assign(_.find(_chat_1.user_list, { _id: __1.Global.store.getState().user._id }), { has_new_message: true });
                        __1.Global.store.dispatch({ type: __1.Global.actions.setHasNewMessage, data: true });
                        if (_inChatRoom) {
                            state.show_new_message_button = true;
                        }
                        else {
                            _shouldPushNotification = true;
                        }
                    }
                    if (_shouldPushNotification && !_isMe) {
                        var _user = getUserById(d_4.chat_id, d_4.message.user_id);
                        var _pno = {
                            body: _user.first_name + ' ' + _user.last_name + ': ' + d_4.message.text,
                            link: pages_1.pages.messaging.path + '?_id=' + d_4.chat_id,
                            icon: _user.photo_url ? cfg.photoBaseUrl[process.env.NODE_ENV].s3 + '/' + _user.photo_url : '/favicon-32x32.png',
                            timeout: 60000
                        };
                        __1.util.pushNotification('New Message', _pno, !_inChatRoom && _pno.link);
                    }
                    state.typing_list = state.typing_list.filter(function (tp) { return tp.chat_id != d_4.chat_id && tp.user_id != d_4.message.user_id; });
                }
                else {
                    getNewChat(d_4.chat_id);
                }
                return state;
            }
            case 17: {
                var d = Object.assign({}, action.data);
                var _chat = getChatById(d.chat_id);
                _.assign(_.find(_chat.message_list, { id: d.message.id }), d.message);
                if (isMe(d.message.user_id) || user_1.Messaging.isMessageAtBottom() == true) {
                    if (d.message.meta.image == null || d.message.meta.image == '') {
                        user_1.Messaging.scrollMessageToBottom();
                    }
                    else {
                        user_1.Messaging.registerMetaImageLoad();
                    }
                }
                return state;
            }
            case 18: {
                state.show_new_message_button = false;
                chatViewed(getActiveChat()._id);
                user_1.Messaging.scrollMessageToBottom(1000);
                return state;
            }
            case 19: {
                state.show_new_message_button = false;
                chatViewed(getActiveChat()._id);
                return state;
            }
            case 11: {
                var d = action.data;
                var _chat = getChatById(d._id);
                _.assign(_.find(_chat.user_list, { _id: d.user._id }), d.user, { has_new_message: false });
                setNewMessageAlert();
                return state;
            }
            case 6: {
                var d = Object.assign({}, action.data);
                state.chat_list.unshift(d);
                if (state.active_chat_id == null) {
                    state.active_chat_id = d._id;
                    joinChatRoom();
                }
                return state;
            }
            default: {
                return state;
            }
        }
    }, JSON.parse(JSON.stringify(initState)));
})(Chat = exports.Chat || (exports.Chat = {}));
