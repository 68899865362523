"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var classNames = require("classnames");
var _ = require("lodash");
var moment = require("moment");
var react_visibility_sensor_1 = require("react-visibility-sensor");
var Cruz = require("cruz");
var cfg = require("../../../cfg");
var pages_1 = require("../../../routes/pages");
var lib_1 = require("../../lib");
var __1 = require("..");
var VehicleCard;
(function (VehicleCard) {
    var Component = (function (_super) {
        __extends(Component, _super);
        function Component(props) {
            var _this = _super.call(this, props) || this;
            _this.imgError = function (e) {
                _this.setState({ img_err: true });
                e.target.onerror = null;
                e.target.src = '/img/default_vehicle.png';
            };
            _this.state = {
                title: __1.Vehicle.detailTitle(_this.props.vehicle, false),
                is_viewed: _this.props.vehicle.is_viewed,
                is_working: false
            };
            return _this;
        }
        Component.prototype.componentDidMount = function () {
            if (lib_1.util.isClient() && this.state.is_viewed == false) {
                this.setState({
                    is_viewed: lib_1.Tracking.isViewed('vehicle', this.props.vehicle._id)
                });
            }
        };
        Component.prototype.gotoEdit = function (e) {
            e.preventDefault();
            lib_1.Global.history.push(pages_1.pages.vehicle_add_edit.path + '?_id=' + this.props.vehicle._id);
        };
        Component.prototype.openDeleteDialog = function () {
            var _this = this;
            lib_1.util.setModal(null, false, true, React.createElement(__1.Delete.Dialog, { id: this.props.vehicle._id, onYes: function (e) { return _this.deleteYes(); } }));
        };
        Component.prototype.deleteYes = function () {
            var _this = this;
            if (!this.state.is_working) {
                this.setState({ is_working: true });
                lib_1.Request.vehicle.delete(this.props.vehicle._id, function (err, apiRes) {
                    _this.setState({ is_working: false });
                    if (!err && apiRes.body.data) {
                        _this.props.onPostStatusUpdateDone && _this.props.onPostStatusUpdateDone(apiRes.body.data);
                    }
                });
            }
        };
        Component.prototype.undelete = function () {
            var _this = this;
            if (!this.state.is_working) {
                this.setState({ is_working: true });
                lib_1.Request.vehicle.undelete(this.props.vehicle._id, function (err, apiRes) {
                    _this.setState({ is_working: false });
                    if (!err && apiRes.body.data) {
                        _this.props.onPostStatusUpdateDone && _this.props.onPostStatusUpdateDone(apiRes.body.data);
                    }
                });
            }
        };
        Component.prototype.renderImage = function (v) {
            var _this = this;
            try {
                var hasPhoto = (v.photo_count > 0 && !_.isEmpty(_.first(v.photo_list)));
                var showStockPhoto = (!hasPhoto && !_.isEmpty(v.stock_photo_list) && v.stock_photo_list.length > 0);
                var my_user_id = !_.isEmpty(lib_1.Global.store.getState().user) ? lib_1.Global.store.getState().user._id : null;
                var isViewed = this.state.is_viewed;
                var color = _.get(v, 'exterior_color.mfr_color_name');
                var color_group = _.get(v, 'exterior_color.color_group');
                if (color != null) {
                    color = color + (color_group != null ? ' (' + color_group + ')' : '');
                }
                else {
                    color = color_group;
                }
                return (React.createElement(React.Fragment, null,
                    React.createElement("img", { className: 'card-img-top', alt: _.join(_.without([this.state.title, color, v.drive_type_name], null), ', '), src: hasPhoto
                            ?
                                cfg.photoBaseUrl[cfg.env].cdn + '/vehicle/' + v.group + '/' + v._id + '/' + v.photo_list[0].id + '-md.jpg'
                            :
                                showStockPhoto ? cfg.photoBaseUrl.stock + _.first(v.stock_photo_list) : '/img/default_vehicle.png', onError: (this.imgError) }),
                    (showStockPhoto && !this.state.img_err) &&
                        React.createElement("div", { className: 'c-img-des' }, "* Not Actual Photo"),
                    (v.condition != null && v.condition == Cruz.Lookup.Vehicle.ConditionEnum.Certified) &&
                        React.createElement("img", { src: '/img/vehicle/cpo.png', className: 'c-icon-cpo img-fluid', title: 'Certified Pre-Owned' }),
                    v.is_disabled
                        ?
                            React.createElement("div", { className: 'c-icon-status', title: 'This vehicle has been disabled by administrator.' },
                                React.createElement("i", { className: 'fal fa-ban' }))
                        :
                            v.post_status_id == Cruz.Lookup.Other.PostStatusEnum.Delete &&
                                React.createElement("div", { className: 'c-icon-status', title: 'This vehicle has been deleted.' },
                                    React.createElement("i", { className: 'fal fa-trash-alt' })),
                    v.sale_status_id == Cruz.Lookup.Vehicle.SaleStatusEnum.Sold &&
                        React.createElement("img", { src: '/img/icon/sold-tag.svg', className: 'c-icon-sold img-fluid' }),
                    (this.props.showEdit && my_user_id != null && my_user_id == v.user_id && !hasPhoto && !v.is_disabled && v.post_status_id == Cruz.Lookup.Other.PostStatusEnum.Active) &&
                        React.createElement("button", { className: 'btn c-btn-1 c-btn-xs c-btn-round c-absbottom', onClick: function (e) { return _this.gotoEdit(e); } },
                            React.createElement("i", { className: 'fal fa-cloud-upload' }),
                            React.createElement("span", null, "Upload Photo")),
                    (hasPhoto || isViewed) &&
                        React.createElement("div", { className: 'c-img-overlay' },
                            hasPhoto &&
                                React.createElement("span", null,
                                    React.createElement("i", { className: 'fal fa-camera' }),
                                    React.createElement("span", null, v.photo_count)),
                            (hasPhoto && isViewed) &&
                                React.createElement("span", { className: 'separator' }),
                            isViewed &&
                                React.createElement("span", null, "Viewed"))));
            }
            catch (err) {
                return React.createElement("img", { className: 'card-img-top', src: '/img/default_vehicle.png' });
            }
        };
        Component.prototype.renderFooter = function () {
            try {
                var v = this.props.vehicle;
                var me = lib_1.Global.store.getState().user;
                var my_user_id = !_.isEmpty(me) ? me._id : null;
                var ss = _.find(lib_1.Lookup.saleStatus, { id: v.sale_status_id });
                var url = v.is_disabled ? '' : __1.Vehicle.detailUrl(v);
                var isLiked = (my_user_id && !_.isEmpty(v.vote) && v.vote.up.user_list && _.indexOf(v.vote.up.user_list, my_user_id) > -1);
                var like_count = (!_.isEmpty(v.vote) && v.vote.up.count > 0) ? v.vote.up.count : 0;
                var isMine = v.user_id != null && v.user_id == my_user_id;
                return (React.createElement("div", { className: 'card-footer c-card-footer' },
                    (this.props.showUserInfo && !_.isEmpty(v.user_id)) &&
                        React.createElement("div", { className: 'c-card-footer-user-display' }, __1.User.renderUserDisplaySummary(v.user_display, (me && !_.isEmpty(me.follow)) ? me.follow.following_list : null)),
                    !_.isEmpty(v.vendor_id) &&
                        React.createElement("div", { className: 'c-card-footer-user-display' }, __1.Vendor.renderVendorDisplaySummary(v.vendor_display, v.location)),
                    React.createElement("div", { className: 'c-card-footer-row row no-gutters' },
                        React.createElement("div", { className: 'cc-item col' },
                            React.createElement("span", { className: 'count' }, v.view_count.toLocaleString()),
                            React.createElement("span", { className: 'name' },
                                "View",
                                v.view_count > 1 && 's')),
                        React.createElement("div", { className: 'cc-item col' },
                            React.createElement("span", { className: 'count' }, v.comment_count.toLocaleString()),
                            v.is_disabled &&
                                React.createElement("span", { className: 'name' },
                                    "Comment",
                                    v.comment_count > 1 && 's'),
                            !v.is_disabled &&
                                React.createElement(react_router_dom_1.Link, { to: url + '#_comments', className: 'd-xl-none' },
                                    React.createElement("span", { className: 'name' },
                                        "Comment",
                                        v.comment_count > 1 && 's')),
                            !v.is_disabled &&
                                React.createElement("span", { className: 'name d-none d-xl-inline' },
                                    "Comment",
                                    v.comment_count > 1 && 's')),
                        React.createElement("div", { className: 'cc-item col' },
                            isLiked &&
                                React.createElement("i", { className: 'fas fa-heart c-fa-liked mr-2', title: 'You liked this' }),
                            React.createElement("span", { className: 'count' }, like_count.toLocaleString()),
                            React.createElement("span", { className: 'name' },
                                "Like",
                                (like_count > 1) && 's'),
                            (like_count > 0) &&
                                React.createElement("button", { type: 'button', className: 'ml-2 c-btn-open-stat', onClick: __1.Vote.openStatLikedModal.bind(this, { name: 'vehicle', _id: v._id }) },
                                    React.createElement("i", { className: 'fal fa-list-ul' }))),
                        !isMine
                            &&
                                React.createElement("div", { className: 'cc-item col' },
                                    React.createElement(__1.Saved.SaveItemButton, { name: 'vehicle', _id: v._id, className: 'c-btn-transparent' })))));
            }
            catch (err) {
                lib_1.util.logError(err, null);
                return null;
            }
        };
        Component.prototype.renderMPG = function (mpg_list) {
            try {
                var mpg = null;
                if (_.size(mpg_list) == 1) {
                    mpg = _.first(mpg_list);
                }
                else {
                    mpg = _.maxBy(mpg_list, function (mpg) { return mpg.city + mpg.highway; });
                }
                return (React.createElement(React.Fragment, null,
                    mpg.city + ' ',
                    React.createElement("span", { className: 'c-font-size-xs' }, "City | "),
                    mpg.highway + ' ',
                    React.createElement("span", { className: 'c-font-size-xs' }, "Highway"),
                    (_.size(mpg_list) > 1) &&
                        React.createElement("span", { className: 'ml-2 c-font-size-xs' },
                            "(",
                            (!_.isEmpty(mpg.fuel_grade) ? mpg.fuel_grade + ' ' : '') + mpg.fuel_type || '',
                            ") ...")));
            }
            catch (err) {
                lib_1.util.logError(err, null);
                return null;
            }
        };
        Component.prototype.renderItem = function (title, value) {
            return (React.createElement("li", null,
                React.createElement("span", { className: 'name mr-2' },
                    title,
                    ":"),
                React.createElement("span", { className: 'value' }, value)));
        };
        Component.prototype.renderBody = function () {
            var _this = this;
            try {
                var v = this.props.vehicle;
                var me = lib_1.Global.store.getState().user;
                var my_user_id = !_.isEmpty(me) ? me._id : null;
                var ss = _.find(lib_1.Lookup.saleStatus, { id: v.sale_status_id });
                var url = v.is_disabled ? '' : __1.Vehicle.detailUrl(v);
                return (React.createElement("div", { className: 'card-body d-flex flex-column' },
                    React.createElement("div", { className: 'd-flex' },
                        React.createElement("h2", { className: 'card-title mb-2 ellipsis' }, v.is_disabled ? this.state.title : React.createElement(react_router_dom_1.Link, { to: url, className: 'c-btn-link' }, this.state.title)),
                        this.state.is_working
                            ?
                                React.createElement("div", { className: 'ml-auto pl-4' },
                                    React.createElement("i", { className: 'far fa-spinner fa-pulse' }))
                            :
                                (this.props.showEdit && my_user_id && my_user_id == v.user_id && !v.is_disabled) &&
                                    React.createElement("div", { className: 'ml-auto dropup c-ellipsis-drop pl-4' },
                                        React.createElement("button", { className: 'dropdown-toggle', "data-toggle": 'dropdown', title: 'Options' },
                                            React.createElement("i", { className: 'far fa-ellipsis-v' })),
                                        React.createElement("div", { className: 'dropdown-menu dropdown-menu-right' },
                                            React.createElement("button", { className: 'dropdown-item', onClick: function (e) { return _this.gotoEdit(e); } },
                                                React.createElement("i", { className: 'far fa-pen fa-fw' }),
                                                React.createElement("span", null, "Edit")),
                                            React.createElement("div", { className: 'dropdown-divider' }),
                                            v.post_status_id == Cruz.Lookup.Other.PostStatusEnum.Delete
                                                ?
                                                    React.createElement("button", { type: 'button', className: 'dropdown-item', onClick: function (e) { return _this.undelete(); } },
                                                        React.createElement("i", { className: 'far fa-undo fa-fw' }),
                                                        React.createElement("span", null, "Undelete"))
                                                :
                                                    React.createElement("button", { type: 'button', className: 'dropdown-item', onClick: function (e) { return _this.openDeleteDialog(); } },
                                                        React.createElement("i", { className: 'far fa-trash-alt fa-fw' }),
                                                        React.createElement("span", null, "Delete"))))),
                    React.createElement("div", { className: classNames('sale_status d-inline-flex align-items-center mt-1 mr-auto', 'ss_' + v.sale_status_id) }, (ss.price_required && v.price != null && v.price > 0)
                        ?
                            React.createElement(React.Fragment, null,
                                React.createElement("span", { className: 'mr-2 status' },
                                    ss.status,
                                    ":"),
                                React.createElement("span", { className: 'mr-2 price' },
                                    React.createElement("sup", null, "$"),
                                    v.price.toLocaleString()))
                        :
                            ss.status),
                    React.createElement("ul", { className: 'list-unstyled specs my-2' },
                        (this.props.showVIN && !_.isEmpty(v.vin)) &&
                            this.renderItem('VIN', v.vin),
                        this.props.showCondition &&
                            this.renderItem('Condition', _.find(lib_1.Lookup.condition, { id: v.condition }).name),
                        (v.mileage != null && v.mileage > 0) &&
                            this.renderItem('Mileage', React.createElement(React.Fragment, null,
                                React.createElement("span", { className: 'highlight' }, v.mileage.toLocaleString()),
                                " mile",
                                v.mileage > 1 ? 's' : '',
                                (!_.isEmpty(v.statistics) && v.statistics.count > 1 && v.statistics.avg_mileage > 0) &&
                                    React.createElement("span", { className: 'c-font-size-xs ml-2' },
                                        "(",
                                        __1.Vehicle.mileageDescription(v),
                                        ")"))),
                        !_.isEmpty(v.engine) && !_.isEmpty(v.engine.name) &&
                            this.renderItem('Engine', v.engine.name),
                        !_.isEmpty(v.transmission) &&
                            this.renderItem('Transmission', v.transmission.name),
                        !_.isEmpty(v.drive_type_name) &&
                            this.renderItem('Drive Type', v.drive_type_name),
                        !_.isEmpty(v.mpg_list) &&
                            this.renderItem((!_.isEmpty(v.engine) && ['L', 'H'].includes(v.engine.fuel_type) ? 'MPGe' : 'MPG'), this.renderMPG(v.mpg_list)),
                        (v.safety_ratings != null && v.safety_ratings > 0) &&
                            this.renderItem('Safety Ratings', React.createElement(React.Fragment, null,
                                _.map(_.range(v.safety_ratings), function (n) {
                                    return React.createElement("i", { key: 'ct_' + n, className: 'fas fa-star c-veh-rating-star' });
                                }),
                                _.map(_.range(5 - v.safety_ratings), function (n) {
                                    return React.createElement("i", { key: 'ct_' + n, className: 'far fa-star c-veh-rating-star' });
                                })))),
                    React.createElement("div", { className: 'c-font-size-xs mt-auto ellipsis' },
                        React.createElement("i", { className: 'fal fa-clock mr-2' }),
                        React.createElement("span", null, 'Posted ' + moment(v.date_create).fromNow()),
                        (v.date_update && moment(v.date_update).fromNow() != moment(v.date_create).fromNow()) &&
                            React.createElement("span", null,
                                React.createElement("span", { className: 'separator' }),
                                React.createElement("span", null, 'Updated ' + moment(v.date_update).fromNow())))));
            }
            catch (err) {
                lib_1.util.logError(err, null);
                return null;
            }
        };
        Component.prototype.renderCard = function () {
            var v = this.props.vehicle;
            var url = v.is_disabled ? '' : __1.Vehicle.detailUrl(v);
            return (React.createElement("div", { className: 'card c-veh-card h-100' },
                !lib_1.util.isClient() &&
                    lib_1.Schema.VehicleItem(v, false),
                React.createElement("div", { className: 'card-img' }, v.is_disabled ? this.renderImage(v) : React.createElement(react_router_dom_1.Link, { to: url }, this.renderImage(v))),
                this.renderBody(),
                this.renderFooter()));
        };
        Component.prototype.render = function () {
            var _this = this;
            return (React.createElement(react_visibility_sensor_1.default, { partialVisibility: true, offset: { top: -3000, bottom: -3000 }, resizeCheck: true }, function (_a) {
                var isVisible = _a.isVisible;
                return (!lib_1.util.isClient() || _this.props.shouldRender || isVisible) ? _this.renderCard() : React.createElement("div", { className: 'c-veh-card-holder' });
            }));
        };
        return Component;
    }(React.Component));
    VehicleCard.Component = Component;
})(VehicleCard = exports.VehicleCard || (exports.VehicleCard = {}));
