"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Redux = require("redux");
var request = require("superagent");
var _ = require("lodash");
var async = require("async");
var uri = require("urijs");
var Cruz = require("cruz");
var lib_1 = require("../lib");
var pages_1 = require("../../routes/pages");
var cfg = require("../../cfg");
var ServiceQuoteRequestControl;
(function (ServiceQuoteRequestControl) {
    function getInitData(callback) {
        async.parallel({
            vehicle: function (next) {
                request.get(lib_1.Global.cruzApiBaseUrl + '/user/my-vehicles' + lib_1.util.buildApiQueryString({ page_size: 100, sort: Cruz.Lookup.Vehicle.SortEnum.Year, is_active: 1 }))
                    .set(lib_1.Auth.authHeader())
                    .end(function (err, apiRes) {
                    next(err, _.get(apiRes, 'body'));
                });
            },
        }, function (err, result) {
            return callback(err, result);
        });
    }
    ServiceQuoteRequestControl.getInitData = getInitData;
    var initData = _.memoize(_.assign)({
        is_loading: true,
        is_working: false,
        vehicle: {},
        quote: {}
    });
    var validate = require('validate.js');
    var rules = {
        phone: {
            presence: { message: '^Phone is required.', allowEmpty: false }
        }
    };
    ServiceQuoteRequestControl.store = Redux.createStore(function (state, action) {
        var _a, _b, _c, _d, _e;
        function valid() {
            if (!_.isEmpty(state.qa_rules)) {
                state.qa_validation = validate(state.quote.qa, state.qa_rules);
            }
            else {
                state.qa_validation = null;
            }
            state.validation = validate(state, rules);
            return state;
        }
        function verifyVehicle() {
            if (!state.is_verifying) {
                state.is_verifying = true;
                state.allow_save = false;
                request.post(lib_1.Global.cruzApiBaseUrl + '/service-quote/verify-vehicle')
                    .timeout(cfg.superagent.timeout.normal)
                    .send(_.pick(state.quote, 'vehicle_id', 'service_code'))
                    .end(function (err, apiRes) {
                    if (err) {
                        ServiceQuoteRequestControl.store.dispatch({ type: 0, error: err });
                    }
                    else {
                        ServiceQuoteRequestControl.store.dispatch({ type: 4, data: apiRes.body.data });
                    }
                });
            }
        }
        switch (action.type) {
            case 0: {
                state.is_loading = false;
                state.is_verifying = false;
                state.is_working = false;
                lib_1.util.setToastMessage('error', _.get(action.error, 'response.body.error.detail'));
                return state;
            }
            case 1: {
                state.is_loading = false;
                state = _.assign({}, state, { is_loading: false, phone: _.get(lib_1.Global.store.getState().user, 'phone') || '' }, action.data);
                if (state.vehicle.meta.total_records_matched == 1) {
                    state.quote.vehicle_id = _.first(state.vehicle.data)._id;
                }
                else {
                    var s = new uri().search(true);
                    var v_id = _.get(s, 'vehicle_id');
                    if (v_id) {
                        var v = _.find(state.vehicle.data, { _id: v_id });
                        if (!_.isEmpty(v)) {
                            state.quote.vehicle_id = v_id;
                        }
                    }
                }
                return state;
            }
            case 2: {
                state = _.merge({}, state, { quote: action.data });
                if (!_.isEmpty(_.get(state.quote, 'vehicle_id')) && _.get(state.quote, 'service_code') > 0) {
                    verifyVehicle();
                }
                return state;
            }
            case 3: {
                state = _.merge({}, _.omit(state, ['vehicle_err_list', 'question_list', 'amenity_list', 'validation', 'allow_save']), { quote: action.data });
                state.quote.qa = {};
                if (!_.isEmpty(_.get(state.quote, 'vehicle_id')) && _.get(state.quote, 'service_code') > 0) {
                    verifyVehicle();
                }
                return state;
            }
            case 4: {
                state.vehicle_err_list = action.data;
                state.is_verifying = false;
                if (_.size(state.vehicle_err_list) == 0) {
                    state.is_loading_question = true;
                    state.allow_save = false;
                    request.get(lib_1.Global.cruzApiBaseUrl + '/service-quote/' + state.quote.service_code + '/question').timeout(cfg.superagent.timeout.normal)
                        .end(function (err, apiRes) {
                        if (err) {
                            ServiceQuoteRequestControl.store.dispatch({ type: 0, error: err });
                        }
                        else {
                            ServiceQuoteRequestControl.store.dispatch({ type: 5, data: apiRes.body.data });
                        }
                    });
                }
                return state;
            }
            case 11: {
                state.phone = lib_1.Mask.UnmaskedValue(action.data, lib_1.Mask.TypeEnum.Phone);
                if (!_.isEmpty(state.validation)) {
                    valid();
                }
                return state;
            }
            case 5: {
                state.is_loading_question = false;
                state.question_list = action.data;
                state.allow_save = true;
                var requiredQuestionList = _.filter(state.question_list, { is_required: true });
                if (_.size(requiredQuestionList) > 0) {
                    state.qa_rules = _.mapValues(_.keyBy(requiredQuestionList, '_id'), function (q) {
                        return {
                            presence: {
                                allowEmpty: false,
                                message: q.type == 'check' ? '^Select at least one' : '^This is required'
                            }
                        };
                    });
                }
                else {
                    state.qa_rules = {};
                }
                return state;
            }
            case 6: {
                var tgt = action.target;
                if (tgt.checked) {
                    if (_.isEmpty(state.quote.amenity_list)) {
                        state.quote.amenity_list = [];
                    }
                    state.quote.amenity_list.push(Number(tgt.value));
                }
                else {
                    state.quote.amenity_list = _.without(state.quote.amenity_list, Number(tgt.value));
                    if (_.size(state.quote.amenity_list) == 0) {
                        state.quote.amenity_list = null;
                    }
                }
                return state;
            }
            case 7: {
                var tgt = action.target;
                if (tgt.type == 'checkbox') {
                    state.quote.qa = _.assign({}, state.quote.qa, (_a = {}, _a[tgt.id] = tgt.checked, _a));
                }
                else {
                    state.quote.qa = _.assign({}, state.quote.qa, (_b = {}, _b[tgt.id] = tgt.value, _b));
                }
                state.qa_validation && valid();
                return state;
            }
            case 8: {
                var tgt = action.target;
                state.quote.qa = _.assign({}, state.quote.qa, (_c = {}, _c[tgt.name] = tgt.value, _c));
                state.qa_validation && valid();
                return state;
            }
            case 9: {
                var tgt = action.target;
                if (tgt.checked) {
                    var d = null;
                    if (_.size(_.get(state.quote.qa, tgt.name)) == 0) {
                        d = (_d = {}, _d[tgt.name] = [tgt.value], _d);
                    }
                    else {
                        d = (_e = {}, _e[tgt.name] = _.concat(state.quote.qa[tgt.name], [tgt.value]), _e);
                    }
                    state.quote.qa = _.assign({}, state.quote.qa, d);
                }
                else {
                    state.quote.qa[tgt.name] = _.without(state.quote.qa[tgt.name], tgt.value);
                }
                state.qa_validation && valid();
                return state;
            }
            case 10: {
                var tgt = action.target;
                state.quote[tgt.id] = tgt.value;
                return state;
            }
            case 12: {
                state.agreed_term = !state.agreed_term;
                return state;
            }
            case 13: {
                action.event && action.event.preventDefault();
                valid();
                if (!state.is_working && _.isEmpty(state.qa_validation) && _.isEmpty(state.validation)) {
                    state.is_working = true;
                    lib_1.Auth.isAuthed(function (isAuthed) {
                        if (isAuthed) {
                            request.post(lib_1.Global.cruzApiBaseUrl + '/user/service-quote').set(lib_1.Auth.authHeader()).send(state.quote)
                                .end(function (err, apiRes) {
                                if (err) {
                                    ServiceQuoteRequestControl.store.dispatch({ type: 0, error: err });
                                }
                                else {
                                    ServiceQuoteRequestControl.store.dispatch({ type: 14, data: apiRes.body.data });
                                }
                            });
                            if (state.phone != _.get(lib_1.Global.store.getState().user, 'phone')) {
                                request.put(lib_1.Global.cruzApiBaseUrl + '/user/my-profile')
                                    .set(lib_1.Auth.authHeader())
                                    .timeout(cfg.superagent.timeout.normal)
                                    .send(_.assign({}, lib_1.Global.store.getState().user, { phone: state.phone }))
                                    .end(function (err, apiRes) {
                                    if (!err) {
                                        var user = _.assign({}, apiRes.body.data);
                                        if (!_.isEmpty(user.access_token)) {
                                            lib_1.Auth.setAccessToken(user.access_token);
                                        }
                                        lib_1.Global.store.dispatch({ type: lib_1.Global.actions.setUser, data: user });
                                    }
                                });
                            }
                        }
                        else {
                            lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                        }
                    });
                }
                return state;
            }
            case 14: {
                state = _.assign({}, initData, { is_saved: true });
                setTimeout(function () {
                    lib_1.Global.history.push(pages_1.pages.my_service_quote.path);
                }, 1);
                return state;
            }
            case 15: {
                state = _.assign({}, initData);
                return state;
            }
            default: {
                return state;
            }
        }
    }, initData);
})(ServiceQuoteRequestControl = exports.ServiceQuoteRequestControl || (exports.ServiceQuoteRequestControl = {}));
