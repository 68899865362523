"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jwt_decode = require("jwt-decode");
var request = require("superagent");
var _ = require("lodash");
var moment = require("moment");
var async = require("async");
var pages_1 = require("../../../routes/pages");
var util_1 = require("./util");
var socket_1 = require("./socket");
var cfg = require("../../../cfg");
var global_1 = require("./global");
var user_1 = require("../../user");
var service_quote_1 = require("../../service_quote");
var chat_1 = require("./chat");
var Auth;
(function (Auth) {
    function authHeader() {
        try {
            var access_token = util_1.util.cookie.get('access_token');
            if (_.isEmpty(access_token)) {
                if (global_1.Global.temp_access_token) {
                    return { temp_access_token: global_1.Global.temp_access_token };
                }
                else {
                    return {};
                }
            }
            else {
                return { 'Authorization': 'Bearer ' + access_token };
            }
        }
        catch (err) {
            return {};
        }
    }
    Auth.authHeader = authHeader;
    function setRefreshToken(refresh_token, remember_me) {
        try {
            if (!_.isEmpty(refresh_token)) {
                var option = {};
                if (remember_me) {
                    option.maxAge = 60 * 60 * 24 * 180;
                    util_1.util.cookie.set('remember_me', true, option);
                }
                else {
                    util_1.util.cookie.remove('remember_me');
                }
                util_1.util.cookie.set('refresh_token', refresh_token, option);
            }
            return;
        }
        catch (err) {
            return;
        }
    }
    Auth.setRefreshToken = setRefreshToken;
    function setAccessToken(access_token) {
        try {
            if (!_.isEmpty(access_token)) {
                util_1.util.cookie.set('access_token', access_token, { expires: new Date(jwt_decode(access_token)['exp'] * 1000) });
            }
            return;
        }
        catch (err) {
            return;
        }
    }
    Auth.setAccessToken = setAccessToken;
    function isAuthed(callback) {
        var isAuthed = false;
        var refresh_token = util_1.util.cookie.get('refresh_token');
        var access_token = null;
        async.waterfall([
            function (next) {
                try {
                    access_token = util_1.util.cookie.get('access_token');
                    if (_.isEmpty(access_token)) {
                        next();
                    }
                    else {
                        var x = jwt_decode(access_token);
                        if (x && moment().isBefore(moment(x['exp'] * 1000))) {
                            isAuthed = true;
                            if (_.isEmpty(refresh_token)) {
                                request.get(global_1.Global.cruzApiBaseUrl + '/user/refresh_token/' + access_token)
                                    .timeout(cfg.superagent.timeout.normal)
                                    .end(function (err, apiRes) {
                                    if (!err) {
                                        var d = _.get(apiRes, 'body.data.refresh_token');
                                        if (!_.isEmpty(d)) {
                                            refresh_token = apiRes.body.data['refresh_token'];
                                            setRefreshToken(refresh_token, false);
                                        }
                                    }
                                    next();
                                });
                            }
                            else {
                                next();
                            }
                        }
                        else {
                            next();
                        }
                    }
                }
                catch (err) {
                    next();
                }
            },
            function (next) {
                if (isAuthed == false) {
                    if (_.isEmpty(refresh_token)) {
                        next();
                    }
                    else {
                        request.get(global_1.Global.cruzApiBaseUrl + '/user/renew-access-token/' + refresh_token)
                            .timeout(cfg.superagent.timeout.normal)
                            .end(function (err, apiRes) {
                            if (err) {
                                if (!err.timeout) {
                                    resetUser('cannot renew access token');
                                }
                                return callback(false);
                            }
                            else {
                                var access_token_1 = _.get(apiRes.body.data, 'access_token');
                                if (!_.isEmpty(access_token_1)) {
                                    setAccessToken(access_token_1);
                                    isAuthed = true;
                                }
                                next();
                            }
                        });
                    }
                }
                else {
                    next();
                }
            },
            function (next) {
                if (isAuthed == false) {
                    var tk = global_1.Global.temp_access_token;
                    if (_.isEmpty(tk)) {
                        next();
                    }
                    else {
                        var x = jwt_decode(tk);
                        if (!_.isEmpty(x) && moment().isBefore(moment(x['exp'] * 1000))) {
                            return callback(true);
                        }
                        else {
                            next();
                        }
                    }
                }
                else {
                    next();
                }
            },
        ], function () {
            try {
                if (isAuthed == true) {
                    if (util_1.util.isClient() && global_1.Global.store && _.isEmpty(global_1.Global.store.getState().user)) {
                        request.get(global_1.Global.cruzApiBaseUrl + '/user/my-profile')
                            .set(Auth.authHeader())
                            .timeout(cfg.superagent.timeout.normal)
                            .end(function (err, apiRes) {
                            if (!err && !_.isEmpty(_.get(apiRes, 'body.data'))) {
                                global_1.Global.store.dispatch({ type: global_1.Global.actions.setUser, data: apiRes.body.data });
                                return callback(true);
                            }
                            else {
                                return callback(false);
                            }
                        });
                    }
                    else {
                        return callback(true);
                    }
                }
                else {
                    if (!_.isEmpty(refresh_token) && !_.isEmpty(access_token)) {
                        resetUser('wrong token');
                    }
                    return callback(false);
                }
            }
            catch (err) {
                return callback(false);
            }
        });
    }
    Auth.isAuthed = isAuthed;
    function getUserIdFromTokenSync() {
        try {
            var tk = util_1.util.cookie.get('access_token');
            if (_.isEmpty(tk)) {
                return null;
            }
            else {
                return _.get(jwt_decode(tk), '_id');
            }
        }
        catch (err) {
            return null;
        }
    }
    Auth.getUserIdFromTokenSync = getUserIdFromTokenSync;
    function resetUser(reason) {
        try {
            util_1.util.cookie.remove('refresh_token');
            util_1.util.cookie.remove('access_token');
            util_1.util.cookie.remove('remember_me');
            if (global_1.Global.store) {
                global_1.Global.store.dispatch({ type: global_1.Global.actions.setUser });
            }
            if (chat_1.Chat.store) {
                chat_1.Chat.store.dispatch({ type: 1 });
            }
            if (user_1.MySpotted.store) {
                user_1.MySpotted.store.dispatch({ type: 8 });
            }
            if (user_1.MyVehicle.store) {
                user_1.MyVehicle.store.dispatch({ type: 1 });
            }
            if (user_1.MyFollow.store) {
                user_1.MyFollow.store = null;
            }
            if (user_1.MyReferrals.store) {
                user_1.MyReferrals.store = null;
            }
            if (service_quote_1.MyServiceQuote.store) {
                service_quote_1.MyServiceQuote.store = null;
            }
            if (service_quote_1.ServiceQuoteRequestControl.store) {
                service_quote_1.ServiceQuoteRequestControl.store.dispatch({ type: 15 });
            }
            if (user_1.MySaved.store) {
                user_1.MySaved.store = null;
            }
            return;
        }
        catch (err) {
        }
    }
    Auth.resetUser = resetUser;
    function signout() {
        setTimeout(function () {
            global_1.Global.history.push(pages_1.pages.home.path);
            resetUser('signout');
            socket_1.CruzSocket.reconnect();
        }, 1);
    }
    Auth.signout = signout;
    function redirectSignin(returnUrl) {
        setTimeout(function () {
            var rUrl = pages_1.pages.signin.path + (returnUrl ? '?returnUrl=' + encodeURIComponent(returnUrl) : '');
            global_1.Global.history.replace(rUrl);
        }, 1);
    }
    Auth.redirectSignin = redirectSignin;
    function redirectAuthed(redirectUrl) {
        setTimeout(function () {
            var rUrl = null;
            if (_.isEmpty(redirectUrl)) {
                rUrl = pages_1.pages.my_cruz.path;
            }
            else {
                rUrl = redirectUrl;
            }
            var gState = global_1.Global.store.getState();
            if (_.isEmpty(gState.user)) {
                request.get(global_1.Global.cruzApiBaseUrl + '/user/my-profile')
                    .timeout(cfg.superagent.timeout.normal)
                    .set(authHeader())
                    .end(function (err, apiRes) {
                    if (err) {
                        signout();
                    }
                    else {
                        global_1.Global.store.dispatch({ type: global_1.Global.actions.setUser, data: apiRes.body.data });
                        global_1.Global.history.replace(rUrl);
                    }
                });
            }
            else {
                global_1.Global.history.replace(rUrl);
            }
        }, 1);
    }
    Auth.redirectAuthed = redirectAuthed;
})(Auth = exports.Auth || (exports.Auth = {}));
