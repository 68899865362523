"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var lib_1 = require("../../lib");
var _ = require("lodash");
var server_1 = require("react-dom/server");
var VinInput = (function (_super) {
    __extends(VinInput, _super);
    function VinInput(props) {
        return _super.call(this, props) || this;
    }
    VinInput.prototype.componentDidMount = function () {
        var content = (React.createElement("div", { className: 'p-1' },
            React.createElement("div", null,
                "VIN (Vehicle Identification Number) can ben found in several places on your vehicle as wells as your ",
                React.createElement("u", null, "insurance card"),
                " and ",
                React.createElement("u", null, "vehicle registration"),
                ".")));
        $('#help-vin').popover({
            container: 'body',
            placement: 'bottom',
            trigger: 'focus',
            html: true,
            content: server_1.renderToString(content)
        });
    };
    VinInput.prototype.render = function () {
        try {
            var dOption = { role: 'textbox', autoComplete: 'off' };
            return (React.createElement("div", { className: this.props['data-grid'] },
                React.createElement("div", { className: lib_1.util.formGroupClass(this.props) },
                    React.createElement("div", { className: 'input-group' },
                        React.createElement("div", { className: 'input-group-main has-addon' },
                            React.createElement("input", __assign({ type: 'text', role: 'textbox' }, _.extend(dOption, lib_1.util.inputProps(this.props)))),
                            !_.isEmpty(this.props.label)
                                &&
                                    React.createElement("label", { htmlFor: this.props.id, className: lib_1.util.formLabelClass(this.props) }, this.props.label)),
                        React.createElement("div", { className: 'input-group-append' },
                            React.createElement("div", { className: 'input-group-text p-0' },
                                React.createElement("button", { type: 'button', id: 'help-vin', className: 'btn c-btn-3 border-1', tabIndex: -1, "data-toggle": 'popover' },
                                    React.createElement("i", { className: 'fal fa-info-circle fa-lg' }))))),
                    React.createElement("span", { className: 'form-control-feedback', "aria-hidden": true }, this.props['data-err']))));
        }
        catch (err) {
            return null;
        }
    };
    return VinInput;
}(React.Component));
exports.VinInput = VinInput;
