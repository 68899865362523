"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var Redux = require("redux");
var react_router_dom_1 = require("react-router-dom");
var history_1 = require("history");
var request = require("superagent");
var _ = require("lodash");
var uri = require("urijs");
var moment = require("moment");
var classNames = require("classnames");
var lib_1 = require("../lib");
var pages_1 = require("../../routes/pages");
var component_1 = require("../component");
var cfg = require("../../cfg");
var slugify_1 = require("slugify");
var DiscussionSearch;
(function (DiscussionSearch) {
    var initState = {
        is_loading: false,
        is_loading_more_feeds: false,
        is_loading_more_comments: false,
        feedQuery: {},
        feeds: {
            meta: {},
            links: {},
            data: []
        },
        searchOptions: {},
        statistic: {},
        vehicles: null
    };
    var actions;
    (function (actions) {
        actions[actions["setInitSearchOptions"] = 0] = "setInitSearchOptions";
        actions[actions["setMakeOptions"] = 1] = "setMakeOptions";
        actions[actions["setModelOptions"] = 2] = "setModelOptions";
        actions[actions["setYearOptions"] = 3] = "setYearOptions";
        actions[actions["setDistanceOptions"] = 4] = "setDistanceOptions";
        actions[actions["bodyTypeChanged"] = 5] = "bodyTypeChanged";
        actions[actions["makeChanged"] = 6] = "makeChanged";
        actions[actions["zipCodeChanged"] = 7] = "zipCodeChanged";
        actions[actions["filterChanged"] = 8] = "filterChanged";
        actions[actions["showMyPostChanged"] = 9] = "showMyPostChanged";
        actions[actions["tagChanged"] = 10] = "tagChanged";
        actions[actions["resetTag"] = 11] = "resetTag";
        actions[actions["sortChanged"] = 12] = "sortChanged";
        actions[actions["toggleFilter"] = 13] = "toggleFilter";
        actions[actions["search"] = 14] = "search";
        actions[actions["searchMore"] = 15] = "searchMore";
        actions[actions["searchDone"] = 16] = "searchDone";
        actions[actions["createFeedDone"] = 17] = "createFeedDone";
        actions[actions["updateFeedDone"] = 18] = "updateFeedDone";
        actions[actions["setVehicles"] = 19] = "setVehicles";
        actions[actions["setStatistic"] = 20] = "setStatistic";
        actions[actions["updateStat"] = 21] = "updateStat";
        actions[actions["refreshFeed"] = 22] = "refreshFeed";
        actions[actions["handleError"] = 23] = "handleError";
    })(actions = DiscussionSearch.actions || (DiscussionSearch.actions = {}));
    function getInitSearchOptions(q) {
        request.get(lib_1.Global.cruzApiBaseUrl + '/community/option/init' + lib_1.util.buildApiQueryString(q))
            .timeout(cfg.superagent.timeout.normal)
            .retry(2)
            .end(function (err, apiRes) {
            err ? DiscussionSearch.store.dispatch({ type: actions.handleError, error: err }) : DiscussionSearch.store.dispatch({ type: actions.setInitSearchOptions, data: apiRes.body.data });
        });
    }
    DiscussionSearch.getInitSearchOptions = getInitSearchOptions;
    function searchFeeds(q, callback) {
        lib_1.Auth.isAuthed(function () {
            var apiUrl = lib_1.Global.cruzApiBaseUrl + '/community' + lib_1.util.buildApiQueryString(_.assign({}, cfg.apiQueryDefault.discussion, q));
            request.get(apiUrl)
                .timeout(cfg.superagent.timeout.normal)
                .retry(2)
                .set(lib_1.Auth.authHeader())
                .end(function (err, apiRes) {
                return (err ? callback(err, null) : callback(null, apiRes.body));
            });
        });
    }
    DiscussionSearch.searchFeeds = searchFeeds;
    function getStatistic() {
        var fq = DiscussionSearch.store.getState().feedQuery;
        if (fq.make && fq.make != '') {
            request.get(lib_1.Global.cruzApiBaseUrl + '/statistic/vehicle' + lib_1.util.buildApiQueryString(_.pick(fq, ['make', 'model', 'year', 'year_min', 'year_max'])))
                .timeout(cfg.superagent.timeout.normal)
                .retry(2)
                .end(function (err, apiRes) {
                err ? DiscussionSearch.store.dispatch({ type: actions.handleError, error: err }) : DiscussionSearch.store.dispatch({ type: actions.setStatistic, data: apiRes.body.data });
            });
        }
    }
    function refreshFeed(feed_id) {
        lib_1.Auth.isAuthed(function () {
            request.get(lib_1.Global.cruzApiBaseUrl + '/community/' + feed_id + '?page_size=' + cfg.commentPageSize.discussion.search)
                .timeout(cfg.superagent.timeout.normal)
                .set(lib_1.Auth.authHeader())
                .retry(2)
                .end(function (err, apiRes) {
                err ? DiscussionSearch.store.dispatch({ type: actions.handleError, error: err }) : DiscussionSearch.store.dispatch({ type: actions.refreshFeed, data: apiRes.body.data });
            });
        });
    }
    DiscussionSearch.refreshFeed = refreshFeed;
    var app = function (state, action) {
        var _a;
        function getMakeOptions(q) {
            request.get(lib_1.Global.cruzApiBaseUrl + '/community/option/make' + lib_1.util.buildApiQueryString(_.pickBy(q, 'body_type')))
                .timeout(cfg.superagent.timeout.normal)
                .retry(2)
                .end(function (err, apiRes) {
                err ? DiscussionSearch.store.dispatch({ type: actions.handleError, error: err }) : DiscussionSearch.store.dispatch({ type: actions.setMakeOptions, data: apiRes.body.data });
            });
        }
        function getModelOptions(q) {
            (state.feedQuery.make && state.feedQuery.make != '') &&
                request.get(lib_1.Global.cruzApiBaseUrl + '/community/option/model' + lib_1.util.buildApiQueryString(_.pickBy(q, ['body_type', 'make'])))
                    .timeout(cfg.superagent.timeout.normal)
                    .retry(2)
                    .end(function (err, apiRes) {
                    err ? DiscussionSearch.store.dispatch({ type: actions.handleError, error: err }) : DiscussionSearch.store.dispatch({ type: actions.setModelOptions, data: apiRes.body.data });
                });
        }
        function search() {
            history_1.createBrowserHistory().replace(lib_1.util.buildUrl(location.pathname, state.feedQuery));
            searchFeeds(state.feedQuery, function (err, result) {
                err ? DiscussionSearch.store.dispatch({ type: actions.handleError, error: err }) : DiscussionSearch.store.dispatch({ type: actions.searchDone, data: result });
            });
        }
        switch (action.type) {
            case actions.handleError: {
                return state;
            }
            case actions.setInitSearchOptions: {
                state.searchOptions = _.assign({}, state.searchOptions, action.data);
                var queryRemoved = false;
                if (state.feedQuery) {
                    if (state.feedQuery.make) {
                        if (_.indexOf(state.searchOptions.make, state.feedQuery.make) > -1) {
                            getModelOptions(state.feedQuery);
                        }
                        else {
                            state.feedQuery = _.omit(state.feedQuery, ['make', 'model']);
                            queryRemoved = true;
                        }
                    }
                    if (state.feedQuery.year_min) {
                        if (_.indexOf(state.searchOptions.year, state.feedQuery.year_min) == -1) {
                            state.feedQuery = _.omit(state.feedQuery, ['year_min']);
                            queryRemoved = true;
                        }
                    }
                    if (state.feedQuery.year_max) {
                        if (_.indexOf(state.searchOptions.year, state.feedQuery.year_max) == -1) {
                            state.feedQuery = _.omit(state.feedQuery, ['year_max']);
                            queryRemoved = true;
                        }
                    }
                    if (state.feedQuery.year) {
                        if (_.indexOf(state.searchOptions.year, state.feedQuery.year) == -1) {
                            state.feedQuery = _.omit(state.feedQuery, ['year']);
                            queryRemoved = true;
                        }
                    }
                    if (queryRemoved) {
                        search();
                        getStatistic();
                    }
                }
                return state;
            }
            case actions.bodyTypeChanged: {
                var tgt = action.target;
                state.feedQuery = _.assign({}, _.omit(state.feedQuery, ['make', 'model', 'page_number']), { body_type: (tgt.value && tgt.value != '') ? action.target.value.split(',') : '' });
                state.searchOptions = _.omit(state.searchOptions, ['make', 'model']);
                getMakeOptions(state.feedQuery);
                search();
                return state;
            }
            case actions.setMakeOptions: {
                state.searchOptions = _.assign({}, state.searchOptions, { make: action.data });
                return state;
            }
            case actions.makeChanged: {
                state.feedQuery = _.assign({}, _.omit(state.feedQuery, ['model', 'page_number']), { make: action.target.value });
                state.searchOptions = _.assign({}, _.omit(state.searchOptions, ['model']));
                getModelOptions(state.feedQuery);
                search();
                if (action.target.value && action.target.value != '') {
                    getStatistic();
                }
                else {
                    state.statistic = {};
                }
                return state;
            }
            case actions.setModelOptions: {
                state.searchOptions = _.assign({}, state.searchOptions, { model: action.data });
                if (state.feedQuery.model && _.indexOf(state.searchOptions.model, state.feedQuery.model) < 0) {
                    state.feedQuery = _.omit(state.feedQuery, ['model']);
                    search();
                    getStatistic();
                }
                return state;
            }
            case actions.setYearOptions: {
                state.searchOptions = _.assign({}, state.searchOptions, { year: action.data });
                return state;
            }
            case actions.zipCodeChanged: {
                var zip_code = lib_1.Mask.UnmaskedValue(action.target.value, lib_1.Mask.TypeEnum.ZipCode);
                if (!zip_code || zip_code == '' || zip_code.length == 5) {
                    state.feedQuery = _.assign({}, _.omit(state.feedQuery, ['page_number']), { zip_code: zip_code });
                    search();
                }
                else {
                    state.feedQuery = _.assign({}, state.feedQuery, { zip_code: zip_code });
                }
                return state;
            }
            case actions.filterChanged: {
                var tgt = action.target;
                state.feedQuery = _.assign({}, _.omit(state.feedQuery, ['page_number']), (_a = {}, _a[tgt.id] = tgt.value, _a));
                $(tgt).blur();
                search();
                if (tgt.id == 'model' || tgt.id == 'year_min' || tgt.id == 'year_max') {
                    getStatistic();
                }
                return state;
            }
            case actions.resetTag: {
                state.feedQuery.tag = [];
                search();
                return state;
            }
            case actions.tagChanged: {
                var tgt = action.target;
                if (state.feedQuery.tag == null) {
                    state.feedQuery.tag = [];
                }
                if (tgt.checked) {
                    state.feedQuery.tag.push(Number(tgt.value));
                }
                else {
                    state.feedQuery.tag = _.without(state.feedQuery.tag, Number(tgt.value));
                }
                search();
                return state;
            }
            case actions.sortChanged: {
                state.feedQuery = _.assign({}, state.feedQuery, { page_number: 1, sort: Number(action.target.value), ts: null });
                search();
                return state;
            }
            case actions.showMyPostChanged: {
                if (action.target.checked) {
                    state.feedQuery = _.assign({}, _.omit(state.feedQuery, ['page_number']), { user_id: lib_1.Global.store.getState().user._id });
                }
                else {
                    state.feedQuery = _.omit(state.feedQuery, ['user_id']);
                }
                search();
                return state;
            }
            case actions.search: {
                state.is_loading = true;
                if (_.isEmpty(lib_1.Global.store.getState().user) || (lib_1.Global.store.getState().user._id != state.feedQuery.user_id)) {
                    state.feedQuery = _.omit(state.feedQuery, 'user_id');
                }
                state.feedQuery = _.assign({}, _.omit(state.feedQuery, ['ts', 'page_number']), action.query, { page_number: 1 });
                search();
                return state;
            }
            case actions.searchMore: {
                action.event && action.event.preventDefault();
                state.is_loading_more_feeds = true;
                state.feedQuery = _.assign({}, state.feedQuery, uri(state.feeds.links.next).search(true));
                searchFeeds(state.feedQuery, function (err, results) {
                    DiscussionSearch.store.dispatch({ type: actions.searchDone, data: results, isLoadMore: true });
                });
                return state;
            }
            case actions.searchDone: {
                state.is_loading = false;
                state.is_loading_more_feeds = false;
                var d = action.data;
                if (d) {
                    state.feeds.links = d.links;
                    state.feeds.meta = d.meta;
                    state.feeds.data = (action.isLoadMore) ? _.union(state.feeds.data, d.data) : d.data;
                }
                return state;
            }
            case actions.createFeedDone: {
                state.feedQuery = _.assign({}, state.feedQuery, { sort: 1 });
                getInitSearchOptions(_.pick(state.feedQuery, ['body_type', 'make', 'model']));
                searchFeeds(state.feedQuery, function (err, results) {
                    DiscussionSearch.store.dispatch({ type: actions.searchDone, data: results, isLoadMore: false });
                });
                return state;
            }
            case actions.updateFeedDone: {
                var feed = action.data;
                var x = _.find(state.feeds.data, { _id: feed._id });
                if (x) {
                    _.assign(x, feed);
                }
                getInitSearchOptions(_.pick(state.feedQuery, ['body_type', 'make', 'model']));
                return state;
            }
            case actions.refreshFeed: {
                try {
                    var feed = action.data;
                    var x = _.find(state.feeds.data, { _id: feed._id });
                    if (x) {
                        _.assign(x, feed);
                    }
                    return state;
                }
                catch (err) {
                }
            }
            case actions.setStatistic: {
                state.statistic = action.data;
                return state;
            }
            case actions.setVehicles: {
                state.vehicles = action.data;
                return state;
            }
            case actions.updateStat: {
                try {
                    var d = action.data;
                    var x = _.find(state.feeds.data, { _id: d._id });
                    if (x) {
                        _.assign(x, d);
                    }
                    return state;
                }
                catch (err) {
                }
            }
            default: {
                return state;
            }
        }
    };
    DiscussionSearch.store = null;
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                filter: false,
                moreFilter: false
            };
            return _this;
        }
        Page.prototype.componentWillReceiveProps = function (nextProps) {
            if (this.props.history.action == 'REPLACE' && this.props.location.search == '') {
                DiscussionSearch.store.dispatch({ type: actions.search });
            }
        };
        Page.prototype.componentWillMount = function () {
            var _this = this;
            lib_1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    var locSearch = {};
                    _this.user = lib_1.Global.store.getState().user;
                    if (lib_1.util.isClient()) {
                        locSearch = _.assign(locSearch, new uri(_this.props.location.search).search(true));
                        getInitSearchOptions(_.pick(locSearch, ['body_type', 'make', 'model']));
                    }
                    if (locSearch && locSearch.tag) {
                        locSearch.tag = (typeof locSearch.tag == 'string' ? [Number(locSearch.tag)] : _.map(locSearch.tag, function (x) { return Number(x); }));
                        _this.setState({ moreFilter: true });
                    }
                    if (_this.props.staticContext) {
                        var d = _.assign({}, _this.props.staticContext);
                        DiscussionSearch.store = Redux.createStore(app, _.assign({}, {
                            feeds: d.result,
                            feedQuery: _.assign({}, d.query, locSearch)
                        }));
                    }
                    else if (!DiscussionSearch.store) {
                        var $initData = document.querySelector('#_init_discussion_search');
                        if ($initData && $initData.getAttribute('data-init')) {
                            DiscussionSearch.store = Redux.createStore(app, _.assign({}, {
                                feeds: JSON.parse(decodeURIComponent($initData.getAttribute('data-init'))),
                                feedQuery: _.assign({}, cfg.apiQueryDefault.discussion, locSearch)
                            }));
                            $initData.remove();
                        }
                        else {
                            var d = {
                                feedQuery: _.assign({}, cfg.apiQueryDefault.discussion, locSearch),
                                feeds: {
                                    data: [],
                                    meta: {},
                                    links: {}
                                }
                            };
                            DiscussionSearch.store = Redux.createStore(app, d);
                            DiscussionSearch.store.dispatch({
                                type: actions.search,
                                query: d.feedQuery
                            });
                        }
                        getStatistic();
                    }
                    else if (_this.props.history.action == 'PUSH') {
                        DiscussionSearch.store.dispatch({ type: actions.search, query: locSearch });
                    }
                    _this.unsubscribe = DiscussionSearch.store.subscribe(function () { return _this.forceUpdate(); });
                }
                else {
                    lib_1.Auth.redirectSignin(_this.props.location.pathname + _this.props.location.search);
                }
            });
        };
        Page.prototype.componentDidMount = function () {
            document.title = lib_1.util.title(pages_1.pages.discussion_search.title);
            var locSearch = null;
            if (lib_1.util.isClient()) {
                locSearch = new uri(this.props.location.search).search(true);
                locSearch.tag && this.setState({ moreFilter: true });
            }
        };
        Page.prototype.componentWillUnmount = function () {
            this.unsubscribe && this.unsubscribe();
        };
        Page.prototype.renderFilter = function () {
            var _this = this;
            try {
                var options = DiscussionSearch.store.getState().searchOptions;
                var query_1 = DiscussionSearch.store.getState().feedQuery;
                var sGrid = 'col-lg-2 col-sm-4';
                return (React.createElement("div", { className: 'mb-4 c-section-navbar' },
                    React.createElement("div", { className: 'text-center mb-3 d-sm-none' }, !this.state.filter
                        ?
                            React.createElement("button", { type: 'button', className: 'c-btn-collapse', onClick: function (e) { return _this.toggleFilter(); } },
                                React.createElement("i", { className: 'fas fa-caret-down' }),
                                React.createElement("span", null, "Show Filter"))
                        :
                            React.createElement("button", { type: 'button', className: 'c-btn-collapse', onClick: function (e) { return _this.toggleFilter(); } },
                                React.createElement("i", { className: 'fas fa-caret-up' }),
                                React.createElement("span", null, "Hide Filter"))),
                    React.createElement("div", { className: classNames({ 'd-none d-sm-block': !this.state.filter }) },
                        React.createElement("div", { className: 'row c-sm-gutters' },
                            React.createElement(component_1.Select, { id: 'body_type', label: 'Type', "data-grid": sGrid, size: 1, options: (options && options.body_type) && lib_1.util.mapBodyTypeOptions(options.body_type, true), value: query_1.body_type ? query_1.body_type.toString() : '', onChange: function (e) { return DiscussionSearch.store.dispatch({ type: actions.bodyTypeChanged, target: e.target }); } }),
                            React.createElement(component_1.Select, { id: 'make', label: 'Make', "data-grid": sGrid, size: 1, options: lib_1.util.mapOptions('make', options && _.without(options.make, '~'), { name: 'Any' }, (options && options.make && _.indexOf(options.make, '~') > -1)), value: query_1.make ? query_1.make : '', onChange: function (e) { return DiscussionSearch.store.dispatch({ type: actions.makeChanged, target: e.target }); } }),
                            React.createElement(component_1.Select, { id: 'model', label: 'Model', "data-grid": sGrid, size: 1, options: lib_1.util.mapOptions('model', options && _.without(options.model, '~'), { name: 'Any' }, (options && options.model && _.indexOf(options.model, '~') > -1)), value: query_1.model ? query_1.model : '', onChange: function (e) { return DiscussionSearch.store.dispatch({ type: actions.filterChanged, target: e.target }); } }),
                            React.createElement("div", { className: 'col-sm-6 col-lg-3' },
                                React.createElement("div", { className: 'form-group' },
                                    React.createElement("label", { className: 'control-label' }, "Year"),
                                    React.createElement("div", { className: 'row no-gutters' },
                                        React.createElement("div", { className: 'col' },
                                            React.createElement(component_1.Select, { id: 'year_min', size: 1, value: (query_1 && query_1.year_min) ? query_1.year_min.toString() : '', options: (options && options.year) && lib_1.util.mapYearOptions(_.min(options.year), _.max(options.year)), onChange: function (e) { return DiscussionSearch.store.dispatch({ type: actions.filterChanged, target: e.target }); } })),
                                        React.createElement("div", { className: 'col-md-auto' },
                                            React.createElement("span", { className: 'form-text-middle-nolabel' }, "to")),
                                        React.createElement("div", { className: 'col' },
                                            React.createElement(component_1.Select, { id: 'year_max', size: 1, value: (query_1 && query_1.year_max) ? query_1.year_max.toString() : '', options: (options && options.year) && lib_1.util.mapYearOptions(_.max(options.year), _.min(options.year)), onChange: function (e) { return DiscussionSearch.store.dispatch({ type: actions.filterChanged, target: e.target }); } }))))),
                            React.createElement("div", { className: 'col-sm-6 col-lg-3' },
                                React.createElement("div", { className: 'row no-gutters' },
                                    React.createElement(component_1.MaskedText, { id: 'zip_code', label: 'Zip', mask: lib_1.Mask.TypeEnum.ZipCode, "data-grid": 'col', value: (options && query_1.zip_code) ? query_1.zip_code.toString() : '', onChange: function (e) { return DiscussionSearch.store.dispatch({ type: actions.zipCodeChanged, target: e.target }); } }),
                                    React.createElement("div", { className: 'col-md-auto' }),
                                    React.createElement(component_1.Select, { id: 'distance', label: 'Radius', "data-grid": 'col', size: 1, "data-input-append": 'miles', options: (options && options.distance) && lib_1.util.mapNumberSearchOptions(options.distance), value: (options && options.distance && query_1.distance) ? query_1.distance : '', onChange: function (e) { return DiscussionSearch.store.dispatch({ type: actions.filterChanged, target: e.target }); } })))),
                        (options && options.tag_list)
                            &&
                                this.renderMoreFilter(options.tag_list)),
                    React.createElement("div", { className: 'row no-gutters mt-3 mt-sm-2' },
                        React.createElement("div", { className: 'col-md-8' },
                            React.createElement("ul", { className: 'list-inline c-btn-group-radio' },
                                React.createElement("li", { className: 'c-text-muted list-inline-item' }, "Sort:"),
                                _.map(lib_1.Lookup.communitySort, function (cs) {
                                    return (React.createElement("li", { key: 'csort_' + cs.value, className: 'list-inline-item' },
                                        React.createElement("input", { type: 'radio', name: 'sort', id: 'csort_' + cs.value, value: cs.value, checked: (query_1.sort == cs.value ? true : false), onChange: function (e) { return DiscussionSearch.store.dispatch({ type: actions.sortChanged, target: e.target }); } }),
                                        React.createElement("label", { htmlFor: 'csort_' + cs.value }, cs.name)));
                                }))),
                        (this.user && this.user._id) &&
                            React.createElement("div", { className: 'col-md-4' },
                                React.createElement(component_1.Checkbox, { id: 'my_post', label: "Show My Posts Only", className: 'float-md-right', defaultChecked: query_1.user_id && query_1.user_id != '', onChange: function (e) { return DiscussionSearch.store.dispatch({ type: actions.showMyPostChanged, target: e.target }); } })))));
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.renderMoreFilter = function (tag_list) {
            var _this = this;
            try {
                var qTagList_1 = DiscussionSearch.store.getState().feedQuery.tag;
                return (this.state.moreFilter
                    ?
                        React.createElement("div", null,
                            React.createElement("ul", { className: 'c-filter-list-tag list-inline' },
                                _.map(tag_list, function (cTag) {
                                    return (React.createElement("li", { key: 'ctag_' + cTag.id, className: 'list-inline-item c-btn-checkbox' },
                                        React.createElement("input", { type: 'checkbox', autoComplete: 'off', id: 'ctag_' + cTag.id, value: cTag.id, checked: (qTagList_1 && _.indexOf(qTagList_1, cTag.id) > -1) ? true : false, onChange: function (e) { return DiscussionSearch.store.dispatch({ type: actions.tagChanged, target: e.target }); } }),
                                        React.createElement("label", { htmlFor: 'ctag_' + cTag.id }, cTag.name)));
                                }),
                                React.createElement("li", { className: 'list-inline-item c-btn-checkbox' },
                                    React.createElement("input", { type: 'checkbox', id: 'ctag_any', autoComplete: 'off', checked: (qTagList_1 == null || qTagList_1.length == 0) ? true : false, onChange: function (e) { return DiscussionSearch.store.dispatch({ type: actions.resetTag, target: e.target }); } }),
                                    React.createElement("label", { htmlFor: 'ctag_any' }, "Any"))),
                            React.createElement("div", { className: 'text-center mb-3' },
                                React.createElement("button", { type: 'button', className: 'c-btn-collapse', onClick: function (e) { return _this.toggleMoreFilter(); } },
                                    React.createElement("i", { className: 'fas fa-caret-up' }),
                                    React.createElement("span", null, "less filters"))))
                    :
                        React.createElement("div", { className: 'text-center mb-3' },
                            React.createElement("button", { type: 'button', className: 'c-btn-collapse', onClick: function (e) { return _this.toggleMoreFilter(); } },
                                React.createElement("i", { className: 'fas fa-caret-down' }),
                                React.createElement("span", null,
                                    "more filters (",
                                    ((qTagList_1 && qTagList_1.length > 0) ? qTagList_1.length : '-') + '/' + tag_list.length,
                                    ")"))));
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.toggleFilter = function () {
            this.setState({ filter: !this.state.filter });
        };
        Page.prototype.toggleMoreFilter = function () {
            this.setState({ moreFilter: !this.state.moreFilter });
        };
        Page.prototype.renderFeedSummary = function (d) {
            try {
                return (React.createElement("div", { key: d._id, className: 'c-feed', id: 'feed_' + d._id },
                    React.createElement("h2", { className: 'c-feed-title' },
                        React.createElement(react_router_dom_1.Link, { className: 'c-btn-link', to: pages_1.pages.discussion_detail.path + '/' + slugify_1.default(d.title) + '/' + d._id }, d.title)),
                    React.createElement("div", { className: 'd-flex align-items-center' },
                        component_1.User.renderUserIcon(d.user_display),
                        React.createElement("div", { className: 'ml-3 c-ud-summary ellipsis' },
                            React.createElement("div", { className: 'ellipsis' },
                                React.createElement("span", { className: 'c-name' }, d.user_display.first_name + ' ' + d.user_display.last_name),
                                React.createElement("span", { className: 'c-font-size-s ml-3' }, moment(d.date_create).fromNow())),
                            React.createElement("div", { className: 'c-location' }, d.user_display.location.city + ', ' + d.user_display.location.state))),
                    (d.photo_list && d.photo_list.length > 0) &&
                        React.createElement("div", { className: 'mt-3' }),
                    (d.content && d.content != '') &&
                        React.createElement("div", { className: 'mt-3 pb-1' }, lib_1.util.text2html(d.content))));
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.getNextPageUrl = function () {
            try {
                var q = _.assign({}, DiscussionSearch.store.getState().feedQuery, uri.parseQuery(new uri(DiscussionSearch.store.getState().feeds.links.next).query()));
                return pages_1.pages.discussion_search.path + '?' + uri.buildQuery(q);
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.renderFeeds = function (feeds) {
            var _this = this;
            try {
                return (React.createElement("div", null,
                    React.createElement("div", { className: 'c-feed-list' }, _.map(feeds.data, function (feed) {
                        return (_this.renderFeedSummary(feed));
                    })),
                    (feeds.links && DiscussionSearch.store.getState().feeds.links.next)
                        &&
                            React.createElement("section", { className: 'text-center mt-4' },
                                React.createElement("a", { className: 'btn c-btn-2-rev c-btn-round', href: this.getNextPageUrl(), onClick: function (e) { return DiscussionSearch.store.dispatch({ type: actions.searchMore, event: e }); } },
                                    React.createElement("i", { className: 'far fa-angle-double-down' }),
                                    "r",
                                    DiscussionSearch.store.getState().is_loading_more_feeds ? React.createElement(component_1.BtnWorking, { value: 'Loading More...' }) : React.createElement("span", null,
                                        "View More (",
                                        feeds.meta.total_records_left.toLocaleString(),
                                        ")")))));
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.renderFeedList = function () {
            var feeds = DiscussionSearch.store.getState().feeds;
            return (React.createElement("div", null,
                React.createElement("div", { className: 'row no-gutters mb-4' },
                    React.createElement("div", { className: 'col-sm-6' },
                        React.createElement("h2", { className: 'c-title' },
                            "Feeds",
                            (!_.isEmpty(feeds.meta) && feeds.meta.total_records > 0) &&
                                React.createElement("span", { className: 'c-title-count' }, feeds.meta.total_records_matched.toLocaleString() + '/' + feeds.meta.total_records.toLocaleString()))),
                    (feeds.meta.total_records > 0) &&
                        React.createElement("div", { className: 'col-sm-6' },
                            React.createElement("div", { className: 'mt-3 mt-sm-0' }))),
                (feeds.meta.total_records == 0)
                    ?
                        this.renderNoPost()
                    :
                        this.renderFilter(),
                (feeds.meta.total_records > 0 && feeds.meta.total_records_matched == 0) &&
                    React.createElement("div", { className: 'alert c-alert' }, "No feed matched your search"),
                (feeds.meta.total_records_matched > 0) &&
                    React.createElement("div", { className: 'row no-gutters' },
                        React.createElement("div", { className: 'col-lg-9 pr-lg-5' }, this.renderFeeds(feeds)),
                        React.createElement("div", { className: 'col-lg-3' },
                            React.createElement(component_1.Vehicle.SimilarVehicles, { className: 'col-sm-6 col-lg-12 mb-3', query: _.assign({}, cfg.apiQueryDefault.discussion_vehicle, _.pick(DiscussionSearch.store.getState().feedQuery, ['body_type', 'make', 'model', 'year_min', 'year_max']), {
                                    page_size: (DiscussionSearch.store.getState().feeds.meta.total_records_matched * 2.5).toFixed()
                                }) })))));
        };
        Page.prototype.renderNoPost = function () {
            return (React.createElement("div", null,
                React.createElement("p", { className: 'mb-4' }, "Post the first feed!")));
        };
        Page.prototype.render = function () {
            return (component_1.Layout.main(null, null, React.createElement("div", { className: 'community-search' },
                React.createElement(component_1.Hero, { title: pages_1.pages.discussion_search.title, room: lib_1.util.getRoom(this.props.location.pathname) }),
                React.createElement("div", { className: 'container c-container' }, (!DiscussionSearch.store || DiscussionSearch.store.getState().is_loading)
                    ?
                        React.createElement(component_1.Loading, null)
                    :
                        this.renderFeedList()))));
        };
        return Page;
    }(React.Component));
    DiscussionSearch.Page = Page;
})(DiscussionSearch = exports.DiscussionSearch || (exports.DiscussionSearch = {}));
