"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var request = require("superagent");
var _ = require("lodash");
var moment = require("moment");
var uri = require("urijs");
var Cruz = require("cruz");
var cfg = require("../../../cfg");
var lib_1 = require("../../lib");
var __1 = require("..");
var pages_1 = require("../../../routes/pages");
var Comment;
(function (Comment) {
    function searchComment(apiUrl, query, callback) {
        lib_1.Auth.isAuthed(function () {
            var url = apiUrl + '?' + uri.buildQuery(query);
            request(url)
                .timeout(cfg.superagent.timeout.normal)
                .set(lib_1.Auth.authHeader())
                .end(function (err, apiRes) {
                return callback(err, _.get(apiRes, 'body'));
            });
        });
    }
    Comment.searchComment = searchComment;
    function isContentValid(content) {
        return (content && content.trim() != '');
    }
    var Component = (function (_super) {
        __extends(Component, _super);
        function Component(props) {
            var _this = _super.call(this, props) || this;
            _this.state = _.assign({}, _this.props, {
                my_user_id: !_.isEmpty(lib_1.Global.store.getState().user) ? lib_1.Global.store.getState().user._id : null,
                status: {},
                result: _this.props.result || {
                    meta: {
                        total_records: null,
                        total_records_lef: null
                    },
                    links: null,
                    data: []
                },
                api_url: _this.props.api_base_url + '/' + _this.props.discussion_id + '/comment',
                create: {},
                update: {}
            });
            return _this;
        }
        Component.prototype.componentWillMount = function () {
            if (!_.isEmpty(this.props.result)) {
                this.setState({ result: _.assign({}, this.props.result) });
            }
            else if (typeof init_comment != 'undefined' && init_comment != null) {
                this.setState({ result: _.assign({}, init_comment) }, function () {
                    init_comment = null;
                });
            }
            else {
                this.setState({ status: { is_loading: true } });
                this.loadComment();
            }
        };
        Component.prototype.handleError = function (err, apiResBody) {
            this.setState({ status: {} });
            if (apiResBody) {
                var errMsg = _.get(apiResBody, 'error.detail');
                if (errMsg) {
                    lib_1.util.setToastMessage('error', errMsg);
                }
            }
        };
        Component.prototype.openDeleteDialog = function (_id) {
            var _this = this;
            lib_1.util.setModal(null, false, true, React.createElement(__1.Delete.Dialog, { id: _id, onYes: function (e) { return _this.deleteYes(_id); } }));
        };
        Component.prototype.deleteYes = function (_id) {
            var _this = this;
            this.setState({ status: { is_deleting: true } }, function () {
                lib_1.Auth.isAuthed(function (isAuthed) {
                    if (isAuthed) {
                        request.delete(_this.state.api_url + '/' + _id)
                            .set(lib_1.Auth.authHeader())
                            .timeout(cfg.superagent.timeout.normal)
                            .end(function (err, apiRes) {
                            if (err) {
                                if (err.status == '410') {
                                    _this.loadComment();
                                }
                                else {
                                    _this.handleError(err, _.get(apiRes, 'body'));
                                }
                            }
                            else {
                                _this.loadComment();
                            }
                            _this.setState({ status: {} });
                        });
                    }
                    else {
                        lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                    }
                });
            });
        };
        Component.prototype.getNextPageUrl = function () {
            if (this.props.parent_url) {
                var q = _.assign({}, this.state.query, uri.parseQuery(new uri(this.state.result.links.next).query()));
                return this.props.parent_url + '?' + uri.buildQuery(q);
            }
            else {
                return '#';
            }
        };
        Component.prototype.loadComment = function (query) {
            var _this = this;
            searchComment(this.state.api_url, query || this.state.query, function (err, d) {
                if (err) {
                    _this.handleError(err, null);
                }
                else {
                    _this.loadCommentDone(d);
                }
            });
        };
        Component.prototype.loadMoreComment = function (e) {
            var _this = this;
            e.preventDefault();
            lib_1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    _this.setState({ status: { is_loading_more: true } }, function () {
                        request(_this.state.result.links.next)
                            .timeout(cfg.superagent.timeout.normal)
                            .end(function (err, apiRes) {
                            if (err) {
                                _this.handleError(err, _.get(apiRes, 'body'));
                            }
                            else {
                                _this.loadCommentDone(_.get(apiRes, 'body'));
                            }
                        });
                    });
                }
                else {
                    lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                }
            });
        };
        Component.prototype.createCommentContentChanged = function (tgt) {
            this.setState({ create: { content: tgt.value } });
        };
        Component.prototype.loadCommentDone = function (d) {
            var _this = this;
            var total_prev = this.state.result.meta.total_records;
            this.setState({
                status: {},
                result: {
                    links: _.assign({}, d.links),
                    meta: _.assign({}, d.meta),
                    data: (this.state.status.is_loading_more ? _.union(this.state.result.data, d.data) : d.data)
                }
            }, function () {
                if (_this.props.onCommentDone && (!total_prev || !d.meta.total_records || total_prev != d.meta.total_records)) {
                    _this.props.onCommentDone(d.meta.total_records);
                }
            });
        };
        Component.prototype.renderCreateForm = function () {
            var _this = this;
            return (React.createElement("section", null,
                React.createElement(__1.Textarea, { className: 'form-group-alt', placeholder: 'Comment', name: 'newComment', minRows: 1, maxRows: 10, value: this.state.create.content || '', onChange: function (e) { return _this.createCommentContentChanged(e.target); }, "data-err": !_.isEmpty(this.state.create.err) ? this.state.create.err.detail : null }),
                React.createElement("div", { className: 'text-right' },
                    React.createElement("button", { type: 'button', className: 'btn c-btn-1 c-btn-xs', onClick: function (e) { return _this.createComment(); } }, this.state.status.is_creating ? React.createElement(__1.BtnWorking, { value: 'Commenting ...' }) : 'Comment'))));
        };
        Component.prototype.createComment = function () {
            var _this = this;
            if (!this.state.status.is_creating && isContentValid(this.state.create.content)) {
                this.setState({ create: _.assign({}, this.state.create), status: { is_creating: true } }, function () {
                    lib_1.Auth.isAuthed(function (isAuthed) {
                        if (isAuthed) {
                            request.post(_this.state.api_url)
                                .set(lib_1.Auth.authHeader())
                                .send({ content: _this.state.create.content })
                                .timeout(cfg.superagent.timeout.normal)
                                .end(function (err, apiRes) {
                                if (err) {
                                    _this.setState({ status: {} });
                                    _this.handleError(err, _.get(apiRes, 'body'));
                                }
                                else {
                                    _this.setState({ status: {}, create: {} }, function () {
                                        var temp_query = {};
                                        if (_this.state.result && _this.state.result.data && _this.state.result.data.length > 0) {
                                            temp_query = _.assign({}, _this.state.query, {
                                                page_size: _this.state.result.data.length,
                                                tsn: new Date(_.first(_this.state.result.data).date_create).getTime()
                                            });
                                        }
                                        else {
                                            temp_query = _.assign({}, _this.state.query);
                                        }
                                        _this.loadComment(temp_query);
                                    });
                                }
                            });
                        }
                        else {
                            lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                        }
                    });
                });
            }
        };
        Component.prototype.updateComment = function (_id) {
            this.setState({
                update: _.pick(_.find(this.state.result.data, { _id: _id }), ['_id', 'content'])
            });
        };
        Component.prototype.updateCommentContentChanged = function (tgt) {
            this.setState({
                update: _.assign({}, this.state.update, { content: tgt.value })
            });
        };
        Component.prototype.saveUpdate = function () {
            var _this = this;
            if (!this.state.status.is_updating && this.state.update.content && this.state.update.content.trim() != '') {
                this.setState({ status: { is_updating: true } }, function () {
                    lib_1.Auth.isAuthed(function (isAuthed) {
                        if (isAuthed) {
                            request.put(_this.state.api_url + '/' + _this.state.update._id)
                                .set(lib_1.Auth.authHeader())
                                .send({ content: _this.state.update.content })
                                .timeout(cfg.superagent.timeout.normal)
                                .end(function (err, apiRes) {
                                if (err) {
                                    _this.setState({ status: {} });
                                    _this.handleError(err, _.get(apiRes, 'body'));
                                }
                                else {
                                    var d = _.get(apiRes, 'body.data');
                                    if (d) {
                                        var x = _.find(_this.state.result.data, { _id: d._id });
                                        x && _.assign(x, d);
                                    }
                                    _this.setState({ status: {}, update: {} });
                                }
                            });
                        }
                        else {
                            lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                        }
                    });
                });
            }
        };
        Component.prototype.renderUpdateForm = function () {
            var _this = this;
            try {
                return (React.createElement(React.Fragment, null,
                    React.createElement(__1.Textarea, { className: 'form-group-alt', placeholder: 'Comment', name: 'updateComment', minRows: 1, maxRows: 10, value: this.state.update.content || '', onChange: function (e) { return _this.updateCommentContentChanged(e.target); }, "data-err": !_.isEmpty(this.state.update.err) ? this.state.update.err.detail : null }),
                    React.createElement("div", { className: 'text-right' },
                        React.createElement("button", { type: 'button', className: 'btn c-btn-2 c-btn-xs mr-2', onClick: function (e) { return _this.setState({ update: {} }); } }, "Cancel"),
                        React.createElement("button", { type: 'button', className: 'btn c-btn-1 c-btn-xs', onClick: function (e) { return _this.saveUpdate(); } }, this.state.status.is_updating ? React.createElement(__1.BtnWorking, { value: 'Saving ...' }) : 'Save'))));
            }
            catch (err) {
                return null;
            }
        };
        Component.prototype.toggleReply = function (d) {
            var _this = this;
            lib_1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    var x = _.find(_this.state.result.data, { _id: d._id });
                    if (x) {
                        _.assign(x, { show_reply: !x.show_reply });
                        _this.forceUpdate();
                    }
                }
                else {
                    lib_1.util.setModal(null, false, false, React.createElement(__1.Modal.RequestAuth, null));
                }
            });
        };
        Component.prototype.updateReplyCount = function (comment_id, reply_count) {
            var x = _.find(this.state.result.data, { _id: comment_id });
            if (x) {
                _.assign(x, { reply_count: reply_count });
                this.forceUpdate();
            }
        };
        Component.prototype.voteDone = function (d) {
            var x = _.find(this.state.result.data, { _id: d._id });
            if (x) {
                _.assign(x, d);
                this.forceUpdate();
            }
        };
        Component.prototype.renderComment = function (d) {
            var _this = this;
            try {
                var isMine = (this.state.my_user_id && this.state.my_user_id == d.user_id);
                var ud = d.user_display;
                var userUrl = lib_1.util.userCruzBaseUrl(ud);
                return (React.createElement("div", { key: d._id, className: 'd-flex c-comment', id: 'comment_' + d._id },
                    React.createElement("div", { className: 'mr-1' }, (ud.status_id == Cruz.Lookup.CruzUser.StatusEnum.Disabled || ud.status_id == Cruz.Lookup.CruzUser.StatusEnum.Closed)
                        ?
                            __1.User.renderUserIcon(ud)
                        :
                            React.createElement(react_router_dom_1.Link, { to: userUrl }, __1.User.renderUserIcon(ud))),
                    React.createElement("div", { className: 'flex-grow ml-2' },
                        React.createElement("div", { className: 'c-ud-summary ellipsis' },
                            React.createElement("div", { className: 'ellipsis' },
                                React.createElement("span", { className: 'c-name' }, (ud.status_id == Cruz.Lookup.CruzUser.StatusEnum.Closed || ud.status_id == Cruz.Lookup.CruzUser.StatusEnum.Disabled)
                                    ?
                                        '-'
                                    :
                                        React.createElement(react_router_dom_1.Link, { to: userUrl, className: 'c-btn-link' }, _.startCase(ud.first_name + ' ' + ud.last_name))),
                                React.createElement("span", { className: 'c-font-size-xs ml-3 c-location' }, ud.location.city + ', ' + ud.location.state)),
                            React.createElement("div", { className: 'c-font-size-xs' }, moment(d.date_create).fromNow())),
                        React.createElement("div", { className: 'word-wrap mt-2 pb-1' },
                            (this.state.update._id == d._id && isMine)
                                ?
                                    this.renderUpdateForm()
                                :
                                    lib_1.util.text2html(d.content),
                            (d.date_update && d.date_update != '') &&
                                React.createElement("div", { className: 'c-font-size-xs c-text-muted mt-1' },
                                    "(updated ",
                                    moment(d.date_update).fromNow(),
                                    ")")),
                        React.createElement("div", { className: 'mt-1 c-toolbar d-flex' },
                            React.createElement("div", { className: 'd-flex align-items-center' },
                                React.createElement("button", { className: 'btn c-btn-comment', onClick: function (e) { return _this.toggleReply(d); } },
                                    React.createElement("i", { className: 'fal fa-reply' }),
                                    React.createElement("span", { className: 'c-count-prepend' }, d.reply_count ? d.reply_count.toLocaleString() : 0),
                                    React.createElement("span", { className: 'c-font-size-xxs' }, (d.reply_count && d.reply_count > 1) ? 'Replies' : 'Reply')),
                                React.createElement("span", { className: 'separator' }),
                                React.createElement(__1.Vote.LikeButton, { name: this.props.name, _id: d._id, user_id: d.user_id, vote: d.vote, className: 'btn c-btn-comment', onVoteDone: this.voteDone.bind(this) })),
                            (isMine && this.state.status.is_deleting) &&
                                React.createElement("div", { className: 'ml-auto pl-4' },
                                    React.createElement("i", { className: 'far fa-spinner fa-pulse' })),
                            (isMine && !this.state.status.is_deleting && this.state.update._id != d._id && (!d.reply_count || d.reply_count == 0)) &&
                                React.createElement("div", { className: 'ml-auto c-ellipsis-drop pl-4 dropup' },
                                    React.createElement("button", { className: 'dropdown-toggle', "data-toggle": 'dropdown' },
                                        React.createElement("i", { className: 'far fa-ellipsis-v' })),
                                    React.createElement("div", { className: 'dropdown-menu dropdown-menu-right' },
                                        React.createElement("button", { className: 'dropdown-item', onClick: function (e) { return _this.updateComment(d._id); } },
                                            React.createElement("i", { className: 'far fa-pen fa-fw' }),
                                            React.createElement("span", null, "Edit")),
                                        React.createElement("div", { className: 'dropdown-divider' }),
                                        React.createElement("button", { className: 'dropdown-item', onClick: function (e) { return _this.openDeleteDialog(d._id); } },
                                            React.createElement("i", { className: 'fal fa-trash-alt fa-fw' }),
                                            React.createElement("span", null, "Delete"))))),
                        d.show_reply &&
                            React.createElement(__1.CommentReply.Reply, { name: this.props.name, comment_id: d._id, api_base_url: this.state.api_url + '/' + d._id + '/reply', reply_count: d.reply_count || 0, onReplyCountUpdate: this.updateReplyCount.bind(this) }))));
            }
            catch (err) {
                return null;
            }
        };
        Component.prototype.render = function () {
            var _this = this;
            try {
                var result = this.state.result;
                var comment_list = result.data;
                return (React.createElement("div", { className: 'c-comments', id: '_comments' },
                    React.createElement("section", { className: 'mb-3' },
                        React.createElement("h2", { className: 'c-title c-title-xs' },
                            "Comments",
                            React.createElement("span", { className: 'c-title-count' }, result.meta.total_records || 0))),
                    _.isEmpty(lib_1.Global.store.getState().user)
                        ?
                            React.createElement("section", null,
                                "Please ",
                                React.createElement(react_router_dom_1.Link, { to: pages_1.pages.signin.path + '?returnUrl=' + this.props.parent_url, className: 'c-btn-link' }, "Sign in"),
                                " to leave a comment.",
                                React.createElement("br", null),
                                "Don't have an account? ",
                                React.createElement(react_router_dom_1.Link, { to: pages_1.pages.register.path, className: 'c-bnt-link' }, "Create a Free Account"),
                                ".")
                        :
                            this.renderCreateForm(),
                    React.createElement("hr", { className: 'c-hr mb-0' }),
                    this.state.status.is_loading &&
                        React.createElement("div", { className: 'c-loading' },
                            React.createElement("i", { className: 'far fa-spinner fa-pulse fa-3x' }),
                            React.createElement("div", { className: 'mt-2' }, "loading ...")),
                    (comment_list && comment_list.length > 0) &&
                        _.map(comment_list, function (d) { return _this.renderComment(d); }),
                    (result.links && result.links.next)
                        &&
                            React.createElement("div", { className: 'text-center mt-4' },
                                React.createElement("a", { className: 'btn c-btn-2-rev c-btn-round c-btn-xs', href: this.getNextPageUrl(), onClick: function (e) { return _this.loadMoreComment(e); } },
                                    React.createElement("i", { className: 'far fa-angle-double-down' }),
                                    this.state.status.is_loading_more ? React.createElement(__1.BtnWorking, { value: 'Loading...' }) : React.createElement("span", null, "Load More")))));
            }
            catch (err) {
                return null;
            }
        };
        return Component;
    }(React.Component));
    Comment.Component = Component;
})(Comment = exports.Comment || (exports.Comment = {}));
