"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var Redux = require("redux");
var react_router_dom_1 = require("react-router-dom");
var _ = require("lodash");
var uri = require("urijs");
var request = require("superagent");
var numeral = require("numeral");
var moment = require("moment");
var classNames = require("classnames");
var slugify_1 = require("slugify");
var Cruz = require("cruz");
var lib_1 = require("../lib");
var pages_1 = require("../../routes/pages");
var component_1 = require("../component");
var MyServiceQuote;
(function (MyServiceQuote) {
    var initData = _.memoize(_.assign)({
        is_loading: false,
        result: {
            meta: null,
            data: null,
            links: null
        }
    });
    function searchMyServiceQuote(query, callback) {
        request.get(lib_1.Global.cruzApiBaseUrl + '/user/my-service-quote' + lib_1.util.buildApiQueryString(query)).set(lib_1.Auth.authHeader()).end(function (err, apiRes) {
            return callback(err, apiRes);
        });
    }
    MyServiceQuote.searchMyServiceQuote = searchMyServiceQuote;
    var app = function (state, action) {
        function search() {
            searchMyServiceQuote(state.query, function (err, apiRes) {
                err ? handleApiError(err) : MyServiceQuote.store.dispatch({ type: 3, data: apiRes.body, isLoadMore: false });
            });
        }
        function handleApiError(err) {
            MyServiceQuote.store.dispatch({ type: 0, error: _.get(err, 'response.status') });
        }
        function update(quote) {
            if (quote) {
                var x = _.find(state.result.data, { _id: quote._id });
                if (x) {
                    _.assign(x, quote);
                }
            }
        }
        switch (action.type) {
            case 0: {
                state.is_loading = false;
                state.is_loading_more = false;
                state.is_filtering = false;
                lib_1.util.handleError(lib_1.Request.getApiErrorCode(action.error));
                break;
            }
            case 1: {
                if (!state.is_loading) {
                    state.is_loading = true;
                    search();
                }
                break;
            }
            case 6: {
                break;
            }
            case 2: {
                action.event && action.event.preventDefault();
                if (!state.is_loading && !state.is_loading_more) {
                    state.is_loading_more = true;
                    searchMyServiceQuote(_.assign({}, state.query, uri.parseQuery(new uri(state.result.links.next).query())), function (err, apiRes) {
                        if (err) {
                            handleApiError(err);
                        }
                        else {
                            MyServiceQuote.store.dispatch({ type: 3, data: apiRes.body, isLoadMore: true });
                        }
                    });
                }
                break;
            }
            case 3: {
                state.is_loading = false;
                state.is_loading_more = false;
                state.is_filtering = false;
                var d = _.assign({}, action.data);
                if (d) {
                    state.result.links = _.assign({}, d.links);
                    state.result.meta = _.assign({}, d.meta);
                    state.result.data = (action.isLoadMore) ? _.union(state.result.data, d.data) : d.data;
                }
                var tgtIndex = d.meta.page_size * (d.meta.page_number - 1);
                if (tgtIndex > 0) {
                    lib_1.util.scrollTo('#_sq_' + tgtIndex, 70);
                }
                break;
            }
            case 4: {
                update(action.data);
                break;
            }
            default:
                return state;
        }
        return state;
    };
    MyServiceQuote.store = null;
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            return _super.call(this, props) || this;
        }
        Page.prototype.componentWillMount = function () {
            var _this = this;
            lib_1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    var locSearch = new uri(_this.props.location.search).search(true);
                    if (_this.props.staticContext) {
                        MyServiceQuote.store = Redux.createStore(app, _.assign({}, _this.props.staticContext));
                    }
                    else if (MyServiceQuote.store == null) {
                        var $initData = document.querySelector('#_init_' + pages_1.pages.my_service_quote.id);
                        if ($initData && $initData.getAttribute('data-init')) {
                            MyServiceQuote.store = Redux.createStore(app, _.assign({}, {
                                result: JSON.parse(decodeURIComponent($initData.getAttribute('data-init'))),
                                query: locSearch,
                                history: _this.props.history
                            }));
                            $initData.remove();
                        }
                        else {
                            var d = _.assign({}, { query: locSearch }, initData);
                            MyServiceQuote.store = Redux.createStore(app, d);
                            MyServiceQuote.store.dispatch({ type: 1 });
                        }
                    }
                    else if (['PUSH', 'REPLACE'].includes(_this.props.history.action)) {
                        MyServiceQuote.store.dispatch({ type: 1 });
                    }
                    if (lib_1.util.isClient()) {
                        lib_1.Global.history.replace(lib_1.util.buildUrl(location.pathname, locSearch));
                    }
                    _this.unsubscribe = MyServiceQuote.store.subscribe(function () { _this.forceUpdate(); });
                }
                else {
                    lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                }
            });
        };
        Page.prototype.componentDidMount = function () {
            document.title = lib_1.util.title(pages_1.pages.my_service_quote.title);
            lib_1.Global.saveUserSettings({ my_cruz_path: pages_1.pages.my_service_quote.path });
        };
        Page.prototype.componentWillUnmount = function () {
            this.unsubscribe && this.unsubscribe();
        };
        Page.prototype.renderHeading = function () {
            try {
                var meta = MyServiceQuote.store.getState().result.meta;
                return (React.createElement("div", { className: 'mb-4 d-flex justify-content-between' },
                    React.createElement("h1", { className: 'c-title' },
                        pages_1.pages.my_service_quote.title,
                        (!_.isEmpty(meta) && meta.total_records > 0)
                            &&
                                React.createElement("span", { className: 'c-title-count' },
                                    (meta.total_records_matched != meta.total_records) && meta.total_records_matched.toLocaleString() + '/',
                                    meta.total_records.toLocaleString())),
                    (!_.isEmpty(meta) && meta.total_records > 0)
                        &&
                            React.createElement(react_router_dom_1.Link, { to: pages_1.pages.service_quote_request.path, className: 'btn c-btn-1 c-btn-xs' },
                                React.createElement("i", { className: 'fal fa-plus' }),
                                React.createElement("span", null, "Request Quote"))));
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.renderServiceQuoteCard = function (quote) {
            try {
                var url = pages_1.pages.service_quote_detail.path + '/' + quote._id;
                var v = quote.vehicle_display;
                v.location = lib_1.Global.store.getState().user.location;
                var result_item_count = numeral(quote.result_item_count).value();
                var status_1 = _.find(lib_1.Lookup.service_quote_status_list, { id: quote.status_id });
                var log = _.find(quote.log, { status_id: quote.status_id });
                return (React.createElement("div", { className: 'card' },
                    React.createElement("div", { className: 'card-body' },
                        React.createElement("h2", { className: 'card-title ellipsis' },
                            React.createElement(react_router_dom_1.Link, { to: url, className: 'c-btn-link' }, quote.service.name)),
                        React.createElement("div", { className: 'c-font-size-xs mt-1' },
                            React.createElement("i", { className: 'fal fa-clock mr-2' }),
                            React.createElement("span", null,
                                "Requested ",
                                moment(quote.date_create).fromNow())),
                        (!_.isEmpty(v.vin) || (v.mileage != null && v.mileage > 0))
                            &&
                                React.createElement("div", { className: 'mt-3 quote-vehicle' },
                                    React.createElement("div", { className: 'ellipsis' },
                                        React.createElement(react_router_dom_1.Link, { to: component_1.Vehicle.detailUrl(v) || '', className: 'c-btn-link' }, component_1.Vehicle.detailTitle(v, false))),
                                    React.createElement("ul", { className: 'list-unstyled specs' },
                                        !_.isEmpty(v.vin)
                                            &&
                                                React.createElement("li", null,
                                                    React.createElement("span", { className: 'name mr-2' }, "VIN:"),
                                                    React.createElement("span", { className: 'value' }, v.vin)),
                                        (v.mileage != null && v.mileage > 0)
                                            &&
                                                React.createElement("li", null,
                                                    React.createElement("span", { className: 'name mr-2' }, "Mileage:"),
                                                    React.createElement("span", { className: 'value' },
                                                        React.createElement("span", { className: 'highlight' }, v.mileage.toLocaleString()),
                                                        " mile",
                                                        v.mileage > 1 ? 's' : '')))),
                        React.createElement("hr", null),
                        React.createElement("div", { className: 'd-flex' },
                            React.createElement("i", { className: classNames('fal fa-lg mt-1 mr-3', status_1.fa_icon || 'fa-info-circle') }),
                            React.createElement("div", { className: 'flex-grow-1' },
                                React.createElement(react_router_dom_1.Link, { to: url, className: 'c-font-weight-medium c-btn-link' },
                                    status_1.status,
                                    _.get(quote, 'charge.refunded') && ' & Refunded'),
                                !_.isEmpty(log)
                                    ?
                                        React.createElement("div", { className: 'c-font-size-xs' },
                                            React.createElement("i", { className: 'fal fa-clock mr-2' }),
                                            React.createElement("span", null, moment(log.date_create).fromNow()))
                                    :
                                        (quote.status_id == Cruz.Lookup.Service.QuoteStatusEnum.InProgress)
                                            &&
                                                React.createElement("div", { className: 'c-font-size-xs' },
                                                    React.createElement("i", { className: 'fal fa-clock mr-2' }),
                                                    React.createElement("span", null, moment(quote.date_create).fromNow())),
                                !_.isEmpty(status_1.description)
                                    &&
                                        React.createElement("div", { className: 'c-font-size-s mt-1' }, status_1.description),
                                (status_1.id == Cruz.Lookup.Service.QuoteStatusEnum.ReadyForService || status_1.id == Cruz.Lookup.Service.QuoteStatusEnum.Completed)
                                    &&
                                        React.createElement("div", { className: 'mt-2' },
                                            React.createElement(react_router_dom_1.Link, { to: pages_1.pages.service_voucher.path + '/' + slugify_1.default(quote.service.name) + '/' + quote._id, className: 'btn c-btn-1 c-btn-xs' },
                                                React.createElement("i", { className: 'fal fa-file' }),
                                                React.createElement("span", null, "Voucher"))))))));
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.renderData = function () {
            var _this = this;
            try {
                var result = MyServiceQuote.store.getState().result;
                return (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'row c-sm-gutters' }, _.map(result.data, function (d, idx) {
                        return (React.createElement("div", { key: d._id, className: 'col-md-6 col-xl-4 my-2', id: '_sq_' + idx }, _this.renderServiceQuoteCard(d)));
                    })),
                    (!_.isEmpty(result.links) && result.links.next != null)
                        &&
                            React.createElement("section", { className: 'text-center mt-4' },
                                React.createElement("button", { className: 'btn c-btn-2-rev c-btn-round', onClick: function (e) { return MyServiceQuote.store.dispatch({ type: 2, event: e }); } },
                                    React.createElement("i", { className: 'far fa-angle-double-down' }),
                                    MyServiceQuote.store.getState().is_loading_more ? React.createElement(component_1.BtnWorking, { value: 'Loading More...' }) : React.createElement("span", null,
                                        "Load More (",
                                        result.meta.total_records_left.toLocaleString() + ' left',
                                        ")")))));
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.render = function () {
            try {
                return (component_1.Layout.main(lib_1.Global.store.getState().user, null, React.createElement("div", { className: 'container c-container' }, (MyServiceQuote.store == null)
                    ?
                        React.createElement(component_1.Loading, null)
                    :
                        React.createElement(React.Fragment, null,
                            this.renderHeading(),
                            MyServiceQuote.store.getState().is_loading
                                ?
                                    React.createElement(component_1.Loading, null)
                                :
                                    React.createElement(React.Fragment, null, MyServiceQuote.store.getState().is_filtering
                                        ?
                                            React.createElement(component_1.Loading, { label: 'searching ...' })
                                        :
                                            (_.isEmpty(MyServiceQuote.store.getState().result.meta) || MyServiceQuote.store.getState().result.meta.total_records == 0)
                                                ?
                                                    React.createElement("div", { className: 'mt-2' },
                                                        React.createElement(react_router_dom_1.Link, { to: pages_1.pages.service_quote_request.path, className: 'btn c-btn-1 c-btn-round mt-2' }, "Request First Quote"))
                                                :
                                                    this.renderData())))));
            }
            catch (err) {
                return null;
            }
        };
        return Page;
    }(React.Component));
    MyServiceQuote.Page = Page;
})(MyServiceQuote = exports.MyServiceQuote || (exports.MyServiceQuote = {}));
