"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var lib_1 = require("../../lib");
var Hero = (function (_super) {
    __extends(Hero, _super);
    function Hero(props) {
        return _super.call(this, props) || this;
    }
    Hero.prototype.componentDidMount = function () {
        var _this = this;
        this.unsubscribe = lib_1.Global.store.subscribe(function () { _this.forceUpdate(); });
        this.unSubTracking = lib_1.Statistic.store.subscribe(function () { _this.forceUpdate(); });
    };
    Hero.prototype.componentWillUnmount = function () {
        this.unsubscribe && this.unsubscribe();
        this.unSubTracking && this.unSubTracking();
    };
    Hero.prototype.render = function () {
        try {
            var uc = this.props.room != null ? lib_1.Statistic.store.getState().online_user : null;
            return (React.createElement("div", { className: 'c-hero shadow' },
                React.createElement("div", { className: 'container d-flex h-100' },
                    React.createElement("div", { className: 'c-hero-title align-self-center' }, this.props.title),
                    (uc && uc.room && uc.room[this.props.room] > 0)
                        &&
                            React.createElement("div", { className: 'ml-auto d-flex align-self-center align-items-center animated fadeIn c-hero-usercount' },
                                React.createElement("i", { className: 'fas fa-user-circle fa-lg mr-2' }),
                                React.createElement("span", null,
                                    uc.room[this.props.room].toLocaleString(),
                                    " viewing")))));
        }
        catch (err) {
            return null;
        }
    };
    return Hero;
}(React.Component));
exports.Hero = Hero;
