"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var request = require("superagent");
var _ = require("lodash");
var moment = require("moment");
var classNames = require("classnames");
var pages_1 = require("../../../routes/pages");
var lib_1 = require("../../lib");
var Nmvtis;
(function (Nmvtis) {
    var Modal = (function (_super) {
        __extends(Modal, _super);
        function Modal(props) {
            var _this = _super.call(this, props) || this;
            _this.state = _.assign({}, _this.props, {
                is_checking_history: false,
                is_agreement_checked: false,
                is_loading_report: false,
                is_report_ready: false,
                has_report: (_this.props.report_log.reports != null && _this.props.report_log.reports.length > 0)
            });
            return _this;
        }
        Modal.prototype.componentDidMount = function () {
            var _this = this;
            if (_.isEmpty(this.state.report_log.history_check) || (this.state.report_log.history_check.has_history == false && moment().diff(moment(this.state.report_log.history_check.date_check), 'hours')) > 1) {
                this.getReportLog();
            }
            if (this.state.has_report) {
                this.getReport();
            }
            $('#_nmvtis').modal('show').on('hidden.bs.modal', function (e) {
                lib_1.util.removeModal(_this);
            });
        };
        Modal.prototype.componentWillUnmount = function () {
        };
        Modal.prototype.getReportLog = function () {
            var _this = this;
            this.setState({ is_checking_history: true, has_error: false });
            lib_1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    request.get(lib_1.Global.cruzApiBaseUrl + '/nmvtis/report/log?vin=' + _this.state.vin + '&vehicle_id=' + _this.state.vehicle_id)
                        .set(lib_1.Auth.authHeader())
                        .timeout(10000)
                        .retry(2)
                        .end(function (err, apiRes) {
                        if (err) {
                            _this.setState({ is_checking_history: false, has_error: true });
                        }
                        else {
                            var rl = _.assign({}, apiRes.body.data);
                            var has_report = (rl.reports != null && rl.reports.length > 0);
                            _this.setState({ is_checking_history: false, report_log: rl, has_report: has_report });
                            if (has_report) {
                                _this.getReport();
                            }
                        }
                    });
                }
            });
        };
        Modal.prototype.renderDisclaimer = function () {
            return (React.createElement(React.Fragment, null,
                React.createElement("h3", { className: 'c-font-size-xl mb-3' }, "NMVTIS Disclaimer"),
                React.createElement("div", { className: 'nmvtis-disclaimer pr-3' },
                    React.createElement("p", null,
                        "The National Motor Vehicle Title Information System (NMVTIS) is an electronic system that contains information on certain automobiles titled in the United States. NMVTIS is intended to serve as a reliable source of title and ",
                        React.createElement("a", { href: 'https://www.vehiclehistory.gov/nmvtis_glossary.html', target: '_blank', rel: 'noopener noreferrer', className: 'c-btn-link' }, "brand"),
                        " history for automobiles, but it does not contain detailed information regarding a vehicle\u2019s repair history."),
                    React.createElement("p", null,
                        "All states, insurance companies, and junk and salvage yards are required by federal law to regularly report information to NMVTIS. However, NMVTIS does not contain information on all motor vehicles in the United States because ",
                        React.createElement("a", { href: 'https://www.vehiclehistory.gov/nmvtis_states.html', target: '_blank', rel: 'noopener noreferrer', className: 'c-btn-link' }, "some states"),
                        " are not yet providing their vehicle data to the system. Currently, the data provided to NMVTIS by states is provided in a variety of time frames; while some states report and update NMVTIS data in \"real-time\" (as title transactions occur), other states send updates less frequently, such as once every 24 hours or within a period of days."),
                    React.createElement("p", null, "Information on previous, significant vehicle damage may not be included in the system if the vehicle was never determined by an insurance company (or other appropriate entity) to be a \"total loss\" or branded by a state titling agency. Conversely, an insurance carrier may be required to report a \"total loss\" even if the vehicle's titling-state has not determined the vehicle to be \"salvage\" or \"junk\"."),
                    React.createElement("p", null,
                        "A vehicle history report is NOT a substitute for an independent vehicle inspection. Before making a decision to purchase a vehicle, consumers are strongly encouraged to also obtain an independent vehicle inspection to ensure the vehicle does not have hidden damage. ",
                        React.createElement("a", { href: "https://www.vehiclehistory.gov/nmvtis_vehiclehistory.html", target: '_blank', rel: 'noopener noreferrer', className: 'c-btn-link' }, "The Approved NMVTIS Data Providers"),
                        " can include vehicle condition data from sources other than NMVTIS."),
                    React.createElement("p", null, "NMVTIS data INCLUDES (as available by those entities required to report to the System):"),
                    React.createElement("ul", null,
                        React.createElement("li", null,
                            "Information from ",
                            React.createElement("a", { href: 'https://www.vehiclehistory.gov/nmvtis_states.html', target: '_blank', rel: 'noopener noreferrer', className: 'c-btn-link' }, "participating state"),
                            " motor vehicle titling agencies."),
                        React.createElement("li", null, "Information on automobiles, buses, trucks, motorcycles, recreational vehicles, motor homes, and tractors. NMVTIS may not currently include commercial vehicles if those vehicles are not included in a state's primary database for title records (in some states, those vehicles are managed by a separate state agency), although these records may be added at a later time."),
                        React.createElement("li", null, "Information on \"brands\" applied to vehicles provided by participating state motor vehicle titling agencies. Brand types and definitions vary by state, but may provide useful information about the condition or prior use of the vehicle."),
                        React.createElement("li", null, "Most recent odometer reading in the state's title record."),
                        React.createElement("li", null, "Information from insurance companies, and auto recyclers, including junk and salvage yards, that is required by law to be reported to the system, beginning March 31, 2009. This information will include if the vehicle was determined to be a \"total loss\" by an insurance carrier."),
                        React.createElement("li", null, "Information from junk and salvage yards receiving a \"cash for clunker\" vehicle traded-in under the Consumer Assistance to Recycle and Save Act of 2009 (CARS) Program.")),
                    React.createElement("p", null,
                        "Consumers are advised to visit ",
                        React.createElement("a", { href: 'https://www.vehiclehistory.gov', target: '_blank', rel: 'noopener noreferrer', className: 'c-btn-link' }, "www.vehiclehistory.gov"),
                        " for details on how to interpret the information in the system and understand the meaning of various labels applied to vehicles by the participating state motor vehicle titling agencies."))));
        };
        Modal.prototype.getReport = function () {
            var _this = this;
            this.setState(_.assign({}, this.state, { is_loading_report: true, is_agreement_checked: true, has_error: false }));
            lib_1.Auth.isAuthed(function () {
                request.get(lib_1.Global.cruzApiBaseUrl + '/nmvtis/report?vin=' + _this.state.vin + '&vehicle_id=' + _this.state.vehicle_id)
                    .set(lib_1.Auth.authHeader())
                    .timeout(20000)
                    .responseType('blob')
                    .end(function (err, apiRes) {
                    if (err) {
                        _this.setState(_.assign({}, _this.state, { is_loading_report: false, has_error: true }));
                    }
                    else {
                        _this.setState(_.assign({}, _this.state, { is_loading_report: false, is_report_ready: true }), function () {
                            var blob = new Blob([apiRes.body]);
                            var link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob);
                            link.download = _this.state.report_name;
                            link.click();
                        });
                    }
                });
            });
        };
        Modal.prototype.render = function () {
            var _this = this;
            try {
                return (React.createElement("div", { className: 'modal', id: '_nmvtis', tabIndex: -1, role: 'dialog', "data-backdrop": 'static', "data-keyboard": false },
                    React.createElement("div", { className: 'modal-dialog modal-md', role: 'document' },
                        React.createElement("div", { className: 'modal-content' },
                            React.createElement("div", { className: 'modal-header' },
                                React.createElement("h2", { className: 'modal-title' }, "Free NMVTIS History Report"),
                                React.createElement("button", { type: 'button', "data-dismiss": 'modal', className: 'close' })),
                            React.createElement("div", { className: 'modal-body' },
                                (this.state.is_checking_history) &&
                                    React.createElement("div", { className: 'nmvtis-text-status' },
                                        React.createElement("i", { className: 'far fa-spinner fa-pulse fa-3x' }),
                                        React.createElement("div", { className: 'mt-2' }, "Checking VIN from NMVTIS (National Motor Vehicle Title Information System) ...")),
                                (!this.state.is_checking_history && !this.state.has_error && (this.state.report_log.history_check == null || !this.state.report_log.history_check.has_history)) &&
                                    React.createElement("div", null,
                                        React.createElement("p", null, "No history data was found from NMVTIS (National Motor Vehicle Title Information System)."),
                                        React.createElement("p", null, "Reason(s):"),
                                        React.createElement("ul", null,
                                            React.createElement("li", null,
                                                "NMVTIS data only come from ",
                                                React.createElement("a", { href: "https://www.vehiclehistory.gov/nmvtis_states.html", target: '_blank', rel: 'noopener noreferrer', className: 'c-btn-link' }, "participating state"),
                                                " motor vehicle titling agencies."),
                                            React.createElement("li", null, "New vehicle may not have been reported to NMVTIS at time of request."))),
                                (!_.isEmpty(this.state.report_log.history_check) && this.state.report_log.history_check.has_history == true && this.state.has_report == false && this.state.is_agreement_checked == false) &&
                                    this.renderDisclaimer(),
                                (this.state.is_loading_report) &&
                                    React.createElement("div", { className: 'nmvtis-text-status' },
                                        React.createElement("i", { className: 'far fa-spinner fa-pulse fa-3x' }),
                                        React.createElement("div", { className: 'mt-2' }, "Loading report... this should only take a few seconds.")),
                                (this.state.is_report_ready) &&
                                    React.createElement("div", { className: 'nmvtis-text-status' },
                                        React.createElement("i", { className: 'far fa-file-pdf fa-3x' }),
                                        React.createElement("div", { className: 'mt-2' }, "Your free NMVTIS report is ready!")),
                                (this.state.has_error) &&
                                    React.createElement("div", { className: 'nmvtis-text-status nmvtis-text-status-error' },
                                        React.createElement("i", { className: 'far fa-exclamation-triangle fa-3x' }),
                                        React.createElement("div", { className: 'mt-2' }, "Oops, Something is wrong! Please try again later."))),
                            React.createElement("div", { className: 'modal-footer' },
                                React.createElement("button", { type: 'button', className: 'btn c-btn-2', "data-dismiss": 'modal' }, "Close"),
                                (this.state.report_log.history_check && this.state.report_log.history_check.has_history && !this.state.has_report && !this.state.is_agreement_checked) &&
                                    React.createElement("button", { type: 'button', className: 'btn c-btn-1 ml-2', onClick: function (e) { return _this.getReport(); } }, "I Understand, Get Report"),
                                (this.state.has_error) &&
                                    React.createElement("button", { type: 'button', className: 'btn c-btn-1 ml-2', onClick: function (e) { return _this.getReportLog(); } }, "Retry"))))));
            }
            catch (err) {
                lib_1.util.logError(err, null);
                return null;
            }
        };
        return Modal;
    }(React.Component));
    Nmvtis.Modal = Modal;
    var GetReport = (function (_super) {
        __extends(GetReport, _super);
        function GetReport(props) {
            var _this = _super.call(this, props) || this;
            _this.state = _.assign({}, _this.props, (_this.props.report_log == null
                &&
                    {
                        report_log: {
                            history_check: null,
                            reports: null
                        },
                    }));
            return _this;
        }
        GetReport.prototype.componentWillReceiveProps = function (nextProps) {
            this.setState(_.assign({}, nextProps, (this.props.report_log == null &&
                {
                    report_log: {
                        history_check: null,
                        reports: null
                    },
                })));
        };
        GetReport.prototype.showModal = function () {
            lib_1.util.setModal({ _modal: 'nmvtis' }, false, true, React.createElement(Modal, __assign({}, this.state)));
        };
        GetReport.prototype.renderWithoutVIN = function () {
            return (React.createElement("div", null,
                React.createElement(react_router_dom_1.Link, { to: pages_1.pages.vehicle_add_edit.path + '?_id=' + this.props.vehicle_id, className: 'c-btn-link' }, "Enter your vehicle's VIN (Vehicle Identity Number)"),
                " to get a Free NMVTIS (National Motor Vehicle Title Information System) History Report.",
                React.createElement("div", { className: 'mt-3' },
                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.vehicle_add_edit.path + '?_id=' + this.props.vehicle_id, className: 'btn c-btn-1 c-btn-xs c-btn-round' },
                        React.createElement("i", { className: 'fal fa-pen' }),
                        React.createElement("span", null, "Enter VIN")))));
        };
        GetReport.prototype.renderWithVIN = function () {
            var _this = this;
            try {
                var user = lib_1.Global.store.getState().user;
                return ((_.isEmpty(user) || user._id == null || user._id == '')
                    ?
                        React.createElement("div", null,
                            "Please ",
                            React.createElement(react_router_dom_1.Link, { to: pages_1.pages.signin.path + '?returnUrl=' + this.props.url }, "sign in"),
                            " to get the Free NMVTIS (National Motor Vehicle Title Information System) History Report. Don't have an account? ",
                            React.createElement(react_router_dom_1.Link, { to: pages_1.pages.register.path }, "Create a Free Account"),
                            ".")
                    :
                        React.createElement("div", null,
                            React.createElement("p", null, "Click the button below to get the Free NMVTIS (National Motor Vehicle Title Information System) History Report."),
                            React.createElement("button", { type: 'button', className: 'btn c-btn-1 c-btn-xs c-btn-round', onClick: function (e) { return _this.showModal(); } }, "Get Free History Report")));
            }
            catch (err) {
                lib_1.util.logError(err, null);
                return null;
            }
        };
        GetReport.prototype.renderNmvtisLogo = function () {
            return (React.createElement("div", { className: 'text-center' },
                React.createElement("a", { href: 'https://www.vehiclehistory.gov/', target: '_blank' },
                    React.createElement("img", { src: '/img/logo/nmvtis.png', className: 'img-fluid rounded logo-nmvtis p-3' })),
                React.createElement("div", { className: 'c-font-size-xs mt-2' },
                    "* Obtained from ",
                    React.createElement("a", { href: 'https://www.carsforsale.com', target: '_blank' }, "Carsforsale.com"),
                    ", an approved ",
                    React.createElement("a", { href: 'https://www.vehiclehistory.gov/', target: '_blank' }, "NMVTIS"),
                    " data provider")));
        };
        GetReport.prototype.toggle = function () {
            this.setState({ isCollapsed: !this.state.isCollapsed });
        };
        GetReport.prototype.render = function () {
            var _this = this;
            try {
                return ((this.state.allow_edit || _.size(this.state.vin) >= 17)
                    &&
                        React.createElement("section", { className: 'c-veh-section c-section-nmvtis' },
                            React.createElement("div", { className: 'd-flex justify-content-between align-items-center c-veh-section-bar', onClick: function () { return _this.toggle(); } },
                                React.createElement("h2", { className: 'c-veh-sub-title ml-3' }, "Free NMVTIS History Report"),
                                React.createElement("i", { className: classNames('fas c-font-size-lg c-font-color-1 mr-3', this.state.isCollapsed ? 'fa-plus' : 'fa-minus') })),
                            React.createElement("div", { className: classNames('ml-3 py-3', this.state.isCollapsed ? 'd-none' : 'd-flex') },
                                (this.state.allow_edit && _.size(this.state.vin) < 17)
                                    ?
                                        this.renderWithoutVIN()
                                    :
                                        this.renderWithVIN(),
                                React.createElement("div", { className: 'ml-auto d-none d-sm-block pl-4' }, this.renderNmvtisLogo()))));
            }
            catch (err) {
                lib_1.util.logError(err, null);
                return null;
            }
        };
        return GetReport;
    }(React.Component));
    Nmvtis.GetReport = GetReport;
})(Nmvtis = exports.Nmvtis || (exports.Nmvtis = {}));
