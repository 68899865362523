"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var _ = require("lodash");
var react_schemaorg_1 = require("react-schemaorg");
var iSchema = require("schema-dts");
var slugify_1 = require("slugify");
var Cruz = require("cruz");
var cfg = require("../../../cfg");
var __1 = require("..");
var component_1 = require("../../component");
var pages_1 = require("../../../routes/pages");
var Schema;
(function (Schema) {
    var webBaseUrl = cfg.webBaseUrl[cfg.env];
    function About() {
        try {
            var d = {
                '@context': 'https://schema.org',
                '@type': 'Organization',
                legalName: 'Cruz.com',
                url: 'https://www.cruz.com',
                logo: webBaseUrl + 'img/logo/cruz.png',
            };
            return React.createElement(react_schemaorg_1.JsonLd, { item: d });
        }
        catch (err) {
            return null;
        }
    }
    Schema.About = About;
    function VehicleBreadcrumbList(q, path) {
        var baseUrl = webBaseUrl + pages_1.pages.vehicle_search.path;
        try {
            var d = {
                '@context': 'https://schema.org',
                '@type': 'BreadcrumbList'
            };
            var items = [];
            items.push({
                '@type': 'ListItem',
                position: 1,
                item: {
                    '@type': 'Thing',
                    name: 'Vehicles',
                    url: baseUrl + '?' + __1.util.buildApiQueryString(_.pick(q, ['sort'])),
                }
            });
            var position = 1;
            if (q.condition != null) {
                position++;
                var condition = _.find(__1.Lookup.condition, { id: Number(q.condition) });
                if (!_.isEmpty(condition)) {
                    items.push({
                        '@type': 'ListItem',
                        position: position,
                        item: {
                            '@type': 'Thing',
                            name: condition.name,
                            url: baseUrl + '?' + __1.util.buildApiQueryString(_.pick(q, ['sort', 'condition'])),
                        }
                    });
                }
            }
            if (!_.isEmpty(q.make)) {
                position++;
                items.push({
                    '@type': 'ListItem',
                    position: position,
                    item: {
                        '@type': 'Thing',
                        name: q.make,
                        url: baseUrl + '?' + __1.util.buildApiQueryString(_.pick(q, ['sort', 'condition', 'make']))
                    }
                });
                if (!_.isEmpty(q.model)) {
                    position++;
                    items.push({
                        '@type': 'ListItem',
                        position: position,
                        item: {
                            '@type': 'Thing',
                            name: q.model,
                            url: baseUrl + '?' + __1.util.buildApiQueryString(_.pick(q, ['sort', 'condition', 'make', 'model']))
                        }
                    });
                }
            }
            else if (!_.isEmpty(q.body_type)) {
                position++;
                items.push({
                    '@type': 'ListItem',
                    position: position,
                    item: {
                        '@type': 'Thing',
                        name: _.join(q.body_type, ', '),
                        url: baseUrl + '?' + __1.util.buildApiQueryString(_.pick(q, ['sort', 'condition', 'body_type']))
                    }
                });
            }
            else if (!_.isEmpty(_.pick(q, 'sport', 'luxury', 'exotic'))) {
                position++;
                items.push({
                    '@type': 'ListItem',
                    position: position,
                    item: {
                        '@type': 'Thing',
                        name: _.chain(q).pick('sport', 'luxury', 'exotic').keys().first().startCase().valueOf(),
                        url: baseUrl + '?' + __1.util.buildApiQueryString(_.pick(q, ['sort', 'condition', 'sport', 'luxury', 'exotic']))
                    }
                });
            }
            if (!_.isEmpty(q.city) && !_.isEmpty(q.state_abbr)) {
                position++;
                items.push({
                    '@type': 'ListItem',
                    position: position,
                    item: {
                        '@type': 'Thing',
                        name: q.city + ', ' + q.state_abbr,
                        url: webBaseUrl + path
                    }
                });
            }
            d.itemListElement = items;
            return React.createElement(react_schemaorg_1.JsonLd, { item: d });
        }
        catch (err) {
            return null;
        }
    }
    Schema.VehicleBreadcrumbList = VehicleBreadcrumbList;
    function VehicleItem(v, is_detail) {
        try {
            var imageUrls = null;
            if (v.photo_list && _.size(v.photo_list) > 0) {
                imageUrls = _.map(v.photo_list, function (x) {
                    return cfg.photoBaseUrl[cfg.env].cdn + '/vehicle/' + v.group + '/' + v._id + '/' + x.id + '-md.jpg';
                });
            }
            else if (v.stock_photo_list && _.size(v.stock_photo_list) > 0) {
                imageUrls = _.map(v.stock_photo_list, function (x) {
                    return cfg.photoBaseUrl.stock + x;
                });
            }
            var detailUrl = webBaseUrl + component_1.Vehicle.detailUrl(v);
            var condition = null;
            if (v.condition) {
                var x = _.find(__1.Lookup.condition, { id: v.condition });
                if (x != null && x.schema) {
                    condition = x.schema;
                }
            }
            var d = {
                '@context': 'https://schema.org',
                '@type': 'Vehicle',
                name: component_1.Vehicle.detailTitle(v, false),
                image: imageUrls,
                description: v.description || component_1.Vehicle.detailDescription(v),
                url: detailUrl,
                itemCondition: condition,
                vehicleIdentificationNumber: _.get(v, 'vin'),
                brand: {
                    '@type': 'Corporation',
                    name: v.make,
                },
                mpn: _.get(v, 'vin') || 'N/A',
                model: v.model,
                modelDate: v.year.toString(),
                mileageFromOdometer: {
                    '@type': 'QuantitativeValue',
                    value: v.mileage,
                    unitText: 'mile',
                    unitCode: 'SMI'
                },
                bodyType: _.without(v.category_list, 'Sport', 'Luxury', 'Exotic'),
                aggregateRating: {
                    '@type': 'AggregateRating',
                    reviewCount: 0,
                    ratingCount: 0
                }
            };
            var addProps_1 = [];
            if (!_.isEmpty(v.stock_number)) {
                d.sku = v.stock_number;
            }
            else if (!_.isEmpty(v.vin)) {
                d.sku = v.vin;
            }
            else {
                d.sku = 'N/A';
            }
            var category = _.pick(v.category_list, 'Sport', 'Luxury', 'Exotic');
            if (_.size(category) > 0) {
                d.category = category;
            }
            if (!_.isEmpty(v.exterior_color)) {
                d.color = _.without([_.get(v.exterior_color, 'color_group'), _.get(v.exterior_color, 'mfr_color_name')], null);
            }
            if (v.style != null) {
                d.vehicleConfiguration = v.style;
            }
            if (!_.isEmpty(v.engine)) {
                d.vehicleEngine = {
                    '@type': 'EngineSpecification',
                };
                var eng = v.engine;
                if (eng.displacement != null) {
                    d.vehicleEngine.engineDisplacement = {
                        '@type': 'QuantitativeValue',
                        value: eng.displacement,
                        unitCode: 'LTR'
                    };
                }
                if (eng.fuel_type_name != null) {
                    d.vehicleEngine.fuelType = eng.fuel_type_name;
                }
                if (eng.max_hp != null && eng.max_hp > 0) {
                    d.vehicleEngine.enginePower = {
                        '@type': 'QuantitativeValue',
                        value: eng.max_hp,
                        unitCode: 'N12'
                    };
                }
                if (eng.max_torque != null && eng.max_torque > 0) {
                    d.vehicleEngine.torque = {
                        '@type': 'QuantitativeValue',
                        value: eng.max_torque,
                        unitCode: 'F17'
                    };
                }
                if (eng.aspiration_name != null) {
                    d.vehicleEngine.engineType = eng.aspiration_name;
                }
                if (eng.name != null) {
                    d.vehicleEngine.description = eng.name;
                }
            }
            if (!_.isEmpty(v.transmission) && v.transmission.trans_gears != null && v.transmission.trans_gears > 0) {
                d.numberOfForwardGears = v.transmission.trans_gears;
            }
            if (v.drive_type) {
                switch (v.drive_type) {
                    case 'AWD':
                        d.driveWheelConfiguration = iSchema.DriveWheelConfigurationValue.AllWheelDriveConfiguration;
                        break;
                    case 'FWD':
                        d.driveWheelConfiguration = iSchema.DriveWheelConfigurationValue.FrontWheelDriveConfiguration;
                        break;
                    case 'RWD':
                        d.driveWheelConfiguration = iSchema.DriveWheelConfigurationValue.RearWheelDriveConfiguration;
                        break;
                    case '4X4':
                        d.driveWheelConfiguration = iSchema.DriveWheelConfigurationValue.FourWheelDriveConfiguration;
                        break;
                    default:
                        d.driveWheelConfiguration = v.drive_type_name;
                }
            }
            if (!_.isEmpty(v.mpg_list)) {
                d.fuelEfficiency = [
                    {
                        '@type': 'QuantitativeValue',
                        value: _.max(_.map(v.mpg_list, 'city')),
                        unitText: 'mpg (city)'
                    },
                    {
                        '@type': 'QuantitativeValue',
                        value: _.max(_.map(v.mpg_list, 'highway')),
                        unitText: 'mpg (highway)'
                    }
                ];
            }
            var hasOffer = (v.sale_status_id == Cruz.Lookup.Vehicle.SaleStatusEnum.ForSale
                || v.sale_status_id == Cruz.Lookup.Vehicle.SaleStatusEnum.OpenToOffers
                || v.sale_status_id == Cruz.Lookup.Vehicle.SaleStatusEnum.Sold);
            d.offers = {
                '@type': 'Offer',
                price: hasOffer ? v.price : 'N/A',
                priceCurrency: 'USD',
                url: detailUrl
            };
            var loc = v.location;
            if (v.vendor_id != null) {
                d.offers.seller = {
                    '@type': 'Organization',
                    legalName: component_1.Vehicle.getDisplayName(v),
                    location: {
                        '@type': 'PostalAddress',
                        addressLocality: loc.city,
                        addressRegion: loc.state_abbr,
                        postalCode: loc.zip_code,
                        streetAddress: loc.address
                    },
                };
            }
            else {
                d.offers.seller = {
                    '@type': 'Person',
                    name: component_1.Vehicle.getDisplayName(v),
                    address: {
                        '@type': 'PostalAddress',
                        addressLocality: loc.city,
                        addressRegion: loc.state_abbr
                    }
                };
            }
            if (v.sale_status_id == Cruz.Lookup.Vehicle.SaleStatusEnum.Sold) {
                d.offers.availability = iSchema.ItemAvailability.SoldOut;
            }
            else if (hasOffer) {
                d.offers.availability = iSchema.ItemAvailability.InStock;
            }
            if (is_detail) {
                if (!_.isEmpty(v.interior_color)) {
                    d.vehicleInteriorColor = _.without([_.get(v.exterior_color, 'color_group'), _.get(v.exterior_color, 'mfr_color_name')], null);
                }
                if (v.doors != null && v.doors > 0) {
                    d.numberOfDoors = v.doors;
                }
                if (v.tonnage != null && v.tonnage != '') {
                    d.tongueWeight = {
                        '@type': 'QuantitativeValue',
                        value: v.tonnage,
                        unitCode: 'TNE'
                    };
                }
                if (!_.isEmpty(v.green_score_list)) {
                    d.meetsEmissionStandard = _.map(v.green_score_list, function (x) { return x.emissions_standard; });
                }
                if (!_.isEmpty(v.specification)) {
                    var spec = v.specification;
                    if (spec.acceleration != null && spec.acceleration > 1) {
                        d.accelerationTime = {
                            '@type': 'QuantitativeValue',
                            value: spec.acceleration,
                            unitCode: 'SEC',
                            name: 'seconds/0..60 mph'
                        };
                    }
                    if (spec.max_seating != null && spec.max_seating > 0) {
                        d.seatingCapacity = spec.max_seating;
                    }
                    if (spec.wheelbase != null && spec.wheelbase > 0) {
                        d.wheelbase = {
                            '@type': 'QuantitativeValue',
                            value: spec.wheelbase,
                            unitCode: 'INH'
                        };
                    }
                    if (spec != null && spec.curb_weight > 0) {
                        d.weight = {
                            '@type': 'QuantitativeValue',
                            value: spec.curb_weight,
                            unitCode: 'LBR'
                        };
                    }
                    if (spec.tank1_gal != null && spec.tank1_gal > 0) {
                        var fuelTankGal = spec.tank1_gal;
                        if (spec.tank2_gal != null && spec.tank2_gal > 0) {
                            fuelTankGal += spec.tank2_gal;
                        }
                        d.fuelCapacity = {
                            '@type': 'QuantitativeValue',
                            value: fuelTankGal,
                            unitCode: 'GLL'
                        };
                    }
                    if (spec.cargo_volume != null && spec.cargo_volume > 0) {
                        d.cargoVolume = {
                            '@type': 'QuantitativeValue',
                            value: spec.cargo_volume,
                            unitCode: 'FTQ'
                        };
                    }
                    if (spec.towing_capacity != null && spec.towing_capacity > 0) {
                        d.trailerWeight = {
                            '@type': 'QuantitativeValue',
                            value: spec.towing_capacity,
                            unitCode: 'LBR'
                        };
                    }
                    if (spec.max_payload != null && spec.max_payload > 0) {
                        d.payload = {
                            '@type': 'QuantitativeValue',
                            value: spec.max_payload,
                            unitCode: 'LBR'
                        };
                    }
                    if (spec.height != null && spec.height > 0) {
                        d.height = {
                            '@type': 'QuantitativeValue',
                            value: spec.height,
                            unitCode: 'INH'
                        };
                    }
                    if (spec.width != null && spec.width > 0) {
                        d.width = {
                            '@type': 'QuantitativeValue',
                            value: spec.width,
                            unitCode: 'INH'
                        };
                    }
                    if (spec.seating_rows != null && spec.seating_rows > 0) {
                        addProps_1.push({
                            '@type': 'PropertyValue',
                            name: 'Seating Rows',
                            value: spec.seating_rows
                        });
                    }
                }
                if (!_.isEmpty(v.plant)) {
                    addProps_1.push({
                        '@type': 'PropertyValue',
                        name: 'Assembled at',
                        value: v.plant
                    });
                }
                if (_.get(v, 'crash_test.overall_stars')) {
                    addProps_1.push({
                        '@type': 'PropertyValue',
                        name: 'NHTSA Crash Test Ratings',
                        value: {
                            '@type': 'StructuredValue',
                            name: 'Overall',
                            value: v.crash_test.overall_stars
                        }
                    });
                }
                if (!_.isEmpty(v.award_list)) {
                    d.awards = _.map(v.award_list, function (x) { return x.award_name + ' by ' + x.source_name; });
                }
                if (!_.isEmpty(v.mfr_warranty_list) && v.condition == Cruz.Lookup.Vehicle.ConditionEnum.New) {
                    _.each(v.mfr_warranty_list, function (x) {
                        var y = x.warranty_months / 12;
                        addProps_1.push({
                            '@type': 'PropertyValue',
                            name: 'Factory Warranty',
                            value: {
                                '@type': 'StructuredValue',
                                name: x.warranty_name,
                                description: (x.warranty_months > 0 && y == Math.round(y) ? y + ' years/' : x.warranty_months + 'months/') + (x.warranty_miles > 0 ? x.warranty_miles + ' miles' : 'unlimited miles')
                            }
                        });
                    });
                }
                d.additionalProperty = addProps_1;
            }
            return React.createElement(react_schemaorg_1.JsonLd, { key: v._id, item: d });
        }
        catch (err) {
            __1.util.logError(err, null);
            return null;
        }
    }
    Schema.VehicleItem = VehicleItem;
    function SpottedItem(spotted) {
        try {
            var ud = _.get(spotted, 'user_display');
            if (_.isEmpty(ud)) {
                return null;
            }
            else {
                var d = {
                    '@context': 'https://schema.org',
                    '@type': 'Photograph',
                    name: spotted.title,
                    image: _.map(spotted.photo_list, function (p) {
                        return cfg.photoBaseUrl[cfg.env].cdn + '/spotted/' + spotted.group + '/' + spotted._id + '/' + p.id + '-xl.jpg';
                    }),
                    thumbnailUrl: _.map(spotted.photo_list, function (p) {
                        return cfg.photoBaseUrl[cfg.env].cdn + '/spotted/' + spotted.group + '/' + spotted._id + '/' + p.id + '-md.jpg';
                    }),
                    url: webBaseUrl + pages_1.pages.spotted_detail.path + '/' + slugify_1.default(spotted.location_name, { replacement: '-' }) + '/' + slugify_1.default(spotted.title, '-') + '/' + spotted._id,
                    author: {
                        '@type': 'Person',
                        name: ud.first_name + ' ' + ud.last_name,
                        address: {
                            '@type': 'PostalAddress',
                            addressLocality: ud.location.city,
                            addressRegion: ud.location.state_abbr
                        }
                    },
                    locationCreated: {
                        '@type': 'Place',
                        address: spotted.location_name
                    },
                    dateCreated: spotted.date_create,
                    commentCount: spotted.comment_count || 0,
                    text: spotted.content || ''
                };
                return React.createElement(react_schemaorg_1.JsonLd, { key: spotted._id, item: d });
            }
        }
        catch (err) {
            return null;
        }
    }
    Schema.SpottedItem = SpottedItem;
})(Schema = exports.Schema || (exports.Schema = {}));
