"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var request = require("superagent");
var _ = require("lodash");
var component_1 = require("../../component");
var cfg = require("../../../cfg");
var pages_1 = require("../../../routes/pages");
var lib_1 = require("../../lib");
var Subscription;
(function (Subscription) {
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                is_working: false,
                unsubscription_list: []
            };
            return _this;
        }
        Page.prototype.componentWillMount = function () {
            var _this = this;
            lib_1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    _this.setState({ unsubscription_list: _.get(lib_1.Global.store.getState().user, 'unsubscription_list') || [] });
                }
                else {
                    lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                }
            });
        };
        Page.prototype.componentDidMount = function () {
            document.title = lib_1.util.title(pages_1.pages.subscription.title);
        };
        Page.prototype.handleError = function (errMsg) {
            lib_1.util.setToastMessage('error', errMsg);
        };
        Page.prototype.checkChanged = function (tgt) {
            this.setState({
                unsubscription_list: tgt.checked ? _.without(this.state.unsubscription_list, tgt.id) : _.union(this.state.unsubscription_list, [tgt.id])
            });
        };
        Page.prototype.submit = function (e) {
            var _this = this;
            e.preventDefault();
            if (!this.state.is_working) {
                this.setState({ is_working: true }, function () {
                    lib_1.Auth.isAuthed(function (isAuthed) {
                        if (isAuthed) {
                            request.put(lib_1.Global.cruzApiBaseUrl + '/user/subscription')
                                .set(lib_1.Auth.authHeader())
                                .timeout(cfg.superagent.timeout.normal)
                                .send(_this.state.unsubscription_list)
                                .end(function (err, apiRes) {
                                _this.setState({ is_working: false });
                                if (err) {
                                    _this.handleError(_.get(err, cfg.apiErrorKey));
                                }
                                else {
                                    lib_1.Global.store.dispatch({ type: lib_1.Global.actions.setUser, data: { unsubscription_list: apiRes.body.data } });
                                }
                            });
                        }
                        else {
                            lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                        }
                    });
                });
            }
        };
        Page.prototype.render = function () {
            var _this = this;
            return (component_1.Layout.main(lib_1.Global.store.getState().user, null, React.createElement("div", { className: 'container c-container' },
                React.createElement("h1", { className: 'c-title mb-3' }, pages_1.pages.subscription.title),
                React.createElement("form", { id: 'formMySubscription', onSubmit: function (e) { return _this.submit(e); }, noValidate: true },
                    React.createElement("div", { className: 'card' },
                        React.createElement("div", { className: 'card-body' },
                            React.createElement("div", { className: 'row justify-content-center' },
                                React.createElement("ul", { className: 'col-md-7 col-lg-5 list-group list-group-flush' }, _.map(lib_1.Lookup.subscription_list, function (d, idx) {
                                    var is_checked = !_.includes(_this.state.unsubscription_list, d.key);
                                    return (React.createElement("li", { className: 'list-group-item px-0', key: idx },
                                        React.createElement("label", { className: 'custom-control custom-checkbox my-0' },
                                            React.createElement("input", { type: 'checkbox', className: 'custom-control-input', id: d.key, checked: is_checked, onChange: function (e) { return _this.checkChanged(e.target); } }),
                                            React.createElement("label", { htmlFor: d.key, className: 'custom-control-label' }, d.name))));
                                })))),
                        React.createElement("div", { className: 'card-footer text-right' },
                            React.createElement("button", { type: 'submit', className: 'btn c-btn-1' }, this.state.is_working ? React.createElement(component_1.BtnWorking, { value: 'Saving ...' }) : 'Save')))))));
        };
        return Page;
    }(React.Component));
    Subscription.Page = Page;
})(Subscription = exports.Subscription || (exports.Subscription = {}));
