"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var _ = require("lodash");
var request = require("superagent");
var Push = require('push.js');
var cfg = require("../../../cfg");
var lib_1 = require("../../lib");
var pages_1 = require("../../../routes/pages");
var component_1 = require("../../component");
var NotificationSettings;
(function (NotificationSettings) {
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                is_loading: true
            };
            return _this;
        }
        Page.prototype.componentWillMount = function () {
            lib_1.Auth.isAuthed(function (isAuthed) {
                if (!isAuthed) {
                    lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                }
            });
        };
        Page.prototype.componentDidMount = function () {
            var _this = this;
            document.title = lib_1.util.title(pages_1.pages.notification.title);
            lib_1.Global.saveUserSettings({
                my_cruz_path: pages_1.pages.notification.path
            });
            this.setState({
                show_notification_permission: lib_1.util.shouldShowRequestNotificationPermission()
            });
            request.get(lib_1.Global.cruzApiBaseUrl + '/lookup/notification-settings')
                .end(function (err, apiRes) {
                if (err) {
                    _this.handleError(err.detail);
                }
                else {
                    _this.setState({ notification_type_list: apiRes.body.data }, function () {
                        request.get(lib_1.Global.cruzApiBaseUrl + '/user/notification/settings')
                            .set(lib_1.Auth.authHeader())
                            .timeout(cfg.superagent.timeout.normal)
                            .end(function (err, apiRes) {
                            if (err) {
                                lib_1.util.handleError(lib_1.Request.getApiErrorCode(err));
                            }
                            else {
                                var d = _.assign({}, apiRes.body.data);
                                var defaultSettings = _.map(_.filter(_this.state.notification_type_list, function (nt) { return nt.default_on; }), 'type_id');
                                _this.setState({ is_loading: false, notification_settings: d.notification_settings || defaultSettings });
                            }
                        });
                    });
                }
            });
        };
        Page.prototype.handleError = function (errMsg) {
            this.setState({ is_loading: false, is_working: false });
            lib_1.util.setToastMessage('error', errMsg);
        };
        Page.prototype.settingChanged = function (tgt) {
            if (tgt.checked) {
                this.setState({
                    notification_settings: _.union(this.state.notification_settings, [Number(tgt.id)])
                });
            }
            else {
                this.setState({
                    notification_settings: _.without(this.state.notification_settings, Number(tgt.id))
                });
            }
        };
        Page.prototype.submit = function (e) {
            var _this = this;
            try {
                e.preventDefault();
                if (!this.state.is_working) {
                    this.setState({ is_working: true }, function () {
                        lib_1.Auth.isAuthed(function (isAuthed) {
                            if (isAuthed) {
                                request.put(lib_1.Global.cruzApiBaseUrl + '/user/notification/settings')
                                    .set(lib_1.Auth.authHeader())
                                    .timeout(cfg.superagent.timeout.normal)
                                    .send({ notification_settings: _this.state.notification_settings })
                                    .end(function (err, apiRes) {
                                    if (err) {
                                        _this.handleError(_.get(err, cfg.apiErrorKey));
                                    }
                                    else {
                                        var d = _.assign({}, apiRes.body.data);
                                        _this.setState({ is_working: false, notification_settings: d.notification_settings }, function () {
                                            lib_1.util.setToastMessage('success', 'Successfully Saved');
                                        });
                                    }
                                });
                            }
                            else {
                                lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                            }
                        });
                    });
                }
            }
            catch (err) {
            }
        };
        Page.prototype.requestNotificationPermission = function () {
            var _this = this;
            Push.Permission.request(function () {
                localStorage.setItem('notification_permission', 'true');
                _this.setState({ show_notification_permission: false });
            }, function () {
                localStorage.setItem('notification_permission', 'false');
                _this.setState({ show_notification_permission: false });
            });
        };
        Page.prototype.render = function () {
            var _this = this;
            return (component_1.Layout.main(lib_1.Global.store.getState().user, null, React.createElement("div", { className: 'container c-container' },
                React.createElement("h1", { className: 'c-title mb-3' }, pages_1.pages.notification_settings.title),
                this.state.is_loading
                    ?
                        React.createElement(component_1.Loading, null)
                    :
                        React.createElement("form", { id: 'formMyProfile', onSubmit: function (e) { return _this.submit(e); }, noValidate: true },
                            React.createElement("div", { className: 'card' },
                                React.createElement("div", { className: 'card-body' },
                                    React.createElement("div", { className: 'row justify-content-center' },
                                        React.createElement("ul", { className: 'col-md-7 col-lg-5 list-group list-group-flush' },
                                            (this.state.show_notification_permission)
                                                &&
                                                    React.createElement("li", { className: 'list-group-item px-0' },
                                                        React.createElement("button", { type: 'button', className: 'btn c-btn-1 c-btn-xs c-btn-round mb-2', onClick: function () { return _this.requestNotificationPermission(); } },
                                                            React.createElement("i", { className: 'fal fa-bell' }),
                                                            React.createElement("span", null, "Enable desktop notifications"))),
                                            _.map(this.state.notification_type_list, function (d) {
                                                var isChecked = _.indexOf(_this.state.notification_settings, d.type_id) > -1;
                                                return (React.createElement("li", { className: 'list-group-item px-0', key: d.type_id },
                                                    React.createElement("label", { className: 'custom-control custom-checkbox my-0' },
                                                        React.createElement("input", { type: 'checkbox', className: 'custom-control-input', id: d.type_id.toString(), checked: isChecked, onChange: function (e) { return _this.settingChanged(e.target); } }),
                                                        React.createElement("label", { htmlFor: d.type_id.toString(), className: 'custom-control-label' }, d.text))));
                                            })))),
                                React.createElement("div", { className: 'card-footer text-right' },
                                    React.createElement("button", { type: 'submit', className: 'btn c-btn-1' }, this.state.is_working ? React.createElement(component_1.BtnWorking, { value: 'Saving ...' }) : 'Save')))))));
        };
        return Page;
    }(React.Component));
    NotificationSettings.Page = Page;
})(NotificationSettings = exports.NotificationSettings || (exports.NotificationSettings = {}));
