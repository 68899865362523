"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var Cruz = require("cruz");
var Lookup;
(function (Lookup) {
    Lookup.condition = Cruz.Lookup.Vehicle.condition_list;
    Lookup.subscription_list = Cruz.Lookup.Other.subscription_list;
    Lookup.service_quote_status_list = Cruz.Lookup.Service.quote_status_list;
    Lookup.postStatus = Cruz.Lookup.Other.post_status_list;
    Lookup.saleStatus = Cruz.Lookup.Vehicle.sale_status_list;
    Lookup.saleStatusSearch = _.filter(Lookup.saleStatus, function (x) { return x.id > 0; });
    Lookup.units = Cruz.Lookup.Vehicle.units;
    Lookup.vehicle_spec_items = Cruz.Lookup.Vehicle.spec_items;
    Lookup.hd_spec_items = Cruz.Lookup.Vehicle.hd_spec_items;
    Lookup.vehicleSort = Cruz.Lookup.Vehicle.sort_list;
    var communitySortEnum;
    (function (communitySortEnum) {
        communitySortEnum[communitySortEnum["newest"] = 1] = "newest";
        communitySortEnum[communitySortEnum["oldest"] = 2] = "oldest";
        communitySortEnum[communitySortEnum["popular"] = 3] = "popular";
        communitySortEnum[communitySortEnum["distance"] = 4] = "distance";
    })(communitySortEnum = Lookup.communitySortEnum || (Lookup.communitySortEnum = {}));
    Lookup.communitySort = [
        {
            name: 'Newest First',
            value: communitySortEnum.newest
        },
        {
            name: 'Oldest First',
            value: communitySortEnum.oldest
        },
        {
            name: 'Popular',
            value: communitySortEnum.popular
        },
        {
            name: 'Distance',
            value: communitySortEnum.distance
        }
    ];
})(Lookup = exports.Lookup || (exports.Lookup = {}));
