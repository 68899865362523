"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var _ = require("lodash");
var moment = require("moment");
var classNames = require("classnames");
var Cruz = require("cruz");
var pages_1 = require("../../../routes/pages");
var cfg = require("../../../cfg");
var lib_1 = require("../../lib");
var component_1 = require("../../component");
var __1 = require("..");
var user_1 = require("../../user");
var VehicleDetail;
(function (VehicleDetail) {
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            var _this = _super.call(this, props) || this;
            _this.isUnmounted = false;
            _this.trackViewTimeout = null;
            _this.state = {
                vehicle: null,
                is_loading: false,
                is_working: false
            };
            return _this;
        }
        Page.prototype.componentWillMount = function () {
            var _this = this;
            try {
                if (this.props.staticContext) {
                    var d = _.assign({}, this.props.staticContext);
                    this.setState({ vehicle: d.result, is_loading: false });
                }
                else {
                    var $initData = document.querySelector('#_init_' + pages_1.pages.vehicle_detail.id);
                    if ($initData && $initData.getAttribute('data-init')) {
                        $initData.remove();
                        this.setState({ vehicle: JSON.parse(decodeURIComponent($initData.getAttribute('data-init'))), is_loading: false }, function () {
                            _this.setPageTitle();
                            _this.trackView();
                        });
                    }
                    else {
                        this.setState({ is_loading: true }, function () {
                            lib_1.Request.vehicle.detail(_this.props.match.params._id, null, function (err, apiRes) {
                                if (err) {
                                    lib_1.util.handleError(lib_1.Request.getApiErrorCode(err));
                                }
                                else {
                                    _this.setState({ vehicle: apiRes.body.data, is_loading: false });
                                }
                                _this.setPageTitle();
                                _this.trackView();
                            });
                        });
                    }
                }
            }
            catch (err) {
                lib_1.util.logError(err, null);
                lib_1.Global.history.goBack();
            }
        };
        Page.prototype.componentWillUpdate = function () {
            if (this.props.location.hash) {
                lib_1.util.scrollTo(this.props.location.hash);
            }
        };
        Page.prototype.componentWillUnmount = function () {
            this.isUnmounted = true;
            this.trackViewTimeout && clearTimeout(this.trackViewTimeout);
        };
        Page.prototype.setPageTitle = function () {
            var v = this.state.vehicle;
            if (!_.isEmpty(v)) {
                document.title = lib_1.util.title(component_1.Vehicle.detailTitle(v, true)) + ' | ' + (v.vin || v._id);
            }
        };
        Page.prototype.buildCommunityUrl = function (v) {
            var x = {
                make: v.make,
                model: v.model,
                year_min: v.year,
                year_max: v.year
            };
            return lib_1.util.buildUrl(pages_1.pages.discussion_search.path, x);
        };
        Page.prototype.trackView = function () {
            var _this = this;
            this.trackViewTimeout = setTimeout(function () {
                lib_1.Tracking.trackView({ name: 'vehicle', _id: _this.props.match.params._id }, function (err, result) {
                    if (!err && !_.isEmpty(result)) {
                        _this.updateDone(result, false);
                    }
                });
            }, 2000);
        };
        Page.prototype.openDeleteDialog = function () {
            var _this = this;
            lib_1.util.setModal(null, false, true, React.createElement(component_1.Delete.Dialog, { id: this.state.vehicle._id, onYes: function (e) { return _this.deleteYes(); } }));
        };
        Page.prototype.deleteYes = function () {
            var _this = this;
            this.setState({ is_working: true }, function () {
                lib_1.Request.vehicle.delete(_this.state.vehicle._id, function (err, apiRes) {
                    if (err) {
                        lib_1.util.handleError(lib_1.Request.getApiErrorCode(err));
                    }
                    else {
                        _this.updateDone(apiRes.body.data, true);
                    }
                });
            });
        };
        Page.prototype.undelete = function () {
            var _this = this;
            this.setState({ is_working: true }, function () {
                lib_1.Request.vehicle.undelete(_this.state.vehicle._id, function (err, apiRes) {
                    if (err) {
                        lib_1.util.handleError(lib_1.Request.getApiErrorCode(err));
                    }
                    else {
                        _this.updateDone(apiRes.body.data, true);
                    }
                });
            });
        };
        Page.prototype.updateDone = function (d, shouldRefreshStatistic) {
            if (shouldRefreshStatistic) {
                lib_1.Statistic.refreshStatistic();
            }
            this.updateExternalStores(d, true);
            if (!this.isUnmounted) {
                this.setState({ vehicle: _.assign({}, this.state.vehicle, d), is_working: false });
            }
        };
        Page.prototype.renderVoteButton = function () {
            var v = this.state.vehicle;
            return (React.createElement(component_1.Vote.LikeButton, { name: 'vehicle', _id: v._id, user_id: v.user_id, vote: v.vote, onVoteDone: this.voteDone.bind(this), className: 'btn c-btn-3 c-btn-xs' }));
        };
        Page.prototype.renderSaveButton = function () {
            var v = this.state.vehicle;
            if (_.get(v, 'user_id') != _.get(lib_1.Global.store.getState().user, '_id')) {
                return (React.createElement(component_1.Saved.SaveItemButton, { name: 'vehicle', _id: v._id, className: 'btn c-btn-3 c-btn-xs' }));
            }
            else {
                return null;
            }
        };
        Page.prototype.voteDone = function (d) {
            if (d) {
                this.setState({ vehicle: _.assign({}, this.state.vehicle, d) });
                this.updateExternalStores(d, false);
            }
        };
        Page.prototype.commentDone = function (c) {
            if (c != null) {
                __1.VehicleSearchControl.store && __1.VehicleSearchControl.store.dispatch({ type: 22, data: { _id: this.state.vehicle._id, comment_count: c } });
                user_1.MyVehicle.store && user_1.MyVehicle.store.dispatch({ type: 6, data: { _id: this.state.vehicle._id, comment_count: c } });
            }
        };
        Page.prototype.updateExternalStores = function (d, updateMyVehicle) {
            __1.VehicleSearchControl.store && __1.VehicleSearchControl.store.dispatch({ type: 22, data: d });
            if (updateMyVehicle && user_1.MyVehicle.store) {
                user_1.MyVehicle.store.dispatch({ type: 6, data: d });
            }
        };
        Page.prototype.renderVehicleHeader = function () {
            var _this = this;
            try {
                var v = this.state.vehicle;
                var my_user_id = !_.isEmpty(lib_1.Global.store.getState().user) ? lib_1.Global.store.getState().user._id : null;
                var isMine = (my_user_id && v.user_id && my_user_id == v.user_id);
                var isVendor = !_.isEmpty(v.vendor_id);
                var loc = v.location;
                return (React.createElement("div", { className: 'c-detail-heading border-bottom-0' },
                    (isMine && v.post_status_id == Cruz.Lookup.Other.PostStatusEnum.Delete) &&
                        React.createElement("div", { className: 'c-alert c-alert-deleted text-center mb-4' }, "This vehicle has been removed."),
                    React.createElement("div", { className: 'd-xl-flex align-items-end' },
                        React.createElement("div", { className: 'c-heading-links pb-1' },
                            React.createElement(react_router_dom_1.Link, { to: pages_1.pages.vehicle_search.path, className: 'c-btn-link' }, pages_1.pages.vehicle_search.title),
                            React.createElement("i", { className: 'fal fa-angle-right mx-2' }),
                            React.createElement(react_router_dom_1.Link, { to: lib_1.util.buildUrl(pages_1.pages.vehicle_search.path, _.pick(v, ['make'])), className: 'c-btn-link' }, v.make == '~' ? 'Custom' : v.make),
                            React.createElement("i", { className: 'fal fa-angle-right mx-2' }),
                            React.createElement(react_router_dom_1.Link, { to: lib_1.util.buildUrl(pages_1.pages.vehicle_search.path, _.pick(v, ['make', 'model'])), className: 'c-btn-link' }, v.model == '~' ? (v.custom.brand) : v.model),
                            React.createElement("i", { className: 'fal fa-angle-right mx-2' }),
                            React.createElement(react_router_dom_1.Link, { to: lib_1.util.buildUrl(pages_1.pages.vehicle_search.path, { make: v.make, model: v.model, year_min: v.year, year_max: v.year }), className: 'c-btn-link' }, v.year),
                            !_.isEmpty(v.trim) && React.createElement("i", { className: 'fal fa-angle-right mx-2' }),
                            !_.isEmpty(v.trim) &&
                                React.createElement(react_router_dom_1.Link, { to: lib_1.util.buildUrl(pages_1.pages.vehicle_search.path, { make: v.make, model: v.model, year_min: v.year, year_max: v.year, trim: v.trim }), className: 'c-btn-link' }, v.trim),
                            React.createElement("h1", { className: 'title mt-1' }, component_1.Vehicle.detailTitle(v, false)),
                            (!_.isEmpty(v.highlight)) &&
                                React.createElement("div", { className: 'mb-2 mt-1 c-veh-highlight' }, v.highlight),
                            React.createElement("div", { className: 'c-font-size-xs mt-1' },
                                !isMine &&
                                    React.createElement("div", { className: 'd-block d-md-inline-block' },
                                        React.createElement("i", { className: 'fal fa-map-marker fa-fw mr-2 ' }),
                                        loc &&
                                            React.createElement("span", null, loc.city + ', ' + loc.state + (isVendor ? ' ' + loc.zip_code : '')),
                                        (v.location.distance != null && v.location.distance > 0)
                                            &&
                                                React.createElement("span", { className: 'ml-1 c-font-size-xs' },
                                                    "(",
                                                    (v.location.distance > 10 ? '\u2248' + ' ' + v.location.distance.toLocaleString() : '< 10') + ' miles',
                                                    ")"),
                                        React.createElement("span", { className: 'separator d-none d-md-inline-block' })),
                                React.createElement("i", { className: 'fal fa-clock fa-fw mr-2' }),
                                React.createElement("span", null, 'Posted ' + moment(v.date_create).fromNow()),
                                (v.date_update && moment(v.date_update).fromNow() != moment(v.date_create).fromNow()) &&
                                    React.createElement("span", null,
                                        React.createElement("span", { className: 'separator' }),
                                        React.createElement("span", null, 'Updated ' + moment(v.date_update).fromNow())))),
                        React.createElement("div", { className: 'ml-xl-auto mt-3 mt-xl-0 pl-0 pl-xl-5 c-toolbar flex-wrap flex-md-nowrap' },
                            component_1.Vehicle.sameVehicleLink(v, null),
                            component_1.Stat.views(v.view_count),
                            this.renderVoteButton(),
                            this.renderSaveButton(),
                            React.createElement("button", { type: 'button', className: 'btn c-btn-3 c-btn-xs d-xl-none btn-goto-comments' },
                                React.createElement("span", { className: 'c-count-prepend c-font-weight-medium' }, v.comment_count.toLocaleString()),
                                React.createElement("span", { className: 'c-font-size-xxs' }, "Comments")),
                            (isMine && !v.is_disabled) &&
                                React.createElement("div", { className: 'd-flex flex-nowrap' },
                                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.vehicle_add_edit.path + '?_id=' + v._id, className: 'btn c-btn-1 c-btn-xs mr-2' },
                                        React.createElement("span", null, "Update")),
                                    v.post_status_id == Cruz.Lookup.Other.PostStatusEnum.Delete
                                        ?
                                            React.createElement("button", { type: 'button', className: 'btn c-btn-2 c-btn-xs', onClick: function (e) { return _this.undelete(); } },
                                                React.createElement("i", { className: 'far fa-undo' }),
                                                React.createElement("span", null, this.state.is_working ? React.createElement(component_1.BtnWorking, { value: 'Undeleting ...' }) : 'Undelete'))
                                        :
                                            React.createElement("button", { type: 'button', className: 'btn c-btn-2 c-btn-xs', onClick: function (e) { return _this.openDeleteDialog(); } }, this.state.is_working ? React.createElement(component_1.BtnWorking, { value: 'Deleting ...' }) : 'Delete'))))));
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.renderPhotos = function () {
            try {
                var v = this.state.vehicle;
                var my_user_id = !_.isEmpty(lib_1.Global.store.getState().user) ? lib_1.Global.store.getState().user._id : null;
                my_user_id = null;
                if (_.isEmpty(v.photo_list) && !_.isEmpty(my_user_id) && my_user_id == v.user_id) {
                    return (React.createElement("div", { className: 'c-veh-photo' },
                        React.createElement("div", { className: 'text-center p-4' },
                            React.createElement(react_router_dom_1.Link, { to: pages_1.pages.vehicle_add_edit.path + '?_id=' + v._id, className: 'btn c-btn-2 c-btn-xs c-btn-round' },
                                React.createElement("i", { className: 'fal fa-cloud-upload' }),
                                React.createElement("span", null, "Upload Photo(s)")))));
                }
                else if (!_.isEmpty(v.photo_list) || !_.isEmpty(v.stock_photo_list)) {
                    var color = _.get(v, 'exterior_color.mfr_color_name');
                    var color_group = _.get(v, 'exterior_color.color_group');
                    if (color != null) {
                        color = color + (color_group != null ? ' (' + color_group + ')' : '');
                    }
                    else {
                        color = color_group;
                    }
                    var photoTitle = _.join(_.without([component_1.Vehicle.detailTitle(v, true), color, v.drive_type_name], null), ', ');
                    return (React.createElement("div", { className: classNames({ 'border-top': _.isEmpty(v.photo_list) || _.isEmpty(v.stock_photo_list) }) },
                        !_.isEmpty(v.photo_list) && !_.isEmpty(v.stock_photo_list) &&
                            React.createElement("nav", { className: 'mb-1' },
                                React.createElement("div", { className: 'nav nav-tabs', id: 'nav-tab' },
                                    React.createElement("a", { className: 'nav-item nav-link active', "data-toggle": 'tab', href: '#nav-1' },
                                        "Photos (",
                                        v.photo_list.length,
                                        ")"),
                                    React.createElement("a", { className: 'nav-item nav-link', "data-toggle": 'tab', href: '#nav-2' },
                                        "Stock Photos (",
                                        v.stock_photo_list.length,
                                        ")"))),
                        React.createElement("div", { id: 'nav-tabContent', className: 'tab-content p-0' },
                            !_.isEmpty(v.photo_list) &&
                                React.createElement("div", { className: 'tab-pane fade show active c-veh-photo', id: 'nav-1' },
                                    component_1.PhotoView.riGallery(photoTitle, cfg.photoBaseUrl[cfg.env].cdn + '/vehicle/' + v.group + '/' + v._id + '/', v.photo_list, true),
                                    v.sale_status_id == Cruz.Lookup.Vehicle.SaleStatusEnum.Sold &&
                                        React.createElement("img", { src: '/img/icon/sold-tag.svg', className: 'img-fluid c-icon-sold' }),
                                    (v.condition != null && v.condition == Cruz.Lookup.Vehicle.ConditionEnum.Certified) &&
                                        React.createElement("img", { src: '/img/vehicle/cpo.png', className: 'img-fluid c-icon-cpo', title: 'Certified Pre-Owned' })),
                            !_.isEmpty(v.stock_photo_list) &&
                                React.createElement("div", { className: classNames('c-veh-photo', { 'tab-pane fade': !_.isEmpty(v.photo_list) }), id: 'nav-2' }, component_1.PhotoView.riStockPhotoGallery(photoTitle, v.stock_photo_list)))));
                }
            }
            catch (err) {
                lib_1.util.logError(err, null);
                return null;
            }
        };
        Page.prototype.renderServiceQuote = function () {
            return (React.createElement("div", { className: 'py-4 px-3 bg-white text-center' },
                React.createElement("div", { className: 'mb-3 c-font-size-l' }, "Do you need service(s) for your vehicle?"),
                React.createElement("a", { href: pages_1.pages.service_quote.path + '?vehicle_id=' + this.state.vehicle._id, className: 'btn c-btn-2-rev c-btn-round mx-auto' }, "Get Service Quotes")));
        };
        Page.prototype.renderVehicleDetail = function () {
            try {
                var v = this.state.vehicle;
                var my_user_id = _.get(lib_1.Global.store.getState(), 'user._id');
                var comment = this.props.staticContext ? _.assign({}, this.props.staticContext['comment']) : null;
                var isMine = (!_.isEmpty(my_user_id) && my_user_id == v.user_id);
                return (React.createElement("div", { className: 'flex-ch-100' },
                    !lib_1.util.isClient() &&
                        lib_1.Schema.VehicleItem(v, true),
                    this.renderVehicleHeader(),
                    React.createElement("div", { className: 'row no-gutters flex-xxl-nowrap flex-rh-100' },
                        React.createElement("div", { className: 'col-12 col-xl pb-4' },
                            this.renderPhotos(),
                            React.createElement("div", { className: 'row no-gutters c-veh-status align-items-stretch' },
                                React.createElement("div", { className: 'col-md-7 d-flex align-content-center justify-content-center' }, React.createElement(component_1.Vehicle.SaleStatus, { vehicle: this.state.vehicle, onUpdateDone: this.updateDone.bind(this) })),
                                React.createElement("div", { className: 'col-md-5 d-flex align-content-center justify-content-center' }, React.createElement(component_1.Vehicle.Mileage, { vehicle: this.state.vehicle, onUpdateDone: this.updateDone.bind(this) }))),
                            component_1.Vehicle.info(v),
                            component_1.Vehicle.tags(v.tag_list),
                            (!v.is_disabled && !v.is_hd && (isMine || (_.size(v.vin) >= 17))) &&
                                React.createElement(component_1.Nmvtis.GetReport, { vin: v.vin, vehicle_id: v._id, report_name: component_1.Vehicle.detailTitle(v, false) + '_' + v.vin + '.pdf', report_log: v.nmvtis_report_log, allow_edit: isMine, url: this.props.location.pathname }),
                            component_1.Vehicle.warranty(v.warranty, v.mfr_warranty_list, v.condition),
                            component_1.Vehicle.description(v.description),
                            component_1.Vehicle.installedOptionalFeatures(v.option_list),
                            component_1.Vehicle.specification(v.specification, v.is_hd),
                            (v.year != null && v.year < 2011) ? component_1.Vehicle.crashTestPre2011(v.crash_test_pre2011) : component_1.Vehicle.crashTest(v.crash_test),
                            component_1.Vehicle.award(v.award_list),
                            component_1.Vehicle.safetyEquipment(v.safety_equipment),
                            component_1.Vehicle.greenScores(v.green_score_list),
                            component_1.Vehicle.features(v.feature_category_list),
                            component_1.Vehicle.overview(v.year + ' ' + v.make + ' ' + v.model, v.overview),
                            React.createElement("div", { className: 'c-veh-section border-0 mt-2 mb-4' },
                                React.createElement("div", { className: 'c-toolbar flex-wrap flex-md-nowrap' },
                                    component_1.Stat.views(v.view_count),
                                    this.renderVoteButton(),
                                    this.renderSaveButton()))),
                        React.createElement("div", { className: 'col-12 c-col-right border-top' },
                            isMine &&
                                React.createElement("div", { className: 'border-bottom' },
                                    React.createElement(component_1.Trademark.InsuranceQuote, { vehicle_id: v._id })),
                            isMine &&
                                React.createElement("div", { className: 'border-bottom' }, this.renderServiceQuote()),
                            (!isMine && (v.sale_status_id == Cruz.Lookup.Vehicle.SaleStatusEnum.ForSale || v.sale_status == Cruz.Lookup.Vehicle.SaleStatusEnum.OpenToOffers)) &&
                                React.createElement("div", { className: 'border-bottom' },
                                    React.createElement(component_1.Trademark.FinanceQuote, { vehicle_id: v._id })),
                            React.createElement(component_1.Comment.Component, { parent_url: this.props.location.pathname, api_base_url: lib_1.Global.cruzApiBaseUrl + '/vehicle', discussion_id: v._id, query: { page_size: cfg.commentPageSize.vehicle }, result: comment, name: 'vehicle_comment', onCommentDone: this.commentDone.bind(this) })))));
            }
            catch (err) {
                lib_1.util.logError(err, null);
                lib_1.Global.history.push(pages_1.pages.error.path + '/500');
                return null;
            }
        };
        Page.prototype.render = function () {
            return (component_1.Layout.main((!_.isEmpty(this.state.vehicle) && this.state.vehicle.user_display), (!_.isEmpty(this.state.vehicle) && this.state.vehicle.vendor_display), React.createElement("div", { className: 'flex-rh-100 container c-detail-container c-veh-detail' }, this.state.is_loading || _.isEmpty(this.state.vehicle)
                ?
                    React.createElement(component_1.Loading, null)
                :
                    this.renderVehicleDetail())));
        };
        return Page;
    }(React.Component));
    VehicleDetail.Page = Page;
})(VehicleDetail = exports.VehicleDetail || (exports.VehicleDetail = {}));
