"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var _ = require("lodash");
var classNames = require("classnames");
var moment = require("moment");
var uri = require("urijs");
var imagesLoaded = require("imagesloaded");
var TextareaAutosize = require('react-textarea-autosize').default;
var react_dropzone_1 = require("react-dropzone");
var Cruz = require("cruz");
var cfg = require("../../../cfg");
var pages_1 = require("../../../routes/pages");
var lib_1 = require("../../lib");
var component_1 = require("../../component");
var Messaging;
(function (Messaging) {
    var msgHolder = '#cc-right-middle';
    function onMessageScroll() {
        setTimeout(function () {
            var $obj = $(msgHolder);
            var mbTimer = null;
            var mtTimer = null;
            if ($obj.length) {
                $obj.on('scroll', function () {
                    mtTimer && clearTimeout(mtTimer);
                    mtTimer = setTimeout(function () {
                        if ($obj.scrollTop() < 40 && lib_1.Chat.store.getState().is_loading_more_message == false) {
                            lib_1.Chat.store.dispatch({ type: 20 });
                        }
                    }, 20);
                    mbTimer && clearTimeout(mbTimer);
                    mbTimer = setTimeout(function () {
                        if (isMessageAtBottom()) {
                            lib_1.Chat.store.dispatch({ type: 19 });
                        }
                    }, 100);
                });
            }
        }, 10);
    }
    Messaging.onMessageScroll = onMessageScroll;
    function offMessageScroll() {
        $(msgHolder).off('scroll');
    }
    Messaging.offMessageScroll = offMessageScroll;
    function onMessageLoadMoreDone(scrollToId) {
        if (scrollToId) {
            imagesLoaded(msgHolder, function () {
                var $obj = $('#' + scrollToId);
                $(msgHolder).animate({ scrollTop: $obj.offset().top - $('#cc-message-container').height() - 48 }, 300);
            });
        }
    }
    Messaging.onMessageLoadMoreDone = onMessageLoadMoreDone;
    function scrollMessageToBottom(duration, delay) {
        setTimeout(function () {
            var $obj = $(msgHolder);
            if ($obj.length > 0) {
                $obj.animate({ scrollTop: $('#cc-message-container').height() + 400 }, duration || 0);
            }
        }, delay || 100);
    }
    Messaging.scrollMessageToBottom = scrollMessageToBottom;
    function isMessageAtBottom() {
        var $obj = $(msgHolder);
        if ($obj.length > 0) {
            return (Math.ceil($obj.innerHeight() + $obj.scrollTop() + 10) >= document.querySelector(msgHolder).scrollHeight);
        }
        else {
            return false;
        }
    }
    Messaging.isMessageAtBottom = isMessageAtBottom;
    function registerMetaImageLoad(id) {
        setTimeout(function () {
            imagesLoaded(id || msgHolder, function () {
                scrollMessageToBottom();
            });
        }, 100);
    }
    Messaging.registerMetaImageLoad = registerMetaImageLoad;
    if (typeof window != 'undefined' && window.document) {
        $(function () {
            $(document).on('focus', '#cc-chat-text', function () {
                if ($('.cc-left').is(':visible') == false) {
                    $('#c-mobile-footer').addClass('d-none');
                }
            });
            $(document).on('blur', '#cc-chat-text', function () {
                $('#c-mobile-footer').removeClass('d-none');
            });
        });
    }
    function setChatTextFocus() {
        setTimeout(function () {
            var $ct = $('#cc-chat-text');
            if ($ct.length > 0 && $ct.is(':visible')) {
                $ct.focus();
            }
        }, 100);
    }
    Messaging.setChatTextFocus = setChatTextFocus;
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                confirm_close_permission_request: false,
                message: '',
                file_list: [],
                screenWidth: 0,
                searchUser: '',
            };
            return _this;
        }
        Page.prototype.componentWillMount = function () {
            var _this = this;
            lib_1.Auth.isAuthed(function (isAuthed) {
                if (!isAuthed) {
                    lib_1.Auth.redirectSignin(_this.props.location.pathname);
                }
            });
        };
        Page.prototype.componentDidMount = function () {
            var _this = this;
            document.title = lib_1.util.title(pages_1.pages.messaging.title);
            this.unsubscribe = lib_1.Chat.store.subscribe(function () { _this.forceUpdate(); });
            this.unsubscribeGlobal = lib_1.Global.store.subscribe(function () { _this.forceUpdate(); });
            if (lib_1.Chat.store) {
                var chat_list = lib_1.Chat.store.getState().chat_list;
                if (chat_list && chat_list.length > 0) {
                    var qs_1 = new uri().search(true);
                    lib_1.Chat.store.dispatch({ type: 9, data: qs_1._id || null });
                }
                setChatTextFocus();
            }
            this.updateWindowWidth();
            window.addEventListener("resize", this.updateWindowWidth.bind(this));
        };
        Page.prototype.componentWillUnmount = function () {
            lib_1.Chat.leaveChatRoom();
            this.unsubscribe && this.unsubscribe();
            this.unsubscribeGlobal && this.unsubscribeGlobal();
            window.removeEventListener("resize", this.updateWindowWidth.bind(this));
        };
        Page.prototype.componentWillReceiveProps = function () {
            var qs = new uri().search(true);
            if (qs._id && qs._id != lib_1.Chat.store.getState().active_chat_id) {
                lib_1.Chat.store.dispatch({ type: 9, data: qs._id });
            }
        };
        Page.prototype.updateWindowWidth = function () {
            this.setState({ screenWidth: window.innerWidth });
        };
        Page.prototype.renderCreatingChat = function () {
            return (React.createElement("div", { className: 'socket-status d-flex justify-content-center' },
                React.createElement("div", { className: 'd-flex align-items-center' },
                    React.createElement("i", { className: 'far fa-spinner fa-pulse fa-2x mr-2' }),
                    "Starting new conversation ...")));
        };
        Page.prototype.download = function (download_url, file_name) {
            lib_1.util.setModal(null, false, true, React.createElement(component_1.Modal.DownloadFile, { download_url: download_url, file_name: file_name }));
        };
        Page.prototype.viewImage = function (image_url) {
            lib_1.util.setModal(null, false, true, React.createElement(component_1.Modal.ViewImageInChat, { download_url: image_url }));
        };
        Page.prototype.viewSharedFiles = function (chat) {
            lib_1.util.setModal(null, false, true, React.createElement(component_1.Modal.ViewSharedFiles, { chat: chat }), '#_sharedFiles');
        };
        Page.prototype.renderFileInChatList = function (msg) {
            var _this = this;
            try {
                var resizeableImageTypes = ['jpeg', 'jpg', 'png', 'tiff', 'webp'];
                var fileDetail = msg.file.name.split('.');
                var ext = fileDetail[fileDetail.length - 1];
                var isImageResized = resizeableImageTypes.includes(ext.toLowerCase());
                var isOriginalImage = (msg.file.mime_type && _.startsWith(msg.file.mime_type.toLowerCase(), 'image')) ? true : false;
                var isPdfFile = (ext.toLowerCase() === 'pdf') ? true : false;
                var absoluteUrl = "chat/" + lib_1.Chat.store.getState().active_chat_id + "/" + msg.id;
                var downloadUrl_1 = cfg.socketUrl[cfg.env] + "/chat/binary/" + uri.encode(absoluteUrl) + "/" + ext + "/" + msg.file.name + "/" + lib_1.Auth.authHeader().Authorization;
                return (React.createElement("div", { className: classNames('ml-auto', 'dropright') },
                    React.createElement("button", { className: classNames('b-wrap dropdown-toggle'), "data-toggle": 'dropdown' },
                        isImageResized && React.createElement("img", { key: msg.id, className: 'c-img-wrap', src: cfg.socketUrl[cfg.env] + "/chat/binary/" + uri.encode(absoluteUrl) + "-md/" + ext + "/" + msg.file.name + "/" + lib_1.Auth.authHeader().Authorization }),
                        !isImageResized && isOriginalImage && React.createElement("img", { className: 'c-img-wrap', src: downloadUrl_1 }),
                        !isImageResized && !isOriginalImage &&
                            React.createElement(React.Fragment, null,
                                React.createElement("i", { className: classNames('fal fa-2x mr-2', { 'fa-file-pdf': isPdfFile }, { 'fa-file-alt': !isPdfFile }) }),
                                React.createElement("div", { className: 'file-name' }, msg.file.name))),
                    React.createElement("div", { className: classNames('dropdown-menu', 'dropdown-menu-left') },
                        (_.startsWith(msg.file.mime_type.toLowerCase(), 'image') || isImageResized) &&
                            React.createElement("div", null,
                                React.createElement("button", { className: 'dropdown-item', onClick: function () { return _this.viewImage(downloadUrl_1); } },
                                    React.createElement("i", { className: 'far fa-expand fa-fw' }),
                                    React.createElement("span", null, "Enlarge")),
                                React.createElement("div", { className: 'dropdown-divider' })),
                        React.createElement("button", { className: 'dropdown-item', onClick: function () { return _this.download(downloadUrl_1, msg.file.name); } },
                            React.createElement("i", { className: 'fal fa-download fa-fw' }),
                            React.createElement("span", null, "Download")))));
            }
            catch (e) {
                return;
            }
        };
        Page.prototype.renderSocketStatus = function () {
            var s = lib_1.Global.store.getState().socket_status;
            return (_.includes([-1, -2, 1], s)
                &&
                    React.createElement("div", { className: 'socket-status d-flex justify-content-center' },
                        (s == -1 || s == -2)
                            &&
                                React.createElement("div", { className: 'd-flex align-items-center flex-column connect-error' },
                                    React.createElement("div", { className: 'mb-2' }, "Cannot connect! Please check your network and try again."),
                                    React.createElement("button", { type: 'button', className: 'btn c-btn-2 c-btn-xs c-btn-round', onClick: function () { return lib_1.CruzSocket.connect(); } }, "Try Again")),
                        (s == 1)
                            &&
                                React.createElement("div", { className: 'd-flex align-items-center' },
                                    React.createElement("i", { className: 'far fa-spinner fa-pulse fa-2x mr-2' }),
                                    "Connecting ...")));
        };
        Page.prototype.openDownloadModal = function (chat) {
            lib_1.util.setModal(null, false, true, React.createElement(component_1.Modal.DownloadChat, __assign({}, chat)));
        };
        Page.prototype.renderChatHeader = function () {
            var _this = this;
            var _chat = _.find(lib_1.Chat.store.getState().chat_list, { _id: lib_1.Chat.store.getState().active_chat_id });
            var showSharedFiles = _.find(_chat.message_list.map(function (message) {
                if (message.file && message.file.name) {
                    return true;
                }
            }));
            if (_chat) {
                var user = _.first(_chat.user_list.filter(function (user) { return user._id != lib_1.Global.store.getState().user._id; }));
                var file_name_1 = _.join(_.map(_chat.user_list.filter(function (user) { return user._id != lib_1.Global.store.getState().user._id; }), function (user) { return _.startCase(user.first_name) + _.startCase(user.last_name); }), '_') + '_' + _chat._id + '.html';
                return (React.createElement("div", { className: 'w-100 d-flex justify-content-between align-items-center' },
                    React.createElement("div", { className: 'cc-profile m-2 pl-2' }, component_1.User.renderUserDisplay(user, true)),
                    _chat.message_count > 0
                        &&
                            React.createElement("div", { className: 'dropdown c-ellipsis-drop mx-2 pr-2' },
                                React.createElement("div", { className: 'd-flex ' }, (this.state.screenWidth > 1400)
                                    ? React.createElement(React.Fragment, null,
                                        showSharedFiles &&
                                            React.createElement("button", { type: 'button', className: 'btn c-btn-3 c-btn-xs xs-hidden ', onClick: function () { _this.viewSharedFiles(_chat); } },
                                                React.createElement("i", { className: 'fal fa-paperclip' }),
                                                React.createElement("span", null, "Shared Files")),
                                        React.createElement("button", { type: 'button', className: 'btn c-btn-3 c-btn-xs ml-2', onClick: function () { _this.openDownloadModal({ _id: _chat._id, file_name: file_name_1 }); } },
                                            React.createElement("i", { className: 'fal fa-download fa-fw' }),
                                            React.createElement("span", null, "Download Chat")))
                                    : React.createElement("div", { className: 'dropdown c-ellipsis-drop mx-2 pr-2' },
                                        React.createElement("button", { className: 'dropdown-toggle', "data-toggle": 'dropdown', title: 'Options' },
                                            React.createElement("i", { className: 'far fa-ellipsis-v' })),
                                        React.createElement("div", { className: 'dropdown-menu dropdown-menu-right' },
                                            showSharedFiles &&
                                                React.createElement(React.Fragment, null,
                                                    React.createElement("button", { className: 'dropdown-item', onClick: function () { _this.viewSharedFiles(_chat); } },
                                                        React.createElement("i", { className: 'fal fa-paperclip fa-fw' }),
                                                        React.createElement("span", null, "Shared Files")),
                                                    React.createElement("div", { className: 'dropdown-divider' })),
                                            React.createElement("button", { className: 'dropdown-item', onClick: function () { _this.openDownloadModal({ _id: _chat._id, file_name: file_name_1 }); } },
                                                React.createElement("i", { className: 'fal fa-download fa-fw' }),
                                                React.createElement("span", null, "Download Chat"))))))));
            }
        };
        Page.prototype.openSelectPhoto = function (dropzone_ref) {
            var s = this.refs[dropzone_ref];
            s.open();
        };
        Page.prototype.messageInputKeydown = function (e) {
            try {
                if (e.keyCode == 13) {
                    e.preventDefault();
                    if (e.ctrlKey || e.shiftKey) {
                        this.setState({ message: this.state.message + '\n' });
                    }
                    else if (e.target.value) {
                        this.sendMessage(e);
                    }
                }
                else {
                    lib_1.Chat.typing();
                }
            }
            catch (err) {
            }
        };
        Page.prototype.messageChanged = function (value) {
            this.setState({ message: value });
        };
        Page.prototype.onDropAccepted = function (file_list) {
            var all_file_list = _.union(this.state.file_list, file_list);
            this.setState({ file_list: all_file_list });
        };
        Page.prototype.onDropRejected = function (rejected_list) {
            var file_too_big = _.find(rejected_list, function (file) { return file.size > 26214400; });
            if (file_too_big) {
                lib_1.util.setToastMessage('error', 'Selected file size is too big. Size limit is 25MB');
            }
            else {
                lib_1.util.setToastMessage('error', 'Invalid file type');
            }
        };
        Page.prototype.onUploadFileChanged = function (file_list) {
            this.setState({ file_list: file_list });
        };
        Page.prototype.sendMessage = function (e) {
            e && e.preventDefault();
            if (!_.isEmpty(this.state.message)) {
                lib_1.Chat.store.dispatch({ type: 15, data: this.state.message });
                this.setState({ message: '' });
            }
        };
        Page.prototype.renderInput = function () {
            var _this = this;
            var ss = lib_1.Chat.store.getState();
            var disableInput = (lib_1.Global.store.getState().socket_status != 2 || ss.chat_list == null || ss.active_chat_id == null);
            var api_url = cfg.socketUrl[cfg.env] + '/chat/' + lib_1.Chat.store.getState().active_chat_id + '/binary/' + _.uniqueId() + '/' + uri.encode(lib_1.CruzSocket.socket.id);
            return (React.createElement("form", { className: 'cc-form', onSubmit: function (e) { return _this.sendMessage(e); }, noValidate: true },
                (_.size(this.state.file_list) > 0)
                    &&
                        React.createElement(component_1.BinaryFile.BinaryUpload, { api_url: api_url, onUploadFileChanged: function (file_list) { return _this.onUploadFileChanged(file_list); }, upload: this.state.file_list }),
                React.createElement("div", { className: 'input-group' },
                    React.createElement("div", { className: 'c-file-upload ml-auto c-ellipsis-drop dropup' },
                        React.createElement("button", { className: 'dropdown-toggle', "data-toggle": 'dropdown' },
                            React.createElement("i", { className: 'fal fa-plus fa-lg' })),
                        React.createElement("div", { className: 'dropdown-menu dropdown-menu-right' },
                            React.createElement(react_dropzone_1.default, { ref: "photo_dropzone", multiple: true, disableClick: true, accept: 'image/jpeg, image/jpg, image/png, image/tiff, image/webp', disablePreview: false, className: 'dropzone mb-4 mb-lg-0', activeClassName: 'active', maxSize: 26214400, onDrop: function (file_list) { return _this.onDropAccepted(file_list); }, onDropRejected: function (rejected_list) { return _this.onDropRejected(rejected_list); } },
                                React.createElement("button", { className: 'dropdown-item', onClick: this.openSelectPhoto.bind(this, 'photo_dropzone') },
                                    React.createElement("i", { className: 'fal fa-file-image fa-lg fa-fw' }),
                                    React.createElement("span", null, "Photos"))),
                            React.createElement(react_dropzone_1.default, { ref: "file_dropzone", multiple: true, disableClick: true, disablePreview: false, className: 'dropzone mb-4 mb-lg-0', activeClassName: 'active', maxSize: 26214400, onDrop: function (file_list) { return _this.onDropAccepted(file_list); }, onDropRejected: function (rejected_list) { return _this.onDropRejected(rejected_list); } },
                                React.createElement("div", { className: 'dropdown-divider' }),
                                React.createElement("button", { className: 'dropdown-item', onClick: this.openSelectPhoto.bind(this, 'file_dropzone') },
                                    React.createElement("i", { className: 'fal fa-file fa-lg fa-fw' }),
                                    React.createElement("span", null, "Add Files"))))),
                    React.createElement(TextareaAutosize, { placeholder: disableInput ? 'Connecting ...' : 'Type a message', id: 'cc-chat-text', minRows: 1, maxRows: 6, onChange: function (e) { return _this.messageChanged(e.target.value); }, onKeyDown: function (e) { _this.messageInputKeydown(e); }, value: this.state.message, disabled: disableInput }),
                    React.createElement("div", { className: "d-none d-sm-flex align-items-end pl-3" },
                        React.createElement("button", { type: 'submit', className: 'btn c-btn-1 c-btn-xs c-btn-round', disabled: disableInput },
                            React.createElement("i", { className: 'fas fa-paper-plane' }),
                            React.createElement("span", null, "Send"))),
                    React.createElement("div", { className: 'd-sm-none d-flex align-items-end pl-3' },
                        React.createElement("button", { type: 'submit', className: 'btn cc-btn-send', disabled: disableInput },
                            React.createElement("i", { className: 'fas fa-paper-plane' }))))));
        };
        Page.prototype.renderTyping = function () {
            var typingList = lib_1.Chat.store.getState().typing_list;
            var user = null;
            if (typingList && typingList.length > 0) {
                var x = typingList.filter(function (tp) {
                    return tp.chat_id == lib_1.Chat.store.getState().active_chat_id && tp.user_id != lib_1.Global.store.getState().user._id;
                });
                if (x && x.length == 1) {
                    var _chat = _.find(lib_1.Chat.store.getState().chat_list, { _id: x[0].chat_id });
                    user = _.find(_chat.user_list, { _id: x[0].user_id });
                }
            }
            return (user
                &&
                    React.createElement("div", { className: 'cc-typing animated fadeIn' },
                        component_1.User.renderUserIcon(user),
                        React.createElement("div", { className: 'c-blink font-italic ml-3' },
                            user.first_name,
                            " is typing ",
                            React.createElement("span", null, "."),
                            React.createElement("span", null, "."),
                            React.createElement("span", null, "."))));
        };
        Page.prototype.renderEmptyMessageList = function (chat_id) {
            try {
                var chat = lib_1.Chat.getChatById(chat_id);
                var user = _.find(chat.user_list, function (user) { return user._id != lib_1.Global.store.getState().user._id; });
                return (React.createElement("div", { className: 'd-flex justify-content-center h-100 pt-4' },
                    React.createElement("div", { className: 'text-center align-self-center pb-4' },
                        React.createElement("p", { className: 'c-font-size-xxl' },
                            "Enter a message below to start a new conversation with ",
                            user.first_name + ' ' + user.last_name,
                            "."),
                        React.createElement("p", null,
                            "Don't want to message ",
                            user.first_name + ' ' + user.last_name,
                            "? No problem, just click the Remove button below."),
                        React.createElement("button", { type: 'button', className: 'btn c-btn-2 c-btn-s c-btn-round mt-3', onClick: function () { return lib_1.Chat.store.dispatch({ type: 23, data: chat_id }); } }, lib_1.Chat.store.getState().is_deleting_chat ? React.createElement(component_1.BtnWorking, { value: 'Removing ...' }) : 'Remove'))));
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.renderMessage = function (msg, user, showDateTime) {
            try {
                var me = lib_1.Global.store.getState().user;
                var alignRight = (me._id == user._id);
                var _dt = null;
                if (showDateTime) {
                    var dm = moment(msg.date_create);
                    var nm = moment();
                    if (dm.isSame(nm, 'year')) {
                        if (dm.isSame(nm, 'day')) {
                            _dt = dm.format('h:mm A');
                        }
                        else {
                            _dt = dm.format('M/D h:mm A');
                        }
                    }
                    else {
                        _dt = dm.format('M/D/YYYY h:mm A');
                    }
                }
                var isFile = (_.get(msg.file, 'name') != null);
                var msg_content = isFile ? this.renderFileInChatList(msg) : lib_1.util.text2html(msg.text.toString());
                return (React.createElement("div", { className: classNames('animated fadeIn d-flex flex-column', { 'mt-1': !showDateTime }, { 'mt-3': showDateTime }), key: msg.id, id: msg.id },
                    showDateTime
                        &&
                            React.createElement("div", { className: classNames('cc-message-from', alignRight ? 'cc-mr ml-auto' : 'cc-ml mr-auto') },
                                React.createElement("span", { className: 'mr-3' }, me._id == user._id ? 'You' : user.first_name + ' ' + user.last_name),
                                React.createElement("span", null, _dt)),
                    React.createElement("div", { className: classNames('cc-message-content', { 'cc-message-file': isFile }, alignRight ? 'cc-mr ml-auto' : 'cc-ml mr-auto') }, msg_content),
                    (msg.meta && (msg.meta.title || msg.meta.description)) &&
                        React.createElement("div", { className: classNames('card cc-meta-card', { 'ml-auto': alignRight }) },
                            React.createElement("a", { href: msg.meta.url, target: '_blank', rel: 'noopener noreferrer' },
                                React.createElement("div", { className: 'card-body d-flex flex-column flex-md-row' },
                                    React.createElement("div", { className: 'mb-2 mb-md-0 mr-0 mr-md-3' }, msg.meta.image && React.createElement("img", { className: 'card-image img-fluid', src: msg.meta.image })),
                                    React.createElement("div", null,
                                        msg.meta.title && React.createElement("div", { className: 'c-font-weight-medium' }, msg.meta.title),
                                        msg.meta.description && React.createElement("div", { className: 'c-font-size-s' }, msg.meta.description),
                                        msg.meta.date && React.createElement("div", { className: 'c-tex-xs' }, moment(msg.meta.date).format(cfg.format.date))))))));
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.renderMessageList = function () {
            var _this = this;
            try {
                var _chat_1 = lib_1.Chat.getActiveChat();
                var preMsg_1 = null;
                var loadedCount = _.size(_chat_1.message_list);
                return (React.createElement("div", { className: 'cc-message-list container', id: 'chat_' + _chat_1._id },
                    lib_1.Chat.store.getState().is_loading_more_message
                        &&
                            React.createElement("div", { className: 'py-1 d-flex justify-content-center' },
                                React.createElement(component_1.Loading, { label: 'loading more ...' })),
                    loadedCount >= cfg.chatMessageViewLimit
                        &&
                            React.createElement("div", { id: 'cc-message-limit', className: 'py-2 d-flex justify-content-center align-items-center d-column d-md-row' },
                                React.createElement("div", null,
                                    "Showing the last ",
                                    loadedCount.toLocaleString(),
                                    " of ",
                                    _chat_1.message_count.toLocaleString(),
                                    " messages."),
                                React.createElement("div", { className: 'd-flex align-items-center ml-md-2' },
                                    React.createElement("button", { type: 'button', className: 'btn c-btn-1 c-btn-xs c-btn-round mr-1', onClick: function () { } },
                                        React.createElement("i", { className: 'fal fa-download' }),
                                        React.createElement("span", null, "Download")),
                                    React.createElement("span", null, " all messages."))),
                    (_chat_1.message_count > cfg.apiQueryDefault.chat_message.page_size && _chat_1.message_count == loadedCount)
                        &&
                            React.createElement("div", { className: 'cc-message-break' },
                                React.createElement("span", { className: 'p-3' }, "Beginning")),
                    _.size(_chat_1.message_list) > 0
                        ?
                            React.createElement("div", { id: 'cc-message-container', className: 'pb-5 pt-1' }, _.map(_chat_1.message_list, function (msg, idx) {
                                var showDateTime = true;
                                if (preMsg_1 && preMsg_1.user_id == msg.user_id && moment(msg.date_create).isSame(preMsg_1.date_create, 'minute')) {
                                    showDateTime = false;
                                }
                                preMsg_1 = _.assign({}, msg);
                                return _this.renderMessage(msg, _.find(_chat_1.user_list, { _id: msg.user_id }), showDateTime);
                            }))
                        :
                            this.renderEmptyMessageList(_chat_1._id),
                    React.createElement("div", { className: 'pb-3' })));
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.renderChatItem = function (_chat) {
            try {
                var ud = _.find(_chat.user_list, function (user) {
                    return user._id != lib_1.Global.store.getState().user._id;
                });
                if (ud && this.userFilter(ud)) {
                    var me = _.find(_chat.user_list, { _id: lib_1.Global.store.getState().user._id });
                    var msg = _.last(_chat.message_list);
                    var dm = msg ? moment(msg.date_create) : null;
                    var hasAlert = (!_.isEmpty(me.chat_log) && me.chat_log.new_message_count > 0) || me.has_new_message == true;
                    return (React.createElement("li", { key: _chat._id, className: classNames('cc-chat-item', { 'active': _chat._id == lib_1.Chat.store.getState().active_chat_id }, { 'has-new-message': hasAlert }), onClick: function () { return lib_1.Chat.store.dispatch({ type: 9, data: _chat._id }); } },
                        React.createElement("div", { className: 'position-relative' },
                            (ud.photo_url && ud.photo_url != '')
                                ?
                                    React.createElement("img", { className: 'img-fluid rounded-circle avatar', src: cfg.photoBaseUrl[cfg.env].s3 + '/' + ud.photo_url })
                                :
                                    (!_.isEmpty(ud.initials))
                                        &&
                                            React.createElement("div", { className: 'avatar avatar-alt' }, ud.initials),
                            hasAlert && React.createElement("div", { className: 'cc-alert' }),
                            (ud.online_status == Cruz.Lookup.Socket.OnlineStatusEnum.Online) && React.createElement("div", { className: 'cc-online' })),
                        React.createElement("div", { className: 'description' },
                            React.createElement("div", { className: 'd-flex flex-row' },
                                React.createElement("div", { className: 'ellipsis name mr-auto' }, ud.first_name + ' ' + ud.last_name),
                                dm
                                    &&
                                        React.createElement("div", { className: 'd-flex align-items-end pl-3 c-font-size-xs' }, dm.isSame(moment(), 'day')
                                            ?
                                                dm.format('h:mm A')
                                            :
                                                moment().diff(dm, 'hours') < 24
                                                    ?
                                                        dm.format('M/D h:mm A')
                                                    :
                                                        dm.fromNow())),
                            !_.isEmpty(ud.location)
                                &&
                                    React.createElement("div", { className: 'c-font-size-xs ellipsis' }, ud.location.city + ', ' + ud.location.state),
                            msg
                                &&
                                    React.createElement("div", { className: 'c-font-size-xs mt-1 ellipsis' },
                                        msg.user_id == lib_1.Global.store.getState().user._id && 'You: ',
                                        msg.text ? lib_1.util.stripHtmlTags(msg.text) : 'File Attachment..'))));
                }
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.userFilter = function (user) {
            var name = user.first_name + ' ' + user.last_name;
            if (name.toLowerCase().includes(this.state.searchUser.toLowerCase()))
                return true;
            return false;
        };
        Page.prototype.isSearchFound = function (chats) {
            var _this = this;
            var userSearchFound = false;
            _.each(chats, function (chat) {
                var ud = _.find(chat.user_list, function (user) {
                    return user._id != lib_1.Global.store.getState().user._id;
                });
                if (ud && _this.userFilter(ud) && !userSearchFound) {
                    userSearchFound = true;
                }
            });
            return userSearchFound;
        };
        Page.prototype.renderChatList = function () {
            var _this = this;
            try {
                var _chat_list = lib_1.Chat.store.getState().chat_list;
                if (_chat_list) {
                    return (React.createElement("div", { className: 'w-100' },
                        React.createElement("div", null,
                            React.createElement("div", { className: classNames('form-group mb-0', { 'has-value': (this.state.searchUser && this.state.searchUser != '') }) },
                                React.createElement("div", { className: 'input-group search' },
                                    React.createElement("input", { type: 'text', className: 'form-control border-0', autoComplete: 'off', id: 'user', role: 'textbox', value: this.state.searchUser, onChange: function (e) { _this.setState(__assign(__assign({}, _this.state), { searchUser: e.target.value })); } }),
                                    React.createElement("label", { htmlFor: 'user', className: 'control-label' },
                                        React.createElement("i", { className: 'far fa-search mr-2' }),
                                        " Search User"),
                                    React.createElement("div", { className: 'input-group-append' }, (this.state.searchUser && this.state.searchUser != '') &&
                                        React.createElement("button", { className: 'btn c-btn-1 ', onClick: function () { _this.setState(__assign(__assign({}, _this.state), { searchUser: '' })); } },
                                            React.createElement("i", { className: 'far fa-times' })))))),
                        React.createElement("ul", { className: 'cc-chat-list list-unstyled' }, (this.isSearchFound(_chat_list))
                            ?
                                _.map(_chat_list, function (_chat) {
                                    return _this.renderChatItem(_chat);
                                })
                            :
                                React.createElement("div", { className: 'pl-3 pr-3 mt-3' },
                                    React.createElement("h5", null, "No users matched"),
                                    React.createElement("p", { className: 'c-font-size-s' }, "We couldn't find any matches for \"" + this.state.searchUser + "\". Please check for typos.",
                                        " ")))));
                }
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.renderInstruction = function () {
            try {
                return (React.createElement("div", { className: 'cc-instruction container mt-4 mt-md-5 mb-4 text-center' },
                    React.createElement("img", { src: '/img/icon/messaging.png', className: 'img-fluid mb-4' }),
                    React.createElement("h2", { className: 'mb-4 c-font-weight-medium' }, "Start a Conversation"),
                    React.createElement("p", null, "You don't have any messages! It's easy and fun to start a conversation with other CRUZ\u00AE members."),
                    React.createElement("hr", { className: 'c-hr-1 w-25 mt-4 mb-4' })));
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.handleCloseRequestPermission = function () {
            this.setState({
                confirm_close_permission_request: true
            });
        };
        Page.prototype.renderRequestPermission = function () {
            var _this = this;
            try {
                return (React.createElement("div", { className: 'p-3 text-center cc-request-permission' }, this.state.confirm_close_permission_request
                    ?
                        React.createElement(React.Fragment, null,
                            React.createElement("button", { type: 'button', className: 'btn c-btn-2 c-btn-xs c-btn-round mt-1 mx-1 mt-sm-none', onClick: function () { return lib_1.Chat.store.dispatch({ type: 25, data: false }); } }, "Never ask me again on this computer"),
                            React.createElement("span", { className: 'd-block d-sm-none' }),
                            React.createElement("button", { type: 'button', className: 'btn c-btn-1 c-btn-xs c-btn-round mt-1 mx-1 mt-sm-none', onClick: function () { return lib_1.Chat.store.dispatch({ type: 25 }); } }, "Ask me next time"))
                    :
                        React.createElement(React.Fragment, null,
                            React.createElement("button", { type: 'button', className: 'float-right c-btn-circle-xs c-btn-1', onClick: function () { return _this.handleCloseRequestPermission(); } }, "\u00D7"),
                            "CRUZ\u00AE needs your permission to",
                            React.createElement("button", { type: 'button', className: 'btn c-btn-1 c-btn-xs c-btn-round ml-1', onClick: function () { return lib_1.Chat.requestNotificationPermission(); } }, "enable desktop notifications"))));
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.renderChatPanel = function () {
            var _this = this;
            try {
                var ss = lib_1.Chat.store.getState();
                var allowRender = (ss.chat_list != null && ss.active_chat_id != null);
                return (React.createElement("div", { className: classNames('cc-right flex-ch-100', { 'd-none': !ss.show_chat_panel }, 'd-md-flex') },
                    React.createElement("div", { className: 'cc-right-top d-flex align-items-center shadow-sm' },
                        React.createElement("div", { className: 'd-md-none d-flex align-self-stretch' },
                            React.createElement("button", { type: 'button', className: 'btn cc-btn-to-chatlist pl-4 pr-4', onClick: function () { return lib_1.Chat.store.dispatch({ type: 10 }); } },
                                React.createElement("i", { className: 'fas fa-arrow-left' }))),
                        allowRender
                            &&
                                this.renderChatHeader()),
                    React.createElement(react_dropzone_1.default, { ref: "dropzone", multiple: true, disableClick: true, disablePreview: false, className: 'cc-right-middle dropzone', activeClassName: 'active', id: 'cc-right-middle', maxSize: 26214400, onDrop: function (file_list) { return _this.onDropAccepted(file_list); }, onDropRejected: function (rejected_list) { return _this.onDropRejected(rejected_list); } }, allowRender && this.renderMessageList()),
                    React.createElement("div", { className: 'cc-right-bottom' },
                        lib_1.Chat.store.getState().show_new_message_button
                            &&
                                React.createElement("div", { className: 'cc-new-message mx-auto text-center' },
                                    React.createElement("button", { type: 'button', className: 'btn c-btn-1 c-btn-s c-btn-round', onClick: function () { return lib_1.Chat.store.dispatch({ type: 18 }); } },
                                        React.createElement("i", { className: 'fas fa-arrow-down' }),
                                        React.createElement("span", null, "New Message"))),
                        this.renderTyping(),
                        this.renderInput())));
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.render = function () {
            try {
                if (lib_1.Chat.store) {
                    var ss = lib_1.Chat.store.getState();
                    var socket_status = lib_1.Global.store.getState().socket_status;
                    var chat_list = null;
                    if (ss && ss.chat_list) {
                        chat_list = _.filter(ss.chat_list, function (cc) {
                            var x = _.filter(cc.user_list, function (user) {
                                return user._id == lib_1.Global.store.getState().user._id && user.is_organizer == true;
                            });
                            return (x != null || cc.message_list.length > 0);
                        });
                    }
                    return (component_1.Layout.main(null, null, React.createElement("div", { className: ' container-fluid c-chat flex-ch-100' },
                        ss.show_request_permission
                            &&
                                this.renderRequestPermission(),
                        ss.is_creating_chat
                            &&
                                this.renderCreatingChat(),
                        (socket_status == 1 || socket_status == -1)
                            &&
                                this.renderSocketStatus(),
                        (ss.chat_list == null && socket_status != -1)
                            &&
                                React.createElement(component_1.Loading, null),
                        (chat_list != null && chat_list.length == 0)
                            &&
                                this.renderInstruction(),
                        (chat_list != null && chat_list.length > 0)
                            &&
                                React.createElement("div", { className: 'flex-rh-100' },
                                    React.createElement("div", { className: classNames('cc-left', { 'd-none': ss.show_chat_panel }, 'd-md-flex shadow-sm') }, this.renderChatList()),
                                    this.renderChatPanel()))));
                }
                else {
                    return (component_1.Layout.main(null, null, React.createElement("div", null)));
                }
            }
            catch (err) {
                return null;
            }
        };
        return Page;
    }(React.Component));
    Messaging.Page = Page;
})(Messaging = exports.Messaging || (exports.Messaging = {}));
