"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Redux = require("redux");
var _ = require("lodash");
var request = require("superagent");
var __1 = require("..");
var cfg = require("../../../cfg");
var Statistic;
(function (Statistic) {
    var actions;
    (function (actions) {
        actions[actions["setStatistic"] = 0] = "setStatistic";
        actions[actions["setOnlineUser"] = 1] = "setOnlineUser";
    })(actions = Statistic.actions || (Statistic.actions = {}));
    Statistic.app = function (state, action) {
        switch (action.type) {
            case actions.setStatistic: {
                if (_.isObject(action.data) && !_.isEmpty(action.data)) {
                    state.statistic = _.assign({}, state.statistic, action.data);
                }
                return state;
            }
            case actions.setOnlineUser: {
                if (_.isObject(action.data) && !_.isEmpty(action.data)) {
                    state.online_user = _.assign({}, state.online_user, action.data);
                }
                return state;
            }
            default:
                return state;
        }
    };
    Statistic.store = Redux.createStore(Statistic.app, {
        online_user: {},
        statistic: {}
    });
    function refreshStatistic() {
        getStatisticSummary(function (err, result) {
            Statistic.store.dispatch({ type: actions.setStatistic, data: result });
        });
    }
    Statistic.refreshStatistic = refreshStatistic;
    function getStatisticSummary(callback) {
        request.get(__1.Global.cruzApiBaseUrl + '/statistic/summary')
            .timeout(cfg.superagent.timeout.short)
            .end(function (err, apiRes) {
            if (err) {
                return callback(err, null);
            }
            else {
                return callback(null, apiRes.body.data);
            }
        });
    }
    Statistic.getStatisticSummary = getStatisticSummary;
})(Statistic = exports.Statistic || (exports.Statistic = {}));
