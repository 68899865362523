"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var _ = require("lodash");
var Cruz = require("cruz");
var home_1 = require("../modules/home");
var user_1 = require("../modules/user");
var discussion_search_1 = require("../modules/discussion/discussion_search");
var discussion_detail_1 = require("../modules/discussion/discussion_detail");
var vehicle_1 = require("../modules/vehicle");
var spotted_1 = require("../modules/spotted");
var deal_1 = require("../modules/deal");
var contact_1 = require("../modules/contact");
var about_1 = require("../modules/about");
var terms_conditions_1 = require("../modules/terms_conditions");
var privacy_policy_1 = require("../modules/privacy_policy");
var rideshare_1 = require("../modules/trademark/rideshare");
var roadside_1 = require("../modules/trademark/roadside");
var service_quote_1 = require("../modules/service_quote");
var error_1 = require("../modules/error");
exports.pages = _.merge(Cruz.Pages.Cruz, {
    home: {
        component: home_1.Home.Page
    },
    messaging: {
        component: user_1.Messaging.Page
    },
    notification: {
        component: user_1.Notification.Page
    },
    notification_settings: {
        component: user_1.NotificationSettings.Page
    },
    register: {
        component: user_1.Register.Page
    },
    registered: {
        component: user_1.Registered.Page
    },
    request_verification_token: {
        component: user_1.RequestVerificationToken.Page
    },
    verify_account: {
        component: user_1.VerifyAccount.Page
    },
    signin: {
        component: user_1.Signin.Page
    },
    forgot_password: {
        component: user_1.ForgotPassword.Page
    },
    reset_password: {
        component: user_1.ResetPassword.Page
    },
    my_profile: {
        component: user_1.MyProfile.Page
    },
    change_password: {
        component: user_1.ChangePassword.Page
    },
    change_email: {
        component: user_1.ChangeEmail.Page
    },
    my_cruz: {
        component: user_1.MyCruz.Page
    },
    my_referral: {
        component: user_1.MyReferrals.Page
    },
    my_follow: {
        component: user_1.MyFollow.Page
    },
    my_vehicle: {
        component: user_1.MyVehicle.Page
    },
    my_spotted: {
        component: user_1.MySpotted.Page
    },
    my_saved: {
        component: user_1.MySaved.Page
    },
    subscription: {
        component: user_1.Subscription.Page,
    },
    user_cruz: {
        component: user_1.UserCruz.Page
    },
    user_vehicle: {
        component: user_1.UserVehicle.Page
    },
    user_spotted: {
        component: user_1.UserSpotted.Page
    },
    user_following: {
        component: user_1.UserFollowing.Page
    },
    vehicle_add_edit: {
        component: vehicle_1.VehicleAddEdit.Page
    },
    discussion_add_edit: {
        component: user_1.DiscussionAddEdit.Page
    },
    discussion_search: {
        component: discussion_search_1.DiscussionSearch.Page
    },
    discussion_detail: {
        component: discussion_detail_1.DiscussionDetail.Page
    },
    vehicle_search: {
        component: vehicle_1.VehicleSearch.Page
    },
    vehicle_detail: {
        component: vehicle_1.VehicleDetail.Page
    },
    spotted_search: {
        component: spotted_1.SpottedSearch.Page
    },
    spotted_detail: {
        component: spotted_1.SpottedDetail.Page
    },
    spotted_add_edit: {
        component: spotted_1.SpottedAddEdit.Page
    },
    deal_search: {
        component: deal_1.DealSearch.Page
    },
    deal_detail: {
        component: deal_1.DealDetail.Page
    },
    about: {
        component: about_1.About.Page
    },
    contact: {
        component: contact_1.Contact.Page
    },
    terms_conditions: {
        component: terms_conditions_1.TermsConditions.Page
    },
    privacy_policy: {
        component: privacy_policy_1.PrivacyPolicy.Page
    },
    rideshare: {
        component: rideshare_1.Rideshare.Page
    },
    roadside: {
        component: roadside_1.Roadside.Page
    },
    service_quote: {
        component: service_quote_1.ServiceQuote.Page
    },
    service_quote_request: {
        component: service_quote_1.ServiceQuoteRequest.Page
    },
    my_service_quote: {
        component: service_quote_1.MyServiceQuote.Page
    },
    service_quote_detail: {
        component: service_quote_1.ServiceQuoteDetail.Page
    },
    service_quote_pay: {
        component: service_quote_1.ServiceQuotePay.Page
    },
    service_voucher: {
        component: service_quote_1.ServiceVoucher.Page
    },
    error: {
        component: error_1.Err.Page
    },
    browser: {
        component: error_1.Browser.Page
    }
});
