"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var ReactDOM = require("react-dom");
var request = require("superagent");
var _ = require("lodash");
var classNames = require("classnames");
var component_1 = require("../../component");
var cfg = require("../../../cfg");
var pages_1 = require("../../../routes/pages");
var lib_1 = require("../../lib");
var ChangeEmail;
(function (ChangeEmail) {
    var validate = require('validate.js');
    var rules = {
        email: {
            presence: { message: '^New Email is required.', allowEmpty: false },
            email: { message: '^Invalid email format.' }
        },
        code: {
            presence: { message: '^Code is required.', allowEmpty: false },
        }
    };
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                email: '',
                is_sending_email: false,
                is_code_sent: false,
                is_sending_code: false,
                validation_email: null,
                validation_code: null,
                is_email_changed: false
            };
            return _this;
        }
        Page.prototype.componentWillMount = function () {
            lib_1.Auth.isAuthed(function (isAuthed) {
                !isAuthed && lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
            });
        };
        Page.prototype.componentDidMount = function () {
            document.title = lib_1.util.title(pages_1.pages.change_email.title);
        };
        Page.prototype.valid = function (key) {
            return validate.single(this.state[key], rules[key]);
        };
        Page.prototype.newEmailChanged = function (tgt) {
            var _this = this;
            this.setState({ email: tgt.value }, function () {
                _this.state.validation_email && _this.setState({ validation_email: _this.valid('email') });
            });
        };
        Page.prototype.requestCode = function (e) {
            var _this = this;
            e.preventDefault();
            if (!this.state.is_sending_email) {
                var err = this.valid('email');
                if (err) {
                    this.setState({ validation_email: err });
                }
                else {
                    lib_1.Auth.isAuthed(function (isAuthed) {
                        if (isAuthed) {
                            _this.setState({ is_sending_email: true }, function () {
                                request.post(lib_1.Global.cruzApiBaseUrl + '/user/request-change-email')
                                    .timeout(cfg.superagent.timeout.normal)
                                    .set(lib_1.Auth.authHeader())
                                    .send({ email: _this.state.email })
                                    .end(function (err, apiRes) {
                                    _this.setState({ is_sending_email: false });
                                    if (err) {
                                        lib_1.util.setToastMessage('error', apiRes.body.error.detail);
                                        _this.setState({ is_code_sent: false });
                                    }
                                    else {
                                        ReactDOM.unmountComponentAtNode(document.querySelector('#_msg_email'));
                                        _this.setState(_.assign({}, { is_code_sent: true }, apiRes.body.data));
                                        lib_1.util.setFocus('#code');
                                    }
                                });
                            });
                        }
                        else {
                            lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                        }
                    });
                }
            }
        };
        Page.prototype.codeChanged = function (tgt) {
            var _this = this;
            var code = tgt.value;
            this.setState({ code: code.toUpperCase() }, function () {
                _this.state.validation_code && _this.setState({ validation_code: _this.valid('code') });
            });
        };
        Page.prototype.sendCode = function (e) {
            var _this = this;
            e.preventDefault();
            if (!this.state.is_sending_code) {
                var err = this.valid('code');
                if (err) {
                    this.setState({ validation_code: err });
                }
                else {
                    lib_1.Auth.isAuthed(function (isAuthed) {
                        if (isAuthed) {
                            _this.setState({ is_sending_code: true });
                            request.patch(lib_1.Global.cruzApiBaseUrl + '/user/change-email')
                                .timeout(cfg.superagent.timeout.normal)
                                .set(lib_1.Auth.authHeader())
                                .send(_.pick(_this.state, ['code', '_id']))
                                .end(function (err, apiRes) {
                                _this.setState({ is_sending_email: false });
                                if (err) {
                                    lib_1.util.setToastMessage('error', _.get(err, cfg.apiErrorKey));
                                    _this.setState({ is_email_changed: false });
                                }
                                else {
                                    lib_1.Global.store.dispatch({ type: lib_1.Global.actions.setUser, data: apiRes.body.data });
                                    ReactDOM.unmountComponentAtNode(document.querySelector('#_msg_code'));
                                    _this.setState({ is_email_changed: true });
                                }
                            });
                        }
                        else {
                            lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                        }
                    });
                }
            }
        };
        Page.prototype.renderForm = function () {
            var _this = this;
            return (this.state.is_email_changed
                ?
                    React.createElement("div", { className: 'alert c-alert mt-3' },
                        "Your email has been changed to ",
                        React.createElement("span", { className: 'c-font-weight-medium' }, this.state.email))
                :
                    React.createElement("div", { className: 'card card-form mt-3' },
                        React.createElement("div", { className: 'card-body' },
                            React.createElement("form", { id: 'formRequestCode', className: 'form-main', onSubmit: function (e) { return _this.requestCode(e); }, noValidate: true },
                                React.createElement("div", { className: 'row justify-content-center' },
                                    React.createElement("div", { className: 'col-sm-9 col-md-7 col-lg-5' },
                                        React.createElement("div", { className: classNames('form-group mb-0', { 'has-error': this.state.validation_email != null }, { 'has-value': (this.state.email && this.state.email != '') }) },
                                            React.createElement("div", { className: 'input-group' },
                                                React.createElement("input", { type: 'email', id: 'email', role: 'textbox', required: true, className: 'form-control', autoComplete: 'off', value: this.state.email || '', onChange: function (e) { return _this.newEmailChanged(e.target); } }),
                                                React.createElement("label", { htmlFor: 'email', className: 'control-label' }, "Enter New Email"),
                                                React.createElement("div", { className: 'input-group-append' },
                                                    React.createElement("button", { type: 'submit', className: 'btn c-btn-1' }, this.state.is_sending_email ? React.createElement(component_1.BtnWorking, { value: 'Sending ...' }) : 'Send'))),
                                            React.createElement("span", { className: 'form-control-feedback', "aria-hidden": true }, this.state.validation_email && this.state.validation_email[0])))),
                                React.createElement("div", { id: "_msg_email" })),
                            this.state.is_code_sent
                                &&
                                    React.createElement("form", { id: 'formSendCode', className: 'form-main', onSubmit: function (e) { return _this.sendCode(e); }, noValidate: true },
                                        React.createElement("hr", { className: 'c-hr' }),
                                        React.createElement("div", { className: 'row justify-content-center' },
                                            React.createElement("div", { className: 'col-sm-9 col-md-7 col-lg-5' },
                                                React.createElement("label", { className: 'control-label' }, "Please check your email for the verification code"),
                                                React.createElement("div", { className: classNames('form-group mb-0', { 'has-error': this.state.validation_code != null }, { 'has-value': (this.state.code && this.state.code != '') }) },
                                                    React.createElement("div", { className: 'input-group' },
                                                        React.createElement("input", { type: 'text', id: 'code', role: 'textbox', className: 'form-control', value: this.state.code || '', placeholder: 'Verification Code', onChange: function (e) { return _this.codeChanged(e.target); }, required: true }),
                                                        React.createElement("div", { className: 'input-group-append' },
                                                            React.createElement("button", { type: 'submit', className: 'btn c-btn-1' }, this.state.is_sending_code ? React.createElement(component_1.BtnWorking, { value: 'Sending ...' }) : 'Send'))),
                                                    React.createElement("span", { className: 'form-control-feedback', "aria-hidden": true }, this.state.validation_code && this.state.validation_code[0])))),
                                        React.createElement("div", { id: "_msg_code" })))));
        };
        Page.prototype.render = function () {
            return (component_1.Layout.main(lib_1.Global.store.getState().user, null, React.createElement("div", { className: 'container c-container' },
                React.createElement("h1", { className: 'c-title' }, pages_1.pages.change_email.title),
                this.renderForm())));
        };
        return Page;
    }(React.Component));
    ChangeEmail.Page = Page;
})(ChangeEmail = exports.ChangeEmail || (exports.ChangeEmail = {}));
