"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var lib_1 = require("../../lib");
var _ = require("lodash");
var $ = require("jquery");
var Toast = (function (_super) {
    __extends(Toast, _super);
    function Toast(props) {
        return _super.call(this, props) || this;
    }
    Toast.prototype.componentDidMount = function () {
        var _this = this;
        this.unsubscribe = lib_1.Global.store.subscribe(function () { _this.forceUpdate(); });
    };
    Toast.prototype.componentWillUnmount = function () {
        this.unsubscribe && this.unsubscribe();
    };
    Toast.prototype.renderHeader = function (type) {
        switch (type) {
            case 'error':
                return (React.createElement("span", null, "Error"));
            case 'success':
                return (React.createElement("span", null, "Succeed"));
            case 'info': {
                return (React.createElement("span", null, "Info"));
            }
            default:
                return (React.createElement("div", null));
        }
    };
    Toast.prototype.render = function () {
        var tm = lib_1.Global.store.getState().toast_message;
        if (_.isEmpty(tm)) {
            if (lib_1.util.isClient()) {
                var x = $('.toast');
                if (x.length > 0) {
                    x.toast('hide');
                }
            }
            return null;
        }
        else {
            setTimeout(function () {
                $('.toast').toast('show');
                $('.toast').on('hide.bs.toast', function () {
                    if (!_.isEmpty(lib_1.Global.store.getState().toast_message)) {
                        lib_1.Global.store.dispatch({ type: lib_1.Global.actions.setToastMessage, data: null });
                    }
                });
            }, 10);
            if (tm.type == 'success') {
                setTimeout(function () {
                    $('.toast').toast('hide');
                }, 3000);
            }
            var message = tm.message;
            if (tm.type == 'error' && (_.isEmpty(message) || typeof message == 'object')) {
                message = 'Something is wrong.';
            }
            return (React.createElement("div", { id: 'c-toast', className: 'toast c-toast fixed-top mx-auto c-toast-' + tm.type, role: 'alert', "aria-live": 'assertive', "aria-atomic": 'true', "data-autohide": false },
                React.createElement("div", { className: 'toast-header d-flex justify-content-between' },
                    this.renderHeader(tm.type),
                    React.createElement("button", { type: 'button', className: 'ml-2 mb-1 close', "data-dismiss": 'toast', "aria-label": 'Close' })),
                React.createElement("div", { className: 'toast-body' }, typeof message == 'object' ? JSON.stringify(message) : lib_1.util.text2html(message))));
        }
    };
    return Toast;
}(React.Component));
exports.Toast = Toast;
