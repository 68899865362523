"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var _ = require("lodash");
var moment = require("moment");
var Cruz = require("cruz");
var pages_1 = require("../../../routes/pages");
var cfg = require("../../../cfg");
var component_1 = require("../../component");
var __1 = require("..");
var user_1 = require("../../user");
var lib_1 = require("../../lib");
var SpottedDetail;
(function (SpottedDetail) {
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            var _this = _super.call(this, props) || this;
            _this.isUnmounted = false;
            _this.trackViewTimeout = null;
            _this.state = {
                _id: _this.props.match.params._id,
                is_loading: false,
                is_working: false
            };
            return _this;
        }
        Page.prototype.componentWillMount = function () {
            var _this = this;
            try {
                if (this.props.staticContext) {
                    var d = JSON.parse(JSON.stringify(this.props.staticContext));
                    this.setState({ spotted: d.result, is_loading: false });
                }
                else {
                    var $initData = document.querySelector('#_init_' + pages_1.pages.spotted_detail.id);
                    if ($initData && $initData.getAttribute('data-init')) {
                        var d = JSON.parse(decodeURIComponent($initData.getAttribute('data-init')));
                        $initData.remove();
                        this.setState({ spotted: d, is_loading: false }, function () {
                            _this.trackView();
                            _this.setPageTitle();
                        });
                    }
                    else {
                        this.init();
                    }
                }
            }
            catch (err) {
            }
        };
        Page.prototype.componentWillUnmount = function () {
            this.isUnmounted = true;
            this.trackViewTimeout && clearTimeout(this.trackViewTimeout);
        };
        Page.prototype.init = function () {
            var _this = this;
            this.setState({ is_loading: true }, function () {
                lib_1.Request.spotted.detail(_this.state._id, function (err, apiRes) {
                    if (err) {
                        lib_1.util.handleError(lib_1.Request.getApiErrorCode(err));
                    }
                    else {
                        _this.setState({ is_loading: false, spotted: apiRes.body.data }, function () {
                            _this.setPageTitle();
                            _this.trackView();
                        });
                    }
                });
            });
        };
        Page.prototype.setPageTitle = function () {
            document.title = lib_1.util.title(pages_1.pages.spotted_detail.title, _.isEmpty(this.state.spotted) ? null : this.state.spotted.title);
        };
        Page.prototype.componentWillUpdate = function () {
            if (this.props.location.hash) {
                lib_1.util.scrollTo(this.props.location.hash, 60);
            }
            this.setPageTitle();
        };
        Page.prototype.updateExternalStore = function (d, updateMySpotted) {
            if (__1.SpottedSearch.store) {
                __1.SpottedSearch.store.dispatch({ type: 5, data: d });
            }
            if (updateMySpotted && user_1.MySpotted.store) {
                user_1.MySpotted.store.dispatch({ type: 5, data: d });
            }
        };
        Page.prototype.updateDone = function (d, shouldRefreshStatistic) {
            if (shouldRefreshStatistic) {
                lib_1.Statistic.refreshStatistic();
            }
            this.updateExternalStore(d, true);
            if (this.isUnmounted == false) {
                this.setState({ spotted: _.assign({}, this.state.spotted, d) });
            }
        };
        Page.prototype.trackView = function () {
            var _this = this;
            this.trackViewTimeout = setTimeout(function () {
                lib_1.Tracking.trackView({ name: 'spotted', _id: _this.state._id }, function (err, result) {
                    if (!err && !_.isEmpty(result)) {
                        _this.updateDone(result, false);
                    }
                });
            }, 2000);
        };
        Page.prototype.openDeleteDialog = function () {
            var _this = this;
            lib_1.util.setModal(null, false, true, React.createElement(component_1.Delete.Dialog, { id: this.state._id, onYes: function () { return _this.deleteYes(); } }));
        };
        Page.prototype.deleteYes = function () {
            var _this = this;
            this.setState({ is_working: true }, function () {
                lib_1.Request.spotted.delete(_this.state._id, function (err, apiRes) {
                    _this.setState({ is_working: false }, function () {
                        if (err) {
                            lib_1.util.handleError(lib_1.Request.getApiErrorCode(err));
                        }
                        else {
                            _this.updateDone(apiRes.body.data, true);
                        }
                    });
                });
            });
        };
        Page.prototype.undelete = function () {
            var _this = this;
            this.setState({ is_working: true }, function () {
                lib_1.Request.spotted.undelete(_this.state._id, function (err, apiRes) {
                    _this.setState({ is_working: false }, function () {
                        if (err) {
                            lib_1.util.handleError(lib_1.Request.getApiErrorCode(err));
                        }
                        else {
                            _this.updateDone(apiRes.body.data, true);
                        }
                    });
                });
            });
        };
        Page.prototype.openPhotoView = function (d) {
            lib_1.util.setModalPhotoView({
                title: d.title,
                baseUrl: cfg.photoBaseUrl[cfg.env].cdn + '/spotted/' + d.group + '/' + d._id,
                photo_list: d.photo_list
            });
        };
        Page.prototype.commentDone = function (c) {
            this.updateExternalStore({ _id: this.state._id, comment_count: c || 0 }, true);
        };
        Page.prototype.voteDone = function (d) {
            var _this = this;
            this.setState({ spotted: _.assign({}, this.state.spotted, d) }, function () {
                _this.updateExternalStore(d, false);
            });
        };
        Page.prototype.renderVote = function () {
            var d = this.state.spotted;
            return (React.createElement(component_1.Vote.LikeButton, { name: 'spotted', _id: d._id, user_id: d.user_id, vote: d.vote, onVoteDone: this.voteDone.bind(this), className: 'btn c-btn-3 c-btn-xs' }));
        };
        Page.prototype.renderHeading = function (spotted, isMine) {
            var _this = this;
            return (React.createElement("div", { className: 'c-detail-heading' },
                (isMine && spotted.post_status_id == Cruz.Lookup.Other.PostStatusEnum.Delete)
                    &&
                        React.createElement("div", { className: 'c-alert c-alert-deleted text-center mb-4' }, "This spotted has been deleted."),
                React.createElement("div", { className: 'd-xl-flex align-items-end' },
                    React.createElement("div", { className: 'pr-lg-5' },
                        isMine
                            ?
                                React.createElement(react_router_dom_1.Link, { to: pages_1.pages.my_spotted.path, className: 'c-category c-btn-link' },
                                    pages_1.pages.my_spotted.title,
                                    React.createElement("i", { className: 'fal fa-angle-double-right ml-2' }))
                            :
                                React.createElement(react_router_dom_1.Link, { to: pages_1.pages.spotted_search.path, className: 'c-category c-btn-link' },
                                    pages_1.pages.spotted_search.title,
                                    React.createElement("i", { className: 'fal fa-angle-double-right ml-2' })),
                        React.createElement("h1", { className: 'title mt-1' }, spotted.title),
                        React.createElement("div", { className: 'c-font-size-xs mt-1' },
                            React.createElement("span", null,
                                React.createElement("i", { className: 'fal fa-map-marker fa-fw mr-2' }),
                                (spotted.location && spotted.location.city && spotted.location.state) ? spotted.location.city + ', ' + spotted.location.state : spotted.location_name),
                            React.createElement("span", { className: 'separator d-none d-md-inline' }),
                            React.createElement("span", { className: 'd-block d-md-inline' },
                                React.createElement("i", { className: 'fal fa-clock fa-fw mr-2' }),
                                React.createElement("span", null, 'Posted ' + moment(spotted.date_create).fromNow()),
                                (spotted.date_update && moment(spotted.date_update).fromNow() != moment(spotted.date_create).fromNow())
                                    &&
                                        React.createElement("span", null,
                                            React.createElement("span", { className: 'separator' }),
                                            React.createElement("span", null, 'Updated ' + moment(spotted.date_update).fromNow()))))),
                    React.createElement("div", { className: 'ml-xl-auto mt-3 mt-xl-0 pl-0 pl-xl-5 c-toolbar flex-wrap flex-md-nowrap' },
                        React.createElement("button", { type: 'button', className: 'btn c-btn-3 c-btn-xs d-xl-none btn-goto-comments' },
                            React.createElement("span", { className: 'c-count-prepend c-font-weight-medium' }, spotted.comment_count.toLocaleString()),
                            React.createElement("span", { className: 'c-font-size-xxs' }, "Comments")),
                        component_1.Stat.views(spotted.view_count),
                        this.renderVote(),
                        isMine
                            &&
                                React.createElement("div", { className: 'd-flex flex-nowrap' },
                                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.spotted_add_edit.path + '?_id=' + spotted._id, className: 'btn c-btn-1 c-btn-xs mr-2' }, "Update"),
                                    (spotted.post_status_id == Cruz.Lookup.Other.PostStatusEnum.Delete)
                                        ?
                                            React.createElement("button", { type: 'button', className: 'btn c-btn-2 c-btn-xs', onClick: function () { return _this.undelete(); } },
                                                React.createElement("i", { className: 'fal fa-undo' }),
                                                React.createElement("span", null, this.state.is_working ? React.createElement(component_1.BtnWorking, { value: 'Undeleting ...' }) : 'Undelete'))
                                        :
                                            React.createElement("button", { type: 'button', className: 'btn c-btn-2 c-btn-xs', onClick: function () { return _this.openDeleteDialog(); } }, this.state.is_working ? React.createElement(component_1.BtnWorking, { value: 'Deleting ...' }) : 'Delete'))))));
        };
        Page.prototype.renderSpottedDetail = function () {
            var d = this.state.spotted;
            var comment = this.props.staticContext ? _.assign({}, this.props.staticContext['comment']) : null;
            var my_user_id = !_.isEmpty(lib_1.Global.store.getState().user) ? lib_1.Global.store.getState().user._id : null;
            var isMine = (my_user_id != null && my_user_id == d.user_id);
            var hasContent = !_.isEmpty(d.content);
            return (React.createElement("div", { className: 'flex-ch-100' },
                !lib_1.util.isClient() && lib_1.Schema.SpottedItem(d),
                this.renderHeading(d, isMine),
                React.createElement("div", { className: 'row no-gutters flex-rh-100' },
                    React.createElement("div", { className: 'col-12 col-xl pb-3' },
                        component_1.PhotoView.riGallery(d.title, cfg.photoBaseUrl[cfg.env].cdn + '/spotted/' + d.group + '/' + d._id + '/', d.photo_list, true),
                        React.createElement("hr", { className: 'c-hr my-0' }),
                        hasContent
                            &&
                                React.createElement("div", { className: 'c-detail-content py-4' }, lib_1.util.text2html(d.content)),
                        hasContent
                            &&
                                React.createElement("hr", { className: 'c-hr my-0' }),
                        React.createElement("div", { className: 'my-3 c-detail-content' },
                            React.createElement("div", { className: 'c-toolbar' },
                                component_1.Stat.views(d.view_count),
                                this.renderVote()))),
                    React.createElement("div", { className: 'col-12 c-col-right' },
                        React.createElement(component_1.Comment.Component, { parent_url: this.props.location.pathname, api_base_url: lib_1.Global.cruzApiBaseUrl + '/spotted', discussion_id: d._id, query: { page_size: cfg.commentPageSize.spotted }, result: comment, name: 'spotted_comment', onCommentDone: this.commentDone.bind(this) })))));
        };
        Page.prototype.render = function () {
            return (component_1.Layout.main(((this.state && !_.isEmpty(this.state.spotted)) ? this.state.spotted.user_display : null), null, React.createElement("div", { className: 'flex-rh-100 container c-detail-container' }, (this.state.is_loading || _.isEmpty(this.state.spotted))
                ?
                    React.createElement(component_1.Loading, null)
                :
                    this.renderSpottedDetail())));
        };
        return Page;
    }(React.Component));
    SpottedDetail.Page = Page;
})(SpottedDetail = exports.SpottedDetail || (exports.SpottedDetail = {}));
