"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var Redux = require("redux");
var react_router_dom_1 = require("react-router-dom");
var _ = require("lodash");
var uri = require("urijs");
var pages_1 = require("../../../routes/pages");
var cfg = require("../../../cfg");
var lib_1 = require("../../lib");
var component_1 = require("../../component");
var user_1 = require("../../user");
var SpottedSearch;
(function (SpottedSearch) {
    function searchSpotted(query, callback) {
        lib_1.Request.spotted.search(_.assign({}, cfg.apiQueryDefault.spotted, query), function (err, apiRes) {
            return (err ? callback(err, null) : callback(null, apiRes.body));
        });
    }
    SpottedSearch.searchSpotted = searchSpotted;
    SpottedSearch.app = function (state, action) {
        var _a;
        function update(spotted) {
            if (spotted) {
                var x = _.find(state.result.data, { _id: spotted._id });
                if (x) {
                    _.assign(x, spotted);
                }
            }
        }
        function search() {
            lib_1.Global.history.replace(lib_1.util.buildUrl(location.pathname, state.query));
            searchSpotted(state.query, function (err, result) {
                if (err) {
                    SpottedSearch.store.dispatch({ type: 8, error: err });
                }
                else {
                    SpottedSearch.store.dispatch({ type: 3, data: result, isLoadMore: state.is_loading_more });
                }
            });
        }
        switch (action.type) {
            case 8: {
                state.is_loading = false;
                state.is_loading_more = false;
                state.is_filtering = false;
                lib_1.util.logError(action.error, null);
                lib_1.util.handleError(lib_1.Request.getApiErrorCode(action.error));
                return state;
            }
            case 6: {
                var tgt = action.target;
                if (tgt.type == 'checkbox' && !tgt.checked) {
                    state.query = _.omit(state.query, ['page_number', 'ts', 'sort_value', tgt.id]);
                }
                else {
                    state.query = _.assign({}, _.omit(state.query, ['page_number', 'ts', 'sort_value']), (_a = {}, _a[tgt.id] = tgt.checked ? 1 : tgt.value, _a));
                }
                $(tgt).blur();
                state.is_filtering = true;
                search();
                return state;
            }
            case 1: {
                if (!state.is_loading) {
                    if (_.isEmpty(lib_1.Global.store.getState().user) || (lib_1.Global.store.getState().user._id != state.query.user_id)) {
                        state.query = _.omit(state.query, 'user_id');
                    }
                    if (action.query) {
                        state.query = _.assign({}, _.omit(state.query, ['ts', 'page_number', 'sort_value']), action.query);
                        if (!_.isEmpty(lib_1.Global.store.getState().user)) {
                            lib_1.Global.saveUserSettings({ spotted_search: _.pick(state.query, 'sort') });
                        }
                        state.is_filtering = true;
                    }
                    else {
                        state.query = _.assign({}, cfg.apiQueryDefault.spotted);
                        state.is_loading = true;
                    }
                    lib_1.Global.history.replace(lib_1.util.buildUrl(location.pathname, state.query));
                    search();
                }
                return state;
            }
            case 2: {
                action.event && action.event.preventDefault();
                if (!state.is_loading_more) {
                    state.query = _.assign({}, state.query, uri(state.result.links.next).search(true));
                    state.is_loading_more = true;
                    search();
                }
                return state;
            }
            case 3: {
                var d = action.data;
                if (d) {
                    state.result.links = _.assign({}, d.links);
                    state.result.meta = _.assign({}, d.meta);
                    state.result.data = (action.isLoadMore) ? _.union(state.result.data, d.data) : d.data;
                }
                state.is_loading = false;
                state.is_loading_more = false;
                state.is_filtering = false;
                var tgtIndex = d.meta.page_size * (d.meta.page_number - 1);
                if (lib_1.util.isClient() && tgtIndex > 0) {
                    lib_1.util.scrollTo('#_ss_' + tgtIndex, 70);
                }
                return state;
            }
            case 4: {
                update(action.data);
                user_1.MySpotted.store && user_1.MySpotted.store.dispatch({ type: 5, data: action.data });
                return state;
            }
            case 5: {
                update(action.data);
                return state;
            }
            default: {
                return state;
            }
        }
    };
    SpottedSearch.store = null;
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            return _super.call(this, props) || this;
        }
        Page.prototype.componentWillReceiveProps = function (nextProps) {
            if (nextProps.history.action == 'PUSH') {
                SpottedSearch.store.dispatch({ type: 1 });
            }
        };
        Page.prototype.componentWillMount = function () {
            try {
                var me = lib_1.Global.store.getState().user;
                var mySettings = ((me && me.settings && me.settings.spotted_search) ? me.settings.spotted_search : {});
                var locSearch = _.assign({}, mySettings, new uri(this.props.location.search).search(true));
                if (this.props.staticContext && _.has(this.props.staticContext, 'result')) {
                    SpottedSearch.store = Redux.createStore(SpottedSearch.app, JSON.parse(JSON.stringify(this.props.staticContext)));
                }
                else if (SpottedSearch.store == null) {
                    var $initData = document.querySelector('#_init_' + pages_1.pages.spotted_search.id);
                    if ($initData && $initData.getAttribute('data-init')) {
                        SpottedSearch.store = Redux.createStore(SpottedSearch.app, _.assign({}, {
                            result: JSON.parse(decodeURIComponent($initData.getAttribute('data-init'))),
                            query: _.assign({}, cfg.apiQueryDefault.spotted, locSearch),
                            history: this.props.history
                        }));
                        $initData.remove();
                    }
                    else {
                        var d = {
                            query: _.assign({}, cfg.apiQueryDefault.spotted, locSearch),
                            result: {
                                data: [],
                                meta: {},
                                links: {},
                            }
                        };
                        SpottedSearch.store = Redux.createStore(SpottedSearch.app, d);
                        SpottedSearch.store.dispatch({ type: 1 });
                    }
                }
                else if (this.props.history.action == 'PUSH') {
                    SpottedSearch.store.dispatch({ type: 1 });
                }
                if (lib_1.util.isClient()) {
                    lib_1.Global.history.replace(lib_1.util.buildUrl(this.props.location.pathname, locSearch));
                }
            }
            catch (err) {
                lib_1.util.handleError(500);
            }
        };
        Page.prototype.componentDidMount = function () {
            var _this = this;
            this.unsubscribe = SpottedSearch.store.subscribe(function () { _this.forceUpdate(); });
            document.title = lib_1.util.title(pages_1.pages.spotted_search.title);
        };
        Page.prototype.componentWillUnmount = function () {
            this.unsubscribe && this.unsubscribe();
        };
        Page.prototype.onPostStatusUpdateDone = function (spotted) {
            SpottedSearch.store.dispatch({ type: 4, data: spotted });
        };
        Page.prototype.getNextPageUrl = function () {
            var q = _.assign({}, SpottedSearch.store.getState().query, uri.parseQuery(new uri(SpottedSearch.store.getState().result.links.next).query()));
            return pages_1.pages.spotted_search.path + '?' + uri.buildQuery(q);
        };
        Page.prototype.renderNav = function () {
            try {
                var query = SpottedSearch.store.getState().query;
                var me = lib_1.Global.store.getState().user;
                return (React.createElement("div", { className: 'c-section-navbar mb-2' },
                    React.createElement("div", { className: 'd-flex flex-column flex-md-row justify-content-md-between' },
                        React.createElement("ul", { className: 'list-inline c-btn-group-radio mb-0 pb-0' },
                            React.createElement("li", { className: 'list-inline-item' },
                                React.createElement("span", { className: 'c-text-muted' }, "Sort:")),
                            React.createElement("li", { className: 'list-inline-item' },
                                React.createElement("input", { type: 'radio', name: 'sort', id: 'sort_0', checked: query.sort == 1 ? true : false, onChange: function () { return SpottedSearch.store.dispatch({ type: 1, query: { sort: 1, ts: null } }); } }),
                                React.createElement("label", { htmlFor: 'sort_0' }, "Newest")),
                            React.createElement("li", { className: 'list-inline-item' },
                                React.createElement("input", { type: 'radio', name: 'sort', id: 'sort_1', checked: query.sort == 2 ? true : false, onChange: function (e) { return SpottedSearch.store.dispatch({ type: 1, query: { sort: 2, ts: null } }); } }),
                                React.createElement("label", { htmlFor: 'sort_1' }, "Popular"))),
                        (!_.isEmpty(me.follow))
                            &&
                                React.createElement("div", { className: 'ml-md-auto mt-2 mt-md-0 d-flex' },
                                    (me.follow.following_count != null && me.follow.following_count > 0)
                                        &&
                                            React.createElement(component_1.Checkbox, { id: 'from_following', label: 'From Following', className: 'mb-0', value: 1, checked: query.from_following ? true : false, onChange: function (e) { return SpottedSearch.store.dispatch({ type: 6, target: e.target }); } }),
                                    (me.follow.following_count != null && me.follow.follower_count > 0)
                                        &&
                                            React.createElement(component_1.Checkbox, { id: 'from_follower', className: 'ml-4 mb-0', label: 'From Follower', value: 1, checked: query.from_follower ? true : false, onChange: function (e) { return SpottedSearch.store.dispatch({ type: 6, target: e.target }); } })))));
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.renderSpottedList = function () {
            var _this = this;
            try {
                var meta_1 = SpottedSearch.store.getState().result.meta;
                return (SpottedSearch.store.getState().result.data && SpottedSearch.store.getState().result.data.length > 0 ?
                    React.createElement(React.Fragment, null,
                        React.createElement("section", { className: 'row c-sm-gutters' }, _.map(SpottedSearch.store.getState().result.data, function (spotted, idx) {
                            return (React.createElement("div", { className: 'col-xl-4 col-lg-6 col-md-6 my-2', key: spotted._id + '_' + idx, id: '_ss_' + idx },
                                React.createElement(component_1.Spotted.SummaryCard, { spotted: spotted, showUserInfo: true, showEdit: true, onPostStatusUpdateDone: _this.onPostStatusUpdateDone, shouldRender: meta_1.page_number == 1 })));
                        })),
                        (SpottedSearch.store.getState().result.links && SpottedSearch.store.getState().result.links.next)
                            &&
                                React.createElement("section", { className: 'text-center mt-4' },
                                    React.createElement("a", { className: 'btn c-btn-2-rev c-btn-round', href: this.getNextPageUrl(), onClick: function (e) { return SpottedSearch.store.dispatch({ type: 2, event: e }); } },
                                        React.createElement("i", { className: 'far fa-angle-double-down' }),
                                        SpottedSearch.store.getState().is_loading_more ? React.createElement(component_1.BtnWorking, { value: 'Loading More...' }) : React.createElement("span", null,
                                            "Load More (",
                                            meta_1.total_records_left.toLocaleString() + ' left',
                                            ")"))))
                    :
                        React.createElement("div", { className: 'alert c-alert text-center mt-3' }, "No spotted matched"));
            }
            catch (err) {
                lib_1.util.handleError(500);
            }
        };
        Page.prototype.renderHeading = function () {
            try {
                var meta = SpottedSearch.store.getState().result.meta;
                var showFilteredNumber = (meta.total_records_matched != meta.total_records);
                var me = lib_1.Global.store.getState().user;
                return (React.createElement("section", { className: 'mb-3' },
                    React.createElement("div", { className: 'row no-gutters' },
                        React.createElement("div", { className: 'col-sm-6' },
                            React.createElement("h1", { className: 'c-title' },
                                pages_1.pages.spotted_search.title,
                                React.createElement("span", { className: 'c-title-count' }, (showFilteredNumber ? (meta.total_records_matched.toLocaleString() + '/') : '') + meta.total_records.toLocaleString()))),
                        (!_.isEmpty(meta) && meta.total_records > 0) &&
                            React.createElement("div", { className: 'col-sm-6 text-md-right' },
                                React.createElement("div", { className: 'mt-3 mt-sm-0 mb-2 mb-sm-0 flex justify-content-between' },
                                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.spotted_add_edit.path, className: 'btn c-btn-1 c-btn-xs' },
                                        React.createElement("i", { className: 'fal fa-plus' }),
                                        React.createElement("span", null, "Post Spotted")),
                                    !_.isEmpty(me)
                                        &&
                                            React.createElement(react_router_dom_1.Link, { to: pages_1.pages.my_spotted.path, className: 'btn c-btn-1-rev c-btn-xs ml-2' }, "My Spotted"))))));
            }
            catch (err) {
                lib_1.util.handleError(500);
            }
        };
        Page.prototype.render = function () {
            return (component_1.Layout.main(null, null, React.createElement("div", null,
                React.createElement(component_1.Hero, { title: pages_1.pages.spotted_search.title, room: lib_1.util.getRoom(this.props.location.pathname) }),
                React.createElement("div", { className: 'container c-container' }, (SpottedSearch.store == null || SpottedSearch.store.getState().is_loading)
                    ?
                        React.createElement(component_1.Loading, null)
                    :
                        React.createElement("div", null,
                            this.renderHeading(),
                            SpottedSearch.store.getState().result.meta.total_records > 0
                                &&
                                    this.renderNav(),
                            SpottedSearch.store.getState().is_filtering
                                ?
                                    React.createElement(component_1.Loading, { label: 'searching ...' })
                                :
                                    SpottedSearch.store.getState().result.meta.total_records > 0
                                        ?
                                            this.renderSpottedList()
                                        :
                                            React.createElement(react_router_dom_1.Link, { to: pages_1.pages.spotted_add_edit.path, className: 'btn c-btn-1 c-btn-block-sm c-btn-round mt-4' },
                                                React.createElement("i", { className: 'fal fa-plus' }),
                                                React.createElement("span", null, "Post Spotted")))))));
        };
        return Page;
    }(React.Component));
    SpottedSearch.Page = Page;
})(SpottedSearch = exports.SpottedSearch || (exports.SpottedSearch = {}));
