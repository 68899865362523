"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var request = require("superagent");
var jwt_decode = require("jwt-decode");
var moment = require("moment");
var _ = require("lodash");
var cfg = require("../../../cfg");
var pages_1 = require("../../../routes/pages");
var component_1 = require("../../component");
var lib_1 = require("../../lib");
var ResetPassword;
(function (ResetPassword) {
    var validate = require('validate.js');
    var rules = {
        'password': {
            presence: { message: '^Password is required.', allowEmpty: false },
            length: {
                minimum: 6,
                tooShort: '^At least 6 characters.'
            }
        }
    };
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                is_working: false,
                password: ''
            };
            return _this;
        }
        Page.prototype.componentWillMount = function () {
            try {
                var token = this.props.match.params.token;
                if (token) {
                    this.verifyToken(token);
                }
                else {
                    lib_1.util.handleError(400);
                }
            }
            catch (err) {
                lib_1.util.handleError(400);
            }
        };
        Page.prototype.componentDidMount = function () {
            document.title = lib_1.util.title(pages_1.pages.reset_password.title);
        };
        Page.prototype.verifyToken = function (tk) {
            if (tk && moment.unix(jwt_decode(tk.toString())['exp']).isAfter(moment())) {
                this.setState({ token: tk });
            }
        };
        Page.prototype.getError = function (key) {
            if (this.state.validation && this.state.validation[key]) {
                return this.state.validation[key][0];
            }
            else {
                return null;
            }
        };
        Page.prototype.valid = function () {
            this.setState({ validation: validate(this.state, rules) });
        };
        Page.prototype.passwordChanged = function (tgt) {
            var _this = this;
            this.setState({ password: tgt.value }, function () {
                _this.state.validation && _this.valid();
            });
        };
        Page.prototype.submit = function (e) {
            var _this = this;
            e && e.preventDefault();
            var vd = validate(this.state, rules);
            this.setState({ validation: vd });
            if (!this.state.is_working && (vd == null || _.isEmpty(vd) == true)) {
                this.setState({ is_working: true });
                request.put(lib_1.Global.cruzApiBaseUrl + '/user/reset-password')
                    .timeout(cfg.superagent.timeout.normal)
                    .send({ token: this.state.token, password: this.state.password })
                    .end(function (err, apiRes) {
                    if (err) {
                        lib_1.util.setToastMessage('error', 'apiRes.body.error.detail');
                        _this.setState({ succeed: false, is_working: false });
                    }
                    else {
                        lib_1.Global.onSubmitSuccess('resetPassword');
                        _this.setState({ succeed: true, is_working: false });
                    }
                });
            }
        };
        Page.prototype.renderTokenExpired = function () {
            return (React.createElement("div", { className: 'alert alert-error text-center' },
                React.createElement("p", null,
                    "This link is expired!",
                    React.createElement("br", null),
                    "Please click the button below to get a new link."),
                React.createElement(react_router_dom_1.Link, { to: pages_1.pages.forgot_password.path, className: 'btn c-btn-1 btn-xlg btn-block mt-4' }, "Get New Link")));
        };
        Page.prototype.renderForm = function () {
            var _this = this;
            if (this.state.succeed) {
                return (React.createElement("div", { className: 'text-center mx-auto' },
                    React.createElement("p", null, "Your password has been reset!"),
                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.signin.path, className: 'btn c-btn-1 btn-block' }, "Sign in")));
            }
            else {
                return (React.createElement("form", { className: 'mx-auto', id: 'formResetPassword', onSubmit: function (e) { return _this.submit(e); }, noValidate: true },
                    React.createElement(component_1.PasswordInput, { label: 'New Password', id: 'password', required: true, value: this.state.password || '', "data-err": this.getError('password'), onChange: function (e) { return _this.passwordChanged(e.target); } }),
                    React.createElement("div", { id: "_msg" }),
                    React.createElement("button", { type: 'submit', className: 'btn c-btn-1 btn-block mt-4' }, this.state.is_working ? React.createElement(component_1.BtnWorking, { value: 'Reset Password ...' }) : 'Reset Password')));
            }
        };
        Page.prototype.render = function () {
            return (component_1.Layout.entry(1, React.createElement("div", null,
                React.createElement("h1", { className: 'c-section-title text-center mb-4' }, "Reset Password"),
                this.state.token && this.state.token != ''
                    ?
                        this.renderForm()
                    :
                        this.renderTokenExpired())));
        };
        return Page;
    }(React.Component));
    ResetPassword.Page = Page;
})(ResetPassword = exports.ResetPassword || (exports.ResetPassword = {}));
