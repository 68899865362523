"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var _ = require("lodash");
var request = require("superagent");
var reactStringReplace = require('react-string-replace');
var moment = require("moment");
var uri = require("urijs");
var slugify_1 = require("slugify");
var Cruz = require("cruz");
var cfg = require("../../../cfg");
var lib_1 = require("../../lib");
var pages_1 = require("../../../routes/pages");
var component_1 = require("../../component");
var Notification;
(function (Notification) {
    var _a;
    var notifyTmpl = (_a = {},
        _a[Cruz.Lookup.Notification.TypeEnum.UserVerified] = 'Your referral {user} is now verified.',
        _a[Cruz.Lookup.Notification.TypeEnum.UserFollowing] = '{user} is now following you.',
        _a[Cruz.Lookup.Notification.TypeEnum.Vehicle] = '{user} added a {title} {des}.',
        _a[Cruz.Lookup.Notification.TypeEnum.VehicleSaleStatusUpdate] = '{user} changed {title} to {sale_status}.',
        _a[Cruz.Lookup.Notification.TypeEnum.VehiclePriceUpdate] = '{user} changed {title} price to {price}.',
        _a[Cruz.Lookup.Notification.TypeEnum.VehicleMileageUpdate] = '{user} updated {title} mileage to {mileage}.',
        _a[Cruz.Lookup.Notification.TypeEnum.VehiclePhotoUpload] = '{user} added new photo(s) to {title}',
        _a[Cruz.Lookup.Notification.TypeEnum.Spotted] = '{user} posted in Spotted: {title}',
        _a[Cruz.Lookup.Notification.TypeEnum.Comment] = '{user} commented on your {name}: {title}',
        _a[Cruz.Lookup.Notification.TypeEnum.CommentReply] = '{user} replied to your comment on {title}',
        _a[Cruz.Lookup.Notification.TypeEnum.LikeUserPost] = {
            one: '{user} liked your {name}: {title}',
            multi: '{user} and {user_id_list_count} liked your {name}: {title}',
            one_comment: '{user} liked your comment on {title}',
            multi_comment: '{user} and {user_id_list_count} liked your comment on {title}'
        },
        _a);
    function formatNotifyHTML(d) {
        var _this = this;
        try {
            var tgId = Math.floor(d.type_id);
            var tmpStr = null;
            if (d.type_id == Cruz.Lookup.Notification.TypeEnum.LikeUserPost) {
                var key = null;
                if (_.size(d.user_id_list) > 0) {
                    if (_.isEmpty(d.comment)) {
                        key = 'multi';
                    }
                    else {
                        key = 'multi_comment';
                    }
                }
                else {
                    if (_.isEmpty(d.comment)) {
                        key = 'one';
                    }
                    else {
                        key = 'one_comment';
                    }
                }
                tmpStr = notifyTmpl[d.type_id][key];
            }
            else {
                tmpStr = notifyTmpl[d.type_id];
            }
            var nh = tmpStr;
            nh = reactStringReplace(nh, '{user}', function (mt, i) {
                return React.createElement(react_router_dom_1.Link, { to: lib_1.util.userCruzBaseUrl(d.user_display), key: mt + i, className: 'c-btn-link' }, d.user_display.first_name + ' ' + d.user_display.last_name);
            });
            if (_.size(d.user_id_list) > 0 && tmpStr.includes('user_id_list_count')) {
                var _like_1 = {
                    name: d.data.collection_name,
                    _id: d.data._id
                };
                nh = reactStringReplace(nh, '{user_id_list_count}', function (mt, i) {
                    return (React.createElement("button", { key: mt + i, type: 'button', className: 'c-btn-link', onClick: component_1.Vote.openStatLikedModal.bind(_this, _like_1) }, _.size(d.user_id_list) + ' other' + (_.size(d.user_id_list) > 1 ? 's' : '')));
                });
            }
            var toUrl_1 = null;
            var title_1 = _.get(d.data, 'title') || '';
            if (tgId == 2 || ((tgId == 4 || tgId == 5) && _.get(d.data, 'name') == 'vehicle')) {
                toUrl_1 = pages_1.pages.vehicle_detail.path + '/' + slugify_1.default(title_1) + '/' + _.get(d.data, 'location_name') + '/' + d.data._id;
            }
            else if (tgId == 3 || ((tgId == 4 || tgId == 5) && d.data && d.data.name == 'spotted')) {
                toUrl_1 = pages_1.pages.spotted_detail.path + '/' + slugify_1.default(title_1) + '/' + slugify_1.default(_.get(d.data, 'location_name') || '') + '/' + _.get(d.data, '_id');
            }
            nh = reactStringReplace(nh, '{title}', function (mt, i) {
                if (toUrl_1) {
                    return React.createElement(react_router_dom_1.Link, { to: toUrl_1, className: 'c-btn-link', key: mt + i }, title_1);
                }
                else {
                    return React.createElement("span", { key: mt + i }, title_1);
                }
            });
            nh = reactStringReplace(nh, '{name}', function (mt, i) {
                return React.createElement("span", { key: mt + i }, (d.data && d.data.name) ? d.data.name : '');
            });
            if (tgId == 2) {
                if (d.type_id == Cruz.Lookup.Notification.TypeEnum.Vehicle) {
                    var desList_1 = [];
                    if (d.data.vehicle.mileage && d.data.vehicle.mileage > 0) {
                        desList_1.push(d.data.vehicle.mileage.toLocaleString() + ' miles');
                    }
                    if (d.data.vehicle.sale_status_id && d.data.vehicle.sale_status_id > 0) {
                        var ss = _.find(lib_1.Lookup.saleStatus, { id: d.data.vehicle.sale_status_id });
                        if (d.data.vehicle.price && d.data.vehicle.price > 0) {
                            desList_1.push(ss.status + ': $' + d.data.vehicle.price.toLocaleString());
                        }
                        else {
                            desList_1.push(ss.status);
                        }
                    }
                    nh = reactStringReplace(nh, '{des}', function (mt, i) {
                        if (desList_1.length > 0) {
                            return React.createElement("span", { key: mt + i },
                                "(",
                                desList_1.join(', '),
                                ")");
                        }
                        else {
                            return '';
                        }
                    });
                }
                else if (d.type_id == Cruz.Lookup.Notification.TypeEnum.VehiclePriceUpdate) {
                    nh = reactStringReplace(nh, '{price}', function (mt, i) {
                        return React.createElement(react_router_dom_1.Link, { to: toUrl_1, key: mt + i, className: 'c-btn-link' },
                            "$",
                            d.data.vehicle.price.toLocaleString());
                    });
                }
                else if (d.type_id == Cruz.Lookup.Notification.TypeEnum.VehicleSaleStatusUpdate) {
                    nh = reactStringReplace(nh, '{sale_status}', function (mt, i) {
                        return React.createElement("span", { key: mt + i, className: 'c-font-weight-medium' },
                            _.find(lib_1.Lookup.saleStatus, { id: d.data.vehicle.sale_status_id }).status,
                            (d.data.vehicle.price ? React.createElement("span", { className: 'ml-2' },
                                "($",
                                d.data.vehicle.price.toLocaleString(),
                                ")") : ''));
                    });
                }
                else if (d.type_id == Cruz.Lookup.Notification.TypeEnum.VehicleMileageUpdate) {
                    nh = reactStringReplace(nh, '{mileage}', function (mt, i) {
                        return React.createElement("span", { key: mt + i, className: 'c-font-weight-medium' }, d.data.vehicle.mileage.toLocaleString());
                    });
                }
            }
            return nh;
        }
        catch (err) {
            return null;
        }
    }
    function init(callback) {
        lib_1.Auth.isAuthed(function (isAuthed) {
            if (isAuthed) {
                request.get(lib_1.Global.cruzApiBaseUrl + '/user/notification' + lib_1.util.buildApiQueryString(cfg.apiQueryDefault.notification))
                    .set(lib_1.Auth.authHeader())
                    .timeout(cfg.superagent.timeout.normal)
                    .end(function (err, apiRes) {
                    return callback(err, apiRes);
                });
            }
            else {
                lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
            }
        });
    }
    Notification.init = init;
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                is_loading: true,
                is_loading_more: false,
                result: {
                    data: [],
                    meta: {},
                    links: {}
                }
            };
            return _this;
        }
        Page.prototype.componentWillMount = function () {
            var _this = this;
            lib_1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    if (_this.props.staticContext) {
                        var d = _.assign({}, _this.props.staticContext);
                        _this.setState({ result: d.result, is_loading: false });
                    }
                    else {
                        var $initData_1 = document.querySelector('#_init_' + pages_1.pages.notification.id);
                        if ($initData_1 && $initData_1.getAttribute('data-init')) {
                            _this.setState({ result: JSON.parse(decodeURIComponent($initData_1.getAttribute('data-init'))), is_loading: false }, function () {
                                $initData_1.remove();
                            });
                        }
                        else {
                            _this.init();
                        }
                    }
                }
                else {
                    lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                }
            });
        };
        Page.prototype.componentDidMount = function () {
            document.title = lib_1.util.title(pages_1.pages.notification.title);
            lib_1.Global.saveUserSettings({ my_cruz_path: pages_1.pages.notification.path });
        };
        Page.prototype.componentWillReceiveProps = function (nextProps) {
            if (nextProps.history.action == 'PUSH') {
                this.init();
            }
        };
        Page.prototype.init = function () {
            var _this = this;
            this.setState({ is_loading: true }, function () {
                init(function (err, apiRes) {
                    if (err) {
                        lib_1.util.handleError(lib_1.Request.getApiErrorCode(err));
                    }
                    else {
                        _this.setState({ is_loading: false, result: apiRes.body });
                    }
                });
            });
        };
        Page.prototype.loadMore = function () {
            var _this = this;
            this.setState({ is_loading_more: true }, function () {
                lib_1.Auth.isAuthed(function (isAuthed) {
                    if (isAuthed) {
                        request.get(lib_1.Global.cruzApiBaseUrl + '/user/notification' + lib_1.util.buildApiQueryString(uri(_this.state.result.links.next).search(true)))
                            .set(lib_1.Auth.authHeader())
                            .timeout(cfg.superagent.timeout.normal)
                            .end(function (err, apiRes) {
                            if (err) {
                                lib_1.util.handleError(lib_1.Request.getApiErrorCode(err));
                            }
                            else {
                                var d = apiRes.body;
                                _this.setState({
                                    result: {
                                        links: d.links,
                                        meta: d.meta,
                                        data: _.union(_this.state.result.data, d.data)
                                    },
                                    is_loading_more: false
                                });
                            }
                        });
                    }
                    else {
                        lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                    }
                });
            });
        };
        Page.prototype.renderNotifyByUser = function (d) {
            if (_.isEmpty(d)) {
                return null;
            }
            else {
                var x = formatNotifyHTML(d);
                if (_.isEmpty(x)) {
                    return null;
                }
                else {
                    return (React.createElement("li", { key: d._id, className: 'list-group-item' },
                        React.createElement("div", { className: 'd-flex align-items-center' },
                            !_.isEmpty(d.user_display)
                                &&
                                    React.createElement("div", { className: 'mr-3' }, component_1.User.renderUserIcon(d.user_display)),
                            React.createElement("div", null,
                                formatNotifyHTML(d),
                                React.createElement("div", { className: 'c-notify-time', title: moment(d.date_update || d.date_create).format(cfg.format.datetime) }, moment(d.date_update || d.date_create).fromNow())))));
                }
            }
        };
        Page.prototype.renderNotifyList = function () {
            var _this = this;
            var ar = this.state.result;
            return ((ar.meta.total_records > 0)
                ?
                    React.createElement(React.Fragment, null,
                        React.createElement("ul", { className: 'list-group c-notif-list' }, _.map(ar.data, function (d, idx) {
                            if (d.user_id) {
                                return _this.renderNotifyByUser(d);
                            }
                        })),
                        (ar.links && ar.links.next)
                            &&
                                React.createElement("div", { className: 'text-center mt-4' },
                                    React.createElement("button", { type: 'button', className: 'btn c-btn-2-rev c-btn-round', onClick: function (e) { return _this.loadMore(); } },
                                        React.createElement("i", { className: 'far fa-angle-double-down' }),
                                        this.state.is_loading_more
                                            ?
                                                React.createElement(component_1.BtnWorking, { value: 'Loading More ...' })
                                            :
                                                React.createElement("span", null,
                                                    "Load More (",
                                                    ar.meta.total_records_left.toLocaleString() + ' left',
                                                    ")"))))
                :
                    React.createElement("div", { className: 'alert c-alert text-center mt-3' }, "No notification"));
        };
        Page.prototype.renderHeading = function () {
            var meta = this.state.result.meta;
            return (React.createElement("div", { className: 'row no-gutters mb-3' },
                React.createElement("div", { className: 'col-sm-7' },
                    React.createElement("h1", { className: 'c-title' },
                        pages_1.pages.notification.title,
                        !_.isEmpty(meta)
                            &&
                                React.createElement("span", { className: 'c-title-count' }, meta.total_records.toLocaleString()))),
                React.createElement("div", { className: 'col-sm-5 text-right' },
                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.notification_settings.path, className: 'btn c-btn-1 c-btn-s c-btn-round c-btn-block-sm mt-3 mt-sm-0' },
                        React.createElement("i", { className: 'fal fa-cog' }),
                        React.createElement("span", null, "Settings")))));
        };
        Page.prototype.render = function () {
            return (component_1.Layout.main(lib_1.Global.store.getState().user, null, React.createElement("div", { className: 'container c-container' }, this.state.is_loading
                ?
                    React.createElement(component_1.Loading, null)
                :
                    React.createElement(React.Fragment, null,
                        this.renderHeading(),
                        this.renderNotifyList()))));
        };
        return Page;
    }(React.Component));
    Notification.Page = Page;
})(Notification = exports.Notification || (exports.Notification = {}));
