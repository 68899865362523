"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var _ = require("lodash");
var request = require("superagent");
var Cruz = require("cruz");
var cfg = require("../../../cfg");
var lib_1 = require("../../lib");
var pages_1 = require("../../../routes/pages");
var component_1 = require("../../component");
var Registered;
(function (Registered) {
    var validate = require('validate.js');
    var rules = {
        'update_email': {
            presence: {
                message: '^Email is required.', allowEmpty: false
            },
            email: {
                message: '^Invalid email format.', allowEmpty: false
            }
        }
    };
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                user: {
                    _id: _this.props.match.params._id
                },
                email: null,
                update_email: null,
                is_loading: true,
                is_working: false,
                show_update_email: false,
                is_updating_email: false
            };
            return _this;
        }
        Page.prototype.componentDidMount = function () {
            var _this = this;
            if (lib_1.util.isClient()) {
                document.title = lib_1.util.title(pages_1.pages.registered.title);
                $('html, body').scrollTop(0);
            }
            lib_1.Request.user.profile(this.state.user._id, function (err, apiRes) {
                if (err) {
                    _this.setState({ is_loading: false });
                    if (err.status == 404) {
                        lib_1.Global.history.push(pages_1.pages.error.path + '/404');
                    }
                }
                else {
                    var user_1 = apiRes.body.data;
                    _this.setState({
                        user: user_1,
                        email: user_1.email,
                        update_email: user_1.email,
                        is_loading: false
                    }, function () {
                        if (user_1.status_id > 0) {
                            lib_1.Auth.redirectSignin(null);
                        }
                    });
                }
            });
        };
        Page.prototype.emailChanged = function (tgt) {
            var _this = this;
            this.setState({ update_email: tgt.value }, function () {
                _this.state.validation && _this.valid();
            });
        };
        Page.prototype.valid = function () {
            this.setState({ validation: validate(this.state, rules) });
        };
        Page.prototype.getError = function (key) {
            if (this.state.validation && this.state.validation[key]) {
                return this.state.validation[key][0];
            }
            else {
                return null;
            }
        };
        Page.prototype.updateEmail = function (e) {
            var _this = this;
            e && e.preventDefault();
            var vd = validate(this.state, rules);
            this.setState({ validation: vd });
            $('#_msg').empty();
            if (!this.state.is_updating_email && (vd == null || _.isEmpty(vd) == true)) {
                this.setState({ is_updating_email: true });
                request.put(lib_1.Global.cruzApiBaseUrl + '/user/email')
                    .timeout(cfg.superagent.timeout.normal)
                    .send({ _id: this.state.user._id, email: this.state.update_email }).end(function (err, apiRes) {
                    _this.setState({ is_updating_email: false });
                    if (err) {
                        var derr = apiRes.body.error;
                        if (derr.type == 'AccountDisabled') {
                            _this.setState({ user: { status_id: Cruz.Lookup.CruzUser.StatusEnum.Disabled } });
                        }
                        else if (derr.type == 'AccountClosed') {
                            _this.setState({ user: { status_id: Cruz.Lookup.CruzUser.StatusEnum.Closed } });
                        }
                        else {
                            lib_1.util.setToastMessage('error', apiRes.body.error.detail);
                        }
                    }
                    else {
                        lib_1.Global.onSubmitSuccess('updateEmail');
                        _this.setState({ email: _this.state.update_email, user: _.assign({}, _this.state.user, apiRes.body.data) });
                        lib_1.util.setToastMessage('success', 'Email has updated, please check your email for verification.');
                    }
                });
            }
        };
        Page.prototype.toggleUpdateEmailForm = function () {
            this.setState({ show_update_email: !this.state.show_update_email });
        };
        Page.prototype.renderUpdateEmailForm = function () {
            var _this = this;
            return (React.createElement("form", { className: 'mx-auto text-left', id: 'formUpdateEmail', onSubmit: function (e) { return _this.updateEmail(e); }, noValidate: true },
                React.createElement(component_1.Text, { id: 'email', type: 'email', label: 'Email', "data-err": this.getError('email'), required: true, value: this.state.update_email || '', onChange: function (e) { return _this.emailChanged(e.target); } }),
                React.createElement("div", { id: "_msg" }),
                React.createElement("button", { type: 'submit', className: 'btn c-btn-1 btn-block px-5 mt-4' }, this.state.is_updating_email ? React.createElement(component_1.BtnWorking, { value: 'Updating ...' }) : 'Update')));
        };
        Page.prototype.renderInactivate = function () {
            var _this = this;
            return (React.createElement("div", null,
                React.createElement("p", null,
                    "We have sent an email to ",
                    React.createElement("span", { className: 'c-highlight' }, this.state.email),
                    "."),
                React.createElement("p", null, "Please check your email & verify your account."),
                React.createElement("hr", { className: 'c-hr-1 w-50 mx-auto mt-4 mb-4' }),
                React.createElement("p", null, "Incorrect email address?"),
                this.state.show_update_email
                    ?
                        this.renderUpdateEmailForm()
                    :
                        React.createElement("button", { type: "button", className: "btn c-btn-1 c-btn-xs c-btn-round", onClick: function (e) { return _this.toggleUpdateEmailForm(); } }, "Update Email")));
        };
        Page.prototype.render = function () {
            return (component_1.Layout.entry(1, React.createElement("div", { className: 'mb30 text-center' },
                React.createElement("h1", { className: 'c-section-title' }, "You're almost there"),
                React.createElement("div", { className: 'mt-4' }, this.state.is_loading
                    ?
                        React.createElement(component_1.Loading, null)
                    :
                        this.state.user.status_id == Cruz.Lookup.CruzUser.StatusEnum.Disabled
                            ?
                                component_1.User.accountDisabled()
                            :
                                this.state.user.status_id == Cruz.Lookup.CruzUser.StatusEnum.Pending
                                    &&
                                        this.renderInactivate()))));
        };
        return Page;
    }(React.Component));
    Registered.Page = Page;
})(Registered = exports.Registered || (exports.Registered = {}));
