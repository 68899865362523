"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.referralPrize = true;
exports.cruzAscii = "\r\n" +
    "   _____ _____  _    _ ______\r\n" +
    "  / ____|  __ \\| |  | |___  /\r\n" +
    " | |    | |__) | |  | |  / /\r\n" +
    " | |    |  _  /| |  | | / /\r\n" +
    " | |____| | \\ \\| |__| |/ /___\r\n" +
    "  \\_____|_|  \\_\\\\_____/______|\r\n";
exports.cruz = {
    name: 'cruz.com'
};
exports.env = process.env.NODE_ENV;
exports.regionDeals = {
    siouxfalls: true,
    twincities: false,
};
exports.photoBaseUrl = {
    development: {
        s3: 'https://cruzcom.s3.amazonaws.com',
        cdn: 'https://cruzcom.s3.amazonaws.com'
    },
    staging: {
        s3: 'https://cruzcom.s3.amazonaws.com',
        cdn: 'https://cruzcom.s3.amazonaws.com'
    },
    production: {
        s3: 'https://cruzs3.s3.amazonaws.com',
        cdn: 'https://img.cruz.com'
    },
    stock: 'https://img.cruz.com/evox/'
};
exports.assetsCdn = 'https://d3rdub4fa9i2b.cloudfront.net/assets';
exports.webBaseUrl = {
    development: 'http://localhost:8600',
    staging: 'http://www.cruzcode.com:8600',
    production: 'https://siouxfalls.cruz.com'
};
exports.apiBaseUrl = {
    development: {
        cruz: 'http://localhost:8601',
        data: 'http://localhost:8605'
    },
    staging: {
        cruz: 'http://cruzcode.com:8601',
        data: 'http://data.cruz.local:8605',
    },
    production: {
        cruz: 'https://api.cruz.com',
        data: 'https://data.cruz.com'
    }
};
exports.socketUrl = {
    development: 'http://localhost:8300',
    staging: 'http://cruzcode.com:8300',
    production: 'https://socket.cruz.com'
};
exports.apiQueryDefault = {
    spotted: {
        page_size: 18,
        page_number: 1,
        sort: 1
    },
    vehicle: {
        page_size: 18,
        page_number: 1,
        sort: 1
    },
    saved_vehicle: {
        page_size: 60,
        page_number: 1,
        sort: 1
    },
    discussion: {
        page_size: 20,
        page_number: 1,
        sort: 1
    },
    discussion_vehicle: {
        page_size: 10,
        page_number: 1,
        sort: 1
    },
    my_referral: {
        page_size: 24,
        page_number: 1,
        sort: 1
    },
    my_service_quote: {
        page_size: 20,
        page_number: 1,
        sort: 1
    },
    follow: {
        page_size: 24,
        page_number: 1,
        sort: 1
    },
    deal: {
        page_size: 100,
        page_number: 1,
        sort: 1
    },
    notification: {
        page_size: 50,
        page_number: 1
    },
    chat_message: {
        page_size: 100
    }
};
exports.chatMessageViewLimit = 1000;
exports.commentPageSize = {
    spotted: 10,
    vehicle: 10,
    discussion: {
        search: 2,
        detail: 10
    },
    deal: 10
};
exports.myCruzLimit = {
    saved_vehicle: 60,
    saved_vehicle_filter: 12
};
exports.superagent = {
    timeout: {
        short: {
            deadline: 8000
        },
        normal: {
            deadline: 20000,
        },
        upload: {
            response: 1200000,
            deadline: 1200000,
        }
    }
};
exports.social = {
    facebook: 'https://www.facebook.com',
    twitter: 'https://www.twitter.com'
};
exports.shareUrl = {
    development: 'localhost:8600/register',
    staging: 'cruzcode.com/register',
    production: 'cruz.com/register'
};
exports.gtmKey = {
    development: 'GTM-MM9HHGS',
    staging: 'GTM-MM9HHGS',
    production: 'GTM-57DX3DV'
};
exports.format = {
    datetime: 'h:mm A, MMMM D, YYYY',
    date: 'dddd, MMMM D, YYYY',
    dateShort: 'MMMM D, YYYY',
    datetimeLong: 'h:mm A, dddd, MMMM D, YYYY',
    time: 'h:mm A'
};
exports.message = {
    forgotPasswordSent: 'Please check your email to reset your password.\r\nNote: The link will expire in 1 hour.',
    requestVerificationToken: 'Please check your email to verify your account.\r\nNote: The link will expire in 24 hours.',
    contactMessageSent: 'Thanks for contacting us! We will get back to you as soon as possible.'
};
exports.allowHtmlTags = '<p><b><strong><br><blockquote><code><pre><var><mark><del><s><ins><u><small><em><sup>';
exports.apiErrorKey = 'response.body.error.detail';
