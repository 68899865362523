"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var Redux = require("redux");
var react_router_dom_1 = require("react-router-dom");
var _ = require("lodash");
var uri = require("urijs");
var classNames = require("classnames");
var request = require("superagent");
var Cruz = require("cruz");
var cfg = require("../../../cfg");
var lib_1 = require("../../lib");
var pages_1 = require("../../../routes/pages");
var component_1 = require("../../component");
var spotted_1 = require("../../spotted");
var MySpotted;
(function (MySpotted) {
    function searchMySpotted(query, callback) {
        lib_1.Request.spotted.mySpotted(_.assign({}, cfg.apiQueryDefault.spotted, query), function (err, apiRes) {
            return callback(err, apiRes);
        });
    }
    MySpotted.searchMySpotted = searchMySpotted;
    var app = function (state, action) {
        function search() {
            searchMySpotted(state.query, function (err, apiRes) {
                if (err) {
                    lib_1.util.handleError(lib_1.Request.getApiErrorCode(err));
                }
                else {
                    MySpotted.store.dispatch({ type: 3, data: apiRes.body, isLoadMore: false });
                }
            });
        }
        function update(d) {
            if (!_.isEmpty(d)) {
                var x = _.find(state.result.data, { _id: d._id });
                if (!_.isEmpty(x)) {
                    if (x.post_status_id != d.post_status_id || x.is_disabled != d.is_disabled) {
                        request.get(lib_1.Global.cruzApiBaseUrl + '/user/my-spotted-statistics')
                            .set(lib_1.Auth.authHeader())
                            .timeout(cfg.superagent.timeout.normal)
                            .end(function (err, apiRes) {
                            if (err) {
                                MySpotted.store.dispatch({ type: 0, error: err });
                            }
                            else if (apiRes.body.data) {
                                MySpotted.store.dispatch({ type: 6, data: apiRes.body.data });
                            }
                        });
                    }
                    _.assign(x, d);
                }
            }
        }
        switch (action.type) {
            case 0: {
                state.is_loading = false;
                state.is_loading_more = false;
                state.is_filtering = false;
                lib_1.util.handleError(lib_1.Request.getApiErrorCode(action.error));
                return state;
            }
            case 1: {
                if (!state.is_loading) {
                    state.is_loading = true;
                    search();
                }
                return state;
            }
            case 7: {
                state.is_filtering = true;
                state.query = _.assign({}, cfg.apiQueryDefault.spotted, action.query);
                lib_1.Global.history.replace(lib_1.util.buildUrl(location.pathname, state.query));
                search();
                return state;
            }
            case 2: {
                action.event && action.event.preventDefault();
                if (!state.is_loading_more) {
                    state.is_loading_more = true;
                    searchMySpotted(_.assign({}, state.query, uri.parseQuery(new uri(state.result.links.next).query())), function (err, apiRes) {
                        if (err) {
                            MySpotted.store.dispatch({ type: 0, error: err });
                        }
                        else {
                            MySpotted.store.dispatch({ type: 3, data: apiRes.body, isLoadMore: true });
                        }
                    });
                }
                return state;
            }
            case 3: {
                state.is_loading = false;
                state.is_loading_more = false;
                state.is_filtering = false;
                var d = _.assign({}, action.data);
                if (d) {
                    state.result.links = _.assign({}, d.links);
                    state.result.meta = _.assign({}, d.meta);
                    state.result.data = (action.isLoadMore) ? _.union(state.result.data, d.data) : d.data;
                }
                var tgtIndex = d.meta.page_size * (d.meta.page_number - 1);
                if (tgtIndex > 0) {
                    lib_1.util.scrollTo('#_ss_' + tgtIndex, 70);
                }
                return state;
            }
            case 4: {
                update(action.data);
                spotted_1.SpottedSearch.store && spotted_1.SpottedSearch.store.dispatch({ type: 5, data: action.data });
                return state;
            }
            case 5: {
                update(action.data);
                return state;
            }
            case 6: {
                state.result.meta.statistics = _.assign({}, action.data);
                if (_.has(state.query, 'post_status_id')) {
                    var ps = _.find(lib_1.Lookup.postStatus, { id: state.query.post_status_id });
                    if (ps) {
                        var val = state.result.meta.statistics[ps.key];
                        if (val == 0) {
                            state.query = _.omit(state.query, 'post_status_id');
                            search();
                        }
                    }
                }
                return state;
            }
            case 8: {
                state = {
                    result: { data: [], meta: [], links: {} }
                };
                return state;
            }
            default:
                return state;
        }
    };
    MySpotted.store = null;
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            return _super.call(this, props) || this;
        }
        Page.prototype.componentWillReceiveProps = function (nextProps) {
            if (nextProps.history.action == 'PUSH') {
                MySpotted.store.dispatch({ type: 1 });
            }
        };
        Page.prototype.componentWillMount = function () {
            var _this = this;
            lib_1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    var locSearch = new uri(_this.props.location.search).search(true);
                    if (_this.props.staticContext) {
                        MySpotted.store = Redux.createStore(app, _.assign({}, _this.props.staticContext));
                    }
                    else if (MySpotted.store == null) {
                        var $initData = document.querySelector('#_init_' + pages_1.pages.my_spotted.id);
                        if ($initData && $initData.getAttribute('data-init')) {
                            MySpotted.store = Redux.createStore(app, _.assign({}, {
                                result: JSON.parse(decodeURIComponent($initData.getAttribute('data-init'))),
                                query: locSearch,
                                history: _this.props.history
                            }));
                            $initData.remove();
                        }
                        else {
                            var d = {
                                query: locSearch,
                                result: { data: [], meta: {}, links: {} },
                                is_loading: false
                            };
                            MySpotted.store = Redux.createStore(app, d);
                            MySpotted.store.dispatch({ type: 1 });
                        }
                    }
                    else if (['PUSH', 'REPLACE'].includes(_this.props.history.action)) {
                        MySpotted.store.dispatch({ type: 1 });
                    }
                    if (lib_1.util.isClient()) {
                        lib_1.Global.history.replace(lib_1.util.buildUrl(location.pathname, locSearch));
                    }
                    _this.unsubscribe = MySpotted.store.subscribe(function () { _this.forceUpdate(); });
                }
                else {
                    lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                }
            });
        };
        Page.prototype.componentDidMount = function () {
            document.title = lib_1.util.title(pages_1.pages.my_spotted.title);
            lib_1.Global.saveUserSettings({ my_cruz_path: pages_1.pages.my_spotted.path });
        };
        Page.prototype.componentWillUnmount = function () {
            this.unsubscribe && this.unsubscribe();
        };
        Page.prototype.renderHeading = function () {
            var meta = MySpotted.store.getState().result.meta;
            return (React.createElement("div", { className: 'mb-3 d-flex justify-content-between' },
                React.createElement("h1", { className: 'c-title' },
                    pages_1.pages.my_spotted.title,
                    meta.total_records > 0
                        &&
                            React.createElement("span", { className: 'c-title-count' },
                                meta.total_records_matched != meta.total_records
                                    &&
                                        meta.total_records_matched.toLocaleString() + '/',
                                meta.total_records.toLocaleString())),
                (!_.isEmpty(meta) && meta.total_records > 0)
                    &&
                        React.createElement(react_router_dom_1.Link, { to: pages_1.pages.spotted_add_edit.path, className: 'btn c-btn-1 c-btn-xs' },
                            React.createElement("i", { className: 'fal fa-plus' }),
                            React.createElement("span", null, "Post Spotted"))));
        };
        Page.prototype.renderPostFirstSpotted = function () {
            return (React.createElement("div", null,
                React.createElement(react_router_dom_1.Link, { to: pages_1.pages.spotted_add_edit.path, className: 'btn c-btn-1 c-btn-round mt-2' },
                    React.createElement("i", { className: 'fal fa-plus' }),
                    React.createElement("span", null, "Post Spotted"))));
        };
        Page.prototype.renderFilter = function () {
            var state = MySpotted.store.getState();
            var s = state.result.meta.statistics;
            var c = _.keys(_.omitBy(s, function (x) { return x == 0; }));
            if (_.size(c) > 1 && state.result.meta.total_records > 2) {
                return (React.createElement("div", { className: 'c-section-navbar mb-2 mt-3' },
                    React.createElement("ul", { className: 'list-inline c-btn-group-radio mb-1' },
                        React.createElement("li", { className: 'list-inline-item' },
                            React.createElement("input", { type: 'radio', name: 'filter', id: 'filter_0', checked: (state.query.post_status_id == null), onChange: function () { return MySpotted.store.dispatch({ type: 7, query: {} }); } }),
                            React.createElement("label", { htmlFor: 'filter_0' },
                                "All (",
                                state.result.meta.total_records.toLocaleString(),
                                ")")),
                        s.active > 0
                            &&
                                React.createElement("li", { className: 'list-inline-item' },
                                    React.createElement("input", { type: 'radio', name: 'filter', id: 'filter_1', className: classNames({ 'disabled': s.active == 0 }), checked: state.query.post_status_id == Cruz.Lookup.Other.PostStatusEnum.Active, onChange: function () { return MySpotted.store.dispatch({ type: 7, query: { post_status_id: Cruz.Lookup.Other.PostStatusEnum.Active } }); } }),
                                    React.createElement("label", { htmlFor: 'filter_1' },
                                        "Active (",
                                        s.active.toLocaleString(),
                                        ")")),
                        s.deleted > 0
                            &&
                                React.createElement("li", { className: 'list-inline-item' },
                                    React.createElement("input", { type: 'radio', name: 'filter', id: 'filter_2', className: classNames({ 'disabled': s.deleted == 0 }), checked: state.query.post_status_id == Cruz.Lookup.Other.PostStatusEnum.Delete, onChange: function () { return MySpotted.store.dispatch({ type: 7, query: { post_status_id: Cruz.Lookup.Other.PostStatusEnum.Delete } }); } }),
                                    React.createElement("label", { htmlFor: 'filter_2' },
                                        "Deleted (",
                                        s.deleted.toLocaleString(),
                                        ")")))));
            }
        };
        Page.prototype.onPostStatusUpdateDone = function (spotted) {
            MySpotted.store.dispatch({ type: 4, data: spotted });
        };
        Page.prototype.renderSpottedLit = function () {
            var _this = this;
            var result = MySpotted.store.getState().result;
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'row c-sm-gutters' }, _.map(result.data, function (spotted, idx) {
                    return (React.createElement("div", { className: 'col-xl-4 col-lg-6 col-md-6 mt-2 mb-2', key: spotted._id, id: '_ss_' + idx },
                        React.createElement(component_1.Spotted.SummaryCard, { spotted: spotted, showUserInfo: false, showEdit: true, onPostStatusUpdateDone: _this.onPostStatusUpdateDone, shouldRender: result.meta.page_number < 2 })));
                })),
                (!_.isEmpty(result.links) && result.links.next != null)
                    &&
                        React.createElement("section", { className: 'text-center mt-4' },
                            React.createElement("button", { className: 'btn c-btn-2-rev c-btn-round', onClick: function (e) { return MySpotted.store.dispatch({ type: 2, event: e }); } },
                                React.createElement("i", { className: 'far fa-angle-double-down' }),
                                MySpotted.store.getState().is_loading_more ? React.createElement(component_1.BtnWorking, { value: 'Loading More...' }) : React.createElement("span", null,
                                    "Load More (",
                                    result.meta.total_records_left.toLocaleString() + ' left',
                                    ")")))));
        };
        Page.prototype.render = function () {
            return (component_1.Layout.main(lib_1.Global.store.getState().user, null, React.createElement("div", { className: 'container c-container' }, (MySpotted.store == null)
                ?
                    React.createElement(component_1.Loading, null)
                :
                    React.createElement(React.Fragment, null,
                        this.renderHeading(),
                        MySpotted.store.getState().is_loading
                            ?
                                React.createElement(component_1.Loading, null)
                            :
                                React.createElement(React.Fragment, null,
                                    MySpotted.store.getState().result.meta.total_records > 0
                                        &&
                                            this.renderFilter(),
                                    MySpotted.store.getState().is_filtering
                                        ?
                                            React.createElement(component_1.Loading, { label: 'searching ...' })
                                        :
                                            MySpotted.store.getState().result.meta.total_records == 0
                                                ?
                                                    this.renderPostFirstSpotted()
                                                :
                                                    MySpotted.store.getState().result.meta.total_records_matched > 0
                                                        ?
                                                            this.renderSpottedLit()
                                                        :
                                                            MySpotted.store.getState().result.meta.total_records > 0
                                                                &&
                                                                    React.createElement("div", { className: 'mt-3' }, "No Spotted matched"))))));
        };
        return Page;
    }(React.Component));
    MySpotted.Page = Page;
})(MySpotted = exports.MySpotted || (exports.MySpotted = {}));
