"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var Redux = require("redux");
var request = require("superagent");
var _ = require("lodash");
var uri = require("urijs");
var async = require("async");
var moment = require("moment");
var Cruz = require("cruz");
var cfg = require("../../../cfg");
var component_1 = require("../../component");
var lib_1 = require("../../lib");
var DiscussionAddEdit;
(function (DiscussionAddEdit) {
    var initState = {
        feedInput: {
            title: '',
            content: '',
            tag_id_list: []
        },
        feed: {},
        target_vehicle: {
            vehicle_id: null,
            show_other: false
        },
        options: {
            tag_list: [],
            my_vehicle_list: [],
            body_type: [],
            make: [],
            model: [],
            year: _.range(moment().year() + 1, 1900, -1),
        },
        is_working: false,
        is_loading: true,
        validation: null
    };
    var validate = require('validate.js');
    var rules = {
        'title': {
            presence: { message: '^Title is required.', allowEmpty: false },
            length: {
                minimum: 5,
                tooShort: '^Invalid! At least 5 characters.'
            }
        }
    };
    var store = Redux.createStore(function (state, action) {
        function getInitData(callback) {
            var d = {
                options: {
                    tag_list: null,
                    my_vehicle_list: null,
                    year: state.options.year
                },
                feed: {}
            };
            async.parallel([
                function (next) {
                    request.get(lib_1.Global.cruzApiBaseUrl + '/lookup/community-tags')
                        .timeout(cfg.superagent.timeout.normal)
                        .retry(2)
                        .end(function (err, apiRes) {
                        if (err) {
                            return callback(err, null);
                        }
                        else {
                            d.options.tag_list = apiRes.body.data;
                            next();
                        }
                    });
                },
                function (next) {
                    request.get(lib_1.Global.cruzApiBaseUrl + '/vehicle?page_size=100&sort=' + Cruz.Lookup.Vehicle.SortEnum.Year + '&user_id=' + lib_1.Global.store.getState().user._id)
                        .timeout(cfg.superagent.timeout.normal)
                        .retry(2)
                        .end(function (err, apiRes) {
                        if (err) {
                            return callback(err, null);
                        }
                        else {
                            d.options.my_vehicle_list = apiRes.body.data;
                            next();
                        }
                    });
                },
                function (next) {
                    if (uri(location.search).hasQuery('_id')) {
                        request.get(lib_1.Global.cruzApiBaseUrl + '/community/' + uri.parseQuery(location.search)['_id'])
                            .set(lib_1.Auth.authHeader())
                            .retry(2)
                            .timeout(cfg.superagent.timeout.normal)
                            .end(function (err, apiRes) {
                            if (err) {
                                return callback(err, null);
                            }
                            else {
                                d.feed = _.assign({}, apiRes.body.data);
                                d.feedInput = _.assign({}, apiRes.body.data);
                                next();
                            }
                        });
                    }
                    else {
                        next();
                    }
                }
            ], function () {
                return callback(null, d);
            });
        }
        function getMakeOption() {
            lib_1.Request.dataone.makes(_.pick(store.getState().feedInput.vehicle, 'body_type_code'), function (err, apiRes) {
                err ? store.dispatch({ type: 0, error: apiRes.body.error }) : store.dispatch({ type: 14, data: apiRes.body.data });
            });
        }
        function getModelOption() {
            lib_1.Request.dataone.models(_.pick(store.getState().feedInput.vehicle, ['body_type_code', 'make']), function (err, apiRes) {
                err ? store.dispatch({ type: 0, error: apiRes.body.error }) : store.dispatch({ type: 15, data: apiRes.body.data });
            });
        }
        function initVehicleOption() {
            if (state.options == null || state.options.body_type == null || state.options.body_type.length == 0) {
                request.get(lib_1.Global.cruzApiBaseUrl + '/dataone/bodytypes')
                    .retry(2)
                    .timeout(cfg.superagent.timeout.normal)
                    .end(function (err, apiRes) {
                    err ? store.dispatch({ type: 0, error: apiRes.body.error }) : store.dispatch({ type: 13, data: apiRes.body.data });
                });
            }
            getMakeOption();
            if (state.feedInput.vehicle && state.feedInput.vehicle.make && state.feedInput.vehicle.make != '') {
                getModelOption();
            }
        }
        function valid() {
            store.getState().validation = validate(store.getState().feedInput, rules);
            return;
        }
        var tgt = action.target;
        switch (action.type) {
            case 0: {
                lib_1.util.setToastMessage('error', action.error.detail);
                state.is_working = false;
                return state;
            }
            case 1: {
                lib_1.Auth.isAuthed(function (isAuthed) {
                    if (isAuthed) {
                        getInitData(function (err, d) {
                            if (err) {
                                store.dispatch({ type: 0, error: err });
                            }
                            else {
                                store.dispatch({ type: 2, data: d });
                            }
                        });
                    }
                    else {
                        lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                    }
                });
                return state;
            }
            case 2: {
                state = _.assign({}, state, action.data, { is_loading: false });
                if (!_.isEmpty(state.feed)) {
                    state.feedInput = _.pick(state.feed, ['_id', 'title', 'content', 'tag_id_list', 'vehicle']);
                }
                if (_.isEmpty(state.feed.vehicle)) {
                    initVehicleOption();
                }
                else {
                    var v = state.feed.vehicle;
                    var vx = _.find(state.options.my_vehicle_list, { make: v.make, model: v.model, year: v.year });
                    if (vx) {
                        state.target_vehicle.vehicle_id = vx._id;
                    }
                    else {
                        state.target_vehicle.show_other = true;
                        initVehicleOption();
                    }
                }
                return state;
            }
            case 3: {
                state.feedInput.upload_file_list = action.data;
                return state;
            }
            case 5: {
                if (action.data) {
                    state.feed.photo_list = action.data;
                }
                state.feedInput.upload_file_list = [];
                state.is_working = false;
                state.executeUpload = false;
                if (state.feedInput._id && state.feedInput._id != '') {
                }
                else {
                }
                lib_1.util.setToastMessage('success', 'Successfully Saved');
                return state;
            }
            case 4: {
                lib_1.Auth.isAuthed(function (isAuthed) {
                    if (isAuthed) {
                        request.put(lib_1.Global.cruzApiBaseUrl + '/community/' + state.feed._id + '/photo')
                            .set(lib_1.Auth.authHeader())
                            .send(action.data)
                            .end(function (err, apiRes) {
                            if (err) {
                                store.dispatch({ type: 0, error: apiRes.body.error });
                            }
                            else {
                                store.dispatch({ type: 5, data: apiRes.body.data });
                            }
                        });
                    }
                    else {
                        lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                    }
                });
                return state;
            }
            case 6: {
                state.feedInput[tgt.id] = tgt.value;
                state.validation && valid();
                return state;
            }
            case 7: {
                if (state.feedInput.tag_id_list == null) {
                    state.feedInput.tag_id_list = [];
                }
                if (tgt.checked) {
                    state.feedInput.tag_id_list.push(Number(tgt.value));
                }
                else {
                    state.feedInput.tag_id_list = _.without(state.feedInput.tag_id_list, Number(tgt.value));
                }
                return state;
            }
            case 8: {
                if (tgt.checked) {
                    if (tgt.value && tgt.value != '') {
                        var v = _.find(state.options.my_vehicle_list, { _id: tgt.value });
                        if (v) {
                            state.feedInput.vehicle = _.pick(v, ['category_list', 'body_type_code', 'make', 'model', 'year']);
                        }
                        state.target_vehicle.show_other = false;
                    }
                    else {
                        state.feedInput.vehicle = {};
                        state.target_vehicle.show_other = true;
                        initVehicleOption();
                    }
                    state.target_vehicle.vehicle_id = tgt.value;
                }
                else {
                    state.feedInput.vehicle = {};
                    state.target_vehicle.show_other = false;
                    state.target_vehicle.vehicle_id = null;
                }
                return state;
            }
            case 13: {
                state.options.body_type = action.data;
                return state;
            }
            case 9: {
                if (tgt.value && tgt.value != '') {
                    state.feedInput.vehicle = { body_type_code: Number(tgt.value) };
                }
                else {
                    state.feedInput.vehicle = { body_type_code: null };
                }
                getMakeOption();
                return state;
            }
            case 14: {
                state.options.make = action.data;
                return state;
            }
            case 10: {
                state.feedInput.vehicle = _.assign({}, _.pick(state.feedInput.vehicle, 'body_type_code'), { make: tgt.value });
                if (tgt.value && tgt.value != '') {
                    getModelOption();
                }
                else {
                    state.options.model = [];
                }
                return state;
            }
            case 15: {
                state.options.model = action.data;
                return state;
            }
            case 11: {
                state.feedInput.vehicle = _.assign({}, _.pick(state.feedInput.vehicle, ['body_type_code', 'make']), { model: tgt.value });
                return state;
            }
            case 16: {
                state.options.year = action.data;
                return state;
            }
            case 12: {
                if (tgt.value && tgt.value != '') {
                    state.feedInput.vehicle.year = Number(tgt.value);
                }
                else {
                    state.feedInput.vehicle = _.omit(state.feedInput.vehicle, 'year');
                }
                return state;
            }
            case 17: {
                action.event && action.event.preventDefault();
                valid();
                if (!state.is_working && (state.validation == null || _.isEmpty(state.validation) == true)) {
                    lib_1.Auth.isAuthed(function (isAuthed) {
                        if (isAuthed) {
                            state.is_working = true;
                            var requestFeed = void 0;
                            var isUpdate_1 = false;
                            if (state.feed._id && state.feed._id != '') {
                                isUpdate_1 = true;
                                requestFeed = request.put(lib_1.Global.cruzApiBaseUrl + '/community/' + state.feed._id);
                            }
                            else {
                                requestFeed = request.post(lib_1.Global.cruzApiBaseUrl + '/community');
                            }
                            requestFeed
                                .set(lib_1.Auth.authHeader())
                                .timeout({ deadline: 10000, response: 5000 })
                                .send(_.omit(state.feedInput, 'file_list'))
                                .end(function (err, apiRes) {
                                if (err) {
                                    store.dispatch({ type: 0, error: apiRes.body.error });
                                }
                                else {
                                    lib_1.Global.onSubmitSuccess('discussion');
                                    store.dispatch({ type: (isUpdate_1 ? 18 : 19), data: apiRes.body.data });
                                }
                            });
                        }
                        else {
                            lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                        }
                    });
                }
                return state;
            }
            case 19: {
                state.feed = _.assign({}, action.data);
                if (state.feedInput.upload_file_list && state.feedInput.upload_file_list.length > 0) {
                    state.executeUpload = true;
                }
                else {
                    state.is_working = false;
                    state.feedInput._id = state.feed._id;
                    lib_1.util.setToastMessage('success', 'Successfully Saved');
                }
                return state;
            }
            case 18: {
                state.feed = _.assign({}, state.feed, action.data);
                if (state.feedInput.upload_file_list && state.feedInput.upload_file_list.length > 0) {
                    state.executeUpload = true;
                }
                else {
                    lib_1.util.setToastMessage('success', 'Successfully Saved');
                    state.is_working = false;
                }
                return state;
            }
            case 21: {
                state = JSON.parse(JSON.stringify(initState));
                return state;
            }
            default: {
                return state;
            }
        }
    }, JSON.parse(JSON.stringify(initState)));
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            return _super.call(this, props) || this;
        }
        Page.prototype.componentWillMount = function () {
            lib_1.Auth.isAuthed(function (isAuthed) {
                if (!isAuthed) {
                    lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                }
            });
        };
        Page.prototype.componentDidMount = function () {
            var _this = this;
            this.unsubscribe = store.subscribe(function () { _this.forceUpdate(); });
            var locSearch = new uri(this.props.location.search).search(true);
            if (locSearch._id && locSearch._id != '') {
                document.title = lib_1.util.title('Edit Discussion Feed');
            }
            else {
                document.title = lib_1.util.title('Post Discussion Feed');
            }
            store.dispatch({ type: 1 });
        };
        Page.prototype.componentWillUnmount = function () {
            store.dispatch({ type: 21 });
            this.unsubscribe && this.unsubscribe();
        };
        Page.prototype.getError = function (key, isServer) {
            return ((store.getState().validation && store.getState().validation[key]) ? store.getState().validation[key][0] : null);
        };
        Page.prototype.renderForm = function () {
            var d = store.getState().feed;
            var dInput = store.getState().feedInput;
            var options = store.getState().options;
            return (React.createElement("form", { id: 'formDiscussion', onSubmit: function (e) { return store.dispatch({ type: 17, event: e }); }, noValidate: true },
                React.createElement("div", { className: 'card card-form mt-3' },
                    React.createElement("div", { className: 'card-body' },
                        React.createElement("div", { className: 'row' },
                            React.createElement("div", { className: 'col-md-6' },
                                React.createElement(component_1.Text, { id: 'title', label: 'Title', required: true, placeholder: 'What do you want to talk about?', maxLength: 100, value: dInput.title || '', "data-err": this.getError('title'), onChange: function (e) { return store.dispatch({ type: 6, target: e.target }); } }),
                                React.createElement(component_1.Textarea, { id: 'content', label: 'Content', placeholder: 'Tell us about your questions, comments, details, etc.', className: 'textarea-lg', value: dInput.content || '', maxLength: 5000, minRows: 3, maxRows: 20, onChange: function (e) { return store.dispatch({ type: 6, target: e.target }); } })),
                            React.createElement("div", { className: 'col-md-6' }, React.createElement(component_1.Photo.PhotoEdit, __assign({ blur_lp: true, api_url: d._id ? lib_1.Global.cruzApiBaseUrl + '/community/' + d._id + '/photo' : '' }, { photo_list: d.photo_list, executeUpload: store.getState().executeUpload }, { _id: d ? d._id : null, photo_list_classNames: 'col-xl-4 col-md-3 col-sm-4 col-xs-6', photo_base_url: d._id ? cfg.photoBaseUrl[cfg.env].s3 + '/community/' + d.group + '/' + d._id : '', onUploadFileChanged: function (file_list) { return store.dispatch({ type: 3, data: file_list }); }, onUploadFileDone: function (photo_list) { return store.dispatch({ type: 5, data: photo_list }); }, onDeletePhotoDone: function (photo_list) { return store.dispatch({ type: 5, data: photo_list }); }, onSortPhoto: function (ids) { return store.dispatch({ type: 4, data: ids }); } })))),
                        (options.my_vehicle_list && options.my_vehicle_list.length > 0)
                            &&
                                React.createElement("div", { className: 'form-group' },
                                    React.createElement("hr", { className: 'c-hr' }),
                                    React.createElement("label", null,
                                        "What is this post for? Choose from your vehicle or choose other.",
                                        React.createElement("span", { className: 'c-font-size-xs ml-2' }, "(optional)")),
                                    React.createElement("ul", { className: 'list-inline mt-1' },
                                        _.map(options.my_vehicle_list, function (v) {
                                            return (React.createElement("li", { key: v._id, className: 'list-inline-item c-btn-checkbox' },
                                                React.createElement("input", { type: 'checkbox', autoComplete: 'off', name: 'vehicle', id: v._id, value: v._id, checked: v._id == store.getState().target_vehicle.vehicle_id, onChange: function (e) { return store.dispatch({ type: 8, target: e.target }); } }),
                                                React.createElement("label", { htmlFor: v._id }, component_1.Vehicle.detailTitle(v, false))));
                                        }),
                                        React.createElement("li", { className: 'list-inline-item c-btn-checkbox' },
                                            React.createElement("input", { type: 'checkbox', autoComplete: 'off', name: 'vehicle', value: '', id: 'v_o', checked: store.getState().target_vehicle.show_other || false, onChange: function (e) { return store.dispatch({ type: 8, target: e.target }); } }),
                                            React.createElement("label", { htmlFor: 'v_o' }, "Other ...")))),
                        (store.getState().target_vehicle.show_other)
                            &&
                                React.createElement("div", { className: 'row' },
                                    React.createElement(component_1.Select, { id: 'body_type_code', label: 'Type', "data-grid": 'col-md-3 col-sm-6', size: 1, options: options.body_type && lib_1.util.mapBodyTypeOptions(options.body_type, false), value: (dInput.vehicle && dInput.vehicle.body_type_code) ? dInput.vehicle.body_type_code.toString() : '', onChange: function (e) { return store.dispatch({ type: 9, target: e.target }); } }),
                                    React.createElement(component_1.Select, { id: 'make', label: 'Make', "data-grid": 'col-md-3 col-sm-6', size: 1, options: lib_1.util.mapOptions('make', options.make, { name: 'Any' }), value: (dInput.vehicle && dInput.vehicle.make) ? dInput.vehicle.make : '', onChange: function (e) { return store.dispatch({ type: 10, target: e.target }); } }),
                                    React.createElement(component_1.Select, { id: 'model', label: 'Model', "data-grid": 'col-md-3 col-sm-6', size: 1, options: lib_1.util.mapOptions('model', options.model, { name: 'Any' }), value: (dInput.vehicle && dInput.vehicle.model) ? dInput.vehicle.model : '', onChange: function (e) { return store.dispatch({ type: 11, target: e.target }); } }),
                                    React.createElement(component_1.Select, { id: 'year', label: 'Year', "data-grid": 'col-md-3 col-sm-6', size: 1, options: lib_1.util.mapOptions('year', options.year, { name: 'Any' }), value: (dInput.vehicle && dInput.vehicle.year) ? dInput.vehicle.year.toString() : '', onChange: function (e) { return store.dispatch({ type: 12, target: e.target }); } })),
                        (options && !_.isEmpty(options.tag_list)) &&
                            React.createElement("div", { className: 'form-group' },
                                React.createElement("hr", { className: 'c-hr' }),
                                React.createElement("label", null,
                                    "What is this post about? You can select more than one.",
                                    React.createElement("span", { className: 'c-font-size-xs ml-2' }, "(optional)")),
                                React.createElement("ul", { className: 'list-inline mt-2' }, _.map(options.tag_list, function (cTag) {
                                    return (React.createElement("li", { key: 'ctag_' + cTag.id, className: 'list-inline-item c-btn-checkbox' },
                                        React.createElement("input", { type: 'checkbox', autoComplete: 'off', id: 'ctag_' + cTag.id, value: cTag.id, checked: _.indexOf(dInput.tag_id_list, cTag.id) > -1, onChange: function (e) { return store.dispatch({ type: 7, target: e.target }); } }),
                                        React.createElement("label", { htmlFor: 'ctag_' + cTag.id }, cTag.name)));
                                })))),
                    React.createElement("div", { className: 'card-footer text-right' },
                        React.createElement("button", { type: 'submit', className: 'btn c-btn-1' }, store.getState().is_working
                            ?
                                React.createElement(component_1.BtnWorking, { value: 'Saving ...' })
                            :
                                'Save')))));
        };
        Page.prototype.render = function () {
            return (component_1.Layout.main(lib_1.Global.store.getState().user, null, React.createElement("div", { className: 'container c-container' }, store.getState().is_loading
                ?
                    React.createElement(component_1.Loading, null)
                :
                    React.createElement("div", null,
                        React.createElement("h1", { className: 'c-title' }, (store.getState().feed && store.getState().feed._id)
                            ?
                                'Edit Discussion Feed'
                            :
                                'Post Discussion Feed'),
                        this.renderForm()))));
        };
        return Page;
    }(React.Component));
    DiscussionAddEdit.Page = Page;
})(DiscussionAddEdit = exports.DiscussionAddEdit || (exports.DiscussionAddEdit = {}));
