"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var _ = require("lodash");
var moment = require("moment");
var request = require("superagent");
var classNames = require("classnames");
var numeral = require("numeral");
var slugify_1 = require("slugify");
var Cruz = require("cruz");
var pages_1 = require("../../routes/pages");
var component_1 = require("../component");
var _1 = require(".");
var lib_1 = require("../lib");
var cfg = require("../../cfg");
var ServiceQuoteDetail;
(function (ServiceQuoteDetail) {
    var validate = require('validate.js');
    var rules = {
        cancel_reason: {
            presence: { message: '^This is required', allowEmpty: false }
        }
    };
    var CancelQuoteModal = (function (_super) {
        __extends(CancelQuoteModal, _super);
        function CancelQuoteModal(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                cancel_reason: null
            };
            return _this;
        }
        CancelQuoteModal.prototype.componentDidMount = function () {
            var _this = this;
            $('#_cancelQuote').modal('show').on('hidden.bs.modal', function (e) {
                lib_1.util.removeModal(_this);
            });
        };
        CancelQuoteModal.prototype.onYes = function () {
            var _this = this;
            this.valid(function () {
                if (_.isEmpty(_this.state.validation)) {
                    _this.props.onYes(_this.state.cancel_reason);
                    $('#_cancelQuote').modal('hide');
                }
            });
        };
        CancelQuoteModal.prototype.getError = function (key) {
            return (_.first(_.get(this.state.validation, key)));
        };
        CancelQuoteModal.prototype.valid = function (callback) {
            this.setState({ validation: validate(this.state, rules) }, function () { return callback(); });
        };
        CancelQuoteModal.prototype.cancelReasonChanged = function (e) {
            var _this = this;
            this.setState({ cancel_reason: e.target.value }, function () {
                if (!_.isEmpty(_this.state.validation)) {
                    _this.valid(function () { });
                }
            });
        };
        CancelQuoteModal.prototype.render = function () {
            var _this = this;
            try {
                return (React.createElement("div", { className: 'modal', id: '_cancelQuote', tabIndex: -1, role: 'dialog', "data-backdrop": 'static', "data-keyboard": false },
                    React.createElement("div", { className: 'modal-dialog', role: 'document' },
                        React.createElement("div", { className: 'modal-content' },
                            React.createElement("div", { className: 'modal-header' },
                                React.createElement("h2", { className: 'modal-title' }, "Cancel Service Quote"),
                                React.createElement("button", { type: 'button', "data-dismiss": 'modal', className: 'close' })),
                            React.createElement("div", { className: 'modal-body' },
                                React.createElement(component_1.Textarea, { id: 'cancel_reason', label: 'Why you want to cancel it?', className: 'textarea-lg', required: true, "data-err": this.getError('cancel_reason'), minRows: 3, maxRows: 10, value: this.state.cancel_reason || '', onChange: function (e) { return _this.cancelReasonChanged(e); } })),
                            React.createElement("div", { className: 'modal-footer' },
                                React.createElement("button", { type: 'button', className: 'btn c-btn-xs c-btn-3 mr-1', "data-dismiss": 'modal' }, "Close"),
                                React.createElement("button", { type: 'button', className: 'btn c-btn-xs c-btn-2 mr-2', onClick: this.onYes.bind(this) }, "Cancel This Request"))))));
            }
            catch (err) {
                lib_1.util.logError(err, null);
                return null;
            }
        };
        return CancelQuoteModal;
    }(React.Component));
    ServiceQuoteDetail.CancelQuoteModal = CancelQuoteModal;
    function getServiceQuoteDetail(_id, callback) {
        request.get(lib_1.Global.cruzApiBaseUrl + '/user/service-quote/' + _id).set(lib_1.Auth.authHeader()).end(function (err, apiRes) {
            return callback(err, _.get(apiRes.body, 'data'));
        });
    }
    ServiceQuoteDetail.getServiceQuoteDetail = getServiceQuoteDetail;
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                _id: _this.props.match.params._id,
                is_loading: false,
                is_ordering: false,
                is_cancelling: false,
                quote: {},
                order: {},
                quote_result_list: [],
                quote_question_list: [],
                show_qa: false,
                show_amenity: false,
                show_description: false
            };
            return _this;
        }
        Page.prototype.componentWillMount = function () {
            var _this = this;
            lib_1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    try {
                        if (_this.props.staticContext) {
                            var d = JSON.parse(JSON.stringify(_this.props.staticContext));
                            _this.setState(__assign(__assign({}, d.result), { is_loading: false }));
                        }
                        else {
                            var $initData = document.querySelector('#_init_' + pages_1.pages.service_quote_detail.id);
                            if ($initData && $initData.getAttribute('data-init')) {
                                var d = JSON.parse(decodeURIComponent($initData.getAttribute('data-init')));
                                $initData.remove();
                                _this.setState(__assign(__assign({}, d), { is_loading: false }));
                            }
                            else {
                                _this.init();
                            }
                        }
                    }
                    catch (err) {
                    }
                }
                else {
                    lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                }
            });
        };
        Page.prototype.componentDidMount = function () {
            document.title = lib_1.util.title(pages_1.pages.service_quote_detail.title);
        };
        Page.prototype.init = function () {
            var _this = this;
            lib_1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    _this.setState({ is_loading: true }, function () {
                        getServiceQuoteDetail(_this.state._id, function (err, result) {
                            if (err) {
                                _this.setState({ is_loading: false });
                                lib_1.util.handleError(lib_1.Request.getApiErrorCode(err));
                            }
                            else {
                                _this.setState(__assign(__assign(__assign({}, _this.state), result), { is_loading: false }));
                            }
                        });
                    });
                }
                else {
                    lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                }
            });
        };
        Page.prototype.openCancelDialog = function () {
            lib_1.util.setModal(null, false, true, React.createElement(CancelQuoteModal, { onYes: this.onCancelConfirm.bind(this) }));
        };
        Page.prototype.onCancelConfirm = function (cancel_reason) {
            var _this = this;
            if (!this.state.is_cancelling) {
                lib_1.Auth.isAuthed(function (isAuthed) {
                    if (isAuthed) {
                        _this.setState({ is_cancelling: true });
                        request.patch(lib_1.Global.cruzApiBaseUrl + '/user/service-quote/' + _this.state._id + '/cancel')
                            .set(lib_1.Auth.authHeader())
                            .send({ cancel_reason: cancel_reason })
                            .end(function (err, apiRes) {
                            _this.setState({ is_cancelling: false });
                            if (!err && _.get(apiRes.body, 'data')) {
                                _this.setState({ quote: _.assign(apiRes.body.data) });
                                if (_1.MyServiceQuote && _1.MyServiceQuote.store) {
                                    _1.MyServiceQuote.store.dispatch({ type: 4, data: apiRes.body.data });
                                }
                            }
                        });
                    }
                    else {
                        lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                    }
                });
            }
        };
        Page.prototype.orderItem = function (result_id, result_item_id) {
            var _this = this;
            lib_1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    _this.setState({
                        is_ordering: true,
                        order: { result_id: result_id, result_item_id: result_item_id }
                    }, function () {
                        request.post(lib_1.Global.cruzApiBaseUrl + '/user/service-quote/' + _this.state._id + '/order').set(lib_1.Auth.authHeader()).send(_this.state.order).end(function (err, apiRes) {
                            if (err) {
                                _this.setState({ is_ordering: false });
                                lib_1.util.setToastMessage('error', _.get(err, 'response.body.error.detail'));
                            }
                            else {
                                _this.setState({ is_ordering: false, quote: apiRes.body.data, order: {} });
                            }
                        });
                    });
                }
                else {
                    lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                }
            });
        };
        Page.prototype.renderHeading = function () {
            var quote = this.state.quote;
            var v = quote.vehicle_display;
            return (React.createElement("div", { className: 'c-detail-heading d-flex flex-column flex-sm-row justify-content-between align-items-sm-end' },
                React.createElement("div", null,
                    React.createElement("h1", { className: 'title' }, quote.service.name),
                    React.createElement("div", null,
                        React.createElement(react_router_dom_1.Link, { to: component_1.Vehicle.detailUrl(v), className: 'c-btn-link' }, component_1.Vehicle.detailTitle(v, false))),
                    React.createElement("div", { className: 'c-font-size-xs mt-1' },
                        React.createElement("span", { className: 'd-block d-md-inline' },
                            React.createElement("i", { className: 'fal fa-clock fa-fw mr-1' }),
                            React.createElement("span", null, 'Requested ' + moment(quote.date_create).fromNow())))),
                React.createElement("div", { className: 'mt-3 mt-sm-0 d-flex ml-sm-auto align-items-end' },
                    React.createElement(component_1.Support.Phone, null))));
        };
        Page.prototype.renderSchedule = function () {
            if (_.isEmpty(_.get(this.state.quote, 'schedule.date')) || this.state.quote.status_id == Cruz.Lookup.Service.QuoteStatusEnum.Scheduling) {
                return null;
            }
            else {
                return (React.createElement("div", { className: 'mt-1 mb-2 d-flex align-items-center' },
                    React.createElement("i", { className: 'far fa-calendar-check fa-lg mr-3' }),
                    React.createElement("div", { className: 'c-font-weight-medium' }, moment(this.state.quote.schedule.date, 'MM/DD/YYYY HH:mm').format(cfg.format.datetimeLong))));
            }
        };
        Page.prototype.renderStatus = function () {
            var _this = this;
            try {
                var status_1 = _.find(lib_1.Lookup.service_quote_status_list, { id: this.state.quote.status_id });
                var log = _.find(this.state.quote.log, { status_id: status_1.id });
                return (React.createElement("div", { className: 'row' },
                    React.createElement("div", { className: 'col-md-9' },
                        React.createElement("div", { className: 'd-flex' },
                            React.createElement("i", { className: classNames('fal fa-2x mr-3 mt-1', status_1.fa_icon || 'fa-info-circle') }),
                            React.createElement("div", null,
                                React.createElement("div", { className: 'c-font-size-l c-font-weight-medium' }, status_1.status),
                                !_.isEmpty(log)
                                    ?
                                        React.createElement("div", { className: 'c-font-size-xs' },
                                            React.createElement("i", { className: 'fal fa-clock mr-2' }),
                                            React.createElement("span", null, moment(log.date_create).fromNow()))
                                    :
                                        status_1.id == Cruz.Lookup.Service.QuoteStatusEnum.InProgress
                                            &&
                                                React.createElement("div", { className: 'c-font-size-xs' },
                                                    React.createElement("i", { className: 'fal fa-clock mr-2' }),
                                                    React.createElement("span", null, moment(this.state.quote.date_create).fromNow())),
                                !_.isEmpty(status_1.description)
                                    &&
                                        React.createElement("div", { className: 'mt-1' }, status_1.description),
                                status_1.id >= Cruz.Lookup.Service.QuoteStatusEnum.ReadyForService
                                    &&
                                        React.createElement(react_router_dom_1.Link, { to: pages_1.pages.service_voucher.path + '/' + slugify_1.default(this.state.quote.service.name) + '/' + this.state.quote._id, className: 'btn c-btn-1 c-btn-xs my-2' },
                                            React.createElement("i", { className: 'fal fa-file' }),
                                            React.createElement("span", null, "Voucher"))))),
                    React.createElement("div", { className: 'col-md-3 text-md-right' }, !_.includes([Cruz.Lookup.Service.QuoteStatusEnum.Cancelled, Cruz.Lookup.Service.QuoteStatusEnum.RequestCancellation, Cruz.Lookup.Service.QuoteStatusEnum.Completed], status_1.id)
                        &&
                            React.createElement("button", { type: 'button', className: 'btn c-btn-2 c-btn-xs mt-4 mb-2 mt-md-0 mb-md-0', onClick: function () { return _this.openCancelDialog(); } },
                                React.createElement("i", { className: 'fal fa-ban' }),
                                this.state.is_cancelling ? React.createElement(component_1.BtnWorking, { value: 'Cancelling ...' }) : React.createElement("span", null, "Cancel")))));
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.renderCruzDiscount = function () {
            var cruz_discount_amount = this.getCruzDiscountAmount();
            if (cruz_discount_amount > 0) {
                var cd = _.get(this.state.quote, 'cruz_discount');
                return (React.createElement(React.Fragment, null,
                    React.createElement("hr", null),
                    React.createElement("div", { className: 'my-3 d-flex align-items-center' },
                        React.createElement("i", { className: 'fa fa-gift mr-3 c-font-color-2 fa-2x' }),
                        React.createElement("div", null,
                            React.createElement("span", { className: 'c-font-weight-medium' }, "Cruz discount"),
                            " ",
                            React.createElement("span", { className: 'c-font-color-2 c-font-weight-semi-bold c-font-size-l' }, numeral(cd.amount).format('$0,0[.]00')),
                            " applied at checkout.",
                            (!_.isEmpty(cd.date_valid_to) && _.isEmpty(this.state.quote.charge))
                                &&
                                    React.createElement("div", null,
                                        "Discount valid before ",
                                        React.createElement("span", { className: 'c-font-weight-medium' }, moment(cd.date_valid_to, 'MM/DD/YYYY').format(cfg.format.date)),
                                        ".")))));
            }
            else {
                return null;
            }
        };
        Page.prototype.renderLocation = function (loc) {
            try {
                var showMapLink = (!_.isEmpty(loc.formatted_address) || !_.isEmpty(loc.address1));
                return (React.createElement(React.Fragment, null,
                    showMapLink
                        ?
                            React.createElement("a", { href: 'https://www.google.com/maps/search/' + (!_.isEmpty(loc.address1) ? (loc.address1 + ', ') : '') + loc.city + ', ' + (loc.state || loc.state_abbr), className: 'c-btn-link', target: '_blank' },
                                loc.address1,
                                React.createElement("span", { className: 'd-inline d-lg-none' }, ", "),
                                React.createElement("span", { className: 'd-none d-lg-block' }),
                                loc.city + ', ' + loc.state_abbr + ' ' + loc.zip_code)
                        :
                            React.createElement("div", null, loc.city + ', ' + loc.state_abbr + ' ' + loc.zip_code),
                    !_.isEmpty(loc.phone) &&
                        React.createElement("a", { href: 'tel:' + loc.phone, className: 'c-btn-link', target: '_blank' }, loc.phone)));
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.renderLocationList = function (loc_list) {
            var _this = this;
            return (React.createElement(React.Fragment, null,
                _.size(loc_list) == 1
                    &&
                        React.createElement(React.Fragment, null,
                            React.createElement("hr", { className: 'd-none d-lg-block' }),
                            this.renderLocation(_.first(loc_list))),
                _.size(loc_list) > 1
                    &&
                        React.createElement("div", { className: 'pt-2' },
                            React.createElement("ul", { className: 'list-group' }, _.map(loc_list, function (loc) {
                                return (React.createElement("li", { key: loc._id, className: 'list-group-item' }, _this.renderLocation(loc)));
                            })))));
        };
        Page.prototype.renderItemDetail = function (item) {
            numeral.zeroFormat('-');
            numeral.nullFormat('-');
            return (React.createElement("div", { className: 'row' },
                React.createElement("div", { className: 'col-4' },
                    React.createElement("label", { className: 'sq-label' }, "Estimated Hours"),
                    React.createElement("div", { className: 'sq-value' }, (lib_1.util.isNumeric(item.estimate_hours) ? item.estimate_hours : '-'))),
                React.createElement("div", { className: 'col-2' },
                    React.createElement("label", { className: 'sq-label' }, "Parts"),
                    React.createElement("div", { className: 'sq-value' }, numeral(_.get(item.price, 'parts')).format('$0,0[.]00'))),
                React.createElement("div", { className: 'col-3' },
                    React.createElement("label", { className: 'sq-label' }, "Installation"),
                    React.createElement("div", { className: 'sq-value' }, numeral(_.get(item.price, 'installation')).format('$0,0[.]00'))),
                React.createElement("div", { className: 'col-3' },
                    React.createElement("label", { className: 'sq-label' }, "Discount"),
                    React.createElement("div", { className: 'sq-value' }, numeral(_.get(item.price, 'discount')).format('$0,0[.]00')))));
        };
        Page.prototype.renderItemDescriptionAndWarranty = function (item) {
            if (!_.isEmpty(item.description) || !_.isEmpty(item.warranty)) {
                React.createElement("div", { className: 'pt-3' },
                    !_.isEmpty(item.description)
                        &&
                            React.createElement("div", { className: 'd-flex flex-column flex-sm-row' },
                                React.createElement("div", { className: 'mr-3 sq-des-label' }, "Description:"),
                                React.createElement("div", null, lib_1.util.text2html(item.description))),
                    !_.isEmpty(item.warranty)
                        &&
                            React.createElement("div", { className: 'd-flex flex-column flex-sm-row' },
                                React.createElement("div", { className: 'mr-3 sq-des-label' }, "Warranty:"),
                                React.createElement("div", null, lib_1.util.text2html(item.warranty))));
            }
            else {
                return null;
            }
        };
        Page.prototype.renderOrderAndCharge = function () {
            var order = this.state.quote.order;
            var charge = this.state.quote.charge;
            var qr = _.find(this.state.quote_result_list, { _id: order.result_id });
            var item = _.find(qr.item_list, { id: order.result_item_id });
            var vd = qr.vendor_display;
            return (React.createElement("div", { className: 'mt-3 px-4 py-3 border rounded service-quote-result' },
                React.createElement("div", { className: 'row' },
                    React.createElement("div", { className: 'col-lg-5' },
                        React.createElement("div", { className: 'c-font-size-l c-font-weight-medium' }, vd.name),
                        !_.isEmpty(vd.web_site)
                            &&
                                React.createElement("div", { className: 'mb-2' },
                                    React.createElement("a", { href: vd.web_site, target: '_blank', rel: 'noopener noreferrer', className: 'c-btn-link' }, vd.web_site)),
                        (_.isBoolean(qr.require_appointment) && !qr.require_appointment)
                            ?
                                React.createElement("div", { className: 'mb-1' },
                                    React.createElement("i", { className: 'far fa-calendar-times mr-2' }),
                                    "No Appointment Needed")
                            :
                                this.renderSchedule(),
                        !_.isEmpty(qr.reference)
                            &&
                                React.createElement("div", { className: 'mb-2' },
                                    React.createElement("span", { className: 'mr-2' }, "Reference:"),
                                    React.createElement("span", { className: 'c-font-weight-medium c-font-size-l' }, qr.reference.toUpperCase())),
                        this.renderLocationList(vd.location_list)),
                    React.createElement("div", { className: 'col-lg-7' },
                        React.createElement("div", { className: 'c-font-size-l c-font-weight-medium pb-3' }, item.brand + (_.isEmpty(item.model) ? '' : ' - ' + item.model)),
                        this.renderItemDetail(item),
                        this.renderItemDescriptionAndWarranty(item),
                        React.createElement("div", { className: 'd-flex align-items-center pt-3' },
                            React.createElement("span", { className: 'mr-2' }, "Vendor Total Price:"),
                            React.createElement("span", { className: 'price' }, lib_1.util.formatPrice(item.price.total))),
                        React.createElement("div", { className: 'pt-1 c-font-size-xs' }, "* Total price includes manufacturer and vendor discount, tax, and fees."),
                        this.renderCruzDiscount(),
                        React.createElement("hr", null),
                        React.createElement("div", { className: 'mb-2 d-flex flex-column flex-sm-row justify-content-sm-between align-items-md-center' },
                            React.createElement("ul", { className: 'list-unstyled mb-0' },
                                React.createElement("li", null,
                                    React.createElement("span", { className: 'c-font-weight-semi-bold c-font-size-xl' }, numeral(charge.amount / 100).format('$0,0.00')),
                                    " charged at ",
                                    React.createElement("span", { className: 'c-font-weight-medium' }, moment(charge.date_create).format(cfg.format.datetime)),
                                    " (",
                                    moment(charge.date_create).fromNow(),
                                    ")."),
                                _.get(charge, 'refunded')
                                    &&
                                        _.map(charge.refunds.data, function (x) {
                                            var m_created = moment.unix(x.created);
                                            return (React.createElement("li", { key: x.id },
                                                React.createElement("span", { className: 'c-font-weight-semi-bold c-font-color-2 c-font-size-xl' }, numeral(x.amount / 100).format('$0,0.00')),
                                                " refunded at ",
                                                React.createElement("span", { className: 'c-font-weight-medium' }, m_created.format(cfg.format.datetime)),
                                                " (",
                                                m_created.fromNow(),
                                                ")"));
                                        })),
                            React.createElement("div", { className: 'ml-sm-4 mt-3 mt-sm-0 ' },
                                React.createElement("a", { href: charge.receipt_url, target: '_blank', className: 'btn c-btn-1 c-btn-xs' },
                                    React.createElement("i", { className: 'fal fa-receipt' }),
                                    React.createElement("span", null, "Receipt"))))))));
        };
        Page.prototype.getCruzDiscountAmount = function () {
            var cd = _.get(this.state.quote, 'cruz_discount');
            var amount = numeral(_.get(cd, 'amount')).value();
            if (!_.isEmpty(cd) && amount > 0) {
                if (!_.isEmpty(_.get(this.state.quote, 'charge')) || (_.isEmpty(cd.date_valid_to) || moment(cd.date_valid_to).isAfter(moment()))) {
                    return amount;
                }
                else {
                    return 0;
                }
            }
            else {
                return 0;
            }
        };
        Page.prototype.renderQuoteResult = function (qr, can_order, is_charged) {
            var _this = this;
            try {
                var vd = qr.vendor_display;
                var qOrder_1 = _.get(this.state.quote, 'order');
                var is_result_selected = (qr._id == _.get(this.state.quote, 'order.result_id') && _.includes([Cruz.Lookup.Service.QuoteStatusEnum.ReadyForService, Cruz.Lookup.Service.QuoteStatusEnum.Paid], this.state.quote.status_id));
                var cruz_discount_amount_1 = this.getCruzDiscountAmount();
                return (React.createElement("div", { className: classNames('card mb-3', { 'shadow-sm': is_result_selected }) },
                    React.createElement("div", { className: 'card-body' },
                        React.createElement("div", { className: 'row' },
                            React.createElement("div", { className: 'col-lg-8 order-2 order-lg-1 mt-3 mt-lg-0 service-quote-result' }, _.map(qr.item_list, function (item, idx) {
                                var is_item_expired = !_.isEmpty(item.date_valid_to) && moment(item.date_valid_to, 'MM/DD/YYYY').isBefore(moment());
                                var is_item_ordered = (!_.isEmpty(qOrder_1) && qOrder_1.result_id == qr._id && qOrder_1.result_item_id == item.id);
                                numeral.zeroFormat('-');
                                numeral.nullFormat('-');
                                return (React.createElement(React.Fragment, { key: item.id },
                                    React.createElement("div", { className: classNames('row no-gutters align-items-center', { paid: (is_charged && is_item_ordered) }) },
                                        React.createElement("div", { className: classNames('col-12', { ' col-md-10': can_order }) },
                                            React.createElement("div", { className: 'c-font-weight-medium c-font-size-l pb-2 title' },
                                                (is_charged && is_item_ordered) &&
                                                    React.createElement("i", { className: 'fa fa-check mr-2' }),
                                                React.createElement("span", null, item.brand + (_.isEmpty(item.model) ? '' : ' - ' + item.model))),
                                            React.createElement("div", { className: 'row' },
                                                React.createElement("div", { className: 'col-12 col-md-7' }, _this.renderItemDetail(item)),
                                                React.createElement("div", { className: 'col-12 col-md-5 pt-3 pt-md-0' },
                                                    React.createElement("div", { className: 'price' }, lib_1.util.formatPrice(_.get(item.price, 'total'))),
                                                    (cruz_discount_amount_1 > 0) &&
                                                        React.createElement("div", { className: 'c-font-size-xs' },
                                                            "(",
                                                            numeral(item.price.total - cruz_discount_amount_1).format('$0,0[.]00'),
                                                            " after Cruz discount)"),
                                                    (!_.isEmpty(item.date_valid_to)) &&
                                                        React.createElement("div", { className: classNames('c-font-size-xs', { 'c-font-color-2': is_item_expired }) }, 'Price valid to ' + moment(item.date_valid_to, 'MM/DD/YYYY').format('MM/DD/YYYY')))),
                                            _this.renderItemDescriptionAndWarranty(item)),
                                        (can_order)
                                            &&
                                                React.createElement("div", { className: 'col-12 col-md-2 text-md-right mt-3 mt-md-0' }, is_item_expired
                                                    ?
                                                        React.createElement("span", { className: 'c-font-weight-medium c-font-color-2' }, "Expired")
                                                    :
                                                        is_item_ordered
                                                            ?
                                                                React.createElement("button", { type: 'button', className: 'btn c-btn-1 c-btn-xs', onClick: function () { return lib_1.Global.history.push(pages_1.pages.service_quote_pay.path + '/' + _this.state._id + '/pay'); } }, cruz_discount_amount_1 > 0 ? 'Pay ' + numeral(item.price.total - cruz_discount_amount_1).format('$0,0[.]00') : 'Pay')
                                                            :
                                                                React.createElement("button", { type: 'button', className: 'btn c-btn-1-rev c-btn-xs', onClick: function () { return _this.orderItem(qr._id, item.id); } }, _this.state.is_ordering && _.isEqual(_this.state.order, { result_id: qr._id, result_item_id: item.id }) ? React.createElement(component_1.BtnWorking, { value: 'Ordering ...' }) : 'Order'))),
                                    ((idx + 1) < _.size(qr.item_list)) &&
                                        React.createElement("hr", null)));
                            })),
                            React.createElement("div", { className: 'col-lg-4 order-1 order-lg-2' },
                                React.createElement("div", { className: 'card-title mb-2' }, vd.name),
                                !_.isEmpty(vd.web_site)
                                    &&
                                        React.createElement("div", { className: 'mb-2' },
                                            React.createElement("a", { href: vd.web_site, target: '_blank', rel: 'noopener noreferrer', className: 'c-btn-link' }, vd.web_site)),
                                (_.isBoolean(qr.require_appointment) && !qr.require_appointment)
                                    ?
                                        React.createElement("div", { className: 'mb-1' },
                                            React.createElement("i", { className: 'far fa-calendar-times mr-2' }),
                                            "No Appointment Needed")
                                    :
                                        is_result_selected
                                            &&
                                                this.renderSchedule(),
                                !_.isEmpty(qr.reference)
                                    &&
                                        React.createElement("div", { className: 'mb-2' },
                                            React.createElement("span", { className: 'mr-2' }, "Reference:"),
                                            React.createElement("span", { className: 'c-font-weight-medium c-font-size-l' }, qr.reference.toUpperCase())),
                                this.renderLocationList(vd.location_list)),
                            React.createElement("hr", { className: 'd-block d-lg-none mb-0' })))));
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.renderQuote = function () {
            var _this = this;
            try {
                var quote = this.state.quote;
                var can_order_1 = (quote.status_id == Cruz.Lookup.Service.QuoteStatusEnum.ReadyForOrder || quote.status_id == Cruz.Lookup.Service.QuoteStatusEnum.Ordered);
                var is_charged_1 = (!_.isEmpty(quote.charge));
                return (React.createElement("div", { className: 'flex-ch-100 pb-4' },
                    this.renderHeading(),
                    React.createElement("div", { className: 'my-3 px-3 px-lg-0' },
                        this.renderStatus(),
                        !is_charged_1 && this.renderCruzDiscount(),
                        is_charged_1 && this.renderOrderAndCharge(),
                        (_.size(this.state.quote_result_list) > 0)
                            &&
                                React.createElement(React.Fragment, null,
                                    is_charged_1
                                        &&
                                            React.createElement("div", { className: 'd-flex justify-content-between align-items-center quote-section-bar mt-4', onClick: function () { return _this.setState(__assign(__assign({}, _this.state), { show_quote: !_this.state.show_quote })); } },
                                                React.createElement("div", { className: 'ml-3 c-font-weight-medium' }, "All Quotes"),
                                                React.createElement("i", { className: classNames('fas c-font-size-lg c-font-color-1 mr-3', this.state.show_qa ? 'fa-minus' : 'fa-plus') })),
                                    (this.state.show_quote || !is_charged_1)
                                        &&
                                            React.createElement(React.Fragment, null,
                                                React.createElement("div", { className: 'mt-3 c-font-size-s' }, "Note: Price includes manufacturer and vendor discount, tax, and fees."),
                                                _.map(this.state.quote_result_list, function (qr) {
                                                    return (React.createElement("div", { className: 'mt-3', key: qr._id }, _this.renderQuoteResult(qr, can_order_1, is_charged_1)));
                                                }))),
                        _.size(quote.qa) > 0 && this.renderQA(),
                        _.size(quote.amenity_list) > 0 && this.renderAmenity(),
                        !_.isEmpty(quote.description) && this.renderDescription())));
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.renderQA = function () {
            var _this = this;
            return (React.createElement("div", { className: 'mt-4' },
                React.createElement("div", { className: 'd-flex justify-content-between align-items-center quote-section-bar', onClick: function () { return _this.setState(__assign(__assign({}, _this.state), { show_qa: !_this.state.show_qa })); } },
                    React.createElement("div", { className: 'ml-3 c-font-weight-medium' }, "Q/A"),
                    React.createElement("i", { className: classNames('fas c-font-size-lg c-font-color-1 mr-3', this.state.show_qa ? 'fa-minus' : 'fa-plus') })),
                React.createElement("div", { className: classNames('ml-4 py-2', this.state.show_qa ? 'd-block' : 'd-none') }, _.map(_.keys(this.state.quote.qa), function (id) {
                    var q = _.get(_.find(_this.state.quote_question_list, { _id: id }), 'question');
                    var a = _.get(_this.state.quote.qa, id);
                    if (!_.isEmpty(q) && !_.isEmpty(a)) {
                        return (React.createElement("ul", { className: 'pt-2 pl-2', key: id },
                            React.createElement("li", null,
                                React.createElement("div", { className: 'c-font-size-s' }, q),
                                React.createElement("div", { className: 'c-font-weight-medium' }, _.isArray(a) ? _.join(a, '; ') : lib_1.util.text2html(a)))));
                    }
                }))));
        };
        Page.prototype.renderAmenity = function () {
            var _this = this;
            return (React.createElement("div", { className: 'mt-4' },
                React.createElement("div", { className: 'd-flex justify-content-between align-items-center quote-section-bar', onClick: function () { return _this.setState(__assign(__assign({}, _this.state), { show_amenity: !_this.state.show_amenity })); } },
                    React.createElement("div", { className: 'ml-3 c-font-weight-medium' }, "Amenities Required"),
                    React.createElement("i", { className: classNames('fas c-font-size-lg c-font-color-1 mr-3', this.state.show_amenity ? 'fa-minus' : 'fa-plus') })),
                React.createElement("div", { className: classNames('my-2 pt-2', this.state.show_amenity ? 'd-block' : 'd-none') },
                    React.createElement("ul", null, _.map(this.state.quote.amenity_name_list, function (d, idx) {
                        return (React.createElement("li", { key: idx }, d));
                    })))));
        };
        Page.prototype.renderDescription = function () {
            var _this = this;
            return (React.createElement("div", { className: 'mt-4' },
                React.createElement("div", { className: 'd-flex justify-content-between align-items-center quote-section-bar', onClick: function () { return _this.setState(__assign(__assign({}, _this.state), { show_description: !_this.state.show_description })); } },
                    React.createElement("div", { className: 'ml-3 c-font-weight-medium' }, "Description"),
                    React.createElement("i", { className: classNames('fas c-font-size-lg c-font-color-1 mr-3', this.state.show_description ? 'fa-minus' : 'fa-plus') })),
                React.createElement("div", { className: classNames('ml-4 py-2', this.state.show_description ? 'd-block' : 'd-none') }, lib_1.util.text2html(this.state.quote.description))));
        };
        Page.prototype.render = function () {
            return (component_1.Layout.main(lib_1.Global.store.getState().user, null, React.createElement("div", { className: 'flex-rh-100 container c-detail-container' }, (this.state.is_loading || _.isEmpty(this.state.quote))
                ?
                    React.createElement(component_1.Loading, null)
                :
                    this.renderQuote())));
        };
        return Page;
    }(React.Component));
    ServiceQuoteDetail.Page = Page;
})(ServiceQuoteDetail = exports.ServiceQuoteDetail || (exports.ServiceQuoteDetail = {}));
