"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var Support;
(function (Support) {
    var Phone = (function (_super) {
        __extends(Phone, _super);
        function Phone(props) {
            return _super.call(this, props) || this;
        }
        Phone.prototype.render = function () {
            var phone_number = '(605) 929-8793';
            return (React.createElement(React.Fragment, null,
                React.createElement("div", null,
                    React.createElement("span", { className: 'c-font-size-xs d-block' }, "Customer Support"),
                    React.createElement("span", { className: 'c-font-size-xxl c-font-weight-semi-bold', style: { lineHeight: 1 } }, "(605) 929-8793")),
                React.createElement("div", { className: 'c-ellipsis-drop dropdown pl-3' },
                    React.createElement("button", { className: 'dropdown-toggle', "data-toggle": 'dropdown' },
                        React.createElement("i", { className: 'far fa-ellipsis-v c-font-size-xxl' })),
                    React.createElement("div", { className: 'dropdown-menu dropdown-menu-right' },
                        React.createElement("a", { href: 'tel:' + phone_number, className: 'dropdown-item' },
                            React.createElement("i", { className: 'fal fa-phone mr-2 fa-lg' }),
                            React.createElement("span", null, "Call")),
                        React.createElement("div", { className: 'dropdown-divider' }),
                        React.createElement("a", { href: 'sms:' + phone_number, className: 'dropdown-item' },
                            React.createElement("i", { className: 'fal fa-sms mr-2 fa-lg' }),
                            React.createElement("span", null, "Text (SMS)"))))));
        };
        return Phone;
    }(React.Component));
    Support.Phone = Phone;
})(Support = exports.Support || (exports.Support = {}));
