"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var request = require("superagent");
var ReactDOM = require("react-dom");
var numeral = require("numeral");
var lib_1 = require("../../lib");
var pages_1 = require("../../../routes/pages");
var component_1 = require("../../component");
var cfg = require("../../../cfg");
var Modal;
(function (Modal) {
    var DownloadChat = (function (_super) {
        __extends(DownloadChat, _super);
        function DownloadChat(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                is_working: true
            };
            return _this;
        }
        DownloadChat.prototype.componentDidMount = function () {
            var _this = this;
            $('#_downloadChat').modal('show').on('hidden.bs.modal', function (e) {
                lib_1.util.removeModal(_this);
            });
            this.download();
        };
        DownloadChat.prototype.download = function () {
            var _this = this;
            request.get(cfg.socketUrl[cfg.env] + '/chat/' + this.props._id + '/download')
                .set(lib_1.Auth.authHeader())
                .timeout(20000)
                .responseType('blob')
                .end(function (err, apiRes) {
                if (err) {
                    _this.setState({ is_working: false, has_error: true });
                }
                else {
                    _this.setState({ is_working: false, has_error: false }, function () {
                        var blob = new Blob([apiRes.body]);
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = _this.props.file_name;
                        link.click();
                    });
                }
            });
        };
        DownloadChat.prototype.render = function () {
            var _this = this;
            try {
                return (React.createElement("div", { className: 'modal', id: '_downloadChat', tabIndex: -1, role: 'dialog', "data-backdrop": 'static', "data-keyboard": false },
                    React.createElement("div", { className: 'modal-dialog', role: 'document' },
                        React.createElement("div", { className: 'modal-content' },
                            React.createElement("div", { className: 'modal-header' },
                                React.createElement("h2", { className: 'modal-title' }, "Download Messages")),
                            React.createElement("div", { className: 'modal-body' }, this.state.is_working
                                ?
                                    React.createElement("div", { className: 'd-flex justify-content-center' },
                                        React.createElement(component_1.Loading, { label: 'Loading file ... this should only take a few seconds.' }))
                                :
                                    this.state.has_error
                                        ?
                                            React.createElement("div", { className: 'c-msg-error' }, "Something is wrong, Please try again.")
                                        :
                                            React.createElement("div", null, "Your file is ready.")),
                            this.state.is_working == false
                                &&
                                    React.createElement("div", { className: 'modal-footer' },
                                        this.state.has_error
                                            &&
                                                React.createElement("button", { type: 'button', className: 'btn c-btn-xs c-btn-2 mr-2', onClick: function () { return _this.download(); } }, "Try Again"),
                                        React.createElement("button", { type: 'button', className: 'btn c-btn-xs c-btn-1', "data-dismiss": 'modal' }, "Close"))))));
            }
            catch (err) {
                lib_1.util.logError(err, null);
                return null;
            }
        };
        return DownloadChat;
    }(React.Component));
    Modal.DownloadChat = DownloadChat;
    var RequestAuth = (function (_super) {
        __extends(RequestAuth, _super);
        function RequestAuth(props) {
            return _super.call(this, props) || this;
        }
        RequestAuth.prototype.componentDidMount = function () {
            var _this = this;
            $('#_requestAuth').modal('show').on('hidden.bs.modal', function (e) {
                lib_1.util.removeModal(_this);
            });
        };
        RequestAuth.prototype.goto = function (path) {
            $('#_requestAuth').modal('hide');
            lib_1.Global.history.push(path);
        };
        RequestAuth.prototype.render = function () {
            var _this = this;
            try {
                return (React.createElement("div", { className: 'modal', id: '_requestAuth', tabIndex: -1, role: 'dialog' },
                    React.createElement("div", { className: 'modal-dialog', role: 'document' },
                        React.createElement("div", { className: 'modal-content' },
                            React.createElement("div", { className: 'modal-header' },
                                React.createElement("h2", { className: 'modal-title' }, "Authorization Required"),
                                React.createElement("button", { type: 'button', "data-dismiss": 'modal', className: 'close' })),
                            React.createElement("div", { className: 'modal-body' },
                                "Please ",
                                React.createElement("button", { className: 'c-btn-link', onClick: function () { return _this.goto(pages_1.pages.signin.path + '?returnUrl=' + lib_1.util.getRelativeUrl()); } }, pages_1.pages.signin.title),
                                " first.",
                                React.createElement("br", null),
                                "Don't have an account? ",
                                React.createElement("button", { className: 'c-btn-link', onClick: function () { return _this.goto(pages_1.pages.register.path); } }, "Create a free account"),
                                ".")))));
            }
            catch (err) {
                lib_1.util.logError(err, null);
                return null;
            }
        };
        return RequestAuth;
    }(React.Component));
    Modal.RequestAuth = RequestAuth;
    var DownloadFile = (function (_super) {
        __extends(DownloadFile, _super);
        function DownloadFile(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                is_working: true
            };
            return _this;
        }
        DownloadFile.prototype.componentDidMount = function () {
            var _this = this;
            $('#_downloadFile').modal('show').on('hidden.bs.modal', function (e) {
                lib_1.util.removeModal(_this);
            });
            this.download();
        };
        DownloadFile.prototype.download = function () {
            var _this = this;
            request.get(this.props.download_url)
                .set(lib_1.Auth.authHeader())
                .timeout(20000)
                .responseType('blob')
                .end(function (err, apiRes) {
                if (err) {
                    _this.setState({ is_working: false, has_error: true });
                }
                else {
                    _this.setState({ is_working: false, has_error: false }, function () {
                        var blob = new Blob([apiRes.body]);
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = _this.props.file_name;
                        link.click();
                    });
                }
            });
        };
        DownloadFile.prototype.render = function () {
            var _this = this;
            try {
                return (React.createElement("div", { className: 'modal modal-push-to-view', id: '_downloadFile', tabIndex: -1, role: 'dialog', "data-backdrop": 'static', "data-keyboard": false },
                    React.createElement("div", { className: 'modal-dialog', role: 'document' },
                        React.createElement("div", { className: 'modal-content' },
                            React.createElement("div", { className: 'modal-header' },
                                React.createElement("h2", { className: 'modal-title' }, "Download File")),
                            React.createElement("div", { className: 'modal-body' }, this.state.is_working
                                ? React.createElement("div", { className: 'd-flex justify-content-center' },
                                    React.createElement(component_1.Loading, { label: 'Loading file ... this should only take a few seconds.' }))
                                : this.state.has_error
                                    ? React.createElement("div", { className: 'c-msg-error' }, "Something is wrong, Please try again.")
                                    : React.createElement("div", null, "Your file is ready.")),
                            this.state.is_working == false
                                &&
                                    React.createElement("div", { className: 'modal-footer' },
                                        this.state.has_error
                                            &&
                                                React.createElement("button", { type: 'button', className: 'btn c-btn-xs c-btn-2 mr-2', onClick: function () { return _this.download(); } }, "Try Again"),
                                        React.createElement("button", { type: 'button', className: 'btn c-btn-xs c-btn-1', "data-dismiss": 'modal' }, "Close"))))));
            }
            catch (err) {
                lib_1.util.logError(err, null);
                return null;
            }
        };
        return DownloadFile;
    }(React.Component));
    Modal.DownloadFile = DownloadFile;
    var ViewImageInChat = (function (_super) {
        __extends(ViewImageInChat, _super);
        function ViewImageInChat(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                is_working: true
            };
            return _this;
        }
        ViewImageInChat.prototype.componentDidMount = function () {
            $('#_photoListView').modal('show').on('hidden.bs.modal', function (e) {
                $(this).data('bs.modal', null);
                if (document.querySelector('#_modal')) {
                    ReactDOM.unmountComponentAtNode(document.querySelector('#_modal'));
                }
            });
        };
        ViewImageInChat.prototype.render = function () {
            try {
                return (React.createElement("div", { className: 'modal modal-photo', id: '_photoListView', tabIndex: -1, role: 'dialog', "data-keyboard": true },
                    React.createElement("button", { type: 'button', className: 'c-btn-close', "data-dismiss": 'modal', title: 'Close' },
                        React.createElement("i", { className: 'far fa-times' })),
                    React.createElement("div", { className: 'modal-dialog', role: 'document' },
                        React.createElement("div", { className: 'modal-content' },
                            React.createElement("div", { className: 'modal-body mx-auto' },
                                React.createElement("img", { className: 'img-fluid animated fadeIn d-block mx-auto my-1', src: this.props.download_url }))))));
            }
            catch (err) {
                lib_1.util.logError(err, null);
                return null;
            }
        };
        return ViewImageInChat;
    }(React.Component));
    Modal.ViewImageInChat = ViewImageInChat;
    var ServiceQuoteTerms = (function (_super) {
        __extends(ServiceQuoteTerms, _super);
        function ServiceQuoteTerms() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        ServiceQuoteTerms.prototype.componentDidMount = function () {
            var _this = this;
            $('#_serviceQuoteTerms').modal('show').on('hidden.bs.modal', function (e) {
                lib_1.util.removeModal(_this);
            });
        };
        ServiceQuoteTerms.prototype.render = function () {
            var user = lib_1.Global.store.getState().user;
            return (React.createElement("div", { className: 'modal', id: '_serviceQuoteTerms', tabIndex: -1, role: 'dialog' },
                React.createElement("div", { className: 'modal-dialog modal-lg', role: 'document' },
                    React.createElement("div", { className: 'modal-content' },
                        React.createElement("div", { className: 'modal-header' },
                            React.createElement("h2", { className: 'modal-title' }, "Affirmation and Release"),
                            React.createElement("button", { type: 'button', className: 'close', "data-dismiss": 'modal' })),
                        React.createElement("div", { className: 'modal-body px-md-4' },
                            React.createElement("p", null,
                                "I, ",
                                user.first_name + ' ' + user.last_name,
                                ", hereby submit this Affirmation of Eligibility and Liability Release (\u201CAffirmation and Release\u201D) with the understanding that it will be relied upon to determine whether I am eligible to use the Cruz.com Vehicle Servicing or Repair Quote Service (the \u201CService\u201D). Cruz.com is owned and operated by Cruz, Inc. (\u201CCruz\u00AE\u201D).  In order to utilize the Service, I agree to the conditions and limitations contained herein, including, but not limited to, the release of liability and indemnification provisions. I warrant, represent, understand and agree to the following:"),
                            React.createElement("ol", null,
                                React.createElement("li", null, "I am of legal age in my state of residence, meet all requirements to register for a Cruz.com account, and agree to the Terms and Conditions of Use and Privacy Policy of Cruz.com."),
                                React.createElement("li", null, "As part of the Service, Cruz\u00AE may contact various businesses in the vehicle service and repair industry (\u201CProviders\u201D) in or around the city in which I reside in order to receive a quote (or estimate) to perform the servicing or repairs identified below on my designated vehicle (\u201CVehicle\u201D)."),
                                React.createElement("li", null, "I am the legal owner of the Vehicle and am authorized to request servicing or repairs to the Vehicle."),
                                React.createElement("li", null, "I am under no obligation to use the Service or obtain servicing or repairs to my Vehicle from any Provider submitting quotes through the Service. At any time, I may opt to obtain servicing or repairs to my Vehicle from any Provider of my choosing, including Providers that did not submit quotes through the Service.  I am in no way obligated or required to use any quote I receive through the Service. I will select the Provider performing any servicing or repairs to my Vehicle using solely my own judgement and will perform all necessary inquiries or investigations on such Provider as may be prudent or necessary prior to receiving any services on my Vehicle from such Provider."),
                                React.createElement("li", null, "Cruz\u00AE is not responsible for the quality, timeliness, thoroughness, or completeness of any servicing or repairs ultimately obtained from a Provider. Cruz\u00AE does not guarantee, warrant, select, recommend, or endorse the services of any Provider submitting quotes through the Service and only serves as a conduit to provide quotes to Cruz.com users from potential Providers."),
                                React.createElement("li", null, "The Services performed by Cruz\u00AE may include: receiving quotes from Providers on my behalf, making a monetary payment for my benefit to my selected Provider after accepting the Provider\u2019s quote, and scheduling an appointment with my selected Provider for servicing or repairs to my Vehicle. If I elect to utilize a Provider that has not provided a quote to Cruz\u00AE through the Service, I will not be eligible to receive additional Services from Cruz\u00AE with regard to the servicing or repair to my Vehicle (including any monetary payment or scheduling assistance).  In order for my selected Provider to receive a monetary payment from Cruz\u00AE for my benefit, I must (i) utilize a Provider that provided a quote to Cruz\u00AE through the Service; and (ii) provide a physical copy of any receipt I receive from such Provider for completed servicing or repairs in-person to Heidi Hall, at Cruz\u00AE, or via email at receipts@cruz.com"),
                                React.createElement("li", null, "Cruz\u00AE is authorized to utilize the data it receives from the Service, including quote information and any other information related to transactions following such quotes, for Cruz\u2019s business purposes in accordance with its Privacy Policy."),
                                React.createElement("li", null, "I HEREBY RELEASE, WAIVE, AND DISCHARGE CRUZ, INC., CARSFORSALE.COM, INC., AND ALL OF THEIR RESPECTIVE PARENT COMPANIES, AFFILIATES, SUBSIDIARIES, OWNERS, SHAREHOLDERS, MEMBERS, MANAGERS, DIRECTORS, OFFICERS, INSURERS, AGENTS, AND EMPLOYEES (\u201CRELEASEES\u201D) FROM ALL LIABILITY TO MYSELF, MY HEIRS, SUCCESSORS, NEXT OF KIN, ASSIGNS, AND REPRESENTATIVES (\u201CRELEASORS\u201D) FOR ANY AND ALL LOSS OR DAMAGE, AND ANY CLAIM OR DEMANDS THEREFOR, ON ACCOUNT OF INJURY TO PERSON OR PROPERTY, OR RESULTING IN MY DEATH, ARISING OUT OF OR RELATED TO THE SERVICE, WHETHER CAUSED BY THE NEGLIGENCE OF THE RELEASEES, PROVIDERS, THIRD PARTIES OR OTHERWISE"),
                                React.createElement("li", null, "I HEREBY AGREE THAT TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL RELEASEES BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER, CONNECTED WITH MY USE OR MISUSE OF THE SERVICE, WHETHER BASED IN CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE, EVEN IF THE RELEASEES HAVE BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. THIS INCLUDES INJURY TO PERSON, PROPERTY, OR BODY, OR ANY OTHER KIND OF LOSS OR DAMAGE WHATSOEVER TO ME OR ANY THIRD PARTY. BECAUSE SOME STATES AND JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO ME, BUT I AGREE THAT RELEASEES\u2019 LIABILITY SHALL BE WAIVED TO THE MAXIMUM EXTENT PERMISSIBLE BY THE LAW OF MY STATE OR JURISDICTION."),
                                React.createElement("li", null, "BECAUSE THE SERVICE IS PROVIDED PURELY IN A CONDUIT CAPACITY, I UNDERSTAND AND AGREE THAT THE RELEASEES HAVE NO CONTROL OVER: (I) THE QUALITY, SAFETY, LEGALITY, TRUTH, OR ACCURACY OF THE QUOTES SUBMITTED BY PROVIDERS THAT MAY REPAIR OR SERVICE MY VEHICLE, (II) WHETHER OR NOT THE PROVIDERS WILL COMPLETE THE SERVICES THEY OFFER FOR MY VEHICLE, OR (III) THE QUALITY, SAFETY, RELIABILITY, FITNESS, MERCHANTABILITY, TRUTH, OR ACCURACY OF ANY SERVICES PROVIDED BY SUCH PROVIDERS. IN THE EVENT THAT I HAVE A DISPUTE WITH ONE OR MORE OF THE PROVIDERS FROM WHICH I RECEIVE A QUOTE OR FROM WHICH I RECEIVE SERVICES FOR MY VEHICLE, OR BOTH, I HEREBY RELEASE THE REALSEES FROM ALL CLAIMS, DEMANDS, AND DAMAGES OF ANY KIND OR NATURE, KNOWN OR UNKNOWN, SUSPECTED OR UNSUSPECTED, DISCLOSED OR UNDISCLOSED, ARISING OUT OF OR IN ANY WAY CONNECTED WITH SUCH QUOTE OR SERVICES. I AGREE THAT IF I AM A RESIDENT IN A JURISDICTION WHICH RESTRICTS THE EFFECT OF THIS RELEASE OF LIABILITY FOR DISPUTES WITH THIRD PARTIES, I HEREBY CONSENT THAT THIS RELEASE OF LIABILITY FOR DISPUTES WITH THIRD PARTIES SHALL APPLY TO THESE TERMS TO THE FULLEST EXTENT LEGALLY PERMISSIBLE."),
                                React.createElement("li", null, "I HEREBY ASSUME FULL RESPONSIBILITY FOR ANY RISK OF BODILY INJURY, DEATH OR PROPERTY DAMAGE ARISING OUT OF OR RELATED TO MY USE OF THE SERVICE, WHETHER CAUSED BY THE NEGLIGENCE OF RELEASEES, THIRD PARTIES, PROVIDERS, RELEASORS, OR OTHERWISE. I HEREBY ACKNOWLEDGE THAT USING THE SERVICE COULD BE DANGEROUS, AND, BY ITS NATURE, INVOLVES THE RISK OF SERIOUS INJURY AND/OR DEATH AND/OR PROPERTY DAMAGE. I HEREBY ASSUME ALL RISKS ASSOCIATED WITH OR ARISING OUT OF USE OF THE SERVICE.  I FURTHER ACKNOWLEDGE THAT USE OF THE SERVICE IS VOLUNTARY AND PERSONAL, PARTICIPATION IS NOT REQUIRED AND IS FULLY OPTIONAL, AND I MAY CEASE USE OF THE SERVICE AT ANY TIME."),
                                React.createElement("li", null, "I FURTHER AGREE TO INDEMNIFY, DEFEND AND HOLD HARMLESS THE RELEASEES, COLLECTIVELY AND EACH OF THEM SEPARATELY, FROM ANY LOSS, LIABILITY, DAMAGE, OR COST THE RELEASORS OR RELEASEES INCUR (INCLUDING, WITHOUT LIMITATION, THE COST OF INVESTIGATING ANY CLAIM, THE COST OF LITIGATION AND ATTORNEYS' FEES, WHETHER OR NOT LEGAL PROCEEDINGS ARE INSTITUTED) ARISING OUT OF, IN CONNECTION WITH, OR RELATED TO MY USE OR MISUSE OF THE SERVICE, WHETHER CAUSED BY THE NEGLIGENCE OF THE RELEASEES, THIRD PARTIES, PROVIDERS, MYSELF, OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO, ANY TRANSACTION I COMPLETE OR SERVICES I UTILIZE FROM A PROVIDER THAT SERVICES MY VEHICLE, THROUGH, OR IN CONNECTION WITH, THE SERVICE."),
                                React.createElement("li", null, "I FURTHER AGREE: (I) THAT I AM RESPONSIBLE FOR ALL TRAVEL AND FEES ASSOCIATED WITH ANY QUOTE OR PROVIDER I UTILIZE TO SERVICE OR REPAIR MY VEHICLE; (II) I HAVE A VALID DRIVER\u2019S LICENSE, VEHICLE INSURANCE, AND ANY OTHER NECESSARY DOCUMENTATION AS REQUIRED BY LAW FOR THE VEHICLE DESIGNATED BELOW AND AM THE OWNER OF SUCH VEHICLE; (III) THAT THE RELEASEES HAVE NOT ARRANGED FOR AND DO NOT CARRY ANY INSURANCE OF ANY KIND FOR MY BENEFIT OR THE BENEFIT OF MY HEIRS, EXECUTORS OR ADMINISTRATORS RELATIVE TO MY VEHICLE OR ANY SERVICES OR REPAIRS I RECEIVE THEREON. I AM SOLELY RESPONSIBLE FOR OBTAINING AND PAYING FOR ANY LIFE, AUTOMOBILE, ACCIDENT, PROPERTY OR OTHER INSURANCE RELATIVE TO MY USE OF THE VEHICLE; (IV) THAT I AGREE TO COMPLY WITH ALL APPLICABLE LAWS RELATIVE TO OWNERSHIP, OPERATION, REPAIR, OR SERVICE OF A MOTOR VEHICLE; (V) THAT I ACCEPT THE SERVICE AS-IS UNDERSTANDING THAT THE RELEASEES MAKE NO WARRANTY, REPRESENTATION, OR GUARANTEE, EXPRESS OR IMPLIED, IN FACT OR IN LAW, IN CONNECTION WITH THE SERVICE, INCLUDING, WITHOUT LIMITATION, THE QUALITY, SAFETY, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OF THE SERVICE. I FURTHER AGREE THAT OPERATING, REPAIRING, OR SERVICING A VEHICLE CAN BE A DANGEROUS ACTIVITY AND THAT I ACCEPT ALL RISK AND RESPONSIBILITY ASSOCIATED WITH OPERATING, REPAIRING, OR SERVICING THE VEHICLE."),
                                React.createElement("li", null, "This Affirmation and Release extends to all acts of negligence by the Releasees, is governed by South Dakota law, and is intended to be as broad and inclusive as is permitted by the laws of South Dakota. If any portion of this Affirmation and Release is held invalid, the balance shall continue in full legal force and effect, and a provision as similar in terms as may be valid and enforceable as a court of competent jurisdiction may determine shall be used in lieu of such provision."),
                                React.createElement("li", null, "Any dispute arising under or related to this Affirmation and Release or the Service will be venued in Circuit Court located in Minnehaha County, South Dakota, or, if appropriate, the United States District Court for the District of South Dakota. Such court shall have jurisdiction over me and will be the sole and exclusive venue for all disputes related to or arising under this Affirmation and Release or the Service."),
                                React.createElement("li", null, "The representations and terms contained in this Affirmation and Release shall be binding on my heirs, successors, next of kin, assigns, and representatives."),
                                React.createElement("li", null, "I will not utilize the Service if I disagree with any representation, warranty, or statement contained in this Affirmation and Release or believe any such representation, warranty, or statement is false. If I am dissatisfied with or disagree with any portion of this Affirmation and Release, including, but not limited to, the liability and indemnification provisions contained herein, my sole and exclusive remedy is to not utilize or immediately discontinue use of the Service."),
                                React.createElement("li", null, "My execution of this Affirmation and Release does not guarantee access or participation in the Service. The Service is offered in the sole discretion of Cruz\u00AE and may be terminated, modified, limited, or canceled by Cruz\u00AE at any time, in its sole discretion."),
                                React.createElement("li", null, "This Affirmation and Release is intended to supplement the Terms and Conditions of Use of Cruz.com.  In the event of a conflict between this Affirmation and Release and the Terms and Conditions of Use of Cruz.com, this Affirmation and Release shall take precedence."))),
                        React.createElement("div", { className: 'modal-footer' },
                            React.createElement("button", { type: 'button', className: 'btn c-btn-xs c-btn-1', "data-dismiss": 'modal' }, "Close"))))));
        };
        return ServiceQuoteTerms;
    }(React.Component));
    Modal.ServiceQuoteTerms = ServiceQuoteTerms;
    var ViewSharedFiles = (function (_super) {
        __extends(ViewSharedFiles, _super);
        function ViewSharedFiles(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                is_working: true
            };
            return _this;
        }
        ViewSharedFiles.prototype.componentDidMount = function () {
            var _this = this;
            $('#_viewSharedFiles').modal('show').on('hidden.bs.modal', function (e) {
                lib_1.util.removeModal(_this, '#_sharedFiles');
            });
        };
        ViewSharedFiles.prototype.renderFiles = function () {
            return (React.createElement("div", { className: 'upload-multiple mt-1 container' },
                React.createElement("div", { className: 'row img-list ' }, this.props.chat.message_list.map(function (message) {
                    if (message.file && message.file.name) {
                        return lib_1.util.renderBinaryMessage(message);
                    }
                }))));
        };
        ViewSharedFiles.prototype.render = function () {
            try {
                return (React.createElement("div", { className: 'modal', id: '_viewSharedFiles', tabIndex: -1, role: 'dialog', "data-keyboard": false },
                    React.createElement("div", { className: 'col-xl-8 col-lg-8 col-md-10 col-sm-12 col-xs-12 modal-files', role: 'content' },
                        React.createElement("div", { className: 'modal-content' },
                            React.createElement("div", { className: 'modal-header' },
                                React.createElement("h2", { className: 'modal-title' }, "Shared Files"),
                                React.createElement("button", { type: 'button', className: 'close', "data-dismiss": 'modal' })),
                            React.createElement("div", { className: 'modal-body ' }, this.renderFiles())))));
            }
            catch (err) {
                lib_1.util.logError(err, null);
                return null;
            }
        };
        return ViewSharedFiles;
    }(React.Component));
    Modal.ViewSharedFiles = ViewSharedFiles;
    var MileageConfirm = (function (_super) {
        __extends(MileageConfirm, _super);
        function MileageConfirm(props) {
            return _super.call(this, props) || this;
        }
        MileageConfirm.prototype.componentDidMount = function () {
            var _this = this;
            $('#_mileageConfirm').modal('show').on('hidden.bs.modal', function (e) {
                lib_1.util.removeModal(_this);
            });
        };
        MileageConfirm.prototype.close = function () {
            $('#_mileageConfirm').modal('hide');
        };
        MileageConfirm.prototype.render = function () {
            var _this = this;
            return (React.createElement("div", { className: 'modal', id: '_mileageConfirm', tabIndex: -1, role: 'dialog' },
                React.createElement("div", { className: 'modal-dialog', role: 'document' },
                    React.createElement("div", { className: 'modal-content' },
                        React.createElement("div", { className: 'modal-header' },
                            React.createElement("h2", { className: 'modal-title' },
                                "Did you mean to enter ",
                                numeral(this.props.mileage * 1000).format('0,0'),
                                " miles?"),
                            React.createElement("button", { type: 'button', "data-dismiss": 'modal', className: 'close' })),
                        React.createElement("div", { className: 'modal-body' },
                            React.createElement("b", null,
                                this.props.mileage,
                                " miles"),
                            " is an unusually low mileage for a ",
                            this.props.year,
                            " vehicle."),
                        React.createElement("div", { className: 'modal-footer' },
                            React.createElement("button", { type: 'button', className: 'btn c-btn-xs c-btn-2', onClick: function () {
                                    _this.close();
                                    _this.props.onNo();
                                } }, "No"),
                            React.createElement("button", { type: 'button', className: 'btn c-btn-xs c-btn-1', onClick: function () {
                                    _this.close();
                                    _this.props.onYes();
                                } }, "Yes"))))));
        };
        return MileageConfirm;
    }(React.Component));
    Modal.MileageConfirm = MileageConfirm;
})(Modal = exports.Modal || (exports.Modal = {}));
