"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var lib_1 = require("../../lib");
var component_1 = require("../../component");
var pages_1 = require("../../../routes/pages");
var Browser;
(function (Browser) {
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            return _super.call(this, props) || this;
        }
        Page.prototype.componentDidMount = function () {
            document.title = lib_1.util.title(pages_1.pages.browser.title);
        };
        Page.prototype.componentWillUnmount = function () {
        };
        Page.prototype.render = function () {
            return (component_1.Layout.errorBrowser(React.createElement("div", { className: 'text-center' },
                React.createElement("h1", { className: 'err-title' }, "Your Internet Browser is Not Compatible"),
                React.createElement("h2", { className: 'err-des mt-sm-4' },
                    "The browser your are using is not compatible with CRUZ\u00AE.",
                    React.createElement("br", null),
                    "Please use any browser listed below:"),
                React.createElement("ul", { className: 'list-inline browser-list mt-4' },
                    React.createElement("li", { className: 'list-inline-item' },
                        React.createElement("a", { className: 'browser', href: 'https://www.google.com/chrome/browser', title: 'Get Google Chrome Browser', target: '_blank', rel: 'noopener noreferrer' },
                            React.createElement("i", { className: 'fab fa-chrome' }),
                            React.createElement("div", { className: 'browser-name' }, "Google Chrome"))),
                    React.createElement("li", { className: 'list-inline-item' },
                        React.createElement("a", { className: 'browser', href: 'https://www.mozilla.org/en-US/firefox/new', title: 'Get Firefox Browser', target: '_blank', rel: 'noopener noreferrer' },
                            React.createElement("i", { className: 'fab fa-firefox' }),
                            React.createElement("div", { className: 'browser-name' }, "Firefox"))),
                    React.createElement("li", { className: 'list-inline-item' },
                        React.createElement("a", { className: 'browser', href: 'https://www.microsoft.com/en-us/windows/microsoft-edge', title: 'Get Microsoft Edge Browser', target: '_blank', rel: 'noopener noreferrer' },
                            React.createElement("i", { className: 'fab fa-edge' }),
                            React.createElement("div", { className: 'browser-name' }, "Microsoft Edge")))))));
        };
        return Page;
    }(React.Component));
    Browser.Page = Page;
})(Browser = exports.Browser || (exports.Browser = {}));
