"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var request = require("superagent");
var _ = require("lodash");
var component_1 = require("../../component");
var cfg = require("../../../cfg");
var pages_1 = require("../../../routes/pages");
var lib_1 = require("../../lib");
var ChangePassword;
(function (ChangePassword) {
    var validate = require('validate.js');
    var rules = {
        'current_password': {
            presence: { message: '^Current password is required.', allowEmpty: false }
        },
        'new_password': {
            presence: { message: '^New password is required.', allowEmpty: false },
            length: {
                minimum: 6,
                tooShort: '^At least 6 characters.'
            }
        }
    };
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                current_password: '',
                new_password: '',
                is_working: false,
                validation: null
            };
            return _this;
        }
        Page.prototype.componentDidMount = function () {
            lib_1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    document.title = lib_1.util.title(pages_1.pages.change_password.title);
                }
                else {
                    lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                }
            });
        };
        Page.prototype.getError = function (key, isServer) {
            return ((this.state.validation && this.state.validation[key]) ? this.state.validation[key][0] : null);
        };
        Page.prototype.valid = function () {
            return validate(this.state, rules);
        };
        Page.prototype.currentPasswordChanged = function (tgt) {
            var _this = this;
            this.setState({ current_password: tgt.value }, function () {
                _this.state.validation && _this.setState({ validation: _this.valid() });
            });
        };
        Page.prototype.newPasswordChanged = function (tgt) {
            var _this = this;
            this.setState({ new_password: tgt.value }, function () {
                _this.state.validation && _this.setState({ validation: _this.valid() });
            });
        };
        Page.prototype.submit = function (e) {
            var _this = this;
            e.preventDefault();
            var errs = this.valid();
            if (errs) {
                this.setState({ validation: errs });
            }
            else if (!this.state.is_working) {
                this.setState({ is_working: true });
                lib_1.Auth.isAuthed(function (isAuthed) {
                    if (isAuthed) {
                        request.put(lib_1.Global.cruzApiBaseUrl + '/user/change-password')
                            .set(lib_1.Auth.authHeader())
                            .timeout(cfg.superagent.timeout.normal)
                            .send(_.pick(_this.state, ['current_password', 'new_password']))
                            .end(function (err, apiRes) {
                            _this.setState({ is_working: false });
                            if (err) {
                                lib_1.util.setToastMessage('error', _.get(err, cfg.apiErrorKey));
                            }
                            else {
                                lib_1.Global.onSubmitSuccess('changePassword');
                                lib_1.util.setToastMessage('success', 'Successfully Changed');
                                var tk = apiRes.body.data;
                                if (tk.refresh_token != null && tk.refresh_token != '') {
                                    lib_1.Auth.setRefreshToken(tk.refresh_token, lib_1.util.cookie.get('remember_me'));
                                }
                                _this.setState({
                                    current_password: '',
                                    new_password: ''
                                });
                            }
                        });
                    }
                    else {
                        lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                    }
                });
            }
        };
        Page.prototype.renderForm = function () {
            var _this = this;
            return (React.createElement("form", { id: 'formChangePassword', className: 'form-main', onSubmit: function (e) { return _this.submit(e); }, noValidate: true },
                React.createElement("div", { className: 'card card-form mt-3' },
                    React.createElement("div", { className: 'card-body' },
                        React.createElement("div", { className: 'row justify-content-center' },
                            React.createElement("div", { className: 'col-sm-9 col-md-7 col-lg-5' },
                                React.createElement("div", { className: 'mb-1' },
                                    React.createElement(component_1.PasswordInput, { id: 'current_password', label: 'Current Password', required: true, "data-err": this.getError('current_password'), value: this.state.current_password || '', onChange: function (e) { return _this.currentPasswordChanged(e.target); } }),
                                    React.createElement(component_1.PasswordInput, { id: 'new_password', label: 'New Password', required: true, "data-err": this.getError('new_password'), value: this.state.new_password || '', onChange: function (e) { return _this.newPasswordChanged(e.target); }, autoComplete: 'new-password' }))))),
                    React.createElement("div", { className: 'card-footer text-right' },
                        React.createElement("button", { type: 'submit', className: 'btn c-btn-1' }, this.state.is_working ? React.createElement(component_1.BtnWorking, { value: 'Saving ...' }) : 'Save')))));
        };
        Page.prototype.render = function () {
            return (component_1.Layout.main(lib_1.Global.store.getState().user, null, React.createElement("div", { className: 'container c-container' },
                React.createElement("h1", { className: 'c-title' }, pages_1.pages.change_password.title),
                this.renderForm())));
        };
        return Page;
    }(React.Component));
    ChangePassword.Page = Page;
})(ChangePassword = exports.ChangePassword || (exports.ChangePassword = {}));
