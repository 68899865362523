"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var _ = require("lodash");
var lib_1 = require("../../lib");
var Checkbox = (function (_super) {
    __extends(Checkbox, _super);
    function Checkbox(props) {
        return _super.call(this, props) || this;
    }
    Checkbox.prototype.renderCheckbox = function () {
        try {
            return (React.createElement("div", { className: lib_1.util.formGroupClass(this.props) },
                React.createElement("label", { className: 'custom-control custom-checkbox' },
                    React.createElement('input', lib_1.util.inputProps(_.assign({}, _.omit(this.props, 'title'), { type: 'checkbox' }))),
                    React.createElement("label", { htmlFor: this.props.id, className: 'custom-control-label' }, this.props.label || this.props["data-label-element"]))));
        }
        catch (err) {
            lib_1.util.logError(err, null);
            return null;
        }
    };
    Checkbox.prototype.render = function () {
        return ((this.props['data-grid'] != null)
            ?
                React.createElement("div", { className: this.props['data-grid'] }, this.renderCheckbox())
            :
                this.renderCheckbox());
    };
    return Checkbox;
}(React.Component));
exports.Checkbox = Checkbox;
