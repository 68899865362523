"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var classNames = require("classnames");
var BtnWorking = (function (_super) {
    __extends(BtnWorking, _super);
    function BtnWorking(props) {
        return _super.call(this, props) || this;
    }
    BtnWorking.prototype.render = function () {
        return (React.createElement("span", { className: 'btn-working' }, this.props.value));
    };
    return BtnWorking;
}(React.Component));
exports.BtnWorking = BtnWorking;
var Progress = (function (_super) {
    __extends(Progress, _super);
    function Progress(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { progress: _this.props.value };
        return _this;
    }
    Progress.prototype.componentWillReceiveProps = function (nextProps) {
        this.setState({ progress: nextProps.value });
    };
    Progress.prototype.render = function () {
        return (React.createElement("div", { className: 'progress' },
            React.createElement("div", { className: classNames('progress-bar', 'progress-bar-striped', 'progress-bar-animated', { 'c-progress-100': this.state.progress == 100 }), role: 'progressbar', style: { width: this.state.progress + '%' }, "aria-valuemin": 0, "aria-valuemax": 0, "aria-valuenow": this.state.progress })));
    };
    return Progress;
}(React.Component));
exports.Progress = Progress;
var Loading = (function (_super) {
    __extends(Loading, _super);
    function Loading(props) {
        return _super.call(this, props) || this;
    }
    Loading.prototype.render = function () {
        return (React.createElement("div", { className: 'flex-ch-100 align-self-center justify-content-center' },
            React.createElement("div", { className: 'c-loading' },
                React.createElement("i", { className: 'fal fa-spinner fa-pulse fa-4x' }),
                React.createElement("div", { className: 'mt-3' }, this.props.label || 'loading ...'))));
    };
    return Loading;
}(React.Component));
exports.Loading = Loading;
exports.Decoding = function (props) {
    return (React.createElement("div", { className: 'flex-ch-100 align-self-center justify-content-around' },
        React.createElement("div", { className: 'c-loading' },
            React.createElement("i", { className: 'fal fa-spinner fa-pulse fa-2x' }),
            React.createElement("div", { className: 'mt-2' }, "decoding ..."))));
};
var PageLoading = (function (_super) {
    __extends(PageLoading, _super);
    function PageLoading(props) {
        return _super.call(this, props) || this;
    }
    PageLoading.prototype.render = function () {
        return (React.createElement("div", { className: 'c-page-loading d-flex justify-content-center w-100' },
            React.createElement("div", { className: 'indicator align-self-center text-center' },
                React.createElement("i", { className: 'far fa-spinner fa-pulse' }),
                React.createElement("div", { className: 'description' }, this.props.label || 'loading ...'))));
    };
    return PageLoading;
}(React.Component));
exports.PageLoading = PageLoading;
