"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var _ = require("lodash");
var lib_1 = require("../../lib");
var Radio = (function (_super) {
    __extends(Radio, _super);
    function Radio(props) {
        return _super.call(this, props) || this;
    }
    Radio.prototype.renderRadio = function () {
        try {
            return (React.createElement("div", { className: lib_1.util.formGroupClass(this.props) },
                React.createElement("label", { className: 'custom-control custom-radio' },
                    React.createElement("input", __assign({ className: 'custom-control-input', type: 'radio' }, _.pick(this.props, 'value', 'name', 'id', 'checked', 'onChange'))),
                    React.createElement("label", { htmlFor: this.props.id, className: 'custom-control-label' }, this.props.label))));
        }
        catch (err) {
            lib_1.util.logError(err, null);
            return null;
        }
    };
    Radio.prototype.render = function () {
        return ((this.props['data-grid'] != null)
            ?
                React.createElement("div", { className: this.props['data-grid'] }, this.renderRadio())
            :
                this.renderRadio());
    };
    return Radio;
}(React.Component));
exports.Radio = Radio;
