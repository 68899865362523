"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var Redux = require("redux");
var react_router_dom_1 = require("react-router-dom");
var request = require("superagent");
var _ = require("lodash");
var uri = require("urijs");
var moment = require("moment");
var slugify_1 = require("slugify");
var pages_1 = require("../../../routes/pages");
var cfg = require("../../../cfg");
var lib_1 = require("../../lib");
var component_1 = require("../../component");
var DealSearch;
(function (DealSearch) {
    function searchDeal(query, callback) {
        lib_1.Auth.isAuthed(function () {
            var url = lib_1.Global.cruzApiBaseUrl + '/deals' + lib_1.util.buildApiQueryString(_.assign({}, cfg.apiQueryDefault._deal, query));
            request.get(url)
                .timeout(cfg.superagent.timeout.normal)
                .set(lib_1.Auth.authHeader())
                .retry(2)
                .end(function (err, apiRes) {
                return (err ? callback(err, null) : callback(null, apiRes.body));
            });
        });
    }
    DealSearch.searchDeal = searchDeal;
    DealSearch.app = function (state, action) {
        switch (action.type) {
            case 6: {
                return state;
            }
            case 0: {
                if (!state.is_loading) {
                    state.is_loading = true;
                    searchDeal({}, function (err, apiRes) {
                        if (err) {
                            DealSearch.store.dispatch({ type: 6, error: err });
                        }
                        else {
                            DealSearch.store.dispatch({ type: 2, data: apiRes, isLoadMore: false });
                        }
                    });
                }
                return state;
            }
            case 2: {
                var d = action.data;
                if (d) {
                    state.result.links = _.assign({}, d.links);
                    state.result.meta = _.assign({}, d.meta);
                    state.result.data = (action.isLoadMore) ? _.union(state.result.data, d.data) : d.data;
                }
                state.is_loading = false;
                state.is_loading_more = false;
                return state;
            }
            case 3: {
                try {
                    var d = action.data;
                    var x = _.find(state.result.data, { _id: d._id });
                    if (x) {
                        _.assign(x, d);
                    }
                }
                catch (err) {
                }
                finally {
                    return state;
                }
            }
            default: {
                return state;
            }
        }
    };
    DealSearch.store = null;
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            return _super.call(this, props) || this;
        }
        Page.prototype.componentWillReceiveProps = function (nextProps) {
            if (nextProps.history.action == 'PUSH') {
                DealSearch.store.dispatch({ type: 0 });
            }
        };
        Page.prototype.componentWillMount = function () {
            var _this = this;
            lib_1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    var locSearch = new uri(_this.props.location.search).search(true);
                    if (_this.props.staticContext) {
                        DealSearch.store = Redux.createStore(DealSearch.app, _.assign({}, _this.props.staticContext));
                    }
                    else if (DealSearch.store == null) {
                        var $initData = document.querySelector('#_init_' + pages_1.pages.deal_search.id);
                        if ($initData && $initData.getAttribute('data-init')) {
                            DealSearch.store = Redux.createStore(DealSearch.app, _.assign({}, {
                                result: JSON.parse(decodeURIComponent($initData.getAttribute('data-init'))),
                                query: _.assign({}, cfg.apiQueryDefault.deal, locSearch),
                                history: _this.props.history
                            }));
                        }
                        else {
                            var d = {
                                query: _.assign({}, cfg.apiQueryDefault.deal, locSearch),
                                result: {
                                    data: [],
                                    meta: {},
                                    links: {},
                                },
                            };
                            DealSearch.store = Redux.createStore(DealSearch.app, d);
                            DealSearch.store.dispatch({ type: 0 });
                        }
                    }
                    else if (_this.props.history.action == 'PUSH') {
                        DealSearch.store.dispatch({ type: 0 });
                    }
                    _this.unsubscribe = DealSearch.store.subscribe(function () { _this.forceUpdate(); });
                }
                else {
                    lib_1.Auth.redirectSignin(_this.props.location.pathname);
                }
            });
        };
        Page.prototype.componentDidMount = function () {
            document.title = lib_1.util.title(pages_1.pages.deal_search.title);
        };
        Page.prototype.componentWillUnmount = function () {
            this.unsubscribe && this.unsubscribe();
        };
        Page.prototype.renderDealLimit = function (deal) {
            var dl = deal.limit;
            return (React.createElement("div", { className: 'mt-1' }, dl.count_total_left > 0
                ?
                    React.createElement("span", null,
                        React.createElement("span", { className: 'c-font-weight-semi-bold c-font-color-2' }, dl.count_total_left.toLocaleString()),
                        " Remain",
                        React.createElement("span", { className: 'separator' }),
                        React.createElement("span", { className: 'c-font-weight-semi-bold' }, (dl.count_total - dl.count_total_left).toLocaleString()),
                        " Redeemed")
                :
                    React.createElement("span", { className: 'c-font-color-2' }, "This deal has sold out.")));
        };
        Page.prototype.renderDealSummary = function (deal) {
            try {
                var url = pages_1.pages.deal_detail.path + '/' + slugify_1.default(deal.title) + '/' + deal._id;
                var hasLimit = (!_.isEmpty(deal.limit) && deal.limit.is_active && deal.limit.count_total > 0);
                var my_user_id = !_.isEmpty(lib_1.Global.store.getState().user) ? lib_1.Global.store.getState().user._id : null;
                var isLiked = (!_.isEmpty(deal.vote) && my_user_id && deal.vote.up.user_list && _.indexOf(deal.vote.up.user_list, my_user_id) > -1);
                var like_count = (!_.isEmpty(deal.vote) && deal.vote.up.count > 0) ? deal.vote.up.count : 0;
                return (React.createElement("div", { className: 'card' },
                    React.createElement("div", { className: 'card-img-top' },
                        React.createElement(react_router_dom_1.Link, { to: url },
                            React.createElement("img", { className: 'img-fluid', src: cfg.photoBaseUrl[cfg.env].cdn + '/deal/' + deal.group + '/' + deal._id + '/' + deal.photo_list[0].id + '-md.jpg' }))),
                    React.createElement("div", { className: 'card-body' },
                        React.createElement("h4", { className: 'card-title mb-2 ellipsis' },
                            React.createElement(react_router_dom_1.Link, { to: url, className: 'c-btn-link' }, deal.title)),
                        React.createElement("div", { className: 'ellipsis c-font-weight-medium' }, deal.vendor_display.name || ''),
                        hasLimit
                            &&
                                this.renderDealLimit(deal),
                        (!hasLimit && deal.date_end && deal.date_end != '')
                            &&
                                React.createElement("div", { className: 'mt-1 c-font-size-xs' },
                                    "Expires: ",
                                    moment(deal.date_end).format(cfg.format.dateShort) + ' (' + moment(deal.date_end).fromNow() + ')')),
                    React.createElement("div", { className: 'card-footer c-card-footer' },
                        React.createElement("div", null),
                        React.createElement("div", { className: 'c-card-footer-row row no-gutters' },
                            React.createElement("div", { className: 'cc-item col' },
                                React.createElement("span", { className: 'count' }, deal.view_count.toLocaleString()),
                                React.createElement("span", { className: 'name' },
                                    "View",
                                    deal.view_count > 1 && 's')),
                            React.createElement("div", { className: 'cc-item col' },
                                React.createElement("span", { className: 'count' }, deal.comment_count.toLocaleString()),
                                React.createElement(react_router_dom_1.Link, { to: url + '#_comments' },
                                    React.createElement("span", { className: 'name' },
                                        "Comment",
                                        deal.comment_count > 1 && 's'))),
                            React.createElement("div", { className: 'cc-item col', title: (isLiked) && 'You liked this' },
                                isLiked
                                    &&
                                        React.createElement("i", { className: 'fas fa-heart c-fa-liked mr-2' }),
                                React.createElement("span", { className: 'count' }, like_count.toLocaleString()),
                                React.createElement("span", { className: 'name' },
                                    "Like",
                                    like_count > 1 && 's'),
                                (like_count > 0)
                                    &&
                                        React.createElement("button", { type: 'button', className: 'ml-2 c-btn-open-stat', onClick: component_1.Vote.openStatLikedModal.bind(this, { name: 'deal', _id: deal._id }) },
                                            React.createElement("i", { className: 'fal fa-list-ul' })))))));
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.renderDealList = function (dealList, classname) {
            var _this = this;
            if (!classname) {
                classname = 'col-sm-6 col-lg-4 col-xl-3 mb-3';
            }
            return (React.createElement("div", { className: 'row c-sm-gutters' }, _.map(dealList, function (deal, idx) {
                return (React.createElement("div", { className: classname, key: idx }, _this.renderDealSummary(deal)));
            })));
        };
        Page.prototype.renderDealGroup = function () {
            var dealList = DealSearch.store.getState().result.data;
            var sDealList = dealList.filter(function (d, idx) {
                return (d.is_spotlight == true && d.date_end > moment().toISOString());
            });
            var nDealList = dealList.filter(function (d, idx) {
                return ((!_.has(d, 'is_spotlight') || d.is_spotlight == false) && d.date_end > moment().toISOString());
            });
            return (React.createElement("div", { className: 'c-deals mt-4' },
                sDealList.length > 0 &&
                    React.createElement("section", null,
                        React.createElement("h2", { className: 'c-title c-title-sm' },
                            "Spotlight Deal",
                            sDealList.length > 1 ? 's' : ''),
                        React.createElement("hr", { className: 'c-hr-3 mt-2 mb-3' }),
                        this.renderDealList(sDealList, 'col-sm-6 col-lg-4')),
                nDealList.length > 0 &&
                    React.createElement("section", null,
                        sDealList.length > 0
                            &&
                                React.createElement("div", null,
                                    React.createElement("h2", { className: 'c-title c-title-sm' },
                                        "Other Deal",
                                        nDealList.length > 1 ? 's' : ''),
                                    React.createElement("hr", { className: 'c-hr-3 mt-2 mb-3' })),
                        this.renderDealList(nDealList))));
        };
        Page.prototype.renderNoDeal = function () {
            return (React.createElement("div", null, "No deal available, Please check back later."));
        };
        Page.prototype.renderAbout = function () {
            return (React.createElement("p", { className: 'c-font-size-l' }, "CRUZ\u00AE deals aren't just your average deals. They're the BEST deals around from leading local companies, and they're EXCLUSIVE to CRUZ\u00AE Members!"));
        };
        Page.prototype.render = function () {
            return (component_1.Layout.main(null, null, React.createElement("div", null,
                React.createElement(component_1.Hero, { title: pages_1.pages.deal_search.title, room: lib_1.util.getRoom(this.props.location.pathname) }),
                React.createElement("div", { className: 'container c-container' },
                    this.renderAbout(),
                    DealSearch.store == null || DealSearch.store.getState().is_loading
                        ?
                            React.createElement(component_1.Loading, null)
                        :
                            (DealSearch.store.getState().result.meta.total_records > 0)
                                ?
                                    this.renderDealGroup()
                                :
                                    this.renderNoDeal()))));
        };
        return Page;
    }(React.Component));
    DealSearch.Page = Page;
})(DealSearch = exports.DealSearch || (exports.DealSearch = {}));
