"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var _ = require("lodash");
var pages_1 = require("../../../routes/pages");
var __1 = require("..");
var lib_1 = require("../../lib");
var Layout;
(function (Layout) {
    Layout.entry = function (cols, elm, elmDirty) {
        try {
            var classNames_1 = '';
            if (cols == 1) {
                classNames_1 = 'c-section-container c-mx-1';
            }
            else if (cols == 2) {
                classNames_1 = 'c-section-container col-md-12 col-lg-8 mx-auto';
            }
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'c-layout' },
                    React.createElement("div", { className: 'c-theme-entry flex-ch-100' },
                        React.createElement("div", { className: 'container' },
                            React.createElement("div", { className: 'row justify-content-center' },
                                React.createElement("div", { className: classNames_1 },
                                    React.createElement("div", { className: 'text-center' },
                                        React.createElement(react_router_dom_1.Link, { to: pages_1.pages.home.path },
                                            React.createElement("img", { src: '/img/logo/cruz.png', alt: 'CRUZ.com', className: 'c-logo img-fluid' }))),
                                    elm)))),
                    React.createElement(__1.Footer.Footer, null),
                    React.createElement(__1.Toast, null)),
                React.createElement("div", { id: '_modal' }),
                !_.isEmpty(elmDirty) &&
                    elmDirty));
        }
        catch (err) {
            lib_1.util.logError(err, null);
            return null;
        }
    };
    function main(user, vendor, elm) {
        try {
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'c-layout' },
                    React.createElement(__1.Nav.Navbar, null),
                    React.createElement("div", { className: 'c-theme-main flex-ch-100' },
                        !_.isEmpty(user) &&
                            React.createElement(__1.User.Hero, { user: user }),
                        !_.isEmpty(vendor) &&
                            React.createElement(__1.Vendor.Hero, { vendor: vendor }),
                        elm,
                        React.createElement("div", { id: '_modal' }),
                        React.createElement("div", { id: '_sharedFiles' })),
                    React.createElement("div", { className: 'd-none d-md-block' },
                        React.createElement(__1.Footer.Footer, null))),
                React.createElement("div", { className: 'd-md-none', id: 'c-mobile-footer' },
                    React.createElement(__1.Footer.Footer, null)),
                React.createElement(__1.Toast, null)));
        }
        catch (err) {
            lib_1.util.logError(err, null);
            return null;
        }
    }
    Layout.main = main;
    function error(elm) {
        try {
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'c-theme-error' },
                    React.createElement("div", { className: 'container text-center' },
                        React.createElement(react_router_dom_1.Link, { to: pages_1.pages.home.path },
                            React.createElement("img", { src: '/img/logo/cruz.png', alt: 'CRUZ.com', className: 'c-logo img-fluid' })),
                        elm)),
                React.createElement(__1.Footer.Footer, null),
                React.createElement(__1.Toast, null)));
        }
        catch (err) {
            lib_1.util.logError(err, null);
            return null;
        }
    }
    Layout.error = error;
    function errorBrowser(elm) {
        try {
            return (React.createElement("div", { className: 'c-theme-error-browser' },
                React.createElement("div", { className: 'container text-center' },
                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.home.path },
                        React.createElement("img", { src: '/img/logo/cruz.png', alt: 'CRUZ.com', className: 'c-logo img-fluid' })),
                    elm)));
        }
        catch (err) {
            lib_1.util.logError(err, null);
            return null;
        }
    }
    Layout.errorBrowser = errorBrowser;
})(Layout = exports.Layout || (exports.Layout = {}));
