"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var react_player_1 = require("react-player");
var lib_1 = require("../lib");
var pages_1 = require("../../routes/pages");
var component_1 = require("../component");
var cfg = require("../../cfg");
var Rideshare;
(function (Rideshare) {
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            return _super.call(this, props) || this;
        }
        Page.prototype.componentDidMount = function () {
            document.title = lib_1.util.title(pages_1.pages.rideshare.title);
        };
        Page.prototype.componentWillUnmount = function () {
        };
        Page.prototype.renderHero = function () {
            return (React.createElement("div", { id: 'sec-hero', className: 'd-flex align-items-end' },
                React.createElement("div", { className: 'container' },
                    React.createElement("div", { className: 'row align-items-end' },
                        React.createElement("div", { className: 'col-md-7 col-lg-7 sec-hero-text pt-5' },
                            React.createElement("div", { className: 'sh-text-1' }, "NEW!"),
                            React.createElement("div", { className: 'sh-text-2' },
                                "Cruz\u00AE ",
                                React.createElement("span", { className: 'c-font-weight-bold' }, "RideShare")),
                            React.createElement("div", { className: 'sh-text-3' }, "Enabling people to Cruz\u00AE to the next destination whatever the journey presents."),
                            React.createElement("div", { className: 'mt-5 mb-md-5' },
                                React.createElement("a", { href: '#sec-app', className: 'btn c-btn-1 c-btn-round' }, "Download App"),
                                React.createElement("a", { href: '#sec-application', className: 'btn c-btn-2 c-btn-round ml-3' }, "Become a Driver")),
                            React.createElement("div", { className: 'sh-text-4' }, "Cruz\u00AE Rideshare is an on-demand transportation company that provides ridesharing services in the Midwest. Cruz\u00AE technology allows riders/drivers to safely get to and from their destination with ease. Cruz\u00AE Rideshare is continually enhancing the platform to provide the ultimate experience for both riders and drivers at the tap of a button.")),
                        React.createElement("div", { className: 'col-md-5 col-lg-4 offset-lg-1 text-center d-none d-md-block' },
                            React.createElement("a", { href: '#sec-app' },
                                React.createElement("img", { src: '/img/page/rideshare/app-mobile-hero.png', className: 'img-fluid sec-hero-img' })))))));
        };
        Page.prototype.renderIntro = function () {
            return (React.createElement("div", { id: 'sec-intro', className: 'text-center' },
                React.createElement("div", { className: 'container py-5 text-center' },
                    React.createElement("h2", { className: 'rs-title' }, "Why Rideshare?"),
                    React.createElement("div", { className: 'rs-lead' }, "Changing the world for the better"),
                    React.createElement("div", { className: 'row mt-3' },
                        React.createElement("div", { className: 'col-lg-4 mb-4 mb-lg-0' },
                            React.createElement("img", { src: '/img/page/rideshare/icon-easy.png', alt: 'connect', className: 'img-fluid icon' }),
                            React.createElement("h3", { className: 'rs-sub-title mt-3' }, "Easy"),
                            React.createElement("div", null, "At the tap of button you can Cruz\u00AE where you want, when you want.")),
                        React.createElement("div", { className: 'col-lg-4 mb-4 mb-lg-0' },
                            React.createElement("img", { src: '/img/page/rideshare/icon-safe.png', alt: 'environment', className: 'img-fluid icon' }),
                            React.createElement("h3", { className: 'rs-sub-title mt-3' }, "Safe"),
                            React.createElement("p", null, "Our drivers undergo extensive background checks and we provide a rating system so you can give feedback after every trip.")),
                        React.createElement("div", { className: 'col-lg-4' },
                            React.createElement("img", { src: '/img/page/rideshare/icon-affordable.png', alt: 'transportation', className: 'img-fluid icon' }),
                            React.createElement("h3", { className: 'rs-sub-title mt-3' }, "Affordable"),
                            React.createElement("div", null, "Keep more money in your wallet by taking our low cost everyday rides for one or many!"))))));
        };
        Page.prototype.renderPrivacyContent = function () {
            return (React.createElement("div", { id: 'sec-tcpp' },
                React.createElement("div", { className: 'container py-5' },
                    React.createElement("div", { id: 'accordion_tcpp' },
                        React.createElement("h2", null, "Terms & Conditions")))));
        };
        Page.prototype.renderFaqTCPP = function () {
            return (React.createElement("div", { id: 'sec-faq' },
                React.createElement("div", { className: 'container py-5' },
                    React.createElement("div", { className: 'row' },
                        React.createElement("div", { className: 'col-lg-8' },
                            React.createElement("h2", { className: 'rs-title' }, "FAQ's"),
                            React.createElement("div", { className: 'mb-4' }, "Have a question about Cruz\u00AE Rideshare? Browse our FAQs to help answer questions about riding or driving with Cruz\u00AE."),
                            React.createElement("div", { id: 'accordion_faq', className: 'ml-5' },
                                React.createElement("h3", null,
                                    React.createElement("button", { className: "btn rs-accordion-title", "data-toggle": "collapse", "data-target": "#faq1", "aria-expanded": "false", "aria-controls": "faq1" },
                                        React.createElement("i", { className: 'fal fa-angle-double-right mr-2' }),
                                        React.createElement("span", null, "Ride with Cruz\u00AE"))),
                                React.createElement("div", { id: 'faq1', className: 'collapse', "data-parent": '#accordion_faq' },
                                    React.createElement("dl", { className: 'ml-5' },
                                        React.createElement("dt", null, "Prime time"),
                                        React.createElement("dd", null, "At this time, Cruz\u00AE does not charge prime time rates."),
                                        React.createElement("dt", null, "Incorrectly charged"),
                                        React.createElement("dd", null,
                                            "If you feel you were charged incorrectly, please ",
                                            React.createElement("a", { href: pages_1.pages.contact.path, className: 'c-btn-link' }, "contact us"),
                                            " and a Cruz\u00AE team member will be in touch."),
                                        React.createElement("dt", null, "Cancellation policy"),
                                        React.createElement("dd", null, "As a rider, you may cancel your Cruz\u00AE ride request up until the driver arrives."),
                                        React.createElement("dt", null, "Service Fees"),
                                        React.createElement("dd", null, "On your receipt, you will notice a service fee of $2.00. This fee goes towards servicing and growing our Cruz\u00AE platform."),
                                        React.createElement("dt", null, "Lost and found"),
                                        React.createElement("dd", null,
                                            "If you left an item behind, please ",
                                            React.createElement("a", { href: pages_1.pages.contact.path, className: 'c-btn-link' }, "contact us"),
                                            " and a Cruz\u00AE team member will be in touch."))),
                                React.createElement("hr", null),
                                React.createElement("h3", null,
                                    React.createElement("button", { className: "btn rs-accordion-title", "data-toggle": "collapse", "data-target": "#faq2", "aria-expanded": "false", "aria-controls": "faq2" },
                                        React.createElement("i", { className: 'fal fa-angle-double-right mr-2' }),
                                        React.createElement("span", null, "Drive with Cruz\u00AE"))),
                                React.createElement("div", { id: 'faq2', className: 'collapse', "data-parent": '#accordion_faq' },
                                    React.createElement("dl", { className: 'ml-5' },
                                        React.createElement("dt", { className: 'q' }, "Vehicle Requirements"),
                                        React.createElement("dd", null,
                                            React.createElement("div", null, "To drive for Cruz\u00AE, your vehicle must meet the following requirements:"),
                                            React.createElement("ul", { className: 'my-0' },
                                                React.createElement("li", null, "Vehicle must have 4 doors and seat at least 4 passengers excluding the driver"),
                                                React.createElement("li", null, "Vehicle must be less than 10 years old"),
                                                React.createElement("li", null, "Vehicle must have valid in-state license plates"),
                                                React.createElement("li", null, "Vehicle must have current registration"),
                                                React.createElement("li", null, "Vehicle must not be marked as a taxi or salvaged vehicle"),
                                                React.createElement("li", null, "Vehicle must pass vehicle inspection test "))),
                                        React.createElement("dt", null, "Driver requirements"),
                                        React.createElement("dd", null, "Cruz\u00AE drivers must be of 21 years of age, have a valid driver\u2019s license with proof of insurance and pass our background check in order to drive on the Cruz\u00AE platform."),
                                        React.createElement("dt", null, "How to apply to become a driver"),
                                        React.createElement("dd", null,
                                            React.createElement("a", { href: '#application' }, "Download the application"),
                                            " and follow the onboarding process steps. Once complete, you will receive an email for additional information to review for your application."))),
                                React.createElement("hr", null),
                                React.createElement("h3", null,
                                    React.createElement("button", { className: "btn rs-accordion-title", "data-toggle": "collapse", "data-target": "#faq3", "aria-expanded": "false", "aria-controls": "faq3" },
                                        React.createElement("i", { className: 'fal fa-angle-double-right mr-2' }),
                                        React.createElement("span", null, "Safety"))),
                                React.createElement("div", { id: 'faq3', className: 'collapse', "data-parent": '#accordion_faq' },
                                    React.createElement("dl", { className: 'ml-5' },
                                        React.createElement("dt", null, "Reporting an accident"),
                                        React.createElement("dd", null,
                                            React.createElement("p", null, "Please follow your local laws to report an accident and make sure a claim is filed with your personal auto insurance provider and provide proof of personal insurance to relevant parties at the scene of an accident."),
                                            React.createElement("p", null,
                                                "Once safe to do so, report the collision to Cruz\u00AE by emailing ",
                                                React.createElement("a", { href: 'mailto: safety@cruz.com' }, "safety@cruz.com"),
                                                ". A Cruz\u00AE member will be in touch to collect the necessary information."),
                                            React.createElement("p", null, "As an approved Cruz\u00AE driver, you have coverage by different forms of insurance depending on which state you are in; 1, 2, or 3. Please see Cruz\u00AE insurance.")),
                                        React.createElement("dt", null, "Safety Video"),
                                        React.createElement("dd", { className: 'mt-2' },
                                            React.createElement(react_player_1.default, { url: cfg.assetsCdn + '/rideshare/cruz.rideshare.safty.mp4', controls: true, width: '100%', height: '100%' })),
                                        React.createElement("dt", null, "Two-way Ratings"),
                                        React.createElement("dd", null, "After each completed ride, Cruz\u00AE drivers and riders will be able to rate each other and the feedback is monitored by the Cruz\u00AE platform for safety and terms of service."),
                                        React.createElement("dt", null, "Cruz\u00AE Insurance"),
                                        React.createElement("dd", null,
                                            React.createElement("p", null, "The coverage applies during the time the Cruz\u00AE driver has the app on and is available to accept a rider request, the next is when you\u2019ve accepted a request to pick up a rider, and then continues while you are giving the ride until you complete the ride in the app."),
                                            React.createElement("img", { src: cfg.assetsCdn + '/rideshare/cruz.rideshare.insurance.coverage.png', className: 'img-fluid c-photo-enlarge', alt: 'insurance coverage', title: 'Insurance Coverage', onClick: function (e) { return lib_1.util.setModalPhotoEnlarge({ title: 'Cruz® RideShare Insurance Coverage', src: cfg.assetsCdn + '/rideshare/cruz.rideshare.insurance.coverage.png' }); } })),
                                        React.createElement("dt", null, "24/7 Support"),
                                        React.createElement("dd", null,
                                            "Need assistance? Please ",
                                            React.createElement(react_router_dom_1.Link, { to: pages_1.pages.contact.path }, "contact us"),
                                            "."),
                                        React.createElement("dt", null, "Safety Guidelines"),
                                        React.createElement("dd", null,
                                            React.createElement("ul", { className: 'mb-0' },
                                                React.createElement("li", null, "No solicitation or acceptance of cash payments or other compensation directly from the rider is allowed"),
                                                React.createElement("li", null, "As a Cruz\u00AE driver, you can only drive with your profile and approved car on the Cruz\u00AE platform. Your picture and license plate number will be displayed to the rider prior to entering your vehicle"),
                                                React.createElement("li", null, "As a Cruz\u00AE driver, you are not to be accompanied by any other person (no friends or family along) other than platform employees when driving rider(s) and their guests to destinations"),
                                                React.createElement("li", null, "As a Cruz\u00AE driver, you must utilize hands free or Bluetooth when using a mobile phone while driving. If you do not have hands free or Bluetooth, you must pull off the road into a safe location to use your mobile device."),
                                                React.createElement("li", null, "As a Cruz\u00AE driver, we recommend using a phone mount while in driver mode"),
                                                React.createElement("li", null, "Cruz\u00AE drivers must obey traffic laws"),
                                                React.createElement("li", null, "Cruz\u00AE prohibits the use of influence of drugs or alcohol by a driver while providing services through the Cruz\u00AE platform"))))),
                                React.createElement("hr", null),
                                React.createElement("h3", null,
                                    React.createElement("button", { className: "btn rs-accordion-title", "data-toggle": "collapse", "data-target": "#faq4", "aria-expanded": "false", "aria-controls": "faq4" },
                                        React.createElement("i", { className: 'fal fa-angle-double-right mr-2' }),
                                        React.createElement("span", null, "Rate Card"))),
                                React.createElement("div", { id: 'faq4', className: 'collapse', "data-parent": '#accordion_faq' },
                                    React.createElement("table", { className: 'table table-striped ml-5 mb-0 w-auto' },
                                        React.createElement("tbody", null,
                                            React.createElement("tr", null,
                                                React.createElement("td", null, "Cost Per Mile"),
                                                React.createElement("td", null, "$0.75")),
                                            React.createElement("tr", null,
                                                React.createElement("td", null, "Cost Per Minute"),
                                                React.createElement("td", null, "$0.25")),
                                            React.createElement("tr", null,
                                                React.createElement("td", null, "Application Fee"),
                                                React.createElement("td", null, "$2.00")),
                                            React.createElement("tr", null,
                                                React.createElement("td", null, "Base Fare"),
                                                React.createElement("td", null, "$1.50")),
                                            React.createElement("tr", null,
                                                React.createElement("td", null, "Minimum Fare"),
                                                React.createElement("td", null, "$3.50")))))))),
                    React.createElement("div", { className: 'mt-4' },
                        React.createElement("i", { className: 'fal fa-file-pdf fa-2x mr-3' }),
                        React.createElement("a", { href: cfg.assetsCdn + '/rideshare/cruz.rideshare.terms_conditions.pdf', className: 'rs-title-link', target: '_blank' },
                            React.createElement("span", null, "Terms & Conditions"))),
                    React.createElement("div", { className: 'mt-3' },
                        React.createElement("i", { className: 'fal fa-file-pdf fa-2x mr-3' }),
                        React.createElement("a", { href: cfg.assetsCdn + '/rideshare/cruz.rideshare.privacy_policy.pdf', className: 'rs-title-link', target: '_blank' },
                            React.createElement("span", null, "Privacy Policy"))))));
        };
        Page.prototype.renderApp = function () {
            return (React.createElement("div", { id: 'sec-app' },
                React.createElement("div", { className: 'container pt-5' },
                    React.createElement("div", { className: 'row align-items-center' },
                        React.createElement("div", { className: 'col-md-5 col-lg-3 d-none d-md-block' },
                            React.createElement("img", { className: 'img-fluid', src: '/img/page/rideshare/app-download.png', alt: 'App Download' })),
                        React.createElement("div", { className: 'col-md-7 col-lg-8 offset-lg-1' },
                            React.createElement("h2", { className: 'rs-title mt-2' }, "Let's Get Started!"),
                            React.createElement("div", { className: 'rs-lead' }, "Download. Ride. Arrive."),
                            React.createElement("div", { className: 'mt-2' }, "Are you ready to Cruz\u00AE! From your phone, please download from the Apple Store or Google Play Store and begin your journey today! Let us Cruz\u00AE you to your next destination."),
                            React.createElement("div", { className: 'my-4 pb-2' },
                                React.createElement("a", { href: '' },
                                    React.createElement("img", { className: 'img-fluid rs-app-icon', src: '/img/icon/app-store-apple.png', alt: 'Apple Store' })),
                                React.createElement("a", { href: '', className: 'ml-3' },
                                    React.createElement("img", { className: 'img-fluid rs-app-icon', src: '/img/icon/app-store-google.png', alt: 'Google Play Store' }))))))));
        };
        Page.prototype.renderForm = function () {
            return (React.createElement("div", { id: 'sec-application' },
                React.createElement("div", { className: 'container py-5' },
                    React.createElement("div", { className: 'row align-items-center' },
                        React.createElement("div", { className: 'col-md-5 col-lg-4 col-xl-3' },
                            React.createElement("h2", { className: 'rs-title text-uppercase mb-4 mb-md-0' }, "Become a Driver")),
                        React.createElement("div", { className: 'col-md-7 col-lg-8 col-xl-9' },
                            React.createElement("h3", { className: 'rs-sub-title' }, "Want to drive for Cruz\u00AE?"),
                            React.createElement("p", null, "Please download and fill out our application today and Cruz\u00AE into the money. Set your own schedule and drive when you want!"),
                            React.createElement("a", { href: cfg.assetsCdn + '/rideshare/Cruz.Driver.Application.Form.pdf', className: 'btn c-btn-2 c-btn-round c-btn-s mt-2', target: '_blank' },
                                React.createElement("i", { className: 'fal fa-file-pdf' }),
                                React.createElement("span", null, "Download Driver Forms")))))));
        };
        Page.prototype.render = function () {
            return (component_1.Layout.main(null, null, React.createElement("div", { className: 'c-rideshare' },
                this.renderHero(),
                this.renderIntro(),
                this.renderFaqTCPP(),
                this.renderApp(),
                this.renderForm())));
        };
        return Page;
    }(React.Component));
    Rideshare.Page = Page;
})(Rideshare = exports.Rideshare || (exports.Rideshare = {}));
