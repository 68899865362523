"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var Redux = require("redux");
var react_router_dom_1 = require("react-router-dom");
var _ = require("lodash");
var uri = require("urijs");
var classNames = require("classnames");
var $ = require("jquery");
var Cruz = require("cruz");
var lib_1 = require("../../lib");
var pages_1 = require("../../../routes/pages");
var component_1 = require("../../component");
var cfg = require("../../../cfg");
var vehicle_search_control_1 = require("./vehicle_search_control");
var VehicleSearch;
(function (VehicleSearch) {
    var rMapKeys = ['state_abbr', 'city'];
    var minSaveKeyCount = 2;
    var emptyOption = [React.createElement("option", { value: '', key: '-1' })];
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            var _this = _super.call(this, props) || this;
            _this.state = { filter: false };
            return _this;
        }
        Page.prototype.componentWillMount = function () {
            var me = lib_1.Global.store.getState().user;
            var mySettings = ((me && me.settings && me.settings.vehicle_search) ? _.pickBy(me.settings.vehicle_search, _.identity) : {});
            var locSearch = _.assign({}, mySettings, new uri(this.props.location.search).search(true));
            if (locSearch.body_type != null && typeof locSearch.body_type == 'string') {
                locSearch.body_type = [locSearch.body_type];
            }
            if (lib_1.util.isClient()) {
                vehicle_search_control_1.VehicleSearchControl.getInitSearchOptions(locSearch);
            }
            var saved_vehicle_filter_id = null;
            if (me && !_.isEmpty(me.saved_vehicle_filter_list)) {
                var x = _.find(me.saved_vehicle_filter_list, function (d) { return _.isEqual(_.omit(locSearch, vehicle_search_control_1.VehicleSearchControl.pageKeys), _.omit(d.filter, vehicle_search_control_1.VehicleSearchControl.pageKeys)); });
                if (!_.isEmpty(x)) {
                    saved_vehicle_filter_id = x.id;
                }
            }
            if (!_.isEmpty(this.props.staticContext)) {
                var query = _.assign({}, _.get(this.props.staticContext, 'query'), locSearch, _.get(this.props.staticContext, 'result.meta.map'));
                var s = _.assign({}, this.props.staticContext, {
                    query: query,
                    saved_vehicle_filter_id: saved_vehicle_filter_id,
                    show_filter: {},
                    searchOptions: { default: {}, specified: {} },
                    title: vehicle_search_control_1.VehicleSearchControl.getPageTitle(query, _.get(this.props.staticContext, 'result.meta.location'))
                });
                vehicle_search_control_1.VehicleSearchControl.store = Redux.createStore(vehicle_search_control_1.VehicleSearchControl.app, s);
            }
            else if (!vehicle_search_control_1.VehicleSearchControl.store) {
                var $initData = document.querySelector('#_init_' + pages_1.pages.vehicle_search.id);
                if ($initData && $initData.getAttribute('data-init')) {
                    var initData = JSON.parse(decodeURIComponent($initData.getAttribute('data-init')));
                    $initData.remove();
                    var query = _.assign({}, cfg.apiQueryDefault.vehicle, locSearch, _.get(initData.meta, 'map'));
                    vehicle_search_control_1.VehicleSearchControl.store = Redux.createStore(vehicle_search_control_1.VehicleSearchControl.app, _.assign({}, {
                        searchOptions: {
                            default: {},
                            specified: {}
                        },
                        result: initData,
                        query: _.assign({}, query),
                        prev_query: _.assign({}, query),
                        show_filter: {},
                        saved_vehicle_filter_id: saved_vehicle_filter_id,
                        title: vehicle_search_control_1.VehicleSearchControl.getPageTitle(query, _.get(initData, 'meta.location'))
                    }));
                    vehicle_search_control_1.VehicleSearchControl.getInitSearchOptions(query);
                }
                else {
                    var d = {
                        searchOptions: {
                            default: {},
                            specified: {}
                        },
                        query: _.assign({}, cfg.apiQueryDefault.vehicle, locSearch),
                        result: {
                            data: [],
                            meta: {},
                            links: {},
                        },
                        show_filter: {},
                        saved_vehicle_filter_id: saved_vehicle_filter_id
                    };
                    vehicle_search_control_1.VehicleSearchControl.store = Redux.createStore(vehicle_search_control_1.VehicleSearchControl.app, d);
                    vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 16, query: locSearch });
                }
            }
            else if (this.props.history.action == 'PUSH') {
                vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 16, query: locSearch });
            }
        };
        Page.prototype.componentDidMount = function () {
            var _this = this;
            document.title = vehicle_search_control_1.VehicleSearchControl.store.getState().title || pages_1.pages.vehicle_search.title;
            this.unsubscribe = vehicle_search_control_1.VehicleSearchControl.store.subscribe(function () { _this.forceUpdate(); });
        };
        Page.prototype.componentWillUnmount = function () {
            this.unsubscribe && this.unsubscribe();
        };
        Page.prototype.componentWillReceiveProps = function (nextProps) {
            if (nextProps.history.action == 'PUSH') {
                vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 16 });
            }
        };
        Page.prototype.onPostStatusUpdateDone = function (v) {
            vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 21, data: v });
        };
        Page.prototype.getNextPageUrl = function () {
            var q = _.assign({}, vehicle_search_control_1.VehicleSearchControl.store.getState().query, uri.parseQuery(new uri(vehicle_search_control_1.VehicleSearchControl.store.getState().result.links.next).query()));
            return pages_1.pages.vehicle_search.path + '?' + uri.buildQuery(q);
        };
        Page.prototype.renderVehicleList = function () {
            var _this = this;
            try {
                var state_1 = vehicle_search_control_1.VehicleSearchControl.store.getState();
                return (state_1.is_filtering
                    ?
                        React.createElement(component_1.Loading, { label: 'searching ...' })
                    :
                        (state_1.result.meta && state_1.result.meta.total_records_matched > 0)
                            ?
                                React.createElement(React.Fragment, null,
                                    React.createElement("div", { className: 'row c-sm-gutters' }, _.map(state_1.result.data, function (v, idx) {
                                        var showCondition = (_.isEmpty(_.pick(state_1.query, ['condition', 'seller'])) && v.condition != null);
                                        return (React.createElement("div", { className: 'col-xl-4 col-lg-6 col-md-6 mt-2 mb-2', key: v._id + idx, id: '_vs_' + idx },
                                            React.createElement(component_1.VehicleCard.Component, { vehicle: v, showUserInfo: true, showEdit: false, onPostStatusUpdateDone: _this.onPostStatusUpdateDone, shouldRender: state_1.result.meta.page_number < 2, showCondition: showCondition })));
                                    })),
                                    (!_.isEmpty(state_1.result.links) && !_.isEmpty(state_1.result.links.next)) &&
                                        React.createElement("div", { className: 'text-center mt-4' },
                                            React.createElement("a", { className: 'btn c-btn-2-rev c-btn-round', href: this.getNextPageUrl(), onClick: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 18, event: e }); } },
                                                React.createElement("i", { className: 'far fa-angle-double-down' }),
                                                state_1.is_loading_more ? React.createElement(component_1.BtnWorking, { value: 'Loading More...' }) : React.createElement("span", null,
                                                    "Load More (",
                                                    state_1.result.meta.total_records_left.toLocaleString(),
                                                    " left)"))))
                            :
                                React.createElement("div", { className: 'alert c-alert my-4 text-center' }, "No vehicle matched your search"));
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.render = function () {
            var isReady = (vehicle_search_control_1.VehicleSearchControl.store != null && !vehicle_search_control_1.VehicleSearchControl.store.getState().is_loading && !_.isEmpty(vehicle_search_control_1.VehicleSearchControl.store.getState().result));
            return (component_1.Layout.main(null, null, React.createElement(React.Fragment, null,
                React.createElement(component_1.Hero, { title: pages_1.pages.vehicle_search.title, room: lib_1.util.getRoom(pages_1.pages.vehicle_search.path) }),
                React.createElement("div", { className: 'container c-container flex-ch-100' }, isReady
                    ?
                        React.createElement(React.Fragment, null,
                            (!lib_1.util.isClient() && !_.isEmpty(_.get(vehicle_search_control_1.VehicleSearchControl.store.getState().result.meta, 'map'))) &&
                                lib_1.Schema.VehicleBreadcrumbList(vehicle_search_control_1.VehicleSearchControl.store.getState().query, lib_1.util.getRelativeUrl()),
                            React.createElement(Filter, null),
                            this.renderVehicleList())
                    :
                        React.createElement(component_1.Loading, null)))));
        };
        return Page;
    }(React.Component));
    VehicleSearch.Page = Page;
    var Filter = (function (_super) {
        __extends(Filter, _super);
        function Filter(props) {
            return _super.call(this, props) || this;
        }
        Filter.prototype.componentDidMount = function () {
            var _this = this;
            this.unsubscribe = vehicle_search_control_1.VehicleSearchControl.store.subscribe(function () { _this.forceUpdate(); });
            this.unsubscribeGlobal = lib_1.Global.store.subscribe(function () { _this.forceUpdate(); });
            $(document).on('mouseenter', '.btn_remove_af', function (e) {
                var id = e.target.id;
                $('#af_' + id).addClass('remove');
                if (['make', 'model'].includes(id)) {
                    _.each(['model', 'trim'], function (x) { $('#af_' + x).addClass('remove'); });
                }
            }).on('mouseleave', '.btn_remove_af', function (e) {
                $('div.af-item').removeClass('remove');
            });
        };
        Filter.prototype.componentWillUnmount = function () {
            this.unsubscribe && this.unsubscribe();
            this.unsubscribeGlobal && this.unsubscribeGlobal();
        };
        Filter.prototype.countUsingFilterByKeys = function (keys) {
            var query = _.omit(vehicle_search_control_1.VehicleSearchControl.store.getState().query, vehicle_search_control_1.VehicleSearchControl.pageKeys);
            var c = _.intersection(_.keys(query), keys).length;
            return c;
        };
        Filter.prototype.countSelectedFeatureTag = function () {
            var c = 0;
            var query = vehicle_search_control_1.VehicleSearchControl.store.getState().query;
            if (!_.isEmpty(query.tag)) {
                if (_.isArray(query.tag)) {
                    c = _.size(query.tag);
                }
                else {
                    c = 1;
                }
            }
            return c;
        };
        Filter.prototype.countOtherFilters = function () {
            var c = _.intersection(vehicle_search_control_1.VehicleSearchControl.filterKey.other, _.keys(vehicle_search_control_1.VehicleSearchControl.store.getState().query)).length;
            return c;
        };
        Filter.prototype.toggleFilter = function (name) {
            vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 13, data: name });
        };
        Filter.prototype.renderActiveFilter = function (key, filter, idx) {
            if (_.get(filter, 'value') == null) {
                return null;
            }
            var dFa = null;
            if (_.includes(key, '_max') && !_.includes(key, 'price') && !_.includes(key, 'year')) {
                dFa = 'fa-less-than';
            }
            return (React.createElement("div", { className: 'af-item d-flex', key: key, id: 'af_' + key + '_' + idx },
                React.createElement("div", { className: 'key_value' },
                    React.createElement("div", { className: 'key' }, filter.name),
                    React.createElement("div", null,
                        dFa ? React.createElement("i", { className: 'icon mr-1 fal ' + dFa }) : '',
                        React.createElement("span", { className: 'value' }, filter.value),
                        filter.unit ? React.createElement("span", { className: 'unit ml-1' }, filter.unit) : '')),
                React.createElement("div", { className: 'action d-flex align-items-center align-items-stretch' },
                    React.createElement("button", { type: 'button', className: 'btn btn_remove_af', id: key, onClick: function () {
                            return key == 'tag' ?
                                vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 11, data: filter.value }) :
                                vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 10, data: key });
                        } },
                        React.createElement("i", { className: 'fal fa-times' })))));
        };
        Filter.prototype.renderActiveFilterList = function () {
            var _this = this;
            try {
                var allowSaveFilter = false;
                var user = lib_1.Global.store.getState().user;
                if (!_.isEmpty(user)) {
                    var state = vehicle_search_control_1.VehicleSearchControl.store.getState();
                    var q_1 = _.omit(state.query, vehicle_search_control_1.VehicleSearchControl.pageKeys);
                    var qx = _.omit(q_1, 'sort');
                    if (qx.zip_code != null || _.size(qx.zip_code) != 5 || qx.distance == null) {
                        qx = _.omit(qx, ['zip_code', 'distance']);
                    }
                    if (_.keys(qx).length >= minSaveKeyCount) {
                        if (_.isEmpty(user.saved_vehicle_filter_list)) {
                            allowSaveFilter = true;
                        }
                        else if (state.saved_vehicle_filter_id == null) {
                            var x = _.find(user.saved_vehicle_filter_list, function (d) { return _.isEqual(q_1, _.omit(d.filter, 'sort')); });
                            allowSaveFilter = _.isEmpty(x);
                        }
                    }
                }
                var query_1 = _.omitBy(_.omit(vehicle_search_control_1.VehicleSearchControl.store.getState().query, vehicle_search_control_1.VehicleSearchControl.pageKeys, rMapKeys), function (x) { return x == null; });
                if (_.isEmpty(query_1)) {
                    return null;
                }
                else {
                    return (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: 'c-active-filter-list d-flex flex-wrap' },
                            _.map(_.keys(query_1), function (key, idx) {
                                if (key == 'tag') {
                                    return (_.map(_.isArray(query_1.tag) ? query_1.tag : [query_1.tag], function (tag, idxTag) {
                                        return _this.renderActiveFilter('tag', { name: 'Feature', value: tag }, idxTag);
                                    }));
                                }
                                else {
                                    return _this.renderActiveFilter(key, Cruz.VehicleFilter.getFilter(key, query_1[key]), idx);
                                }
                            }),
                            React.createElement("div", { className: 'af-item' },
                                React.createElement("button", { className: 'btn c-btn-2 px-3 fc-height', title: 'Reset Filter', onClick: function () { vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 12 }); } }, "Reset Filter")),
                            allowSaveFilter &&
                                React.createElement("div", { className: 'af-item' },
                                    React.createElement("button", { className: 'btn c-btn-1 px-3 fc-height', title: 'Save Filter', onClick: function () { lib_1.util.setModal({}, false, true, React.createElement(component_1.Saved.SaveFilterModal, { filter: vehicle_search_control_1.VehicleSearchControl.store.getState().query })); } }, "Save Filter"))),
                        React.createElement("hr", { className: 'mt-1' })));
                }
            }
            catch (err) {
                return null;
            }
        };
        Filter.prototype.renderMoreFilter = function () {
            var _this = this;
            try {
                var state = vehicle_search_control_1.VehicleSearchControl.store.getState();
                var options = state.searchOptions;
                if (_.isEmpty(options) || (_.isEmpty(options.default) && _.isEmpty(options.specified))) {
                    return null;
                }
                else {
                    var opt = _.isEmpty(options.specified) ? options.default : options.specified;
                    var optOther = _.concat(_.isEmpty(opt.market) ? [] : opt.market, _.isEmpty(options.default.other) ? [] : options.default.other);
                    var query_2 = state.query;
                    var sGrid = 'col-6 col-sm-4 col-lg-3 col-xl-2';
                    var sGridSpec = 'col-6 col-sm-4 col-lg-3 col-xl';
                    var has_engine_opt = !_.isEmpty(opt.engine);
                    var powertrainKeysAdjust = (_.isEmpty(opt.engine) ? -1 : -2);
                    var totalMoreFilters = _.size(vehicle_search_control_1.VehicleSearchControl.moreFilterKeys) + _.size(opt.tag) + _.size(optOther) + powertrainKeysAdjust;
                    var cf_more = this.countUsingFilterByKeys(vehicle_search_control_1.VehicleSearchControl.filterKey.more);
                    var cf_powertrain = this.countUsingFilterByKeys(vehicle_search_control_1.VehicleSearchControl.filterKey.powertrain);
                    var cf_feature = this.countSelectedFeatureTag();
                    var cf_specification = this.countUsingFilterByKeys(vehicle_search_control_1.VehicleSearchControl.filterKey.specification);
                    var cf_other = this.countOtherFilters();
                    var cf_all = cf_more + cf_powertrain + cf_feature + cf_specification + cf_other;
                    return (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: 'text-center mb-4' },
                            React.createElement("button", { type: 'button', className: 'btn c-btn-1 c-btn-xs c-btn-round', onClick: function (e) { return _this.toggleFilter('more'); } },
                                React.createElement("i", { className: classNames('fal', { 'fa-minus': state.show_filter.more }, { 'fa-plus': !state.show_filter.more }) }),
                                React.createElement("span", null,
                                    state.show_filter.more ? 'Less' : 'More',
                                    " Filters"),
                                React.createElement("span", { className: 'ml-2 c-font-family-default' },
                                    "(",
                                    cf_all || '-',
                                    "/",
                                    totalMoreFilters,
                                    ")"))),
                        React.createElement("div", { className: classNames({ 'd-none': !state.show_filter.more }) },
                            React.createElement("div", { className: 'c-veh-filter-bar' },
                                React.createElement("button", { className: 'c-btn-link', onClick: function () { return _this.toggleFilter('powertrain'); }, title: 'Powertrain' },
                                    React.createElement("i", { className: classNames('mr-3 fal', { 'fa-plus': !state.show_filter.powertrain }, { 'fa-minus': state.show_filter.powertrain }) }),
                                    "POWERTRAIN (",
                                    cf_powertrain || '-',
                                    "/",
                                    _.size(vehicle_search_control_1.VehicleSearchControl.filterKey.powertrain) + powertrainKeysAdjust,
                                    ")")),
                            React.createElement("div", { className: classNames('row c-sm-gutters', { 'd-none': !state.show_filter.powertrain }) },
                                React.createElement(component_1.Select, { id: 'drive_type', label: 'Drive Type', "data-grid": sGrid, size: 1, disabled: _.isEmpty(opt.drive_type), options: !_.isEmpty(opt.drive_type) && lib_1.util.mapSearchOptions(opt.drive_type), value: query_2.drive_type || '', onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 4, target: e.target }); } }),
                                React.createElement(component_1.Select, { id: 'trans_type', label: 'Transmission Type', "data-grid": sGrid, size: 1, disabled: _.isEmpty(opt.trans_type), options: !_.isEmpty(opt.trans_type) && lib_1.util.mapSearchOptions(opt.trans_type), value: query_2.trans_type || '', onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 4, target: e.target }); } }),
                                React.createElement(component_1.Select, { id: 'trans_gear', label: 'Transmission Gears', "data-grid": sGrid, size: 1, disabled: _.isEmpty(opt.trans_gear), options: !_.isEmpty(opt.trans_gear) && lib_1.util.mapNumberSearchOptions(opt.trans_gear), value: query_2.trans_gear || '', onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 4, target: e.target }); } }),
                                React.createElement(component_1.Select, { id: 'fuel_type', label: 'Fuel Type', "data-grid": sGrid, size: 1, disabled: _.isEmpty(opt.fuel_type), options: !_.isEmpty(opt.fuel_type) && lib_1.util.mapSearchOptions(opt.fuel_type), value: query_2.fuel_type || '', onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 4, target: e.target }); } }),
                                has_engine_opt
                                    &&
                                        React.createElement(component_1.Select, { id: 'engine_name', label: 'Engine', "data-grid": sGrid, size: 1, options: lib_1.util.mapSearchOptions(opt.engine), value: query_2.engine_name || '', onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 4, target: e.target }); } }),
                                !has_engine_opt
                                    &&
                                        React.createElement(React.Fragment, null,
                                            React.createElement(component_1.Select, { id: 'engine_aspiration', label: 'Engine Aspiration', "data-grid": sGrid, size: 1, disabled: _.isEmpty(opt.engine_aspiration), options: !_.isEmpty(opt.engine_aspiration) && lib_1.util.mapSearchOptions(opt.engine_aspiration), value: query_2.engine_aspiration || '', onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 4, target: e.target }); } }),
                                            React.createElement(component_1.Select, { id: 'engine_cylinder', label: 'Engine Cylinders', "data-grid": sGrid, size: 1, disabled: _.isEmpty(opt.engine_cylinder), options: !_.isEmpty(opt.engine_cylinder) && lib_1.util.mapNumberSearchOptions(opt.engine_cylinder), value: query_2.engine_cylinder || '', onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 4, target: e.target }); } })),
                                React.createElement(component_1.Select, { id: 'engine_hp_min', label: 'Horsepower', "data-grid": sGrid, size: 1, "data-input-append": 'hp', disabled: _.isEmpty(opt.engine_horsepower), options: !_.isEmpty(opt.engine_horsepower) && _.union(emptyOption, _.map(opt.engine_horsepower, function (n) { return React.createElement("option", { value: n, key: n },
                                        n.toLocaleString(),
                                        "+"); })), value: query_2.engine_hp_min || '', onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 4, target: e.target }); } }),
                                React.createElement(component_1.Select, { id: 'engine_torque_min', label: 'Torque', "data-grid": sGrid, size: 1, "data-input-append": 'ft.lbf', disabled: _.isEmpty(opt.engine_torque), options: !_.isEmpty(opt.engine_torque) && _.union(emptyOption, _.map(opt.engine_torque, function (n) { return React.createElement("option", { value: n, key: n },
                                        n.toLocaleString(),
                                        "+"); })), value: query_2.engine_torque_min || '', onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 4, target: e.target }); } }),
                                React.createElement(component_1.Select, { id: 'mpg_min', label: 'Fuel Economy', "data-grid": sGrid, size: 1, "data-input-append": 'mpg', disabled: _.isEmpty(opt.mpg), options: !_.isEmpty(opt.mpg) && _.union(emptyOption, _.map(opt.mpg, function (n) { return React.createElement("option", { value: n, key: n.toLocaleString() },
                                        n,
                                        "+"); })), value: query_2.mpg_min || '', onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 4, target: e.target }); } }),
                                React.createElement(component_1.Select, { id: 'towing_capacity_min', label: 'Towing Capacity', "data-grid": sGrid, size: 1, "data-input-append": 'lbs', disabled: _.isEmpty(opt.towing_capacity), options: !_.isEmpty(opt.towing_capacity) && _.union(emptyOption, _.map(opt.towing_capacity, function (n) { return React.createElement("option", { value: n, key: n },
                                        n.toLocaleString(),
                                        "+"); })), value: query_2.towing_capacity_min || '', onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 4, target: e.target }); } }),
                                React.createElement(component_1.Select, { id: 'acceleration_max', label: 'Acceleration', name: 'acceleration_max', "data-grid": sGrid, size: 1, "data-input-append": 'sec', disabled: _.isEmpty(opt.acceleration), options: !_.isEmpty(opt.acceleration) && _.union(emptyOption, _.map(opt.acceleration, function (n) { return React.createElement("option", { value: n, key: n }, '< ' + n); })), value: query_2.acceleration_max || '', onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 4, target: e.target }); } }),
                                React.createElement(component_1.Select, { id: 'range_min', label: 'Max Range', "data-grid": sGrid, size: 1, "data-input-append": 'miles', disabled: _.isEmpty(opt.range), options: !_.isEmpty(opt.range) && _.union(emptyOption, _.map(opt.range, function (n) { return React.createElement("option", { value: n, key: n },
                                        n.toLocaleString(),
                                        "+"); })), value: query_2.range_min || '', onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 4, target: e.target }); } })),
                            React.createElement("div", { className: 'c-veh-filter-bar' },
                                React.createElement("button", { className: 'c-btn-link', onClick: function () { return _this.toggleFilter('specification'); }, title: 'Specification' },
                                    React.createElement("i", { className: classNames('mr-3 fal', { 'fa-plus': !state.show_filter.specification }, { 'fa-minus': state.show_filter.specification }) }),
                                    "SPECIFICATION (",
                                    cf_specification || '-',
                                    "/",
                                    _.size(vehicle_search_control_1.VehicleSearchControl.filterKey.specification),
                                    ")")),
                            React.createElement("div", { className: classNames('row c-sm-gutters', { 'd-none': !state.show_filter.specification }) },
                                React.createElement(component_1.Select, { id: 'seats_min', label: 'Seats', "data-grid": sGridSpec, size: 1, disabled: _.isEmpty(opt.seat), options: !_.isEmpty(opt.seat) && _.union(emptyOption, _.map(opt.seat, function (n) { return React.createElement("option", { value: n, key: n },
                                        n,
                                        "+"); })), value: query_2.seats_min || '', onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 4, target: e.target }); } }),
                                React.createElement(component_1.Select, { id: 'seating_row', label: 'Seating Rows', "data-grid": sGridSpec, size: 1, disabled: _.isEmpty(opt.seating_row), options: !_.isEmpty(opt.seating_row) && lib_1.util.mapNumberSearchOptions(opt.seating_row), value: query_2.seating_row || '', onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 4, target: e.target }); } }),
                                React.createElement(component_1.Select, { id: 'door', label: 'Doors', "data-grid": sGridSpec, size: 1, disabled: _.isEmpty(opt.door), options: !_.isEmpty(opt.door) && lib_1.util.mapNumberSearchOptions(opt.door), value: query_2.door != null ? query_2.door : '', onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 4, target: e.target }); } }),
                                React.createElement(component_1.Select, { id: 'wheelbase_min', name: '', label: 'Wheelbase', "data-grid": sGridSpec, size: 1, "data-input-append": 'in.', disabled: _.isEmpty(opt.wheelbase), options: !_.isEmpty(opt.wheelbase) && _.union(emptyOption, _.map(opt.wheelbase, function (n) { return React.createElement("option", { value: n, key: n },
                                        n,
                                        "+"); })), value: query_2.wheelbase_min != null ? query_2.wheelbase_min : '', onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 4, target: e.target }); } }),
                                React.createElement(component_1.Select, { id: 'tonnage', label: 'Tonnage', "data-grid": sGridSpec, size: 1, disabled: _.isEmpty(opt.tonnage), options: !_.isEmpty(opt.tonnage) && lib_1.util.mapSearchOptions(_.map(opt.tonnage, function (x) { return { value: x, name: isNaN(Number(_.first(x))) ? x : x + ' ton' }; })), value: query_2.tonnage || '', onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 4, target: e.target }); } }),
                                React.createElement(component_1.Select, { id: 'bed_length_min', name: '', label: 'Bed Length', "data-grid": sGridSpec, size: 1, "data-input-append": 'in.', disabled: _.isEmpty(opt.bed_length), options: !_.isEmpty(opt.bed_length) && _.union(emptyOption, _.map(opt.bed_length, function (n) { return React.createElement("option", { value: n, key: n },
                                        n.toLocaleString(),
                                        "+"); })), value: query_2.bed_length_min || '', onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 4, target: e.target }); } }),
                                React.createElement(component_1.Select, { id: 'class', name: '', label: 'GVWR', "data-grid": sGridSpec, size: 1, "data-input-append": 'lbs', disabled: _.isEmpty(opt.class), options: !_.isEmpty(opt.class) && lib_1.util.mapSearchOptions(opt.class), value: query_2.class != null ? query_2.class : '', onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 4, target: e.target }); } })),
                            (!_.isEmpty(opt.tag))
                                &&
                                    React.createElement(React.Fragment, null,
                                        React.createElement("div", { className: 'c-veh-filter-bar' },
                                            React.createElement("button", { className: 'c-btn-link', onClick: function () { return _this.toggleFilter('feature'); }, title: 'Features' },
                                                React.createElement("i", { className: classNames('mr-3 fal', { 'fa-plus': !state.show_filter.feature }, { 'fa-minus': state.show_filter.feature }) }),
                                                "FEATURES (",
                                                cf_feature || '-',
                                                "/",
                                                _.size(opt.tag),
                                                ")")),
                                        React.createElement("ul", { className: classNames('list-inline', { 'd-none': !state.show_filter.feature }) }, _.map(opt.tag, function (tag, idx) {
                                            return (React.createElement("li", { key: idx, className: 'list-inline-item c-btn-checkbox' },
                                                React.createElement("input", { type: 'checkbox', autoComplete: 'off', id: 'tag_' + idx, value: tag, checked: query_2.tag ? query_2.tag.includes(tag) : false, onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 9, target: e.target }); } }),
                                                React.createElement("label", { htmlFor: 'tag_' + idx }, tag)));
                                        }))),
                            !_.isEmpty(optOther)
                                &&
                                    React.createElement(React.Fragment, null,
                                        React.createElement("div", { className: 'c-veh-filter-bar' },
                                            React.createElement("button", { className: 'c-btn-link', onClick: function () { return _this.toggleFilter('other'); }, title: 'Others' },
                                                React.createElement("i", { className: classNames('mr-3 fal', { 'fa-plus': !state.show_filter.other }, { 'fa-minus': state.show_filter.other }) }),
                                                "OTHERS (",
                                                cf_other || '-',
                                                "/",
                                                _.size(optOther),
                                                ")")),
                                        React.createElement("ul", { className: classNames('list-inline', { 'd-none': !state.show_filter.other }) }, _.map(optOther, function (o, idx) {
                                            return (React.createElement("li", { key: idx, className: 'list-inline-item c-btn-checkbox' },
                                                React.createElement("input", { type: 'checkbox', autoComplete: 'off', id: o.key, value: '1', checked: _.has(query_2, o.key), onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 4, target: e.target }); } }),
                                                React.createElement("label", { htmlFor: o.key }, o.name)));
                                        }))))));
                }
            }
            catch (err) {
                return null;
            }
        };
        Filter.prototype.renderMainFilter = function () {
            function mapGenerationOption(d) {
                return _.map(d, function (x) {
                    return {
                        name: _.toString(x.name) + (" (" + x.from + " - " + x.to + ")"),
                        value: _.toString(x.name)
                    };
                });
            }
            try {
                var state = vehicle_search_control_1.VehicleSearchControl.store.getState();
                var query_3 = state.query;
                var opt = _.isEmpty(state.searchOptions.specified) ? state.searchOptions.default : state.searchOptions.specified;
                var sGrid1 = 'col-6 col-md-4 col-lg-3 col-xl-2';
                var sGrid2 = 'col-12 col-md-4 col-lg-3 col-xl-4';
                var optPriceMin = _.isEmpty(query_3.price_max) ? opt.price : _.filter(opt.price, function (x) { return x < query_3.price_max; });
                var optPriceMax = _.isEmpty(query_3.price_min) ? opt.price : _.filter(opt.price, function (x) { return x > query_3.price_min; });
                return (React.createElement("div", { className: classNames({ 'd-none d-lg-block': !state.show_filter.main }) },
                    React.createElement("div", { className: 'row c-sm-gutters' },
                        React.createElement("input", { type: 'hidden', autoComplete: 'off' }),
                        cfg.env != 'production'
                            &&
                                React.createElement(component_1.Select, { id: 'condition', label: 'Condition', "data-grid": sGrid1, size: 1, disabled: _.isEmpty(opt.condition), options: !_.isEmpty(opt.condition) && lib_1.util.mapSearchOptions(opt.condition), value: query_3.condition || '', onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 4, target: e.target }); } }),
                        React.createElement(component_1.Select, { id: 'body_type', label: 'Type', "data-grid": sGrid1, size: 1, disabled: _.isEmpty(opt.body_type), options: !_.isEmpty(opt.body_type) && lib_1.util.mapBodyTypeOptions(opt.body_type, true), value: query_3.body_type ? query_3.body_type.toString() : '', onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 5, target: e.target }); } }),
                        React.createElement(component_1.Select, { id: 'make', label: 'Make', "data-grid": sGrid1, size: 1, disabled: _.isEmpty(opt.make_group) || _.isEmpty(opt.make_group.all), value: query_3.make || '', options: (lib_1.util.mapOptionGroup('Make', opt.make_group, { name: '' }, true)), onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 4, target: e.target }); } }),
                        query_3.make == '~'
                            ?
                                React.createElement(component_1.Select, { id: 'custom_brand', label: 'Brand', "data-grid": sGrid1, size: 1, disabled: _.isEmpty(opt.custom_brand), value: query_3.custom_brand || '', options: !_.isEmpty(opt.custom_brand) && lib_1.util.mapCustomBrandOptions(opt.custom_brand, true), onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 4, target: e.target }); } })
                            :
                                React.createElement(component_1.Select, { id: 'model', label: 'Model', "data-grid": sGrid1, size: 1, disabled: _.isEmpty(opt.model_group) || _.isEmpty(opt.model_group.all), value: query_3.model || '', options: !_.isEmpty(opt.model_group) && lib_1.util.mapOptionGroup('Model', opt.model_group, { name: '' }, false), onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 4, target: e.target }); } }),
                        React.createElement(component_1.Select, { id: 'trim', label: 'Trim', "data-grid": sGrid1, size: 1, disabled: _.isEmpty(opt.trim), options: !_.isEmpty(opt.trim) && lib_1.util.mapSearchOptions(_.map(opt.trim, function (x) { return { value: x, name: x }; })), value: query_3.trim || '', onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 4, target: e.target }); } }),
                        React.createElement(component_1.Select, { id: 'generation', label: 'Generation', "data-grid": sGrid1, size: 1, disabled: _.isEmpty(opt.generation), options: !_.isEmpty(opt.generation) && lib_1.util.mapSearchOptions(_.map(opt.generation, function (x) {
                                return {
                                    name: _.toString(x.name) + ' (' + x.from + ' - ' + x.to + ')', value: x.name
                                };
                            })), value: _.get(query_3, 'generation') || '', onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 4, target: e.target }); } }),
                        React.createElement("div", { className: sGrid1 },
                            React.createElement("div", { className: 'row no-gutters' },
                                React.createElement("div", { className: 'col' },
                                    React.createElement(component_1.Select, { id: 'year_min', size: 1, label: 'Year', disabled: opt.year_max == null && opt.year_min == null, value: query_3.year_min || '', options: (opt.year_max != null && opt.year_min != null) && lib_1.util.mapYearOptions(state.query.year_max || opt.year_max, opt.year_min), onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 6, target: e.target }); } })),
                                React.createElement("div", { className: 'col-md-auto' },
                                    React.createElement("span", { className: 'form-text-middle' }, "-")),
                                React.createElement("div", { className: 'col' },
                                    React.createElement(component_1.Select, { id: 'year_max', size: 1, label: 'Year', disabled: opt.year_max == null && opt.year_min == null, value: query_3.year_max || '', options: (opt.year_max != null && opt.year_min != null) && lib_1.util.mapYearOptions(opt.year_max, state.query.year_min || opt.year_min), onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 6, target: e.target }); } })))),
                        React.createElement(component_1.Select, { id: 'mileage_max', label: 'Mileage', "data-grid": sGrid1, size: 1, "data-input-append": 'miles', disabled: query_3.condition == Cruz.Lookup.Vehicle.ConditionEnum.New.toString() || _.isEmpty(opt.mileage), options: (query_3.condition != Cruz.Lookup.Vehicle.ConditionEnum.New.toString()) && _.union(emptyOption, _.map(opt.mileage, function (n) { return React.createElement("option", { value: n, key: n }, '< ' + n.toLocaleString()); })), value: query_3.mileage_max || '', onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 4, target: e.target }); } }),
                        React.createElement("div", { className: sGrid2 },
                            React.createElement("div", { className: 'form-group mb-0' },
                                React.createElement("div", { className: 'row no-gutters' },
                                    React.createElement("div", { className: 'col' },
                                        React.createElement(component_1.Select, { id: 'price_min', name: 'price_min', size: 1, label: 'Price From', disabled: _.isEmpty(opt.price), value: query_3.price_min || '', options: !_.isEmpty(opt.price) && _.union(emptyOption, _.map(optPriceMin, function (n) { return React.createElement("option", { value: n, key: n },
                                                "$",
                                                n.toLocaleString()); })), onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 4, target: e.target }); } })),
                                    React.createElement("div", { className: 'col-md-auto' },
                                        React.createElement("span", { className: 'form-text-middle' }, "to")),
                                    React.createElement("div", { className: 'col' },
                                        React.createElement(component_1.Select, { id: 'price_max', name: 'price_max', size: 1, label: 'Price To', disabled: _.isEmpty(opt.price), value: query_3.price_max || '', options: !_.isEmpty(opt.price) && _.union(emptyOption, _.map(optPriceMax, function (n, idx) { return React.createElement("option", { value: n, key: n + '_' + idx },
                                                "$",
                                                n.toLocaleString()); })), onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 4, target: e.target }); } }))))),
                        React.createElement(component_1.Select, { id: 'exterior_color', label: 'Exterior Color', "data-grid": sGrid1, size: 1, disabled: _.isEmpty(opt.exterior_color), options: !_.isEmpty(opt.exterior_color) && lib_1.util.mapColorGroupOptions(opt.exterior_color, true), value: query_3.exterior_color || '', onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 4, target: e.target }); } }),
                        React.createElement(component_1.Select, { id: 'interior_color', label: 'Interior Color', "data-grid": sGrid1, size: 1, disabled: _.isEmpty(opt.interior_color), options: !_.isEmpty(opt.interior_color) && lib_1.util.mapColorGroupOptions(opt.interior_color, true), value: query_3.interior_color || '', onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 4, target: e.target }); } }),
                        React.createElement(component_1.Select, { id: 'country', label: 'Assembled In', "data-grid": sGrid1, size: 1, disabled: _.isEmpty(opt.country), options: !_.isEmpty(opt.country) && lib_1.util.mapSearchOptions(opt.country), value: query_3.country || '', onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 4, target: e.target }); } }),
                        cfg.env != 'production' &&
                            React.createElement(component_1.Select, { id: 'seller', label: 'Seller', "data-grid": sGrid1, size: 1, disabled: _.isEmpty(opt.seller), options: lib_1.util.mapSearchOptions(opt.seller), value: query_3.seller || '', onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 4, target: e.target }); } }),
                        React.createElement(component_1.Select, { id: 'sale_status', size: 1, label: 'Sale Status', "data-grid": sGrid1, disabled: _.isEmpty(opt.sale_status), options: !_.isEmpty(opt.sale_status) && lib_1.util.mapSaleStatusOptions(opt.sale_status, true), value: query_3.sale_status || '', onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 4, target: e.target }); } }),
                        React.createElement(component_1.MaskedText, { type: 'tel', id: 'zip_code', name: 'zip_code', label: 'Zip Code', placeholder: '', mask: lib_1.Mask.TypeEnum.ZipCode, "data-grid": sGrid1, value: query_3.zip_code || '', onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 7, target: e.target }); } }),
                        React.createElement(component_1.Select, { id: 'distance', label: 'Radius', "data-grid": sGrid1, size: 1, "data-ss": true, "data-input-append": 'miles', options: lib_1.util.mapNumberSearchOptions(state.searchOptions.default.distance), value: query_3.distance || '', onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 8, target: e.target }); } })),
                    this.renderMoreFilter()));
            }
            catch (err) {
                return null;
            }
        };
        Filter.prototype.renderSaveFilterTip = function () {
            var user = lib_1.Global.store.getState().user;
            var showTip = false;
            if (_.isEmpty(user)) {
                showTip = (_.keys(_.omit(vehicle_search_control_1.VehicleSearchControl.store.getState().query, vehicle_search_control_1.VehicleSearchControl.pageKeys)).length > minSaveKeyCount);
            }
            if (showTip) {
                return (React.createElement("div", { className: 'text-md-center' },
                    "Do you want to save your filters? Please ",
                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.signin.path + '?returnUrl=' + lib_1.util.getRelativeUrl(), className: 'c-btn-link' }, "Sign in"),
                    " to save this filter!",
                    React.createElement("br", null),
                    "Don't have an account? ",
                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.register.path, className: 'c-btn-link' }, "Create a Free Account"),
                    ".",
                    React.createElement("hr", null)));
            }
            else {
                return null;
            }
        };
        Filter.prototype.render = function () {
            var state = vehicle_search_control_1.VehicleSearchControl.store.getState();
            var user = lib_1.Global.store.getState().user;
            var zip_code = null;
            if (!_.isEmpty(state.query.zip_code) && _.size(state.query.zip_code) == 5) {
                zip_code = state.query.zip_code;
            }
            else if (!_.isEmpty(user) && !_.isEmpty(user.location.zip_code)) {
                zip_code = user.location.zip_code;
            }
            var meta = state.result.meta;
            var isFiltered = (meta.total_records != meta.total_records_matched);
            var sid = vehicle_search_control_1.VehicleSearchControl.store.getState().saved_vehicle_filter_id;
            return (React.createElement("div", { className: 'mb-2' },
                React.createElement("div", { className: 'row align-items-center' },
                    React.createElement("div", { className: 'col-md-6' },
                        React.createElement("h1", { className: 'c-title pr-3 pb-3' },
                            React.createElement("span", { className: 'pr-2 mr-1' }, state.title),
                            (!state.is_loading && !_.isEmpty(meta) && meta.total_records != null && meta.total_records > 0 && meta.total_records_matched != null) &&
                                React.createElement("span", { className: 'c-title-count ml-0' }, state.is_filtering
                                    ?
                                        React.createElement("i", { className: 'fal fa-spinner fa-spin' })
                                    :
                                        isFiltered
                                            ?
                                                meta.total_records_matched.toLocaleString() + '/' + meta.total_records.toLocaleString()
                                            :
                                                meta.total_records.toLocaleString()))),
                    React.createElement("div", { className: 'col-md-6' }, (!_.isEmpty(user) && !_.isEmpty(user.saved_vehicle_filter_list)) &&
                        React.createElement("div", { className: 'd-md-flex justify-content-end' },
                            React.createElement("div", { className: classNames('form-group mb-3', { 'has-value': sid != null }) },
                                React.createElement("select", { id: 'saved_vehicle_filter_list', size: 1, name: '', className: 'form-control', value: sid || '', onChange: function (e) { vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 14, target: e.target }); } }, _.union([React.createElement("option", { key: 0 })], _.map(user.saved_vehicle_filter_list, function (d) { return React.createElement("option", { value: d.id, key: d.id }, d.name); }))),
                                React.createElement("label", { className: 'control-label' }, "Saved Filter (" + user.saved_vehicle_filter_list.length + ")"))))),
                React.createElement("hr", { className: 'mt-0 d-none d-md-block' }),
                React.createElement("div", { className: 'text-center d-lg-none mt-1 mb-4' },
                    React.createElement("button", { type: 'button', className: 'c-btn-collapse', onClick: function () { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 13, data: 'main' }); } },
                        React.createElement("i", { className: classNames('fal', 'fa-' + (state.show_filter.main ? 'minus' : 'plus')) }),
                        React.createElement("span", null,
                            state.show_filter.main ? 'Hide' : 'Show',
                            " Filters"))),
                this.renderMainFilter(),
                React.createElement("hr", { className: 'd-none d-md-block mt-0' }),
                !_.isEmpty(state.searchOptions.default) &&
                    this.renderActiveFilterList(),
                this.renderSaveFilterTip(),
                React.createElement(component_1.Select, { id: 'sort', name: '', size: 1, label: 'Sort', className: 'd-block d-md-inline-block mb-2', options: lib_1.util.mapSortOptions(lib_1.Lookup.vehicleSort, zip_code), value: state.query.sort != null ? state.query.sort : 1, onChange: function (e) { return vehicle_search_control_1.VehicleSearchControl.store.dispatch({ type: 4, target: e.target }); } })));
        };
        return Filter;
    }(React.Component));
})(VehicleSearch = exports.VehicleSearch || (exports.VehicleSearch = {}));
