"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var Autosuggest = require("react-autosuggest");
var request = require("superagent");
var _ = require("lodash");
var cfg = require("../../../cfg");
var lib_1 = require("../../lib");
var LocationInput = (function (_super) {
    __extends(LocationInput, _super);
    function LocationInput(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            suggestions: []
        };
        return _this;
    }
    LocationInput.prototype.componentDidMount = function () {
        var _this = this;
        if (!_.isEmpty(this.props.label)) {
            setTimeout(function () {
                $('#form_group_' + _this.props.id + ' .react-autosuggest__container').append("<label for='" + _this.props.id + "' class='" + lib_1.util.formLabelClass(_this.props) + "'>" + _this.props.label + "</label>");
            }, 1);
        }
    };
    LocationInput.prototype.onChange = function (e, _a) {
        var newValue = _a.newValue, method = _a.method;
        this.setState({ value: newValue });
        this.props.onChange(newValue);
    };
    LocationInput.prototype.onFetch = function (value) {
        var loc = this;
        request.get(lib_1.Global.cruzApiBaseUrl + '/geo/city-state-suggestion/' + encodeURI(value.value))
            .timeout(cfg.superagent.timeout.normal)
            .end(function (err, apiRes) {
            var suggestions = [];
            if (!err && apiRes.body && apiRes.body.data) {
                suggestions = apiRes.body.data;
            }
            loc.setState({ suggestions: suggestions });
        });
    };
    LocationInput.prototype.onClear = function () {
        this.setState({ suggestions: [] });
    };
    LocationInput.prototype.getValue = function (suggestion) {
        return suggestion.city + ', ' + suggestion.state;
    };
    LocationInput.prototype.doRender = function (suggestion) {
        return (React.createElement("span", null, suggestion.city + ', ' + suggestion.state));
    };
    LocationInput.prototype.shouldRender = function (value) {
        return (value != null && value.trim().length > 1);
    };
    LocationInput.prototype.render = function () {
        try {
            var inputProps = {
                id: this.props.id,
                placeholder: this.props.placeholder,
                value: this.props.value.toString(),
                onChange: this.onChange.bind(this),
                className: 'form-control'
            };
            return (React.createElement("div", { className: this.props['data-grid'] },
                React.createElement("div", { className: lib_1.util.formGroupClass(this.props), id: 'form_group_' + this.props.id },
                    React.createElement(Autosuggest, { inputProps: inputProps, suggestions: this.state.suggestions, onSuggestionsFetchRequested: this.onFetch.bind(this), onSuggestionsClearRequested: this.onClear.bind(this), getSuggestionValue: this.getValue.bind(this), renderSuggestion: this.doRender.bind(this), shouldRenderSuggestions: this.shouldRender.bind(this) }),
                    React.createElement("span", { className: 'form-control-feedback', "aria-hidden": true }, this.props['data-err']),
                    React.createElement("span", { className: 'form-control-icon' }))));
        }
        catch (err) {
            lib_1.util.logError(err, null);
            return null;
        }
    };
    return LocationInput;
}(React.Component));
exports.LocationInput = LocationInput;
