"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var _ = require("lodash");
var Cruz = require("cruz");
var lib_1 = require("../../lib");
var pages_1 = require("../../../routes/pages");
var UserCruz;
(function (UserCruz) {
    function init(user_id, callback) {
        lib_1.Request.user.profile(user_id, function (err, apiRes) {
            if (err) {
                return callback(err, null);
            }
            else {
                return callback(null, apiRes.body.data);
            }
        });
    }
    UserCruz.init = init;
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            return _super.call(this, props) || this;
        }
        Page.prototype.componentWillMount = function () {
            var _this = this;
            lib_1.Auth.isAuthed(function () {
                var params = _this.props.match.params;
                if (!params.user_name || !params.user_id) {
                    lib_1.Global.history.replace(pages_1.pages.error.path + '/404');
                }
                else {
                    init(params.user_id, function (err, user) {
                        if (err) {
                            lib_1.Global.history.replace(pages_1.pages.error.path + '/500');
                        }
                        else {
                            if (user.status_id == Cruz.Lookup.CruzUser.StatusEnum.Closed || user.status_id == Cruz.Lookup.CruzUser.StatusEnum.Disabled) {
                                lib_1.Global.history.replace(pages_1.pages.error.path + '/410');
                            }
                            else {
                                var me = lib_1.Global.store.getState().user;
                                var xPath = null;
                                if (me && !_.isEmpty(me.settings) && me.settings.user_cruz_path) {
                                    xPath = me.settings.user_cruz_path;
                                }
                                else {
                                    xPath = pages_1.pages.user_vehicle.path;
                                }
                                lib_1.Global.history.replace('/user/' + params.user_name + '/' + params.user_id + '/' + _.last(xPath.split('/')));
                            }
                        }
                    });
                }
            });
        };
        Page.prototype.render = function () {
            return null;
        };
        return Page;
    }(React.Component));
    UserCruz.Page = Page;
})(UserCruz = exports.UserCruz || (exports.UserCruz = {}));
