"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var history_1 = require("history");
var uri = require("urijs");
var _ = require("lodash");
var classNames = require("classnames");
var numeral = require("numeral");
var lib_1 = require("../../lib");
var __1 = require("..");
var pages_1 = require("../../../routes/pages");
var Nav;
(function (Nav) {
    var MemberCount = (function (_super) {
        __extends(MemberCount, _super);
        function MemberCount(props) {
            return _super.call(this, props) || this;
        }
        MemberCount.prototype.componentDidMount = function () {
            var _this = this;
            this.unsubscribe = lib_1.Statistic.store.subscribe(function () { return _this.forceUpdate(); });
        };
        MemberCount.prototype.componentWillUnmount = function () {
            this.unsubscribe && this.unsubscribe();
        };
        MemberCount.prototype.render = function () {
            var stat = lib_1.Statistic.store.getState();
            if (!_.isEmpty(stat.statistic) && stat.statistic.user_count > 0) {
                return React.createElement("span", { className: 'c-nav-member-count d-none d-sm-inline-block d-lg-none d-xl-inline-block ml-2' },
                    stat.statistic.user_count.toLocaleString(),
                    " members");
            }
            else {
                return null;
            }
        };
        return MemberCount;
    }(React.Component));
    var NavMain = (function (_super) {
        __extends(NavMain, _super);
        function NavMain(props) {
            return _super.call(this, props) || this;
        }
        NavMain.prototype.render = function () {
            var user = this.props.user;
            var pathname = lib_1.Global.history.location.pathname;
            return (React.createElement(React.Fragment, null,
                _.isEmpty(user)
                    &&
                        React.createElement(react_router_dom_1.Link, { to: pages_1.pages.register.path, className: 'nav-item d-block d-sm-none' }, pages_1.pages.register.title),
                React.createElement(react_router_dom_1.Link, { to: pages_1.pages.vehicle_search.path, className: classNames('nav-item', { active: pathname == pages_1.pages.vehicle_search.path }) }, pages_1.pages.vehicle_search.title),
                React.createElement(react_router_dom_1.Link, { to: pages_1.pages.spotted_search.path, className: classNames('nav-item', { active: pathname == pages_1.pages.spotted_search.path }) }, pages_1.pages.spotted_search.title),
                React.createElement(react_router_dom_1.Link, { to: pages_1.pages.service_quote.path, className: classNames('nav-item', { active: pathname == pages_1.pages.service_quote.path }) }, pages_1.pages.service_quote.title),
                React.createElement(react_router_dom_1.Link, { to: pages_1.pages.my_cruz.path, className: classNames('nav-item', { active: _.chain(pathname).split('/').indexOf('my').value() > -1 }) }, pages_1.pages.my_cruz.title),
                !_.isEmpty(user)
                    &&
                        React.createElement("div", { className: 'ml-4 mb-2 d-block d-lg-none' },
                            React.createElement(react_router_dom_1.Link, { to: pages_1.pages.my_saved.path, className: classNames('nav-item', 'd-block', { active: pathname == pages_1.pages.my_saved.path }) }, pages_1.pages.my_saved.title),
                            React.createElement(react_router_dom_1.Link, { to: pages_1.pages.my_vehicle.path, className: classNames('nav-item', 'd-block', { active: pathname == pages_1.pages.my_vehicle.path }) }, pages_1.pages.my_vehicle.title),
                            React.createElement(react_router_dom_1.Link, { to: pages_1.pages.my_service_quote.path, className: classNames('nav-item', 'd-block', { active: pathname == pages_1.pages.my_service_quote.path }) }, pages_1.pages.my_service_quote.title),
                            React.createElement(react_router_dom_1.Link, { to: pages_1.pages.my_spotted.path, className: classNames('nav-item', 'd-block', { active: pathname == pages_1.pages.my_spotted.path }) }, pages_1.pages.my_spotted.title),
                            React.createElement(react_router_dom_1.Link, { to: pages_1.pages.my_follow.path, className: classNames('nav-item', 'd-block', { active: pathname == pages_1.pages.my_follow.path }) }, pages_1.pages.my_follow.title),
                            React.createElement(react_router_dom_1.Link, { to: pages_1.pages.my_referral.path, className: classNames('nav-item', 'd-block', { active: pathname == pages_1.pages.my_referral.path }) }, pages_1.pages.my_referral.title))));
        };
        return NavMain;
    }(React.Component));
    Nav.NavMain = NavMain;
    var NavUser = (function (_super) {
        __extends(NavUser, _super);
        function NavUser(props) {
            return _super.call(this, props) || this;
        }
        NavUser.prototype.componentDidMount = function () {
            var _this = this;
            this.unsubscribeGlobal = lib_1.Global.store.subscribe(function () { return _this.forceUpdate(); });
        };
        NavUser.prototype.componentWillUnmount = function () {
            this.unsubscribeGlobal && this.unsubscribeGlobal();
        };
        NavUser.prototype.render = function () {
            var gState = lib_1.Global.store.getState();
            var user = gState.user;
            var urlPathname = lib_1.Global.history.location.pathname;
            var urlSearch = lib_1.Global.history.location.search;
            var n_notification = numeral(_.get(user.notification, 'new_notification_count'));
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'c-nav-user h-100 d-flex align-items-center' }, (_.isEmpty(user))
                    ?
                        React.createElement("div", { className: 'mr-2 d-inline-flex h-100' },
                            React.createElement(react_router_dom_1.Link, { to: pages_1.pages.signin.path + '?returnUrl=' + encodeURI(urlPathname + urlSearch), className: 'ml-3 btn btn-nav-user d-flex align-items-center' },
                                React.createElement("i", { className: 'fal fa-user-alt fa-lg mr-1' }),
                                React.createElement("span", null, pages_1.pages.signin.title)),
                            React.createElement("div", { className: 'separator d-none d-sm-flex align-items-center mx-3' }),
                            React.createElement(react_router_dom_1.Link, { to: pages_1.pages.register.path, className: 'btn btn-nav-user d-none d-sm-flex align-items-center' }, pages_1.pages.register.title))
                    :
                        React.createElement(React.Fragment, null,
                            React.createElement(react_router_dom_1.Link, { to: pages_1.pages.messaging.path, className: classNames('nav-item d-flex align-items-center', { active: urlPathname == pages_1.pages.messaging.path }), title: pages_1.pages.messaging.title },
                                React.createElement("span", { className: 'position-relative' },
                                    React.createElement("i", { className: 'fal fa-comments-alt fa-lg' }),
                                    gState.has_new_message
                                        &&
                                            React.createElement("span", { className: 'c-nav-alert' }))),
                            React.createElement(react_router_dom_1.Link, { to: pages_1.pages.notification.path, className: classNames('nav-item d-flex align-items-center', { active: urlPathname == pages_1.pages.notification.path }), title: pages_1.pages.notification.title },
                                React.createElement("span", { className: 'position-relative' },
                                    React.createElement("i", { className: 'fal fa-bell fa-lg' }),
                                    n_notification.value() > 0
                                        &&
                                            React.createElement("span", { className: 'c-nav-count' }, n_notification.format('0,0')))),
                            React.createElement("div", { className: 'dropdown nav-item ml-3' },
                                React.createElement("button", { className: 'btn btn-nav-user dropdown-toggle h-100 d-flex align-items-center', type: 'button', "data-toggle": 'dropdown', id: 'dpMenuUser' },
                                    React.createElement("span", { className: 'mr-1 mr-md-3' }, __1.User.renderUserIcon(user)),
                                    React.createElement("span", { className: 'c-name mr-2 d-none d-md-inline-block text-uppercase' }, !_.isEmpty(user) && _.get(user, 'first_name') && _.get(user, 'last_name')
                                        &&
                                            (user.first_name.length > 9 ? user.first_name.substring(0, 7) + '...' : user.first_name + '.' + user.last_name.substring(0, 1)))),
                                React.createElement("div", { className: 'dropdown-menu dropdown-menu-right', "aria-labelledby": 'dpMenuUser' },
                                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.messaging.path, className: classNames('dropdown-item', { active: urlPathname == pages_1.pages.messaging.path }) },
                                        pages_1.pages.messaging.title,
                                        gState.has_new_message
                                            &&
                                                React.createElement("span", { className: 'c-dropdown-item-alert' })),
                                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.notification.path, className: classNames('dropdown-item', { active: urlPathname == pages_1.pages.notification.path }) },
                                        pages_1.pages.notification.title,
                                        n_notification.value() > 0
                                            &&
                                                React.createElement("span", { className: 'c-dropdown-item-alert' })),
                                    React.createElement("div", { className: "dropdown-divider" }),
                                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.my_saved.path, className: classNames('dropdown-item', { active: urlPathname == pages_1.pages.my_saved.path }) }, pages_1.pages.my_saved.title),
                                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.my_vehicle.path, className: classNames('dropdown-item', { active: urlPathname == pages_1.pages.my_vehicle.path }) }, pages_1.pages.my_vehicle.title),
                                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.my_service_quote.path, className: classNames('dropdown-item', { active: urlPathname == pages_1.pages.my_service_quote.path }) }, pages_1.pages.my_service_quote.title),
                                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.my_spotted.path, className: classNames('dropdown-item', { active: urlPathname == pages_1.pages.my_spotted.path }) }, pages_1.pages.my_spotted.title),
                                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.my_follow.path, className: classNames('dropdown-item', { active: urlPathname == pages_1.pages.my_follow.path }) }, pages_1.pages.my_follow.title),
                                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.my_referral.path, className: classNames('dropdown-item', { active: urlPathname == pages_1.pages.my_referral.path }) }, pages_1.pages.my_referral.title),
                                    React.createElement("div", { className: "dropdown-divider" }),
                                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.my_profile.path, className: classNames('dropdown-item', { active: urlPathname == pages_1.pages.my_profile.path }) },
                                        React.createElement("i", { className: 'fal fa-user-alt fa-fw' }),
                                        React.createElement("span", null, pages_1.pages.my_profile.title)),
                                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.change_email.path, className: classNames('dropdown-item', { active: urlPathname == pages_1.pages.change_email.path }) },
                                        React.createElement("i", { className: 'fal fa-at fa-fw' }),
                                        React.createElement("span", null, pages_1.pages.change_email.title)),
                                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.change_password.path, className: classNames('dropdown-item', { active: urlPathname == pages_1.pages.change_password.path }) },
                                        React.createElement("i", { className: 'fal fa-key fa-fw' }),
                                        React.createElement("span", null, pages_1.pages.change_password.title)),
                                    React.createElement("div", { className: "dropdown-divider" }),
                                    React.createElement("button", { className: 'dropdown-item', type: 'button', onClick: function () { return lib_1.Auth.signout(); } },
                                        React.createElement("i", { className: 'fal fa-sign-out fa-fw' }),
                                        React.createElement("span", null, "Sign Out")))))),
                React.createElement("button", { className: "navbar-toggler ml-3", type: "button", "data-toggle": "collapse", "data-target": "#navbarNav", "aria-expanded": "false", "aria-label": "Toggle navigation" },
                    React.createElement("i", { className: 'fal fa-bars' }))));
        };
        return NavUser;
    }(React.Component));
    Nav.NavUser = NavUser;
    var Navbar = (function (_super) {
        __extends(Navbar, _super);
        function Navbar(props) {
            return _super.call(this, props) || this;
        }
        Navbar.prototype.componentDidMount = function () {
            var _this = this;
            this.unsubscribeGlobal = lib_1.Global.store.subscribe(function () { return _this.forceUpdate(); });
            history_1.createBrowserHistory().listen(function (location, action) {
                if (!uri(location.search).hasSearch('_modal')) {
                    $('.modal').modal('hide');
                    $('.modal-backdrop').remove();
                    $('body').removeClass('modal-open');
                }
            });
        };
        Navbar.prototype.componentWillUnmount = function () {
            this.unsubscribeGlobal && this.unsubscribeGlobal();
        };
        Navbar.prototype.render = function () {
            try {
                var user = lib_1.Global.store.getState().user;
                return (React.createElement("nav", { className: 'navbar navbar-expand-lg navbar-dark navbar-fixed' },
                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.home.path, className: 'navbar-brand order-lg-0' },
                        React.createElement("img", { src: '/img/logo/cruz.png', alt: 'CRUZ.com', className: 'd-none d-sm-inline-block c-nav-logo img-fluid ml-2' }),
                        React.createElement("img", { src: '/img/logo/cruz_sm.png', alt: 'CRUZ.com', className: 'd-inline-block d-sm-none c-nav-logo-sm img-fluid' }),
                        React.createElement(MemberCount, null)),
                    React.createElement("div", { className: 'd-flex align-self-stretch order-lg-2' },
                        React.createElement(NavUser, { user: user })),
                    React.createElement("div", { className: 'collapse navbar-collapse align-items-center align-self-stretch order-lg-1', id: 'navbarNav' },
                        React.createElement("div", { className: 'nav navbar-nav mx-auto' },
                            React.createElement(NavMain, { user: user })))));
            }
            catch (err) {
                lib_1.util.logError(err, null);
                return null;
            }
        };
        return Navbar;
    }(React.Component));
    Nav.Navbar = Navbar;
    var NavbarMyCruz = (function (_super) {
        __extends(NavbarMyCruz, _super);
        function NavbarMyCruz(props) {
            return _super.call(this, props) || this;
        }
        NavbarMyCruz.prototype.componentDidMount = function () {
            var _this = this;
            this.unsubscribeGlobal = lib_1.Global.store.subscribe(function () { return _this.forceUpdate(); });
        };
        NavbarMyCruz.prototype.componentWillUnmount = function () {
            this.unsubscribeGlobal && this.unsubscribeGlobal();
        };
        NavbarMyCruz.prototype.render = function () {
            var pathname = this.props.pathname;
            return (React.createElement("div", { className: 'navbar-mycruz navbar-dark d-none d-lg-flex' },
                React.createElement("div", { className: 'container d-flex justify-content-center' },
                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.my_saved.path, className: classNames('nav-item', { active: pathname == pages_1.pages.my_saved.path }) }, pages_1.pages.my_saved.title),
                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.my_vehicle.path, className: classNames('nav-item', { active: pathname == pages_1.pages.my_vehicle.path }) }, pages_1.pages.my_vehicle.title),
                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.my_spotted.path, className: classNames('nav-item', { active: pathname == pages_1.pages.my_spotted.path }) }, pages_1.pages.my_spotted.title),
                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.my_service_quote.path, className: classNames('nav-item', { active: pathname == pages_1.pages.my_service_quote.path }) }, pages_1.pages.my_service_quote.title),
                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.my_follow.path, className: classNames('nav-item', { active: pathname == pages_1.pages.my_follow.path }) }, "Follow"),
                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.my_referral.path, className: classNames('nav-item', { active: pathname == pages_1.pages.my_referral.path }) }, pages_1.pages.my_referral.title),
                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.messaging.path, className: classNames('nav-item', { active: pathname == pages_1.pages.messaging.path }) },
                        React.createElement("span", { className: 'position-relative' },
                            lib_1.Global.store.getState().has_new_message
                                &&
                                    React.createElement("span", { className: 'c-nav-alert' }),
                            pages_1.pages.messaging.title)))));
        };
        return NavbarMyCruz;
    }(React.Component));
    Nav.NavbarMyCruz = NavbarMyCruz;
    var NavbarUser = (function (_super) {
        __extends(NavbarUser, _super);
        function NavbarUser(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                vehicle: _this.props.baseUrl + '/vehicle',
                spotted: _this.props.baseUrl + '/spotted',
                following: _this.props.baseUrl + '/following'
            };
            return _this;
        }
        NavbarUser.prototype.render = function () {
            try {
                return (React.createElement("div", { className: 'navbar-mycruz navbar-dark' },
                    React.createElement("div", { className: 'container d-flex justify-content-center' },
                        React.createElement(react_router_dom_1.Link, { to: this.state.vehicle, className: classNames('nav-item', { active: this.state.vehicle == this.props.pathname }) }, pages_1.pages.user_vehicle.title),
                        React.createElement(react_router_dom_1.Link, { to: this.state.spotted, className: classNames('nav-item', { active: this.state.spotted == this.props.pathname }) }, pages_1.pages.user_spotted.title),
                        React.createElement(react_router_dom_1.Link, { to: this.state.following, className: classNames('nav-item', { active: this.state.following == this.props.pathname }) }, pages_1.pages.user_following.title))));
            }
            catch (err) {
                return null;
            }
        };
        return NavbarUser;
    }(React.Component));
    Nav.NavbarUser = NavbarUser;
})(Nav = exports.Nav || (exports.Nav = {}));
