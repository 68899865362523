"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var _ = require("lodash");
var request = require("superagent");
var lib_1 = require("../lib");
var pages_1 = require("../../routes/pages");
var component_1 = require("../component");
var Roadside;
(function (Roadside) {
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                message: null, is_working: false, sent: false
            };
            return _this;
        }
        Page.prototype.componentDidMount = function () {
            document.title = lib_1.util.title(pages_1.pages.roadside.title);
        };
        Page.prototype.renderHero = function () {
            return (React.createElement("section", { id: 'sec-hero', className: 'd-flex align-items-end' },
                React.createElement("div", { className: 'container sec-hero-text' },
                    React.createElement("h1", null, "CRUZ\u00AE Roadside Assistance"),
                    React.createElement("div", { className: 'c-font-size-xxl' }, "We will make sure you get home safe!"))));
        };
        Page.prototype.renderService = function () {
            return (React.createElement("div", { className: 'row text-center' },
                React.createElement("div", { className: 'col-6 col-md-3 my-3' },
                    React.createElement("div", { className: 'd-flex justify-content-center align-items-center icon mx-auto' },
                        React.createElement("i", { className: 'fal fa-fw fa-tire-flat' })),
                    React.createElement("h4", null, "Flat Tire")),
                React.createElement("div", { className: 'col-6 col-md-3 my-3' },
                    React.createElement("div", { className: 'd-flex justify-content-center align-items-center icon mx-auto' },
                        React.createElement("i", { className: 'fal fa-fw fa-car-battery' })),
                    React.createElement("h4", null, "Jump Start")),
                React.createElement("div", { className: 'col-6 col-md-3 my-3' },
                    React.createElement("div", { className: 'd-flex justify-content-center align-items-center icon mx-auto' },
                        React.createElement("i", { className: 'fal fa-fw fa-unlock' })),
                    React.createElement("h4", null, "Unlock")),
                React.createElement("div", { className: 'col-6 col-md-3 my-3' },
                    React.createElement("div", { className: 'd-flex justify-content-center align-items-center icon mx-auto' },
                        React.createElement("i", { className: 'fal fa-fw fa-truck-loading' })),
                    React.createElement("h4", null, "Tow"))));
        };
        Page.prototype.messageChanged = function (e) {
            this.setState({ message: e.target.value });
        };
        Page.prototype.sendMessage = function (e) {
            var _this = this;
            e.preventDefault();
            if (!_.isEmpty(this.state.message) && !this.state.is_working) {
                this.setState({ is_working: true });
                request.post(lib_1.Global.cruzApiBaseUrl + '/roadside/request').set(lib_1.Auth.authHeader()).send(_.pick(this.state, 'message')).end(function (err, apiRes) {
                    if (err) {
                        _this.setState({ is_working: true });
                    }
                    else {
                        _this.setState({ is_working: false, sent: true });
                    }
                });
            }
        };
        Page.prototype.renderForm = function () {
            var _this = this;
            var user = lib_1.Global.store.getState().user;
            if (_.isEmpty(user)) {
                return (React.createElement("div", { className: 'text-center' },
                    React.createElement("div", null,
                        "Please ",
                        React.createElement(react_router_dom_1.Link, { to: pages_1.pages.signin.path + '?returnUrl=' + encodeURIComponent(pages_1.pages.roadside.path), className: 'btn c-btn-1 c-btn-round c-btn-xs' }, "Sign in"),
                        " to request roadside assistance."),
                    React.createElement("div", { className: 'mt-2' },
                        "Don't have an account? ",
                        React.createElement(react_router_dom_1.Link, { to: pages_1.pages.register.path, className: 'c-bnt-link' }, "Create a Free Account"),
                        ".")));
            }
            else if (this.state.sent) {
                return (React.createElement("div", { className: 'text-center' }, "Your request has been sent to us. We will contact you shortly."));
            }
            else {
                return (React.createElement("form", { onSubmit: function (e) { return _this.sendMessage(e); } },
                    React.createElement("div", { className: 'pb-2 c-font-weight-medium' }, "Request Roadside Assistance:"),
                    React.createElement("div", { className: 'pb-2' },
                        React.createElement(component_1.Textarea, { className: 'textarea', label: 'Message', id: 'message', required: true, minRows: 3, maxRows: 10, value: this.state.message || '', onChange: function (e) { return _this.messageChanged(e); } })),
                    React.createElement("div", { className: 'text-right' },
                        React.createElement("button", { type: 'submit', className: 'btn c-btn-1' }, this.state.is_working ? React.createElement(component_1.BtnWorking, { value: 'Sending ...' }) : 'Send'))));
            }
        };
        Page.prototype.render = function () {
            return (component_1.Layout.main(null, null, React.createElement("div", { className: 'c-roadside' },
                this.renderHero(),
                React.createElement("section", { className: 'py-3', id: 'sec-service' },
                    React.createElement("div", { className: 'container' }, this.renderService())),
                React.createElement("div", { className: 'py-5' },
                    React.createElement("div", { className: 'container' },
                        React.createElement("div", { className: 'row' },
                            React.createElement("div", { className: 'col-md-8 order-2 order-md-1 mt-3 mt-md-0' },
                                React.createElement("div", { className: 'card' },
                                    React.createElement("div", { className: 'card-body' }, this.renderForm()))),
                            React.createElement("div", { className: 'col-md-4 order-1 order-md-2' },
                                React.createElement("address", null,
                                    React.createElement("h6", null, "Address:"),
                                    React.createElement("b", null, "CRUZ\u00AE Roadside Assistance"),
                                    React.createElement("br", null),
                                    "2500 S Westlake Drive",
                                    React.createElement("br", null),
                                    "Sioux Falls, South Dakota 57106"))))))));
        };
        return Page;
    }(React.Component));
    Roadside.Page = Page;
})(Roadside = exports.Roadside || (exports.Roadside = {}));
