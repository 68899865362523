"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var _ = require("lodash");
var pages_1 = require("./pages");
var lib_1 = require("../modules/lib");
var Modernizr = require('modernizr');
var isBrowserCompatible = null;
function checkBrowser() {
    var mz = Modernizr;
    return (mz.blobconstructor && mz.canvas && mz.cookies && mz.es5object && mz.filereader && mz.flexbox && mz.flexwrap && mz.websockets && mz.sessionstorage && mz.localstorage);
}
var HistoryComponent = (function (_super) {
    __extends(HistoryComponent, _super);
    function HistoryComponent(props) {
        var _this = _super.call(this, props) || this;
        lib_1.Global.history = _this.props.history;
        return _this;
    }
    HistoryComponent.prototype.componentWillMount = function () {
        try {
            if (lib_1.util.isClient()) {
                if (/google|bot|googlebot|crawler|spider|robot|crawling|yahoo|bing/i.test(window.navigator.userAgent) == false) {
                    if (isBrowserCompatible == null) {
                        isBrowserCompatible = checkBrowser();
                    }
                    if (isBrowserCompatible != undefined && isBrowserCompatible == false && this.props.location.pathname != pages_1.pages.browser.path) {
                        this.props.history.replace(pages_1.pages.browser.path);
                    }
                    else {
                        lib_1.CruzSocket.connect();
                    }
                }
            }
        }
        catch (err) {
        }
    };
    HistoryComponent.prototype.componentDidMount = function () {
    };
    HistoryComponent.prototype.componentDidUpdate = function (prevProps) {
        try {
            if (this.props.location.pathname != prevProps.location.pathname) {
                window.scrollTo(0, 0);
                lib_1.Global.store.dispatch({ type: lib_1.Global.actions.setToastMessage, data: null });
                lib_1.CruzSocket.changeRoom(lib_1.util.getRoom(prevProps.location.pathname), lib_1.util.getRoom(this.props.location.pathname));
            }
        }
        catch (err) {
        }
    };
    HistoryComponent.prototype.render = function () {
        return (React.createElement(React.Fragment, null, this.props.children));
    };
    return HistoryComponent;
}(React.Component));
var History = react_router_dom_1.withRouter(HistoryComponent);
exports.CruzRoutes = function () { return (React.createElement(History, null,
    React.createElement(react_router_dom_1.Switch, null, _.toArray(pages_1.pages).map(function (_a, key) {
        var path = _a.path, params = _a.params, component = _a.component;
        return (React.createElement(react_router_dom_1.Route, { exact: true, key: key, path: path + (params || ''), component: component }));
    })))); };
