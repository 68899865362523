"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var _ = require("lodash");
var uri = require("urijs");
var cfg = require("../../../cfg");
var component_1 = require("../../component");
var lib_1 = require("../../lib");
var spotted_add_edit_control_1 = require("./spotted_add_edit_control");
var SpottedAddEdit;
(function (SpottedAddEdit) {
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            return _super.call(this, props) || this;
        }
        Page.prototype.componentWillMount = function () {
            var _this = this;
            lib_1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    var locSearch = new uri(_this.props.location.search).search(true);
                    if (!_.isEmpty(locSearch._id)) {
                        _this.title = 'Edit Spotted';
                        spotted_add_edit_control_1.SpottedAddEditControl.store.dispatch({ type: 2, data: locSearch._id });
                    }
                    else {
                        _this.title = 'Post Spotted';
                        spotted_add_edit_control_1.SpottedAddEditControl.store.dispatch({ type: 1 });
                    }
                }
                else {
                    lib_1.Auth.redirectSignin(_this.props.location.pathname + _this.props.location.search);
                }
            });
        };
        Page.prototype.componentDidMount = function () {
            var _this = this;
            document.title = lib_1.util.title(this.title);
            this.unsubscribe = spotted_add_edit_control_1.SpottedAddEditControl.store.subscribe(function () { _this.forceUpdate(); });
        };
        Page.prototype.componentWillUnmount = function () {
            spotted_add_edit_control_1.SpottedAddEditControl.store.dispatch({ type: 16 });
            this.unsubscribe && this.unsubscribe();
        };
        Page.prototype.getError = function (key, isServer) {
            return ((spotted_add_edit_control_1.SpottedAddEditControl.store.getState().validation && spotted_add_edit_control_1.SpottedAddEditControl.store.getState().validation[key]) ? spotted_add_edit_control_1.SpottedAddEditControl.store.getState().validation[key][0] : null);
        };
        Page.prototype.renderForm = function () {
            var spotted = spotted_add_edit_control_1.SpottedAddEditControl.store.getState().spotted;
            var spottedInput = spotted_add_edit_control_1.SpottedAddEditControl.store.getState().spottedInput;
            return (React.createElement("form", { id: 'formSpotted', onSubmit: function (e) { return spotted_add_edit_control_1.SpottedAddEditControl.store.dispatch({ type: 12, event: e }); }, noValidate: true },
                React.createElement("div", { className: 'card card-form mt-3' },
                    React.createElement("div", { className: 'card-body' },
                        React.createElement("div", { className: 'row' },
                            React.createElement("div", { className: 'col-lg-6' },
                                React.createElement(component_1.Photo.PhotoEdit, __assign({ blur_lp: true, required: true, "data-err": this.getError('upload_file_list'), api_url: spotted._id ? lib_1.Global.cruzApiBaseUrl + '/spotted/' + spotted._id + '/photo' : null }, { photo_list: spotted.photo_list, executeUpload: spotted_add_edit_control_1.SpottedAddEditControl.store.getState().executeUpload }, { _id: spottedInput._id, photo_base_url: spotted._id ? cfg.photoBaseUrl[cfg.env].s3 + '/spotted/' + spotted.group.toString() + '/' + spotted._id : null, onUploadFileChanged: function (file_list) { return spotted_add_edit_control_1.SpottedAddEditControl.store.dispatch({ type: 4, data: file_list }); }, onUploadFileDone: function (photo_list) { return spotted_add_edit_control_1.SpottedAddEditControl.store.dispatch({ type: 7, data: photo_list }); }, onDeletePhotoDone: function (photo_list) { return spotted_add_edit_control_1.SpottedAddEditControl.store.dispatch({ type: 7, data: photo_list }); }, onWorking: function (status) { return spotted_add_edit_control_1.SpottedAddEditControl.store.dispatch({ type: 10, data: status }); }, onSortPhoto: function (ids) { return spotted_add_edit_control_1.SpottedAddEditControl.store.dispatch({ type: 8, data: ids }); }, photo_list_classNames: 'col-xs-6 col-sm-4 col-lg-6 col-xl-4' }))),
                            React.createElement("div", { className: 'col-lg-6' },
                                React.createElement(component_1.LocationInput, { id: 'location_name', label: 'Location (City, State)', required: true, maxLength: 100, value: spottedInput.location_name || '', "data-err": this.getError('location_name'), onChange: function (newValue) { return spotted_add_edit_control_1.SpottedAddEditControl.store.dispatch({ type: 11, data: { location_name: newValue } }); } }),
                                React.createElement(component_1.Text, { id: 'title', label: 'Title', required: true, maxLength: 100, value: spottedInput.title || '', "data-err": this.getError('title'), onChange: function (e) { return spotted_add_edit_control_1.SpottedAddEditControl.store.dispatch({ type: 11, data: { title: e.target['value'] } }); } }),
                                React.createElement(component_1.Textarea, { id: 'content', label: 'Description', className: 'textarea-lg', minRows: 3, maxRows: 20, value: spottedInput.content || '', onChange: function (e) { return spotted_add_edit_control_1.SpottedAddEditControl.store.dispatch({ type: 11, data: { content: e.target['value'] } }); } })))),
                    React.createElement("div", { className: 'card-footer text-right' },
                        React.createElement("button", { type: 'submit', className: 'btn c-btn-1' }, spotted_add_edit_control_1.SpottedAddEditControl.store.getState().is_working
                            ?
                                React.createElement(component_1.BtnWorking, { value: 'Saving ...' })
                            :
                                'Save')))));
        };
        Page.prototype.render = function () {
            return (component_1.Layout.main(lib_1.Global.store.getState().user, null, React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'container c-container' }, spotted_add_edit_control_1.SpottedAddEditControl.store == null
                    ?
                        React.createElement("div", { className: 'flex-ch-100' },
                            React.createElement(component_1.Loading, null))
                    :
                        React.createElement(React.Fragment, null,
                            React.createElement("h1", { className: 'c-title' }, (_.get(spotted_add_edit_control_1.SpottedAddEditControl.store.getState(), 'spotted._id'))
                                ?
                                    'Edit Spotted'
                                :
                                    this.title),
                            spotted_add_edit_control_1.SpottedAddEditControl.store.getState().is_loading
                                ?
                                    React.createElement(component_1.Loading, null)
                                :
                                    this.renderForm())),
                React.createElement(component_1.Dirty, { when: spotted_add_edit_control_1.SpottedAddEditControl.store && spotted_add_edit_control_1.SpottedAddEditControl.store.getState().is_dirty }))));
        };
        return Page;
    }(React.Component));
    SpottedAddEdit.Page = Page;
})(SpottedAddEdit = exports.SpottedAddEdit || (exports.SpottedAddEdit = {}));
