"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var Dropzone = require("react-dropzone/dist");
var async = require("async");
var _ = require("lodash");
var classNames = require("classnames");
var request = require("superagent");
var __1 = require("..");
var filesize = require("filesize");
var cfg = require("../../../cfg");
var lib_1 = require("../../lib");
var BinaryFile;
(function (BinaryFile) {
    var BinaryUpload = (function (_super) {
        __extends(BinaryUpload, _super);
        function BinaryUpload(props) {
            var _this = _super.call(this, props) || this;
            _this.uploader = null;
            _this.api_url = null;
            _this.api_url = _this.props.api_url;
            _this.state = {
                file_list: _this.props.upload,
                is_uploading: false,
                total_size: 0,
                progress: 0,
            };
            return _this;
        }
        BinaryUpload.prototype.componentWillReceiveProps = function (nextProps) {
            if (nextProps.api_url) {
                this.api_url = nextProps.api_url;
            }
            if (nextProps.upload) {
                this.setState({ file_list: nextProps.upload });
            }
        };
        BinaryUpload.prototype.onDropRejected = function (rejected_list) {
            var file_too_big = _.find(rejected_list, function (file) { return file.size > 26214400; });
            (file_too_big)
                ? lib_1.util.setToastMessage('error', 'Selected file size is too big. Size limit is 25MB')
                : lib_1.util.setToastMessage('error', 'Invalid file type');
        };
        BinaryUpload.prototype.onDropAccepted = function (file_list) {
            var all_file_list = _.union(this.state.file_list, file_list);
            this.setState({ file_list: all_file_list });
            this.props.onUploadFileChanged && this.props.onUploadFileChanged(all_file_list);
        };
        BinaryUpload.prototype.selectFile = function (preview) {
            var file = _.find(this.state.file_list, { preview: preview });
            file.selected ? file.selected = false : file.selected = true;
            this.forceUpdate();
        };
        BinaryUpload.prototype.removeFile = function () {
            var _this = this;
            var file_list = _.filter(this.state.file_list, function (file) { return !file.selected; });
            this.setState({ file_list: file_list }, function () {
                _this.props.onUploadFileChanged && _this.props.onUploadFileChanged(file_list);
            });
        };
        BinaryUpload.prototype.removeAllFiles = function () {
            var _this = this;
            this.setState({ file_list: [] }, function () {
                _this.props.onUploadFileChanged && _this.props.onUploadFileChanged([]);
            });
        };
        BinaryUpload.prototype.uploadFileDone = function () {
            this.setState({
                file_list: [],
                is_uploading: false,
                total_size: 0,
                progress: 0,
            });
            this.props.onUploadFileChanged && this.props.onUploadFileChanged(this.state.file_list);
        };
        BinaryUpload.prototype.cancelUpload = function () {
            this.uploader.abort();
            this.setState({ is_uploading: false });
        };
        BinaryUpload.prototype.sendFile = function () {
            var _this = this;
            try {
                if (this.api_url && this.api_url != '' && !this.state.is_uploading) {
                    lib_1.Auth.isAuthed(function (isAuthed) {
                        if (isAuthed) {
                            var total_size_1 = 0;
                            _this.uploader = request.post(_this.api_url)
                                .set(lib_1.Auth.authHeader())
                                .timeout(cfg.superagent.timeout.upload);
                            var formData_1 = new FormData();
                            _this.uploader.send(formData_1);
                            async.eachSeries(_this.state.file_list, function (file, nf) {
                                total_size_1 += file.size;
                                formData_1.append("files", file);
                                nf();
                            }, function () {
                                _this.setState({ is_uploading: true, total_size: total_size_1 }, function () {
                                    _this.uploader.on('progress', function (pevt) {
                                        _this.setState({ progress: Math.round(pevt['percent']) });
                                    }).end(function (err, apiRes) {
                                        if (err) {
                                            lib_1.util.logError(err, null);
                                            lib_1.util.setToastMessage('error', 'Error Sending File');
                                        }
                                        _this.uploadFileDone();
                                    });
                                });
                            });
                        }
                        else {
                            lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                        }
                    });
                }
            }
            catch (error) {
                lib_1.util.logError(error, null);
            }
        };
        BinaryUpload.prototype.render = function () {
            var _this = this;
            try {
                var selected_file_count = _.filter(this.state.file_list, function (x) { return x.selected; }).length;
                var be_1 = this;
                return (React.createElement("div", null, (_.size(this.state.file_list) > 0)
                    &&
                        React.createElement("div", { className: classNames('c-photo-edit', lib_1.util.formGroupClass(this.props)) },
                            React.createElement(Dropzone, { ref: 'dropzone', multiple: true, disableClick: true, disablePreview: false, className: 'dropzone', maxSize: 26214400, activeClassName: 'active', onDrop: function (file_list) { return _this.onDropAccepted(file_list); }, onDropRejected: function (rejected_list) { return _this.onDropRejected(rejected_list); } }, (this.state.file_list.length > 0)
                                &&
                                    React.createElement("div", { className: 'upload-multiple mt-1 container' },
                                        React.createElement("div", { className: 'row img-list justify-content-center' }, _.map(this.state.file_list, function (file) {
                                            var id = _.uniqueId(file.name + '_');
                                            var imageFile = _.startsWith(file.type.toLowerCase(), 'image');
                                            var pdfFile = _.endsWith(file.type.toLowerCase(), 'pdf');
                                            return (React.createElement("div", { key: id, className: 'col-6 col-sm-3 col-lg-4 col-xl-2 align-items-stretch' },
                                                React.createElement("div", { className: classNames('b-img-wrap d-flex justify-content-center align-items-center flex-column', { selected: file.selected }), onClick: function () { return be_1.selectFile(file.preview); } },
                                                    imageFile && React.createElement("img", { src: file.preview, alt: file.name, className: 'img-fluid' }),
                                                    !imageFile
                                                        &&
                                                            React.createElement(React.Fragment, null,
                                                                React.createElement("i", { title: file.name, className: classNames('fal fa-2x pt-3', { 'fa-file-pdf': pdfFile }, { 'fa-file-alt': !pdfFile }) }),
                                                                React.createElement("label", { className: 'file-name px-3 pt-3', htmlFor: id }, file.name),
                                                                React.createElement("label", { className: 'file-name pb-2' },
                                                                    "size: ",
                                                                    filesize(file.size, { round: 2 }))))));
                                        })),
                                        React.createElement("div", { className: 'clearfix' }),
                                        this.state.is_uploading
                                            ?
                                                React.createElement("div", { className: 'upload-progress' },
                                                    React.createElement(__1.Progress, { value: this.state.progress.toString() }),
                                                    React.createElement("div", { className: 'pb-2' },
                                                        (this.state.total_size > 0)
                                                            &&
                                                                React.createElement("span", null,
                                                                    (this.state.progress && !_.isNaN(this.state.progress) && this.state.progress.toString() != 'NaN' && this.state.progress > 0)
                                                                        &&
                                                                            React.createElement("span", { className: 'mr-2' }, this.state.progress.toString() + '% of'),
                                                                    React.createElement("span", null, filesize(this.state.total_size, { round: 2 }))),
                                                        (!_.isNaN(this.state.progress) && this.state.progress < 100)
                                                            &&
                                                                React.createElement("button", { type: 'button', className: 'btn c-btn-2 c-btn-xs c-btn-round ml-2', onClick: be_1.cancelUpload.bind(be_1) },
                                                                    React.createElement("i", { className: 'fal fa-times' }),
                                                                    React.createElement("span", null, "Cancel")),
                                                        (this.state.progress == 100)
                                                            &&
                                                                React.createElement("span", { className: 'c-blink c-font-color-2 ml-3' },
                                                                    "Processing photo ",
                                                                    React.createElement("span", null, "."),
                                                                    React.createElement("span", null, "."),
                                                                    React.createElement("span", null, "."))))
                                            :
                                                React.createElement("div", { className: 'toolbar text-center' },
                                                    (_.size(this.state.file_list) > 0)
                                                        &&
                                                            React.createElement("div", { className: 'mt-3 mb-3 c-help' }, "* To remove file(s), please select the file(s) then click the \"Remove\" button."),
                                                    (selected_file_count > 0) ?
                                                        React.createElement("button", { type: 'button', className: 'btn c-btn-2 c-btn-xs c-btn-round', onClick: function (e) { return be_1.removeFile(); } }, 'Remove ' + selected_file_count + ' File' + (selected_file_count > 1 ? 's' : ''))
                                                        :
                                                            React.createElement(React.Fragment, null,
                                                                React.createElement("button", { type: 'button', className: 'btn c-btn-2 c-btn-xs c-btn-round ml-2', onClick: function (e) { return be_1.removeAllFiles(); } },
                                                                    React.createElement("i", { className: 'fal fa-times' }),
                                                                    React.createElement("span", null, "Cancel")),
                                                                " \u00A0\u00A0",
                                                                React.createElement("button", { type: 'button', className: 'btn c-btn-1 c-btn-xs c-btn-round', onClick: function (e) { return be_1.sendFile(); } },
                                                                    React.createElement("span", null, 'Send ' + this.state.file_list.length + ' File' + (this.state.file_list.length > 1 ? 's' : ''))))))),
                            React.createElement("span", { className: 'form-control-icon' }),
                            React.createElement("span", { className: 'form-control-feedback' }, be_1.props['data-err']))));
            }
            catch (err) {
                lib_1.util.logError(err, null);
                return null;
            }
        };
        return BinaryUpload;
    }(React.Component));
    BinaryFile.BinaryUpload = BinaryUpload;
})(BinaryFile = exports.BinaryFile || (exports.BinaryFile = {}));
