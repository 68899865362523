"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var request = require("superagent");
var _ = require("lodash");
var Cruz = require("cruz");
var cfg = require("../../../cfg");
var lib_1 = require("../../lib");
var pages_1 = require("../../../routes/pages");
var component_1 = require("../../component");
var ForgotPassword;
(function (ForgotPassword) {
    var validate = require('validate.js');
    var rules = {
        email: {
            presence: {
                message: '^Email is required.', allowEmpty: false
            },
            email: {
                message: '^Invalid email format.', allowEmpty: false
            }
        }
    };
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                email: '',
                is_working: false,
                user: {
                    status_id: Cruz.Lookup.CruzUser.StatusEnum.Active
                }
            };
            return _this;
        }
        Page.prototype.componentDidMount = function () {
            document.title = lib_1.util.title(pages_1.pages.forgot_password.title);
        };
        Page.prototype.getError = function (key) {
            if (this.state.validation && this.state.validation[key]) {
                return this.state.validation[key][0];
            }
            else {
                return null;
            }
        };
        Page.prototype.valid = function (callback) {
            this.setState({ validation: validate(this.state, rules) }, function () {
                return callback();
            });
        };
        Page.prototype.emailChanged = function (tgt) {
            var _this = this;
            this.setState({ email: tgt.value }, function () {
                _this.state.validation && _this.valid(function () { });
            });
        };
        Page.prototype.submit = function (e) {
            var _this = this;
            e && e.preventDefault();
            lib_1.util.removeToastMessage();
            this.valid(function () {
                if (!_this.state.is_working && _.isEmpty(_this.state.validation)) {
                    _this.setState({ is_working: true }, function () {
                        request.post(lib_1.Global.cruzApiBaseUrl + '/user/forgot-password')
                            .timeout(cfg.superagent.timeout.normal)
                            .send({ email: _this.state.email }).end(function (err, apiRes) {
                            _this.setState({ is_working: false }, function () {
                                if (err) {
                                    var derr = apiRes.body.error;
                                    if (derr.type == 'AccountDisabled') {
                                        _this.setState({ user: { status_id: Cruz.Lookup.CruzUser.StatusEnum.Disabled } });
                                    }
                                    else if (derr.type == 'AccountPending') {
                                        _this.setState({ user: { status_id: Cruz.Lookup.CruzUser.StatusEnum.Pending } });
                                    }
                                    else if (derr.type == 'AccountClosed') {
                                        _this.setState({ user: { status_id: Cruz.Lookup.CruzUser.StatusEnum.Closed } });
                                    }
                                    else {
                                        lib_1.util.setToastMessage('error', _.get(apiRes.body, 'error.detail'));
                                    }
                                }
                                else {
                                    lib_1.Global.onSubmitSuccess('forgotPassword');
                                    lib_1.util.setToastMessage('info', cfg.message.forgotPasswordSent);
                                }
                            });
                        });
                    });
                }
            });
        };
        Page.prototype.renderForm = function () {
            var _this = this;
            return (React.createElement(React.Fragment, null,
                React.createElement("p", { className: 'text-center mt-3' },
                    "Please enter your email below.",
                    React.createElement("br", null),
                    "We'll send you an email to reset your password."),
                React.createElement("form", { className: 'mt-4 mx-auto', id: 'formForgotPassword', onSubmit: function (e) { return _this.submit(e); }, noValidate: true },
                    React.createElement(component_1.Text, { id: 'email', required: true, type: 'email', label: 'Email', "data-err": this.getError('email'), value: this.state.email || '', onChange: function (e) { return _this.emailChanged(e.target); } }),
                    React.createElement("div", { id: "_msg" }),
                    React.createElement("button", { type: 'submit', className: 'btn c-btn-1 c-btn-lg btn-block mt-4' },
                        React.createElement("i", { className: 'fal fa-paper-plane' }),
                        React.createElement("span", null, this.state.is_working ? React.createElement(component_1.BtnWorking, { value: 'Sending ...' }) : 'Send'))),
                React.createElement("div", { className: 'text-center' },
                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.signin.path, className: 'btn c-btn-2 c-btn-s c-btn-round mt-4' }, "Cancel"))));
        };
        Page.prototype.render = function () {
            return (component_1.Layout.entry(1, React.createElement("div", { className: 'mt-3' },
                React.createElement("h1", { className: 'c-section-title text-center' }, "Forgot Password"),
                this.state.user.status_id == Cruz.Lookup.CruzUser.StatusEnum.Disabled
                    &&
                        component_1.User.accountDisabled(),
                this.state.user.status_id == Cruz.Lookup.CruzUser.StatusEnum.Closed
                    &&
                        component_1.User.accountClosed(),
                this.state.user.status_id == Cruz.Lookup.CruzUser.StatusEnum.Pending
                    &&
                        React.createElement(component_1.User.accountPending, { email: this.state.email }),
                this.state.user.status_id > 0
                    &&
                        this.renderForm())));
        };
        return Page;
    }(React.Component));
    ForgotPassword.Page = Page;
})(ForgotPassword = exports.ForgotPassword || (exports.ForgotPassword = {}));
