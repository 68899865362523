"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var _ = require("lodash");
var uri = require("urijs");
var lib_1 = require("../../lib");
var pages_1 = require("../../../routes/pages");
var component_1 = require("../../component");
var Err;
(function (Err) {
    function Page(props) {
        try {
            var code_1 = 500;
            var pCode = _.get(props.match.params, 'code');
            if (lib_1.util.isNumeric(pCode)) {
                code_1 = Number(pCode);
            }
            react_1.useEffect(function () {
                document.title = lib_1.util.title('Error ' + code_1);
            }, []);
            var Content = function () {
                if (code_1 == 401) {
                    return (React.createElement(React.Fragment, null,
                        React.createElement("h1", { className: 'err-title' }, "Not Authorized")));
                }
                else if (code_1 == 404) {
                    return (React.createElement(React.Fragment, null,
                        React.createElement("h1", { className: 'err-title' }, "Not Found"),
                        React.createElement("h2", { className: 'err-des mt-sm-3 mb-5' }, "The item you are looking for doesn't exist.")));
                }
                else if (code_1 == 408) {
                    return (React.createElement(React.Fragment, null,
                        React.createElement("h1", { className: 'err-title' }, "Timeout"),
                        React.createElement("h2", { className: 'err-des mt-sm-3 mb-5' }, "Please try again")));
                }
                else if (code_1 == 410) {
                    return (React.createElement(React.Fragment, null,
                        React.createElement("h1", { className: 'err-title' }, "Gone"),
                        React.createElement("h2", { className: 'err-des mt-sm-3 mb-5' }, "The item you are looking for has been removed.")));
                }
                else if (code_1 == 500) {
                    return (React.createElement(React.Fragment, null,
                        React.createElement("h1", { className: 'err-title' }, "Something's Wrong!"),
                        React.createElement("h2", { className: 'err-des mt-sm-3 mb-5' }, "We will fix the error as soon as possible.")));
                }
            };
            var d_1 = new uri(props.location.search).search(true);
            return (component_1.Layout.error(React.createElement(React.Fragment, null,
                React.createElement("p", { className: 'err-code' }, code_1),
                React.createElement(Content, null),
                ((code_1 == 500 || code_1 == 408) && !_.isEmpty(d_1.returnUrl))
                    &&
                        React.createElement("button", { className: 'btn c-btn-round c-btn-2 c-btn-xs mr-3', onClick: function () { return lib_1.Global.history.replace(d_1.url); } }, "Try Again"),
                React.createElement(react_router_dom_1.Link, { to: pages_1.pages.home.path, className: 'btn c-btn-round c-btn-1 c-btn-xs' }, "Go Home"))));
        }
        catch (err) {
            return null;
        }
    }
    Err.Page = Page;
})(Err = exports.Err || (exports.Err = {}));
