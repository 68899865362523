"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_1 = require("react");
var request = require("superagent");
var _ = require("lodash");
var lib_1 = require("../../lib");
var __1 = require("..");
var Trademark;
(function (Trademark) {
    function InsuranceQuote(props) {
        var user = lib_1.Global.store.getState().user;
        function showModal() {
            if (_.isEmpty(user)) {
                lib_1.util.setModal(null, false, false, React.createElement(__1.Modal.RequestAuth, null));
            }
            else {
                lib_1.util.setModal(null, false, true, React.createElement(InsuranceModal, __assign({}, props)));
            }
        }
        return ((_.isEmpty(user) || user.location.state_abbr == 'SD')
            ?
                React.createElement("div", { className: 'py-4 px-3 text-center bg-white' },
                    React.createElement("div", { className: 'mb-3 c-font-size-l' }, "Get competitive insurance quotes today!"),
                    React.createElement("button", { className: 'btn c-btn-2-rev c-btn-round', onClick: showModal }, "Get Free Insurance Quote"))
            :
                null);
    }
    Trademark.InsuranceQuote = InsuranceQuote;
    function InsuranceModal(props) {
        var _a = react_1.useState({
            working: false, sent: false
        }), state = _a[0], setState = _a[1];
        react_1.useEffect(function () {
            $('#_InsuranceQuote').modal('show').on('hidden.bs.modal', function (e) {
                lib_1.util.removeModal($('#_InsuranceQuote'));
            });
        }, []);
        function send() {
            if (!state.working) {
                lib_1.Auth.isAuthed(function (isAuthed) {
                    if (isAuthed) {
                        setState(__assign(__assign({}, state), { working: true }));
                        request.post(lib_1.Global.cruzApiBaseUrl + '/insurance-quote').set(lib_1.Auth.authHeader()).send({ vehicle_id: _.get(props, 'vehicle_id') }).end(function (err, apiRes) {
                            setState({ working: false, sent: true });
                        });
                    }
                    else {
                        lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                    }
                });
            }
        }
        return (React.createElement("div", { className: 'modal', id: '_InsuranceQuote', tabIndex: -1, role: 'dialog', "data-backdrop": 'static' },
            React.createElement("div", { className: 'modal-dialog', role: 'document' },
                React.createElement("div", { className: 'modal-content' },
                    React.createElement("div", { className: 'modal-header' },
                        React.createElement("h2", { className: 'modal-title' }, "Insurance Inquire")),
                    React.createElement("div", { className: 'modal-body' },
                        React.createElement("div", { className: 'py-2' },
                            "By submitting, you agree to receive up to 4 offers from local insurance agents via email who may need additional information to complete your quote request.",
                            state.sent
                                &&
                                    React.createElement("div", { className: 'mt-3 c-alert text-center' }, "Your inquiry has been sent to local insurance agents."))),
                    React.createElement("div", { className: 'modal-footer' },
                        React.createElement("button", { type: 'button', className: 'btn c-btn-1-rev mr-1', "data-dismiss": 'modal' }, "Close"),
                        React.createElement("button", { type: 'button', className: 'btn c-btn-1', onClick: send, disabled: state.sent }, state.working ? React.createElement(__1.BtnWorking, { value: 'Sending ...' }) : 'Send Inquire'))))));
    }
    function FinanceQuote(props) {
        var user = lib_1.Global.store.getState().user;
        function showModal() {
            if (_.isEmpty(user)) {
                lib_1.util.setModal(null, false, false, React.createElement(__1.Modal.RequestAuth, null));
            }
            else {
                lib_1.util.setModal(null, false, true, React.createElement(FinanceModal, __assign({}, props)));
            }
        }
        return ((_.isEmpty(user) || user.location.state_abbr == 'SD')
            ?
                React.createElement("div", { className: 'py-4 px-3 text-center bg-white' },
                    React.createElement("div", { className: 'mb-3 c-font-size-l' }, "Get financing today!"),
                    React.createElement("button", { className: 'btn c-btn-2 c-btn-round', onClick: showModal }, "Get Pre-Approved"))
            :
                null);
    }
    Trademark.FinanceQuote = FinanceQuote;
    function FinanceModal(props) {
        var _a = react_1.useState({
            working: false, sent: false
        }), state = _a[0], setState = _a[1];
        react_1.useEffect(function () {
            $('#_FinanceQuote').modal('show').on('hidden.bs.modal', function (e) {
                lib_1.util.removeModal($('#_FinanceQuote'));
            });
        }, []);
        function send() {
            if (!state.working) {
                lib_1.Auth.isAuthed(function (isAuthed) {
                    if (isAuthed) {
                        setState(__assign(__assign({}, state), { working: true }));
                        request.post(lib_1.Global.cruzApiBaseUrl + '/finance-quote').set(lib_1.Auth.authHeader()).send({ vehicle_id: _.get(props, 'vehicle_id') }).end(function (err, apiRes) {
                            setState({ working: false, sent: true });
                        });
                    }
                    else {
                        lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                    }
                });
            }
        }
        return (React.createElement("div", { className: 'modal', id: '_FinanceQuote', tabIndex: -1, role: 'dialog', "data-backdrop": 'static' },
            React.createElement("div", { className: 'modal-dialog', role: 'document' },
                React.createElement("div", { className: 'modal-content' },
                    React.createElement("div", { className: 'modal-header' },
                        React.createElement("h2", { className: 'modal-title' }, "Finance Inquire")),
                    React.createElement("div", { className: 'modal-body' },
                        React.createElement("div", { className: 'py-2' },
                            "By submitting, you agree to receive up to 4 offers from local bankers via email who may need additional information to complete your quote request.",
                            state.sent
                                &&
                                    React.createElement("div", { className: 'mt-3 c-alert text-center' }, "Your inquiry has been sent to local bankers."))),
                    React.createElement("div", { className: 'modal-footer' },
                        React.createElement("button", { type: 'button', className: 'btn c-btn-1-rev mr-1', "data-dismiss": 'modal' }, "Close"),
                        React.createElement("button", { type: 'button', className: 'btn c-btn-1', onClick: send, disabled: state.sent }, state.working ? React.createElement(__1.BtnWorking, { value: 'Sending ...' }) : 'Send Inquire'))))));
    }
})(Trademark = exports.Trademark || (exports.Trademark = {}));
