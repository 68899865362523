"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var Stat;
(function (Stat) {
    function views(c) {
        try {
            return (React.createElement("div", { className: 'c-stat' },
                React.createElement("span", { className: 'c-count-prepend' }, (c != null && c > 0) ? c.toLocaleString() : 0),
                React.createElement("span", { className: 'name' },
                    "View",
                    (c > 1) ? 's' : '')));
        }
        catch (err) {
            return null;
        }
    }
    Stat.views = views;
})(Stat = exports.Stat || (exports.Stat = {}));
