"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var moment = require("moment");
var pages_1 = require("../../../routes/pages");
var Footer;
(function (Footer_1) {
    var Footer = (function (_super) {
        __extends(Footer, _super);
        function Footer() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        Footer.prototype.render = function () {
            try {
                return (React.createElement("div", { className: 'c-footer text-center' },
                    React.createElement("ul", { className: 'list-inline c-footer-nav mt-md-2 mb-md-2' },
                        React.createElement("li", { className: 'list-inline-item' },
                            React.createElement(react_router_dom_1.Link, { to: pages_1.pages.about.path }, pages_1.pages.about.title)),
                        React.createElement("li", { className: 'list-inline-item' },
                            React.createElement(react_router_dom_1.Link, { to: pages_1.pages.contact.path }, pages_1.pages.contact.title)),
                        React.createElement("li", { className: 'list-inline-item' },
                            React.createElement(react_router_dom_1.Link, { to: pages_1.pages.terms_conditions.path }, pages_1.pages.terms_conditions.title)),
                        React.createElement("li", { className: 'list-inline-item' },
                            React.createElement(react_router_dom_1.Link, { to: pages_1.pages.privacy_policy.path }, pages_1.pages.privacy_policy.title)),
                        React.createElement("div", { className: 'd-block d-sm-none mt-1 ml-2' }),
                        React.createElement("li", { className: 'list-inline-item ml-sm-4' },
                            "\u00A9 ",
                            '2017-' + moment().year().toString(),
                            React.createElement("span", { className: 'ml-2' },
                                "CRUZ",
                                React.createElement("sup", { className: 'c-trademark-sm' }, "TM")),
                            React.createElement("span", { className: 'ml-3' },
                                "Take Control",
                                React.createElement("sup", { className: 'c-trademark-sm' }, "TM"))))));
            }
            catch (err) {
                return null;
            }
        };
        return Footer;
    }(React.Component));
    Footer_1.Footer = Footer;
})(Footer = exports.Footer || (exports.Footer = {}));
