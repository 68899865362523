"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var request = require("superagent");
var uri = require("urijs");
var _ = require("lodash");
var global_1 = require("./global");
var util_1 = require("./util");
var __1 = require("..");
var cfg = require("../../../cfg");
var Request;
(function (Request) {
    var err401 = {
        status: 401
    };
    var timeout = cfg.superagent.timeout;
    function getApiErrorCode(apiError) {
        if (_.get(apiError, 'errno') == 'ETIME') {
            return 408;
        }
        else {
            return _.get(apiError, 'response.status');
        }
    }
    Request.getApiErrorCode = getApiErrorCode;
    Request.user = {
        verifyEmail: function (email, user_id, callback) {
            request.get(global_1.Global.cruzApiBaseUrl + '/user/verify-email/' + uri.encode(email) + ((user_id && user_id != '') ? '/' + user_id : ''))
                .timeout(cfg.superagent.timeout.normal).end(function (err, apiRes) {
                return callback(err, apiRes);
            });
        },
        getReferralByCode: function (cruz_code, callback) {
            request.get(global_1.Global.cruzApiBaseUrl + '/user/profile/code/' + cruz_code)
                .timeout(cfg.superagent.timeout.normal).end(function (err, apiRes) {
                return callback(err, apiRes);
            });
        },
        myProfile: function (callback) {
            __1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    request.get(global_1.Global.cruzApiBaseUrl + '/user/my-profile').set(__1.Auth.authHeader()).end(function (err, apiRes) {
                        return callback(err, apiRes);
                    });
                }
                else if (util_1.util.isClient()) {
                    __1.Auth.redirectSignin(util_1.util.getRelativeUrl());
                }
                else {
                    return callback(err401, null);
                }
            });
        },
        myReferral: function (query, callback) {
            __1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    request.get(global_1.Global.cruzApiBaseUrl + '/user/my-referrals' + util_1.util.buildApiQueryString(query)).set(__1.Auth.authHeader()).end(function (err, apiRes) {
                        return callback(err, apiRes);
                    });
                }
                else if (util_1.util.isClient()) {
                    __1.Auth.redirectSignin(util_1.util.getRelativeUrl());
                }
                else {
                    return callback(err401, null);
                }
            });
        },
        profile: function (user_id, callback) {
            __1.Auth.isAuthed(function () {
                request.get(global_1.Global.cruzApiBaseUrl + '/user/profile/' + user_id).set(__1.Auth.authHeader()).end(function (err, apiRes) {
                    return callback(err, apiRes);
                });
            });
        }
    };
    Request.follow = {
        follow: function (user_id, callback) {
            __1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    request.post(global_1.Global.cruzApiBaseUrl + '/follow/user/' + user_id).set(__1.Auth.authHeader()).end(function (err, apiRes) {
                        return callback(err, apiRes);
                    });
                }
                else {
                    __1.Auth.redirectSignin(util_1.util.getRelativeUrl());
                }
            });
        },
        unFollow: function (user_id, callback) {
            __1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    request.delete(global_1.Global.cruzApiBaseUrl + '/follow/user/' + user_id).set(__1.Auth.authHeader()).timeout(timeout.normal).end(function (err, apiRes) {
                        return callback(err, apiRes);
                    });
                }
                else {
                    __1.Auth.redirectSignin(util_1.util.getRelativeUrl());
                }
            });
        },
        myFollower: function (query, callback) {
            __1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    request.get(global_1.Global.cruzApiBaseUrl + '/follow/my-follower' + util_1.util.buildApiQueryString(query)).set(__1.Auth.authHeader()).timeout(timeout.normal).end(function (err, apiRes) {
                        return callback(err, apiRes);
                    });
                }
                else if (util_1.util.isClient()) {
                    __1.Auth.redirectSignin(util_1.util.getRelativeUrl());
                }
                else {
                    return callback(err401, null);
                }
            });
        },
        myFollowing: function (query, callback) {
            __1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    request.get(global_1.Global.cruzApiBaseUrl + '/follow/my-following' + util_1.util.buildApiQueryString(query)).set(__1.Auth.authHeader()).timeout(timeout.normal).end(function (err, apiRes) {
                        return callback(err, apiRes);
                    });
                }
                else if (util_1.util.isClient()) {
                    __1.Auth.redirectSignin(util_1.util.getRelativeUrl());
                }
                else {
                    return callback(err401, null);
                }
            });
        },
        userFollowing: function (query, callback) {
            __1.Auth.isAuthed(function () {
                request.get(global_1.Global.cruzApiBaseUrl + '/user/following' + util_1.util.buildApiQueryString(query)).set(__1.Auth.authHeader()).timeout(timeout.normal).end(function (err, apiRes) {
                    return callback(err, apiRes);
                });
            });
        },
        searchUser: function (query, callback) {
            __1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    request.get(global_1.Global.cruzApiBaseUrl + '/user/search' + util_1.util.buildApiQueryString(query)).set(__1.Auth.authHeader()).timeout(timeout.normal).end(function (err, apiRes) {
                        return callback(err, apiRes);
                    });
                }
                else if (util_1.util.isClient()) {
                    __1.Auth.redirectSignin(util_1.util.getRelativeUrl());
                }
                else {
                    return callback(err401, null);
                }
            });
        }
    };
    Request.geo = {
        getZipCode: function (zip_code, callback) {
            request.get(global_1.Global.cruzApiBaseUrl + '/geo/zip-code/' + zip_code).timeout(timeout.normal).end(function (err, apiRes) {
                return callback(err, apiRes);
            });
        }
    };
    Request.lookup = {
        bodyType: function (callback) {
            request.get(global_1.Global.cruzApiBaseUrl + '/lookup/body-types').timeout(timeout.normal)
                .then(function (apiRes) { return callback(null, apiRes.body.data); })
                .catch(function (err) {
                return callback(err, null);
            });
        },
        saleStatus: function (showAll, callback) {
            request.get(global_1.Global.cruzApiBaseUrl + '/lookup/sale-status' + (showAll ? '/all' : '')).timeout(timeout.normal).end(function (err, apiRes) {
                return callback(err, apiRes);
            });
        }
    };
    Request.dataone = {
        makes: function (query, callback) {
            request.get(global_1.Global.cruzApiBaseUrl + '/dataone/makes' + (!_.isEmpty(query) ? util_1.util.buildApiQueryString(query) : '')).timeout(timeout.normal).end(function (err, apiRes) {
                return callback(err, apiRes);
            });
        },
        makesGroup: function (query, callback) {
            request.get(global_1.Global.cruzApiBaseUrl + '/dataone/makes-group' + (!_.isEmpty(query) ? util_1.util.buildApiQueryString(query) : ''))
                .timeout(timeout.normal).end(function (err, apiRes) {
                return callback(err, apiRes);
            });
        },
        models: function (query, callback) {
            request.get(global_1.Global.cruzApiBaseUrl + '/dataone/models')
                .query(query).timeout(timeout.normal).end(function (err, apiRes) {
                return callback(err, apiRes);
            });
        },
        modelsGroup: function (query, callback) {
            request.get(global_1.Global.cruzApiBaseUrl + '/dataone/models-group')
                .query(query).timeout(timeout.normal).end(function (err, apiRes) {
                return callback(err, apiRes);
            });
        },
        trims: function (query, callback) {
            request.get(global_1.Global.cruzApiBaseUrl + '/dataone/trims').query(query)
                .timeout(timeout.normal).end(function (err, apiRes) {
                return callback(err, apiRes);
            });
        },
        exteriorColors: function (query, callback) {
            request.get(global_1.Global.cruzApiBaseUrl + '/dataone/exterior-colors')
                .query(query).timeout(timeout.normal).end(function (err, apiRes) {
                return callback(err, apiRes);
            });
        },
        interiorColors: function (query, callback) {
            request.get(global_1.Global.cruzApiBaseUrl + '/dataone/interior-colors').query(query)
                .timeout(timeout.normal).end(function (err, apiRes) {
                return callback(err, apiRes);
            });
        },
        roofColors: function (query, callback) {
            request.get(global_1.Global.cruzApiBaseUrl + '/dataone/roof-colors').query(query)
                .timeout(cfg.superagent.timeout.normal).end(function (err, apiRes) {
                return callback(err, apiRes);
            });
        },
        noneDecodeOptions: function (callback) {
            request.get(global_1.Global.cruzApiBaseUrl + '/dataone/none-decode-options')
                .timeout(timeout.normal).end(function (err, apiRes) {
                return callback(err, apiRes);
            });
        },
        reverseDecode: function (query, callback) {
            request.get(global_1.Global.cruzApiBaseUrl + '/dataone/decode/reverse')
                .query(query).timeout(timeout.normal).end(function (err, apiRes) {
                return callback(err, apiRes);
            });
        },
        vinDecode: function (vin, cruz_vehicle_id, callback) {
            request.get(global_1.Global.cruzApiBaseUrl + '/dataone/decode/vin?vin=' + vin + (cruz_vehicle_id != null ? '&cruz_vehicle_id=' + cruz_vehicle_id : ''))
                .timeout(timeout.normal).end(function (err, apiRes) {
                return callback(err, apiRes);
            });
        }
    };
    Request.deal = {
        detail: function (_id, callback) {
            __1.Auth.isAuthed(function () {
                request.get(global_1.Global.cruzApiBaseUrl + '/deal/' + _id).set(__1.Auth.authHeader()).timeout(timeout.normal).end(function (err, apiRes) {
                    return callback(err, apiRes);
                });
            });
        }
    };
    Request.spotted = {
        search: function (query, callback) {
            __1.Auth.isAuthed(function () {
                request.get(global_1.Global.cruzApiBaseUrl + '/spotted' + util_1.util.buildApiQueryString(query)).set(__1.Auth.authHeader()).timeout(timeout.normal).end(function (err, apiRes) {
                    return callback(err, apiRes);
                });
            });
        },
        mySpotted: function (query, callback) {
            __1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    request.get(global_1.Global.cruzApiBaseUrl + '/user/my-spotted' + util_1.util.buildApiQueryString(query)).set(__1.Auth.authHeader()).timeout(timeout.normal).end(function (err, apiRes) {
                        return callback(err, apiRes);
                    });
                }
                else if (util_1.util.isClient()) {
                    __1.Auth.redirectSignin(util_1.util.getRelativeUrl());
                }
                else {
                    return callback(err401, null);
                }
            });
        },
        detail: function (_id, callback) {
            __1.Auth.isAuthed(function () {
                request.get(global_1.Global.cruzApiBaseUrl + '/spotted/' + _id).set(__1.Auth.authHeader()).timeout(timeout.normal).end(function (err, apiRes) {
                    return callback(err, apiRes);
                });
            });
        },
        forUpdate: function (_id, callback) {
            __1.Auth.isAuthed(function () {
                request.get(global_1.Global.cruzApiBaseUrl + '/spotted/' + _id + '/update').set(__1.Auth.authHeader()).timeout(timeout.normal).end(function (err, apiRes) {
                    return callback(err, apiRes);
                });
            });
        },
        delete: function (_id, callback) {
            __1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    request.delete(global_1.Global.cruzApiBaseUrl + '/spotted/' + _id).set(__1.Auth.authHeader()).timeout(timeout.normal).end(function (err, apiRes) {
                        return callback(err, apiRes);
                    });
                }
                else if (util_1.util.isClient()) {
                    __1.Auth.redirectSignin(util_1.util.getRelativeUrl());
                }
                else {
                    return callback(err401, null);
                }
            });
        },
        undelete: function (_id, callback) {
            __1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    request.patch(global_1.Global.cruzApiBaseUrl + '/spotted/' + _id).set(__1.Auth.authHeader()).timeout(timeout.normal).end(function (err, apiRes) {
                        return callback(err, apiRes);
                    });
                }
                else {
                    __1.Auth.redirectSignin(util_1.util.getRelativeUrl());
                }
            });
        }
    };
    Request.vehicle = {
        getMap: function (_id, callback) {
            request.get(global_1.Global.cruzApiBaseUrl + '/vehicle-map/' + _id).end(function (err, apiRes) {
                if (err) {
                    return callback(err, null);
                }
                else {
                    return callback(null, _.get(apiRes, 'body.data'));
                }
            });
        },
        search: function (query, callback) {
            __1.Auth.isAuthed(function () {
                request.get(global_1.Global.cruzApiBaseUrl + '/vehicle' + util_1.util.buildApiQueryString(query))
                    .set(__1.Auth.authHeader())
                    .timeout(timeout.normal)
                    .end(function (err, apiRes) {
                    return callback(err, apiRes);
                });
            });
        },
        myVehicle: function (query, callback) {
            __1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    request.get(global_1.Global.cruzApiBaseUrl + '/user/my-vehicles' + util_1.util.buildApiQueryString(query)).set(__1.Auth.authHeader()).timeout(timeout.normal).end(function (err, apiRes) {
                        return callback(err, apiRes);
                    });
                }
                else if (util_1.util.isClient()) {
                    __1.Auth.redirectAuthed(util_1.util.getRelativeUrl());
                }
                else {
                    return callback(err401, null);
                }
            });
        },
        detail: function (_id, zip_code, callback) {
            __1.Auth.isAuthed(function () {
                request.get(global_1.Global.cruzApiBaseUrl + '/vehicle/detail/' + _id).set(__1.Auth.authHeader()).timeout(timeout.normal).end(function (err, apiRes) {
                    return callback(err, apiRes);
                });
            });
        },
        delete: function (_id, callback) {
            __1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    request.delete(global_1.Global.cruzApiBaseUrl + '/vehicle/' + _id).set(__1.Auth.authHeader()).timeout(timeout.normal).end(function (err, apiRes) {
                        return callback(err, apiRes);
                    });
                }
                else {
                    __1.Auth.redirectSignin(util_1.util.getRelativeUrl());
                }
            });
        },
        undelete: function (_id, callback) {
            __1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    request.patch(global_1.Global.cruzApiBaseUrl + '/vehicle/' + _id).set(__1.Auth.authHeader()).timeout(timeout.normal).end(function (err, apiRes) {
                        return callback(err, apiRes);
                    });
                }
                else {
                    __1.Auth.redirectSignin(util_1.util.getRelativeUrl());
                }
            });
        }
    };
    Request.saved = {
        item: {
            create: function (body, callback) {
                __1.Auth.isAuthed(function (isAuthed) {
                    if (isAuthed) {
                        request.post(global_1.Global.cruzApiBaseUrl + '/user/saved-item').set(__1.Auth.authHeader()).timeout(timeout.normal).send(body).end(function (err, apiRes) {
                            return callback(err, apiRes);
                        });
                    }
                    else {
                        __1.Auth.redirectSignin(util_1.util.getRelativeUrl());
                    }
                });
            },
            get: function (key, query, callback) {
                __1.Auth.isAuthed(function (isAuthed) {
                    if (isAuthed) {
                        request.get(global_1.Global.cruzApiBaseUrl + '/user/saved-item/' + key + util_1.util.buildApiQueryString(query)).set(__1.Auth.authHeader()).timeout(timeout.normal).end(function (err, apiRes) {
                            return callback(err, apiRes);
                        });
                    }
                    else {
                        __1.Auth.redirectSignin(util_1.util.getRelativeUrl());
                    }
                });
            },
            delete: function (body, callback) {
                __1.Auth.isAuthed(function (isAuthed) {
                    if (isAuthed) {
                        request.delete(global_1.Global.cruzApiBaseUrl + '/user/saved-item').set(__1.Auth.authHeader()).timeout(timeout.normal).send(body).end(function (err, apiRes) {
                            return callback(err, apiRes);
                        });
                    }
                    else {
                        __1.Auth.redirectSignin(util_1.util.getRelativeUrl());
                    }
                });
            },
        },
        vehicle_filter: {
            get: function (callback) {
                __1.Auth.isAuthed(function (isAuthed) {
                    if (isAuthed) {
                        request.get(global_1.Global.cruzApiBaseUrl + '/user/saved-vehicle-filter').set(__1.Auth.authHeader()).timeout(timeout.normal).end(function (err, apiRes) {
                            return callback(err, apiRes);
                        });
                    }
                    else {
                        __1.Auth.redirectSignin(util_1.util.getRelativeUrl());
                    }
                });
            },
            create: function (body, callback) {
                __1.Auth.isAuthed(function (isAuthed) {
                    if (isAuthed) {
                        request.post(global_1.Global.cruzApiBaseUrl + '/user/saved-vehicle-filter').set(__1.Auth.authHeader()).timeout(timeout.normal).send(body).end(function (err, apiRes) {
                            return callback(err, apiRes);
                        });
                    }
                    else {
                        __1.Auth.redirectSignin(util_1.util.getRelativeUrl());
                    }
                });
            },
            updateDateView: function (id, callback) {
                __1.Auth.isAuthed(function (isAuthed) {
                    if (isAuthed) {
                        request.put(global_1.Global.cruzApiBaseUrl + '/user/saved-vehicle-filter/' + id + '/viewed').set(__1.Auth.authHeader()).timeout(timeout.normal).end(function (err, apiRes) {
                            return callback(err, apiRes);
                        });
                    }
                    else {
                        __1.Auth.redirectSignin(util_1.util.getRelativeUrl());
                    }
                });
            },
            rename: function (id, new_name, callback) {
                __1.Auth.isAuthed(function (isAuthed) {
                    if (isAuthed) {
                        request.patch(global_1.Global.cruzApiBaseUrl + '/user/saved-vehicle-filter/' + id).set(__1.Auth.authHeader()).timeout(timeout.normal).send({ name: new_name }).end(function (err, apiRes) {
                            return callback(err, apiRes);
                        });
                    }
                    else {
                        __1.Auth.redirectSignin(util_1.util.getRelativeUrl());
                    }
                });
            },
            delete: function (id, callback) {
                __1.Auth.isAuthed(function (isAuthed) {
                    if (isAuthed) {
                        request.delete(global_1.Global.cruzApiBaseUrl + '/user/saved-vehicle-filter/' + id).set(__1.Auth.authHeader()).timeout(timeout.normal).end(function (err, apiRes) {
                            return callback(err, apiRes);
                        });
                    }
                    else {
                        __1.Auth.redirectSignin(util_1.util.getRelativeUrl());
                    }
                });
            }
        }
    };
})(Request = exports.Request || (exports.Request = {}));
