"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var Redux = require("redux");
var react_router_dom_1 = require("react-router-dom");
var request = require("superagent");
var _ = require("lodash");
var uri = require("urijs");
var cfg = require("../../../cfg");
var lib_1 = require("../../lib");
var pages_1 = require("../../../routes/pages");
var component_1 = require("../../component");
var vehicle_1 = require("../../vehicle");
var MyVehicle;
(function (MyVehicle) {
    function searchMyVehicles(query, callback) {
        lib_1.Request.vehicle.myVehicle(query, function (err, apiRes) {
            return callback(err, apiRes);
        });
    }
    MyVehicle.searchMyVehicles = searchMyVehicles;
    var app = function (state, action) {
        function search() {
            searchMyVehicles(state.query, function (err, apiRes) {
                if (err) {
                    lib_1.util.handleError(lib_1.Request.getApiErrorCode(err));
                }
                else {
                    MyVehicle.store.dispatch({ type: 4, data: apiRes.body, isLoadMore: false });
                }
            });
        }
        function update(v) {
            if (!_.isEmpty(v)) {
                var x = _.find(state.result.data, { _id: v._id });
                if (x) {
                    if (x.post_status_id != v.post_status_id || x.sale_status_id != v.sale_status_id || x.is_disabled != v.is_disabled) {
                        request.get(lib_1.Global.cruzApiBaseUrl + '/user/my-vehicle-statistics').set(lib_1.Auth.authHeader()).timeout(cfg.superagent.timeout.normal)
                            .end(function (err, apiRes) {
                            if (err) {
                                MyVehicle.store.dispatch({ type: 0, error: err });
                            }
                            else if (apiRes.body.data) {
                                MyVehicle.store.dispatch({ type: 7, data: apiRes.body.data });
                            }
                        });
                    }
                    _.assign(x, v);
                }
            }
        }
        switch (action.type) {
            case 0: {
                state.is_loading = false;
                state.is_loading_more = false;
                state.is_filtering = false;
                lib_1.util.handleError(lib_1.Request.getApiErrorCode(action.error));
                return state;
            }
            case 2: {
                if (!state.is_loading) {
                    state.is_loading = true;
                    search();
                }
                return state;
            }
            case 8: {
                state.is_filtering = true;
                state.query = _.assign({}, cfg.apiQueryDefault.vehicle, action.query);
                lib_1.Global.history.replace(lib_1.util.buildUrl(location.pathname, state.query));
                search();
                return state;
            }
            case 3: {
                action.event && action.event.preventDefault();
                state.is_loading_more = true;
                searchMyVehicles(_.assign({}, state.query, uri.parseQuery(new uri(state.result.links.next).query())), function (err, apiRes) {
                    if (err) {
                        MyVehicle.store.dispatch({ type: 0, error: err });
                    }
                    else {
                        MyVehicle.store.dispatch({ type: 4, data: apiRes.body, isLoadMore: true });
                    }
                });
                return state;
            }
            case 4: {
                state.is_loading = false;
                state.is_loading_more = false;
                state.is_filtering = false;
                var d = _.assign({}, action.data);
                if (d) {
                    state.result.links = _.assign({}, d.links);
                    state.result.meta = _.assign({}, d.meta);
                    state.result.data = (action.isLoadMore) ? _.union(state.result.data, d.data) : d.data;
                }
                var tgtIndex = d.meta.page_size * (d.meta.page_number - 1);
                if (tgtIndex > 0) {
                    lib_1.util.scrollTo('#_vs_' + tgtIndex, 70);
                }
                return state;
            }
            case 5: {
                update(action.data);
                vehicle_1.VehicleSearchControl.store && vehicle_1.VehicleSearchControl.store.dispatch({ type: 22, data: action.data });
                return state;
            }
            case 6: {
                update(action.data);
                return state;
            }
            case 7: {
                state.result.meta.statistics = _.assign({}, action.data);
                var qKey = _.find(_.keys(state.query), function (x) { return x.includes('is_'); });
                if (qKey) {
                    var val = state.result.meta.statistics[_.replace(qKey, 'is_', '')];
                    if (val == 0) {
                        state.query = _.omit(state.query, qKey);
                        search();
                    }
                }
                return state;
            }
            case 1: {
                state = {
                    result: { data: [], meta: {}, links: {} }
                };
                return state;
            }
            default:
                return state;
        }
    };
    MyVehicle.store = null;
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            return _super.call(this, props) || this;
        }
        Page.prototype.componentWillMount = function () {
            var _this = this;
            lib_1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    var locSearch = new uri(_this.props.location.search).search(true);
                    if (_this.props.staticContext) {
                        MyVehicle.store = Redux.createStore(app, _.assign({}, _this.props.staticContext));
                    }
                    else if (MyVehicle.store == null) {
                        var $initData = document.querySelector('#_init_my_vehicle');
                        if ($initData && $initData.getAttribute('data-init')) {
                            MyVehicle.store = Redux.createStore(app, _.assign({}, {
                                result: JSON.parse(decodeURIComponent($initData.getAttribute('data-init'))),
                                query: locSearch,
                                history: _this.props.history
                            }));
                            $initData.remove();
                        }
                        else {
                            var d = {
                                query: _.assign({}, locSearch),
                                result: { data: [], meta: {}, links: {} },
                                is_loading: false
                            };
                            MyVehicle.store = Redux.createStore(app, d);
                            MyVehicle.store.dispatch({ type: 2 });
                        }
                    }
                    else if (['PUSH', 'REPLACE'].includes(_this.props.history.action)) {
                        MyVehicle.store.dispatch({ type: 2 });
                    }
                    if (lib_1.util.isClient()) {
                        lib_1.Global.history.replace(lib_1.util.buildUrl(location.pathname, locSearch));
                    }
                    _this.unsubscribe = MyVehicle.store.subscribe(function () { _this.forceUpdate(); });
                }
                else {
                    lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                }
            });
        };
        Page.prototype.componentDidMount = function () {
            document.title = lib_1.util.title(pages_1.pages.my_vehicle.title);
            lib_1.Global.saveUserSettings({ my_cruz_path: pages_1.pages.my_vehicle.path });
        };
        Page.prototype.componentWillUnmount = function () {
            this.unsubscribe && this.unsubscribe();
        };
        Page.prototype.componentWillReceiveProps = function (nextProps) {
            if (nextProps.history.action == 'PUSH') {
                MyVehicle.store.dispatch({ type: 2 });
            }
        };
        Page.prototype.renderFilter = function () {
            try {
                var state = MyVehicle.store.getState();
                var s = state.result.meta.statistics;
                var c = _.keys(_.omitBy(s, function (x) { return x == 0; }));
                if (_.size(c) > 1 && state.result.meta.total_records > 2) {
                    return (React.createElement("div", { className: 'c-section-navbar mb-2 mt-3' },
                        React.createElement("ul", { className: 'list-inline c-btn-group-radio mb-1' },
                            React.createElement("li", { className: 'list-inline-item' },
                                React.createElement("input", { type: 'radio', name: 'filter', id: 'filter_0', checked: (!state.query.is_active && !state.query.is_disabled && !state.query.is_sold && !state.query.is_deleted), onChange: function (e) { return MyVehicle.store.dispatch({ type: 8, query: {} }); } }),
                                React.createElement("label", { htmlFor: 'filter_0' },
                                    "All (",
                                    state.result.meta.total_records.toLocaleString(),
                                    ")")),
                            React.createElement("li", { className: 'list-inline-item' }, s.active > 0
                                ?
                                    React.createElement("div", null,
                                        React.createElement("input", { type: 'radio', name: 'filter', id: 'filter_1', checked: state.query.is_active == 1, onChange: function (e) { return MyVehicle.store.dispatch({ type: 8, query: { is_active: 1 } }); } }),
                                        React.createElement("label", { htmlFor: 'filter_1' },
                                            "Active (",
                                            s.active > 0 ? s.active.toLocaleString() : '-',
                                            ")"))
                                :
                                    React.createElement("div", { className: 'c-text-muted' }, "Active (-)")),
                            s.sold > 0
                                &&
                                    React.createElement("li", { className: 'list-inline-item' },
                                        React.createElement("div", null,
                                            React.createElement("input", { type: 'radio', name: 'filter', id: 'filter_2', checked: !_.isEmpty(state.query) && state.query.is_sold == 1, onChange: function () { return MyVehicle.store.dispatch({ type: 8, query: { is_sold: 1 } }); } }),
                                            React.createElement("label", { htmlFor: 'filter_2' },
                                                "Sold (",
                                                s.sold > 0 ? s.sold.toLocaleString() : '-',
                                                ")"))),
                            s.deleted > 0
                                &&
                                    React.createElement("li", { className: 'list-inline-item' },
                                        React.createElement("div", null,
                                            React.createElement("input", { type: 'radio', name: 'filter', id: 'filter_3', checked: !_.isEmpty(state.query) && state.query.is_deleted == 1, onChange: function () { return MyVehicle.store.dispatch({ type: 8, query: { is_deleted: 1 } }); } }),
                                            React.createElement("label", { htmlFor: 'filter_3' },
                                                "Deleted (",
                                                s.deleted.toLocaleString(),
                                                ")"))),
                            (s.disabled > 0)
                                &&
                                    React.createElement("li", { className: 'list-inline-item' },
                                        React.createElement("input", { type: 'radio', name: 'filter', id: 'filter_4', checked: !_.isEmpty(state.query) && state.query.is_disabled == 1, onChange: function (e) { return MyVehicle.store.dispatch({ type: 8, query: { is_disabled: 1 } }); } }),
                                        React.createElement("label", { htmlFor: 'filter_4' },
                                            "Disabled (",
                                            s.disabled.toLocaleString(),
                                            ")")))));
                }
                else {
                    return null;
                }
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.postStatusUpdateDone = function (v) {
            MyVehicle.store.dispatch({ type: 5, data: v });
        };
        Page.prototype.renderVehicleList = function () {
            var _this = this;
            try {
                return (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'row c-sm-gutters' }, _.map(MyVehicle.store.getState().result.data, function (v, idx) {
                        return (React.createElement("div", { className: 'col-xl-4 col-lg-6 col-md-6 mt-2 mb-2', key: v._id, id: '_vs_' + idx },
                            React.createElement(component_1.VehicleCard.Component, __assign({}, {
                                vehicle: v,
                                showUserInfo: false,
                                showEdit: true,
                                onPostStatusUpdateDone: _this.postStatusUpdateDone,
                                shouldRender: true,
                                showVIN: true
                            }))));
                    })),
                    MyVehicle.store.getState().result.links && MyVehicle.store.getState().result.links.next
                        &&
                            React.createElement("div", { className: 'text-center mt-4' },
                                React.createElement("button", { className: 'btn c-btn-2-rev c-btn-round', onClick: function (e) { return MyVehicle.store.dispatch({ type: 3, event: e }); } },
                                    React.createElement("i", { className: 'far fa-angle-double-down' }),
                                    MyVehicle.store.getState().is_loading_more ? React.createElement(component_1.BtnWorking, { value: 'Loading More...' }) : React.createElement("span", null,
                                        "Load More (",
                                        MyVehicle.store.getState().result.meta.total_records_left.toLocaleString() + ' left',
                                        ")")))));
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.renderAddFirstVehicle = function () {
            return (React.createElement("a", { href: pages_1.pages.vehicle_add_edit.path, className: 'btn c-btn-1 c-btn-round mt-2' },
                React.createElement("i", { className: 'fal fa-plus' }),
                React.createElement("span", null, "Add Vehicle")));
        };
        Page.prototype.renderHeading = function () {
            try {
                var meta = MyVehicle.store.getState().result.meta;
                return (React.createElement("div", { className: 'mb-3 d-flex justify-content-between' },
                    React.createElement("h1", { className: 'c-title' },
                        pages_1.pages.my_vehicle.title,
                        (meta.total_records > 0)
                            &&
                                React.createElement("span", { className: 'c-title-count' },
                                    meta.total_records != meta.total_records_matched && meta.total_records_matched.toLocaleString() + '/',
                                    meta.total_records.toLocaleString())),
                    (!_.isEmpty(meta) && meta.total_records > 0)
                        &&
                            React.createElement(react_router_dom_1.Link, { to: pages_1.pages.vehicle_add_edit.path, className: 'btn c-btn-1 c-btn-xs' },
                                React.createElement("i", { className: 'fal fa-plus' }),
                                React.createElement("span", null, "Add Vehicle"))));
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.render = function () {
            return (component_1.Layout.main(lib_1.Global.store.getState().user, null, React.createElement("div", { className: 'container c-container' }, (MyVehicle.store == null || MyVehicle.store.getState().is_loading)
                ?
                    React.createElement(component_1.Loading, null)
                :
                    React.createElement(React.Fragment, null,
                        this.renderHeading(),
                        (MyVehicle.store.getState().result.meta.total_records > 0)
                            &&
                                this.renderFilter(),
                        MyVehicle.store.getState().is_filtering
                            ?
                                React.createElement(component_1.Loading, { label: 'searching ...' })
                            :
                                MyVehicle.store.getState().result.meta.total_records == 0
                                    ?
                                        this.renderAddFirstVehicle()
                                    :
                                        MyVehicle.store.getState().result.meta.total_records_matched > 0
                                            ?
                                                this.renderVehicleList()
                                            :
                                                MyVehicle.store.getState().result.meta.total_records > 0 && React.createElement("div", { className: 'mt-3' }, "No vehicle matched")))));
        };
        return Page;
    }(React.Component));
    MyVehicle.Page = Page;
})(MyVehicle = exports.MyVehicle || (exports.MyVehicle = {}));
