"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var _ = require("lodash");
var moment = require("moment");
var request = require("superagent");
var Cruz = require("cruz");
var pages_1 = require("../../routes/pages");
var component_1 = require("../component");
var lib_1 = require("../lib");
var cfg = require("../../cfg");
var ServiceVoucher;
(function (ServiceVoucher) {
    function getVoucher(_id, callback) {
        request.get(lib_1.Global.cruzApiBaseUrl + '/user/service-quote/' + _id + '/order')
            .set(lib_1.Auth.authHeader())
            .end(function (err, apiRes) {
            return callback(err, _.get(apiRes.body, 'data'));
        });
    }
    ServiceVoucher.getVoucher = getVoucher;
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                is_loading: false,
                quote: {}
            };
            return _this;
        }
        Page.prototype.componentWillMount = function () {
            var _this = this;
            lib_1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    try {
                        if (_this.props.staticContext) {
                            var d = JSON.parse(JSON.stringify(_this.props.staticContext));
                            _this.setState({ is_loading: false, quote: d.result });
                        }
                        else {
                            var $initData = document.querySelector('#_init_' + pages_1.pages.service_voucher.id);
                            if ($initData && $initData.getAttribute('data-init')) {
                                var d = JSON.parse(decodeURIComponent($initData.getAttribute('data-init')));
                                $initData.remove();
                                _this.setState({ quote: d, is_loading: false });
                            }
                            else {
                                _this.init();
                            }
                        }
                    }
                    catch (err) {
                    }
                }
                else {
                    lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                }
            });
        };
        Page.prototype.componentDidMount = function () {
        };
        Page.prototype.init = function () {
            var _this = this;
            this.setState({ is_loading: true });
            getVoucher(this.props.match.params._id, function (err, result) {
                if (err) {
                    lib_1.util.handleError(lib_1.Request.getApiErrorCode(err));
                }
                else {
                    _this.setState({ is_loading: false, quote: result }, function () {
                        _this.setPageTitle();
                    });
                }
            });
        };
        Page.prototype.setPageTitle = function () {
            document.title = lib_1.util.title(pages_1.pages.service_voucher.title, _.isEmpty(this.state.quote) ? null : this.state.quote.service.name);
        };
        Page.prototype.renderVehicle = function () {
            var vd = this.state.quote.vehicle_display;
            return (React.createElement("div", { className: 'border rounded p-4 section' },
                React.createElement("div", { className: 'section-icon' },
                    React.createElement("i", { className: 'fa fa-car' })),
                React.createElement("div", { className: 'c-font-weight-medium c-font-size-l mb-2' },
                    React.createElement(react_router_dom_1.Link, { to: component_1.Vehicle.detailUrl(vd), className: 'c-btn-link' }, component_1.Vehicle.detailTitle(vd, false))),
                React.createElement("div", { className: 'row' },
                    React.createElement("div", { className: 'col-sm-6' },
                        React.createElement("div", { className: 'text-muted c-font-size-xs' }, "Mileage"),
                        React.createElement("div", null,
                            vd.mileage.toLocaleString(),
                            " miles")),
                    React.createElement("div", { className: 'col-sm-6 mt-3 mt-sm-0' },
                        React.createElement("div", { className: 'text-muted c-font-size-xs' }, "VIN"),
                        React.createElement("div", null, _.isEmpty(vd.vin) ? '-' : vd.vin)),
                    React.createElement("div", { className: 'col-12 col-md-6 mt-3' },
                        React.createElement("div", { className: 'text-muted c-font-size-xs' }, "Engine"),
                        React.createElement("div", null, vd.engine.name)),
                    React.createElement("div", { className: 'col-12 col-md-6 mt-3' },
                        React.createElement("div", { className: 'text-muted c-font-size-xs' }, "Transmission"),
                        React.createElement("div", null, vd.transmission.name)))));
        };
        Page.prototype.renderLocation = function (loc) {
            return (React.createElement(React.Fragment, null,
                (!_.isEmpty(loc.formatted_address) || !_.isEmpty(loc.address1)) &&
                    React.createElement(React.Fragment, null,
                        React.createElement("span", null, loc.address1),
                        React.createElement("span", { className: 'd-none d-sm-inline' }, ", "),
                        React.createElement("span", { className: 'd-block d-sm-none' })),
                loc.city + ', ' + loc.state_abbr + ' ' + loc.zip_code,
                !_.isEmpty(loc.phone) &&
                    React.createElement("div", null, loc.phone)));
        };
        Page.prototype.renderVendor = function () {
            var _this = this;
            var qr = this.state.quote.order.result;
            var vendor = qr.vendor_display;
            return (React.createElement("div", { className: 'border rounded p-4 section' },
                React.createElement("div", { className: 'section-icon' },
                    React.createElement("i", { className: 'fa fa-map-marker-alt' })),
                React.createElement("div", { className: 'c-font-weight-medium c-font-size-l mb-2' }, vendor.name),
                !_.isEmpty(qr.reference)
                    &&
                        React.createElement("div", { className: 'mb-2' },
                            React.createElement("span", { className: 'text-muted c-font-size-xs mr-2' }, "Reference:"),
                            React.createElement("span", { className: 'c-font-weight-medium' }, qr.reference)),
                _.size(vendor.location_list) == 1
                    &&
                        React.createElement(React.Fragment, null,
                            React.createElement("hr", { className: 'd-none d-lg-block' }),
                            this.renderLocation(_.first(vendor.location_list))),
                (_.size(vendor.location_list) > 1)
                    &&
                        React.createElement("div", { className: 'pt-2' },
                            React.createElement("ul", { className: 'list-group' }, _.map(vendor.location_list, function (loc, idx) {
                                return (React.createElement("li", { key: idx, className: 'list-group-item' }, _this.renderLocation(loc)));
                            })))));
        };
        Page.prototype.renderQuoteItem = function () {
            var item = _.first(this.state.quote.order.result.item_list);
            return (React.createElement("div", { className: 'border rounded p-4 section' },
                React.createElement("div", { className: 'section-icon' },
                    React.createElement("i", { className: 'fa fa-box-alt' })),
                React.createElement("div", { className: 'c-font-weight-medium c-font-size-l mb-2' },
                    item.brand,
                    !_.isEmpty(item.model) && ' - ' + item.model),
                React.createElement("div", null,
                    React.createElement("div", { className: 'text-muted c-font-size-xs' }, "Estimated Hours"),
                    React.createElement("div", null, (lib_1.util.isNumeric(item.estimate_hours) ? item.estimate_hours + ' hours' : '-'))),
                !_.isEmpty(item.description)
                    &&
                        React.createElement("div", { className: 'pt-3' },
                            React.createElement("div", { className: 'text-muted c-font-size-xs' }, "Description"),
                            lib_1.util.text2html(item.description)),
                !_.isEmpty(item.warranty)
                    &&
                        React.createElement("div", { className: 'pt-3' },
                            React.createElement("div", { className: 'text-muted c-font-size-xs' }, "Warranty"),
                            lib_1.util.text2html(item.warranty))));
        };
        Page.prototype.renderVoucher = function () {
            var quote = this.state.quote;
            var aDate = quote.order.result.require_appointment ? moment(quote.schedule.date, 'MM/DD/YYYY HH:mm') : null;
            return (React.createElement("div", { className: 'service-voucher mx-auto' },
                React.createElement("div", { className: 'mt-2 mb-3 c-font-size-s text-muted' }, "Please show this to vendor at time of service"),
                React.createElement("div", { className: 'border rounded p-4 shadow' },
                    quote.status_id == Cruz.Lookup.Service.QuoteStatusEnum.Completed
                        &&
                            React.createElement("div", { className: 'stamp completed' }, "Completed"),
                    quote.status_id == Cruz.Lookup.Service.QuoteStatusEnum.RequestCancellation
                        &&
                            React.createElement("div", { className: 'stamp cancel' }, "Cancelling"),
                    quote.status_id == Cruz.Lookup.Service.QuoteStatusEnum.Cancelled
                        &&
                            React.createElement("div", { className: 'stamp cancel' }, "Cancelled"),
                    React.createElement("div", { className: 'c-font-family-primary c-font-size-xl c-font-weight-semi-bold pt-2 pb-3 px-4' }, quote.service.name),
                    (quote.status_id > 0)
                        &&
                            React.createElement("div", { className: 'px-4' }, (aDate == null && quote.status_id > 0)
                                ?
                                    'No Appointment Required'
                                :
                                    React.createElement("ul", { className: 'list-inline' },
                                        React.createElement("li", { className: 'list-inline-item' },
                                            React.createElement("div", { className: 'text-muted c-font-size-xs' }, "Date"),
                                            React.createElement("div", { className: 'c-font-weight-medium' }, aDate.format(cfg.format.date))),
                                        React.createElement("li", { className: 'list-inline-item ml-3' },
                                            React.createElement("div", { className: 'text-muted c-font-size-xs' }, "Time"),
                                            React.createElement("div", { className: 'c-font-weight-medium' }, aDate.format(cfg.format.time))))),
                    this.renderVehicle(),
                    this.renderVendor(),
                    this.renderQuoteItem(),
                    React.createElement("div", { className: 'mt-4 px-4' },
                        "Please call or text ",
                        React.createElement("span", { className: 'c-font-weight-semi-bold' }, "(605) 929-8793"),
                        " with questions. Thank you for choosing Cruz\u00AE concierge."))));
        };
        Page.prototype.renderHeading = function () {
            return (React.createElement("div", { className: 'c-detail-heading d-flex flex-column flex-sm-row justify-content-between align-items-sm-end' },
                React.createElement("h1", { className: 'c-title' }, pages_1.pages.service_quote.title),
                React.createElement("div", { className: 'mt- mt-sm-0 d-flex ml-sm-auto align-items-end' },
                    React.createElement(component_1.Support.Phone, null))));
        };
        Page.prototype.render = function () {
            return (component_1.Layout.main(lib_1.Global.store.getState().user, null, React.createElement("div", { className: 'container c-container' }, this.state.is_loading || _.isEmpty(this.state.quote)
                ?
                    React.createElement(component_1.Loading, null)
                :
                    React.createElement(React.Fragment, null,
                        this.renderHeading(),
                        React.createElement("hr", { className: 'mb-4' }),
                        this.renderVoucher()))));
        };
        return Page;
    }(React.Component));
    ServiceVoucher.Page = Page;
})(ServiceVoucher = exports.ServiceVoucher || (exports.ServiceVoucher = {}));
