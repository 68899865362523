"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var ReactDOM = require("react-dom");
var _ = require("lodash");
var react_image_gallery_1 = require("react-image-gallery");
var lib_1 = require("../../lib");
var cfg = require("../../../cfg");
var PhotoView;
(function (PhotoView) {
    function riGallery(title, baseUrl, photo_list, showFullScreenButton) {
        var photos = _.map(photo_list, function (p) {
            return {
                original: baseUrl + p.id + '-lg.jpg',
                thumbnail: baseUrl + p.id + '-md.jpg',
                originalTitle: title
            };
        });
        return (React.createElement(react_image_gallery_1.default, { items: photos, additionalClass: 'c-ri-img-gallery', showPlayButton: false, useBrowserFullscreen: false, showThumbnails: _.size(photo_list) > 1, showIndex: false, showFullscreenButton: showFullScreenButton, useTranslate3D: true, renderLeftNav: function (onClick) {
                return (React.createElement("button", { className: 'image-gallery-custom-left-nav', onClick: onClick }));
            }, renderRightNav: function (onClick) {
                return (React.createElement("button", { className: 'image-gallery-custom-right-nav', onClick: onClick }));
            }, renderFullscreenButton: function (onClick) {
                return (React.createElement("button", { type: 'button', className: 'image-gallery-fullscreen-button', onClick: function () {
                        lib_1.util.setModalPhotoView({
                            title: title,
                            baseUrl: baseUrl,
                            photo_list: photo_list
                        });
                    } }));
            }, renderItem: function (item) {
                return (React.createElement("div", { className: 'image-gallery-image' },
                    React.createElement("figure", { className: 'figure' },
                        React.createElement("img", { src: item.original, alt: title }),
                        React.createElement("figcaption", { className: 'd-none' }, title))));
            } }));
    }
    PhotoView.riGallery = riGallery;
    function riStockPhotoGallery(title, photo_list) {
        var photos = _.map(photo_list, function (p) {
            return {
                original: cfg.photoBaseUrl.stock + p,
                thumbnail: cfg.photoBaseUrl.stock + p
            };
        });
        return (React.createElement(react_image_gallery_1.default, { items: photos, additionalClass: 'c-ri-img-gallery', showPlayButton: false, useBrowserFullscreen: false, showThumbnails: _.size(photo_list) > 1, showIndex: false, showFullscreenButton: false, renderLeftNav: function (onClick) {
                return (React.createElement("button", { className: 'image-gallery-custom-left-nav', onClick: onClick }));
            }, renderRightNav: function (onClick) {
                return (React.createElement("button", { className: 'image-gallery-custom-right-nav', onClick: onClick }));
            }, renderItem: function (item) {
                return (React.createElement("div", { className: "image-gallery-image c-veh-stock-photo text-center" },
                    React.createElement("figure", { className: 'figure' },
                        React.createElement("img", { src: item.original, alt: title }),
                        React.createElement("figcaption", { className: 'c-photo-des' }, "* Not Actual Photo"))));
            } }));
    }
    PhotoView.riStockPhotoGallery = riStockPhotoGallery;
    var Enlarge = (function (_super) {
        __extends(Enlarge, _super);
        function Enlarge(props) {
            return _super.call(this, props) || this;
        }
        Enlarge.prototype.componentDidMount = function () {
            var _this = this;
            $('#_photoEnlarge').modal('show').on('hidden.bs.modal', function () {
                lib_1.util.removeModal(_this);
            });
        };
        Enlarge.prototype.render = function () {
            try {
                return (React.createElement("div", { className: 'modal modal-photo', id: '_photoEnlarge', tabIndex: -1, role: 'dialog', "data-keyboard": true },
                    React.createElement("button", { type: 'button', className: 'c-btn-close', "data-dismiss": 'modal', title: 'Close' },
                        React.createElement("i", { className: 'far fa-times' })),
                    React.createElement("div", { className: 'modal-dialog', role: 'document' },
                        React.createElement("div", { className: 'modal-content' },
                            React.createElement("div", { className: 'modal-body mx-auto' },
                                React.createElement("img", { src: this.props.src, alt: this.props.title, className: 'img-fluid' }))))));
            }
            catch (err) {
                lib_1.util.logError(err, null);
                return null;
            }
        };
        return Enlarge;
    }(React.Component));
    PhotoView.Enlarge = Enlarge;
    var ListView = (function (_super) {
        __extends(ListView, _super);
        function ListView(props) {
            return _super.call(this, props) || this;
        }
        ListView.prototype.componentDidMount = function () {
            $('#_photoListView').modal('show').on('hidden.bs.modal', function (e) {
                $(this).data('bs.modal', null);
                if (document.querySelector('#_modal')) {
                    ReactDOM.unmountComponentAtNode(document.querySelector('#_modal'));
                }
            });
        };
        ListView.prototype.render = function () {
            try {
                var pp_1 = this.props;
                return (React.createElement("div", { className: 'modal modal-photo', id: '_photoListView', tabIndex: -1, role: 'dialog', "data-keyboard": true },
                    React.createElement("button", { type: 'button', className: 'c-btn-close', "data-dismiss": 'modal', title: 'Close' },
                        React.createElement("i", { className: 'far fa-times' })),
                    React.createElement("div", { className: 'modal-dialog', role: 'document' },
                        React.createElement("div", { className: 'modal-content' },
                            React.createElement("div", { className: 'modal-body mx-auto' }, _.map(pp_1.photo_list, function (p) {
                                return (React.createElement("img", { className: 'img-fluid animated fadeIn d-block mx-auto my-1', key: p.id, src: pp_1.baseUrl + p.id + '-lg.jpg' }));
                            }))))));
            }
            catch (err) {
                lib_1.util.logError(err, null);
                return null;
            }
        };
        return ListView;
    }(React.Component));
    PhotoView.ListView = ListView;
})(PhotoView = exports.PhotoView || (exports.PhotoView = {}));
