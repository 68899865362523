"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var classNames = require("classnames");
var showdown = require("showdown");
var request = require("superagent");
var _ = require("lodash");
var moment = require("moment");
var slugify_1 = require("slugify");
var uri = require("urijs");
var numeral = require("numeral");
var Cruz = require("cruz");
var cfg = require("../../../cfg");
var pages_1 = require("../../../routes/pages");
var lib_1 = require("../../lib");
var __1 = require("..");
var Vehicle;
(function (Vehicle) {
    function sameVehicleLink(v, className) {
        try {
            if (v.statistics.count != null && v.statistics.count > 0) {
                return (React.createElement(react_router_dom_1.Link, { to: lib_1.util.buildUrl(pages_1.pages.vehicle_search.path, { make: v.make, model: v.model, year_min: v.year, year_max: v.year }), className: classNames('btn c-btn-3 c-btn-xs d-flex align-items-center', className) },
                    React.createElement("span", { className: 'c-count-prepend c-font-weight-medium' }, v.statistics.count.toLocaleString()),
                    React.createElement("span", { className: 'c-font-size-xxs' }, "People with this Vehicle")));
            }
            else {
                return null;
            }
        }
        catch (err) {
            lib_1.util.logError(err, null);
            return null;
        }
    }
    Vehicle.sameVehicleLink = sameVehicleLink;
    function detailUrl(v) {
        try {
            if (_.isEmpty(v)) {
                return null;
            }
            else {
                var slugTitle = '';
                if (v.sale_status_id == Cruz.Lookup.Vehicle.SaleStatusEnum.OpenToOffers || v.sale_status_id == Cruz.Lookup.Vehicle.SaleStatusEnum.ForSale) {
                    var ss = _.find(lib_1.Lookup.saleStatus, { id: v.sale_status_id });
                    slugTitle = slugify_1.default(_.trim(detailTitle(v, false) + ' ' + (_.get(ss, 'status') || '')), { replacement: '-' });
                }
                else {
                    slugTitle = slugify_1.default(detailTitle(v, false), { replacement: '-' });
                }
                return '/vehicle/' + slugify_1.default(v.location.city + ' ' + v.location.state + ' ' + v.location.zip_code, { replacement: '-' }) + '/' + slugTitle + '/' + v._id;
            }
        }
        catch (err) {
            return null;
        }
    }
    Vehicle.detailUrl = detailUrl;
    function detailTitle(v, showLocation) {
        try {
            var res = '';
            var _year = (v.year != null && v.year > 0 ? v.year.toString() : '') + ' ';
            if (v.custom && v.custom.title && v.custom.title.trim() != '') {
                res = _year + '(' + v.custom.title + ')';
            }
            else {
                if (v.make == '~') {
                    res = _year + 'Custom ' + ((v.custom && v.custom.brand) ? v.custom.brand : '').trim();
                }
                else if (v.model == '~') {
                    res = _year + v.make;
                }
                else {
                    res = (_year + v.make + ' ' + v.model + ' ' + (v.trim && v.trim != '' ? v.trim : '')).trim();
                }
            }
            if (showLocation) {
                return res + ' | ' + v.location.city + ', ' + v.location.state_abbr;
            }
            else {
                return res;
            }
        }
        catch (err) {
            return null;
        }
    }
    Vehicle.detailTitle = detailTitle;
    function getDisplayName(v) {
        var displayName = null;
        if (!_.isEmpty(v.vendor_display)) {
            if (_.get(v.vendor_display, 'location.name')) {
                displayName = _.get(v.vendor_display, 'location.name');
            }
            else {
                displayName = _.get(v.vendor_display, 'name');
            }
        }
        else if (!_.isEmpty(v.user_display)) {
            displayName = v.user_display.first_name + ' ' + v.user_display.last_name;
        }
        return displayName;
    }
    Vehicle.getDisplayName = getDisplayName;
    function detailDescription(v) {
        try {
            var vd = [];
            if (v.sale_status_id != null) {
                var displayName = getDisplayName(v);
                var sale_status = _.find(lib_1.Lookup.saleStatus, { id: v.sale_status_id }).status + (displayName ? ' by ' + displayName : '');
                vd.push(sale_status);
                if (v.sale_status_id == Cruz.Lookup.Vehicle.SaleStatusEnum.ForSale) {
                    vd.push('Price ' + v.price.toLocaleString());
                }
            }
            if (!_.isEmpty(v.engine) && !_.isEmpty(v.engine.name)) {
                vd.push('Engine: ' + v.engine.name);
            }
            if (!_.isEmpty(v.transmission) && !_.isEmpty(v.transmission.name)) {
                vd.push('Transmission: ' + v.transmission.name);
            }
            if (!_.isEmpty(v.drive_type_name)) {
                vd.push('Drive type: ' + v.drive_type_name);
            }
            if (_.size(v.mpg_list) > 0) {
                if (v.mpg_list.length == 1) {
                    var d = _.first(v.mpg_list);
                    vd.push('MPG: ' + d.city + ' (city) | ' + d.highway + ' (highway)');
                }
            }
            if (v.doors != null && v.doors > 0) {
                vd.push('Doors: ' + v.doors);
            }
            if (!_.isEmpty(v.specification) && v.specification.max_seating != null && v.specification.max_seating > 0) {
                vd.push('Max seating: ' + v.specification.max_seating);
            }
            if (v.mileage != null && v.mileage > 0) {
                vd.push('Mileage: ' + v.mileage.toLocaleString() + ' miles');
            }
            if (!_.isEmpty(v.location)) {
                vd.push(v.location.city + ', ' + v.location.state + ' ' + v.location.zip_code);
            }
            return vd.join('; ');
        }
        catch (err) {
            lib_1.util.logError(err, null);
            return null;
        }
    }
    Vehicle.detailDescription = detailDescription;
    function searchDescription(q, location) {
        try {
            var res = 'Find the best ';
            var tp = [];
            if (q.condition != null) {
                var x = _.find(lib_1.Lookup.condition, { id: Number(q.condition) });
                if (x) {
                    tp.push(x.name);
                }
            }
            if (q.luxury) {
                tp.push('Luxury');
            }
            else if (q.sport) {
                tp.push('Sport');
            }
            else if (q.exotic) {
                tp.push('Exotic');
            }
            if (q.make == null && q.body_type == null) {
                tp.push('Vehicles');
            }
            else {
                if (q.make != null) {
                    if (q.year_min != null) {
                        if (q.year_min == q.year_max) {
                            tp.push(q.year_min);
                        }
                        else if (q.year_min < q.year_max) {
                            tp.push(q.year_min + '-' + q.year_max);
                        }
                    }
                    tp.push(q.make);
                    if (q.model != null) {
                        tp.push(q.model);
                        if (q.trim != null) {
                            tp.push(q.trim);
                        }
                    }
                }
                else if (q.body_type != null) {
                    var bt = lib_1.util.getBodyTypeByValue(q.body_type);
                    if (!_.isEmpty(bt)) {
                        tp.push(bt.name);
                    }
                }
            }
            res += _.join(tp, ' ');
            if (!_.isEmpty(location)) {
                res += ' in ' + location.city + ', ' + location.state_abbr;
            }
            else if (_.size(q.zip_code) == 5) {
                res += ' near ' + q.zip_code;
            }
            else {
                res += ' near you.';
            }
            return res;
        }
        catch (err) {
            lib_1.util.logError(err, null);
            return null;
        }
    }
    Vehicle.searchDescription = searchDescription;
    var SaleStatus = (function (_super) {
        __extends(SaleStatus, _super);
        function SaleStatus(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                vehicle: _this.props.vehicle,
                vehicleInput: _.pick(_this.props.vehicle, ['sale_status_id', 'sale_status', 'price']),
                sale_status_options: null,
                is_editing: false,
                is_working: false,
                price_error: null
            };
            return _this;
        }
        SaleStatus.prototype.componentDidMount = function () {
            var _this = this;
            var my_user_id = !_.isEmpty(lib_1.Global.store.getState().user) ? lib_1.Global.store.getState().user._id : null;
            if ((my_user_id && this.props.vehicle.user_id == my_user_id)) {
                lib_1.Request.lookup.saleStatus(true, function (err, apiRes) {
                    if (!err) {
                        _this.setState({ sale_status_options: apiRes.body.data });
                    }
                });
            }
        };
        SaleStatus.prototype.toggleEdit = function () {
            if (this.state.is_editing) {
                this.setState({ is_editing: false, vehicleInput: _.pick(this.props.vehicle, ['sale_status_id', 'sale_status', 'price']) });
            }
            else {
                this.setState({ is_editing: true });
            }
        };
        SaleStatus.prototype.saleStatusChanged = function (tgt) {
            this.setState({ vehicleInput: _.assign({}, this.state.vehicleInput, { sale_status_id: Number(tgt.value) }) });
        };
        SaleStatus.prototype.priceChanged = function (tgt) {
            var _this = this;
            this.setState({ vehicleInput: _.assign({}, this.state.vehicleInput, { price: Number(tgt.value.replace(/[^0-9]/g, '')) }) }, function () {
                if (_this.state.price_error) {
                    _this.isValid();
                }
            });
        };
        SaleStatus.prototype.isValid = function () {
            var ss = _.find(this.state.sale_status_options, { id: this.state.vehicleInput.sale_status_id });
            if (ss && ss.price_required) {
                if (this.state.vehicleInput.price) {
                    this.setState({ price_error: null });
                    return true;
                }
                else {
                    this.setState({ price_error: 'Price is required' });
                    return false;
                }
            }
            else {
                this.setState({ vehicleInput: _.assign({}, this.state.vehicleInput, { price: null }), price_error: null });
                return true;
            }
        };
        SaleStatus.prototype.submit = function (e) {
            var _this = this;
            e && e.preventDefault();
            if (this.isValid()) {
                this.setState({ is_working: true }, function () {
                    lib_1.Auth.isAuthed(function (isAuthed) {
                        if (isAuthed) {
                            request.put(lib_1.Global.cruzApiBaseUrl + '/vehicle/' + _this.state.vehicle._id + '/sale-status')
                                .set(lib_1.Auth.authHeader())
                                .timeout(cfg.superagent.timeout.normal)
                                .send(_this.state.vehicleInput)
                                .end(function (err, apiRes) {
                                if (err) {
                                    _this.setState({ is_working: false });
                                }
                                else {
                                    _this.setState({
                                        is_working: false,
                                        is_editing: false,
                                        vehicle: _.assign({}, _this.state.vehicle, apiRes.body.data)
                                    }, function () {
                                        _this.props.onUpdateDone && _this.props.onUpdateDone(apiRes.body.data);
                                    });
                                }
                            });
                        }
                        else {
                            lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                        }
                    });
                });
            }
        };
        SaleStatus.prototype.renderEdit = function () {
            var _this = this;
            var vi = this.state.vehicleInput;
            var ss = _.find(this.state.sale_status_options, { id: vi.sale_status_id });
            var hasPrice = (ss != null && ss.price_name != null);
            return (React.createElement("form", { id: 'formSaleStatus', className: 'c-ss-edit mx-4 mx-lg-2', onSubmit: function (e) { return _this.submit(e); }, noValidate: true },
                React.createElement(__1.Select, { id: 'sale_status_id', label: 'Is Your Vehicle ...', size: 1, required: true, options: _.map(this.state.sale_status_options, function (item) { return React.createElement("option", { value: item.id.toString(), key: 'sale_status_' + item.id }, item.status); }), value: vi.sale_status_id != null ? vi.sale_status_id.toString() : '', onChange: function (e) { return _this.saleStatusChanged(e.target); } }),
                hasPrice &&
                    React.createElement(__1.MaskedText, { id: 'price', label: ss.price_name, required: ss.price_required, mask: lib_1.Mask.TypeEnum.Price, "data-err": this.state.price_error, "data-input-pre-addon": '$', value: vi.price ? vi.price.toString() : '', maxLength: 8, onChange: function (e) { return _this.priceChanged(e.target); } }),
                React.createElement("div", { className: 'text-right mt-3' },
                    React.createElement("button", { type: 'button', className: 'btn c-btn-2 c-btn-xs mr-2', onClick: function (e) { return _this.toggleEdit(); } }, "Cancel"),
                    React.createElement("button", { type: 'submit', className: 'btn c-btn-1 c-btn-xs' }, this.state.is_working ? React.createElement(__1.BtnWorking, { value: 'Saving ...' }) : 'Save'))));
        };
        SaleStatus.prototype.render = function () {
            var _this = this;
            try {
                var v_1 = this.props.vehicle;
                var my_user_id_1 = !_.isEmpty(lib_1.Global.store.getState().user) ? lib_1.Global.store.getState().user._id : null;
                var isMine = my_user_id_1 && this.props.vehicle.user_id == my_user_id_1;
                var isDisabledOrDeleted = (v_1.is_disabled || v_1.post_status_id == Cruz.Lookup.Other.PostStatusEnum.Delete);
                var vh = v_1.history;
                var uDate = null;
                if (vh) {
                    if (vh.sale_status && vh.sale_status.length > 0) {
                        var x = _.last(vh.sale_status);
                        if (x.value == v_1.sale_status_id) {
                            uDate = moment(x.date);
                        }
                    }
                    if (vh.price != null && vh.price.length > 0) {
                        var x = _.last(vh.price);
                        if (uDate && moment(x.date).isAfter(uDate) || !uDate) {
                            uDate = moment(x.date);
                        }
                    }
                }
                if (uDate == null) {
                    uDate = moment(v_1.date_update || v_1.date_create);
                }
                return (this.state.is_editing
                    ?
                        this.renderEdit()
                    :
                        React.createElement("div", { className: 'd-flex flex-column flex-sm-row justify-content-center align-items-center text-center' },
                            React.createElement("div", { className: classNames('c-ss', { 'c-ss-ae mr-sm-3 pr-sm-3': !isDisabledOrDeleted && !_.isEmpty(v_1.user_id) }) },
                                (v_1.sale_status_id == Cruz.Lookup.Vehicle.SaleStatusEnum.NotForSale ||
                                    v_1.sale_status_id == Cruz.Lookup.Vehicle.SaleStatusEnum.Sold ||
                                    v_1.sale_status_id == Cruz.Lookup.Vehicle.SaleStatusEnum.OpenToOffers) &&
                                    React.createElement("div", { className: classNames('c-ss_' + v_1.sale_status_id) },
                                        v_1.sale_status.status,
                                        (v_1.sale_status.price_required && v_1.price != null && v_1.price > 0) &&
                                            React.createElement("span", { className: 'ml-2' },
                                                React.createElement("sup", null, "$"),
                                                v_1.price.toLocaleString())),
                                (v_1.sale_status_id == Cruz.Lookup.Vehicle.SaleStatusEnum.ForSale) &&
                                    React.createElement("div", { className: classNames('d-flex align-items-center justify-content-center', { 'justify-content-sm-end': !isDisabledOrDeleted }, 'c-ss_' + v_1.sale_status_id) },
                                        React.createElement("span", null,
                                            v_1.sale_status.status,
                                            ":"),
                                        React.createElement("span", { className: 'ml-2 c-ss-price c-font-size-xxl c-font-weight-semi-bold' },
                                            React.createElement("sup", null, "$"),
                                            v_1.price.toLocaleString())),
                                uDate &&
                                    React.createElement("div", { className: 'c-font-size-xs' },
                                        React.createElement("i", { className: 'fal fa-clock mr-2' }),
                                        React.createElement("span", null, uDate.fromNow()))),
                            (!isDisabledOrDeleted && !_.isEmpty(v_1.user_id)) &&
                                React.createElement("div", { className: 'mt-3 mt-sm-0' }, isMine
                                    ?
                                        React.createElement("button", { type: 'button', className: 'btn c-btn-xs c-btn-1 c-btn-round', onClick: function () { return _this.toggleEdit(); } },
                                            React.createElement("i", { className: 'fal fa-pen' }),
                                            React.createElement("span", null, "Change"))
                                    :
                                        (v_1.user_id != null) &&
                                            React.createElement("button", { type: 'button', className: 'btn c-btn-xs c-btn-1 c-btn-round', onClick: function () { return (my_user_id_1 == null ? lib_1.util.setModal(null, false, false, React.createElement(__1.Modal.RequestAuth, null)) : lib_1.Chat.chatWithUser(v_1.user_display, lib_1.util.getRelativeUrl())); } },
                                                React.createElement("i", { className: 'fal fa-comments' }),
                                                React.createElement("span", null, (v_1.sale_status.id == Cruz.Lookup.Vehicle.SaleStatusEnum.OpenToOffers ||
                                                    v_1.sale_status_id == Cruz.Lookup.Vehicle.SaleStatusEnum.ForSale)
                                                    ?
                                                        'Make an Offer'
                                                    :
                                                        'Message the Owner')))));
            }
            catch (err) {
                return null;
            }
        };
        return SaleStatus;
    }(React.Component));
    Vehicle.SaleStatus = SaleStatus;
    var Mileage = (function (_super) {
        __extends(Mileage, _super);
        function Mileage(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                vehicle: _this.props.vehicle,
                mileageInput: _this.props.vehicle.mileage,
                confirmedMileage: _this.props.vehicle.mileage,
                is_editing: false,
                is_working: false,
                mileage_error: null
            };
            return _this;
        }
        Mileage.prototype.toggleEdit = function () {
            if (this.state.is_editing) {
                this.setState({
                    is_editing: false,
                    mileageInput: this.state.vehicle.mileage
                });
            }
            else {
                this.setState({
                    is_editing: true,
                });
            }
            lib_1.util.setFocus('#mileage');
        };
        Mileage.prototype.mileageChanged = function (tgt) {
            var _this = this;
            this.setState({ mileageInput: Number(tgt.value.replace(/[^0-9]/g, '')) }, function () {
                if (_this.state.mileage_error) {
                    _this.isValid();
                }
            });
        };
        Mileage.prototype.isValid = function () {
            if (this.state.mileageInput == null || this.state.mileageInput < 1) {
                this.setState({ mileage_error: 'Mileage is required' });
                return false;
            }
            else {
                this.setState({ mileage_error: null });
                return true;
            }
        };
        Mileage.prototype.submit = function (e) {
            var _this = this;
            e && e.preventDefault();
            if (this.isValid()) {
                this.setState({ is_working: true }, function () {
                    lib_1.Auth.isAuthed(function (isAuthed) {
                        if (isAuthed) {
                            request.put(lib_1.Global.cruzApiBaseUrl + '/vehicle/' + _this.state.vehicle._id + '/mileage')
                                .set(lib_1.Auth.authHeader())
                                .timeout(cfg.superagent.timeout.normal)
                                .send({ mileage: _this.state.mileageInput })
                                .end(function (err, apiRes) {
                                if (err) {
                                    _this.setState({ is_working: false });
                                }
                                else {
                                    _this.setState({
                                        is_working: false,
                                        is_editing: false,
                                        vehicle: _.assign({}, _this.state.vehicle, apiRes.body.data)
                                    }, function () {
                                        _this.props.onUpdateDone && _this.props.onUpdateDone(apiRes.body.data);
                                    });
                                }
                            });
                        }
                        else {
                            lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                        }
                    });
                });
            }
        };
        Mileage.prototype.validMileage = function (mileage) {
            var _this = this;
            var v = this.state.vehicle;
            if (mileage == this.state.confirmedMileage) {
                return true;
            }
            else if (v.year > 0 && mileage > 0 && mileage < 1000) {
                var age = moment().year() - v.year;
                var shouldConfirmMileage = ((age == 1 && mileage < 20) || (age > 1 && age <= 4 && mileage <= 100) || (age > 4));
                if (shouldConfirmMileage) {
                    lib_1.util.setModal(null, false, false, React.createElement(__1.Modal.MileageConfirm, { year: v.year, mileage: mileage, onYes: function () {
                            _this.setState({ mileageInput: mileage * 1000, confirmedMileage: mileage * 1000 });
                        }, onNo: function () {
                            _this.setState({ confirmedMileage: mileage });
                        } }));
                }
                return !shouldConfirmMileage;
            }
            return true;
        };
        Mileage.prototype.renderEdit = function () {
            var _this = this;
            return (React.createElement("form", { id: 'formMileage', className: 'c-ss-edit mx-4', onSubmit: function (e) { return _this.submit(e); }, noValidate: true },
                React.createElement(__1.MaskedText, { id: 'mileage', label: 'Mileage', mask: lib_1.Mask.TypeEnum.Number, "data-input-addon": 'miles', size: 1, required: true, "data-err": this.state.mileage_error, value: this.state.mileageInput != null ? this.state.mileageInput.toString() : '', maxLength: 9, onChange: function (e) { return _this.mileageChanged(e.target); }, onBlur: function (e) { return _this.validMileage(numeral(e.target.value).value()); } }),
                React.createElement("div", { className: 'text-right mt-2' },
                    React.createElement("button", { type: 'button', className: 'btn c-btn-2 c-btn-xs mr-2', onClick: function (e) { return _this.toggleEdit(); } }, "Cancel"),
                    React.createElement("button", { type: 'submit', className: 'btn c-btn-1 c-btn-xs' }, this.state.is_working ? React.createElement(__1.BtnWorking, { value: 'Saving ...' }) : 'Save'))));
        };
        Mileage.prototype.render = function () {
            var _this = this;
            try {
                var v = this.props.vehicle;
                var my_user_id = !_.isEmpty(lib_1.Global.store.getState().user) ? lib_1.Global.store.getState().user._id : null;
                var allowUpdate = (my_user_id && this.props.vehicle.user_id == my_user_id && v.post_status_id > 0 && v.sale_status_id > 0);
                var uDate = null;
                var vh = v.history;
                if (vh != null && vh.mileage != null && vh.mileage.length > 0) {
                    var x = _.last(vh.mileage);
                    if (x.value == v.mileage) {
                        uDate = moment(x.date);
                    }
                }
                if (uDate == null) {
                    uDate = moment(v.date_update || v.date_create);
                }
                return (this.state.is_editing
                    ?
                        this.renderEdit()
                    :
                        React.createElement("div", { className: classNames('d-flex justify-content-center align-items-center', { 'flex-column flex-sm-row ': allowUpdate }) },
                            React.createElement("div", { className: classNames('c-m text-center', { 'mr-sm-3 pr-sm-3 c-m-mine text-sm-right': allowUpdate }) },
                                React.createElement("div", { className: classNames('d-flex justify-content-center align-items-center', { 'justify-content-sm-end': allowUpdate }) },
                                    React.createElement("span", null, "Mileage:"),
                                    React.createElement("span", { className: 'ml-2 c-font-size-xxl c-font-weight-semi-bold' }, v.mileage.toLocaleString())),
                                (v.post_status_id > 0 && !v.is_disabled) &&
                                    mileageDescription(v),
                                React.createElement("div", { className: 'c-font-size-xs' },
                                    React.createElement("i", { className: 'fal fa-clock mr-2' }),
                                    React.createElement("span", { className: classNames({ 'c-m-warning': (allowUpdate && moment().diff(uDate, 'days') > 90) }) }, uDate.fromNow()))),
                            allowUpdate &&
                                React.createElement("button", { type: 'button', className: 'btn c-btn-xs c-btn-1 c-btn-round mt-3 mt-sm-0', onClick: function (e) { return _this.toggleEdit(); } },
                                    React.createElement("i", { className: 'fal fa-pen' }),
                                    React.createElement("span", null, "Update"))));
            }
            catch (err) {
                return null;
            }
        };
        return Mileage;
    }(React.Component));
    Vehicle.Mileage = Mileage;
    function mileageDescription(v) {
        try {
            var stat = v.statistics;
            if (v.make != '~' && v.model != '~' && v.mileage != null && v.mileage > 0 && !_.isEmpty(stat) && stat.count > 1 && stat.avg_mileage > 0) {
                if (v.mileage == stat.avg_mileage) {
                    return (React.createElement("div", { className: 'd-inline-block' }, "same as average"));
                }
                else {
                    var isAbove = (v.mileage > stat.avg_mileage);
                    return (React.createElement("div", { className: 'd-inline-block' },
                        React.createElement("i", { className: classNames('mr-2', 'c-stat-' + (isAbove ? 'above' : 'below')) }),
                        React.createElement("span", null, Math.round(Math.abs(v.mileage - stat.avg_mileage)).toLocaleString() + (isAbove ? ' above' : ' below') + ' average')));
                }
            }
            else {
                return null;
            }
        }
        catch (err) {
            return null;
        }
    }
    Vehicle.mileageDescription = mileageDescription;
    function renderOptionLabel(option) {
        try {
            var has_msrp = (option.option_msrp != null && option.option_msrp > 0);
            var has_invoice = (option.option_invoice != null && option.option_invoice > 0);
            return (React.createElement(React.Fragment, null,
                option.option_name,
                !_.isEmpty(option.option_code)
                    && " (" + option.option_code + ")",
                React.createElement("span", { className: 'd-block d-lg-none' }),
                (has_msrp || has_invoice) &&
                    React.createElement("span", { className: 'mx-2 d-none d-lg-inline-block' }, "..."),
                has_msrp &&
                    React.createElement(React.Fragment, null,
                        React.createElement("span", { className: 'c-font-size-xs mt-md-1' }, "MSRP: "),
                        React.createElement("sup", null, "$"),
                        option.option_msrp.toLocaleString()),
                (has_msrp && has_invoice) && ', ',
                has_invoice &&
                    React.createElement("span", null,
                        React.createElement("span", { className: 'c-font-size-xs' }, "Invoice: "),
                        React.createElement("sup", null, "$"),
                        option.option_invoice.toLocaleString()),
                !_.isEmpty(option.option_description) &&
                    React.createElement("div", { className: 'c-font-size-s mt-1 c-text-muted' }, option.option_description)));
        }
        catch (err) {
            return null;
        }
    }
    Vehicle.renderOptionLabel = renderOptionLabel;
    function renderVehicleInfo(icon, name, value, description) {
        try {
            if (value != null) {
                return (React.createElement("li", { className: 'c-veh-info-item' },
                    React.createElement("div", { className: 'item-icon' },
                        React.createElement("img", { className: 'img-fluid', src: '/img/vehicle/icon/' + icon + '.png' })),
                    React.createElement("div", { className: 'item-body' },
                        React.createElement("label", { className: 'item-name' }, name),
                        React.createElement("div", { className: 'item-value' }, value),
                        !_.isEmpty(description)
                            &&
                                React.createElement("div", { className: 'item-description' }, description))));
            }
            else {
                return null;
            }
        }
        catch (err) {
            return null;
        }
    }
    function renderMPG(mpg_list) {
        var show_fuel = _.size(mpg_list) > 1;
        try {
            return (_.map(mpg_list, function (mpg) {
                return (React.createElement("div", { key: 'mgp_' + mpg.veh_mpg_id },
                    mpg.city + ' ',
                    React.createElement("span", { className: 'item-description' }, "city | "),
                    mpg.highway + ' ',
                    React.createElement("span", { className: 'item-description' }, "highway"),
                    show_fuel &&
                        React.createElement("span", { className: 'item-description' }, " (" + ((' ' + _.isEmpty(mpg.fuel_grade) ? '' : ' ' + mpg.fuel_grade) + mpg.fuel_type) + ")")));
            }));
        }
        catch (err) {
            return null;
        }
    }
    function renderRange(max_range) {
        if (numeral(max_range).value() > 0) {
            return (React.createElement(React.Fragment, null,
                "Max range: ",
                React.createElement("span", { className: 'c-font-weight-semi-bold' }, Math.floor(max_range).toLocaleString()),
                " miles"));
        }
        else {
            return null;
        }
    }
    function renderEngine(d) {
        try {
            if (_.isEmpty(d)) {
                return null;
            }
            else {
                return (React.createElement("div", { className: 'c-veh-engine' },
                    React.createElement("div", { className: 'c-veh-info-item' },
                        React.createElement("div", { className: 'item-icon' },
                            React.createElement("img", { className: 'img-fluid', src: '/img/vehicle/icon/engine.png' })),
                        React.createElement("div", { className: 'item-body' },
                            React.createElement("label", { className: 'item-name' }, "Engine"),
                            React.createElement("div", { className: 'item-value' }, d.name))),
                    React.createElement("ul", { className: 'c-veh-engine-list pl-0' },
                        (!_.isEmpty(d.engine_brand)) &&
                            React.createElement("li", null,
                                React.createElement("span", { className: 'eng-name' }, "Brand:"),
                                React.createElement("span", { className: 'eng-value' }, d.engine_brand)),
                        (!_.isEmpty(d.manufacturer)) &&
                            React.createElement("li", null,
                                React.createElement("span", { className: 'eng-name' }, "Manufacturer:"),
                                React.createElement("span", { className: 'eng-value' }, d.manufacturer)),
                        (!_.isEmpty(d.model)) &&
                            React.createElement("li", null,
                                React.createElement("span", { className: 'eng-name' }, "Model:"),
                                React.createElement("span", { className: 'eng-value' }, d.model)),
                        (!_.isEmpty(d.code)) &&
                            React.createElement("li", null,
                                React.createElement("span", { className: 'eng-name' }, "Code:"),
                                React.createElement("span", { className: 'eng-value' }, d.code)),
                        (d.electric_max_kw != null && d.electric_max_kw > 0) &&
                            React.createElement("li", null,
                                React.createElement("span", { className: 'eng-name' }, "Electric Max Power Output:"),
                                React.createElement("span", { className: 'eng-value' },
                                    d.electric_max_kw.toLocaleString(),
                                    " kw")),
                        (d.electric_max_range != null && d.electric_max_range > 0) &&
                            React.createElement("li", null,
                                React.createElement("span", { className: 'eng-name' }, "Electric Max Range:"),
                                React.createElement("span", { className: 'eng-value' },
                                    d.electric_max_range.toLocaleString(),
                                    " miles")),
                        (!_.isEmpty(d.battery_capacity)) &&
                            React.createElement("li", null,
                                React.createElement("span", { className: 'eng-name' }, "Battery Capacity:"),
                                React.createElement("span", { className: 'eng-value' }, d.battery_capacity)),
                        (d.displacement != null && d.displacement > 0) &&
                            React.createElement("li", null,
                                React.createElement("span", { className: 'eng-name' }, "Displacement:"),
                                React.createElement("span", { className: 'eng-value' },
                                    d.displacement.toFixed(1),
                                    "L")),
                        !_.isEmpty(d.block_type) &&
                            React.createElement("li", null,
                                React.createElement("span", { className: 'eng-name' }, "Block Type:"),
                                React.createElement("span", { className: 'eng-value' }, d.block_type)),
                        (d.cylinders != null && d.cylinders > 0) &&
                            React.createElement("li", null,
                                React.createElement("span", { className: 'eng-name' }, "Cylinders:"),
                                React.createElement("span", { className: 'eng-value' }, d.cylinders)),
                        !_.isEmpty(d.aspiration_name) &&
                            React.createElement("li", null,
                                React.createElement("span", { className: 'eng-name' }, "Aspiration:"),
                                React.createElement("span", { className: 'eng-value' }, d.aspiration_name)),
                        !_.isEmpty(d.cam_type) &&
                            React.createElement("li", null,
                                React.createElement("span", { className: 'eng-name' }, "Cam Type:"),
                                React.createElement("span", { className: 'eng-value' }, d.cam_type)),
                        !_.isEmpty(d.valve_timing) &&
                            React.createElement("li", null,
                                React.createElement("span", { className: 'eng-name' }, "Valve Timing:"),
                                React.createElement("span", { className: 'eng-value' }, d.valve_timing)),
                        (d.valves != null && d.valves > 0) &&
                            React.createElement("li", null,
                                React.createElement("span", { className: 'eng-name' }, "Valves:"),
                                React.createElement("span", { className: 'eng-value' }, d.valves)),
                        (!_.isEmpty(d.fuel_induction) || !_.isEmpty(d.fuel_induction_name)) &&
                            React.createElement("li", null,
                                React.createElement("span", { className: 'eng-name' }, "Fuel Induction:"),
                                React.createElement("span", { className: 'eng-value', title: d.fuel_induction_name }, d.fuel_induction_name ? d.fuel_induction_name.replace('Fuel Injection', '').trim() : d.fuel_induction)),
                        (d.max_hp != null && d.max_hp > 0) &&
                            React.createElement("li", null,
                                React.createElement("span", { className: 'eng-name' }, "Max Horsepower:"),
                                React.createElement("span", { className: 'eng-value' }, d.max_hp.toLocaleString() + ' hp' + ((d.max_hp_at != null && d.max_hp_at > 0) ? ' @ ' + d.max_hp_at.toLocaleString() + ' rpm' : ''))),
                        (d.max_torque != null && d.max_torque > 0) &&
                            React.createElement("li", null,
                                React.createElement("span", { className: 'eng-name' }, "Max Torque:"),
                                React.createElement("span", { className: 'eng-value' }, d.max_torque.toLocaleString() + ' ft.lbf' + ((d.max_torque_at != null && d.max_torque_at > 0) ? ' @ ' + d.max_torque_at.toLocaleString() + ' rpm' : ''))),
                        (d.redline != null && d.redline > 0) &&
                            React.createElement("li", null,
                                React.createElement("span", { className: 'eng-name' }, "Redline:"),
                                React.createElement("span", { className: 'eng-value' },
                                    d.redline.toLocaleString(),
                                    " rpm")),
                        (d.compression != null && d.compression > 0) &&
                            React.createElement("li", null,
                                React.createElement("span", { className: 'eng-name' }, "Compression:"),
                                React.createElement("span", { className: 'eng-value' }, d.compression)),
                        (d.bore != null && d.bore > 0) &&
                            React.createElement("li", null,
                                React.createElement("span", { className: 'eng-name' }, "Bore:"),
                                React.createElement("span", { className: 'eng-value' },
                                    d.bore,
                                    " in.")),
                        (d.stroke != null && d.stroke > 0) &&
                            React.createElement("li", null,
                                React.createElement("span", { className: 'eng-name' }, "Stroke:"),
                                React.createElement("span", { className: 'eng-value' },
                                    d.stroke,
                                    " in.")),
                        (!_.isEmpty(d.fuel_type_name)) &&
                            React.createElement("li", null,
                                React.createElement("span", { className: 'eng-name' }, "Fuel Type:"),
                                React.createElement("span", { className: 'eng-value' }, d.fuel_type_name)),
                        (d.fuel_quality != null && d.fuel_quality > 0) &&
                            React.createElement("li", null,
                                React.createElement("span", { className: 'eng-name' }, "Fuel Quality:"),
                                React.createElement("span", { className: 'eng-value' }, d.fuel_quality.toString())),
                        (d.oil_capacity != null && d.oil_capacity > 0) &&
                            React.createElement("li", null,
                                React.createElement("span", { className: 'eng-name' }, "Oil Capacity:"),
                                React.createElement("span", { className: 'eng-value' },
                                    d.oil_capacity,
                                    "L")))));
            }
        }
        catch (err) {
            return null;
        }
    }
    function description(d) {
        try {
            if (d != null && d.trim() != '') {
                return React.createElement(VehicleSection, { title: 'Description', content: lib_1.util.text2html(lib_1.util.stripHtmlTags(d, cfg.allowHtmlTags)) });
            }
            else {
                return null;
            }
        }
        catch (err) {
            return null;
        }
    }
    Vehicle.description = description;
    function warranty(warranty, mfr_warranty_list, condition) {
        try {
            var has_warranty = !_.isEmpty(warranty);
            var has_mfr_warranty = !_.isEmpty(mfr_warranty_list);
            if (_.isEmpty(warranty) && _.isEmpty(mfr_warranty_list)) {
                return null;
            }
            else {
                return (React.createElement(VehicleSection, { title: 'Warranty', content: React.createElement(React.Fragment, null,
                        has_warranty && lib_1.util.text2html(lib_1.util.stripHtmlTags(warranty, cfg.allowHtmlTags)),
                        has_mfr_warranty &&
                            React.createElement("h3", { className: classNames('c-veh-sub-category-title pb-1', { 'pt-3': has_warranty }) }, (condition == Cruz.Lookup.Vehicle.ConditionEnum.New || condition == Cruz.Lookup.Vehicle.ConditionEnum.Certified)
                                ?
                                    'Factory Warranty'
                                :
                                    'Possible Remaining Factory Warranty'),
                        has_mfr_warranty &&
                            React.createElement("table", { className: 'table table-sm mb-1 mb-0 w-auto' },
                                React.createElement("tbody", null, _.map(mfr_warranty_list, function (d, idx) {
                                    var y = d.warranty_months / 12;
                                    return (React.createElement("tr", { key: idx },
                                        React.createElement("td", { className: 'pr-3 py-2' }, d.is_expired ? React.createElement("del", null, d.warranty_name) : d.warranty_name),
                                        React.createElement("td", { className: 'py-2 text-nowrap' },
                                            d.warranty_months > 0 && y == Math.round(y) ? y + ' years/' : d.warranty_months + 'months/',
                                            d.warranty_miles > 0 ? d.warranty_miles.toLocaleString() + ' miles' : 'unlimited miles')));
                                })))) }));
            }
        }
        catch (err) {
            lib_1.util.logError(err, null);
            return null;
        }
    }
    Vehicle.warranty = warranty;
    function renderMfrPrice(d) {
        var oMsrp = 0;
        var has_msrp = (d.msrp != null && d.msrp > 0);
        if (has_msrp && _.size(d.option_list) > 0) {
            oMsrp = _.map(d.option_list, 'option_msrp').reduce(function (t, msrp) { return t + msrp; }, 0);
        }
        var has_invoice = (!_.isEmpty(d.mfr_price) && d.mfr_price.invoice != null && d.mfr_price.invoice > 0);
        var oInvoice = 0;
        if (has_invoice && _.size(d.option_list) > 0) {
            oInvoice = _.map(d.option_list, 'option_invoice').reduce(function (t, invoice) { return t + invoice; }, 0);
        }
        var show_dest = (d.condition == Cruz.Lookup.Vehicle.ConditionEnum.New && !_.isEmpty(d.mfr_price) && d.mfr_price.dest != null && d.mfr_price.dest > 0);
        if (has_msrp) {
            return (React.createElement("li", { className: 'c-veh-info-item' },
                React.createElement("div", { className: 'item-icon' },
                    React.createElement("img", { className: 'img-fluid', src: '/img/vehicle/icon/msrp.png' })),
                React.createElement("div", { className: 'item-body' },
                    React.createElement("div", { className: 'd-flex' },
                        has_msrp &&
                            React.createElement("div", null,
                                React.createElement("label", { className: 'item-name' }, "MSRP:"),
                                React.createElement("div", { className: 'item-value' },
                                    React.createElement("sup", null, "$"),
                                    (d.msrp + oMsrp).toLocaleString())),
                        has_msrp && has_invoice && React.createElement("div", { className: 'ml-3 border-left pr-3' }),
                        has_invoice &&
                            React.createElement("div", null,
                                React.createElement("label", { className: 'item-name' }, "Invoice:"),
                                React.createElement("div", { className: 'item-value' },
                                    React.createElement("sup", null, "$"),
                                    (d.mfr_price.invoice + oInvoice).toLocaleString())),
                        show_dest &&
                            (has_msrp || has_invoice) && React.createElement("div", { className: 'ml-3 border-left pr-3' }),
                        show_dest &&
                            React.createElement("div", null,
                                React.createElement("label", { className: 'item-name' }, "Destination:"),
                                React.createElement("div", { className: 'item-value' },
                                    React.createElement("sup", null, "$"),
                                    (d.mfr_price.dest).toLocaleString()))),
                    (oMsrp > 0 && oInvoice > 0) &&
                        React.createElement("div", { className: 'item-description' },
                            React.createElement("a", { href: '#veh-options', className: 'c-btn-link' },
                                "Includes ",
                                React.createElement("sup", null, "$"),
                                oMsrp.toLocaleString(),
                                " (",
                                React.createElement("span", null,
                                    "Invoice ",
                                    React.createElement("sup", null, "$"),
                                    oInvoice.toLocaleString()),
                                ") options")))));
        }
        else {
            return null;
        }
    }
    var Generation = function (_a) {
        var d = _a.d;
        try {
            var currentGen = _.find(d.generation_list, { name: d.generation });
            var _b = react_1.useState(null), showGenInfo_1 = _b[0], setShowGenInfo_1 = _b[1];
            var GenInfoModal_1 = function () {
                react_1.useEffect(function () {
                    $('#_genInfo').modal('show');
                }, []);
                function close() {
                    $('#_genInfo').modal('hide');
                    setShowGenInfo_1(false);
                }
                return (React.createElement("div", { className: 'modal', id: '_genInfo', tabIndex: -1, role: 'dialog' },
                    React.createElement("div", { className: 'modal-dialog modal-lg', role: 'document' },
                        React.createElement("div", { className: 'modal-content' },
                            React.createElement("div", { className: 'modal-header' },
                                React.createElement("h2", { className: 'modal-title' },
                                    "About ",
                                    d.make,
                                    " ",
                                    d.model,
                                    " ",
                                    d.generation,
                                    React.createElement("sup", { className: 'font-weight-normal c-font-size-xs' }, Cruz.Util.getOrdinal(d.generation)),
                                    " Generation"),
                                React.createElement("button", { type: 'button', className: 'close', onClick: close })),
                            React.createElement("div", { className: 'modal-body px-4' }, _.map(d.generation_overview_list, function (gen, idx) { return (React.createElement("div", { key: 'gen_' + idx, className: 'py-3' },
                                React.createElement("h3", null, gen.year),
                                lib_1.util.text2html(gen.overview))); })),
                            React.createElement("div", { className: 'modal-footer' },
                                React.createElement("button", { type: 'button', className: 'btn c-btn-xs c-btn-2', onClick: close }, "Close"))))));
            };
            react_1.useEffect(function () {
                if (showGenInfo_1) {
                    lib_1.util.setModal(null, false, false, React.createElement(GenInfoModal_1, null));
                }
            }, [showGenInfo_1]);
            react_1.useEffect(function () {
                return function () {
                    $('#_genInfo').modal('dispose');
                };
            }, []);
            if (!_.isNil(d.generation) && d.generation > 0) {
                return (renderVehicleInfo('generation', 'Generation', React.createElement("div", { className: 'd-flex align-items-center' },
                    d.generation,
                    React.createElement("sup", { className: 'font-weight-normal c-font-size-xs' }, Cruz.Util.getOrdinal(d.generation)),
                    (!_.isEmpty(currentGen)) &&
                        React.createElement("div", { className: 'ml-1 c-font-size-s font-weight-normal' },
                            "(",
                            currentGen.from,
                            " - ",
                            currentGen.to || 'present',
                            ")")), _.size(d.generation_overview_list) > 0 &&
                    React.createElement("button", { type: 'button', className: 'c-btn-link c-font-size-s', onClick: function () { return setShowGenInfo_1(true); } }, "About this generation")));
            }
            else {
                return null;
            }
        }
        catch (err) {
            console.log(err);
            return null;
        }
    };
    function info(d) {
        try {
            var is_vendor = !_.isEmpty(d.vendor_display);
            var condition_name = null;
            if (lib_1.util.isNumeric(d.condition)) {
                var x = _.find(lib_1.Lookup.condition, { id: d.condition });
                if (x != null && !_.isEmpty(x.name)) {
                    condition_name = x.name;
                }
            }
            var is_electric = (!_.isEmpty(d.engine) && ['L', 'H'].includes(d.engine.fuel_type));
            return (React.createElement("section", { className: 'c-veh-section mb-3' },
                React.createElement("ul", { className: 'c-veh-info-list pl-0 py-2' },
                    !_.isEmpty(condition_name) &&
                        renderVehicleInfo('condition', 'Condition', condition_name),
                    (is_vendor == true && !_.isEmpty(d.stock_number)) &&
                        renderVehicleInfo('stock_number', 'Stock Number', d.stock_number),
                    renderVehicleInfo('vin', 'VIN', d.vin || '---'),
                    renderMfrPrice(d),
                    (_.size(d.category_list) > 0) &&
                        renderVehicleInfo('vehicle_type', 'Vehicle Type', _.compact(d.category_list).join(', ')),
                    (!_.isEmpty(d.size_segment) && !d.is_hd) &&
                        renderVehicleInfo('size', 'Size', d.size_segment),
                    React.createElement(Generation, { d: d }),
                    (numeral(d.doors).value() >= 0) &&
                        renderVehicleInfo('doors', 'Doors', d.doors),
                    (!_.isEmpty(d.specification) && d.specification.max_seating != null && d.specification.max_seating > 0) &&
                        renderVehicleInfo('max_seating', 'Max Seating', d.specification.max_seating),
                    (!_.isEmpty(d.transmission) && !_.isEmpty(d.transmission.name)) &&
                        renderVehicleInfo('transmission', 'Transmission', d.transmission.name, !_.isEmpty(d.transmission.trans_brand) ? 'Brand: ' + d.transmission.trans_brand : ''),
                    (d.drive_type || d.drive_type_name) &&
                        renderVehicleInfo('drive_type', 'Drivetrain', (d.drive_type_name || d.drive_type)),
                    (!_.isEmpty(d.specification) && numeral(d.specification.acceleration).value() > 0) &&
                        renderVehicleInfo(is_electric ? 'acceleration_electric' : 'acceleration', 'Acceleration (0-60 mph)', d.specification.acceleration + ' second'),
                    (!_.isEmpty(d.mpg_list)) &&
                        renderVehicleInfo('mpg', (is_electric ? 'MPGe' : 'MPG'), renderMPG(d.mpg_list), renderRange(d.max_range)),
                    (numeral(d.safety_ratings).value() > 0) &&
                        renderVehicleInfo('crash_rating', 'NHTSA Crash Test Ratings', renderCrashTestRating(d.safety_ratings)),
                    !_.isEmpty(d.exterior_color) &&
                        renderVehicleInfo('exterior_color', 'Exterior Color', (d.exterior_color.mfr_color_name
                            ?
                                d.exterior_color.mfr_color_name + (d.exterior_color.color_name != d.exterior_color.mfr_color_name ? ' (' + d.exterior_color.color_name + ')' : '')
                            :
                                (d.exterior_color.color_name || d.exterior_color.color_group)), d.exterior_color.mfr_color_code
                            &&
                                React.createElement("div", null,
                                    "Factory Paint Code: ",
                                    React.createElement("span", { className: 'c-font-weight-semi-bold' }, d.exterior_color.mfr_color_code))),
                    !_.isEmpty(d.interior_color) &&
                        renderVehicleInfo('interior_color', 'Interior Color', (d.interior_color.mfr_color_name
                            ?
                                d.interior_color.mfr_color_name + (d.interior_color.color_name != d.interior_color.mfr_color_name ? ' (' + d.interior_color.color_name + ')' : '')
                            :
                                d.interior_color.color_name || d.interior_color.color_group)),
                    !_.isEmpty(d.roof_color) &&
                        renderVehicleInfo('roof_color', 'Roof Color', (d.roof_color.mfr_color_name ? d.roof_color.mfr_color_name : d.roof_color.color_name)),
                    !_.isEmpty(d.plant) &&
                        renderVehicleInfo('plant', 'Final Assembly Point', d.plant)),
                (!_.isEmpty(d.engine) && !_.isEmpty(_.get(d.engine, 'name'))) &&
                    renderEngine(d.engine)));
        }
        catch (err) {
            return null;
        }
    }
    Vehicle.info = info;
    function tags(d) {
        try {
            if (_.isEmpty(d)) {
                return null;
            }
            else {
                return (React.createElement(VehicleSection, { title: 'Highlights', count: _.size(d), content: React.createElement("ul", { className: 'c-veh-tag-list p-0' }, _.map(_.sortBy(d), function (tag) {
                        return (React.createElement("li", { key: 'tag_' + tag }, tag));
                    })) }));
            }
        }
        catch (err) {
            lib_1.util.logError(err, null);
            return null;
        }
    }
    Vehicle.tags = tags;
    function specification(d, is_hd) {
        try {
            if (!_.isEmpty(_.pickBy(d, _.identity))) {
                if (is_hd) {
                    var lkp_1 = lib_1.Lookup.hd_spec_items;
                    return (React.createElement(VehicleSection, { title: 'Specifications', content: React.createElement("ul", { className: 'c-hd-spec-list pl-0 pl-sm-3 pl-md-4' }, _.map(_.keys(lkp_1), function (key, idx) {
                            var item = lkp_1[key];
                            var val = d[key];
                            if (!_.isEmpty(val)) {
                                return (React.createElement("li", { key: idx },
                                    React.createElement("span", null,
                                        item.name,
                                        ":"),
                                    React.createElement("span", { className: 'c-item-value' }, (val > 0 ? val.toLocaleString() : val)),
                                    item.unit &&
                                        React.createElement("span", { className: 'c-item-unit' }, item.unit)));
                            }
                        })), isCollapsed: false }));
                }
                else {
                    var lkp_2 = lib_1.Lookup.vehicle_spec_items;
                    var specKeys_1 = _.keys(_.pickBy(d, _.identity));
                    return (React.createElement(VehicleSection, { title: 'Standard Specifications', content: _.map(_.keys(lkp_2), function (mainKey, idx) {
                            var cKeys = _.keys(lkp_2[mainKey]);
                            if (_.intersection(specKeys_1, cKeys).length > 0) {
                                return (React.createElement("div", { key: idx },
                                    React.createElement("h3", { className: 'c-veh-sub-category-title' },
                                        mainKey,
                                        ":"),
                                    React.createElement("ul", { className: 'c-veh-spec-list pl-0 pl-sm-3 pl-md-4' }, _.map(cKeys, function (key, sIdx) {
                                        var val = d[key];
                                        if (val != null && (val > 0 || val != '')) {
                                            var si = lkp_2[mainKey][key];
                                            return (React.createElement("li", { key: sIdx },
                                                React.createElement("span", null,
                                                    si.name,
                                                    ":"),
                                                React.createElement("span", { className: 'c-item-value' }, (val > 0 ? val.toLocaleString() : val)),
                                                lib_1.Lookup.units[si.unit] &&
                                                    React.createElement("span", { className: 'c-item-unit' }, lib_1.Lookup.units[si.unit])));
                                        }
                                    }))));
                            }
                        }), isCollapsed: false }));
                }
            }
            else {
                return null;
            }
        }
        catch (err) {
            lib_1.util.logError(err, null);
            return null;
        }
    }
    Vehicle.specification = specification;
    function award(award_list) {
        try {
            if (_.isEmpty(award_list)) {
                return null;
            }
            else {
                var c = _.size(award_list);
                return (React.createElement(VehicleSection, { title: 'Award' + (c > 1 ? 's' : ''), count: c, content: React.createElement("ul", { className: 'c-veh-award-list pl-0' }, _.map(award_list, function (award, idx) {
                        return (React.createElement("li", { key: idx },
                            React.createElement("div", null,
                                React.createElement("span", { className: 'c-font-weight-medium' }, award.award_name),
                                React.createElement("div", { className: 'mt-1' },
                                    React.createElement("span", { className: 'mr-2' }, "-"),
                                    (!_.isEmpty(award.source_citation) && new uri(award.source_citation).is('name') == true)
                                        ?
                                            React.createElement("a", { href: award.source_citation, target: '_blank', className: 'c-btn-link' }, award.source_name)
                                        :
                                            award.source_name))));
                    })), isCollapsed: true }));
            }
        }
        catch (err) {
            lib_1.util.logError(err, null);
            return null;
        }
    }
    Vehicle.award = award;
    function safetyEquipment(d) {
        try {
            if (_.isEmpty(d)) {
                return null;
            }
            else {
                return (React.createElement(VehicleSection, { title: 'Safety Equipment', count: _.size(d), content: React.createElement("ul", { className: 'c-veh-safety-equipment-list pl-0' }, _.map(_.keys(d), function (x, idx) {
                        return (React.createElement("li", { key: 'se_' + idx },
                            _.startCase(_.replace(x, 'abs', 'ABS')),
                            (d[x] == 'O') &&
                                ' (optional)'));
                    })), isCollapsed: true }));
            }
        }
        catch (err) {
            lib_1.util.logError(err, null);
            return null;
        }
    }
    Vehicle.safetyEquipment = safetyEquipment;
    function crashTest(d) {
        try {
            if (_.isEmpty(_.pickBy(_.omit(d, 'crash_test_id', 'nhtsa_vehicle_tested'), _.identity))) {
                return null;
            }
            else {
                var dx = _.omit(_.omitBy(d, function (x) { return x == 'N/A'; }), ['crash_test_id', 'nhtsa_vehicle_tested']);
                return (React.createElement(VehicleSection, { id: 'crash_test', title: 'NHTSA Crash Test Ratings', content: _.isEmpty(dx)
                        ?
                            React.createElement("div", null, "No data available yet, please check back later.")
                        :
                            React.createElement("ul", { className: 'c-veh-crash-test-list pl-0' },
                                renderCrashTestItem('Overall', d.overall_stars),
                                renderCrashTestItem('Overall Front Crash', d.front_crash_overall_stars),
                                renderCrashTestItem('Front Crash (Driver)', d.front_crash_driver_stars),
                                renderCrashTestItem('Front Crash (Passenger)', d.front_crash_passenger_stars),
                                renderCrashTestItem('Overall Side Crash', d.side_crash_overall_stars),
                                renderCrashTestItem('Side Crash Combined Front', d.side_combined_front_stars),
                                renderCrashTestItem('Side Crash Combined Rear', d.side_combined_rear_stars),
                                renderCrashTestItem('Overall Side Barrier Crash', d.side_barrier_overall_stars),
                                renderCrashTestItem('Side Barrier Crash (Driver)', d.side_barrier_driver_stars),
                                renderCrashTestItem('Side Barrier Crash (Passenger)', d.side_barrier_passenger_stars),
                                renderCrashTestItem('Side Pole Crash (Driver)', d.side_pole_driver_stars),
                                renderCrashTestItem('Rollover', d.rollover_stars)), isCollapsed: false }));
            }
        }
        catch (err) {
            lib_1.util.logError(err, null);
            return null;
        }
    }
    Vehicle.crashTest = crashTest;
    function crashTestPre2011(d) {
        try {
            if (_.isEmpty(d)) {
                return null;
            }
            else {
                return (React.createElement(VehicleSection, { id: 'crash_test', title: 'NHTSA Crash Test Ratings', content: React.createElement(React.Fragment, null,
                        React.createElement("ul", { className: 'c-veh-crash-test-list pl-0' },
                            renderCrashTestItem('Front Crash (Driver)', d.front_driver_stars),
                            renderCrashTestItem('Front Crash (Passenger)', d.front_passenger_stars),
                            renderCrashTestItem('Side Crash (Driver)', d.side_driver_stars),
                            renderCrashTestItem('Side Crash (Passenger)', d.side_rear_passenger_stars),
                            Math.max(d.rollover_2wd_stars, d.rollover_4wd_stars) > 0 &&
                                renderCrashTestItem('Rollover', Math.max(d.rollover_2wd_stars, d.rollover_4wd_stars))),
                        !_.isEmpty(d.nhtsa_vehicle_tested) &&
                            React.createElement(React.Fragment, null,
                                React.createElement("div", { className: 'mt-3 c-font-size-xs' }, "NTHSA Vehicle Tested:"),
                                React.createElement("div", null, d.nhtsa_vehicle_tested))), isCollapsed: false }));
            }
        }
        catch (err) {
            lib_1.util.logError(err, null);
            return null;
        }
    }
    Vehicle.crashTestPre2011 = crashTestPre2011;
    function renderCrashTestRating(rating) {
        try {
            return (React.createElement("a", { href: '#crash_test' },
                _.map(_.range(rating), function (n, idx) {
                    return React.createElement("i", { key: 'ct_' + idx, className: 'fas fa-star c-veh-rating-star' });
                }),
                _.map(_.range(5 - rating), function (n, idx) {
                    return React.createElement("i", { key: 'ct_' + idx, className: 'far fa-star c-veh-rating-star' });
                })));
        }
        catch (err) {
            lib_1.util.logError(err, null);
            return null;
        }
    }
    function renderCrashTestItem(name, rating) {
        try {
            if (lib_1.util.isNumeric(rating)) {
                return (React.createElement("li", null,
                    React.createElement("div", null, name),
                    renderCrashTestRating(rating)));
            }
            else {
                return null;
            }
        }
        catch (err) {
            lib_1.util.logError(err, null);
            return null;
        }
    }
    function installedOptionalFeatures(d) {
        try {
            if (_.isEmpty(d)) {
                return null;
            }
            else {
                return (React.createElement(VehicleSection, { id: 'optional_features', title: 'Installed Optional Features', count: _.size(d), content: React.createElement("ul", { className: 'c-veh-option-list pl-0' }, _.map(d, function (option) {
                        var has_msrp = (option.option_msrp != null && option.option_msrp > 0);
                        var has_invoice = (option.option_invoice != null && option.option_invoice > 0);
                        return (React.createElement("li", { key: 'o_' + option.option_id },
                            React.createElement("h4", { className: 'option-name' },
                                option.option_name,
                                option.option_code != null && option.option_code.trim() != '' &&
                                    React.createElement("span", { className: 'option-code' },
                                        "(Factory Code: ",
                                        option.option_code,
                                        ")"),
                                (has_invoice || has_msrp) && React.createElement("span", { className: 'mx-1' }, "..."),
                                has_msrp &&
                                    React.createElement("span", { className: 'option-price' },
                                        React.createElement("span", { className: 'name' }, "MSRP:"),
                                        React.createElement("span", { className: 'price ml-1' },
                                            React.createElement("sup", null, "$"),
                                            option.option_msrp.toLocaleString())),
                                (has_msrp && has_invoice) && React.createElement("span", null, ", "),
                                has_invoice &&
                                    React.createElement("span", { className: 'option-price' },
                                        React.createElement("span", { className: 'name' }, "Invoice:"),
                                        React.createElement("span", { className: 'price ml-1' },
                                            React.createElement("sup", null, "$"),
                                            option.option_invoice.toLocaleString()))),
                            (option.option_description && option.option_description.trim() != '') &&
                                React.createElement("div", { className: 'option-description' }, option.option_description)));
                    })) }));
            }
        }
        catch (err) {
            lib_1.util.logError(err, null);
            return null;
        }
    }
    Vehicle.installedOptionalFeatures = installedOptionalFeatures;
    function features(d) {
        try {
            if (_.isEmpty(d)) {
                return null;
            }
            else {
                return (React.createElement(VehicleSection, { id: 'features', title: 'Standard Installed Equipment', count: _.flatten(_.map(d, 'feature_list')).length, content: _.map(d, function (cat) {
                        return (React.createElement("div", { key: cat.category_id },
                            React.createElement("h3", { className: 'c-veh-sub-category-title' }, cat.category_name),
                            React.createElement("ul", { className: 'c-veh-feature-list pl-0 pl-sm-3 pl-md-4' }, _.map(cat.feature_list, function (f) {
                                return (React.createElement("li", { key: cat.category_id + '_' + f.feature_id }, f.feature));
                            }))));
                    }), isCollapsed: true }));
            }
        }
        catch (err) {
            lib_1.util.logError(err, null);
            return null;
        }
    }
    Vehicle.features = features;
    function greenScores(d) {
        try {
            if (_.isEmpty(d)) {
                return null;
            }
            else {
                return (React.createElement(VehicleSection, { id: 'epa_green_scores', title: 'EPA Green Scores', content: React.createElement("div", { className: 'c-veh-greenscore' }, _.map(d, function (gs, idx) {
                        return (React.createElement("div", { key: 'gs_' + idx, className: classNames(idx > 0 ? 'mt-3' : '') },
                            React.createElement("h3", { className: 'c-veh-sub-category-title' },
                                gs.emissions_standard,
                                ":"),
                            React.createElement("ul", { className: 'c-veh-gs-list pl-0 pl-sm-3 pl-md-4' },
                                (gs.smartway != null && gs.smartway.toLowerCase() == 'y') && React.createElement("li", null,
                                    React.createElement("span", null, "SmartWay Certified")),
                                (gs.smartway != null && gs.smartway.toLowerCase() == 'elite') && React.createElement("li", null,
                                    React.createElement("span", null, "SmartWay Elite Certified")),
                                gs.air_pollution_score != null && React.createElement("li", null,
                                    React.createElement("span", null, "Air Pollution Score:"),
                                    React.createElement("span", { className: 'c-font-weight-medium ml-1' }, gs.air_pollution_score)),
                                gs.greenhouse_gas_score != null && React.createElement("li", null,
                                    React.createElement("span", null, "Greenhouse Gas Score:"),
                                    React.createElement("span", { className: 'c-font-weight-medium ml-1' }, gs.greenhouse_gas_score)),
                                gs.fuel_type != null && React.createElement("li", null,
                                    React.createElement("span", null, "Fuel type:"),
                                    React.createElement("span", { className: 'c-font-weight-medium ml-1' }, gs.fuel_type)),
                                gs.epa_underhood_id != null && React.createElement("li", null,
                                    React.createElement("span", null, "EPA ID:"),
                                    React.createElement("span", { className: 'c-font-weight-medium ml-1' }, gs.epa_underhood_id)))));
                    })), isCollapsed: true }));
            }
        }
        catch (err) {
            lib_1.util.logError(err, null);
            return null;
        }
    }
    Vehicle.greenScores = greenScores;
    function overview(title, overview) {
        try {
            if (_.isEmpty(overview)) {
                return null;
            }
            else {
                return (React.createElement(VehicleSection, { id: 'overview', title: 'About ' + title, content: _.map(overview.split('\n'), function (line, i) {
                        return React.createElement("div", { key: 'overview_' + i, dangerouslySetInnerHTML: { __html: new showdown.Converter().makeHtml(line) } });
                    }), isCollapsed: true }));
            }
        }
        catch (err) {
            lib_1.util.logError(err, null);
            return null;
        }
    }
    Vehicle.overview = overview;
    var VehicleSection = (function (_super) {
        __extends(VehicleSection, _super);
        function VehicleSection(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                isCollapsed: _this.props.isCollapsed
            };
            return _this;
        }
        VehicleSection.prototype.toggle = function () {
            this.setState({ isCollapsed: !this.state.isCollapsed });
        };
        VehicleSection.prototype.render = function () {
            var _this = this;
            try {
                return (React.createElement("section", { className: 'c-veh-section', id: this.props.id },
                    React.createElement("div", { className: 'd-flex justify-content-between align-items-center c-veh-section-bar', onClick: function () { return _this.toggle(); } },
                        React.createElement("h2", { className: 'c-veh-sub-title ml-3' },
                            this.props.title,
                            (this.props.count != null && this.props.count > 0) &&
                                React.createElement("span", { className: 'ml-2 c-font-size-xs' },
                                    "(",
                                    this.props.count.toLocaleString(),
                                    ")")),
                        React.createElement("i", { className: classNames('fa c-font-size-lg c-font-color-1 mr-3', this.state.isCollapsed ? 'fa-plus' : 'fa-minus') })),
                    React.createElement("div", { className: classNames('ml-3 pt-3 pb-2', this.state.isCollapsed ? 'd-none' : 'd-block') }, this.props.content)));
            }
            catch (err) {
                lib_1.util.logError(err, null);
                return null;
            }
        };
        return VehicleSection;
    }(React.Component));
    Vehicle.VehicleSection = VehicleSection;
    var SimilarVehicles = (function (_super) {
        __extends(SimilarVehicles, _super);
        function SimilarVehicles(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                query: _this.props.query,
                result: {
                    links: {},
                    meta: {},
                    data: []
                }
            };
            return _this;
        }
        SimilarVehicles.prototype.searchVehicle = function (query) {
            var _this = this;
            var apiUrl = lib_1.Global.cruzApiBaseUrl + '/vehicle' + lib_1.util.buildApiQueryString(query);
            request.get(apiUrl).timeout(cfg.superagent.timeout.normal).retry(2).end(function (err, apiRes) {
                _this.setState({
                    result: apiRes.body,
                    query: query
                });
            });
        };
        SimilarVehicles.prototype.componentWillReceiveProps = function (nextProps) {
            var _this = this;
            if (!_.isEqual(nextProps.query, this.state.query)) {
                this.setState({ query: nextProps.query }, function () {
                    _this.searchVehicle(nextProps.query);
                });
            }
        };
        SimilarVehicles.prototype.componentDidMount = function () {
            this.searchVehicle(this.state.query);
        };
        SimilarVehicles.prototype.componentWillUnmount = function () {
        };
        SimilarVehicles.prototype.renderSearchLink = function () {
            try {
                var xName = _.values(_.pick(this.state.query, ['year', 'make', 'model'])).join(' ').trim();
                return (React.createElement("div", { className: 'text-center' },
                    React.createElement(react_router_dom_1.Link, { to: lib_1.util.buildUrl('/vehicles', this.state.query), className: 'c-btn-link' },
                        React.createElement("i", { className: 'fas fa-search' }),
                        React.createElement("span", null,
                            "Search ",
                            xName != '' ? xName : 'vehicles'))));
            }
            catch (err) {
                lib_1.util.logError(err, null);
                return null;
            }
        };
        SimilarVehicles.prototype.render = function () {
            var _this = this;
            try {
                if (this.state.result.meta.total_records_matched > 0) {
                    return (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: 'mb-3' }, this.renderSearchLink()),
                        React.createElement("div", { className: 'row c-sm-gutters' }, _.map(this.state.result.data, function (v) {
                            return (React.createElement("div", { className: _this.props.className, key: v._id },
                                React.createElement(__1.VehicleCard.Component, __assign({}, {
                                    vehicle: v,
                                    showUserInfo: false,
                                    showEdit: false,
                                    onPostStatusUpdateDone: null,
                                    shouldRender: true
                                }))));
                        })),
                        this.renderSearchLink()));
                }
                else {
                    return null;
                }
            }
            catch (err) {
                lib_1.util.logError(err, null);
                return null;
            }
        };
        return SimilarVehicles;
    }(React.Component));
    Vehicle.SimilarVehicles = SimilarVehicles;
})(Vehicle = exports.Vehicle || (exports.Vehicle = {}));
