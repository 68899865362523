"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var _ = require("lodash");
var moment = require("moment");
var request = require("superagent");
var numeral = require("numeral");
var slugify_1 = require("slugify");
var Cruz = require("cruz");
var pages_1 = require("../../routes/pages");
var component_1 = require("../component");
var lib_1 = require("../lib");
var ServiceQuotePay;
(function (ServiceQuotePay) {
    var validate = require('validate.js');
    var rules = {
        number: {
            presence: { message: '^Credit card number is required', allowEmpty: false, },
            length: { is: 19, message: '^Invalid credit card number' }
        },
        exp_date: {
            presence: { message: '^Expire date is required', allowEmpty: false }
        },
        cvc: {
            presence: { message: '^CVC is required', allowEmpty: false },
            length: { is: 3, message: '^Invalid CVC' }
        },
        name: {
            presence: { message: '^Name on card is required', allowEmpty: false }
        },
        address_zip: {
            presence: { message: '^US zip code is required', allowEmpty: false }
        }
    };
    function getQuoteWithOrder(_id, callback) {
        request.get(lib_1.Global.cruzApiBaseUrl + '/user/service-quote/' + _id + '/order').set(lib_1.Auth.authHeader()).end(function (err, apiRes) {
            return callback(err, _.get(apiRes.body, 'data'));
        });
    }
    ServiceQuotePay.getQuoteWithOrder = getQuoteWithOrder;
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                _id: _this.props.match.params._id,
                is_loading: true,
                is_working: false,
                quote: {},
                card: {}
            };
            return _this;
        }
        Page.prototype.componentWillMount = function () {
            var _this = this;
            lib_1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    try {
                        if (_this.props.staticContext) {
                            var d = JSON.parse(JSON.stringify(_this.props.staticContext));
                            var user = lib_1.Global.store.getState().user;
                            _this.setState({
                                quote: d.result,
                                is_loading: false,
                                card: {
                                    name: _.upperCase(user.first_name + ' ' + user.last_name),
                                    address_zip: user.location.zip_code
                                }
                            });
                        }
                        else {
                            var $initData = document.querySelector('#_init_' + pages_1.pages.service_quote_pay.id);
                            if ($initData && $initData.getAttribute('data-init')) {
                                var d = JSON.parse(decodeURIComponent($initData.getAttribute('data-init')));
                                $initData.remove();
                                _this.setState({ is_loading: false, quote: d });
                            }
                            else {
                                _this.init();
                            }
                        }
                    }
                    catch (err) {
                    }
                }
                else {
                    lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                }
            });
        };
        Page.prototype.componentDidMount = function () {
            document.title = lib_1.util.title(pages_1.pages.service_quote_pay.title);
            var user = lib_1.Global.store.getState().user;
            this.setState({
                card: __assign(__assign({}, this.state.card), { name: _.upperCase(user.first_name + ' ' + user.last_name), address_zip: user.location.zip_code })
            });
        };
        Page.prototype.init = function () {
            var _this = this;
            lib_1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    _this.setState({ is_loading: true }, function () {
                        getQuoteWithOrder(_this.state._id, function (err, result) {
                            if (err) {
                                _this.setState({ is_loading: false });
                                lib_1.util.handleError(lib_1.Request.getApiErrorCode(err));
                            }
                            else {
                                _this.setState({ quote: result, is_loading: false });
                            }
                        });
                    });
                }
                else {
                    lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                }
            });
        };
        Page.prototype.renderHeading = function () {
            var quote = this.state.quote;
            var v = quote.vehicle_display;
            return (React.createElement("div", { className: 'c-detail-heading d-flex flex-column flex-sm-row justify-content-between align-items-sm-end' },
                React.createElement("div", null,
                    React.createElement("h1", { className: 'title' }, quote.service.name),
                    React.createElement("div", null,
                        React.createElement(react_router_dom_1.Link, { to: component_1.Vehicle.detailUrl(v), className: 'c-btn-link' }, component_1.Vehicle.detailTitle(v, false)))),
                React.createElement("div", { className: 'mt-3 mt-sm-0 d-flex ml-sm-auto align-items-end' },
                    React.createElement(component_1.Support.Phone, null))));
        };
        Page.prototype.renderOrder = function () {
            var order = this.state.quote.order;
            var item = _.first(order.result.item_list);
            var vendor = order.result.vendor_display;
            var location_name = null;
            if (_.size(vendor.location_list) == 1) {
                location_name = _.get(_.first(vendor.location_list), 'name');
            }
            if (_.isEmpty(location_name)) {
                location_name = vendor.name;
            }
            return (React.createElement("div", { className: 'px-4 py-5 text-center service-quote-result' },
                React.createElement("div", { className: 'c-font-weight-medium c-font-size-xl' }, item.brand),
                !_.isEmpty(item.model)
                    &&
                        React.createElement("div", { className: 'c-font-weight-medium c-font-size-l' }, item.model),
                (order.discount != null && order.discount > 0)
                    ?
                        React.createElement("div", { className: 'pt-4' },
                            React.createElement("div", { className: 'price-lg' },
                                React.createElement("span", { className: 'mr-3' },
                                    React.createElement("del", null, numeral(order.total_price).format('$0,0[.]00'))),
                                React.createElement("span", { className: 'c-font-color-2' }, numeral(order.payment_amount).format('$0,0[.]00'))),
                            React.createElement("div", { className: 'py-2' },
                                "Cruz discount ",
                                React.createElement("span", { className: 'c-font-weight-medium' }, numeral(order.discount).format('$0,0[.]00')),
                                " applied"))
                    :
                        React.createElement("div", { className: 'pt-4 price-lg' }, lib_1.util.formatPrice(this.state.quote.order.payment_amount)),
                !_.isEmpty(order.result.vendor_display)
                    &&
                        React.createElement("div", { className: 'pt-3' },
                            React.createElement("div", { className: 'c-font-size-xs' }, "Service By"),
                            React.createElement("div", { className: 'c-font-weight-medium' }, location_name))));
        };
        Page.prototype.valid = function (callback) {
            this.setState({ validation: validate(this.state.card, rules) }, function () {
                return callback();
            });
        };
        Page.prototype.reValid = function () {
            if (!_.isEmpty(this.state.validation)) {
                this.valid(function () { });
            }
        };
        Page.prototype.getError = function (key) {
            return ((this.state.validation && this.state.validation[key]) ? this.state.validation[key][0] : null);
        };
        Page.prototype.handleStripeApiError = function (err) {
            this.setState({ is_loading: false, is_working: false });
            lib_1.util.setToastMessage('error', _.get(err, 'response.body.error.detail'));
        };
        Page.prototype.pay = function (e) {
            var _this = this;
            e.preventDefault();
            this.valid(function () {
                if (_.isEmpty(_this.state.validation) && !_this.state.is_working) {
                    lib_1.Auth.isAuthed(function (isAuthed) {
                        if (isAuthed) {
                            _this.setState({ is_working: true });
                            lib_1.util.removeToastMessage();
                            var card = _.omit(_this.state.card, 'exp_date');
                            var exp_date = moment(_this.state.card.exp_date, 'MM/YY');
                            card.exp_month = exp_date.format('MM');
                            card.exp_year = exp_date.format('YY');
                            request.post(lib_1.Global.cruzApiBaseUrl + '/user/service-quote/' + _this.state.quote._id + '/pay')
                                .set(lib_1.Auth.authHeader())
                                .send({ card: card, payment_amount: _this.state.quote.order.payment_amount })
                                .retry(1)
                                .end(function (err, apiRes) {
                                if (err) {
                                    _this.handleStripeApiError(err);
                                }
                                else {
                                    _this.setState({ quote: apiRes.body.data, is_working: false });
                                }
                            });
                        }
                        else {
                            lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                        }
                    });
                }
            });
        };
        Page.prototype.renderPaymentForm = function () {
            var _this = this;
            var card = this.state.card;
            return (React.createElement(React.Fragment, null,
                React.createElement("ul", { className: 'list-inline text-muted text-center' },
                    React.createElement("li", { className: 'list-inline-item', title: 'Visa' },
                        React.createElement("i", { className: 'fab fa-cc-visa fa-3x' })),
                    React.createElement("li", { className: 'list-inline-item', title: 'Master' },
                        React.createElement("i", { className: 'fab fa-cc-mastercard fa-3x' })),
                    React.createElement("li", { className: 'list-inline-item', title: 'American Express' },
                        React.createElement("i", { className: 'fab fa-cc-amex fa-3x' })),
                    React.createElement("li", { className: 'list-inline-item', title: 'Discover' },
                        React.createElement("i", { className: 'fab fa-cc-discover fa-3x' })),
                    React.createElement("li", { className: 'list-inline-item', title: 'Diners Club' },
                        React.createElement("i", { className: 'fab fa-cc-diners-club fa-3x' })),
                    React.createElement("li", { className: 'list-inline-item', title: 'JCB' },
                        React.createElement("i", { className: 'fab fa-cc-jcb fa-3x' }))),
                React.createElement("div", { className: 'mb-4 text-muted text-center' }, "Cruz\u00AE accept all US Address major credit and debit cards."),
                React.createElement("form", { id: 'formPayment', onSubmit: function (e) { return _this.pay(e); }, noValidate: true },
                    React.createElement("div", { className: 'row c-sm-gutters' },
                        React.createElement(component_1.MaskedText, { type: 'tel', id: 'number', label: 'Credit Card Number', "data-grid": 'col-sm-6 col-lg-12 col-xl-6', mask: lib_1.Mask.TypeEnum.CreditCardNumber, required: true, value: card.number || '', "data-err": this.getError('number'), onChange: function (e) { _this.setState({ card: __assign(__assign({}, _this.state.card), { 'number': lib_1.Mask.UnmaskedValue(e.target['value'], lib_1.Mask.TypeEnum.CreditCardNumber) }) }, function () { _this.reValid(); }); } }),
                        React.createElement(component_1.MaskedText, { type: 'tel', id: 'exp_date', label: 'Expire Date (MM/YY)', "data-grid": 'col-8 col-sm-3 col-lg-7 col-xl-3', mask: lib_1.Mask.TypeEnum.CreditCardExpireDate, required: true, value: card.exp_date || '', "data-err": this.getError('exp_date'), onChange: function (e) { _this.setState({ card: __assign(__assign({}, _this.state.card), { 'exp_date': lib_1.Mask.UnmaskedValue(e.target['value'], lib_1.Mask.TypeEnum.CreditCardExpireDate) }) }, function () { _this.reValid(); }); } }),
                        React.createElement(component_1.MaskedText, { type: 'tel', id: 'cvc', label: 'CVC', "data-grid": 'col-4 col-sm-3 col-lg-5 col-xl-3', mask: lib_1.Mask.TypeEnum.CreditCardCvc, required: true, value: card.cvc || '', "data-err": this.getError('cvc'), onChange: function (e) { _this.setState({ card: __assign(__assign({}, _this.state.card), { 'cvc': lib_1.Mask.UnmaskedValue(e.target['value'], lib_1.Mask.TypeEnum.CreditCardCvc) }) }, function () { _this.reValid(); }); } }),
                        React.createElement(component_1.Text, { id: 'name', label: 'Name on Card', "data-grid": 'col-sm-6', required: true, value: card.name || '', "data-err": this.getError('name'), onChange: function (e) { _this.setState({ card: __assign(__assign({}, _this.state.card), { 'name': e.target['value'] }) }, function () { _this.reValid(); }); } }),
                        React.createElement(component_1.MaskedText, { type: 'tel', id: 'address_zip', label: 'US Zip Code', "data-grid": 'col-sm-6', mask: lib_1.Mask.TypeEnum.ZipCode, required: true, value: card.address_zip || '', "data-err": this.getError('address_zip'), onChange: function (e) { _this.setState({ card: __assign(__assign({}, _this.state.card), { 'address_zip': lib_1.Mask.UnmaskedValue(e.target['value'], lib_1.Mask.TypeEnum.ZipCode) }) }, function () { _this.reValid(); }); } })),
                    React.createElement("div", null),
                    React.createElement("div", { className: 'pt-4 pb-2 text-center' },
                        React.createElement("button", { type: 'submit', className: 'btn c-btn-1' }, this.state.is_working ? React.createElement(component_1.BtnWorking, { value: 'Processing ...' }) : 'Pay $' + this.state.quote.order.payment_amount.toLocaleString())))));
        };
        Page.prototype.renderCharged = function () {
            var quote = this.state.quote;
            var charge = quote.charge;
            var orderResult = _.get(this.state.quote.order, 'result');
            var not_require_appointment = (quote.status_id == Cruz.Lookup.Service.QuoteStatusEnum.ReadyForService && !_.isEmpty(orderResult) && !orderResult.require_appointment);
            return (React.createElement("div", { className: 'text-center' },
                React.createElement("div", { className: 'mb-4' },
                    React.createElement("i", { className: 'fa fa-5x fa-badge-check c-font-color-1' })),
                React.createElement("div", { className: 'mb-4' },
                    React.createElement("div", { className: 'c-font-size-xl' }, "Paid Successfully"),
                    React.createElement("div", { className: 'c-font-size-xs' }, moment(charge.date_create).fromNow())),
                quote.status_id == Cruz.Lookup.Service.QuoteStatusEnum.Scheduling
                    &&
                        React.createElement("div", { className: 'mb-4' }, "We are working on getting your service scheduled. You will receive confirmation once scheduled, for now just sit back and wait!"),
                not_require_appointment
                    &&
                        React.createElement("div", { className: 'mb-4' }, "No appointment needed. Please bring your vehicle in at your earliest convenience. Don't forget to show your service voucher."),
                React.createElement("div", { className: 'd-flex justify-content-center pt-2' },
                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.service_quote_detail.path + '/' + this.state.quote._id, className: 'btn c-btn-1-rev c-btn-xs mr-3' }, "View Order"),
                    not_require_appointment
                        &&
                            React.createElement(react_router_dom_1.Link, { to: pages_1.pages.service_voucher.path + '/' + slugify_1.default(this.state.quote.service.name) + '/' + this.state.quote._id, className: 'btn c-btn-1 c-btn-xs mr-3' },
                                React.createElement("i", { className: 'fal fa-file' }),
                                React.createElement("span", null, "Voucher")),
                    React.createElement("a", { href: this.state.quote.charge.receipt_url, target: '_blank', className: 'btn c-btn-1 c-btn-xs' },
                        React.createElement("i", { className: 'fal fa-receipt' }),
                        React.createElement("span", null, "Receipt")))));
        };
        Page.prototype.render = function () {
            return (component_1.Layout.main(lib_1.Global.store.getState().user, null, React.createElement("div", { className: 'flex-rh-100 container c-detail-container' }, (this.state.is_loading || _.isEmpty(this.state.quote))
                ?
                    React.createElement(component_1.Loading, null)
                :
                    React.createElement("div", { className: 'flex-ch-100 pb-4' },
                        this.renderHeading(),
                        React.createElement("div", { className: 'my-3 px-3 px-lg-0' },
                            React.createElement("div", { className: 'card' }, _.isEmpty(this.state.quote.order)
                                ?
                                    React.createElement("div", { className: 'text-center' },
                                        "Please ",
                                        React.createElement("a", { href: pages_1.pages.service_quote_detail.path + '/' + this.state._id, className: 'c-btn-link' }, "order one of the quote results"),
                                        " first.")
                                :
                                    React.createElement("div", { className: 'row no-gutters' },
                                        React.createElement("div", { className: 'col-lg-5 bg-light' }, this.renderOrder()),
                                        React.createElement("div", { className: 'col-lg-7 px-5 py-5' }, _.isEmpty(this.state.quote.charge)
                                            ?
                                                this.renderPaymentForm()
                                            :
                                                this.renderCharged()))))))));
        };
        return Page;
    }(React.Component));
    ServiceQuotePay.Page = Page;
})(ServiceQuotePay = exports.ServiceQuotePay || (exports.ServiceQuotePay = {}));
