"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var _ = require("lodash");
var moment = require("moment");
var slugify_1 = require("slugify");
var react_visibility_sensor_1 = require("react-visibility-sensor");
var Cruz = require("cruz");
var pages_1 = require("../../../routes/pages");
var lib_1 = require("../../lib");
var __1 = require("..");
var delete_1 = require("./delete");
var cfg = require("../../../cfg");
var Spotted;
(function (Spotted) {
    var SummaryCard = (function (_super) {
        __extends(SummaryCard, _super);
        function SummaryCard(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                is_working: false,
                is_viewed: _this.props.spotted.is_viewed
            };
            return _this;
        }
        SummaryCard.prototype.componentDidMount = function () {
            if (lib_1.util.isClient() && !this.state.is_viewed) {
                this.setState({
                    is_viewed: lib_1.Tracking.isViewed('spotted', this.props.spotted._id)
                });
            }
        };
        SummaryCard.prototype.openDeleteDialog = function () {
            var _this = this;
            lib_1.util.setModal(null, false, true, React.createElement(delete_1.Delete.Dialog, { id: this.props.spotted._id, onYes: function (e) { return _this.deleteYes(); } }));
        };
        SummaryCard.prototype.deleteYes = function () {
            var _this = this;
            if (!this.state.is_working) {
                this.setState({ is_working: true });
                lib_1.Request.spotted.delete(this.props.spotted._id, function (err, apiRes) {
                    _this.setState({ is_working: false });
                    if (!err && apiRes.body.data) {
                        _this.props.onPostStatusUpdateDone && _this.props.onPostStatusUpdateDone(apiRes.body.data);
                    }
                });
            }
        };
        SummaryCard.prototype.undelete = function () {
            var _this = this;
            if (!this.state.is_working) {
                this.setState({ is_working: true });
                lib_1.Request.spotted.undelete(this.props.spotted._id, function (err, apiRes) {
                    _this.setState({ is_working: false });
                    if (!err && apiRes.body.data) {
                        _this.props.onPostStatusUpdateDone && _this.props.onPostStatusUpdateDone(apiRes.body.data);
                    }
                });
            }
        };
        SummaryCard.prototype.renderCard = function () {
            var _this = this;
            try {
                var spotted = this.props.spotted;
                var url = pages_1.pages.spotted_detail.path + '/' + slugify_1.default(spotted.location_name, { replacement: '-' }) + '/' + slugify_1.default(spotted.title, '-') + '/' + spotted._id;
                var me = lib_1.Global.store.getState().user;
                var my_user_id = me ? me._id : null;
                var isViewed = this.state.is_viewed;
                var isLiked = (my_user_id && !_.isEmpty(spotted.vote) && spotted.vote.up.user_list && _.indexOf(spotted.vote.up.user_list, my_user_id) > -1);
                var like_count = (!_.isEmpty(spotted.vote) && spotted.vote.up.count > 0) ? spotted.vote.up.count : 0;
                return (React.createElement("div", { className: 'card' },
                    !lib_1.util.isClient()
                        &&
                            lib_1.Schema.SpottedItem(spotted),
                    React.createElement(react_router_dom_1.Link, { to: url },
                        React.createElement("div", { className: 'card-img' },
                            React.createElement("img", { className: 'card-img-top', alt: spotted.title || '', src: cfg.photoBaseUrl[cfg.env].cdn + '/spotted/' + spotted.group + '/' + spotted._id + '/' + spotted.photo_list[0].id + '-md.jpg' }),
                            spotted.post_status_id == Cruz.Lookup.Other.PostStatusEnum.Delete
                                &&
                                    React.createElement("div", { className: 'c-icon-status', title: 'This spotted has been deleted.' },
                                        React.createElement("i", { className: 'fal fa-trash-alt' })),
                            React.createElement("div", { className: 'c-img-overlay' },
                                React.createElement("span", null,
                                    React.createElement("i", { className: 'fal fa-camera' }),
                                    React.createElement("span", { className: '' }, spotted.photo_list.length)),
                                isViewed
                                    &&
                                        React.createElement(React.Fragment, null,
                                            React.createElement("span", { className: 'separator' }),
                                            React.createElement("span", { className: 'c-font-size-xs' }, "Viewed"))))),
                    React.createElement("div", { className: 'card-body' },
                        React.createElement("div", { className: 'd-flex' },
                            React.createElement("div", { className: 'ellipsis' },
                                React.createElement("h2", { className: 'card-title mb-1 ellipsis' },
                                    React.createElement(react_router_dom_1.Link, { to: url, className: 'c-btn-link', title: spotted.title || '' }, spotted.title)),
                                React.createElement("div", null,
                                    React.createElement("div", { className: 'c-font-size-s ellipsis' }, (spotted.location && spotted.location.city && spotted.location.state) ? spotted.location.city + ', ' + spotted.location.state : spotted.location_name),
                                    React.createElement("div", { className: 'c-font-size-xs mt-2 ellipsis' },
                                        React.createElement("i", { className: 'fal fa-clock mr-2' }),
                                        React.createElement("span", null, 'Posted ' + moment(spotted.date_create).fromNow()),
                                        (spotted.date_update && moment(spotted.date_update).fromNow() != moment(spotted.date_create).fromNow())
                                            &&
                                                React.createElement("span", null,
                                                    React.createElement("span", { className: 'separator' }),
                                                    React.createElement("span", null, 'Updated ' + moment(spotted.date_update).fromNow()))))),
                            this.state.is_working
                                ?
                                    React.createElement("div", { className: 'ml-auto pl-4' },
                                        React.createElement("i", { className: 'far fa-spinner fa-pulse' }))
                                :
                                    (this.props.showEdit && my_user_id && my_user_id == spotted.user_id)
                                        &&
                                            React.createElement("div", { className: 'ml-auto c-ellipsis-drop dropup pl-4' },
                                                React.createElement("button", { className: 'dropdown-toggle', "data-toggle": 'dropdown' },
                                                    React.createElement("i", { className: 'far fa-ellipsis-v' })),
                                                React.createElement("div", { className: 'dropdown-menu dropdown-menu-right' },
                                                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.spotted_add_edit.path + '?_id=' + spotted._id, className: 'dropdown-item' },
                                                        React.createElement("i", { className: 'far fa-pen fa-fw' }),
                                                        React.createElement("span", null, "Edit")),
                                                    React.createElement("div", { className: 'dropdown-divider' }),
                                                    spotted.post_status_id == Cruz.Lookup.Other.PostStatusEnum.Delete
                                                        ?
                                                            React.createElement("button", { type: 'button', className: 'dropdown-item', onClick: function (e) { return _this.undelete(); } },
                                                                React.createElement("i", { className: 'fal fa-undo fa-fw' }),
                                                                React.createElement("span", null, "Undelete"))
                                                        :
                                                            React.createElement("button", { type: 'button', className: 'dropdown-item', onClick: function (e) { return _this.openDeleteDialog(); } },
                                                                React.createElement("i", { className: 'fal fa-trash-alt fa-fw' }),
                                                                React.createElement("span", null, "Delete")))))),
                    React.createElement("div", { className: 'card-footer c-card-footer' },
                        this.props.showUserInfo
                            &&
                                React.createElement("div", { className: 'c-card-footer-user-display' }, __1.User.renderUserDisplaySummary(spotted.user_display, (me && !_.isEmpty(me.follow)) ? me.follow.following_list : null)),
                        React.createElement("div", { className: 'c-card-footer-row row no-gutters' },
                            React.createElement("div", { className: 'cc-item col' },
                                React.createElement("span", { className: 'count' }, spotted.view_count.toLocaleString()),
                                React.createElement("span", { className: 'name' },
                                    "View",
                                    spotted.view_count > 1 && 's')),
                            React.createElement("div", { className: 'cc-item col' },
                                React.createElement("span", { className: 'count' }, spotted.comment_count.toLocaleString()),
                                React.createElement(react_router_dom_1.Link, { to: url + '#_comments', className: 'd-inline d-xl-none c-btn-link' },
                                    React.createElement("span", { className: 'name' },
                                        "Comment",
                                        spotted.comment_count > 1 && 's')),
                                React.createElement("span", { className: 'name d-none d-xl-inline' },
                                    "Comment",
                                    spotted.comment_count > 1 && 's')),
                            React.createElement("div", { className: 'cc-item col', title: isLiked ? 'You liked this' : '' },
                                isLiked
                                    &&
                                        React.createElement("i", { className: 'fas fa-heart c-fa-liked mr-2' }),
                                React.createElement("span", { className: 'count' }, like_count.toLocaleString()),
                                React.createElement("span", { className: 'name' },
                                    "Like",
                                    (like_count > 1) && 's'),
                                (like_count > 0)
                                    &&
                                        React.createElement("button", { type: 'button', className: 'ml-2 c-btn-open-stat', onClick: __1.Vote.openStatLikedModal.bind(this, { name: 'spotted', _id: spotted._id }) },
                                            React.createElement("i", { className: 'fal fa-list-ul' })))))));
            }
            catch (err) {
                lib_1.util.logError(err, null);
                return null;
            }
        };
        SummaryCard.prototype.render = function () {
            var _this = this;
            return (React.createElement(react_visibility_sensor_1.default, { partialVisibility: true, offset: { top: -2000, bottom: -2000 }, resizeCheck: true }, function (_a) {
                var isVisible = _a.isVisible;
                return (isVisible || !lib_1.util.isClient() || _this.props.shouldRender) ? _this.renderCard() : React.createElement("div", { style: { minHeight: 555 } });
            }));
        };
        return SummaryCard;
    }(React.Component));
    Spotted.SummaryCard = SummaryCard;
})(Spotted = exports.Spotted || (exports.Spotted = {}));
