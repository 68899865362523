"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var _ = require("lodash");
var classNames = require("classnames");
var Cruz = require("cruz");
var lib_1 = require("../lib");
var pages_1 = require("../../routes/pages");
var component_1 = require("../component");
var service_quote_request_control_1 = require("./service_quote_request_control");
var cfg = require("../../cfg");
var ServiceQuoteRequest;
(function (ServiceQuoteRequest) {
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            var _this = _super.call(this, props) || this;
            _this.renderNoVehicle = function () {
                return (React.createElement(React.Fragment, null,
                    React.createElement("p", null,
                        "No vehicle registered!",
                        React.createElement("br", null),
                        "Please register your vehicle(s) for the quote."),
                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.vehicle_add_edit.path, className: 'btn c-btn-1 mt-3' }, "Register Vehicle")));
            };
            return _this;
        }
        Page.prototype.componentWillMount = function () {
            var _this = this;
            lib_1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    service_quote_request_control_1.ServiceQuoteRequestControl.getInitData(function (err, result) {
                        if (err) {
                            service_quote_request_control_1.ServiceQuoteRequestControl.store.dispatch({ type: 0, error: err });
                        }
                        else {
                            service_quote_request_control_1.ServiceQuoteRequestControl.store.dispatch({ type: 1, data: result });
                        }
                    });
                }
                else {
                    lib_1.Auth.redirectSignin(_this.props.location.pathname + _this.props.location.search);
                }
            });
        };
        Page.prototype.componentDidMount = function () {
            var _this = this;
            this.unsubscribeGlobal = lib_1.Global.store.subscribe(function () { _this.forceUpdate(); });
            document.title = lib_1.util.title(pages_1.pages.service_quote_request.title);
            if (service_quote_request_control_1.ServiceQuoteRequestControl != null && service_quote_request_control_1.ServiceQuoteRequestControl.store != null) {
                this.unsubscribe = service_quote_request_control_1.ServiceQuoteRequestControl.store.subscribe(function () { _this.forceUpdate(); });
            }
        };
        Page.prototype.componentWillUnmount = function () {
            service_quote_request_control_1.ServiceQuoteRequestControl.store.dispatch({ type: 15 });
            this.unsubscribe && this.unsubscribe();
            this.unsubscribeGlobal && this.unsubscribeGlobal();
        };
        Page.prototype.renderVehicleCard = function () {
            var state = service_quote_request_control_1.ServiceQuoteRequestControl.store.getState();
            var v = _.find(state.vehicle.data, { _id: state.quote.vehicle_id });
            var title = component_1.Vehicle.detailTitle(v, false);
            var url = component_1.Vehicle.detailUrl(v);
            var hasPhoto = (v.photo_count > 0 && !_.isEmpty(_.first(v.photo_list)));
            var hasStockPhoto = _.size(v.stock_photo_list) > 0;
            var showPhoto = (hasPhoto || hasStockPhoto);
            return (React.createElement("div", { className: 'pb-2' },
                React.createElement("div", { className: 'card c-veh-card h-auto' },
                    React.createElement("div", { className: 'row no-gutters' },
                        showPhoto
                            &&
                                React.createElement("div", { className: 'col-5 col-md-4' },
                                    React.createElement(react_router_dom_1.Link, { to: url },
                                        React.createElement("img", { className: 'card-img-top', src: hasPhoto
                                                ?
                                                    cfg.photoBaseUrl[cfg.env].cdn + '/vehicle/' + v.group + '/' + v._id + '/' + v.photo_list[0].id + '-md.jpg'
                                                :
                                                    cfg.photoBaseUrl.stock + _.first(v.stock_photo_list) }))),
                        React.createElement("div", { className: classNames({ 'col-7 col-md-8': showPhoto }) },
                            React.createElement("div", { className: 'card-body ml-md-2' },
                                React.createElement("h2", { className: 'card-title pb-2 ellipsis' },
                                    React.createElement(react_router_dom_1.Link, { to: url, className: 'c-btn-link' }, title)),
                                React.createElement("ul", { className: 'list-unstyled specs m-0' },
                                    React.createElement("li", null,
                                        React.createElement("span", { className: 'name mr-2' }, "VIN:"),
                                        React.createElement("span", { className: 'value' }, v.vin || '---')),
                                    (v.mileage != null && v.mileage > 0)
                                        &&
                                            React.createElement("li", null,
                                                React.createElement("span", { className: 'name mr-2' }, "Mileage:"),
                                                React.createElement("span", { className: 'value' },
                                                    React.createElement("span", { className: 'highlight' }, v.mileage.toLocaleString()),
                                                    " mile",
                                                    v.mileage > 1 ? 's' : '')),
                                    !_.isEmpty(v.engine) && !_.isEmpty(v.engine.name)
                                        &&
                                            React.createElement("li", null,
                                                React.createElement("span", { className: 'name mr-2' }, "Engine:"),
                                                React.createElement("span", { className: 'value' }, v.engine.name)),
                                    !_.isEmpty(v.transmission)
                                        &&
                                            React.createElement("li", null,
                                                React.createElement("span", { className: 'name mr-2' }, "Transmission:"),
                                                React.createElement("span", { className: 'value' }, v.transmission.name))),
                                React.createElement(react_router_dom_1.Link, { to: pages_1.pages.vehicle_add_edit.path + '?_id=' + v._id + '&service_quote=1', className: 'btn c-btn-1 c-btn-xs mt-3' }, "Update")))))));
        };
        Page.prototype.renderVehicleSelect = function () {
            var state = service_quote_request_control_1.ServiceQuoteRequestControl.store.getState();
            return (React.createElement(React.Fragment, null,
                React.createElement("h2", { className: 'quote-section-title' }, "Choose Vehicle:"),
                (state.vehicle.meta.total_records_matched > 1)
                    &&
                        React.createElement("div", { className: 'row' },
                            React.createElement("div", { className: 'col-lg-8' },
                                React.createElement(component_1.Select, { id: 'vehicle_id', name: 'vehicle_id', label: 'Vehicle', required: true, size: 1, options: _.concat([React.createElement("option", { key: 0 })], _.map(state.vehicle.data, function (x) {
                                        return (React.createElement("option", { value: x._id, key: x._id }, _.chain(x).pick('year', 'make', 'model', 'trim').toArray().value().join(' ')));
                                    })), value: _.get(state.quote, 'vehicle_id') || '', onChange: function (e) { return service_quote_request_control_1.ServiceQuoteRequestControl.store.dispatch({ type: 2, data: { vehicle_id: e.target['value'] } }); } }),
                                (state.vehicle.meta.total_records_matched == 1 || _.isEmpty(state.quote.vehicle_id))
                                    &&
                                        React.createElement("div", { className: 'text-right mb-2' },
                                            state.vehicle.meta.total_records_matched == 1 ? 'Not this vehicle? ' : 'Not in the list? ',
                                            React.createElement(react_router_dom_1.Link, { to: pages_1.pages.vehicle_add_edit.path, className: 'c-btn-link' }, "Add Vehicle")))),
                _.get(state.quote, 'vehicle_id')
                    &&
                        this.renderVehicleCard()));
        };
        Page.prototype.renderServiceSelect = function () {
            var state = service_quote_request_control_1.ServiceQuoteRequestControl.store.getState();
            return (React.createElement("div", { className: 'mt-4 pb-2' },
                React.createElement("h2", { className: 'quote-section-title' }, "Choose Service Type:"),
                React.createElement("div", { className: 'row' },
                    React.createElement("div", { className: 'col-lg-8' },
                        React.createElement(component_1.Select, { id: 'service_code', name: 'service_code', label: 'Service Type', required: true, size: 1, className: 'mb-0', value: _.get(state.quote, 'service_code') || '', options: this.mapServiceOption(), onChange: function (e) { return service_quote_request_control_1.ServiceQuoteRequestControl.store.dispatch({
                                type: 3,
                                data: { service_code: e.target['value'] != null ? Number(e.target['value']) : null }
                            }); } }))),
                !(_.get(state.quote, 'service_code') > 1)
                    &&
                        React.createElement("div", { className: 'mt-3' },
                            "Don't see the quote type you are looking for? Email us at ",
                            React.createElement("a", { href: 'mailto:quotes@cruz.com?subject=Service Type Request', className: 'c-btn-link' }, "quotes@cruz.com"),
                            " with your request.")));
        };
        Page.prototype.renderVehicleErrorList = function () {
            var state = service_quote_request_control_1.ServiceQuoteRequestControl.store.getState();
            var v = _.find(state.vehicle.data, { _id: state.quote.vehicle_id });
            return ((_.size(state.vehicle_err_list) > 0)
                &&
                    React.createElement("div", { className: 'mt-4' },
                        React.createElement("h2", { className: 'quote-section-title' }, "Need more Information About this Vehicle:"),
                        React.createElement("div", { className: 'row' },
                            React.createElement("div", { className: 'col-xl-8' },
                                React.createElement("div", { className: 'bg-warning px-2 py-3 rounded' },
                                    React.createElement("ul", { className: 'mb-0' }, _.map(state.vehicle_err_list, function (d, idx) {
                                        return React.createElement("li", { key: idx }, d);
                                    }))),
                                (_.includes(state.vehicle_err_list, 'VIN') == true)
                                    &&
                                        React.createElement("div", { className: 'mt-3 px-4 py-3 border rounded' },
                                            React.createElement("div", null,
                                                "VIN (vehicle identification number) can be found in several places on your vehicle as well as your ",
                                                React.createElement("u", null, "insurance card"),
                                                " and ",
                                                React.createElement("u", null, "vehicle registration"),
                                                "."),
                                            React.createElement("img", { src: '/img/vehicle/where_is_vin.jpg', className: 'img-fluid mt-3' })))),
                        React.createElement(react_router_dom_1.Link, { to: pages_1.pages.vehicle_add_edit.path + '?_id=' + v._id + '&service_quote=1', className: 'btn c-btn-1 c-btn-xs mt-3' }, "Update Vehicle")));
        };
        Page.prototype.renderPhone = function () {
            var state = service_quote_request_control_1.ServiceQuoteRequestControl.store.getState();
            return (React.createElement("div", { className: 'mt-4 pb-2' },
                React.createElement("h2", { className: 'quote-section-title' }, "Phone:"),
                React.createElement("div", { className: 'row' },
                    React.createElement("div", { className: 'col-lg-8' },
                        React.createElement(component_1.MaskedText, { type: 'tel', id: 'phone', label: 'Phone', required: true, mask: lib_1.Mask.TypeEnum.Phone, className: 'mb-0', value: state.phone || '', onChange: function (e) { return service_quote_request_control_1.ServiceQuoteRequestControl.store.dispatch({ type: 11, data: e.target['value'] }); }, "data-err": _.get(service_quote_request_control_1.ServiceQuoteRequestControl.store.getState().validation, 'phone') })))));
        };
        Page.prototype.renderAmenityList = function () {
            var state = service_quote_request_control_1.ServiceQuoteRequestControl.store.getState();
            return (React.createElement("div", { className: 'mt-5 pb-2' },
                React.createElement("h2", { className: 'quote-section-title' },
                    "Choose Must have Amenities ",
                    React.createElement("span", { className: 'c-font-weight-light' }, "(optional)"),
                    ":"),
                React.createElement("ul", { className: 'list-inline mb-0' }, _.map(Cruz.Lookup.Vendor.amenity_list, function (d, idx) {
                    return (React.createElement("li", { key: idx, className: 'list-inline-item c-btn-checkbox' },
                        React.createElement("input", { type: 'checkbox', autoComplete: 'off', id: 'amenity_' + d.code, value: d.code, checked: _.includes(state.quote.amenity_list, d.code), onChange: function (e) { return service_quote_request_control_1.ServiceQuoteRequestControl.store.dispatch({ type: 6, target: e.target }); } }),
                        React.createElement("label", { htmlFor: 'amenity_' + d.code.toString() }, d.name)));
                })),
                React.createElement("div", { className: 'clearfix' })));
        };
        Page.prototype.getError = function (key) {
            var state = service_quote_request_control_1.ServiceQuoteRequestControl.store.getState();
            return (_.get(state.qa_validation, key) ? _.first(state.qa_validation[key]) : null);
        };
        Page.prototype.requiredLabel = function (is_required) {
            if (is_required) {
                return React.createElement("span", { className: 'ml-2 c-font-size-xs' }, "(required)");
            }
            else {
                return null;
            }
        };
        Page.prototype.renderQuestion = function (q) {
            var quote = service_quote_request_control_1.ServiceQuoteRequestControl.store.getState().quote;
            switch (q.type) {
                case 'text': {
                    return (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: 'pb-2' },
                            q.question,
                            this.requiredLabel(q.is_required)),
                        React.createElement(component_1.Text, { id: q._id, required: q.is_required, "data-err": this.getError(q._id), maxLength: 300, value: _.get(quote.qa, q._id) || '', onChange: function (e) { return service_quote_request_control_1.ServiceQuoteRequestControl.store.dispatch({ type: 7, target: e.target }); } })));
                }
                case 'switch': {
                    return (React.createElement(component_1.Checkbox, { id: q._id, label: q.question, checked: Boolean(_.get(quote.qa, q._id)) || false, value: 1, onChange: function (e) { return service_quote_request_control_1.ServiceQuoteRequestControl.store.dispatch({ type: 7, target: e.target }); } }));
                }
                case 'radio': {
                    var err = this.getError(q._id);
                    return (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: 'pb-2' },
                            q.question,
                            this.requiredLabel(q.is_required)),
                        React.createElement("div", { className: classNames('form-group', { 'has-error': !_.isEmpty(err) }) },
                            _.map(q.option_list, function (d, idx) {
                                return (React.createElement(component_1.Radio, { key: idx, name: q._id, id: q._id + '_' + idx, value: d, label: d, checked: _.includes(_.get(quote.qa, q._id), d), className: 'mb-0', onChange: function (e) { return service_quote_request_control_1.ServiceQuoteRequestControl.store.dispatch({ type: 8, target: e.target }); } }));
                            }),
                            !_.isEmpty(err)
                                &&
                                    React.createElement("span", { className: 'form-control-feedback', "aria-hidden": true }, err))));
                }
                case 'check': {
                    var err = this.getError(q._id);
                    return (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: 'pb-2' },
                            q.question,
                            this.requiredLabel(q.is_required)),
                        React.createElement("div", { className: classNames('form-group', { 'has-error': !_.isEmpty(err) }) },
                            _.map(q.option_list, function (d, idx) {
                                return (React.createElement(component_1.Checkbox, { id: q._id + '_' + idx, name: q._id, key: idx, label: d, value: d, checked: _.includes(_.get(quote.qa, q._id), d), className: 'mb-0', onChange: function (e) { return service_quote_request_control_1.ServiceQuoteRequestControl.store.dispatch({ type: 9, target: e.target }); } }));
                            }),
                            !_.isEmpty(err)
                                &&
                                    React.createElement("span", { className: 'form-control-feedback', "aria-hidden": true }, err))));
                }
            }
        };
        Page.prototype.renderQuestionList = function () {
            var _this = this;
            var state = service_quote_request_control_1.ServiceQuoteRequestControl.store.getState();
            var q_count = _.size(state.question_list);
            return ((q_count > 0)
                &&
                    React.createElement("div", { className: 'mt-4 pb-1' },
                        (q_count == 1)
                            &&
                                React.createElement(React.Fragment, null,
                                    React.createElement("h2", { className: 'quote-section-title' }, "One Quick Question:"),
                                    this.renderQuestion(_.first(state.question_list))),
                        q_count > 1
                            &&
                                React.createElement(React.Fragment, null,
                                    React.createElement("h2", { className: 'quote-section-title' }, "A few Questions:"),
                                    React.createElement("ol", { className: 'quote-question-list' }, _.map(state.question_list, function (q) {
                                        return (React.createElement("li", { key: q._id }, _this.renderQuestion(q)));
                                    })))));
        };
        Page.prototype.mapServiceOption = function () {
            var options = [
                React.createElement("option", { key: '0' })
            ];
            _.each(Cruz.Lookup.Service.service_list, function (service) {
                if (_.size(_.filter(service.item_list, { allow_quote: true }))) {
                    var x_1 = [];
                    _.map(service.item_list, function (d) {
                        if (d.allow_quote) {
                            x_1.push(React.createElement("option", { value: d.code.toString(), key: d.code }, d.name));
                        }
                    });
                    options.push(React.createElement("optgroup", { label: service.name, key: service.code }, x_1));
                }
            });
            return options;
        };
        Page.prototype.renderDescription = function () {
            var state = service_quote_request_control_1.ServiceQuoteRequestControl.store.getState();
            return (React.createElement("div", { className: 'mt-4' },
                React.createElement(component_1.Textarea, { id: 'description', label: 'Description', className: 'textarea mb-0', minRows: 3, maxRows: 20, value: state.quote.description || '', onChange: function (e) { return service_quote_request_control_1.ServiceQuoteRequestControl.store.dispatch({ type: 10, target: e.target }); } })));
        };
        Page.prototype.openTermsModal = function () {
            lib_1.util.setModal(null, false, false, React.createElement(component_1.Modal.ServiceQuoteTerms, null));
        };
        Page.prototype.renderData = function () {
            var _this = this;
            var state = service_quote_request_control_1.ServiceQuoteRequestControl.store.getState();
            var hasVehicleData = !_.isEmpty(_.get(state.vehicle, 'meta'));
            return (React.createElement(React.Fragment, null,
                (!state.is_loading && hasVehicleData && state.vehicle.meta.total_records_matched == 0) && this.renderNoVehicle(),
                (!state.is_loading && hasVehicleData && state.vehicle.meta.total_records_matched > 0)
                    &&
                        React.createElement("form", { id: 'formServiceQuote', noValidate: true, onSubmit: function (e) { return service_quote_request_control_1.ServiceQuoteRequestControl.store.dispatch({ type: 13, event: e }); } },
                            React.createElement("div", { className: 'card h-auto' },
                                React.createElement("div", { className: 'card-body' },
                                    React.createElement("div", { className: 'row justify-content-center py-3' },
                                        React.createElement("div", { className: 'col-md-9 col-lg-8' },
                                            this.renderVehicleSelect(),
                                            this.renderServiceSelect(),
                                            state.is_verifying
                                                &&
                                                    React.createElement("div", { className: 'mt-4' },
                                                        React.createElement(component_1.Loading, { label: 'Verifying vehicle data ...' })),
                                            (!state.is_verifying && _.size(state.vehicle_err_list) > 0)
                                                &&
                                                    this.renderVehicleErrorList(),
                                            state.is_loading_question
                                                &&
                                                    React.createElement("div", { className: 'mt-4' },
                                                        React.createElement(component_1.Loading, { label: 'Loading data ...' })),
                                            (!state.is_verifying && !state.is_loading_question && !_.isEmpty(state.quote.vehicle_id) && state.quote.service_code != null && state.quote.service_code > 0 && _.size(state.vehicle_err_list) == 0)
                                                &&
                                                    React.createElement(React.Fragment, null,
                                                        this.renderPhone(),
                                                        this.renderAmenityList(),
                                                        this.renderQuestionList(),
                                                        this.renderDescription()),
                                            state.allow_save
                                                &&
                                                    React.createElement("div", { className: 'mt-4' },
                                                        React.createElement("label", { className: 'custom-control custom-checkbox mb-0' },
                                                            React.createElement("input", { type: 'checkbox', autoComplete: 'off', id: 'agree_term', className: 'custom-control-input', onClick: function () { service_quote_request_control_1.ServiceQuoteRequestControl.store.dispatch({ type: 12 }); } }),
                                                            React.createElement("label", { htmlFor: 'agree_term', className: 'custom-control-label' },
                                                                "Check here to indicate that you agree to the ",
                                                                React.createElement("button", { type: 'button', className: 'c-btn-link', onClick: function () { return _this.openTermsModal(); } }, "CRUZ\u00AE's Affirmation and Release"))))))),
                                state.allow_save
                                    &&
                                        React.createElement("div", { className: 'card-footer text-right' },
                                            React.createElement("button", { type: 'submit', className: 'btn c-btn-1', disabled: !state.agreed_term }, state.is_working ? React.createElement(component_1.BtnWorking, { value: 'Saving ...' }) : 'Save'))))));
        };
        Page.prototype.render = function () {
            return (component_1.Layout.main(null, null, React.createElement(React.Fragment, null,
                React.createElement(component_1.Hero, { title: pages_1.pages.service_quote.title }),
                React.createElement("div", { className: 'container c-container' },
                    React.createElement("section", { className: 'mb-4' },
                        React.createElement("h1", { className: 'c-title' }, pages_1.pages.service_quote_request.title)),
                    (service_quote_request_control_1.ServiceQuoteRequestControl == null || service_quote_request_control_1.ServiceQuoteRequestControl.store == null || service_quote_request_control_1.ServiceQuoteRequestControl.store.getState().is_loading)
                        ?
                            React.createElement(component_1.Loading, null)
                        :
                            this.renderData()))));
        };
        return Page;
    }(React.Component));
    ServiceQuoteRequest.Page = Page;
})(ServiceQuoteRequest = exports.ServiceQuoteRequest || (exports.ServiceQuoteRequest = {}));
