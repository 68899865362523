"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var Redux = require("redux");
var request = require("superagent");
var _ = require("lodash");
var moment = require("moment");
var numeral = require("numeral");
var Cruz = require("cruz");
var cfg = require("../../../cfg");
var pages_1 = require("../../../routes/pages");
var lib_1 = require("../../lib");
var user_1 = require("../../user");
var __1 = require("..");
var VehicleAddEditControl;
(function (VehicleAddEditControl) {
    VehicleAddEditControl.initState = {
        vehicleInput: {
            body_type_code: null,
            vin: '',
            make: '',
            model: '',
            year: null,
            trim: '',
            custom: {},
            mileage: null,
            description: '',
            warranty: '',
            price: null,
            sale_status_id: null,
            post_status_id: null,
            xdata: {},
            ydata: {},
            upload_file_list: []
        },
        prevVehicleInput: null,
        dataone: {
            options: {
                year: _.range(moment().year() + 1, 1900, -1),
                select_list: [],
                vehicle_list: []
            },
            decode_method: null,
            vinDecodeData: null,
            reverseDecodeData: null,
        },
        noneDecodeOptions: {},
        is_dirty: false,
        is_working: false,
        is_loading: true,
        executeUpload: false,
        is_create: false,
        showNoneDecodeForm: false
    };
    var validate = require('validate.js');
    var rules = {
        'sale_status_id': {
            presence: { message: '^This is required.', allowEmpty: false }
        },
        'make': {
            presence: { message: '^Make is required.', allowEmpty: false }
        },
        'model': {
            presence: { message: '^Model is required.', allowEmpty: false }
        },
        'year': {
            presence: { message: '^Year is required.', allowEmpty: false }
        },
        'mileage': {
            presence: { message: '^Mileage is required.', allowEmpty: false },
            numericality: { greaterThan: 0, message: '^Mileage must be greater than 0.' }
        },
        'exterior_color': {
            presence: { message: '^Exterior Color is required.', allowEmpty: false }
        },
    };
    var rulesCustom = {
        'custom.brand': {
            presence: { message: '^Brand is required.', allowEmpty: false }
        }
    };
    var rulesNoneDecode = {
        'ydata.engine_displacement': {
            numericality: {
                greaterThan: 0,
                lessThan: 11,
                message: '^Invalid displacement.'
            }
        },
        'ydata.max_hp': {
            numericality: {
                greaterThan: 1,
                lessThan: 1100,
                message: '^Invalid horsepower.'
            }
        },
        'ydata.max_torque': {
            numericality: {
                greaterThan: 1,
                lessThan: 1000,
                message: '^Invalid torque.'
            }
        },
        'ydata.acceleration': {
            numericality: {
                greaterThan: 2,
                lessThan: 500,
                message: '^Invalid acceleration.'
            }
        },
        'ydata.wheel_dia': {
            numericality: {
                greaterThan: 10,
                lessThan: 30,
                message: '^Invalid diameter.'
            }
        }
    };
    function getBodyTypeByCode(code, bodyTypeOptions) {
        var bt = null;
        if (code && bodyTypeOptions) {
            for (var _i = 0, bodyTypeOptions_1 = bodyTypeOptions; _i < bodyTypeOptions_1.length; _i++) {
                var d = bodyTypeOptions_1[_i];
                if (d.code == Number(code)) {
                    bt = d;
                }
                else if (d.child) {
                    var x = _.find(d.child, { code: Number(code) });
                    if (x) {
                        bt = x;
                        break;
                    }
                }
            }
        }
        return bt;
    }
    VehicleAddEditControl.getBodyTypeByCode = getBodyTypeByCode;
    function vehicleOptionCount(selected_o_list, o_list) {
        var st = _.intersection(selected_o_list, o_list).length;
        return ((st > 0 ? st : '-') + '/' + o_list.length);
    }
    VehicleAddEditControl.vehicleOptionCount = vehicleOptionCount;
    function verifyVIN() {
        lib_1.Auth.isAuthed(function (isAuthed) {
            if (isAuthed) {
                request.get(lib_1.Global.cruzApiBaseUrl + '/vehicle/verify-vin?vin=' + VehicleAddEditControl.store.getState().vehicleInput.vin + '&_id=' + (VehicleAddEditControl.store.getState().vehicleInput._id || ''))
                    .set(lib_1.Auth.authHeader())
                    .retry(2)
                    .timeout(cfg.superagent.timeout.normal)
                    .end(function (err, apiRes) {
                    VehicleAddEditControl.store.dispatch({ type: 14, data: (apiRes && apiRes.body) ? apiRes.body.data : null });
                });
            }
            else {
                lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
            }
        });
    }
    VehicleAddEditControl.verifyVIN = verifyVIN;
    function getVehicleForUpdate(_id, callback) {
        request.get(lib_1.Global.cruzApiBaseUrl + '/vehicle/update/' + _id)
            .set(lib_1.Auth.authHeader())
            .timeout(cfg.superagent.timeout.normal)
            .end(function (err, apiRes) {
            if (err) {
                lib_1.util.handleError(lib_1.Request.getApiErrorCode(err));
                return callback(null, null);
            }
            else {
                return callback(null, apiRes.body.data);
            }
        });
    }
    VehicleAddEditControl.getVehicleForUpdate = getVehicleForUpdate;
    var keysVehicle = ['vehicle_id', 'body_type_code', 'make', 'model', 'year', 'trim', 'exterior_color', 'interior_color', 'roof_color', 'option_id_list', 'xdata'];
    var keysNoneDecode = ['transmission', 'drive_type'];
    VehicleAddEditControl.store = Redux.createStore(function (state, action) {
        var _a;
        var tgt = action.target;
        function valid() {
            var vr = _.assign({}, rules);
            if (state.dataone.decode_method == 'none') {
                vr = _.assign({}, vr, ((state.vehicleInput.make == '~') && rulesCustom), rulesNoneDecode);
            }
            state.validation = validate(state.vehicleInput, vr);
            if (!_.isEmpty(state.validation) && state.dataone.decode_method == 'none') {
                if (_.intersection(_.keys(state.validation), _.keys(rulesNoneDecode)).length > 0) {
                    state.showNoneDecodeForm = true;
                }
            }
        }
        function getSaleStatusOptions() {
            lib_1.Request.lookup.saleStatus(false, function (err, apiRes) {
                err ? VehicleAddEditControl.store.dispatch({ type: 0, error: err }) : VehicleAddEditControl.store.dispatch({ type: 5, data: apiRes.body.data });
            });
        }
        function getMakeOptions() {
            lib_1.Request.dataone.makesGroup({ body_type_code: state.vehicleInput.body_type_code }, function (err, apiRes) {
                err ? VehicleAddEditControl.store.dispatch({ type: 0, error: err }) : VehicleAddEditControl.store.dispatch({ type: 6, data: apiRes.body.data });
            });
        }
        function getModelOptions(d) {
            if (d.make && d.make != '') {
                lib_1.Request.dataone.modelsGroup(d, function (err, apiRes) {
                    err ? VehicleAddEditControl.store.dispatch({ type: 0, error: err }) : VehicleAddEditControl.store.dispatch({ type: 7, data: apiRes.body.data });
                });
            }
        }
        function getTrimOptions(d) {
            if (!_.isEmpty(d.make) && !_.isEmpty(d.model) && d.year && d.year > 1980) {
                lib_1.Request.dataone.trims(d, function (err, apiRes) {
                    err ? VehicleAddEditControl.store.dispatch({ type: 0, error: err }) : VehicleAddEditControl.store.dispatch({ type: 8, data: apiRes.body.data });
                });
            }
        }
        function getVehicleOptions(vehicle_id) {
            if (vehicle_id != null) {
                request.get(lib_1.Global.cruzApiBaseUrl + '/dataone/options').query({ 'vehicle_id': vehicle_id })
                    .timeout(cfg.superagent.timeout.normal)
                    .end(function (err, apiRes) {
                    err ? VehicleAddEditControl.store.dispatch({ type: 0, error: err }) : VehicleAddEditControl.store.dispatch({ type: 12, data: apiRes.body.data });
                });
            }
            else {
                state.dataone.options.vehicle_option = null;
                state.vehicleInput.option_id_list = [];
                return state;
            }
        }
        function getNoneDecodeOptions() {
            lib_1.Request.dataone.noneDecodeOptions(function (err, apiRes) {
                err ? VehicleAddEditControl.store.dispatch({ type: 0, error: err }) : VehicleAddEditControl.store.dispatch({ type: 43, data: apiRes.body.data });
            });
        }
        function getColorOptions(d) {
            if (d.year != null || !_.isEmpty(d.vehicle_id)) {
                lib_1.Request.dataone.exteriorColors(d, function (err, apiRes) {
                    err ? VehicleAddEditControl.store.dispatch({ type: 0, error: err }) : VehicleAddEditControl.store.dispatch({ type: 9, data: apiRes.body.data });
                });
                lib_1.Request.dataone.interiorColors(d, function (err, apiRes) {
                    err ? VehicleAddEditControl.store.dispatch({ type: 0, error: err }) : VehicleAddEditControl.store.dispatch({ type: 10, data: apiRes.body.data });
                });
                lib_1.Request.dataone.roofColors(d, function (err, apiRes) {
                    err ? VehicleAddEditControl.store.dispatch({ type: 0, error: err }) : VehicleAddEditControl.store.dispatch({ type: 11, data: apiRes.body.data });
                });
            }
        }
        function reverseDecode() {
            var q = _.pick(state.vehicleInput, ['body_type_code', 'make', 'model', 'year']);
            if (isHD() == true) {
                state.is_decoding = true;
                lib_1.Request.dataone.reverseDecode(_.assign(q, { is_hd: 1 }), function (err, apiRes) {
                    err ? VehicleAddEditControl.store.dispatch({ type: 0, error: err }) : VehicleAddEditControl.store.dispatch({ type: 30, data: apiRes.body.data });
                });
            }
            else if (!_.isEmpty(state.vehicleInput.trim)) {
                state.is_decoding = true;
                lib_1.Request.dataone.reverseDecode(_.assign(q, { trim: state.vehicleInput.trim }), function (err, apiRes) {
                    err ? VehicleAddEditControl.store.dispatch({ type: 0, error: err }) : VehicleAddEditControl.store.dispatch({ type: 30, data: apiRes.body.data });
                });
            }
        }
        function getDecodedTrimOptions() {
            var d = {
                vehicle_list: [],
                select_list: []
            };
            var x = _.find(state.dataone.vinDecodeData.model_list, { 'model': state.vehicleInput.model });
            if (x) {
                var y = _.find(x.trim_list, { 'trim': state.vehicleInput.trim });
                if (y) {
                    return _.pick(y, ['vehicle_list', 'select_list']);
                }
                else {
                    return d;
                }
            }
            else {
                return d;
            }
        }
        function resolveVehicleId() {
            var vList = state.dataone.options.vehicle_list;
            var sList = state.dataone.options.select_list;
            var xdata = state.vehicleInput.xdata || {};
            var xdataKeys = [];
            var vehicle_id = null;
            if (_.size(vList) > 1 && !_.isEmpty(_.get(xdata, 'style'))) {
                vList = _.reject(vList, function (v) { return v.style != xdata.style; });
                if (_.size(vList) == 1) {
                    var v_1 = _.first(vList);
                    vehicle_id = v_1.vehicle_id;
                    _.each(sList, function (s) {
                        if (s.id != 'style') {
                            if (['transmission_list', 'engine_list'].includes(s.key)) {
                                if (_.size(v_1[s.key]) == 1) {
                                    xdata[s.id] = _.first(v_1[s.key])[s.id];
                                }
                            }
                            else if (v_1[s.id] != null) {
                                xdata[s.id] = v_1[s.id].toString();
                            }
                        }
                    });
                }
                else {
                    _.each(sList, function (s) {
                        if (s.id != 'style') {
                            var x = _.chain(vList).map(s.id).uniq().value();
                            if (_.size(x) == 1) {
                                xdata[s.id] = _.first(x);
                            }
                        }
                    });
                }
            }
            var vi = state.vehicleInput;
            if (vehicle_id == null && _.size(vList) > 1 && vi.body_type_code != null) {
                var bt = getBodyTypeByCode(vi.body_type_code, Cruz.Lookup.Vehicle.body_type_search);
                if (bt != null) {
                    if ((xdata.body_subtype == null || xdata.doors == null)) {
                        if (bt.doors != null) {
                            xdata.doors = bt.doors;
                        }
                        if (bt.code != Math.floor(bt.code) && _.size(bt.value) == 1) {
                            xdata.body_subtype = _.first(bt.value);
                        }
                    }
                }
            }
            if (vehicle_id == null && _.size(vList) > 1 && xdata.doors != null && _.isEmpty(_.get(sList, 'doors'))) {
                vList = _.reject(vList, function (v) { return v.doors != xdata.doors; });
            }
            if (vehicle_id == null && _.size(vList) > 1 && xdata.body_subtype != null && _.isEmpty(_.get(sList, 'body_subtype'))) {
                vList = _.reject(vList, function (v) { return v.body_subtype != xdata.body_subtype; });
            }
            if (vehicle_id == null && _.size(vList) > 1 && _.size(sList) > 0 && !_.isEmpty(xdata)) {
                _.each(_.keys(xdata), function (id) {
                    var sl = _.find(sList, { id: id });
                    if (!_.isEmpty(sl)) {
                        if (id == 'trans_id' || id == 'engine_id') {
                            vList = _.reject(vList, function (v) {
                                var _a;
                                return (!_.find(v[sl.key], (_a = {}, _a[sl.id] = xdata[id], _a)));
                            });
                        }
                        else {
                            vList = _.reject(vList, function (v) { return v[sl.key] != xdata[id]; });
                        }
                        xdataKeys.push(id);
                        if (vList.length == 1) {
                            return false;
                        }
                    }
                });
            }
            if (vehicle_id == null && _.size(vList) == 1) {
                var v_2 = vList[0];
                vehicle_id = v_2.vehicle_id;
                var x = _.filter(sList, function (item) { return xdataKeys.includes(item.id) == false; });
                var oxData_1 = {};
                _.each(x, function (item) {
                    var _a;
                    var d = v_2[item.key];
                    if (!_.isEmpty(d) && d.length == 1) {
                        _.assign(oxData_1, (_a = {}, _a[item.id] = d[0][item.id], _a));
                    }
                });
                _.extend(state.vehicleInput.xdata, oxData_1);
            }
            state.vehicleInput.vehicle_id = vehicle_id;
            if (!isHD() && vehicle_id != null && vehicle_id > 0) {
                getVehicleOptions(vehicle_id);
            }
        }
        function updateVehicleStores() {
            user_1.MyVehicle.store && user_1.MyVehicle.store.dispatch({ type: 6, data: state.vehicle });
            __1.VehicleSearchControl.store && __1.VehicleSearchControl.store.dispatch({ type: 22, data: state.vehicle });
        }
        function reloadMyVehicles() {
            user_1.MyVehicle.store && user_1.MyVehicle.store.dispatch({ type: 2 });
        }
        function isHD() {
            if (state.vehicleInput.body_type_code == null) {
                return false;
            }
            else {
                var x = _.find(Cruz.Lookup.Vehicle.body_type_search, { code: state.vehicleInput.body_type_code });
                return (x && x.is_hd == true);
            }
        }
        function setOptions() {
            if (state.dataone.decode_method == 'vin') {
                if (state.vehicle.is_hd) {
                    var x = _.find(state.dataone.vinDecodeData.model_list, { 'model': state.vehicleInput.model });
                    if (x && x.hd) {
                        _.assign(state.dataone.options, { is_hd: true }, x.hd);
                    }
                }
                else {
                    var x = _.find(_.find(state.dataone.vinDecodeData.model_list, { 'model': state.vehicleInput.model }).trim_list, { 'trim': state.vehicleInput.trim });
                    state.dataone.options.select_list = (x && x.select_list) ? x.select_list : [];
                    state.dataone.options.vehicle_list = (x && x.vehicle_list) ? x.vehicle_list : [];
                }
            }
            else if (state.dataone.decode_method == 'reverse' && !_.isEmpty(state.dataone.reverseDecodeData)) {
                _.assign(state.dataone.options, _.pick(state.dataone.reverseDecodeData, ['select_list', 'vehicle_list', 'transmission_list']));
            }
            else if (!_.isEmpty(state.noneDecodeOptions)) {
                _.assign(state.dataone.options, _.pick(state.noneDecodeOptions, ['exterior_color', 'interior_color']));
            }
        }
        function isDirty() {
            state.is_dirty = !_.isEqual(_.omit(state.vehicleInput, 'upload_file_list'), state.prevVehicleInput);
            return;
        }
        switch (action.type) {
            case 0: {
                state.is_working = false;
                state.is_decoding = false;
                lib_1.util.setToastMessage('error', _.get(action.error, 'response.body.error.detail'));
                return state;
            }
            case 1: {
                getSaleStatusOptions();
                state.is_loading = false;
                return state;
            }
            case 2: {
                state = _.assign({}, state, _.pickBy(action.data, _.identity));
                state.vehicleInput = _.assign({}, state.vehicle);
                state.prevVehicleInput = _.memoize(_.assign)(state.vehicle);
                setOptions();
                state.is_loading = false;
                var ss = _.find(Cruz.Lookup.Vehicle.sale_status_list, { id: state.vehicleInput.sale_status_id });
                if (ss && ss.price_required) {
                    rules['price'] = { presence: { message: '^Price is required.', allowEmpty: false } };
                }
                return state;
            }
            case 3: {
                state.dataone = action.data.dataone;
                setOptions();
                valid();
                return state;
            }
            case 14: {
                if (action.data) {
                    state.validationServer = { vin: 'This VIN has been registered.' };
                }
                else {
                    state.validationServer = {};
                }
                return state;
            }
            case 13: {
                var vin = tgt.value || '';
                vin = vin.trim().toUpperCase();
                _.assign(state.vehicleInput, { vin: vin });
                if (vin.length >= 17) {
                    state.is_decoding = true;
                    lib_1.Request.dataone.vinDecode(vin, _.get(state, 'vehicle._id'), function (err, apiRes) {
                        err ? VehicleAddEditControl.store.dispatch({ type: 0, error: err }) : VehicleAddEditControl.store.dispatch({ type: 31, data: apiRes.body.data });
                    });
                }
                else {
                    state.dataone.decode_method = null;
                    if (!_.isEmpty(state.dataone.vinDecodeData)) {
                        state.dataone.vinDecodeData = {};
                        state.vehicleInput = _.omit(state.vehicleInput, keysVehicle);
                        state.dataone.options = _.pick(state.dataone.options, ['body_type']);
                    }
                }
                isDirty();
                state.validation && valid();
                return state;
            }
            case 31: {
                state.is_decoding = false;
                var d = action.data;
                state.dataone.vinDecodeData = _.assign({}, d);
                if (_.isEmpty(d)) {
                    state.dataone.decode_method = null;
                    state.vehicleInput = _.omit(state.vehicleInput, keysVehicle);
                    state.dataone.options = _.pick(state.dataone.options, 'body_type');
                }
                else {
                    state.dataone.decode_method = 'vin';
                    var v = {
                        body_type_code: d.body_type_code,
                        make: d.make,
                        model: _.size(d.model_list) == 1 ? d.model_list[0].model : '',
                        year: d.year,
                        trim: '',
                        is_hd: Boolean(d.is_hd),
                        xdata: {},
                        ydata: {}
                    };
                    state.dataone.options = {
                        is_hd: Boolean(d.is_hd),
                        make: { all: [v.make] },
                        model: { all: _.map(d.model_list, function (item) { return item.model; }) },
                        trim: []
                    };
                    _.assign(state.vehicleInput, v);
                    if (!_.isEmpty(v.model)) {
                        if (d.is_hd) {
                            _.assign(state.dataone.options, d.model_list[0].hd);
                        }
                        else {
                            state.dataone.options.trim = _.map(d.model_list[0].trim_list, function (t) { return t.trim; });
                            if (_.size(state.dataone.options.trim) == 1) {
                                state.vehicleInput.trim = _.first(state.dataone.options.trim);
                            }
                        }
                    }
                    var hasReference = !_.isEmpty(d.reference);
                    if (hasReference) {
                        state.vehicleInput = _.assign({}, state.vehicleInput, d.reference);
                        if (!isHD()) {
                            getVehicleOptions(state.vehicleInput.vehicle_id);
                        }
                    }
                    if (_.isEmpty(state.vehicleInput.trim)) {
                        state.vehicleInput = _.omit(state.vehicleInput, ['vehicle_id', 'exterior_color', 'interior_color', 'roof_color', 'option_id_list', 'is_hd']);
                        state.vehicleInput.xdata = {};
                    }
                    else {
                        _.assign(state.dataone.options, getDecodedTrimOptions());
                        if (!hasReference) {
                            resolveVehicleId();
                        }
                        getColorOptions(_.pick(state.vehicleInput, ['make', 'model', 'year', 'trim', 'vehicle_id']));
                    }
                }
                state.validation && valid();
                return state;
            }
            case 4: {
                state.vehicleInput = _.assign({}, _.omit(state.vehicleInput, _.without(keysVehicle, 'body_type_code')), { body_type_code: Number(tgt.value), xdata: {}, ydata: {} });
                state.dataone.options.make = {};
                state.dataone.options.model = {};
                state.dataone.options.trim = [];
                state.dataone.options.exterior_color = [];
                state.dataone.options.select_list = [];
                state.noneDecodeOptions = {};
                var x = _.find(Cruz.Lookup.Vehicle.body_type_search, { code: state.vehicleInput.body_type_code });
                state.dataone.options.is_hd = (x && x.is_hd && x.is_hd == true);
                state.vehicleInput.is_hd = state.dataone.options.is_hd;
                getMakeOptions();
                isDirty();
                return state;
            }
            case 6: {
                state.dataone.options.make = action.data;
                return state;
            }
            case 15: {
                state.vehicleInput = _.assign({}, _.omit(state.vehicleInput, _.without(keysVehicle, 'body_type_code', 'make', 'year')), { make: tgt.value, xdata: {}, ydata: {} });
                state.dataone.options = _.pick(state.dataone.options, ['body_type', 'make', 'year']);
                state.noneDecodeOptions = {};
                if (!_.isEmpty(tgt.value) && tgt.value != '~') {
                    getModelOptions(_.pick(state.vehicleInput, ['body_type_code', 'make']));
                }
                else if (tgt.value == '~') {
                    state.vehicleInput.model = '~';
                    state.dataone.decode_method = 'none';
                    state.dataone.options.trim = null;
                    if (_.isEmpty(state.noneDecodeOptions)) {
                        getNoneDecodeOptions();
                    }
                    else {
                        state.dataone.options.exterior_color = state.noneDecodeOptions.exterior_color;
                        state.dataone.options.interior_color = state.noneDecodeOptions.interior_color;
                    }
                }
                if (tgt.value != '~') {
                    state.vehicleInput.custom = {};
                }
                isDirty();
                state.validation && valid();
                return state;
            }
            case 7: {
                state.dataone.options.model = action.data;
                if (_.size(state.dataone.options.model.all) == 1) {
                    state.vehicleInput.model = _.first(state.dataone.options.model.all);
                }
                return state;
            }
            case 16: {
                state.vehicleInput = _.assign({}, _.omit(state.vehicleInput, _.without(keysVehicle, 'body_type_code', 'make', 'model', 'year')), { model: tgt.value, xdata: {}, ydata: {} });
                state.noneDecodeOptions = {};
                if (isHD() == true) {
                    if (state.dataone.decode_method == 'vin') {
                        var x = null;
                        x = _.find(state.dataone.vinDecodeData.model_list, { model: state.vehicleInput.model });
                        if (!_.isEmpty(x)) {
                            _.assign(state.dataone.options, x.hd);
                        }
                        resolveVehicleId();
                    }
                    else {
                        var year = state.vehicleInput.year;
                        if (year && year > 1900 && year <= moment().year() + 1) {
                            reverseDecode();
                        }
                    }
                    getColorOptions(_.pick(state.vehicleInput, ['make', 'model', 'year']));
                }
                else {
                    if (state.dataone.decode_method == 'vin') {
                        if (_.isEmpty(tgt.value)) {
                            state.dataone.options.trim = [];
                        }
                        else {
                            var trim_list = _.map(_.find(state.dataone.vinDecodeData.model_list, { model: tgt.value })['trim_list'], function (item) { return item.trim; });
                            state.dataone.options.trim = trim_list;
                            if (_.size(trim_list) == 1) {
                                state.vehicleInput.trim = _.first(trim_list);
                                state.dataone.options = _.assign({}, state.dataone.options, getDecodedTrimOptions());
                                resolveVehicleId();
                            }
                            getColorOptions(_.pick(state.vehicleInput, ['make', 'model', 'year', 'trim']));
                        }
                    }
                    else {
                        state.dataone.options = _.pick(state.dataone.options, ['body_type', 'make', 'model', 'year']);
                        if (!_.isEmpty(tgt.value) && tgt.value != '~' && state.vehicleInput.year) {
                            var q = _.pick(state.vehicleInput, ['make', 'model', 'year']);
                            getTrimOptions(q);
                            getColorOptions(q);
                        }
                        else if (tgt.value == '~') {
                            state.dataone.decode_method = 'none';
                            if (_.isEmpty(state.noneDecodeOptions)) {
                                getNoneDecodeOptions();
                            }
                            else {
                                state.dataone.options.exterior_color = state.noneDecodeOptions.exterior_color;
                                state.dataone.options.interior_color = state.noneDecodeOptions.interior_color;
                            }
                        }
                    }
                }
                isDirty();
                state.validation && valid();
                return state;
            }
            case 17: {
                var keys = ['body_type_code', 'make', 'model', 'year'];
                var year = Number(tgt.value);
                state.vehicleInput = _.assign({}, _.omit(state.vehicleInput, _.without.apply(_, __spreadArrays([keysVehicle], keys))), { year: year, xdata: {}, ydata: {} });
                if (year && year > 1900 && year <= moment().year() + 1) {
                    state.dataone.options.select_list = [];
                    state.vehicleInput.xdata = {};
                    if (year > 1980 && state.vehicleInput.make != '~' && state.vehicleInput.model != '~') {
                        if (!_.isEmpty(state.vehicleInput.make) && !_.isEmpty(state.vehicleInput.model)) {
                            var q = _.pick(state.vehicleInput, keys);
                            if (isHD()) {
                                reverseDecode();
                            }
                            else {
                                getTrimOptions(q);
                            }
                            getColorOptions(q);
                        }
                    }
                    else {
                        state.dataone.decode_method = 'none';
                        state.dataone.options = _.omit(state.dataone.options, ['trim', 'select_list', 'vehicle_list', 'transmission_list']);
                        if (_.isEmpty(state.noneDecodeOptions)) {
                            getNoneDecodeOptions();
                        }
                        else {
                            state.dataone.options.exterior_color = state.noneDecodeOptions.exterior_color;
                            state.dataone.options.interior_color = state.noneDecodeOptions.interior_color;
                        }
                    }
                }
                isDirty();
                state.validation && valid();
                return state;
            }
            case 8: {
                state.dataone.options.trim = action.data;
                if (state.dataone.options.trim == null || state.dataone.options.trim.length == 0) {
                    state.dataone.decode_method = 'none';
                    var omitKeys = _.union(_.without(keysVehicle, 'body_type_code', 'make', 'model', 'year'));
                    state.vehicleInput = _.omit(state.vehicleInput, omitKeys);
                    state.dataone.options.vehicle_list = [];
                    state.dataone.options.select_list = [];
                    if (_.isEmpty(state.noneDecodeOptions)) {
                        getNoneDecodeOptions();
                    }
                }
                else {
                    var omitKeys = _.union(_.without(keysVehicle, 'body_type_code', 'make', 'model', 'year'), keysNoneDecode);
                    state.vehicleInput = _.omit(state.vehicleInput, omitKeys);
                    if (state.dataone.decode_method != 'vin') {
                        state.dataone.decode_method = 'reverse';
                    }
                    if (state.dataone.options.trim.length == 1) {
                        state.vehicleInput.trim = state.dataone.options.trim[0];
                        getColorOptions(_.pick(state.vehicleInput, ['make', 'model', 'year', 'trim']));
                        if (state.dataone.decode_method == 'vin') {
                            _.assign(state.dataone.options, getDecodedTrimOptions());
                        }
                        else {
                            state.dataone.options.select_list = [];
                            state.dataone.options.vehicle_list = [];
                        }
                    }
                }
                reverseDecode();
                return state;
            }
            case 18: {
                if (tgt.type == 'text') {
                    _.assign(state.vehicleInput, { trim: tgt.value });
                }
                else {
                    var keys = ['body_type_code', 'make', 'model', 'year', 'trim', 'exterior_color', 'interior_color'];
                    state.vehicleInput = _.assign({}, _.omit(state.vehicleInput, _.without.apply(_, __spreadArrays([keysVehicle], keys))), { trim: tgt.value });
                    state.dataone.options = _.pick(state.dataone.options, ['body_type', 'make', 'model', 'year', 'trim']);
                    getColorOptions(_.pick(state.vehicleInput, ['make', 'model', 'year', 'trim']));
                    if (state.dataone.decode_method == 'vin') {
                        _.assign(state.dataone.options, getDecodedTrimOptions());
                    }
                    else {
                        state.dataone.options = _.omit(state.dataone.options, ['vehicle_list', 'select_list']);
                        state.vehicleInput.xdata = {};
                        reverseDecode();
                    }
                    resolveVehicleId();
                }
                isDirty();
                return state;
            }
            case 12: {
                _.assign(state.dataone.options, { 'vehicle_option': action.data });
                return state;
            }
            case 23: {
                if (tgt) {
                    if (tgt.checked) {
                        state.vehicleInput.option_id_list = _.union(state.vehicleInput.option_id_list, [Number(tgt.value)]);
                    }
                    else {
                        state.vehicleInput.option_id_list = _.without(state.vehicleInput.option_id_list, Number(tgt.value));
                    }
                }
                isDirty();
                return state;
            }
            case 30: {
                state.is_decoding = false;
                if (_.isEmpty(action.data)) {
                    state.dataone.decode_method = 'none';
                    state.dataone.options = _.omit(state.dataone.options, ['select_list', 'vehicle_list', 'transmission_list']);
                    if (!isHD()) {
                        getNoneDecodeOptions();
                    }
                }
                else {
                    state.dataone.decode_method = 'reverse';
                    state.dataone.reverseDecodeData = action.data;
                    _.assign(state.dataone.options, _.pick(action.data, ['select_list', 'vehicle_list', 'transmission_list']));
                    if (state.dataone.reverseDecodeData.vehicle_list && state.dataone.reverseDecodeData.vehicle_list.length == 1) {
                        state.vehicleInput.vehicle_id = state.dataone.reverseDecodeData.vehicle_list[0].vehicle_id;
                        if (!isHD()) {
                            getVehicleOptions(state.vehicleInput.vehicle_id);
                        }
                    }
                }
                return state;
            }
            case 9: {
                state.dataone.options.exterior_color = action.data;
                if (_.size(state.dataone.options.exterior_color) == 1) {
                    state.vehicleInput.exterior_color = state.dataone.options.exterior_color[0];
                }
                else {
                    state.vehicleInput.exterior_color = _.find(state.dataone.options.exterior_color, { ext_color_id: _.get(state.vehicleInput, 'exterior_color.ext_color_id') }) || null;
                }
                return state;
            }
            case 19: {
                if (!_.isEmpty(tgt.value)) {
                    if (lib_1.util.isNumeric(tgt.value)) {
                        state.vehicleInput.exterior_color = _.find(state.dataone.options.exterior_color, { 'ext_color_id': Number(tgt.value) });
                    }
                    else {
                        state.vehicleInput.exterior_color = { 'color_group': tgt.value };
                    }
                }
                else {
                    state.vehicleInput = _.omit(state.vehicleInput, 'exterior_color');
                }
                isDirty();
                state.validation && valid();
                return state;
            }
            case 10: {
                state.dataone.options.interior_color = action.data;
                if (_.size(state.dataone.options.interior_color) == 1) {
                    state.vehicleInput.interior_color = state.dataone.options.interior_color[0];
                }
                else {
                    state.vehicleInput.interior_color = _.find(state.dataone.options.interior_color, { int_color_id: _.get(state.vehicleInput, 'interior_color.int_color_id') }) || null;
                }
                return state;
            }
            case 20: {
                if (!_.isEmpty(tgt.value)) {
                    if (lib_1.util.isNumeric(tgt.value)) {
                        state.vehicleInput.interior_color = _.find(state.dataone.options.interior_color, { 'int_color_id': Number(tgt.value) });
                    }
                    else {
                        state.vehicleInput.interior_color = { 'color_group': tgt.value };
                    }
                }
                else {
                    state.vehicleInput = _.omit(state.vehicleInput, 'interior_color');
                }
                isDirty();
                return state;
            }
            case 11: {
                state.dataone.options.roof_color = action.data;
                if (_.size(state.dataone.options.roof_color) == 1) {
                    state.vehicleInput.roof_color = state.dataone.options.roof_color[0];
                }
                else {
                    state.vehicleInput.roof_color = _.find(state.dataone.options.roof_color, { roof_color_id: _.get(state.vehicleInput, 'roof_color.roof_color_id') }) || null;
                }
                return state;
            }
            case 21: {
                if (!_.isEmpty(tgt.value)) {
                    if (lib_1.util.isNumeric(tgt.value)) {
                        state.vehicleInput.roof_color = _.find(state.dataone.options.roof_color, { 'roof_color_id': Number(tgt.value) });
                    }
                    else {
                        state.vehicleInput.roof_color = { 'color_group': tgt.value };
                    }
                }
                else {
                    state.vehicleInput = _.omit(state.vehicleInput, 'roof_color');
                }
                isDirty();
                return state;
            }
            case 22: {
                if (_.isEmpty(tgt.value)) {
                    state.vehicleInput.xdata = _.omit(state.vehicleInput.xdata, tgt.id);
                }
                else {
                    state.vehicleInput.xdata = _.assign({}, state.vehicleInput.xdata, (_a = {}, _a[tgt.id] = lib_1.util.isNumeric(tgt.value) ? Number(tgt.value) : tgt.value, _a));
                }
                isDirty();
                resolveVehicleId();
                return state;
            }
            case 29: {
                if (_.isNil(action.data)) {
                    state.vehicleInput.mileage = null;
                }
                else {
                    state.vehicleInput.mileage = numeral(action.data).value();
                }
                isDirty();
                state.validation && valid();
                return state;
            }
            case 49: {
                state.vehicleInput.custom[tgt.id] = tgt.value;
                isDirty();
                state.validation && valid();
                return state;
            }
            case 24: {
                state.vehicleInput.highlight = tgt.value;
                isDirty();
                return state;
            }
            case 25: {
                state.vehicleInput.description = tgt.value;
                isDirty();
                return state;
            }
            case 26: {
                if (tgt.value && tgt.value.trim() != '') {
                    state.vehicleInput.warranty = tgt.value;
                }
                else {
                    state.vehicleInput = _.omit(state.vehicleInput, 'warranty');
                }
                isDirty();
                return state;
            }
            case 5: {
                state = _.assign({}, state, { 'sale_status_options': action.data });
                return state;
            }
            case 27: {
                state.vehicleInput.sale_status_id = Number(tgt.value);
                var ss = _.find(Cruz.Lookup.Vehicle.sale_status_list, { id: state.vehicleInput.sale_status_id });
                if (ss && ss.price_required) {
                    rules['price'] = { presence: { message: '^Price is required.', allowEmpty: false } };
                }
                else {
                    delete rules['price'];
                    state.vehicleInput.price = null;
                }
                isDirty();
                state.validation && valid();
                return state;
            }
            case 28: {
                if (tgt.value && tgt.value != '') {
                    state.vehicleInput.price = Number(tgt.value.replace(/[^0-9]/g, ''));
                }
                else {
                    state.vehicleInput.price = null;
                }
                isDirty();
                state.validation && valid();
                return state;
            }
            case 43: {
                state.noneDecodeOptions = action.data;
                if (_.isEqual(state.dataone.options.exterior_color, state.noneDecodeOptions.exterior_color) == false) {
                    state.dataone.options.exterior_color = state.noneDecodeOptions.exterior_color;
                }
                if (_.isEqual(state.dataone.options.interior_color, state.noneDecodeOptions.interior_color) == false) {
                    state.dataone.options.interior_color = state.noneDecodeOptions.interior_color;
                }
                return state;
            }
            case 44: {
                state.showNoneDecodeForm = !state.showNoneDecodeForm;
                return state;
            }
            case 45: {
                if (tgt.value && tgt.value != '') {
                    state.vehicleInput.ydata.body_type_code = Number(tgt.value);
                    var x = getBodyTypeByCode(Number(tgt.value), state.noneDecodeOptions.body_type);
                    if (x && x.doors) {
                        state.vehicleInput.ydata.doors = x.doors;
                    }
                }
                else {
                    state.vehicleInput.ydata.body_type_code = null;
                }
                isDirty();
                state.validation && valid();
                return state;
            }
            case 48: {
                if (state.vehicleInput.ydata.feature_tag_list == null) {
                    state.vehicleInput.ydata.feature_tag_list = [];
                }
                if (tgt.checked) {
                    state.vehicleInput.ydata.feature_tag_list.push(tgt.value);
                }
                else {
                    state.vehicleInput.ydata.feature_tag_list = _.without(state.vehicleInput.ydata.feature_tag_list, tgt.value);
                }
                isDirty();
                return state;
            }
            case 46: {
                if (tgt.value && tgt.value != '') {
                    state.vehicleInput.ydata[tgt.id] = Number(tgt.value);
                }
                else {
                    state.vehicleInput.ydata[tgt.id] = null;
                }
                isDirty();
                state.validation && valid();
                return state;
            }
            case 47: {
                state.vehicleInput.ydata[tgt.id] = tgt.value;
                isDirty();
                state.validation && valid();
                return state;
            }
            case 39: {
                action.event.preventDefault();
                valid();
                if (_.isEmpty(state.validation) && _.isEmpty(state.validationServer) && !state.is_working) {
                    lib_1.Auth.isAuthed(function (isAuthed) {
                        if (isAuthed) {
                            state.is_working = true;
                            if (!_.isEmpty(state.vehicleInput.ydata) && state.vehicleInput.ydata.feature_tag_list && state.vehicleInput.ydata.feature_tag_list.length > 0) {
                                var ftl = _.map(state.noneDecodeOptions.feature_tag.filter(function (tag) { return state.vehicleInput.year >= tag.start_year; }), 'name');
                                state.vehicleInput.ydata.feature_tag_list = _.intersection(ftl, state.vehicleInput.ydata.feature_tag_list);
                            }
                            if (!_.isEmpty(state.vehicleInput._id)) {
                                request.put(lib_1.Global.cruzApiBaseUrl + '/vehicle/' + state.vehicleInput._id)
                                    .set(lib_1.Auth.authHeader())
                                    .timeout(cfg.superagent.timeout.normal)
                                    .send(state.vehicleInput)
                                    .end(function (err, apiRes) {
                                    if (err) {
                                        VehicleAddEditControl.store.dispatch({ type: 0, error: err });
                                    }
                                    else {
                                        lib_1.Global.onSubmitSuccess('vehicleUpdate');
                                        VehicleAddEditControl.store.dispatch({ type: 41, data: apiRes.body.data });
                                    }
                                });
                            }
                            else {
                                request.post(lib_1.Global.cruzApiBaseUrl + '/vehicle')
                                    .set(lib_1.Auth.authHeader())
                                    .timeout(cfg.superagent.timeout.normal)
                                    .send(state.vehicleInput)
                                    .end(function (err, apiRes) {
                                    if (err) {
                                        VehicleAddEditControl.store.dispatch({ type: 0, error: err });
                                    }
                                    else {
                                        lib_1.Global.onSubmitSuccess('vehicleAdd');
                                        VehicleAddEditControl.store.dispatch({ type: 40, data: apiRes.body.data });
                                    }
                                });
                            }
                        }
                        else {
                            lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                        }
                    });
                }
                else if (!_.isEmpty(state.validation)) {
                    lib_1.util.setFocus('#' + _.last(_.keys(state.validation)[0].split('.')));
                }
                return state;
            }
            case 41: {
                state.vehicle = _.pickBy(action.data, _.identity);
                state.vehicleInput = _.assign({}, _.pick(state.vehicleInput, 'upload_file_list'), action.data);
                state.prevVehicleInput = _.memoize(_.assign)(state.vehicle);
                if (state.vehicleInput.upload_file_list && state.vehicleInput.upload_file_list.length > 0) {
                    state.executeUpload = true;
                }
                else {
                    state.is_working = false;
                    state.is_dirty = false;
                    lib_1.util.setToastMessage('success', 'Successfully Saved');
                }
                updateVehicleStores();
                return state;
            }
            case 40: {
                state.vehicle = _.pickBy(action.data, _.identity);
                state.vehicleInput = _.assign({}, state.vehicle, _.pick(state.vehicleInput, 'upload_file_list'));
                state.prevVehicleInput = _.memoize(_.assign)(state.vehicle);
                getVehicleForUpdate(state.vehicle._id, function (err, result) {
                    VehicleAddEditControl.store.dispatch({ type: 3, data: result });
                });
                state.is_create = true;
                if (_.size(state.vehicleInput.upload_file_list) > 0) {
                    state.executeUpload = true;
                }
                else {
                    lib_1.Global.refreshMyProfile();
                    reloadMyVehicles();
                    state.is_working = false;
                    state.is_dirty = false;
                    lib_1.util.setToastMessage('success', 'Successfully Saved');
                    setTimeout(function () {
                        lib_1.Global.history.replace(pages_1.pages.vehicle_add_edit.path + '?_id=' + state.vehicle._id);
                    }, 10);
                }
                return state;
            }
            case 37: {
                if (action.data) {
                    state.is_working = true;
                }
                return state;
            }
            case 32: {
                state.vehicleInput.upload_file_list = action.data;
                return state;
            }
            case 34: {
                if (action.data) {
                    state.vehicleInput.photo_list = action.data;
                }
                state.vehicleInput.upload_file_list = [];
                state.vehicle.photo_list = action.data;
                state.is_working = false;
                state.is_dirty = false;
                state.executeUpload = false;
                if (state.is_create) {
                    state.is_create = false;
                    reloadMyVehicles();
                }
                else {
                    updateVehicleStores();
                }
                lib_1.util.setToastMessage('success', 'Successfully Saved');
                setTimeout(function () {
                    lib_1.Global.history.replace(pages_1.pages.vehicle_add_edit.path + '?_id=' + state.vehicle._id);
                }, 10);
                lib_1.Global.refreshMyProfile();
                return state;
            }
            case 33: {
                lib_1.Auth.isAuthed(function (isAuthed) {
                    if (isAuthed) {
                        var id_list = action.data;
                        request.delete(lib_1.Global.cruzApiBaseUrl + '/vehicle/' + state.vehicleInput._id + '/photo')
                            .set(lib_1.Auth.authHeader())
                            .timeout(cfg.superagent.timeout.normal)
                            .send(id_list)
                            .end(function (err, apiRes) {
                            if (err) {
                                lib_1.util.handleError(lib_1.Request.getApiErrorCode(err));
                            }
                            else {
                                VehicleAddEditControl.store.dispatch({ type: 34, data: apiRes.body.data });
                            }
                        });
                    }
                    else {
                        lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                    }
                });
                return state;
            }
            case 35: {
                lib_1.Auth.isAuthed(function (isAuthed) {
                    if (isAuthed) {
                        state.is_working = true;
                        request.put(lib_1.Global.cruzApiBaseUrl + '/vehicle/' + state.vehicleInput._id + '/photo')
                            .set(lib_1.Auth.authHeader())
                            .timeout(cfg.superagent.timeout.normal)
                            .send(action.data)
                            .end(function (err, apiRes) {
                            if (err) {
                                lib_1.util.handleError(lib_1.Request.getApiErrorCode(err));
                            }
                            else {
                                VehicleAddEditControl.store.dispatch({ type: 36, data: apiRes.body.data });
                            }
                        });
                    }
                    else {
                        lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                    }
                });
                return state;
            }
            case 36: {
                state.is_working = false;
                state.vehicleInput.photo_list = action.data;
                state.vehicle.photo_list = action.data;
                updateVehicleStores();
                lib_1.util.setToastMessage('success', 'Successfully Saved');
                return state;
            }
            case 42: {
                state = _.assign({}, JSON.parse(JSON.stringify(VehicleAddEditControl.initState)));
                return state;
            }
            default: {
                return state;
            }
        }
    }, JSON.parse(JSON.stringify(VehicleAddEditControl.initState)));
})(VehicleAddEditControl = exports.VehicleAddEditControl || (exports.VehicleAddEditControl = {}));
