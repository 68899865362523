"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var _ = require("lodash");
var moment = require("moment");
var slugify_1 = require("slugify");
var cfg = require("../cfg");
var lib_1 = require("./lib");
var component_1 = require("./component");
var pages_1 = require("../routes/pages");
var Home;
(function (Home) {
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            var _this = _super.call(this, props) || this;
            _this.state = { spotted_list: null };
            return _this;
        }
        Page.prototype.componentDidMount = function () {
            var _this = this;
            lib_1.Request.spotted.search({ page_size: 2, page_number: 1, sort: 1 }, function (err, apiRes) {
                if (!err && apiRes.body.data) {
                    _this.setState({ spotted_list: apiRes.body.data });
                }
            });
            this.unsubscribeGlobal = lib_1.Global.store.subscribe(function () { _this.forceUpdate(); });
            this.unsubscribeStatistic = lib_1.Statistic.store.subscribe(function () { _this.forceUpdate(); });
            document.title = lib_1.util.title(pages_1.pages.home.title);
        };
        Page.prototype.componentWillUnmount = function () {
            this.unsubscribeGlobal && this.unsubscribeGlobal();
            this.unsubscribeStatistic && this.unsubscribeStatistic();
        };
        Page.prototype.signout = function () {
            lib_1.Auth.signout();
        };
        Page.prototype.renderMain = function (user) {
            var _this = this;
            try {
                return (React.createElement("div", { id: 'home-main', className: 'hp-bg' },
                    React.createElement("nav", { className: 'navbar navbar-expand-lg navbar-dark navbar-home' },
                        React.createElement("div", { className: 'container' },
                            React.createElement("div", { className: 'navbar-brand order-lg-0' }),
                            React.createElement("div", { className: 'd-flex align-self-stretch order-lg-2' },
                                React.createElement(component_1.Nav.NavUser, { user: user })),
                            React.createElement("div", { className: 'collapse navbar-collapse align-self-stretch order-lg-1', id: 'navbarNav' },
                                React.createElement("div", { className: 'nav navbar-nav' },
                                    React.createElement(component_1.Nav.NavMain, { user: user }))))),
                    React.createElement("div", { className: 'container mt-5' },
                        React.createElement("img", { src: '/img/logo/cruz.png', alt: 'CRUZ.com', className: 'hp-logo img-fluid pt-5' }),
                        React.createElement("h1", { className: 'hp-title pt-5' },
                            "Take Control",
                            React.createElement("sup", { className: 'c-trademark' }, "TM"),
                            " of Vehicle Ownership"),
                        React.createElement("p", { className: 'hp-lead' }, "Join the Midwest\u2019s fastest growing car community and prime owner-to-owner buying and selling network! Cruz\u00AE puts you in control of vehicle ownership."),
                        (!_.isEmpty(user))
                            ?
                                React.createElement("div", { className: 'mt-5' },
                                    React.createElement(react_router_dom_1.Link, { className: 'btn hp-btn c-btn-1 c-btn-round', to: pages_1.pages.my_cruz.path }, "My Cruz\u00AE"),
                                    React.createElement("div", { className: 'mt-2' },
                                        'Not ' + _.startCase(user.first_name + ' ' + user.last_name) + '? ',
                                        React.createElement("i", { className: 'fal fa-angle-double-right mx-1' }),
                                        React.createElement("button", { className: 'c-link c-link-light ml-1', onClick: function () { return _this.signout(); } }, "Sign Out")))
                            :
                                React.createElement("div", { className: 'mt-4' },
                                    React.createElement(react_router_dom_1.Link, { className: 'btn hp-btn c-btn-2 c-btn-round mr-2', to: pages_1.pages.register.path }, pages_1.pages.register.title),
                                    React.createElement(react_router_dom_1.Link, { className: 'btn hp-btn c-btn-1 c-btn-round', to: pages_1.pages.signin.path }, pages_1.pages.signin.title)))));
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.renderBuySell = function () {
            try {
                return (React.createElement("div", { id: 'home-buysell' },
                    React.createElement("div", { className: 'container pt-5' },
                        React.createElement("div", { className: 'text-center' },
                            React.createElement("h2", { className: 'c-title' }, "Buyer-to-Buyer Car Market"),
                            React.createElement("p", { className: 'c-font-size-l c-font-weight-medium pt-2' }, "Buy and sell your way when you choose to list your car for sale, not for sale, or open to offers. With Cruz\u00AE, you\u2019re in control.")),
                        React.createElement("div", { className: 'pt-3 pt-xl-0' }),
                        React.createElement("div", { className: 'row' },
                            React.createElement("div", { className: 'col-md-6 col-xl-3 order-1 pt-xl-3 pb-xl-5' },
                                React.createElement("h3", { className: 'c-title' }, "Buying"),
                                React.createElement("p", { className: 'pt-2' }, "Looking to buy? Cruz\u00AE is the place to snag a great deal from other awesome owners! Cruz\u00AE is a buyer-to-buyer network, so there\u2019s no dealer markup, letting you save big on your next family car, utility vehicle, dream car or restoration project."),
                                React.createElement("div", { className: 'c-font-weight-semi-bold' }, "Connect directly with other Cruz\u00AE members though our new messaging feature \u2026"),
                                React.createElement("ul", { className: 'mt-3' },
                                    React.createElement("li", null, "Ask questions"),
                                    React.createElement("li", null, "Negotiate prices"),
                                    React.createElement("li", null, "Request more photos"),
                                    React.createElement("li", null, "Get free Cruz\u00AE vehicle history reports")),
                                React.createElement(react_router_dom_1.Link, { to: pages_1.pages.messaging.path, className: 'btn c-btn-1 c-btn-round mt-2' }, "Messaging")),
                            React.createElement("div", { className: 'col-12 col-xl-6 order-3 order-xl-3 text-center align-self-end' },
                                React.createElement("img", { src: '/img/page/home/buy-sell.jpg', className: 'img-fluid' })),
                            React.createElement("div", { className: 'col-md-6 col-xl-3 order-2 order-xl-3 pt-3 pt-4 pt-md-0 pt-xl-3 pb-xl-4' },
                                React.createElement("h3", { className: 'c-title' }, "Selling"),
                                React.createElement("p", { className: 'pt-2' }, "Have you considered selling but aren\u2019t fully committed? No problem! List your car and see what happens! For the right price, you might be ready to let it go!"),
                                React.createElement("p", null, "Aren\u2019t selling right now? When you\u2019re ready, simply update your status and your vehicle is on the market!"),
                                React.createElement("div", { className: 'c-font-weight-semi-bold' }, "Cruz\u00AE provides all the selling tools you need ..."),
                                React.createElement("ul", { className: 'mt-3' },
                                    React.createElement("li", null, "Detailed manufacturer specs"),
                                    React.createElement("li", null, "Free vehicle history reports"),
                                    React.createElement("li", null, "Your very own portal to showcase your vehicle"),
                                    React.createElement("li", null, "Thousands of members")))))));
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.renderVehicle = function () {
            try {
                var c = 0;
                if (lib_1.Statistic.store != null && !_.isEmpty(lib_1.Statistic.store.getState().statistic)) {
                    c = lib_1.Statistic.store.getState().statistic.vehicle_count;
                }
                return (React.createElement("div", { id: 'home-about' },
                    React.createElement("div", { className: 'container pt-5 pb-5' },
                        React.createElement("h2", { className: 'c-title' }, "All About Cars"),
                        React.createElement("div", { className: 'row no-gutters pt-5' },
                            React.createElement("div", { className: 'col-lg-8' },
                                React.createElement("div", { className: 'd-flex mb-5' },
                                    React.createElement("div", { className: 'mr-4 c-flex-icon' },
                                        React.createElement(react_router_dom_1.Link, { to: pages_1.pages.vehicle_search.path, title: pages_1.pages.vehicle_search.title },
                                            React.createElement("img", { src: '/img/page/home/icon-community.png', className: 'img-fluid' }))),
                                    React.createElement("div", null,
                                        React.createElement("h3", { className: 'c-sub-title mb-2' }, "Vehicles"),
                                        React.createElement(react_router_dom_1.Link, { to: pages_1.pages.vehicle_search.path, className: 'btn c-btn-1 c-btn-round mt-2' }, "Search Vehicles"))),
                                React.createElement("div", { className: 'd-flex' },
                                    React.createElement("div", { className: 'mr-4 c-flex-icon' },
                                        React.createElement(react_router_dom_1.Link, { to: pages_1.pages.my_cruz.path, title: pages_1.pages.my_cruz.title },
                                            React.createElement("img", { src: '/img/page/home/icon-my-cruz.png', className: 'img-fluid' }))),
                                    React.createElement("div", null,
                                        React.createElement("h3", { className: 'c-sub-title mb-2' }, "My CRUZ\u00AE"),
                                        React.createElement("p", null, "Manage your cars, your way, in your personal vehicle portal. Not only can you see tons of manufacturer details and specs on your cars, you also get free vehicle history reports! Add photos, notes, and more."),
                                        React.createElement(react_router_dom_1.Link, { to: pages_1.pages.my_cruz.path, className: 'btn c-btn-1 c-btn-round mt-2 mb-2' }, pages_1.pages.my_cruz.title))))))));
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.renderStat = function (stat) {
            try {
                if (!_.isEmpty(_.get(stat, 'statistic'))) {
                    var s = stat.statistic;
                    return (React.createElement("div", { id: 'home-stat' },
                        React.createElement("div", { className: 'container pt-4 pb-4' },
                            React.createElement("div", { className: 'row c-sm-gutters mt-3' },
                                React.createElement("div", { className: 'col text-center pb-1' },
                                    React.createElement("div", { className: 'hs-value' }, s.user_count.toLocaleString()),
                                    React.createElement("div", { className: 'hs-name' }, "Members")),
                                React.createElement("div", { className: 'col text-center pb-1' },
                                    React.createElement("div", { className: 'hs-value' }, s.vehicle_count.toLocaleString()),
                                    React.createElement("div", { className: 'hs-name' },
                                        React.createElement(react_router_dom_1.Link, { to: pages_1.pages.vehicle_search.path, className: 'c-link c-link-light' }, pages_1.pages.vehicle_search.title))),
                                React.createElement("div", { className: 'col text-center pb-2' },
                                    React.createElement("div", { className: 'hs-value' }, s.spotted_count.toLocaleString()),
                                    React.createElement("div", { className: 'hs-name' },
                                        React.createElement(react_router_dom_1.Link, { to: pages_1.pages.spotted_search.path, className: 'c-link c-link-light' }, pages_1.pages.spotted_search.title)))))));
                }
                else {
                    return null;
                }
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.renderSpotted = function () {
            try {
                return (React.createElement("div", { id: 'home-spotted' },
                    React.createElement("div", { className: 'container pt-5' },
                        React.createElement("div", { className: 'row' },
                            React.createElement("div", { className: 'col-lg-6 d-none d-lg-flex align-items-end' },
                                React.createElement("img", { src: '/img/page/home/spotted.jpg', className: 'img-fluid' })),
                            React.createElement("div", { className: 'col-lg-6 pb-5' },
                                React.createElement("h2", { className: 'c-title' }, pages_1.pages.spotted_search.title),
                                React.createElement("div", { className: 'c-font-size-l c-font-size-semi-bold' }, "Spot it. Post it. Love it."),
                                React.createElement("p", { className: 'pt-3' }, "Get ready. You\u2019re about to experience your new favorite car community! Crazy and fun cars are spotted and posted daily \u2013 from beaters to muscle cars and beyond \u2013 snap a picture and post it! Like photos and leave comments on others to get in on the fun!"),
                                (this.state.spotted_list && this.state.spotted_list.length > 0)
                                    &&
                                        React.createElement("div", { className: 'row c-sm-gutters mb-2 mt-4' }, _.map(this.state.spotted_list, function (d) {
                                            var url = pages_1.pages.spotted_detail.path + '/' + slugify_1.default(d.location_name) + '/' + slugify_1.default(d.title) + '/' + d._id;
                                            return (React.createElement("div", { className: 'col-md-6 mb-3', key: d._id },
                                                React.createElement("div", { className: 'card' },
                                                    React.createElement(react_router_dom_1.Link, { to: url },
                                                        React.createElement("img", { className: 'img-fluid card-img-top', alt: d.title, src: cfg.photoBaseUrl[cfg.env].cdn + '/spotted/' + d.group + '/' + d._id + '/' + d.photo_list[0].id + '-md.jpg' })),
                                                    React.createElement("div", { className: 'card-body' },
                                                        React.createElement("h4", { className: 'card-title mb-1 ellipsis' },
                                                            React.createElement(react_router_dom_1.Link, { to: url, className: 'c-btn-link' }, d.title)),
                                                        React.createElement("div", { className: 'c-font-size-xs c-line-height-xs' },
                                                            React.createElement("div", null, (d.location && d.location.city && d.location.state) ? d.location.city + ', ' + d.location.state : d.location_name),
                                                            React.createElement("div", null,
                                                                "Posted ",
                                                                moment(d.date_create).fromNow()))))));
                                        })),
                                React.createElement(react_router_dom_1.Link, { to: pages_1.pages.spotted_search.path, className: 'btn c-btn-1 c-btn-round mt-2' }, "View Spotted"))))));
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.renderDeals = function () {
            try {
                return (React.createElement("div", { id: 'home-deal' },
                    React.createElement("div", { className: 'container pt-5' },
                        React.createElement("div", { className: 'row' },
                            React.createElement("div", { className: 'col-lg-6 pb-5' },
                                React.createElement("h2", { className: 'c-title mb-2' }, pages_1.pages.deal_search.title),
                                React.createElement("p", null, "Take advantage of specials and discounts you won\u2019t get anywhere else from local auto businesses!  From car washes, to remote start discounts, and even rock chip repair, Cruz\u00AE deals has you covered."),
                                React.createElement("h3", { className: 'c-sub-title mt-4' }, "Spotlight Deals"),
                                React.createElement("p", null, "Get in on the most popular deals among Cruz\u00AE members before they're gone."),
                                React.createElement("h3", { className: 'c-sub-title mt-3' }, "All Cruz\u00AE Deals"),
                                React.createElement("p", null, "Tons of awesome deals are waiting to be redeemed \u2013 scroll, click, and save away."),
                                React.createElement(react_router_dom_1.Link, { to: pages_1.pages.deal_search.path, className: 'btn c-btn-1 c-btn-round mt-3' }, "View Deals")),
                            React.createElement("div", { className: 'col-lg-6 align-self-end' },
                                React.createElement("img", { src: '/img/page/home/deals.jpg', className: 'img-fluid' }))))));
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.renderBottom = function (user) {
            var _this = this;
            try {
                return (React.createElement("div", { id: 'home-bottom', className: 'hp-bg' },
                    React.createElement("div", { className: 'container' },
                        React.createElement("img", { src: '/img/logo/cruz.png', alt: 'CRUZ.com', className: 'hp-logo img-fluid' }),
                        (_.isEmpty(user))
                            &&
                                React.createElement("div", { className: 'ml-lg-5' },
                                    React.createElement("h1", { className: 'hp-title pt-4' },
                                        "Take Control",
                                        React.createElement("sup", { className: 'c-trademark' }, "TM")),
                                    React.createElement("p", { className: 'hp-lead' }, "So, what are you waiting for? Get started on Cruz\u00AE today and gain access to all the fun, deals, and vehicle details you\u2019ve been missing!")),
                        (!_.isEmpty(user))
                            ?
                                React.createElement("div", { className: 'mt-4 ml-lg-5' },
                                    React.createElement(react_router_dom_1.Link, { className: 'btn hp-btn c-btn-1 c-btn-round', to: pages_1.pages.my_cruz.path }, "My Cruz\u00AE"),
                                    React.createElement("div", { className: 'mt-2 ml-4' },
                                        'Not ' + lib_1.Global.store.getState().user.first_name + ' ' + lib_1.Global.store.getState().user.last_name + '? ',
                                        React.createElement("button", { className: 'c-link c-link-light ml-1', onClick: function () { return _this.signout(); } }, "Sign Out")))
                            :
                                React.createElement("div", { className: 'mt-4 ml-lg-5' },
                                    React.createElement(react_router_dom_1.Link, { className: 'btn hp-btn c-btn-2 c-btn-round mr-3', to: pages_1.pages.register.path }, pages_1.pages.register.title),
                                    React.createElement(react_router_dom_1.Link, { className: 'btn hp-btn c-btn-1 c-btn-round', to: pages_1.pages.signin.path }, pages_1.pages.signin.title)))));
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.render = function () {
            var user = {};
            if (lib_1.Global != null && lib_1.Global.store != null) {
                user = lib_1.Global.store.getState().user;
            }
            var stat = {};
            if (lib_1.Statistic != null && lib_1.Statistic.store != null) {
                stat = lib_1.Statistic.store.getState();
            }
            return (React.createElement("div", { id: 'c-home' },
                !lib_1.util.isClient()
                    &&
                        lib_1.Schema.About(),
                this.renderMain(user),
                this.renderBuySell(),
                this.renderVehicle(),
                this.renderStat(stat),
                this.renderSpotted(),
                (lib_1.Global.store && lib_1.Global.store.getState().region_id != null && lib_1.Global.store.getState().region_id == 'siouxfalls')
                    &&
                        this.renderDeals(),
                this.renderBottom(user),
                React.createElement(component_1.Footer.Footer, null)));
        };
        return Page;
    }(React.Component));
    Home.Page = Page;
})(Home = exports.Home || (exports.Home = {}));
