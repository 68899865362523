"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var conformToMask = require('react-text-mask').conformToMask;
var createNumberMask = require('text-mask-addons/dist/createNumberMask').default;
var Mask;
(function (Mask) {
    var _a;
    var TypeEnum;
    (function (TypeEnum) {
        TypeEnum[TypeEnum["ZipCode"] = 1] = "ZipCode";
        TypeEnum[TypeEnum["Phone"] = 2] = "Phone";
        TypeEnum[TypeEnum["Price"] = 3] = "Price";
        TypeEnum[TypeEnum["Number"] = 4] = "Number";
        TypeEnum[TypeEnum["VehicleYear"] = 5] = "VehicleYear";
        TypeEnum[TypeEnum["UserCode"] = 6] = "UserCode";
        TypeEnum[TypeEnum["CreditCardNumber"] = 10] = "CreditCardNumber";
        TypeEnum[TypeEnum["CreditCardExpireDate"] = 11] = "CreditCardExpireDate";
        TypeEnum[TypeEnum["CreditCardCvc"] = 12] = "CreditCardCvc";
        TypeEnum[TypeEnum["Date"] = 13] = "Date";
        TypeEnum[TypeEnum["DateTime"] = 14] = "DateTime";
    })(TypeEnum = Mask.TypeEnum || (Mask.TypeEnum = {}));
    var priceMask = createNumberMask({
        prefix: '',
        suffix: ''
    });
    var numberMask = createNumberMask({
        prefix: '',
        suffix: ''
    });
    Mask.Type = (_a = {},
        _a[TypeEnum.ZipCode] = [/\d/, /\d/, /\d/, /\d/, /\d/],
        _a[TypeEnum.Phone] = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
        _a[TypeEnum.Price] = priceMask,
        _a[TypeEnum.Number] = numberMask,
        _a[TypeEnum.VehicleYear] = [/[1, 2]/, /[9, 0]/, /\d/, /\d/],
        _a[TypeEnum.UserCode] = [/[A-Za-z]/, /[A-Za-z]/, /[A-Za-z]/, /[A-Za-z]/, /[A-Za-z]/],
        _a[TypeEnum.CreditCardNumber] = [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
        _a[TypeEnum.CreditCardExpireDate] = [/[0-1]/, /\d/, '/', /\d/, /\d/],
        _a[TypeEnum.CreditCardCvc] = [/\d/, /\d/, /\d/],
        _a[TypeEnum.Date] = [/[0, 1]/, /\d/, '/', /[0-3]/, /\d/, '/', /[1-2]/, /\d/, /\d/, /\d/],
        _a[TypeEnum.DateTime] = [/[0, 1]/, /\d/, '/', /[0-3]/, /\d/, '/', /[1-2]/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ':', /[0-5]/, /\d/],
        _a);
    function UnmaskedValue(val, mask) {
        return conformToMask(val, Mask.Type[mask], { guide: false }).conformedValue;
    }
    Mask.UnmaskedValue = UnmaskedValue;
})(Mask = exports.Mask || (exports.Mask = {}));
