"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var request = require("superagent");
var jwt_decode = require("jwt-decode");
var moment = require("moment");
var _ = require("lodash");
var pages_1 = require("../../../routes/pages");
var component_1 = require("../../component");
var cfg = require("../../../cfg");
var lib_1 = require("../../lib");
var VerifyAccount;
(function (VerifyAccount) {
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                is_token_valid: true,
                is_token_expired: false,
                is_activating: false,
                user: {},
                has_error: false
            };
            return _this;
        }
        Page.prototype.componentDidMount = function () {
            var _this = this;
            document.title = lib_1.util.title(pages_1.pages.verify_account.title);
            var token = this.props.match.params.token;
            var d = null;
            try {
                d = jwt_decode(token);
                if (d) {
                    if (moment.unix(d['exp']).isAfter(moment())) {
                        this.setState({
                            token: token,
                            is_activating: true
                        }, function () {
                            _this.activate(token);
                        });
                    }
                    else {
                        this.setState({
                            token: token,
                            is_token_expired: true,
                        });
                    }
                }
                else {
                    this.setState({ is_token_valid: false });
                }
            }
            catch (err) {
                this.setState({ is_token_valid: false });
            }
        };
        Page.prototype.activate = function (tk) {
            var _this = this;
            request.patch(lib_1.Global.cruzApiBaseUrl + '/user/verify-account/' + this.state.token)
                .timeout(cfg.superagent.timeout.upload)
                .end(function (err, apiRes) {
                if (err) {
                    var derr = apiRes.body.error;
                    if (derr.type == 'AccountDisabled') {
                        _this.setState({
                            is_activating: false,
                            is_token_valid: false,
                            is_disabled: true
                        });
                    }
                    else {
                        _this.setState({
                            is_activating: false,
                            is_token_valid: false,
                            is_disabled: false
                        });
                    }
                }
                else if (_.isEmpty(apiRes.body)) {
                    _this.setState({
                        is_activating: false,
                        has_error: true
                    });
                }
                else {
                    lib_1.Global.onSubmitSuccess('accountVerified');
                    _this.setState({
                        user: apiRes.body.data
                    }, function () {
                        lib_1.Global.store.dispatch({ type: lib_1.Global.actions.setUser, data: _this.state.user });
                        lib_1.Statistic.refreshStatistic();
                        lib_1.Auth.setRefreshToken(_this.state.user.refresh_token, false);
                        lib_1.Auth.setAccessToken(_this.state.user.access_token);
                        lib_1.CruzSocket.reconnect();
                        lib_1.Auth.redirectAuthed();
                    });
                }
            });
        };
        Page.prototype.renderInvalidToken = function () {
            return (React.createElement("div", { className: 'alert alert-error text-center' },
                React.createElement("p", null,
                    "Invalid link!",
                    React.createElement("br", null),
                    "Please click the button below to get a new link."),
                React.createElement(react_router_dom_1.Link, { to: pages_1.pages.request_verification_token.path, className: 'btn c-btn-1 btn-xlg btn-block mt-4' }, "Get New Link")));
        };
        Page.prototype.renderTokenExpired = function () {
            return (React.createElement("div", { className: 'alert text-center' },
                React.createElement("p", null,
                    "This link is expired!",
                    React.createElement("br", null),
                    "Please click the button below to get a new link."),
                React.createElement(react_router_dom_1.Link, { to: pages_1.pages.request_verification_token.path + '?token=' + this.state.token, className: 'btn c-btn-1 btn-xlg btn-block mt-4' }, "Get New Link")));
        };
        Page.prototype.renderVerifying = function () {
            return (React.createElement("div", { className: 'alert text-center' },
                React.createElement("i", { className: 'far fa-spinner fa-pulse fa-2x' }),
                React.createElement("div", { className: 'mt-2' }, "Verifying your account ...")));
        };
        Page.prototype.render = function () {
            return (component_1.Layout.entry(1, React.createElement(React.Fragment, null,
                React.createElement("h1", { className: 'c-section-title text-center mb-4' }, "Account Activation"),
                this.state.is_disabled == true
                    ?
                        component_1.User.accountDisabled()
                    :
                        this.state.is_token_valid == false
                            ?
                                this.renderInvalidToken()
                            :
                                this.state.is_token_expired
                                    ?
                                        this.renderTokenExpired()
                                    :
                                        this.state.is_activating
                                            ?
                                                this.renderVerifying()
                                            :
                                                React.createElement(component_1.Loading, null))));
        };
        return Page;
    }(React.Component));
    VerifyAccount.Page = Page;
})(VerifyAccount = exports.VerifyAccount || (exports.VerifyAccount = {}));
