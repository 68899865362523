"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var _ = require("lodash");
var classNames = require("classnames");
var moment = require("moment");
var lib_1 = require("../../lib");
var __1 = require("..");
var __2 = require("..");
var vehicle_1 = require("../../vehicle");
var pages_1 = require("../../../routes/pages");
var Saved;
(function (Saved) {
    var SaveItemButton = (function (_super) {
        __extends(SaveItemButton, _super);
        function SaveItemButton(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                is_working: false
            };
            return _this;
        }
        SaveItemButton.prototype.componentDidMount = function () {
            var _this = this;
            this.unsubscribe = lib_1.Global.store.subscribe(function () { _this.forceUpdate(); });
        };
        SaveItemButton.prototype.componentWillUnmount = function () {
            this.unsubscribe && this.unsubscribe();
        };
        SaveItemButton.prototype.handleError = function (err) {
            this.setState({ is_working: false });
            lib_1.util.setToastMessage('error', lib_1.util.getErrorMessage(null, err));
        };
        SaveItemButton.prototype.requestDone = function (user) {
            this.setState({ is_working: false });
            lib_1.Global.store.dispatch({ type: lib_1.Global.actions.setUser, data: user });
        };
        SaveItemButton.prototype.toggleSaveItem = function (is_saved) {
            var _this = this;
            lib_1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    _this.setState({ is_working: true });
                    if (is_saved) {
                        lib_1.Request.saved.item.delete(_.pick(_this.props, ['name', '_id']), function (err, apiRes) {
                            if (err) {
                                _this.handleError(apiRes.body.error);
                            }
                            else {
                                _this.requestDone(apiRes.body.data);
                            }
                        });
                    }
                    else {
                        lib_1.Request.saved.item.create(_.pick(_this.props, ['name', '_id']), function (err, apiRes) {
                            if (err) {
                                _this.handleError(apiRes.body.error);
                            }
                            else {
                                _this.requestDone(apiRes.body.data);
                            }
                        });
                    }
                }
                else {
                    lib_1.util.setModal({}, false, false, React.createElement(__2.Modal.RequestAuth, null));
                }
            });
        };
        SaveItemButton.prototype.render = function () {
            var _this = this;
            var user = lib_1.Global.store.getState().user;
            var is_saved = !_.isEmpty(user) && !_.isEmpty(user.saved_vehicle_list) && !_.isEmpty(_.find(user.saved_vehicle_list, { _id: this.props._id }));
            return (React.createElement("button", { className: this.props.className, onClick: function () { return _this.toggleSaveItem(is_saved); } },
                React.createElement("i", { className: is_saved ? 'fas fa-heart c-fa-saved' : 'fal fa-heart' }),
                React.createElement("span", { className: 'c-font-size-xxs ml-2' }, is_saved ? 'Saved' : 'Save'),
                this.state.is_working
                    &&
                        React.createElement("i", { className: 'far fa-spinner fa-pulse ml-2' })));
        };
        return SaveItemButton;
    }(React.Component));
    Saved.SaveItemButton = SaveItemButton;
    var SaveFilterModal = (function (_super) {
        __extends(SaveFilterModal, _super);
        function SaveFilterModal(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                is_working: false,
            };
            return _this;
        }
        SaveFilterModal.prototype.componentDidMount = function () {
            var _this = this;
            $('#_saveFilter').modal('show').on('hidden.bs.modal', function () {
                lib_1.util.removeModal(_this);
            });
        };
        SaveFilterModal.prototype.filterNameChanged = function (tgt) {
            this.setState({ filter_name: tgt.value, errMsg: null });
        };
        SaveFilterModal.prototype.submit = function (e) {
            var _this = this;
            e && e.preventDefault();
            if (!this.state.is_working) {
                if (_.isEmpty(this.state.filter_name)) {
                    this.setState({ errMsg: 'Filter name is required.' });
                }
                else {
                    lib_1.Auth.isAuthed(function (isAuthed) {
                        if (isAuthed) {
                            _this.setState({ is_working: true });
                            lib_1.Request.saved.vehicle_filter.create({
                                name: _this.state.filter_name,
                                filter: _.omit(_this.props.filter, ['page_number', 'page_size'])
                            }, function (err, apiRes) {
                                if (err) {
                                    _this.setState({ errMsg: apiRes.body.error.detail, is_working: false });
                                }
                                else {
                                    _this.setState({ is_working: false }, function () {
                                        var d = apiRes.body.data;
                                        lib_1.Global.store.dispatch({ type: lib_1.Global.actions.setUser, data: d });
                                        vehicle_1.VehicleSearchControl.store.dispatch({ type: 15, data: _.first(d.saved_vehicle_filter_list).id });
                                        $('#_saveFilter').modal('hide');
                                    });
                                }
                            });
                        }
                        else {
                            lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                        }
                    });
                }
            }
        };
        SaveFilterModal.prototype.gotoMyCruz = function (e) {
            e && e.preventDefault();
            $('#_saveFilter').modal('hide');
            lib_1.Global.history.push(pages_1.pages.my_saved.path + '?tab=vehicle_filter');
        };
        SaveFilterModal.prototype.render = function () {
            var _this = this;
            setTimeout(function () {
                $('#filter_name').focus();
            }, 50);
            var c = _.size(lib_1.Global.store.getState().user.saved_vehicle_filter_list);
            return (React.createElement("div", { className: 'modal', id: '_saveFilter', tabIndex: -1, role: 'dialog', "data-backdrop": 'static', "data-keyboard": false },
                React.createElement("div", { className: 'modal-dialog', role: 'document' },
                    React.createElement("form", { id: 'formSaveFilter', className: 'form-main', noValidate: true, onSubmit: function (e) { return _this.submit(e); } },
                        React.createElement("div", { className: 'modal-content' },
                            React.createElement("div", { className: 'modal-header' },
                                React.createElement("h2", { className: 'modal-title' }, "Save Filter"),
                                React.createElement("button", { type: 'button', "data-dismiss": 'modal', className: 'close' })),
                            React.createElement("div", { className: 'modal-body' },
                                React.createElement(__1.Text, { type: 'text', id: 'filter_name', label: 'Filter Name', maxLength: 40, required: true, "data-err": this.state.errMsg, value: this.state.filter_name || '', onChange: function (e) { return _this.filterNameChanged(e.target); } }),
                                React.createElement("ul", { className: 'c-font-size-s mb-0' },
                                    React.createElement("li", null,
                                        "Save up to 12 filters",
                                        c > 0 ? ', ' + (12 - c) + ' left' : '',
                                        "."),
                                    React.createElement("li", null,
                                        "Manage saved filters from ",
                                        React.createElement("button", { type: 'button', className: 'c-btn-link', onClick: function (e) { return _this.gotoMyCruz(e); } }, "My Cruz\u00AE"),
                                        "."))),
                            React.createElement("div", { className: 'modal-footer d-flex justify-content-end' },
                                React.createElement("button", { type: 'submit', className: 'btn c-btn-1 c-btn-xs' }, this.state.is_working ? React.createElement(__1.BtnWorking, { value: 'Saving ...' }) : 'Save')))))));
        };
        return SaveFilterModal;
    }(React.Component));
    Saved.SaveFilterModal = SaveFilterModal;
    var RenameSavedFilterModal = (function (_super) {
        __extends(RenameSavedFilterModal, _super);
        function RenameSavedFilterModal(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                is_working: false,
                name: _this.props.name
            };
            return _this;
        }
        RenameSavedFilterModal.prototype.componentDidMount = function () {
            var _this = this;
            $('#_saveFilter').modal('show').on('hidden.bs.modal', function () {
                lib_1.util.removeModal(_this);
            });
        };
        RenameSavedFilterModal.prototype.filterNameChanged = function (tgt) {
            this.setState({ name: tgt.value, errMsg: null });
        };
        RenameSavedFilterModal.prototype.submit = function (e) {
            var _this = this;
            e.preventDefault();
            if (!this.state.is_working) {
                if (_.isEmpty(this.state.name)) {
                    this.setState({ errMsg: 'Filter name is required.' });
                }
                else {
                    lib_1.Auth.isAuthed(function (isAuthed) {
                        if (isAuthed) {
                            _this.setState({ is_working: true });
                            lib_1.Request.saved.vehicle_filter.rename(_this.props.id, _this.state.name, function (err, apiRes) {
                                if (err) {
                                    _this.setState({ errMsg: apiRes.body.error.detail, is_working: false });
                                }
                                else {
                                    _this.setState({ is_working: false });
                                    lib_1.Global.store.dispatch({ type: lib_1.Global.actions.setUser, data: apiRes.body.data });
                                    $('#_saveFilter').modal('hide');
                                }
                            });
                        }
                        else {
                            lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                        }
                    });
                }
            }
        };
        RenameSavedFilterModal.prototype.render = function () {
            var _this = this;
            setTimeout(function () { $('#filter_name').focus(); }, 50);
            var c = _.size(lib_1.Global.store.getState().user.saved_vehicle_filter_list);
            return (React.createElement("div", { className: 'modal', id: '_saveFilter', tabIndex: -1, role: 'dialog', "data-backdrop": 'static', "data-keyboard": false },
                React.createElement("div", { className: 'modal-dialog', role: 'document' },
                    React.createElement("form", { id: 'formSaveFilter', className: 'form-main', onSubmit: function (e) { return _this.submit(e); }, noValidate: true },
                        React.createElement("div", { className: 'modal-content' },
                            React.createElement("div", { className: 'modal-header' },
                                React.createElement("h2", { className: 'modal-title' }, "Change Filter Name"),
                                React.createElement("button", { type: 'button', "data-dismiss": 'modal', className: 'close' })),
                            React.createElement("div", { className: 'modal-body' },
                                React.createElement(__1.Text, { id: 'filter_name', label: 'Filter Name', maxLength: 40, required: true, "data-err": this.state.errMsg, value: this.state.name || '', onChange: function (e) { return _this.filterNameChanged(e.target); } })),
                            React.createElement("div", { className: 'modal-footer d-flex justify-content-end' },
                                React.createElement("button", { type: 'submit', className: 'btn c-btn-1 c-btn-xs' }, this.state.is_working ? React.createElement(__1.BtnWorking, { value: 'Saving ...' }) : 'Save')))))));
        };
        return RenameSavedFilterModal;
    }(React.Component));
    Saved.RenameSavedFilterModal = RenameSavedFilterModal;
    var FilterCard = (function (_super) {
        __extends(FilterCard, _super);
        function FilterCard(props) {
            var _this = _super.call(this, props) || this;
            _this.state = {
                is_working: false
            };
            return _this;
        }
        FilterCard.prototype.openDeleteDialog = function () {
            var _this = this;
            lib_1.util.setModal(null, false, true, React.createElement(__2.Delete.Dialog, { onYes: function (e) { return _this.deleteYes(); } }));
        };
        FilterCard.prototype.deleteYes = function () {
            var _this = this;
            if (!this.state.is_working) {
                this.setState({ is_working: true });
                lib_1.Request.saved.vehicle_filter.delete(this.props.id, function (err, apiRes) {
                    _this.setState({ is_working: false });
                    if (err) {
                        lib_1.util.setToastMessage('error', apiRes.body.error.detail);
                    }
                    else {
                        lib_1.Global.store.dispatch({ type: lib_1.Global.actions.setUser, data: apiRes.body.data });
                    }
                });
            }
        };
        FilterCard.prototype.gotoVehicleSearch = function () {
            lib_1.Global.history.push(lib_1.util.buildUrl(pages_1.pages.vehicle_search.path, this.props.filter));
        };
        FilterCard.prototype.render = function () {
            var _this = this;
            var d = this.props;
            return (React.createElement("div", { className: 'card' },
                React.createElement("div", { className: 'card-body' },
                    React.createElement("div", { className: 'd-flex' },
                        React.createElement("div", null,
                            React.createElement("h2", { className: 'card-title mb-1' },
                                React.createElement("button", { type: 'button', className: 'c-font-weight-medium c-btn-link text-left', onClick: function () { _this.gotoVehicleSearch(); } }, d.name)),
                            React.createElement("div", { className: 'c-font-size-xs' },
                                React.createElement("span", null,
                                    "Created ",
                                    moment(d.date_create).fromNow()),
                                React.createElement("span", { className: 'separator' }),
                                React.createElement("span", null,
                                    "Last viewed ",
                                    moment(d.date_view).fromNow()))),
                        this.state.is_working
                            ?
                                React.createElement("div", { className: 'ml-auto pl-4' },
                                    React.createElement("i", { className: 'far fa-spinner fa-pulse' }))
                            :
                                React.createElement("div", { className: 'ml-auto c-ellipsis-drop dropup pl-4' },
                                    React.createElement("button", { type: 'button', className: 'dropdown-toggle', "data-toggle": 'dropdown' },
                                        React.createElement("i", { className: 'far fa-ellipsis-v' })),
                                    React.createElement("div", { className: 'dropdown-menu dropdown-menu-right' },
                                        React.createElement("button", { type: 'button', className: 'dropdown-item', onClick: function () { lib_1.util.setModal({}, false, true, React.createElement(RenameSavedFilterModal, __assign({}, d))); } },
                                            React.createElement("i", { className: 'far fa-pen fa-fw' }),
                                            React.createElement("span", null, "Change Name")),
                                        React.createElement("div", { className: 'dropdown-divider' }),
                                        React.createElement("button", { type: 'button', className: 'dropdown-item', onClick: function (e) { return _this.openDeleteDialog(); } },
                                            React.createElement("i", { className: 'fal fa-trash-alt fa-fw' }),
                                            React.createElement("span", null, "Delete"))))),
                    !_.isEmpty(d.matched)
                        &&
                            React.createElement("div", { className: 'mt-2' },
                                "Total: ",
                                React.createElement("span", { className: 'c-font-weight-semi-bold c-font-size-l' }, d.matched.total.toLocaleString()),
                                React.createElement("span", { className: 'separator' }),
                                "Recent Listings: ",
                                React.createElement("span", { className: classNames('c-font-weight-semi-bold c-font-size-l', { 'c-font-color-2': d.matched.new > 0 }) }, d.matched.new.toLocaleString()))),
                React.createElement("div", { className: 'card-footer text-right' },
                    React.createElement("button", { type: 'button', className: 'btn c-btn-1 c-btn-xs', onClick: function () { _this.gotoVehicleSearch(); } },
                        React.createElement("span", null, "View")))));
        };
        return FilterCard;
    }(React.Component));
    Saved.FilterCard = FilterCard;
})(Saved = exports.Saved || (exports.Saved = {}));
