"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var react_router_dom_1 = require("react-router-dom");
var moment = require("moment");
var lib_1 = require("./lib");
var pages_1 = require("../routes/pages");
var component_1 = require("./component");
var TermsConditions;
(function (TermsConditions) {
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            return _super.call(this, props) || this;
        }
        Page.prototype.componentDidMount = function () {
            document.title = lib_1.util.title(pages_1.pages.terms_conditions.title);
        };
        Page.prototype.componentWillUnmount = function () {
        };
        Page.prototype.renderContent = function () {
            var y = moment().year();
            return (React.createElement(React.Fragment, null,
                React.createElement("h4", null, "Terms and Conditions of Use"),
                React.createElement("p", null, "PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY.  BY ACCESSING THIS WEBSITE, ANY OF ITS PAGES, OR ITS ASSOCIATED APPLICATIONS, YOU AGREE THAT YOU ARE BOUND BY THESE TERMS AND CONDITIONS OF USE AS THEY MAY BE AMENDED FROM TIME TO TIME.  IF YOU DO NOT AGREE WITH ANY OF THESE TERMS AND CONDITIONS OF USE, PLEASE EXIT THIS WEBSITE IMMEDIATELY."),
                React.createElement("h4", null, "General"),
                React.createElement("p", null, "Thank you for visiting the website of Cruz, Inc. (\u201CCruz\u00AE\u201D).  The Cruz.com website and associated applications (the \"Website\") is comprised of various web pages, applications, and features operated by Cruz\u00AE.  The terms \u201Cwe,\u201D \u201Cour,\u201D and \u201Cus\u201D refer to Cruz\u00AE, and its subsidiaries and affiliates, and the terms \u201Cyou\u201D or \u201Cyour\u201D refer to any individuals who access this Website.  By accessing or using this Website, you agree and consent to be legally bound by these Terms and Conditions of Use (the \u201CTerms\u201D) without limitation or qualification."),
                React.createElement("h4", null, "Use of Website"),
                React.createElement("p", null, "Our Website is not directed towards children.  If you are using this Website, you are confirming that you are 18 years of age or older, or are over 13 years of age and using the Website with the consent and supervision of your parent or guardian.  Use of the Website is also governed by our Privacy Policy, which informs users of our data collection practices."),
                React.createElement("p", null, "If you fail to comply with any of these Terms, your permission to use the Website automatically terminates.  If we become aware that you have been using the Website in an unauthorized way, we may block or suspend your access to the Website, bar you from future usage of the Website, or take any other action we deem necessary or appropriate, and may pursue any or all legal or equitable claims against you for such unauthorized use.  We further reserve the right, in our sole discretion, to refuse, suspend, or terminate your access to this Website, or any of our resources or services, at any time for any reason without notice.  We shall not be held liable to you or any third party for any termination of your access to this Website."),
                React.createElement("p", null, "You agree to indemnify and hold us harmless from any liability, loss, claim or expense including attorney\u2019s fees, related to your violation of these Terms or your use of the services, products, software, applications, or information made available through the Website."),
                React.createElement("p", null, "Use of the Website is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including, but not limited to, the liability, indemnification, arbitration, and jurisdiction sections contained herein below."),
                React.createElement("h4", null, "Unauthorized Use"),
                React.createElement("p", null, "Unauthorized use of this Website is prohibited. The following uses are expressly unauthorized:"),
                React.createElement("ul", null,
                    React.createElement("li", null, "Gathering, monitoring, or copying any content on this Website by using any crawler, spyware, engine, robot, \u201Cbot,\u201D spider, device, extraction tool, software, or any other utility, automatic device, or manual process of any kind without our express permission."),
                    React.createElement("li", null, "Harvesting or otherwise collecting information about others including, without limitation, e-mail addresses or phone numbers, without their explicit consent."),
                    React.createElement("li", null, "Interfering, or attempting to interfere, with the operations of the Website or using any device or software that will interfere, or attempt to interfere, with the operations of the Website; or posting any unauthorized material to the pages of the Website or the databases that power it.  "),
                    React.createElement("li", null, "Attempting to circumvent Website security in any way; probing or testing the vulnerability of the Website or any network connected to the Website; or hacking, breaching, or attempting to breach any part of the Website, its security or authentication measures, or any network connected to the Website, including the content management system and source code."),
                    React.createElement("li", null, "Uploading or submitting any data or information containing viruses, trojans, worms, malware, or any other computer code, corrupt file, program or component designed to interfere with this Website or its use, or the use of any software, hardware, networks, servers, computers, electronic devices, or other equipment of ours or any third party."),
                    React.createElement("li", null, "Submitting any content in such volume as to disrupt use of this Website; or taking any action that imposes an unreasonable or disproportionately large load on the infrastructure of the Website or any systems or networks connected to the Website."),
                    React.createElement("li", null, "Taking any action or making any communication that is inappropriate, unlawful, threatening, harassing, obscene, vulgar, pornographic, profane, indecent, defamatory, abusive, or a violation of the our legal rights (including, without limitation, privacy and publicity rights) or the legal rights of a third party."),
                    React.createElement("li", null, "Violating the copyright, trademark, or other intellectual property rights of any other person or entity."),
                    React.createElement("li", null, "Posting Submissions (defined below) or any other content using any manual or automatic posting tools other than those provided by us, unless you have received our express written consent. "),
                    React.createElement("li", null, "Creating more than one account per person."),
                    React.createElement("li", null, "Improperly assuming or claiming the identity, characteristics, or qualifications of another person or entity."),
                    React.createElement("li", null, "Conducting or forwarding surveys, contests, pyramid schemes, or chain letters."),
                    React.createElement("li", null, "Taking actions to seek or discover any materials or information, including passwords, through or in connection with the Website, except as authorized by these Terms and as intentionally made available to you through the Website. "),
                    React.createElement("li", null, "Attempting to gain unauthorized access to any portion of the Website or any systems or networks connected to the Website. "),
                    React.createElement("li", null, "Interfering or attempting to interfere with the use of the Website by other users."),
                    React.createElement("li", null, "Violating any applicable laws or regulations."),
                    React.createElement("li", null, "Posting hyperlinks to other websites that contain content that falls within the descriptions set forth above."),
                    React.createElement("li", null, "Attempting to use this Website for any purposes other than those intended by us, as determined in our sole discretion.")),
                React.createElement("h4", null, "Website Modifications"),
                React.createElement("p", null, "We reserve the right to modify or terminate this Website or any service available on this Website, any link, embed, platform, widget, or feature used by this Website, and your access to this Website, in whole or in part, at any time whatsoever."),
                React.createElement("h4", null, "Changes to Website Policies"),
                React.createElement("p", null,
                    "We reserve the right to make changes to Website policies at any time without advance notice to you including, without limitation, these Terms and our ",
                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.privacy_policy.path, className: 'c-link-2' }, "Privacy Policy"),
                    ". We encourage you to continue to review these Terms each time before using this Website, as your continued use will be strictly subject to the then-current Terms.  Each time we modify the content of these Terms, we will update the effective date set forth above."),
                React.createElement("h4", null, "Electronic Communications"),
                React.createElement("p", null, "Visiting this Website or sending emails to us constitutes electronic communications. You consent to receive electronic communications from us and agree to notify us of any changes in your email address. Except as otherwise required under applicable law, you agree that all agreements, notices, and other communications that we provide to you electronically, via email and on the Website, satisfy any legal requirement that such communications be in writing. "),
                React.createElement("h4", null, "Text Messages and Other Communications to a Telephone"),
                React.createElement("p", null, "You authorize us to contact you, including by sending text messages or other communications to a cell phone using an automatic telephone dialing system or an artificial or prerecorded message, at any number you provide.  You acknowledge that any text messages or prerecorded messages sent by us may contain sales or marketing content.  We will only send you a text message for the purposes to which you have consented.  "),
                React.createElement("h4", null, "Account Authority and Security of Your Account"),
                React.createElement("p", null, "Any individual who sets up an account on the Website must set up the account in his or her own name. You agree that you will only establish an account on your own behalf.  If you use this Website, you are solely responsible for maintaining the security and confidentiality of your account, user name, and password, and for restricting access to your computer.  By using this Website, you further agree to accept responsibility for all activities that occur on your account under your user name or password. You may not assign or otherwise transfer your account to any other person or entity. Any user names or passwords used for this Website are for individual use only.  You acknowledge that we are not responsible for third party access to your account that results from theft or misappropriation of your account."),
                React.createElement("p", null, "We are entitled to monitor your password, and, at our discretion, require you to change it. If you use a password that we consider unsecure, we are entitled to require the password to be changed or to terminate your account.  You understand that providing your user name or password to a third party without the authorization of Cruz\u00AE, or using a password to gain unauthorized access to the Website, may constitute computer fraud, which is a class 5 felony under the laws of the state of South Dakota (see S.D. Codified Laws Ann. \u00A743-43B-1 to 8), and may further constitute a violation of Title 18, \u00A71030 of Federal law, which is punishable by fine and/or imprisonment. You acknowledge we may monitor password use and vigorously pursue violators to the full extent of the law."),
                React.createElement("h4", null, "Links, Widgets, Embeds, Social Media, and Other Third Party Features"),
                React.createElement("p", null, "Our Website may provide links to other websites for the convenience of our Website users.  Our Website may also make use of widgets, embeds, social media, or other third party features that are hosted by a third party or hosted directly on our Website.  Your interactions with any link or third party widget, embed, social media, or other feature are governed by the terms and conditions of use of the third party providing the widget, embed, social media, or other third party feature, or the terms and conditions of use of the linked website.  The terms and conditions of use of third parties may be substantially different than these Terms. We neither own nor control third party websites, widgets, embeds, social media, or other features that are accessible through this Website.  Therefore, before visiting the website of a third party, or making use of a third party feature on our Website, whether by means of widget, embed, social media feature, or otherwise, you should first consult that third party\u2019s terms and conditions of use (if any) and inform yourself of the terms applicable to that third party\u2019s website, feature, or services (if any). We are not responsible for any dealings with third parties you may have through a third party website, link, widget, embed, social media, or other third party feature."),
                React.createElement("h4", null, "Endorsements"),
                React.createElement("p", null, "Any description of a product, service, or publication on this Website (including any description or reference via hyperlink) does not imply endorsement by us of that product, service, or publication.  Moreover, the inclusion of any third party link, social media feature, embed, or widget on our Website does not imply an endorsement by us or any association with its third party operator."),
                React.createElement("h4", null, "Use of Third Party Sites and Organizations"),
                React.createElement("p", null, "Certain services made available via the Website are delivered by third party sites and organizations acting as our third party service providers. By using any product, service, or functionality originating from the Website, you hereby acknowledge and consent that we may share your information and data with any third party service provider that provides the requested product, service, or functionality on behalf of our users and customers. "),
                React.createElement("h4", null, "Conditions of User Non-Exclusive, Non-Transferable, Revocable License"),
                React.createElement("p", null, "You are granted a non-exclusive, non-transferable, revocable license to access and use the Website, or any software, digital products, applications, or services provided by this Website, strictly in accordance with these Terms.  As a condition of your use of the Website, or any software, digital products, applications, or services provided by this Website, you warrant to us that you will not use the Website, or any software, digital products, applications, or services provided by this Website, for any purpose that is unlawful or prohibited by these Terms.  You may not use the Website, or any software, digital products, applications, or services provided by this Website, in any manner which could damage, disable, overburden, or impair the Website or any software, digital products, applications, or services provided by this Website, or interfere with any other party's use and enjoyment of the Website or any software, digital products, applications, or services provided by this Website.  You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Website, or any software, digital products, applications, or services provided by this Website.  Using software, applications, or digital products from this Website does not give you title to such software, application, or digital product, including any files, data and images incorporated in or associated with the software, application, or digital product."),
                React.createElement("p", null, "Except for use expressly authorized, you agree that you will not copy, modify, publish, transmit, distribute, reverse engineer, perform, display, participate in the transfer or sale, license, create derivative works, reproduce, decompile or disassemble, or in any way exploit any of the content, in whole or in part, found on the Website, or in any software, application, or digital product we provide you.  This Website and its content, along with the software, applications, and digital products available on this Website, is copyrighted by us or its owner. Our content is not for resale. Your use of the Website, software, application, or digital product does not entitle you to make any unauthorized use of any protected content, and in particular you will not delete or alter any proprietary rights or attribution notices in any content. You will use protected content solely for your personal use, and will make no other use of the content without the express written permission of Cruz\u00AE and the copyright owner. You agree that you do not acquire any ownership rights in any protected content. We do not grant you any licenses, express or implied, to the intellectual property of us or our licensors except as expressly authorized by these Terms."),
                React.createElement("p", null, "You further agree that: (1) You will not provide any false information on the Website; (2) You will not create an account except for yourself or for a third party that has explicitly authorized you to do so; (3) If we suspend or close your account, you will not create another one without our explicit permission; (4) All information you provide to us, including contact information such as telephone number and email address, and your vehicle information, shall be kept true, accurate, complete, and up-to-date, including, but not limited to, ensuring that your vehicle has its sale status properly designated at all times; (5) You will not transfer your account to anyone without first getting our written permission; (6) Downloading, scraping, exporting, copying, extracting, capturing vehicle information, phone numbers, or any content found on the Website for the purpose of creating a collection, compilation, database, or directory, or redisplay or distribution of any data and/or content without express written permission of Cruz\u00AE is strictly prohibited and that you will not partake in such activities; (7) You will not mirror or archive any part of the Website or any material contained on the Website on any server or computer without Cruz\u00AE's written permission; (8) We reserve the right to use fictitious information, including, but not limited to, fictitious accounts or vehicle information, to test and develop website content and features and to help identify any third-party that uses, calls, or displays the Website content on any other website without express written permission of Cruz\u00AE and will seek maximum punitive monetary damages according to law to protect the information displayed on the Website; and (9) All rights not expressly granted herein are reserved by us. "),
                React.createElement("p", null, "If we have reasonable grounds to suspect that you have violated any of these obligations, we may suspend or terminate your access to the Website, revoke your license, terminate your access to the Website services, and may refuse any and all current or future use of the services, software, application, product, Website, or license.  We further reserve the right to refuse or cancel service, terminate accounts, or remove or edit Website content at any time whatsoever in our sole discretion. "),
                React.createElement("h4", null, "Use of Communication Services"),
                React.createElement("p", null, "The Website may contain bulletin board services, electronic mail postings, news groups, forums, communities, personal web pages, calendars, email, and/or other message or communication facilities designed to enable you to communicate with the public at large, or with a group (collectively, \"Communication Services\"), you agree to use the Communication Services only to post, send, and receive messages and material that are proper, relevant, and related to the particular Communication Service and its intended subject matter.  "),
                React.createElement("p", null, "By way of example, and not as a limitation, you agree that when using a Communication Service, you will not: defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy and publicity) of others; publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful topic, name, material or information; upload files that contain software or other material protected by intellectual property laws (or by rights of privacy of publicity) unless you own or control the rights thereto or have received all necessary consents; upload files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of another's computer or electronic device; advertise or offer to sell or buy any goods or services for any business purpose, unless such Communication Service specifically allows such messages for buying or selling for that specific type of good or service; conduct or forward surveys, contests, pyramid schemes or chain letters; download any file posted by another user of a Communication Service that you know, or reasonably should know, cannot be legally distributed in such manner; falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded, restrict or inhibit any other user from using and enjoying the Communication Services; violate any code of conduct or other guidelines which may be applicable for any particular Communication Service; harvest or otherwise collect information about others, including e-mail addresses, without their consent; or violate any applicable laws or regulations. "),
                React.createElement("p", null, "We have no obligation to monitor the Communication Services and are not liable for any messages, material or information posted to, sent or received through the Communication Services.  However, we reserve the right to review materials posted to a Communication Service and to delete, remove, edit, or decline to post any materials in our sole discretion.  We reserve the right to terminate your access to any or all of the Communication Services at any time without notice for any reason whatsoever. "),
                React.createElement("p", null, "We reserve the right at all times to disclose any information as necessary to satisfy any applicable law, regulation, legal process or governmental request, or to edit, refuse to post or to remove any information or materials, in whole or in part, in our sole discretion. "),
                React.createElement("p", null, "Always use caution when giving out any personally identifying information in any Communication Service. This information may be visible to the public and we cannot guarantee the security of any information you post in a Communication Service.  If you provide contact information to a third-party via any of our Communication Services, or in your Submissions (as defined below), we are not responsible for, and you hereby release us from liability and will indemnify and hold us harmless, from any contacts or communications you may have with that third-party, including, but not limited to, contacts or communications by email, text message, phone call, or meetings in person.  We do not control or endorse the content, messages, or information found in any Communication Service, and we specifically disclaim any liability with regard to the Communication Services and any actions resulting from your participation in any Communication Service.  Materials uploaded to a Communication Service may be subject to posted limitations on usage, reproduction, and/or dissemination. You are responsible for adhering to such limitations if you upload the materials. "),
                React.createElement("h4", null, "Submissions"),
                React.createElement("p", null, "Any content, remarks, suggestions, feedback, testimonials, ideas, graphics, reviews, comments, advertisements, listings for products and/or services, , or other information communicated to us through this Website (together, the \"Submissions\") which you post, input, or submit to the Website or our associated services is considered to be non-confidential and public.  We do not claim ownership of your Submissions, however, you hereby grant to us the royalty-free, perpetual, irrevocable, worldwide, non-exclusive right and license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform, and display all Submissions alone or as part of other works (including, without limitation, for products or advertising) in any form, media, or technology whether now known or hereafter developed, without incurring any liability for royalties or any other consideration of any kind, and to sublicense such rights through multiple tiers of sublicensees. You agree that we shall not be liable as a result of any similarities that may appear between Submissions and our future operations and/or content.  "),
                React.createElement("p", null, "You agree that all Submissions provided on this Website are the sole responsibility of the person or entity from which the Submissions originated, and that you are responsible for any Submissions that you post on this Website. By posting, uploading, inputting, providing or submitting your Submissions, you warrant and represent that you own or otherwise control all of the rights to your Submissions as described in this section including, without limitation, all the rights necessary for you to provide, post, upload, input or submit the Submissions. You further represent and warrant that you are the sole author of all Submissions you post or submit to the Website, and that such Submissions do not infringe upon the copyright, trademark, privacy, publicity, or other rights of any person or entity.   You agree we are not liable or responsible for any Submissions made by you or other third parties."),
                React.createElement("p", null, "By way of example, and not as a limitation, you agree your Submissions will not: defame, abuse, harass, stalk, threaten or otherwise violate the legal rights of others; publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful topic, name, material or information; upload files that contain software or other material protected by intellectual property laws (or by rights of privacy of publicity) unless you own or control the rights thereto or have received all necessary consents; upload files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of another's computer or electronic device; conduct or forward surveys, contests, pyramid schemes or chain letters; falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded, restrict or inhibit any other user from using and enjoying the Website; violate any code of conduct or other guidelines which may be applicable for any particular Submission; harvest or otherwise collect information about others, including e-mail addresses, without their consent; or violate any applicable laws or regulations. "),
                React.createElement("p", null, "We have no obligation to monitor Submissions.  However, we reserve the right to review and to delete, remove, edit, move, or decline to post any Submissions on the Website in our sole discretion.  You are exclusively responsible for the accuracy of all Submissions you post or submit to the Website, including, but not limited to, the accuracy of information provided about your vehicle. If you believe information in your Submission is incorrect, you must amend, delete, or flag such Submission immediately, or if you have concerns with our services regarding your Submission, you must notify us immediately. If any information in your Submission is found to be fraudulent, we reserve the right to immediately deactivate your user account and remove your Submissions without any form of compensation or advance warning."),
                React.createElement("p", null, "No compensation will be paid with respect to the use of your Submissions, as provided herein. We are under no obligation to post or use any Submissions you may provide and we may remove any Submission at any time in our sole discretion. "),
                React.createElement("h4", null, "Ownership"),
                React.createElement("p", null, "All content on this Website whether provided by this Website or through links, widgets, embeds, or social media or other third party features, is the copyrighted work of us, the owner(s) of the links, widgets, embeds, or social media or other third party features, and/or the owner(s) of Submission(s).  Except as stated herein, none of the content of the Website may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means, including, but not limited to, electronic, mechanical, photocopying, recording, or otherwise, without the prior express written permission of us, the owner(s) of the links, widgets, embeds, or social media or other third party features, and/or the owner(s) of Submission(s), with the exception that Website content may be shared through social media, so long as such sharing maintains a true and official link back to the Website and the shared content is unaltered."),
                React.createElement("p", null, "Any trademarks, service marks, or logos appearing on this Website are our property or the property of the party that provided the trademarks, service marks, or logos to us. We and any party that provided trademarks, service marks, or logos to us retain all rights with respect to any of our respective trademarks, service marks, or logos appearing in this Website, whether registered or not.  You agree to observe and abide by all copyright, trademark, and other proprietary notices, legends or other restrictions contained in any such content and will not make any changes thereto. "),
                React.createElement("h4", null, "Privacy & Information Use"),
                React.createElement("p", null,
                    "Information processed via us and our systems may be stored and used for various purposes.  Use of this Website is also governed by this Website\u2019s Privacy Policy which outlines what information we collect on our Website and how that information is used.  Please review our ",
                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.privacy_policy.path }, "Privacy Policy"),
                    " for more information on our information collection, use, and sharing practices and our Children\u2019s Online Privacy Protection Act disclosure."),
                React.createElement("h4", null, "Digital Millennium Copyright Act (DMCA) Procedures"),
                React.createElement("p", null, "Violating the trademark or copyright rights of others is a violation of our Terms.  Nothing on this Website shall be construed as conferring any license under any intellectual property right, including any right in the nature of trademark or copyright, of Cruz\u00AE or any third party, whether by estoppel, implication, or otherwise. All trademarks and trade names are the property of their respective owners. Except as otherwise noted, Cruz\u00AE is the owner of all trademarks and service marks on this Website, whether registered or not."),
                React.createElement("p", null, "We provide the following procedures for making a Digital Millennium Copyright Act (DMCA) claim or counter-claim."),
                React.createElement("div", { className: 'ml-5' },
                    React.createElement("p", null,
                        React.createElement("b", null, "Notice and Procedure for Making Claims of Copyright Infringement."),
                        " In accordance with the Digital Millennium Copyright Act (DMCA), we have a designated agent to receive notice of unauthorized online use of copyrighted materials on this Website. If you believe that your copyrighted work is being infringed, please notify our copyright agent specified below"),
                    React.createElement("p", null,
                        "E-mail: ",
                        React.createElement("a", { href: 'mailto:terms@cruz.com', className: 'c-link-2' }, "terms@cruz.com")),
                    React.createElement("p", null,
                        "Certified Mail:",
                        React.createElement("br", null),
                        "Cruz, Inc.",
                        React.createElement("br", null),
                        "c/o Sean Coffman",
                        React.createElement("br", null),
                        "113 East Spruce",
                        React.createElement("br", null),
                        "Beresford, SD 57004",
                        React.createElement("br", null)),
                    React.createElement("p", null, "Please notify us in writing and include all of the following:"),
                    React.createElement("ul", null,
                        React.createElement("li", null, "Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works are covered by a single notification, a representative list of such works."),
                        React.createElement("li", null, "Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit the service provider to locate the material"),
                        React.createElement("li", null, "Information reasonably sufficient to permit us to contact the complaining party, such as an address, telephone number, and, if available, an electronic mail address at which the complaining party may be contacted."),
                        React.createElement("li", null, "A statement that the complaining party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law."),
                        React.createElement("li", null, "A statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed"),
                        React.createElement("li", null, "A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.")),
                    React.createElement("p", null,
                        React.createElement("b", null, "Procedure for making a DMCA Counter Notification."),
                        "  If you have had material that you placed online removed pursuant to a DMCA infringement complaint and feel that it is not in fact an infringement, you can submit a counter notification. Please send a written counter notification of copyright infringement containing the following information to our designated agent specified below."),
                    React.createElement("p", null,
                        "E-mail: ",
                        React.createElement("a", { href: 'mailto:terms@cruz.com', className: 'c-link-2' }, "terms@cruz.com")),
                    React.createElement("p", null,
                        "Certified Mail:",
                        React.createElement("br", null),
                        "Cruz, Inc.",
                        React.createElement("br", null),
                        "c/o Sean Coffman",
                        React.createElement("br", null),
                        "113 East Spruce",
                        React.createElement("br", null),
                        "Beresford, SD 57004",
                        React.createElement("br", null)),
                    React.createElement("p", null, "Please notify us in writing and include all of the following:"),
                    React.createElement("ul", null,
                        React.createElement("li", null, "Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access to it was disabled"),
                        React.createElement("li", null, "A statement under penalty of perjury that you have a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material to be removed or disabled"),
                        React.createElement("li", null, "Your name, address, telephone number, and if available, an electronic mail address at which the counter-complaining party may be contacted, and a statement that you consent to the jurisdiction of Federal District Court for the judicial district in which the address is located, or if your address is outside of the United States, for any judicial district in which the service provider may be found, and that you will accept service of process from the person who provided notification under subsection (c)(1)(C) or an agent of such person"),
                        React.createElement("li", null, "A physical or electronic signature of a person who has had alleged infringing material removed.")),
                    React.createElement("p", null, "We will not respond to counter notifications that do not adhere to the listed requirements.  We will deliver your counter notification to the original individual who filed the DMCA copyright infringement complaint informing them that the removed material will be replaced within 10 -14 business days following notification. Once the notification has been delivered we are authorized to reinstate the removed material and cease disabling access 10 -14 business days following the receipt of the counter notice unless we receive notice that the original individual is proceeding with a court order against you."),
                    React.createElement("p", null,
                        React.createElement("b", null, "Possession of an Injunction."),
                        "  If we receive a notification and appropriate accompanying documentation from a copyright holder to our designated agent that they are in possession of an injunction that prohibits us from providing access to the infringing material, you will be informed as soon as we are able and we will follow the requirements as set forth in the injunction.")),
                React.createElement("h4", null, "Vehicle History Report"),
                React.createElement("p", null,
                    "By using the Vehicle History Report feature of this Website, which is provided by ",
                    React.createElement("a", { href: 'https://www.carsforsale.com', target: '_blank', rel: 'noopener noreferrer', className: 'c-link-2' }, "Carsforsale.com"),
                    ", users agree and consent to the disclaimers provided by Carsforsale.com, including the ",
                    React.createElement("a", { href: '/docs/NMVTIS_Disclaimer.pdf', target: '_blank', rel: 'noopener noreferrer', className: 'c-link-2' }, "NMVTIS Disclaimer"),
                    "."),
                React.createElement("h4", null, "Liability & Indemnity"),
                React.createElement("p", null, "For the purpose of this liability and indemnity provision, the terms \u201Cwe,\u201D \u201Cour,\u201D and \u201Cus\u201D refer to Cruz\u00AE, its subsidiaries and affiliates, and the owners, shareholders, members, directors, shareholders, owners, managers, officers, employees, agents, licensors or licensees, service or content providers, and suppliers of Cruz\u00AE, or of its subsidiaries and affiliates. The terms \u201Cyou\u201D or \u201Cyour\u201D refer to any individuals who use or access this Website."),
                React.createElement("div", { className: 'ml-5' },
                    React.createElement("h5", null, "Release from Disputes with Third Parties."),
                    React.createElement("p", null, "BECAUSE THE WEBSITE IS A PLACE FOR USERS TO POST ADVERTISEMENTS AND LISTINGS OF GOODS AND SERVICES FOR SALE, YOU AGREE THAT THIS WEBSITE MERELY ACTS AS A CONDUIT FOR SELLERS TO CONDUCT SALES AND FOR BUYERS TO PURCHASE SELLERS' GOODS. WE HAVE NO CONTROL OVER THE QUALITY, SAFETY, LEGALITY, TRUTH, OR ACCURACY OF USER-POSTED SUBMISSIONS, OR THE ABILITY OF SELLERS TO SELL ITEMS OR BUYERS TO BUY ITEMS. WE CANNOT AND DO NOT CONTROL WHETHER OR NOT SELLERS WILL COMPLETE THE SALE OF GOODS OR SERVICES THEY OFFER OR IF BUYERS WILL COMPLETE THE PURCHASE. IN THE EVENT THAT YOU HAVE A DISPUTE WITH ONE OR MORE BUYERS OR SELLERS, YOU HEREBY RELEASE US FROM ALL CLAIMS, DEMANDS, AND DAMAGES OF ANY KIND OR NATURE, KNOWN OR UNKNOWN, SUSPECTED OR UNSUSPECTED, DISCLOSED OR UNDISCLOSED, ARISING OUT OF OR IN ANY WAY CONNECTED WITH SUCH CLAIM, DEMAND, OR DAMAGES.  YOU AGREE THAT IF YOU ARE A RESIDENT IN A JURISDICTION WHICH RESTRICTS THE EFFECT OF THIS RELEASE OF LIABILITY FOR DISPUTES WITH THIRD PARTIES, YOU HEREBY CONSENT THAT THIS RELEASE OF LIABILITY FOR DISPUTES WITH THIRD PARTIES SHALL APPLY TO THESE TERMS TO THE FULLEST EXTENT LEGALLY POSSIBLE. "),
                    React.createElement("h5", null, "General Liability Release."),
                    React.createElement("p", null, "ANY AND ALL CONTENT CONTAINED ON THIS WEBSITE, INCLUDING BUT NOT LIMITED TO TEXT, GRAPHICS, IMAGES, SOFTWARE, CODE, PRODUCTS, APPLICATIONS, INFORMATION, AND CORRESPONDING SERVICES IS RELIED UPON AT YOUR OWN RISK AND IS PRESENTED \u201CAS IS\u201D OR \u201CAS AVAILABLE.\u201D  NO WARRANTIES OF ANY KIND, WHETHER EXPRESS, STATUTORY, OR IMPLIED, ARE PROVIDED FOR OUR WEBSITE OR ITS CONTENT, INCLUDING, BUT NOT LIMITED TO, ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT.  AS WAY OF EXAMPLE, NOT LIMITATION, WE PROVIDE NO WARRANTIES OR REPRESENTATIONS ABOUT SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, OR ACCURACY OF THE CONTENT ON THIS WEBSITE.  WE PROVIDE NO WARRANTIES THAT OUR WEBSITE OR ITS CONTENT IS FREE FROM ERROR OR DEFECTS (LIKE VIRUSES OR MALICIOUS CODE), THAT USE OF OUR WEBSITE WILL BE FREE FROM INTERRUPTIONS, OR THAT OUR WEBSITE WILL NOT BE COMPROMISED BY A CYBERATTACK, HACK, OR OTHER SIMILAR EVENT.  WE MAKE NO REPRESENTATIONS OR WARRANTIES TO ANY ACTUAL OR PROSPECTIVE PURCHASER OR OWNER OF ANY ITEM AS TO THE EXISTENCE, OWNERSHIP, OR CONDITION OF THE ITEM, OR AS TO THE ACCURACY OR COMPLETENESS OF ANY INFORMATION ABOUT THE ITEM CONTAINED ON THIS WEBSITE.  YOU EXPRESSLY ACKNOWLEDGE THAT ANY RELIANCE UPON ANY CONTENT ON THIS WEBSITE SHALL BE AT YOUR SOLE RISK, AND THAT WE WILL NOT BE LIABLE IN ANY WAY FOR CONTENT POSTED ON THIS WEBSITE OR OTHERWISE MADE AVAILABLE THROUGH USE OF THIS WEBSITE, INCLUDING BUT NOT LIMITED TO, THROUGH EMAIL OR TEXT NOTIFICATIONS."),
                    React.createElement("p", null, "TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL WE BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER, INCLUDING ANY INJURY TO PERSON, PROPERTY, OR BODY, OR ANY KIND OF LOSS OR DAMAGE WHATSOEVER TO YOU OR ANY THIRD PARTY CONNECTED WITH THE USE OF OR INABILITY TO USE THIS WEBSITE, WHETHER BASED IN CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF DAMAGES (COLLECTIVELY A \u201CCLAIM\u201D).  THIS INCLUDES BUT IS NOT LIMITED TO DAMAGES ARISING FROM MISTAKES, OMISSIONS, DELAYS, INTERRUPTIONS, INSUFFICIENT, INCOMPLETE, MISSING OR DELETED CONTENT, DETERIORATION OR CORRUPTION OF FILES OR CONTENT, LOSS OF DATA, LOSS OF PROFITS, ERRORS, DEFECTS, VIRUSES, WORMS, TROJANS, MALWARE AND MALICIOUS CODE, OR INJURY TO PERSON, PROPERTY, OR BODY, OR ANY OTHER KIND OF LOSS OR DAMAGE WHATSOEVER, TO YOU OR ANY THIRD PARTY.  BECAUSE SOME STATES AND JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU, BUT YOU AGREE THAT OUR LIABILITY SHALL BE WAIVED TO THE MAXIMUM EXTENT PERMISSIBLE BY THE LAW OF YOUR STATE OR JURISDICTION.  IF YOU ARE DISSATISFIED WITH OR DISAGREE WITH ANY PORTION OF THESE TERMS, INCLUDING, BUT NOT LIMITED TO, THIS LIABILITY AND INDEMNITY PROVISION, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THIS WEBSITE"),
                    React.createElement("h5", null, "Indemnity."),
                    React.createElement("p", null, "BY USING THIS WEBSITE, YOU EXPRESSLY AGREE: (1) YOUR USE OF THIS WEBSITE IS AT YOUR OWN RISK; (2) YOU CONSENT TO AND ARE BOUND BY THE ABOVE LIABILITY WAIVER AND THIRD PARTY DISPUTE RELEASE; (3) YOU WILL INDEMNIFY AND HOLD US HARMLESS AGAINST ANY AND ALL LIABILITY AND WILL INDEMNIFY US FOR ANY CLAIM (INCLUDING, WITHOUT LIMITATION, THE COST OF INVESTIGATING ANY CLAIM, THE COST OF LITIGATION, AND ATTORNEYS\u2019 FEES, WHETHER OR NOT LEGAL PROCEEDINGS ARE INSTITUTED) ARISING FROM, ATTRIBUTABLE TO, OR IN CONNECTION WITH YOUR ACCESS TO OR USE OR MISUSE OF THIS WEBSITE, ITS CONTENT, OR ANY INFORMATION, GOODS, OR SERVICES PROVIDED THROUGH OR ON THIS WEBSITE, INCLUDING, BUT NOT LIMITED TO, ANY TRANSACTION ON, THROUGH, OR IN CONNECTION WITH THIS WEBSITE (INCLUDING BUT NOT LIMITED TO ANY INACCURACIES, MISREPRESENTATIONS, OR FRAUD IN ANY SALE OR PURCHASE THROUGH OR IN CONNECTION WITH THIS WEBSITE OR THE CONTENT OF OR REPRESENTATIONS MADE BY ANY ADVERTISEMENT OR LISTING ON THE WEBSITE), ANY SUBMISSIONS YOU POST ON THIS WEBSITE, YOUR BREACH OF ANY OBLIGATION, TERM, OR WARRANTY IN THESE TERMS, AND YOUR INFRINGEMENT OF THE INTELLECTUAL PROPERTY OR OTHER RIGHTS OF THIRD PARTIES, OR ANY ACT OR OMISSION BY YOU IN VIOLATION OF THESE TERMS, OR OF ANY APPLICABLE LAW OR REGULATION; AND (4) WE EXPRESSLY RESERVE THE RIGHT, AT OUR SOLE AND COMPLETE DISCRETION AND AT OUR OWN COST, TO ASSUME THE EXCLUSIVE DEFENSE AND CONTROL OF ANY MATTER OTHERWISE SUBJECT TO INDEMNIFICATION BY YOU, IN WHICH EVENT YOU WILL FULLY COOPERATE WITH US IN ASSERTING ANY AVAILABLE DEFENSES.")),
                React.createElement("h4", null, "ARBITRATION FOR DISPUTES"),
                React.createElement("p", null, "PLEASE READ THIS PROVISION CAREFULLY.  BY USING THIS WEBSITE, YOU AGREE THAT ANY DISPUTE WILL BE RESOLVED BY BINDING ARBITRATION, PROVIDED THAT EITHER YOU OR WE MAY BRING A CLAIM IN SMALL CLAIMS COURT. ARBITRATION REPLACES THE RIGHT TO GO TO COURT, INCLUDING THE RIGHT TO HAVE A JURY, TO ENGAGE IN DISCOVERY (EXCEPT AS MAY BE PROVIDED IN THE ARBITRATION RULES), AND TO PARTICIPATE IN A CLASS ACTION OR SIMILAR PROCEEDING. IN ARBITRATION, A DISPUTE IS RESOLVED BY AN ARBITRATOR INSTEAD OF A JUDGE OR JURY. ARBITRATION PROCEDURES ARE SIMPLER AND MORE LIMITED THAN COURT PROCEDURES. YOU ALSO AGREE ANY ARBITRATION WILL BE LIMITED TO THE DISPUTE BETWEEN YOU AND US AND WILL NOT BE PART OF A CLASS-WIDE OR CONSOLIDATED ARBITRATION PROCEEDING."),
                React.createElement("div", { className: 'ml-5' },
                    React.createElement("p", null,
                        React.createElement("b", null, "Agreement to Arbitrate."),
                        " You and we agree that any Dispute, except as provided below, will be resolved by arbitration. This arbitration agreement is governed by the Federal Arbitration Act (FAA), 9 U.S.C.S. \u00A7 1 et seq. and the substantive law of the State of South Dakota (without applying its choice-of-law rules)."),
                    React.createElement("p", null,
                        React.createElement("b", null, "Arbitration Defined."),
                        " \u201CArbitration\u201D is a means of having an independent third party resolve a Dispute. A \u201CDispute\u201D is any claim or controversy of any kind between you and us. The term Dispute is to be given its broadest possible meaning and includes, without limitation, all claims or demands (whether past, present or future), based on any legal or equitable theory (contract, tort, or otherwise) and regardless of the type of relief sought (i.e., money, injunctive relief or declaratory relief). A Dispute includes, by way of example and without limitation, any claim based upon a federal or state constitution, statute, ordinance, regulation, or common law, and any issue concerning the validity, enforceability or scope of this arbitration agreement. For purposes of this arbitration provision, the terms \u201Cyou\u201D and \u201Cyour\u201D include your heirs, guardian, personal representative or trustee in bankruptcy. The terms \u201Cwe,\u201D \u201Cour,\u201D and \u201Cus\u201D include our employees, officers, directors, members, shareholders, owners, managers, attorneys, affiliated companies, predecessors and assigns, as well as our marketing, servicing and collection representatives and agents."),
                    React.createElement("p", null,
                        React.createElement("b", null, "Choice of Arbitrator."),
                        " The party filing an arbitration must choose either of the following arbitration firms for initiating and pursuing an arbitration: the American Arbitration Association or JAMS (formerly known as Judicial Arbitration and Mediation Services, Inc.). If you claim you have a Dispute with us, but you do not initiate an arbitration or select an arbitrator, we may do so. You may obtain copies of the current rules of each of the arbitration firms and forms and instructions for initiating an arbitration by contacting them as follows:"),
                    React.createElement("p", null,
                        "American Arbitration Association",
                        React.createElement("br", null),
                        "335 Madison Avenue, Floor 10",
                        React.createElement("br", null),
                        "New York, NY 10017-4605",
                        React.createElement("br", null),
                        "Website: www.adr.org"),
                    React.createElement("p", null,
                        "JAMS",
                        React.createElement("br", null),
                        "1920 Main Street, Suite 300",
                        React.createElement("br", null),
                        "Irvine, CA 92614",
                        React.createElement("br", null),
                        "Website: www.jamsadr.com"),
                    React.createElement("p", null, "The policies and procedures of the selected arbitration firm will apply provided that these are consistent with this arbitration agreement. To the extent the arbitration firm\u2019s rules or procedures are different than the terms of this arbitration agreement, the terms of this agreement will apply. If neither arbitration firm listed in this arbitration agreement is available to resolve the Dispute, you and we may each select a local arbitrator. The local arbitrators selected by you and us shall then agree upon the selection of another arbitrator who will hear and resolve the Dispute. The arbitrator selected to resolve the Dispute must be an attorney with at least 10 years of experience, retired judge, or arbitrator registered and in good standing with an arbitration association and arbitrate pursuant to such arbitrator\u2019s rules applicable to consumer disputes. Notwithstanding the foregoing, no party or local arbitrator may select an arbitrator who refuses to enforce an arbitration agreement containing a class-action waiver, because you and we have in this arbitration agreement waived any right to arbitrate a dispute on a class-action, representative-action, or consolidated basis."),
                    React.createElement("p", null,
                        React.createElement("b", null, "What Arbitration Costs."),
                        " If you initiate the arbitration, you will be responsible for all of your filing fees and other costs or fees. If you cannot afford to pay, we may pay this in our discretion. If we initiate the arbitration, we will pay the filing fee and any costs or fees charged by the arbitrator. Unless prohibited by law, the arbitrator may award fees, costs and reasonable attorneys\u2019 fees to the party who substantially prevails in the arbitration."),
                    React.createElement("p", null,
                        React.createElement("b", null, "Location of Arbitration."),
                        " Unless you and we agree to a different location, the arbitration will be conducted in Sioux Falls, South Dakota."),
                    React.createElement("p", null,
                        React.createElement("b", null, "Waiver of Rights."),
                        " You are waiving your right to a jury trial, to have a court decide your Dispute, to participate in a class action lawsuit, and to certain discovery and other procedures that are available in a lawsuit. The arbitrator has the ability to award all remedies available by statute, at law, or in equity to the prevailing party, except that you and we agree that the arbitrator has no authority to conduct class-wide proceedings and will be restricted to resolving the individual disputes between you and us. The validity, effect, and enforceability of this waiver of class action lawsuit and class-wide arbitration are to be determined solely by a court of competent jurisdiction and not by the arbitration firm or arbitrator. If the court, or an arbitration firm or arbitrator, refuses to enforce the class-wide arbitration waiver or otherwise refuses to enforce this arbitration agreement, you and we agree that the Dispute will proceed in court and will be decided by a judge, sitting without a jury, under applicable court rules and procedures."),
                    React.createElement("p", null,
                        React.createElement("b", null, "Small Claims Court."),
                        " Notwithstanding any other provisions of these Terms, including any provision of this arbitration agreement, all parties, including related third parties, shall retain the right to seek adjudication in small claims court in Minnehaha County, South Dakota for disputes within the scope of such court\u2019s jurisdiction. Any dispute, which cannot be adjudicated within the jurisdiction of a small claims court, shall be resolved by binding arbitration. Any appeal of a judgment from a small claims court shall be resolved by binding arbitration de novo (i.e., upon a fresh review of the facts.)"),
                    React.createElement("p", null,
                        React.createElement("b", null, "Applicable Law and Judicial Review."),
                        " The arbitrator will apply applicable federal and South Dakota substantive law and the terms of this arbitration agreement. The arbitrator must apply the terms of this arbitration agreement, including without limitation the waiver of class-wide arbitration. The arbitrator will make written findings and the arbitrator\u2019s award may be filed with any court having jurisdiction. The arbitration award will be supported by substantial evidence and must be consistent with this arbitration agreement and applicable law or may be set aside by a court upon judicial review. Either party may seek judicial review of the arbitrator\u2019s decision according to applicable law."),
                    React.createElement("p", null,
                        React.createElement("b", null, "Other Provisions."),
                        " This arbitration provision will survive: (1) termination or changes in these Terms, including this arbitration agreement, or the relationship between you and us concerning this Website; (2) the bankruptcy of any party; and (3) any transfer, sale or assignment of the Website or Cruz\u00AE or its assets to any other person or entity, or the transfer, sale, or assignment of your Cruz\u00AE account interest to any other person or entity."),
                    React.createElement("p", null,
                        React.createElement("b", null, "Class Action Waiver."),
                        " Any arbitration under these Terms will take place on an individual basis; class arbitrations and class/representative/collective actions are not permitted. THE PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN EACH'S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY GENERAL ACTION AGAINST THE OTHER. Further, unless both you and we agree otherwise, the arbitrator may not consolidate more than one person's claims, and may not otherwise preside over any form of a representative or class proceeding."),
                    React.createElement("p", null,
                        React.createElement("b", null, "Disputes Not Subject to Arbitration."),
                        " In the event any Dispute is not subject to arbitration, you agree and consent that this agreement is governed by, and construed in accordance with the laws of the State of South Dakota, without giving effect to any principles of conflicts of law.  You further consent to be subject to the exclusive jurisdiction and venue of the Circuit Court located in Minnehaha County, South Dakota, or if appropriate, the United States District Court for the District of South Dakota, and agree that such court shall be the sole and exclusive jurisdiction and venue of all Disputes for this Website or its associated services that are not subject to arbitration.  You further agree that the class action waiver shall still bind any Disputes not subject to arbitration."),
                    React.createElement("p", null,
                        React.createElement("b", null, "Right to Opt-Out."),
                        " If you are an individual and do not wish to agree to arbitrate all Disputes in accordance with the terms and conditions of this section, you must advise us in writing within thirty (30) days of registering your account at the following email address: ",
                        React.createElement("a", { href: "mailto:terms@cruz.com", className: 'c-link-2' }, "terms@cruz.com"),
                        " with the subject heading \u201CRight to Opt-Out of Arbitration for Cruz.com\u201D.  In the text of the email, you must provide the Website domain address, your name, and state that you reject arbitration.  Individuals opting out of arbitration will still be bound to the Disputes Not Subject to Arbitration subsection.  Individuals must give written notice in the method specified, and it is not sufficient to telephone us.  Send only an email notice to reject arbitration; do not include the notice with other correspondence to us.")),
                React.createElement("h4", null, "No Agency, Joint Venture, Employment, or Partnership"),
                React.createElement("p", null, "You agree that no joint venture, partnership, employment, or agency relationship exists between you and us as a result of these Terms or use of the Website. You agree that our performance under these Terms is subject to existing laws and legal process, and nothing contained in these Terms is in derogation of our right to comply with governmental, court and law enforcement requests or requirements relating to your use of the Website or information provided to or gathered by us with respect to such use."),
                React.createElement("h4", null, "International Users"),
                React.createElement("p", null, "The Website is controlled, operated and administered by us from our offices within the United States of America. If you access the Website from a location outside the USA, you are responsible for compliance with all local laws. You agree that you will not use the Website in any country or in any manner prohibited by any applicable laws, restrictions, or regulations. "),
                React.createElement("h4", null, "Enforceability"),
                React.createElement("p", null, "These Terms shall be given effect to the fullest extent permissible by law.  If any provision of these Terms is deemed unlawful, void, or unenforceable for any reason whatsoever, that provision shall be severed and the rest of these Terms shall remain valid and in full force and effect, and a provision as similar in terms as may be valid and enforceable shall be used in lieu of such provision.  Our failure to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision."),
                React.createElement("h4", null, "Entire Agreement and Headings"),
                React.createElement("p", null,
                    "Unless otherwise specified herein, these Terms, coupled with our ",
                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.privacy_policy.path, className: 'c-link-2' }, "Privacy Policy"),
                    ", reflect the entire agreement between you and us regarding the Website and supersede and replace all prior or contemporaneous understandings or agreements of any type regarding this subject matter.  Notwithstanding the foregoing, any additional terms and conditions provided with any service or product you obtain through the Website will also govern your use of that service or product."),
                React.createElement("p", null, "The headings used herein are provided solely for your convenience and have no legal or contractual effect.  A printed version of these Terms and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to  the Website to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. It is the express wish of the parties that these Terms and all related documents be written in English."),
                React.createElement("p", null, "Specific areas or pages of this Website may include additional or different terms relating to the use of this Website. In the event of a conflict between such terms and these Terms, the terms provided on the relevant specific area or page of the Website shall control."),
                React.createElement("h4", null, "Reservation of Rights"),
                React.createElement("p", null, "All rights not expressly granted herein are reserved exclusively and entirely to us."),
                React.createElement("h4", null, "Assignment"),
                React.createElement("p", null, "You may not assign these Terms.  We may assign these Terms, in whole or in part, at any time."),
                React.createElement("h4", null, "Accessibility"),
                React.createElement("p", null, "If any portion of this Website is inaccessible to you for any reason, please contact us at 605-306-3494."),
                React.createElement("h4", null, "Notices; Contact Information"),
                React.createElement("p", null, "Unless otherwise permitted under applicable law or these Terms, all notices provided to us in connection with these Terms, including, without limitation, any notices regarding arbitration requests or other legal demands or claims, should be directed to the following address via certified mail: "),
                React.createElement("p", { className: 'ml-5' },
                    "Certified Mail:",
                    React.createElement("br", null),
                    "Cruz, Inc.",
                    React.createElement("br", null),
                    "c/o Legal Department",
                    React.createElement("br", null),
                    "113 East Spruce",
                    React.createElement("br", null),
                    "Beresford, SD 57004",
                    React.createElement("br", null)),
                React.createElement("p", null, "Questions about these Terms should be directed to the following address via certified mail or email at the designated addresses below:"),
                React.createElement("p", { className: 'ml-5' },
                    "Certified Mail:",
                    React.createElement("br", null),
                    "Cruz, Inc.",
                    React.createElement("br", null),
                    "c/o Terms of Use",
                    React.createElement("br", null),
                    "113 East Spruce",
                    React.createElement("br", null),
                    "Beresford, SD 57004",
                    React.createElement("br", null)),
                React.createElement("p", null,
                    "Email: ",
                    React.createElement("a", { href: "mailto:terms@cruz.com", className: 'c-link-2' }, "terms@cruz.com")),
                React.createElement("p", null,
                    "Questions about our products should not be directed to this address and will not be replied to.  Please use this contact information only for questions regarding our Terms or ",
                    React.createElement(react_router_dom_1.Link, { to: pages_1.pages.privacy_policy.path, className: 'c-link-2' }, "Privacy Policy"),
                    "."),
                React.createElement("p", null,
                    "Unless otherwise indicated, all material on this Website \u00A9 ",
                    y > 2017 ? '2017-' + y.toString() + ' ' : '2017',
                    " Cruz, Inc. All rights reserved."),
                React.createElement("p", null, "Effective Date: 11/22/2017")));
        };
        Page.prototype.render = function () {
            return (component_1.Layout.main(null, null, React.createElement(React.Fragment, null,
                React.createElement(component_1.Hero, { title: pages_1.pages.terms_conditions.title }),
                React.createElement("div", { className: 'container c-container' },
                    React.createElement("section", { className: 'c-section-heading mb-4' },
                        React.createElement("h1", { className: 'c-title' }, pages_1.pages.terms_conditions.title)),
                    this.renderContent()))));
        };
        return Page;
    }(React.Component));
    TermsConditions.Page = Page;
})(TermsConditions = exports.TermsConditions || (exports.TermsConditions = {}));
