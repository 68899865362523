"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var ReactDOM = require("react-dom");
var react_router_dom_1 = require("react-router-dom");
var request = require("superagent");
var _ = require("lodash");
var $ = require("jquery");
var uri = require("urijs");
var classNames = require("classnames");
var showdown = require("showdown");
var Push = require('push.js');
var react_qr_svg_1 = require("react-qr-svg");
var slugify_1 = require("slugify");
var Modernizr = require('modernizr');
var platform = require("platform");
var numeral = require("numeral");
var Cruz = require("cruz");
var __1 = require("..");
var global_1 = require("./global");
var pages_1 = require("../../../routes/pages");
var component_1 = require("../../component");
var cfg = require("../../../cfg");
var util;
(function (util) {
    var emptyOption = [React.createElement("option", { value: '', key: '-1' })];
    function myQRCode() {
        return (React.createElement("div", { className: 'bg-white' },
            React.createElement(react_qr_svg_1.QRCode, { fgColor: '#000', bgColor: '#fff', value: JSON.stringify(_.pick(global_1.Global.store.getState().user, ['_id', 'code'])), className: 'img-fluid c-qr-code' })));
    }
    util.myQRCode = myQRCode;
    function pushNotification(title, options, newUrl) {
        if (Push.Permission.has()) {
            var opts = _.assign({
                icon: '/favicon-32x32.png',
                vibrate: true,
                onClick: function () {
                    if (newUrl) {
                        window.open(newUrl);
                    }
                    else {
                        window.focus();
                    }
                    this.close();
                }
            }, options);
            Push.create(title, opts);
        }
    }
    util.pushNotification = pushNotification;
    function shouldShowRequestNotificationPermission() {
        try {
            var hasPermission = localStorage.getItem('notification_permission');
            var cUri = new uri();
            var isHttps = (cUri.protocol().toLowerCase() == 'https');
            var isLocalhost = (cUri.hostname() == 'localhost' || cUri.hostname() == '127.0.0.1');
            return (Push.Permission.has() == false && Modernizr.notification && hasPermission == null && (isHttps == true || isLocalhost == true));
        }
        catch (err) {
            return null;
        }
    }
    util.shouldShowRequestNotificationPermission = shouldShowRequestNotificationPermission;
    var stripTags = require('striptags');
    function stripHtmlTags(txt, allowTags) {
        return stripTags(txt.toString(), allowTags).trim();
    }
    util.stripHtmlTags = stripHtmlTags;
    function trimJsonData(d) {
        try {
            return JSON.parse(stripTags(JSON.stringify(d).replace(/"\s+|\s+"/g, '"'), cfg.allowHtmlTags));
        }
        catch (err) {
            return null;
        }
    }
    util.trimJsonData = trimJsonData;
    function logError(err, req) {
        try {
            request.post(global_1.Global.cruzApiBaseUrl + '/error/web').send(err);
        }
        catch (err) {
        }
    }
    util.logError = logError;
    function resize(file, maxWidth, maxHeight, callback) {
        try {
            var loadImage_1 = require('blueimp-load-image');
            var dataURLtoBlob_1 = require('blueimp-canvas-to-blob');
            loadImage_1.parseMetaData(file, function (md) {
                var orientation = 0;
                if (md.exif) {
                    orientation = md.exif.get('Orientation');
                }
                loadImage_1(file, function (cv) {
                    try {
                        var d = cv.toDataURL(file.type).replace(/^data\:image\/\w+\;base64\,/, '');
                        var blob = dataURLtoBlob_1('data:image/' + file.type + ';base64,' + d);
                        return callback(null, blob);
                    }
                    catch (err) {
                        return callback(err, file);
                    }
                }, {
                    maxWidth: maxWidth,
                    maxHeight: maxHeight,
                    orientation: orientation,
                    meta: true,
                    canvas: true
                });
            }, {
                disableImageHead: false
            });
        }
        catch (err) {
            return callback(err, null);
        }
    }
    util.resize = resize;
    function setToastMessage(type, message) {
        if (type == 'error' && _.isEmpty(message)) {
            message = 'Something is wrong';
        }
        global_1.Global.store.dispatch({ type: global_1.Global.actions.setToastMessage, data: { type: type, message: message } });
    }
    util.setToastMessage = setToastMessage;
    function removeToastMessage() {
        global_1.Global.store.dispatch({ type: global_1.Global.actions.setToastMessage, data: null });
    }
    util.removeToastMessage = removeToastMessage;
    function download(download_url, file_name) {
        util.setModal(null, false, true, React.createElement(component_1.Modal.DownloadFile, { download_url: download_url, file_name: file_name }));
    }
    function viewImage(image_url) {
        util.setModal(null, false, true, React.createElement(component_1.Modal.ViewImageInChat, { download_url: image_url }));
    }
    function renderBinaryMessage(message) {
        try {
            var resizeableImageTypes = ['jpeg', 'jpg', 'png', 'tiff', 'webp'];
            var fileDetail = message.file.name.split('.');
            var ext = fileDetail[fileDetail.length - 1];
            var isImageResized = resizeableImageTypes.includes(ext.toLowerCase());
            var isOriginalImage = (message.file.mime_type && _.startsWith(message.file.mime_type.toLowerCase(), 'image')) ? true : false;
            var isPdfFile = (ext.toLowerCase() === 'pdf') ? true : false;
            var absoluteUrl = "chat/" + __1.Chat.store.getState().active_chat_id + "/" + message.id;
            var downloadUrl_1 = cfg.socketUrl[process.env.NODE_ENV] + "/chat/binary/" + uri.encode(absoluteUrl) + "/" + ext + "/" + message.file.name + "/" + __1.Auth.authHeader().Authorization;
            return (React.createElement("div", { key: message.id, className: 'col-6 col-sm-4 col-lg-3 col-md-4 col-xl-2 align-items-stretch' },
                React.createElement("button", { className: classNames('b-wrap dropdown-toggle d-flex justify-content-center align-items-center flex-column'), "data-toggle": 'dropdown' },
                    isImageResized && React.createElement("img", { key: message.id, className: 'img-fluid', src: cfg.socketUrl[process.env.NODE_ENV] + "/chat/binary/" + uri.encode(absoluteUrl) + "-md/" + ext + "/" + message.file.name + "/" + __1.Auth.authHeader().Authorization }),
                    !isImageResized && isOriginalImage && React.createElement("img", { className: 'img-fluid', src: downloadUrl_1 }),
                    !isImageResized && !isOriginalImage &&
                        React.createElement(React.Fragment, null,
                            React.createElement("i", { title: message.file.name, className: classNames('fal fa-2x pt-3', { 'fa-file-pdf': isPdfFile }, { 'fa-file-alt': !isPdfFile }) }),
                            React.createElement("br", null),
                            React.createElement("label", { htmlFor: message.id }, message.file.name))),
                React.createElement("div", { className: classNames('dropdown-menu') },
                    (_.startsWith(message.file.mime_type.toLowerCase(), 'image') || isImageResized) &&
                        React.createElement("div", null,
                            React.createElement("button", { className: 'dropdown-item', onClick: function () { return viewImage(downloadUrl_1); } },
                                React.createElement("i", { className: 'far fa-expand fa-fw' }),
                                React.createElement("span", null, "Enlarge")),
                            React.createElement("div", { className: 'dropdown-divider' })),
                    React.createElement("button", { className: 'dropdown-item', onClick: function () { return download(downloadUrl_1, message.file.name); } },
                        React.createElement("i", { className: 'fal fa-download fa-fw' }),
                        React.createElement("span", null, "Download")))));
        }
        catch (e) {
            return;
        }
    }
    util.renderBinaryMessage = renderBinaryMessage;
    function title(defaultTitle, docTitle) {
        try {
            return defaultTitle + _((docTitle && (typeof docTitle == 'string') && docTitle != '') ? (' | ' + docTitle) : '');
        }
        catch (err) {
            logError(err, null);
            return null;
        }
    }
    util.title = title;
    function setModal(mq, save_history, requireAuth, elm_modal, selector) {
        if (selector === void 0) { selector = '#_modal'; }
        try {
            __1.Auth.isAuthed(function (isAuthed) {
                var url = location.pathname + new uri(location.search).addSearch(mq);
                if (!requireAuth || isAuthed) {
                    if (save_history == true) {
                        global_1.Global.history.replace(url);
                    }
                    ReactDOM.render(elm_modal, document.querySelector(selector));
                }
                else {
                    __1.Auth.redirectSignin(url);
                }
            });
        }
        catch (err) {
            logError(err, null);
        }
    }
    util.setModal = setModal;
    function removeModal(obj, selector) {
        if (selector === void 0) { selector = '#_modal'; }
        $(obj).data('bs.modal', null);
        if (document.querySelector(selector)) {
            ReactDOM.unmountComponentAtNode(document.querySelector(selector));
        }
    }
    util.removeModal = removeModal;
    function setModalPhotoView(photos) {
        ReactDOM.render(React.createElement(component_1.PhotoView.ListView, __assign({}, photos)), document.querySelector('#_modal'));
    }
    util.setModalPhotoView = setModalPhotoView;
    function setModalPhotoEnlarge(d) {
        ReactDOM.render(React.createElement(component_1.PhotoView.Enlarge, __assign({}, d)), document.querySelector('#_modal'));
    }
    util.setModalPhotoEnlarge = setModalPhotoEnlarge;
    function confirm(elm) {
        try {
            ReactDOM.render(elm, document.querySelector('#_confirm'));
            $('#__confirm').modal('show').on('hidden.bs.modal', function (e) {
                ReactDOM.unmountComponentAtNode(document.querySelector('#_confirm'));
                $(this).data('bs.modal', null);
            });
        }
        catch (err) {
            logError(err, null);
        }
    }
    util.confirm = confirm;
    function getErrorMessage(systemError, apiError) {
        try {
            if (apiError) {
                return apiError.detail;
            }
            else if (systemError) {
                var err = systemError.toString();
                if (/(network)/gi.test(err)) {
                    return 'Oops! Something went wrong with network!\nPlease check your network connection.';
                }
                else if (/(timeout)/gi.test(err)) {
                    return 'Oops! Connection timeout!\nPlease try again in a few minutes.';
                }
                else {
                    return 'Oops! Something went wrong!\nPlease try again in a few minutes.';
                }
            }
        }
        catch (err) {
            logError(err, null);
            return '';
        }
    }
    util.getErrorMessage = getErrorMessage;
    function handleError(statusCode) {
        try {
            if (isClient()) {
                $('.modal').modal('hide');
            }
            var returnUrl = getRelativeUrl();
            var url = '';
            if (statusCode == 400) {
                url = pages_1.pages.error.path + '/500' + (returnUrl ? '?url=' + encodeURI(returnUrl) : '');
            }
            else if (statusCode == 401) {
                if (cookie.get('access_token') || cookie.get('refresh_token')) {
                    url = pages_1.pages.error.path + '/401' + (returnUrl ? '?url=' + encodeURI(returnUrl) : '');
                }
                else {
                    url = pages_1.pages.signin.path + (returnUrl ? '?returnUrl=' + encodeURI(returnUrl) : '');
                }
            }
            else if (statusCode == 404) {
                url = pages_1.pages.error.path + '/404' + (returnUrl ? '?url=' + encodeURI(returnUrl) : '');
            }
            else if (statusCode == 408) {
                url = pages_1.pages.error.path + '/408' + (returnUrl ? '?url=' + encodeURI(returnUrl) : '');
            }
            else if (statusCode == 410) {
                url = pages_1.pages.error.path + '/410' + (returnUrl ? '?url=' + encodeURI(returnUrl) : '');
            }
            else if (statusCode == 500) {
                url = pages_1.pages.error.path + '/500' + (returnUrl ? '?url=' + encodeURI(returnUrl) : '');
            }
            if (url != '') {
                if (global_1.Global.history) {
                    global_1.Global.history.replace(url);
                }
                else {
                    window.location.replace(url);
                }
            }
        }
        catch (err) {
            logError(err, null);
        }
    }
    util.handleError = handleError;
    var rCookie = require('react-cookies').default;
    var cookie;
    (function (cookie) {
        var cookiePrepend = '_cruz_';
        cookie.get = function (name) {
            try {
                return rCookie.load(cookiePrepend + name);
            }
            catch (err) {
                return null;
            }
        };
        cookie.set = function (name, val, options) {
            try {
                rCookie.save(cookiePrepend + name, val, _.assign({}, options, { path: '/' }));
            }
            catch (err) {
            }
        };
        cookie.remove = function (name, options) {
            try {
                rCookie.remove(cookiePrepend + name, _.assign({}, options, { path: '/' }));
            }
            catch (err) {
            }
        };
    })(cookie = util.cookie || (util.cookie = {}));
    var otherOptions = [
        React.createElement("option", { disabled: true, key: '-2' }, "\u2500\u2500\u2500\u2500\u2500\u2500\u2500\u2500\u2500\u2500\u2500\u2500\u2500"),
        React.createElement("option", { value: '~', key: '-3' }, "Custom & Other")
    ];
    function mapOptions(name, data, default_option, show_other) {
        try {
            if ((data != null && data.length > 0) || show_other == true) {
                var options = _.map(data, function (item) {
                    return React.createElement("option", { value: item, key: name.toLowerCase() + '_' + item }, item);
                });
                return _.union([
                    React.createElement("option", { value: '', key: '-1' }, default_option ? default_option.name : '')
                ], options || [], show_other && otherOptions);
            }
            else {
                return null;
            }
        }
        catch (err) {
            return null;
        }
    }
    util.mapOptions = mapOptions;
    function mapOptionGroup(name, data, default_option, show_other) {
        try {
            if (!_.isEmpty(data) || show_other == true) {
                if (!_.isEmpty(data.popular) && !_.isEmpty(data.other)) {
                    var _popularOptions = _.map(data.popular, function (item, idx) {
                        return React.createElement("option", { value: item, key: 'popular_' + idx }, item);
                    });
                    var _otherOptions = _.map(data.other, function (item, idx) {
                        return React.createElement("option", { value: item, key: 'all_' + idx }, item);
                    });
                    return _.union(emptyOption, [
                        !_.isEmpty(_popularOptions) && React.createElement("optgroup", { key: 'popular', label: 'Popular ' + name + ':' }, _popularOptions),
                        !_.isEmpty(_otherOptions) && React.createElement("optgroup", { key: 'other', label: 'Other ' + name + ':' }, _otherOptions)
                    ], show_other && otherOptions);
                }
                else if (data.all) {
                    return mapOptions(name, data.all, default_option, show_other);
                }
            }
            else {
                return null;
            }
        }
        catch (err) {
            return null;
        }
    }
    util.mapOptionGroup = mapOptionGroup;
    function mapStyleOptions(data) {
        return _.union(emptyOption, _.map(data, function (item) {
            return React.createElement("option", { value: item.vehicle_id.toString(), key: item.vehicle_id }, item.style);
        }));
    }
    util.mapStyleOptions = mapStyleOptions;
    function mapSaleStatusOptions(d, isSearch) {
        try {
            return _.union(emptyOption, _.map(d, function (item) {
                return React.createElement("option", { value: item.id.toString(), key: 'sale_status_' + item.id }, item.status);
            }));
        }
        catch (err) {
            return null;
        }
    }
    util.mapSaleStatusOptions = mapSaleStatusOptions;
    function mapExteriorColorOptions(name, data, isSearch) {
        try {
            var colors = _.map(_.groupBy(data, function (d) { return d.mfr_color_name; }), function (x) {
                return x[0];
            });
            return _.union([React.createElement("option", { value: '', key: '-1' }, isSearch ? 'Any' : '')], _.map(colors, function (item) {
                return React.createElement("option", { value: item.ext_color_id.toString(), key: 'ext_color_' + item.ext_color_id }, item.mfr_color_name + (item.mfr_color_name != item.color_name ? ' (' + item.color_name + ')' : ''));
            }));
        }
        catch (err) {
            return null;
        }
    }
    util.mapExteriorColorOptions = mapExteriorColorOptions;
    function mapInteriorColorOptions(name, data, isSearch) {
        try {
            var colors = _.map(_.groupBy(data, function (d) { return d.mfr_color_name; }), function (x) {
                return x[0];
            });
            return _.union(emptyOption, _.map(colors, function (item) {
                return React.createElement("option", { value: item.int_color_id.toString(), key: 'ext_color_' + item.int_color_id }, item.mfr_color_name + (item.mfr_color_name != item.color_name ? ' (' + item.color_name + ')' : ''));
            }));
        }
        catch (err) {
            return null;
        }
    }
    util.mapInteriorColorOptions = mapInteriorColorOptions;
    function mapRoofColorOptions(name, data, isSearch) {
        try {
            var colors = _.map(_.groupBy(data, function (d) { return d.mfr_color_name; }), function (x) {
                return x[0];
            });
            return _.union(emptyOption, _.map(colors, function (item) {
                return React.createElement("option", { value: item.roof_color_id.toString(), key: 'ext_color_' + item.roof_color_id }, item.mfr_color_name + (item.mfr_color_name != item.color_name ? ' (' + item.color_name + ')' : ''));
            }));
        }
        catch (err) {
            return null;
        }
    }
    util.mapRoofColorOptions = mapRoofColorOptions;
    function mapColorOptions(name, data) {
        try {
            return _.union(emptyOption, _.map(data, function (item) {
                return React.createElement("option", { value: item.color_name, key: 'color_' + item.hex }, item.color_name);
            }));
        }
        catch (err) {
            return null;
        }
    }
    util.mapColorOptions = mapColorOptions;
    function mapColorGroupOptions(data, isSearch) {
        try {
            return _.union(emptyOption, _.map(data, function (item, idx) {
                return React.createElement("option", { value: item.color_group, key: idx }, item.color_name);
            }));
        }
        catch (err) {
            return null;
        }
    }
    util.mapColorGroupOptions = mapColorGroupOptions;
    function mapCustomBrandOptions(brand_list, isSearch) {
        try {
            return _.union(emptyOption, _.map(brand_list, function (brand) {
                return React.createElement("option", { value: brand.name, key: brand.name }, brand.name);
            }));
        }
        catch (err) {
            return null;
        }
    }
    util.mapCustomBrandOptions = mapCustomBrandOptions;
    function mapSelectList(d) {
        try {
            return _.union(emptyOption, _.map(d.data, function (item) {
                return React.createElement("option", { value: item.value, key: item.value }, item.name);
            }));
        }
        catch (err) {
            return null;
        }
    }
    util.mapSelectList = mapSelectList;
    function mapStyleList(d) {
        try {
            return _.union(emptyOption, _.map(d, function (item) {
                return React.createElement("option", { value: item.vehicle_id.toString(), key: item.vehicle_id }, item.style);
            }));
        }
        catch (err) {
            return null;
        }
    }
    util.mapStyleList = mapStyleList;
    function mapBodyTypeOptions(d, isForSearch) {
        try {
            return (_.union(emptyOption, _.map(d, function (bt, idx) {
                var gOption = React.createElement("option", { key: 'body_type_' + idx, value: isForSearch ? bt.value : bt.code }, bt.name);
                var options = null;
                if (bt.child && bt.child.length > 0) {
                    options = _.union([gOption], _.map(bt.child, function (c_bt, c_idx) {
                        return React.createElement("option", { key: 'body_type_' + idx + '_' + c_idx, value: isForSearch ? c_bt.value : c_bt.code },
                            "\u00A0\u00A0",
                            '- ' + c_bt.name);
                    }));
                    return options;
                }
                else {
                    return gOption;
                }
            })));
        }
        catch (err) {
            return null;
        }
    }
    util.mapBodyTypeOptions = mapBodyTypeOptions;
    function mapYearOptions(start, end) {
        try {
            if (start && end && start > 0 && end > 0) {
                var ys = [];
                if (start >= end) {
                    ys = _.range(start, end - 1, -1);
                }
                else {
                    ys = _.range(start, end + 1, 1);
                }
                return (_.union(emptyOption, _.map(ys, function (y) {
                    return React.createElement("option", { key: 'year_' + y, value: y.toString() }, y);
                })));
            }
            else {
                return null;
            }
        }
        catch (err) {
            return null;
        }
    }
    util.mapYearOptions = mapYearOptions;
    function mapSortOptions(d, zip_code) {
        try {
            return (_.map(d, function (o, idx) {
                if (o.require_zip_code) {
                    if (_.isEmpty(zip_code)) {
                        return null;
                    }
                    else {
                        return React.createElement("option", { key: idx, value: o.value }, o.name + ' (from ' + zip_code + ')');
                    }
                }
                else {
                    return React.createElement("option", { key: idx, value: o.value }, o.name);
                }
            }));
        }
        catch (err) {
            return null;
        }
    }
    util.mapSortOptions = mapSortOptions;
    function mapSearchOptions(data) {
        try {
            return _.union(emptyOption, _.map(data, function (item) { return React.createElement("option", { value: item.value.toString(), key: item.value }, item.name); }));
        }
        catch (err) {
            return null;
        }
    }
    util.mapSearchOptions = mapSearchOptions;
    function mapNumberSearchOptions(data) {
        return _.union(emptyOption, _.map(data, function (item) { return React.createElement("option", { value: item, key: item }, item.toLocaleString()); }));
    }
    util.mapNumberSearchOptions = mapNumberSearchOptions;
    function text2html(text, className) {
        var options = {
            simplifiedAutoLink: true,
            openLinksInNewWindow: true,
            excludeTrailingPunctuationFromURLs: true,
            literalMidWordUnderscores: true,
            simpleLineBreaks: true,
            ghCodeBlocks: false,
            encodeEmails: false,
            emoji: true
        };
        try {
            return ((text != null && text != '')
                ?
                    React.createElement("div", { className: classNames('c-mb-offset-p', className), dangerouslySetInnerHTML: {
                            __html: new showdown.Converter(options).makeHtml(text)
                        } })
                :
                    '');
        }
        catch (err) {
            return '';
        }
    }
    util.text2html = text2html;
    function inputProps(props) {
        try {
            var elm = _.assign({}, _.omit(props, 'options'));
            elm.autoComplete = 'off';
            if (elm.type == 'checkbox') {
                elm.className = 'custom-control-input';
            }
            else {
                elm.className = 'form-control';
                if (_.isEmpty(props.label)) {
                    elm.className += ' no-label';
                }
                elm.placeholder = null;
            }
            if (elm.name == null || elm.name == '') {
                elm.name = elm.id;
            }
            if (elm.value == null) {
                elm.value = '';
            }
            var aria = {
                'aria-describedby': 'help-' + (props.id || ''),
                'aria-required': elm.required,
                'aria-invalid': (props['data-err'] ? true : false)
            };
            elm.ref = elm.id;
            return _.omit(_.assign({}, elm, aria), ['label', 'data-err', 'data-grid', 'data-input-pre-addon', 'data-input-addon']);
        }
        catch (err) {
            return null;
        }
    }
    util.inputProps = inputProps;
    function formGroupClass(props) {
        try {
            return classNames('form-group', 'has-feedback', { 'has-error': (props['data-err'] != null) }, { 'has-value': (!props["data-hide-has-value"] && props.value != null) && props.value.toString() != '' }, props.className);
        }
        catch (err) {
            return null;
        }
    }
    util.formGroupClass = formGroupClass;
    function formLabelClass(props) {
        try {
            return classNames('control-label', { optional: (!props.disabled && (props.required == null || props.required == false)) });
        }
        catch (err) {
            return null;
        }
    }
    util.formLabelClass = formLabelClass;
    function isClient() {
        try {
            return _.isEmpty(process.env);
        }
        catch (err) {
            return false;
        }
    }
    util.isClient = isClient;
    function getRelativeUrl() {
        var x = new uri();
        return x.pathname() + x.search();
    }
    util.getRelativeUrl = getRelativeUrl;
    function buildUrl(pathname, query) {
        try {
            var q = _.omit(query, 'page_number', 'page_size', 'ts', 'tsn');
            return pathname + '?' + uri.buildQuery(_.pickBy(q, _.identity));
        }
        catch (err) {
            return pathname;
        }
    }
    util.buildUrl = buildUrl;
    function buildApiQueryString(query) {
        try {
            var q = void 0;
            if (typeof query == 'string') {
                q = new uri(query).search(true);
            }
            else {
                q = query;
            }
            return '?' + uri.buildQuery(q);
        }
        catch (err) {
            return '';
        }
    }
    util.buildApiQueryString = buildApiQueryString;
    function buildSeoQueryString(url) {
        try {
            var x = new uri(url);
            return x.pathname() + x.search();
        }
        catch (err) {
            return '';
        }
    }
    util.buildSeoQueryString = buildSeoQueryString;
    function goTop() {
        var $btn = $('.c-btn-go-top');
        var toggleBtn = function () {
            $btn.toggleClass('show', $(window).scrollTop() > 300 && $('#cc-chat-text').length == 0);
        };
        $btn.on('click', function (e) {
            e.preventDefault();
            $('html, body').animate({ scrollTop: 0 }, 300);
        });
        toggleBtn();
        $(window).on('scroll', function () { toggleBtn(); });
    }
    function scrollTo(targetSelector, offset) {
        setTimeout(function () {
            $(function () {
                var $tgt = $(targetSelector);
                if ($tgt && $tgt.offset()) {
                    $('html, body').animate({ scrollTop: $tgt.offset().top - (offset || 10) }, 300);
                }
            });
        }, 10);
    }
    util.scrollTo = scrollTo;
    if (isClient()) {
        goTop();
        $(document).on('click', '.btn-goto-comments', function (e) {
            e.preventDefault();
            scrollTo('#_comments', 60);
        });
    }
    function getRegion(hostname) {
        try {
            if (!hostname) {
                hostname = uri().hostname();
            }
            var x = hostname.split('.');
            if (x && x.length > 1) {
                return x[0];
            }
            else {
                return null;
            }
        }
        catch (err) {
            return null;
        }
    }
    util.getRegion = getRegion;
    var page_list = _.values(pages_1.pages);
    function getRoom(path) {
        if (_.size(page_list) == 0) {
            page_list = _.values(pages_1.pages);
        }
        var pg = _.find(page_list, function (page) {
            var match = react_router_dom_1.matchPath(path, {
                path: page.path + (page.params || ''), exact: true, strict: false
            });
            return !_.isEmpty(match);
        });
        if (_.get(pg, 'isRedirect')) {
            return null;
        }
        else {
            return _.get(pg, 'room') || _.get(pg, 'title');
        }
    }
    util.getRoom = getRoom;
    function setFormWorking(id, isWorking) {
        if (isWorking) {
            $('#' + id + ' :input').attr('disabled', 'disabled');
        }
        else {
            $('#' + id + ' :input').removeAttr('disabled');
        }
    }
    util.setFormWorking = setFormWorking;
    function setFocus(selector) {
        setTimeout(function () {
            $(selector).focus();
        }, 100);
    }
    util.setFocus = setFocus;
    function formatPrice(price) {
        var nPrice = numeral(price);
        return (isNumeric(price)
            ?
                React.createElement(React.Fragment, null,
                    React.createElement("sup", null, "$"),
                    React.createElement("span", null, Math.floor(nPrice.value()).toLocaleString()),
                    React.createElement("sup", null, nPrice.format('.00')))
            :
                '-');
    }
    util.formatPrice = formatPrice;
    function userCruzBaseUrl(user) {
        return pages_1.pages.user_cruz.path + '/' + slugify_1.default(user.first_name + ' ' + user.last_name) + '/' + user._id;
    }
    util.userCruzBaseUrl = userCruzBaseUrl;
    function getBodyTypeByValue(value) {
        var bt = Cruz.Lookup.Vehicle.body_type_search;
        for (var _i = 0, bt_1 = bt; _i < bt_1.length; _i++) {
            var x = bt_1[_i];
            if (x.value.toString() == value.toString()) {
                return x;
            }
            else if (x.child != null && x.child.length > 0) {
                var y = _.find(x.child, function (xc) { return xc.value.toString() == value.toString(); });
                if (y) {
                    return y;
                }
            }
        }
    }
    util.getBodyTypeByValue = getBodyTypeByValue;
    function getDeviceType() {
        if (platform.product == 'iPhone') {
            return 'i';
        }
        else {
            var isMobile = false;
            var name_1 = platform.name;
            if (name_1 != null) {
                name_1 = name_1.toLowerCase();
                isMobile = _.includes(name_1, 'mobile') || _.includes(name_1, 'android browser');
            }
            var osFamily = platform.os.family;
            var isAndroid = osFamily != null && osFamily.toLowerCase() == 'android';
            if (isAndroid && isMobile) {
                return 'a';
            }
            else {
                return null;
            }
        }
    }
    util.getDeviceType = getDeviceType;
    function isNumeric(d) {
        return !_.isArray(d) && (d - parseFloat(d) + 1) >= 0;
    }
    util.isNumeric = isNumeric;
})(util = exports.util || (exports.util = {}));
