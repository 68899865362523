"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var _ = require("lodash");
var request = require("superagent");
var lib_1 = require("./lib");
var pages_1 = require("../routes/pages");
var cfg = require("../cfg");
var component_1 = require("./component");
var Contact;
(function (Contact) {
    var validate = require('validate.js');
    var rules = {
        'user.first_name': {
            presence: { message: '^First name is required.', allowEmpty: false },
        },
        'user.last_name': {
            presence: { message: '^Last name is required.', allowEmpty: false }
        },
        'user.email': {
            presence: { message: '^Email is required.', allowEmpty: false },
            email: { message: '^Invalid email format.' }
        },
        message: {
            presence: { message: '^Message is required.', allowEmpty: false },
        }
    };
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            var _this = _super.call(this, props) || this;
            if (lib_1.Global.store.getState().user) {
                _this.state = {
                    user: _.pick(lib_1.Global.store.getState().user, ['_id', 'first_name', 'last_name', 'email', 'phone']),
                    is_working: false
                };
            }
            else {
                _this.state = { user: {}, is_working: false };
            }
            return _this;
        }
        Page.prototype.componentDidMount = function () {
            document.title = lib_1.util.title(pages_1.pages.contact.title);
        };
        Page.prototype.getError = function (key) {
            if (this.state.validation && this.state.validation[key]) {
                return this.state.validation[key][0];
            }
            else {
                return null;
            }
        };
        Page.prototype.userInfoChanged = function (tgt) {
            var _a;
            var _this = this;
            this.setState({
                user: _.assign({}, this.state.user, (_a = {}, _a[tgt.id] = tgt.value, _a))
            }, function () {
                _this.state.validation && _this.valid();
            });
        };
        Page.prototype.messageChanged = function (tgt) {
            var _this = this;
            this.setState({
                message: tgt.value
            }, function () {
                _this.state.validation && _this.valid();
            });
        };
        Page.prototype.valid = function () {
            this.setState({ validation: validate(this.state, rules) });
        };
        Page.prototype.submit = function (e) {
            var _this = this;
            e && e.preventDefault();
            var vd = validate(this.state, rules);
            this.setState({ validation: vd });
            if (!this.state.is_working && (vd == null || _.isEmpty(vd) == true)) {
                this.setState({ is_working: true });
                request.post(lib_1.Global.cruzApiBaseUrl + '/contact')
                    .timeout(cfg.superagent.timeout.normal)
                    .send(_.pick(this.state, ['message', 'user'])).end(function (err, apiRes) {
                    _this.setState({ is_working: false }, function () {
                        if (err) {
                            lib_1.util.setToastMessage('error', apiRes.body.error.detail);
                        }
                        else {
                            lib_1.Global.onSubmitSuccess('contact');
                            lib_1.util.setToastMessage('success', cfg.message.contactMessageSent);
                        }
                    });
                });
            }
        };
        Page.prototype.renderForm = function () {
            var _this = this;
            var sGrid = 'col-sm-6';
            return (React.createElement("form", { className: 'mt-4 text-left', id: 'formContact', onSubmit: function (e) { return _this.submit(e); }, noValidate: true },
                React.createElement("div", { className: 'pb-4' },
                    React.createElement("div", { className: 'row' },
                        React.createElement("input", { type: 'text', id: 'subject', name: 'subject', className: 'd-none' }),
                        React.createElement(component_1.Text, { id: 'first_name', label: 'First Name', required: true, "data-err": this.getError('user.first_name'), "data-grid": 'col-sm-6', maxLength: 50, value: this.state.user.first_name || '', onChange: function (e) { return _this.userInfoChanged(e.target); } }),
                        React.createElement(component_1.Text, { id: 'last_name', label: 'Last Name', required: true, "data-err": this.getError('user.last_name'), "data-grid": sGrid, maxLength: 50, value: this.state.user.last_name || '', onChange: function (e) { return _this.userInfoChanged(e.target); } }),
                        React.createElement(component_1.Text, { id: 'email', label: 'Email', type: 'email', required: true, "data-err": this.getError('user.email'), "data-grid": sGrid, maxLength: 50, value: this.state.user.email || '', onChange: function (e) { return _this.userInfoChanged(e.target); } }),
                        React.createElement(component_1.MaskedText, { id: 'phone', label: 'Phone', mask: lib_1.Mask.TypeEnum.Phone, "data-grid": sGrid, value: this.state.user.phone || '', onChange: function (e) { return _this.userInfoChanged(e.target); } })),
                    React.createElement(component_1.Textarea, { id: 'message', label: 'Message', minRows: 3, maxRows: 15, className: 'textarea-lg', required: true, "data-err": this.getError('message'), maxLength: 4000, value: this.state.message || '', onChange: function (e) { return _this.messageChanged(e.target); } })),
                React.createElement("div", { className: 'text-center mt-4' },
                    React.createElement("button", { type: 'submit', className: 'btn c-btn-1 c-btn-round px-5', role: 'button' },
                        React.createElement("i", { className: 'fal fa-paper-plane' }),
                        React.createElement("span", null, this.state.is_working ? React.createElement(component_1.BtnWorking, { value: 'Sending ...' }) : 'Send')))));
        };
        Page.prototype.render = function () {
            return (component_1.Layout.entry(2, React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'text-center' },
                    React.createElement("h1", { className: 'c-section-title' }, "Contact Us")),
                this.renderForm())));
        };
        return Page;
    }(React.Component));
    Contact.Page = Page;
})(Contact = exports.Contact || (exports.Contact = {}));
