"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var Redux = require("redux");
var _ = require("lodash");
var uri = require("urijs");
var classNames = require("classnames");
var cfg = require("../../../cfg");
var lib_1 = require("../../lib");
var pages_1 = require("../../../routes/pages");
var component_1 = require("../../component");
var MySaved;
(function (MySaved) {
    var initState = _.memoize(_.assign)({
        is_loading_vehicle: true,
        tab: 'vehicle',
        vehicle: null,
        vehicle_query: cfg.apiQueryDefault.saved_vehicle,
    });
    function loadVehicle(q, callback) {
        lib_1.Request.saved.item.get('vehicle', q, function (err, apiRes) {
            if (err) {
                return callback(err, null);
            }
            else {
                return callback(null, apiRes.body.data);
            }
        });
    }
    MySaved.loadVehicle = loadVehicle;
    var app = function (state, action) {
        switch (action.type) {
            case 0: {
                state.is_loading_vehicle = false;
                lib_1.util.setToastMessage('error', _.get(action.error, 'detail') || 'Something is wrong');
                return state;
            }
            case 2: {
                state.is_loading_vehicle = true;
                if (action.query) {
                    _.assign(state.vehicle_query, action.query);
                }
                loadVehicle(state.vehicle_query, function (err, d) {
                    if (err) {
                        MySaved.store.dispatch({ type: 0, error: _.first(err) });
                    }
                    else {
                        MySaved.store.dispatch({ type: 3, data: d });
                    }
                });
                return state;
            }
            case 3: {
                state.is_loading_vehicle = false;
                state.vehicle = action.data;
                return state;
            }
            case 1: {
                state.tab = action.data;
                lib_1.Global.history.replace(lib_1.util.buildUrl(location.pathname, { tab: state.tab }));
                return state;
            }
            case 4: {
                var sl = lib_1.Global.store.getState().user.saved_vehicle_list;
                if (_.size(sl) == 0) {
                    state.vehicle = null;
                }
                else {
                    var slIds_1 = _.map(sl, '_id');
                    state.vehicle = _.filter(state.vehicle, function (v) {
                        return slIds_1.includes(v._id);
                    });
                }
                return state;
            }
            default: {
                return state;
            }
        }
    };
    MySaved.store = null;
    var Page = (function (_super) {
        __extends(Page, _super);
        function Page(props) {
            return _super.call(this, props) || this;
        }
        Page.prototype.componentWillMount = function () {
            var _this = this;
            lib_1.Auth.isAuthed(function (isAuthed) {
                if (isAuthed) {
                    var locSearch = new uri(_this.props.location.search).search(true);
                    var tab = _.get(locSearch, 'tab') || 'vehicle';
                    if (_this.props.staticContext) {
                        MySaved.store = Redux.createStore(app, _.assign({}, initState, _this.props.staticContext['result'], { is_loading_vehicle: false, tab: tab }));
                    }
                    else if (MySaved.store == null) {
                        var $initData = document.querySelector('#_init_' + pages_1.pages.my_saved.id);
                        if ($initData && $initData.getAttribute('data-init')) {
                            MySaved.store = Redux.createStore(app, _.assign({}, initState, JSON.parse(decodeURIComponent($initData.getAttribute('data-init'))), { is_loading_vehicle: false, tab: tab }));
                            $initData.remove();
                        }
                        else {
                            MySaved.store = Redux.createStore(app, _.assign({}, initState, { tab: tab }));
                            MySaved.store.dispatch({ type: 2 });
                        }
                    }
                    else if (['PUSH', 'REPLACE'].includes(_this.props.history.action) || (_this.props.history.action == 'POP' && _.size(MySaved.store.getState().vehicle) != _.size(lib_1.Global.store.getState().user.saved_vehicle_list))) {
                        MySaved.store.dispatch({ type: 2 });
                    }
                    _this.unsubscribe = MySaved.store.subscribe(function () {
                        _this.forceUpdate();
                    });
                    _this.unsubscribeGlobal = lib_1.Global.store.subscribe(function () {
                        _this.forceUpdate();
                    });
                }
                else {
                    lib_1.Auth.redirectSignin(lib_1.util.getRelativeUrl());
                }
            });
        };
        Page.prototype.componentDidMount = function () {
            document.title = lib_1.util.title(pages_1.pages.my_saved.title);
            if (MySaved.store) {
                lib_1.Global.saveUserSettings({ my_cruz_path: pages_1.pages.my_saved.path + '?tab=' + MySaved.store.getState().tab });
            }
        };
        Page.prototype.componentWillUnmount = function () {
            this.unsubscribe && this.unsubscribe();
            this.unsubscribeGlobal && this.unsubscribeGlobal();
        };
        Page.prototype.renderVehicleList = function () {
            try {
                var user = lib_1.Global.store.getState().user;
                var vList = MySaved.store.getState().vehicle;
                var state = MySaved.store.getState();
                return (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: 'd-flex justify-content-between' },
                        React.createElement("div", null, _.size(user.saved_vehicle_list) > 1
                            &&
                                React.createElement(component_1.Select, { id: 'sort', name: '', size: 1, label: 'Sort', className: 'mt-1 mb-3 d-inline-block', options: lib_1.util.mapSortOptions(lib_1.Lookup.vehicleSort, user.location.zip_code), value: _.get(state.vehicle_query, 'sort') || 1, onChange: function (e) { return MySaved.store.dispatch({ type: 2, query: { sort: e.target['value'] } }); } })),
                        (_.size(user.saved_vehicle_list) < _.size(vList))
                            &&
                                React.createElement("button", { type: 'button', className: 'btn c-btn-2 c-btn-xs my-2', onClick: function () { MySaved.store.dispatch({ type: 4 }); } }, "Remove Unsaved")),
                    React.createElement("div", { className: 'row c-sm-gutters' }, _.map(vList, function (v, idx) {
                        return (React.createElement("div", { className: 'col-xl-4 col-lg-6 col-md-6 mt-2 mb-2', key: v._id + idx, id: '_vs_' + idx },
                            React.createElement(component_1.VehicleCard.Component, { vehicle: v, showUserInfo: true, showEdit: false, showCondition: true, shouldRender: true })));
                    }))));
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.renderVehicleFilterList = function () {
            return (React.createElement("div", { className: 'row c-sm-gutters' }, _.map(lib_1.Global.store.getState().user.saved_vehicle_filter_list, function (vf, idx) {
                return (React.createElement("div", { className: 'col-xl-4 col-lg-6 col-md-6 mt-2 mb-2', key: vf.id + idx, id: '_vs_' + idx },
                    React.createElement(component_1.Saved.FilterCard, __assign({}, vf))));
            })));
        };
        Page.prototype.renderContent = function () {
            try {
                var user = lib_1.Global.store.getState().user;
                var state = MySaved.store.getState();
                var vList = state.vehicle;
                return (React.createElement(React.Fragment, null,
                    React.createElement("ul", { className: 'nav nav-tabs mt-3 mb-2', id: 'mySavedTab', role: 'tablist' },
                        React.createElement("li", { className: 'nav-item' },
                            React.createElement("a", { className: classNames('nav-link', { active: state.tab == 'vehicle' }), id: 'vehicle', "data-toggle": 'tab', href: '#vehicle', onClick: function () { MySaved.store.dispatch({ type: 1, data: 'vehicle' }); } },
                                "Vehicles (",
                                _.size(user.saved_vehicle_list),
                                ")")),
                        React.createElement("li", { className: 'nav-item' },
                            React.createElement("a", { className: classNames('nav-link', { active: state.tab == 'vehicle_filter' }), id: 'vehicle_tab', "data-toggle": 'tab', href: '#vehicle_filter', onClick: function () { MySaved.store.dispatch({ type: 1, data: 'vehicle_filter' }); } },
                                "Vehicle Filters (",
                                _.size(user.saved_vehicle_filter_list),
                                ")"))),
                    React.createElement("div", { className: 'tab-content' },
                        React.createElement("div", { className: classNames('tab-pane fade', { 'show active': state.tab == 'vehicle' }), id: 'vehicle' }, state.is_loading_vehicle
                            ?
                                React.createElement(component_1.Loading, null)
                            :
                                _.isEmpty(vList)
                                    ?
                                        React.createElement("div", { className: 'alert c-alert mt-3 text-center' }, "No Saved Vehicle.")
                                    :
                                        this.renderVehicleList()),
                        React.createElement("div", { className: classNames('tab-pane fade', { 'show active': state.tab == 'vehicle_filter' }), id: 'vehicle_filter' }, _.isEmpty(user.saved_vehicle_filter_list)
                            ?
                                React.createElement("div", { className: 'alert c-alert mt-3 text-center' }, "No Saved Vehicle Filter.")
                            :
                                this.renderVehicleFilterList()))));
            }
            catch (err) {
                return null;
            }
        };
        Page.prototype.render = function () {
            return (component_1.Layout.main(lib_1.Global.store.getState().user, null, React.createElement("div", { className: 'container c-container' },
                React.createElement("h1", { className: 'c-title' }, pages_1.pages.my_saved.title),
                this.renderContent())));
        };
        return Page;
    }(React.Component));
    MySaved.Page = Page;
})(MySaved = exports.MySaved || (exports.MySaved = {}));
